import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';

/**
 * Service to work with user API resources
 *
 * @class ProductSortedByCategoryService
 */
export class ProductSortedByCategoryService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/product-list(/:unitId)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['username', 'email'];
    }

    /**
     * Get list of products sorted by category
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    getAllProduct(): Promise<AxiosResponse> {

        return this.http
            .get(this.url() + '/a', {
                headers: { ...this.headers, 'Pragma': 'no-cache' },
            });
    }

    /**
     * Get list of products sorted by category for the current unit.
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    getProductByUnitId(unitId: number): Promise<AxiosResponse> {

        return this.http
            .get(this.url({ unitId: unitId }), {
                headers: { ...this.headers, 'Pragma': 'no-cache' },
            });
    }

}