export const dashboardConstants = {
    LIST_SUCCESS: 'DASHBOARD_LIST_SUCCESS',
    LIST_FAILURE: 'DASHBOARD_LIST_FAILURE',

    STORE_SUCCESS: 'DASHBOARD_STORE_SUCCESS',
    STORE_FAILURE: 'DASHBOARD_STORE_FAILURE',

    UPDATE_SUCCESS: 'DASHBOARD_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DASHBOARD_UPDATE_FAILURE',

    DRAGGED_UPDATE_SUCCESS: 'DASHBOARD_DRAGGED_UPDATE_SUCCESS',
    DRAGGED_UPDATE_FAILURE: 'DASHBOARD_DRAGGED_UPDATE_FAILURE',

    DUPLICATE_SUCCESS: 'DASHBOARD_DUPLICATE_SUCCESS',
    DUPLICATE_FAILURE: 'DASHBOARD_DUPLICATE_FAILURE',

    DELETE_SUCCESS: 'DASHBOARD_DELETE_SUCCESS',
    DELETE_FAILURE: 'DASHBOARD_DELETE_FAILURE',

    SELECT: 'DASHBOARD_SELECT',

    RECEIVE_SENSORS_DATA: 'DASHBOARD_RECEIVE_SENSORS_DATA',
    UPDATE_SENSORS_DATA: 'DASHBOARD_UPDATE_SENSORS_DATA',
    UPDATE_SENSOR_DATA: 'DASHBOARD_UPDATE_SENSOR_DATA',

    UPDATE_SENSORS_DATA_RANGE: 'DASHBOARD_UPDATE_SENSORS_DATA_RANGE',

    RECEIVE_SENSORS_DATA_RANGE: 'DASHBOARD_RECEIVE_SENSORS_DATA_RANGE',

    RECEIVE_SENSORS_NARROW_DATA_RANGE: 'RECEIVE_SENSORS_NARROW_DATA_RANGE',
    RECEIVE_SENSORS_NARROW_DATA: 'RECEIVE_SENSORS_NARROW_DATA',

    RECEIVE_SENSORS_DATA_LOADED: 'DASHBOARD_RECEIVE_SENSORS_DATA_LOADED',

    CLEAR_RANGE_DATA: 'CLEAR_RANGE_DATA',

    SET_ONLINE: 'DASHBOARD_SET_ONLINE',
    SET_OFFLINE: 'DASHBOARD_SET_OFFLINE',

    SET_ON_CURRENT_TIME: 'DASHBOARD_SET_ON_CURRENT_TIME',
    SET_OFF_CURRENT_TIME: 'DASHBOARD_SET_OFF_CURRENT_TIME',

    UPDATE_SCREEN_WIDTH: 'DASHBOARD_UPDATE_SCREEN_WIDTH',
};
