import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as AddIcon } from '../../../../core/ui/assets/images/icons/add-thin.svg';
import { ReactComponent as SettingsIcon } from '../../../../core/ui/assets/images/icons/settings.svg';
import { ReactComponent as ProductViewIcon } from '../../../../core/ui/assets/images/icons/product.svg';
import './AddProduct.scss';
import {
    List,
    Popover,
    ListSubheader,
    ListItemText,
    ListItem,
    makeStyles,
    Theme,
    createStyles,
    ListItemIcon,
    Link,
} from '@material-ui/core';
import { ConfirmDialog, SearchInput } from '../../../../core/ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    IProduct,
    IUnit,
} from '../../../../core/interfaces';
import {
    ConfigurationAction,
    UnitActions,
} from '../../../../core/actions';
import { selectProductSortedByCategory } from '../../../../core/selectors/product/productSortedByCategorySelector';





interface IProps {
    currentUnit: IUnit;
    class: string;
    height: number;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
            margin: 0,
        },
    }),
);

const AddProduct: React.FC<IProps> = ({ ...props }: IProps) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const [filter, setFilter] = useState('');
    const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
    const [elementContainer, setElementContainer] = useState<Element | null>(null);

    const dispatch = useDispatch();

    const selectedUnit = useCallback((open: boolean, unit: IUnit) => {

            dispatch(ConfigurationAction.configurationProductSelected(open, unit));

        }, [dispatch]),
        addProduct = useCallback((data: IUnit, type: string, product: IProduct | null) => {

            dispatch(UnitActions.update(data, type, product));

        }, [dispatch]);

    const category = useSelector(selectProductSortedByCategory);

    const open = Boolean(anchorEl);

    const id = open ? 'simple-popover' : undefined;

    const { currentUnit } = props;

    /**
     * click handler
     *
     * @type {(event) => void}
     */
    const handleClick = useCallback((event) => {

        event.stopPropagation();

        setAnchorEl(event.currentTarget.children[0]);

        selectedUnit(open, props.currentUnit);

    }, [props, setAnchorEl, open, selectedUnit]);

    /**
     * close handler
     *
     * @type {() => void}
     */
    const handleClose = useCallback(() => {

        setAnchorEl(null);

        setTimeout(() => setFilter(''), 150);

        selectedUnit(open, props.currentUnit);

    }, [setAnchorEl, props, open, selectedUnit]);

    /**
     * search handler
     *
     * @type {(event) => void}
     */
    const searchHandler = useCallback((event) => {

        setFilter(event.toLowerCase());

    }, [setFilter]);

    /**
     * callback on confirm
     *
     * @type {() => void}
     */
    const onConfirm = useCallback(() => {

        const storeData = { ...currentUnit };

        storeData.products = [selectedProduct?.id];

        addProduct(storeData, 'add', selectedProduct);

        selectedUnit(open, props.currentUnit);

        setSelectedProduct(null);

        setAnchorEl(null);

    }, [addProduct, selectedUnit, currentUnit, setAnchorEl, setSelectedProduct, props, open, selectedProduct]);

    /**
     * Set selected product
     *
     * @type {() => void}
     */
    const handleCancel = useCallback(() => {

        setSelectedProduct(null);

    }, [setSelectedProduct]);

    /**
     * stopPropagation
     *
     * @type {(event) => void}
     */
    const stopPropagation = useCallback((event) => {

        event.stopPropagation();
    }, []);


    useEffect(() => {

        setElementContainer(document.getElementsByClassName('configure-draggable')[0]);

    }, [setElementContainer]);

    /**
     * Clear search filter
     */
    useEffect(() => {

        if (anchorEl === null && filter.trim().length > 0) {

            setFilter('');

        }

    }, [setFilter, anchorEl]);


    const wrapAddSectionStyle = {
            height: props.height,
            transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            zIndex: 1,
        },
        wrapAddSectionHeaderStyle = {
            width: 16,
            height: 16,
            marginRight: 4,
        };

    return (
        <React.Fragment>
            <div className={open ? `wrap-add-section selected ${props.class}` : `wrap-add-section ${props.class}`}
                 aria-describedby={id}
                 style={wrapAddSectionStyle}
                 onClick={handleClick}
            >
                <div className="header">
                    <AddIcon style={wrapAddSectionHeaderStyle} />
                    <span>{t('ADD_PRODUCT')}</span>
                </div>
            </div>
            {Boolean(anchorEl)&&
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                className={'add-product-popover'}
                // onBackdropClick={stopPropagation}
                container={elementContainer}
            >
                <div className="add-product-popover--wrap" onClick={stopPropagation}>
                    <div className="header">
                        <SearchInput placeholder={t('SEARCH')} options={{}} searchHandler={searchHandler} />
                    </div>
                    <div className="body">
                        <List className={classes.root} subheader={<li />}>
                            {category.length > 0 ? category.filter(value => value.products?.length !== 0).map((section) => (
                                <li key={`section-${section.name}`} className={classes.listSection}>
                                    <ul className={classes.ul}>
                                        <ListSubheader
                                            className={'subheader'}
                                        >
                                            {`${section.id !== null ? section.name : t('UNSORTED')}`}
                                        </ListSubheader>
                                        {section.products && section.products.filter((value: { name: string }) => value.name.toLowerCase().includes(filter)).map((item: IProduct) => (
                                            <ListItem
                                                className={'list-item'}
                                                key={`item-${section.name}-${item.name}`}
                                                disabled={props.currentUnit.products.filter(value1 => value1.id === item.id).length > 0}
                                                onClick={() => {

                                                    if (props.currentUnit.products.filter(value1 => value1.id === item.id).length === 0) {
                                                        setSelectedProduct(item);
                                                    }
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <ProductViewIcon />
                                                </ListItemIcon>
                                                <ListItemText className={'text-item'} primary={`${item.name}`} />
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                            )):
                                <ListItem>
                                    {t('NO_PRODUCTS')}
                                </ListItem>}
                        </List>
                    </div>
                    <div className="footer">
                        <Link href={'/setting/product'}>
                            <SettingsIcon style={{ width: 24, height: 24 }} />{t('CONFIGURE_LIST')}
                        </Link>
                    </div>
                </div>
            </Popover>
            }
            {selectedProduct &&
            <ConfirmDialog
                heading={t('ADD_PRODUCT_TO_UNIT', {
                    product: selectedProduct && selectedProduct.name,
                    unit: props.currentUnit.name,
                })}
                onAccept={onConfirm}
                onClose={handleCancel}
                container={elementContainer}
                open={Boolean(selectedProduct)}
            />
            }
        </React.Fragment>

    );
};

export default AddProduct;
