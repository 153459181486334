export const editorConstants = {
    interaction: {
        modeSelection: 'selection',
        modeGrab: 'grab',
        modePolygon: 'polygon',
        modeDelete: 'delete',
        modeEdit: 'edit',
    },
    objects: {
        label: 'label',
    },
    colors: {
        active: '#75c36e',
        inActive: '#4092f5',
    },
};