import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import ProcessItemTree from '../Items/ProcessItemTree';
import StructuralTreeUnitTitle from '../Items/StructuralTreeUnitTitle';
import { ReactComponent as FolderKeyIcon } from '../../../../core/ui/assets/images/icons/folder-key.svg';
import { ReactComponent as FolderKeySelectedIcon } from '../../../../core/ui/assets/images/icons/folder-key-selected.svg';
import { ReactComponent as UnitIcon } from '../../../../core/ui/assets/images/icons/folder-unit.svg';
import { IActiveProductData, IEditStructuralTreeUnitProps, IFlatTreeUnit, ISensor } from '../../../../core/interfaces';
import Sensor from './Sensor';
import { selectActiveProductByUnitId } from '../../../../core/selectors/unitActiveProducts/activeProductDataSelector';
import { selectHistogramHeight } from '../../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectFlatMonitoringTreeItemById } from '../../../../core/selectors/monitoringTree/monitoringTreeSelector';
import {
    selectMaxWidthSideBar,
    selectVisibleSideBar,
} from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { sortOrder } from '../../../../helpers/sortOrder';

/**
 * Edit structural tree unit node
 *
 * @param unit
 * @param process
 * @param factory
 * @param searchField
 * @param onChange
 * @param onMouseDown
 * @param handleClick
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Unit: React.FC<IEditStructuralTreeUnitProps> = ({
    unitID,
    process,
    factory,
    searchField,
    // placeholderProps,
    onChange,
    onMouseDown,
    handleClick,
}: IEditStructuralTreeUnitProps) => {
    const [isMinimized, setIsMinimized] = useState<boolean>(false);
    const unit = useParamSelector(selectFlatMonitoringTreeItemById, unitID, 'unit') as IFlatTreeUnit;

    useEffect(() => {
        setIsMinimized(!unit.isMinimized);
    }, [unit]);

    const activeProductDataByUnit = useParamSelector(selectActiveProductByUnitId, unit.id) as IActiveProductData | undefined,
        histogramHeight = useSelector(selectHistogramHeight),
        selectedDashboard = useSelector(selectSelectedDashboard),
        visibleSideBar = useSelector(selectVisibleSideBar),
        maxWidthSideBar = useSelector(selectMaxWidthSideBar);

    const keySensor = unit.data.find((sensor: ISensor) => sensor.isVisible === true && sensor.isKeyParameter === true);

    const hasVisibleSensor = unit.data.some((sensor: ISensor) => sensor.isVisible === true);

    /**
     * Apply filter and sorting to sensors array
     *
     * @param {ISensor[]} sensors
     *
     * @returns {ISensor[]}
     */
    function filter(sensors: ISensor[]) {

        return sensors.filter(sensor => sensor.name.toLowerCase().match(searchField));
    }

    /**
     * Apply filter and sorting to sensors array
     *
     * @param {ISensor[]} sensors
     *
     * @returns {ISensor[]}
     */
    const filterAndSorting = useCallback((sensors: ISensor[]) => {

        const filtered = filter(sensors);

        sortOrder(filtered);

        return filtered;
    }, [filter]);

    const AccordionChangeCallback = useCallback((event: React.ChangeEvent<{}>, expanded: boolean) => {

        onChange();

        setIsMinimized(expanded);

    }, [setIsMinimized, onChange]);

    // const { clientX, clientY, clientWidth, clientHeight } = placeholderProps;

    if (!unit) {

        return null;
    }

    return (
        <div className="process-item">
            {unit.products && unit.products.length > 0 ?
                <ProcessItemTree
                    maxWidthSideBar={maxWidthSideBar}
                    visibleSideBar={visibleSideBar}
                    unitId={unit.id}
                    displayProductRule={unit.products.length <= 0}
                    activeProductData={activeProductDataByUnit}
                />
                :
                ''
            }
            <Accordion
                className={'expansion-panel unit-panel'}
                defaultExpanded={isMinimized}
                expanded={isMinimized}
                onChange={AccordionChangeCallback}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id={'unit' + unit.id.toString()}
                >
                    <StructuralTreeUnitTitle
                        GetMinimizedUnit={isMinimized}
                        GetSensorWithKeyParam={keySensor}
                        unit={unit}
                        histogramHeight={histogramHeight}
                        handleClick={handleClick}
                        icon={hasVisibleSensor ?
                            <React.Fragment>
                                {keySensor ?
                                    <FolderKeyIcon className="default-icon-size folder-key" />
                                    :
                                    <FolderKeySelectedIcon className="default-icon-size" />
                                }
                            </React.Fragment>
                            :
                            <UnitIcon className="default-icon-size" />
                        }
                        checkedIcon={hasVisibleSensor ?
                            <React.Fragment>
                                {keySensor ?
                                    <FolderKeyIcon className="default-icon-size folder-key" />
                                    :
                                    <FolderKeySelectedIcon className="default-icon-size" />
                                }
                            </React.Fragment>
                            :
                            <UnitIcon className="default-icon-size" />
                        }
                        histogramData={unit.data}
                        activeProductData={activeProductDataByUnit}

                    />
                </AccordionSummary>

                <AccordionDetails className="unit-details">
                    <Droppable
                        droppableId={unit.id.toString()}
                        key={unit.id}
                        isCombineEnabled
                        ignoreContainerClipping
                    >
                        {(provided: DroppableProvided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                key={unit.id}
                                className="unit-item"
                            >
                                {filterAndSorting(unit.data).map((sensor: ISensor, sensorIndex: number) => {

                                    if (selectedDashboard && !selectedDashboard.graphs_switch && sensorIndex !== 0) {

                                        return null;
                                    }

                                    return (
                                        <Sensor
                                            key={sensor.id}
                                            index={sensorIndex}
                                            sensor={{ ...sensor }}
                                            unit={unit}
                                            process={process}
                                            factory={factory}
                                            onMouseDown={onMouseDown}
                                            activeProductData={activeProductDataByUnit}
                                        />
                                    );
                                })}

                                {provided.placeholder}

                                {/*{placeholderProps.id && placeholderProps.id === unit.id ?*/}
                                {/*    <div*/}
                                {/*        className={'placeholder-props'}*/}
                                {/*        style={{*/}
                                {/*            position: 'absolute',*/}
                                {/*            top: (clientY ? clientY : 0) + 40,*/}
                                {/*            left: clientX,*/}
                                {/*            height: clientHeight,*/}
                                {/*            width: clientWidth,*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*:*/}
                                {/*    null*/}
                                {/*}*/}

                            </div>
                        )}
                    </Droppable>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Unit;