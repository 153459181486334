import { Canvas } from '../canvas/Canvas';
import { editorConstants } from '../constants';
import { IHmiObject, ITypeObject } from '../../../../../core/interfaces';
import { drawObject } from './drawObject';
import { maxString } from '../../../../../core/helpers/fittingStringHelper';

/**
 * Add object to canvas by type
 *
 * @param {Canvas} canvas
 * @param {IHmiObject} object
 * @param fullLabel
 * @param fontSizeLabel
 * @param visible
 * @param dataValue
 */
export function addObjectByType(canvas: Canvas, object: IHmiObject, fullLabel = '', fontSizeLabel = 12, visible : boolean, dataValue?: string): void {

    const {
        modePolygon,
        modeEngine,
        modeSensor,
        modeValve,
        modeIndicator,
        modeRectangle,
        modeOval,
        modeTriangle,
        modeStar,
    } = editorConstants.interaction;

    const {
        engine,
        sensor,
        valve,
        indicator,
        rectangle,
        oval,
        triangle,
        star,
        draw,
    } = editorConstants.typeToId;

    const label = maxString(fullLabel, 15);

    switch ((object.type as ITypeObject).id) {

        case engine:

            drawObject(canvas, modeEngine, object, label, fontSizeLabel, visible, dataValue);

            break;

        case indicator:

            drawObject(canvas, modeIndicator, object, label, fontSizeLabel, visible, dataValue);

            break;

        case valve:

            drawObject(canvas, modeValve, object, label, fontSizeLabel, visible, dataValue);

            break;

        case sensor:

            drawObject(canvas, modeSensor, object, label, fontSizeLabel, visible, dataValue);

            break;

        case rectangle:

            drawObject(canvas, modeRectangle, object, label, fontSizeLabel, visible, dataValue);

            break;

        case oval:

            drawObject(canvas, modeOval, object, label, fontSizeLabel, visible, dataValue);

            break;

        case triangle:

            drawObject(canvas, modeTriangle, object, label, fontSizeLabel, visible, dataValue);

            break;

        case star:

            drawObject(canvas, modeStar, object, label, fontSizeLabel, visible, dataValue);

            break;

        case draw:

            drawObject(canvas, modePolygon, object, label, fontSizeLabel, visible, dataValue);

            break;
    }
}