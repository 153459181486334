import { graphConstants } from '../../../../core/constants';
import { IChartData, IGraphPeakAction } from '../../../../core/interfaces';


interface IGraphRuleState {
    display: boolean;
    x: number;
    mobileDetect: boolean;
    data: IChartData[];
}

/**
 * Graph rule reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphRule(state: IGraphRuleState = {
    display: false,
    x: 0,
    mobileDetect: false,
    data: [],
}, action: IGraphPeakAction): IGraphRuleState {

    switch (action.type) {

        case graphConstants.peakMouseEnter:

            return {
                display: true,
                x: action.x,
                mobileDetect: action.mobileDetect,
                data: action.data,
            };

        case graphConstants.peakEnterEmptyLine:

            return {
                ...state,
                display: true,
                x: action.x,
            };

        case graphConstants.peakMouseLeave:

            return {
                ...state,
                display: false,
                x: 0,
            };

        default:

            return state;
    }
}
