import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectEditorBarrierState = createSelector(
    selectHrModuleState,
    state => state.editorBarrierState,
);

export const selectEditorBarriers = createSelector(
    selectEditorBarrierState,
    state => state.barriers,
);
