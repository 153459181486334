import {
    SaveZoneAction,
    FetchZonesRequest,
    FetchZonesSuccess,
    FetchZonesFailure,
    DeleteZoneAction,
    ClearZonesAction,
} from '../actions/zoneActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IZoneApiModel, IZoneIndentOnly } from '../../services/zoneService';
import { ZoneService } from '../../services/zoneService';
import { IOrder } from '../../../../core/interfaces';
import { IFilter, IJoin, ILocation } from '../../interfaces';
import { FetchGatewaysFailure } from '../actions/gatewayActions';

/**
 * Zone thunks
 */
export const zoneThunks = {

    /**
     * Fetch list of zones
     *
     * @param {ILocation} location
     * @param {string} search
     * @param {IOrder} order
     *
     * @returns {Promise<void>}
     */
    fetchZones: (location: ILocation, search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }, join?: IJoin, filter?: IFilter) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            dispatch(new FetchZonesRequest());

            const service = new ZoneService();

            const filter = 'plan.id||$eq||' + location.id;

            const zones = await service.list(search, order, join, { field: [filter] });

            dispatch(new FetchZonesSuccess({ zones }));

        } catch (error) {

            dispatch(new FetchZonesFailure({ error }));
        }
    },

    /**
     * Fetch list of zones
     *
     * @param {string} search
     * @param {IOrder} order
     * @param {IJoin} join
     * @param {IFilter} filter
     *
     * @returns {Promise<void>}
     */
    fetchAllZones: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }, join?: IJoin, filter?: IFilter) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        const service = new ZoneService();

        try {

            dispatch(new FetchZonesRequest());

            const zones = await service.list(search, order, join, filter);

            dispatch(new FetchZonesSuccess({ zones }));

        } catch (errors) {

            const error = service.errorHandler(errors);

            dispatch(new FetchGatewaysFailure({ error }));
        }
    },

    /**
     * Delete zone
     *
     * @param {IZoneApiModel} zone
     *
     * @returns {Promise<void>}
     */
    deleteZone: (zone: IZoneIndentOnly) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new ZoneService();

            service.delete(zone);

            dispatch(new DeleteZoneAction({ zone }));

        } catch (error) {

            //nothing to do
        }
    },

    /**
     * Save zone
     *
     * @param {IZoneApiModel} model
     */
    saveZone: (model: IZoneApiModel) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new ZoneService();

            const zone = await service.save(model);

            dispatch(new SaveZoneAction({ zone }));

        } catch (error) {

            console.warn(error);

            throw error;
        }
    },

    /**
     * Clear zone state
     */
    clearZones: () => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {
        dispatch(new ClearZonesAction());
    },
};
