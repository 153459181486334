import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { isMobile } from 'react-device-detect';

interface IScrollContainerProps {
    maxHeightSlider?: number | string;
    children: React.ReactNode;
    headerScroll?: React.ReactNode;
    headerStyle?: Record<string, string | number>;
    bodyStyle?: Record<string, string | number>;
}

const ScrollContainer: React.FC<IScrollContainerProps> = ({ maxHeightSlider, headerScroll, headerStyle, bodyStyle, children }: IScrollContainerProps) => {

    const defaultsHeaderStyle: Record<string, string | number> = {
        position: 'sticky',
        top: 0,
        zIndex: 5,
        background: '#e6eaed',
        padding: '28px 32px 15px 32px',
        marginBottom: -15,
    };

    const defaultsBodyStyle: Record<string, string | number> = {
        padding: headerScroll ? '0 32px 28px 32px' : '28px 32px',
    };

    return (
        <React.Fragment>
            <Scrollbar
                width={'100%'}
                style={{
                    height: maxHeightSlider ? maxHeightSlider : '100vh',
                }}
                className={'scroll-container'}
                momentum={false}
                mobileNative={false}
                disabled={isMobile}
            >
                {headerScroll &&
                <div className="header-scroll" style={{ ...defaultsHeaderStyle, ...headerStyle }}>
                    {headerScroll}
                </div>
                }
                <div className="body-scroll" style={{ ...defaultsBodyStyle, ...bodyStyle }}>
                    {children}
                </div>
            </Scrollbar>
        </React.Fragment>

    );
};

export default ScrollContainer;
