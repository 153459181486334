import React, { FC, useCallback, useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button, ScrollContainer, TextInput } from '../../../core/ui/components';
import '../ChangePassword.scss';
import { IAuthState, IError } from '../../../core/interfaces';
import { UserActions } from '../../../core/actions';
import { connect } from 'react-redux';
import OldPasswordField from './OldPasswordField';
import { isMobile } from 'react-device-detect';


interface IFormValues {
    old_password: string;
    password: string;
    re_type_password: string;
}

interface IPassword {
    setPassword: (oldPassword: string, newPassword: string) => void;
    logOut: () => void;
    errors?: IError;
    success?: boolean;
    typingPassword: () => void;
}

const Password: FC<IPassword & WithTranslation> = ({ t, setPassword, errors, success, typingPassword, ...props }: IPassword & WithTranslation) => {
        const initialValues = {
            old_password: '',
            password: '',
            re_type_password: '',
        };

        const validationSchema = yup.object().shape({
            old_password: yup
                .string()
                .min(6, t('OLD_PASSWORD_MUST_CONTAIN_AT_LEAST_6_CHARACTERS'))
                .required(t('OLD_PASSWORD_IS_REQUIRED')),
            password: yup
                .string()
                .min(6, t('PASSWORD_MUST_CONTAIN_AT_LEAST_6_CHARACTERS'))
                .matches(/^(?=.*[0-9])(?=.*[a-z\u0430-\u044f])(?=\S+$).*$/i, t('PASSWORD_POSSIBLE_CHARACTERS_ERROR'))
                .required(t('PASSWORD_IS_REQUIRED')),
            re_type_password: yup
                .string()
                .oneOf([yup.ref('password')], t('PASSWORD_CONFIRMATION_DOES_NOT_MATCH_PASSWORD'))
                .required(t('PASSWORD_CONFIRMATION_IS_REQUIRED')),
        });

        /**
         * Handel submit form
         */
        const handleSubmit = useCallback((values: IFormValues) => {

            setPassword(values.old_password, values.re_type_password);

        }, [setPassword]);

        useEffect(() => {

            const errorLogic = errors && errors.common;

            if (!errorLogic && success) {

                setTimeout(() => props.logOut(), 50);
            }

            // eslint-disable-next-line
        }, [ errors, success ])


        return (
            <div className="change-password-option">
                <ScrollContainer
                    maxHeightSlider={`calc(100vh - ${isMobile ? 220 : 40}px)`}
                    bodyStyle={{
                        paddingTop: 20,
                    }}
                >
                    <h2 className={'title'}>{t('CHANGE_PASSWORD')}</h2>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >{props => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <div className="section wrap-change-password">
                                <div className="table-body ">
                                    <div className="form-group">
                                        <OldPasswordField
                                            value={props.values}
                                            errors={errors ? errors : {}}
                                            errorsProps={props.errors}
                                            handleBlur={props.handleBlur}
                                            handleChange={props.handleChange}
                                            touchedProps={props.touched}
                                            typingPassword={typingPassword}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.password ? props.errors.password ? 'error-field' : 'success-field' : '')
                                            }
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.password}
                                            name="password"
                                            type="password"
                                            placeholder={t('ENTER_PASSWORD')}
                                        >
                                            {((props.touched.password && props.errors.password)) &&
                                            <div
                                                className="validation-massage"
                                            >
                                                {props.errors.password}
                                            </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.re_type_password ? props.errors.re_type_password ? 'error-field' : 'success-field' : '')
                                            }
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.re_type_password}
                                            name="re_type_password"
                                            type="password"
                                            placeholder={t('REENTER_PASSWORD')}
                                        >
                                            {((props.touched.re_type_password && props.errors.re_type_password)) &&
                                            <div
                                                className="validation-massage"
                                            >
                                                {props.errors.re_type_password}
                                            </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group btn-group">
                                        <Button
                                            type="submit"
                                            color={'primary'}
                                        >
                                            {t('SAVE_NEW_PASSWORD')}
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    )}
                    </Formik>
                </ScrollContainer>
            </div>
        );
    }
;

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: { auth: IAuthState }) => {

    const { errors, success } = state.auth;

    return {
        errors,
        success,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    setPassword: UserActions.changePassword,
    typingPassword: UserActions.typingPassword,
    logOut: UserActions.logout,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Password));