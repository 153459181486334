import { graphConstants } from '../../constants';
import { IGraphStructuralTreeVisibilityState } from '../../interfaces';

/**
 * Graph Minimap visibility reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphStructuralTreeVisibility(state: IGraphStructuralTreeVisibilityState = {
    maxWidth: JSON.parse(localStorage.getItem('sidebar') as string) ? 320 : 76,
}, action: { type: string }): IGraphStructuralTreeVisibilityState {

    switch (action.type) {

        case graphConstants.sideBarSTShow:

            return {
                visible: false,
                maxWidth: 320,
            };

        case graphConstants.sideBarSTHide:

            return {
                visible: true,
                maxWidth: 76,
            };

        default:

            return state;
    }
}
