import { Handler as BaseHandler } from '../../../../../../base/components/Editor/canvas/handlers/Handler';
import { EventHandler } from './EventHandler';
import { InteractionHandler } from './InteractionHandler';

export class Handler extends BaseHandler {

    /**
     * Interaction handler instance
     *
     * @type {InteractionHandler}
     */
    public interactionHandler: InteractionHandler;

    /**
     * Event handler instance
     *
     * @type {EventHandler}
     */
    public eventHandler: EventHandler;

    /**
     * Constructor
     *
     * @param options
     */
    constructor(options: any) {

        super(options);

        this.onHover = options.onHover;

        this.interactionHandler = new InteractionHandler(this);
        this.eventHandler = new EventHandler(this);
    }
}
