import { createSelector } from 'reselect';
import { RootState } from '../../store';

const getAlert = (state: RootState, alertId: number) => {

    return state.notification.notificationsMap.get(alertId);
};

export const selectHoverAlerts = createSelector(
    [getAlert],
    (notification) => notification,
);
