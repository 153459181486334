import { HrApiProvider } from '../providers/apiProvider';
import { IHRDashboard, IHRDashboardUpdateModel } from '../interfaces';


/**
 * Service to work with Employee API resources
 *
 * @class EmployeeService
 */
export class MonitoringTreeService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/configuration/dashboards(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of Department
     *
     * @return {Promise<Object>}
     */
    async list(id: number): Promise<IHRDashboard> {

        return await this.http
            .get<IHRDashboard>(this.url({ id: id }), {
                headers: this.headers,
            })
            .then(this.getDataExtractor());
    }

    /**
     * Update Department
     *
     * @param monitoringTree
     */
    update(monitoringTree: IHRDashboardUpdateModel): Promise<IHRDashboardUpdateModel> {

        return this.http
                .patch<IHRDashboardUpdateModel>(this.url({ id: monitoringTree.id }), monitoringTree, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor());
    }

}
