import roles from './roles';
import { IUser } from '../core/interfaces';

/**
 * Role based access control for usage outside JSX
 *
 * @class Rbac
 */
export default class Rbac {

    /**
     * Current user role
     *
     * @type string
     */
    private role: string;

    /**
     * Constructor
     *
     * @param {IUser} user
     */
    constructor(user?: IUser) {

        this.role = user ? user.role : 'standard';
    }

    /**
     * Check if user can access given action
     *
     * @param {string} action
     *
     * @returns {boolean | null}
     */
    can(action: string): boolean | null {

        const permissions = roles[this.role] ? roles[this.role] : null;

        return permissions && permissions.actions.includes(action);
    }
}
