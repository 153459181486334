import { LocationActions, LocationActionTypes } from '../actions/locationActions';
import { ILocation } from '../../interfaces';
import { LayerActions, LayerActionTypes } from '../actions/layerActions';

export interface ILocationState {
    isLoading: boolean;
    locations: ILocation[];
    error: any;
}

export const initialState: ILocationState = {
    isLoading: false,
    locations: [],
    error: null,
};

export function locationReducer(state = initialState, action: LocationActions | LayerActions): ILocationState {
    switch (action.type) {
        case LocationActionTypes.FETCH_LOCATIONS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case LocationActionTypes.FETCH_LOCATIONS_SUCCESS: {
            const { locations } = action.payload;

            return {
                ...state,
                isLoading: false,
                locations,
            };
        }

        case LocationActionTypes.FETCH_LOCATIONS_FAILURE: {
            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case LocationActionTypes.DELETE_LOCATION_ACTION: {
            const { location } = action.payload;

            const locations = state.locations.filter(({ id }) => location.id !== id);

            return {
                ...state,
                locations,
            };
        }

        case LayerActionTypes.DELETE_LAYER_ACTION: {
            const { layer } = action.payload;

            const locations = state.locations.map(location => {
                return {
                    ...location,
                    layers: location.layers.filter(({ id }) => layer.id !== id),
                };
            });

            return {
                ...state,
                locations,
            };
        }

        case LocationActionTypes.SAVE_LOCATION: {
            const { location } = action.payload;

            const locationsMapper = new Map(state.locations.map(location => [location.id, location]));

            locationsMapper.set(location.id, location);

            const locations = Array.from(locationsMapper.values());

            return {
                ...state,
                locations,
            };
        }

        case LocationActionTypes.SET_LOCATION_PICTURE: {
            const { locationId, pictureContent } = action.payload;

            const locationsMapper = new Map(state.locations.map(location => [location.id, location]));

            if (locationsMapper.has(locationId)) {
                const location = locationsMapper.get(locationId) as ILocation;
                locationsMapper.set(locationId, { ...location, pictureContent });
            }

            const locations = Array.from(locationsMapper.values());

            return {
                ...state,
                locations,
            };
        }

        case LocationActionTypes.SET_LOCATION_PICTURE_ERROR: {

            const { error } = action.payload;

            return {
                ...state,
                error: error,
            };
        }

        default: {
            return state;
        }
    }
}
