import React, { useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { ListItemText } from '@material-ui/core';
import { TextInput } from '../../../core/ui/components';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { ConfigurationActions, ProductActions } from '../../../core/actions';
import { useTranslation } from 'react-i18next';


interface IProps {
    targetValue: {
        minTargetValue: number | null;
        maxTargetValue: number| null;
        productId: number;
        sensorId: number;
    };
    productId?: number;
    sensorId?: number;
}

interface ITargetProduct {
    maxTargetValue: number | null;
    minTargetValue: number | null;
    productId: number;
}

const TargetValue: React.FC<IProps> = (
    {
        productId,
        sensorId,
        targetValue,
    }: IProps,
) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState<ITargetProduct>(targetValue);

    const [enableInput, setEnableInput] = useState<{ status: boolean; nameTargetValue: string | null; }>(
        {
            status: false,
            nameTargetValue: null,
        },
    );

    const validationSchema = yup.object().shape({
        maxTargetValue: yup.number(),
        minTargetValue: yup.number(),
    });

    const handleSubmit = useCallback((data: ITargetProduct) => {

        if (productId && sensorId) {

            data.minTargetValue = String(data.minTargetValue).trim().length > 0 ? data.minTargetValue : null;
            data.maxTargetValue = String(data.maxTargetValue).trim().length > 0 ? data.maxTargetValue : null;

            const { minTargetValue, maxTargetValue } = data;

            if (minTargetValue && maxTargetValue &&
                minTargetValue > maxTargetValue
            ) {
                return;
            }

            dispatch(ProductActions.updateTargetValue(productId, sensorId, {
                minTargetValue: data.minTargetValue,
                maxTargetValue: data.maxTargetValue,
            }));

            const updatedTree = setTimeout(() => {

                dispatch(ConfigurationActions.list(true));

                clearTimeout(updatedTree);

            }, 300);

            setInitialValues(data);


            setEnableInput({ nameTargetValue: null, status: false });
        }
    }, [dispatch, productId, sensorId]);

    return (
        <React.Fragment>
            {enableInput.status && (enableInput.nameTargetValue === 'minTargetValue' || enableInput.nameTargetValue === 'maxTargetValue') ?
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >{(props: FormikProps<ITargetProduct>) => (
                    <form
                        className={'target-form'}
                        onSubmit={props.handleSubmit}
                        onKeyDown={event => {

                            if (event.key === 'Enter') {

                                handleSubmit(props.values);
                            }
                        }}
                    >
                        <ListItemText className={'description__targetvalue form'}>
                            <ul className="target-list">
                                <li 
                                    className={`target-item ${enableInput.status && enableInput.nameTargetValue === 'minTargetValue' && 'active'}`}
                                >
                                    {targetValue && enableInput.status && enableInput.nameTargetValue === 'minTargetValue' ?
                                        <>
                                            <TextInput
                                                className={
                                                    'form-field '
                                                }
                                                autoFocus
                                                onChange={event => {

                                                    if (event.target.value.length <= 16) {

                                                        props.handleChange(event);

                                                    }
                                                }}
                                                onBlur={event => {

                                                    if (event.target.value.length <= 16) {

                                                        props.handleBlur(event);
                                                    }
                                                    if (enableInput.nameTargetValue === 'minTargetValue') {

                                                        setEnableInput({
                                                            nameTargetValue: 'minTargetValue',
                                                            status: false,
                                                        });

                                                        props.handleReset();
                                                    }
                                                }}
                                                value={props.values.minTargetValue !== null ? props.values.minTargetValue : ''}
                                                onMouseLeave={() => {
                                                    if (enableInput.nameTargetValue === 'minTargetValue') {

                                                        setEnableInput({
                                                            nameTargetValue: 'minTargetValue',
                                                            status: false,
                                                        });

                                                        props.handleReset();
                                                    }
                                                }}
                                                name={'minTargetValue'}
                                                type="number"
                                                placeholder={t('NUMBER')}
                                                style={{ maxWidth: 86 }}
                                            />
                                        </>
                                        :
                                        <React.Fragment>
                                            {/*{targetValue.minTargetValue?*/}
                                            {/*    <Tooltip*/}
                                            {/*        title={targetValue?.minTargetValue || ''}*/}
                                            {/*        leaveDelay={0}*/}
                                            {/*        enterDelay={0}*/}
                                            {/*    >*/}
                                            {/*        <span>{targetValue?.minTargetValue}</span>*/}
                                            {/*    </Tooltip>*/}
                                            {/*    :*/}
                                            <span>{initialValues?.minTargetValue}</span>
                                            {/*}*/}
                                        </React.Fragment>
                                    }
                                </li>
                                <li className={`target-item ${enableInput && enableInput.status && enableInput.nameTargetValue === 'maxTargetValue' && 'active'}`}>
                                    {enableInput && enableInput.status && enableInput.nameTargetValue === 'maxTargetValue' ?

                                        <TextInput
                                            className={
                                                'max-target-value '
                                            }
                                            autoFocus
                                            onChange={event => {

                                                if (event.target.value.length <= 16) {

                                                    props.handleChange(event);

                                                }

                                            }}
                                            onBlur={event => {

                                                if (event.target.value.length <= 16) {

                                                    props.handleBlur(event);
                                                }

                                                if (enableInput.nameTargetValue === 'maxTargetValue') {

                                                    setEnableInput({
                                                        nameTargetValue: 'maxTargetValue',
                                                        status: false,
                                                    });
                                                    props.handleReset();
                                                }
                                            }}
                                            value={props.values.maxTargetValue !== null ? props.values.maxTargetValue : ''}
                                            onMouseLeave={() => {
                                                if (enableInput.nameTargetValue === 'maxTargetValue') {

                                                    setEnableInput({
                                                        nameTargetValue: 'maxTargetValue',
                                                        status: false,
                                                    });

                                                    props.handleReset();
                                                }
                                            }}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {

                                                    props.handleSubmit();

                                                    setEnableInput({
                                                        nameTargetValue: 'maxTargetValue',
                                                        status: false,
                                                    });
                                                }
                                            }}
                                            name={'maxTargetValue'}
                                            type="number"
                                            placeholder={t('NUMBER')}
                                            style={{ maxWidth: 86 }}
                                        />
                                        :
                                        <React.Fragment>
                                            {/*{targetValue.maxTargetValue?*/}
                                            {/*    <Tooltip*/}
                                            {/*        title={targetValue?.maxTargetValue || ''}*/}
                                            {/*        leaveDelay={0}*/}
                                            {/*        enterDelay={0}*/}
                                            {/*    >*/}
                                            {/*        <span>{targetValue?.maxTargetValue}</span>*/}
                                            {/*    </Tooltip>*/}
                                            {/*    :*/}
                                            <span>{initialValues?.maxTargetValue}</span>
                                            {/*}*/}
                                        </React.Fragment>
                                    }
                                </li>
                            </ul>
                        </ListItemText>
                    </form>
                )}
                </Formik>
                :
                <ListItemText className={'description__targetvalue form'}>
                    <ul className="target-list">
                        <li className={`target-item ${enableInput.status && enableInput.nameTargetValue === 'minTargetValue' && 'active'}`}
                            onDoubleClick={(event) => {
                                event.preventDefault();

                                setEnableInput({ nameTargetValue: 'minTargetValue', status: true });
                            }}
                            onTouchStart={() => {
                                setEnableInput({ nameTargetValue: 'minTargetValue', status: true });
                            }}
                        >
                            {/*{targetValue.minTargetValue?*/}
                            {/*    <Tooltip*/}
                            {/*        title={targetValue?.minTargetValue || ''}*/}
                            {/*        leaveDelay={0}*/}
                            {/*        enterDelay={0}*/}
                            {/*    >*/}
                            {/*        <span>{targetValue?.minTargetValue}</span>*/}
                            {/*    </Tooltip>*/}
                            {/*    :*/}
                            <span>{initialValues?.minTargetValue}</span>
                            {/*}*/}
                        </li>
                        <li className={`target-item ${enableInput && enableInput.status && enableInput.nameTargetValue === 'maxTargetValue' && 'active'}`}
                            onDoubleClick={(event) => {
                                event.preventDefault();

                                setEnableInput({ nameTargetValue: 'maxTargetValue', status: true });
                            }}
                            onTouchStart={() => {

                                setEnableInput({ nameTargetValue: 'maxTargetValue', status: true });
                            }}
                        >
                            {/*{targetValue.maxTargetValue?*/}
                            {/*    <Tooltip*/}
                            {/*        title={targetValue?.maxTargetValue || ''}*/}
                            {/*        leaveDelay={0}*/}
                            {/*        enterDelay={0}*/}
                            {/*    >*/}
                            {/*        <span>{targetValue?.maxTargetValue}</span>*/}
                            {/*    </Tooltip>*/}
                            {/*    :*/}
                            <span>{initialValues?.maxTargetValue}</span>
                            {/*}*/}
                        </li>
                    </ul>
                </ListItemText>
            }
        </React.Fragment>
    );
};

export default React.memo(TargetValue);
