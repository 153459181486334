
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IFilter, IJoin, IOrder } from '../../../../core/interfaces';
import { IEmployeeState } from '../reducers/employeeReducer';
import { BeaconService, IBeaconIndentOnly } from '../../services/beaconService';
import {
    FetchBeaconsFailure,
    FetchBeaconsRequest,
    FetchBeaconsSuccess,
    DeleteBeaconAction,
    SaveBeaconAction, ClearBeaconError,
} from '../actions/beaconActions';
import { IBeacon } from '../../interfaces';
import { OpeningForm } from '../actions/settingPageActions';

export const BeaconThunks = {

    fetchBeacon: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    },  join?: IJoin, filter?: IFilter) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void>  => {

        const beaconService = new BeaconService();

        try {

            dispatch(new FetchBeaconsRequest());
            const beacons = await beaconService.list(search, order, join, filter);

            dispatch(new FetchBeaconsSuccess({ beacons }));
        } catch (errors) {

            const error = beaconService.errorHandler(errors);

            dispatch(new FetchBeaconsFailure({ error }));

            throw error;
        }
    },

    saveBeacon: (model: IBeacon) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        const service = new BeaconService();

        try {

            const beacon = await service.save(model);

            dispatch(new SaveBeaconAction({ beacon }));

            dispatch(new FetchBeaconsRequest());

            const beacons = await service.list('', {
                    column: 'id',
                    dir: 'asc',
                }, { table: ['employee'] });

            dispatch(new FetchBeaconsSuccess({ beacons }));

            dispatch(new OpeningForm({ formOpening: false, formName: '' }));

        } catch (errors) {

            const error = service.errorHandler(errors);

            dispatch(new FetchBeaconsFailure({ error }));


            throw error;
        }
    },

    deleteBeacon: (beacon: IBeaconIndentOnly) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new BeaconService();

            await service.delete(beacon);

            dispatch(new DeleteBeaconAction({ beacon }));

        } catch (error) {

            //nothing to do
        }
    },

    clearError: () => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        dispatch(new ClearBeaconError());
    },

};
