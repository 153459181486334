import { graphConstants } from '../../../../core/constants';
import { IGraphMinimapBrushAction, IGraphMinimapBrushState } from '../../../../core/interfaces';
import moment from 'moment';
import { getParamQuery, URLFormat } from '../../../../core/helpers/historyHalper';

/**
 * Getting data from the page session and URL query
 */
const dashboardOnline = JSON.parse(window.sessionStorage.getItem('pageReloaded') || 'false'),
    fromUrl = getParamQuery('from'),
    toUrl = getParamQuery('to'),
    from = fromUrl ? moment(fromUrl, URLFormat).toDate() : moment().subtract({ d: 1 }).toDate(),
    to = toUrl ? moment(toUrl, URLFormat).toDate() : moment().toDate();

/**
 * Graph Minimap brush reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphMinimapBrush(state: IGraphMinimapBrushState = {
    selection: dashboardOnline? [moment().subtract({ d: 1 }).toDate(), moment().toDate()]:[from, to],
    // selection: [moment().subtract({ d: 1 }).toDate(), moment().add({ m: 10 }).toDate()],
    brushRule: false,
    timerRule: false,
}, action: IGraphMinimapBrushAction): IGraphMinimapBrushState {

    switch (action.type) {

        case graphConstants.minimapBrush:

            return {
                ...state,
                selection: action.selection,
                brushRule: action.brushRule,
            };

        case graphConstants.minimapBrushTimerOnOff:

            return {
                ...state,
                timerRule: action.timerRule,
            };

        case graphConstants.minimapShifrRight:
        case graphConstants.minimapShiftLeft:
        case graphConstants.minimapSelectZoomOut:
        case graphConstants.minimapSelectZoomIn:
        case graphConstants.minimapSelectPeriod:

            return {
                ...state,
                selection: action.selection,
            };

        default:

            return state;
    }
}
