import React, { useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { IEditorTool } from '../interfaces';
import '../styles/MainMenu.scss';

interface IProps {
    tools: IEditorTool[];
    selectedTool: string | null;
    onSelect: (tool: IEditorTool) => void;
}

/**
 * Editor main menu component
 *
 * @class MainMenu
 */
export const MainMenu: React.FC<IProps> = ({
    tools,
    selectedTool,
    onSelect,
}: IProps) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openMenu = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {

        onSelect({
            id: '',
            superType: '',
            type: '',
            title: '',
        });

        setAnchorEl(event.currentTarget);

    }, [setAnchorEl]);

    const closeMenu = () => {
        
        setAnchorEl(null);
    };
    
    return (
        <div className="map-editor-menu">
            {tools.map((tool: IEditorTool, index: number) => {

                const key = 'editor-menu-tool-' + index;

                return (
                    <React.Fragment key={key}>
                        {tool.superType === 'label' &&
                        <span className="panel-label" style={tool.style}>{tool.title}</span>}
                        {tool.superType === 'separator' && <span className="panel-separator" />}
                        {tool.superType === 'drawing' && (
                            <IconButton
                                color="primary"
                                style={{ border: tool.id === selectedTool ? '1px solid black' : '1px solid transparent' }}
                                component="span"
                                title={tool.title}
                                onClick={() => onSelect(tool)}
                            >
                                {tool.icon}
                            </IconButton>
                        )}
                        {tool.superType === 'dropdown' && (
                            <React.Fragment>
                                <IconButton
                                    color="primary"
                                    component="span"
                                    style={{ border: anchorEl ? '1px solid black' : '1px solid transparent' }}
                                    onClick={openMenu}
                                >
                                    {tool.icon}
                                </IconButton>
                                {Boolean(anchorEl) && (
                                    <Menu
                                        className="editor-menu-dropdown"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={closeMenu}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        {tool.items && tool.items.map((item: IEditorTool, index: number) => {

                                            const key = 'editor-menu-dropdown-item-' + index;

                                            return (
                                                <MenuItem
                                                    key={key}
                                                    onClick={() => {

                                                        onSelect(item);
                                                        closeMenu();
                                                    }}
                                                    className="editor-menu-dropdown-list-item"
                                                >
                                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                                    <ListItemText>{item.title}</ListItemText>
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

