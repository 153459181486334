import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IOrder } from '../../../../../../core/interfaces';
import { connect } from 'react-redux';
import ListStickyHeader from '../../../../ui/components/List/ListStickyHeader';
import { IHrState } from '../../../../store/reducers';
import { selectEditorGateways } from '../../../../store/selectors/gatewaySelector';
import RightSidebar from '../../../../ui/components/Sidebar/RightSidebar';
import { ObjectHelper } from '../../../../../../core/helpers/objectHelper';
import { IData } from '../../../../../interfaces';
import { ReactComponent as Energy } from '../../../../ui/assets/images/icons/energy.svg';
import GatewayForm from './GatewayForm/GatewayForm';
import { SettingPageThunks } from '../../../../store/thunks/settingPageThunks';
import { IGatewayApiModel, IGatewayIndentOnly } from '../../../../services/gatewayService';
import { selectSettingPageState } from '../../../../store/selectors/settingPageSelector';
import DeleteDialog from '../../../../ui/components/Dialog/DeleteDialog';
import { gatewayThunks } from '../../../../store/thunks/gatewayThunks';
import './GatewayTable.scss';

import moment from 'moment';
import Tooltip from '../../../../ui/components/Tooltip/Tooltip';

interface IProps {
    gateways: IGatewayApiModel[];
    toggleForm: (formOpening: boolean, formName?: string, model?: IGatewayApiModel) => void;
    formOpening?: boolean;
    formName?: string;
    deleteGateway: (gateway: IGatewayIndentOnly) => void;
}


interface IState {
    search: string | null
    order: IOrder | null;
    dialogOpened: boolean;
    filterKey: string | null;
    filterOrder: 'asc' | 'desc';
    selectedFilters: Record<string, unknown>;
    removeId: IData | null;
    formOpened: boolean
}

class GatewayTable extends React.Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t } = this.props;
        const contextMenu: string | any[] = [
            {
                title: t('EDIT'),
                action: this.editState.bind(this),
                color: '',
            },
            {
                title: t('DELETE'),
                action: this.removeConfirmation.bind(this),
                color: 'red',
                disabled: (row: IData) => {

                    return !row.id;
                },
            },
        ];

        this.columns = [
            {
                name: 'name',
                label: t('NAME'),
                sortable: true,
                width: '20%',
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    return (
                        <Tooltip
                            disableTouchListener
                            title={`${t('TYPE')}: ${row?.type ? row.type : t('NOT_SET')}, ${t('FW_VERSION')}: ${row?.version ? row.version : t('NOT_SET')}`}
                        >
                            <span className={'name'}>
                                {row.name ? row.name : row.zone ? row.zone.name : null}
                            </span>
                        </Tooltip>

                    );
                },
            },
            {
                name: 'id',
                label: t('ID/BLE-MAC'),
                sortable: true,
                width: '20%',
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    return (
                        <React.Fragment>
                            <span>{row.id}</span><br />
                            <span>{row.bleMac ? `${row.bleMac}` : ''}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                name: 'batteryLevel',
                label: <Energy />,
                sortable: true,
                width: '8%',
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    return `${row.batteryLevel || 0}%`;
                },
            },
            {
                name: 'lastSeen',
                label: t('LAST_ACTIVITY'),
                sortable: true,
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    return row.lastSeen ? moment(row.lastSeen).format('DD.MM.YYYY HH:mm') : null;
                },
                width: '20%',
            },
            {
                name: 'zone',
                label: t('LINKED_ZONE'),
                sortable: true,
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    return row.zone ? row.zone.name : null;
                },
                width: '32%',
                menu: contextMenu.length ? contextMenu : undefined,
            },
        ];

        this.state = {
            search: null,
            order: {
                column: 'id',
                dir: 'desc',
            },
            dialogOpened: false,
            filterKey: null,
            filterOrder: 'asc',
            selectedFilters: {
                stateCategoryName: [],
                causeName: [],
            },
            removeId: null,
            formOpened: false,
        };

        this.setSearchOrder = this.setSearchOrder.bind(this);

        this.getFilteredData = this.getFilteredData.bind(this);

        this.onConfirmDeleteLayer = this.onConfirmDeleteLayer.bind(this);

        this.onCloseDeleteDialog = this.onCloseDeleteDialog.bind(this);

    }


    private readonly columns: (
        { mutate: (value: undefined, row: IData) => React.ReactNode; name: string; width: string; label: string | any; sortable: boolean } |
        { name: string; width: string; action: { handler: () => void; icon: React.ReactNode }; label: string | any; sortable: boolean } |
        { name: string; width: string; label: string | any; sortable: boolean; menu: any[] | undefined })[];


    /**
     * Open edit state form and fill it with data
     *
     * @param {IData} gateway
     */
    editState(gateway: IData) {

        this.setState({ formOpened: true });

        this.props.toggleForm(true, 'gateway', gateway as IGatewayApiModel);

    }

    /**
     * Confirm remove state
     *
     * @param {IData} state
     */
    removeConfirmation(state: IData) {

        this.setState({
            removeId: state,
        });
    }

    /**
     * Set search and order settings
     *
     * @param {string} search
     * @param {IOrder} order
     */
    setSearchOrder(search: string, order: IOrder) {

        this.setState({
            search: search,
            order: order,
        });
    }

    /**
     * Show filter dialog
     *
     * @param {string} column
     */
    showFilter(column: string) {

        this.setState({
            filterKey: column,
            dialogOpened: true,
        });
    }

    /**
     * Apply filters and order settings to data
     *
     * @return {IData[]}
     */
    getFilteredData() {

        let data = [...this.props.gateways];

        const { search, order } = this.state;

        if (order && order.column) {

            const paths: Record<string, string> = {
                'name': 'name',
                'id': 'id',
                'batteryLevel': 'batteryLevel',
                'lastSeen': 'lastSeen',
                'zone': 'zone.name',
            };

            data.sort((a: IData, b: IData) => {

                const valueA = ObjectHelper.getPathValue(paths[order.column], a),
                    valueB = ObjectHelper.getPathValue(paths[order.column], b);

                if ((typeof valueA === 'string' && typeof valueB === 'string' ?
                    String(valueA).toLowerCase() < String(valueB).toLowerCase() :
                    valueA < valueB) || !valueA) {

                    return order.dir === 'asc' ? -1 : 1;
                }

                if ((typeof valueA === 'string' && typeof valueB === 'string' ?
                    String(valueA).toLowerCase() > String(valueB).toLowerCase() :
                    valueA > valueB) || !valueB) {

                    return order.dir === 'asc' ? 1 : -1;
                }

                return 0;
            });
        }

        if (search) {

            data = data.filter((d: IData) => d.name && d.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        }

        return data;
    }

    /**
     *
     */
    onConfirmDeleteLayer() {

        const { removeId } = this.state;

        this.props.deleteGateway(removeId as IGatewayIndentOnly);

        this.setState({ removeId: null });
    }

    /**
     *
     */
    onCloseDeleteDialog() {

        this.setState({ removeId: null });

    }


    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { formOpening, formName, t } = this.props;

        return (
            <div className="setting-page-gateway-table">
                <ListStickyHeader
                    columns={this.columns}
                    data={this.getFilteredData()}
                    refresh={this.setSearchOrder}
                    searchable
                    selectedParam={'id'}
                    searchableField={'name'}
                    searchableField2={'id'}
                    maxHeightSlider={'calc(100vh - 260px)'}
                />
                <RightSidebar openSidebar={formOpening}>
                    {formName === 'gateway'?
                        <GatewayForm />
                        :
                        null
                    }
                </RightSidebar>
                {this.state.removeId ?
                    <DeleteDialog
                        open
                        removeId={this.state.removeId.id}
                        heading={t('DELETE_GATEWAY_Q', { name: this.state.removeId.name })}
                        body={t('THIS_ACTION_WILL_DELETE_GATEWAY_AND_CANNOT_BE_UNDONE')}
                        onAccept={this.onConfirmDeleteLayer}
                        onClose={this.onCloseDeleteDialog}
                    />
                    :
                    null}
            </div>
        );
    }

}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: IHrState) => {

    const gateways = selectEditorGateways(state);

    const { formOpening, formName, model } = selectSettingPageState(state);

    return {
        gateways,
        formOpening,
        formName,
        model,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    toggleForm: SettingPageThunks.toggleForm,
    deleteGateway: gatewayThunks.deleteGateway,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GatewayTable));
