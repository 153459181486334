import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { MenuProps } from '@material-ui/core/Menu';
import { HmiSchemaAction, HmiPlayerActions } from '../../../core/actions';
import { selectAllHmiScheme } from '../../../core/selectors/hmiSchemas/hmiSchemeSelector';
import { IHmiSchema } from '../../../core/interfaces';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { SearchInput } from '../../../core/ui/components';
import { selectHmiPlayerSchema, selectHmiPlayerSchemaDefault } from '../../../core/selectors/hmi/playerSelector';
import Scrollbar from 'react-scrollbars-custom';
import './styles/ButtonMenu.scss';
import { LayoutActions } from '../../../core/actions/layout';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { GraphActions } from '../../../base/store/actions';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from '../../../core/selectors/auth/authSelector';
import { appConfig } from '../../../config/appConfig';

interface IProps {
    loadSchemas: () => void;
    setSchema: (schema: IHmiSchema, defaultSchema?: boolean) => void;
    loadPicture: (schema: IHmiSchema) => void;
    handleClose: () => void;
    drawerToggleFullScreen: (fullScreen: boolean) => void;
}

/**
 * HMI button menu component
 *
 * @param {function} loadSchemas
 * @param {function} setSchema
 * @param {function} loadPicture
 * @param {function} handleClose
 * @param drawerToggleFullScreen
 * @param {object} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const ButtonMenu: FC<IProps & MenuProps> = (
    {
        loadSchemas,
        setSchema,
        loadPicture,
        handleClose,
        drawerToggleFullScreen,
        ...props
    }: IProps & MenuProps,
): JSX.Element => {

    const schemas = useSelector(selectAllHmiScheme);
    const selectedSchemas = useSelector(selectHmiPlayerSchema);
    const selectedSchemasDefault = useSelector(selectHmiPlayerSchemaDefault);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [filter, setFilter] = useState('');


    /**
     * The method that, when first loaded, opens the HMI circuit on the monitoring page
     *
     * @type {IUser}
     */
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        if (schemas.length > 0 && appConfig.autoOpenHmiPlayer) {

            loadPicture(selectedSchemasDefault? selectedSchemasDefault: schemas[0]);
            setSchema(selectedSchemasDefault? selectedSchemasDefault : schemas[0], true);
            handleClose();
            dispatch(GraphActions.toggleHMI(false));
            dispatch(GraphActions.barToggleTableView(true, 0));

            if (isMobileOnly && !isTablet) {
                drawerToggleFullScreen(true);
            }
        }

    }, [user, schemas, selectedSchemasDefault, appConfig]);
    /**
     * The method that, when first loaded, opens the HMI circuit on the monitoring page
     * END
     */

    useEffect(() => {

        if (!schemas.length) {

            loadSchemas();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * clearing the filter after closing the list
     */
    useEffect(() => {

        const { anchorEl } = props;

        const setFilterTimeout = setTimeout(() => {

            if (!anchorEl && filter.trim().length > 0) {

                setFilter('');

                clearTimeout(setFilterTimeout);
            }

        }, 200);


        return () => clearTimeout(setFilterTimeout);

    }, [props, filter, setFilter]);


    /**
     * Choose a schema for HMI player
     *
     * @param {IHmiSchema} schema
     */
    const chooseSchema = useCallback((schema: IHmiSchema) => {

        loadPicture(schema);
        setSchema(schema, true);
        handleClose();
        dispatch(GraphActions.toggleHMI(false));
        dispatch(GraphActions.barToggleTableView(true, 0));

        if (isMobileOnly && !isTablet) {
            drawerToggleFullScreen(true);
        }
    }, [loadPicture, setSchema, handleClose, dispatch, isMobileOnly, drawerToggleFullScreen]);

    /**
     * search handler
     *
     * @type {(event) => void}
     */
    const searchHandler = useCallback((event) => {

        setFilter(event.toLowerCase());

    }, [setFilter]);

    /**
     * Stop propagation
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const stopPropagation = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.stopPropagation();
    }, []);

    return (
        <>
            <Popover
                {...props}
                keepMounted={false}
                elevation={0}
                getContentAnchorEl={null}
                onMouseMove={stopPropagation}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        maxHeight: '260px',
                        border: '1px solid #d3d4d5',
                        padding: '10px 5px 10px 10px',
                    },
                }}
            >
                <Scrollbar style={{ height: 210, width: 260 }} className="button-menu-scroll-container">
                    <List className="button-menu">
                        <ListSubheader>
                            <SearchInput placeholder={t('SEARCH')} options={{}} searchHandler={searchHandler} />
                        </ListSubheader>
                        {schemas.filter(value => value.name.toLocaleLowerCase().includes(filter)).map((schema: IHmiSchema) => (
                            <ListItem
                                key={schema.id}
                                selected={selectedSchemas?.id === schema.id}
                                onClick={() => chooseSchema(schema)}
                            >
                                {schema.name}
                            </ListItem>
                        ))}
                    </List>
                </Scrollbar>
            </Popover>
        </>
    );
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    loadSchemas: HmiSchemaAction.list,
    setSchema: HmiPlayerActions.setSchema,
    loadPicture: HmiSchemaAction.getPicture,
    drawerToggleFullScreen: LayoutActions.drawerToggleFullScreen,
});

export default connect(null, mapDispatchToProps)(ButtonMenu);
