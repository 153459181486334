import React, { useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TextInput } from '../../../core/ui/components';
import { IError } from '../../../core/interfaces';

interface IOldPasswordField extends WithTranslation{
    touchedProps: Record<string, unknown>;
    errorsProps: Record<string, unknown>;
    errors: IError;
    handleBlur: (event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>void;
    value: Record<string, unknown>;
    handleChange: (event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>void;
    typingPassword:() => void;
}

const OldPasswordField: React.FC<IOldPasswordField> = ({ touchedProps, errorsProps, errors, handleBlur, value, handleChange, typingPassword, ...props }:IOldPasswordField) => {

    const onChangeCallback= useCallback((event) => {

        handleChange(event);

        typingPassword();

    }, [handleChange, typingPassword]);

    const { t } = props;

    return (
        <TextInput
            className={
                'form-field '
                +
                (touchedProps.old_password ? (errorsProps.old_password || errors.common) ? 'error-field' : 'success-field' : '')
            }
            onChange={onChangeCallback}
            onBlur={handleBlur}
            value={value.old_password}
            name="old_password"
            type="password"
            placeholder={t('OLD_PASSWORD')}
        >
            {((touchedProps.old_password && (errorsProps.old_password || errors.common))) &&
            <div
                className="validation-massage"
            >
                {errorsProps.old_password} {errors.common && !errorsProps.old_password && t('OLD_PASSWORD_IS_NOT_CORRECT')}
            </div>
            }
        </TextInput>
    );
};

export default withTranslation()(OldPasswordField);