import { createSelector } from 'reselect';
import { RootState } from '../../store';


export const selectSelectedDashboard = createSelector(
    (state: RootState) => state.dashboardSelect.selectedDashboard,
    (selectedDashboard) => selectedDashboard,
);

export const selectDashboardSelected = createSelector(
    (state: RootState) => state.dashboardSelect.dashboardSelected,
    (dashboardSelected) => dashboardSelected,
);
