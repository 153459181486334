import React from 'react';
import PanToolIcon from '@material-ui/icons/PanTool';
import IconButton from '@material-ui/core/IconButton';
import CachedIcon from '@material-ui/icons/Cached';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Slider from '@material-ui/core/Slider';

export const FormHmiTools: React.FC<IProps> = ({
    onReset,
    onGrab,
    onZoom,
    onCrop,
    zoom,
    minZoom,
    maxZoom,
    grab,
    select,
}: IProps) => {

    return (
        <div className="action-section">
            <div className="action-btn-list">
                <IconButton
                    className="reset-tool"
                    onClick={onReset}
                >
                    <CachedIcon />
                </IconButton>
                <IconButton
                    className={`grab-tool ${grab ? 'active' : ''}`}
                    onClick={onGrab}
                >
                    <PanToolIcon />
                </IconButton>
                <IconButton
                    className={`crop-tool ${select ? 'active' : ''}`}
                    onClick={onCrop}
                >
                    <CropFreeIcon />
                </IconButton>
            </div>
            <div className="slider">
                <Slider
                    value={zoom}
                    min={minZoom}
                    max={maxZoom}
                    onChange={onZoom}
                    track={false}
                    step={0.001}
                />
            </div>
        </div>
    );
};

interface IProps {
    onReset: (e: any) => void;
    onGrab: () => void;
    onZoom: (e: any, val: any) => void,
    onCrop: () => void,
    zoom: number;
    minZoom: number;
    maxZoom: number;
    grab: boolean;
    select: boolean;
}