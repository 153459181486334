import React, { RefObject } from 'react';
import Cropper from 'react-cropper';
import { Formik, FormikProps } from 'formik';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import ReplayIcon from '@material-ui/icons/Replay';
import { DropzoneArea } from 'material-ui-dropzone';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';
import { readFile } from '../../../base/helpers/readFile';
import { Button, CommonError, FileUpload, TextInput } from '../../../core/ui/components';
import { ReactComponent as FunctionIcon } from '../../../core/ui/assets/images/icons/function-v2.svg';
import { IError, IHmiSchema } from '../../../core/interfaces';
import 'cropperjs/dist/cropper.css';
import './FormHmi.scss';
import { FormHmiTools } from './FormHmiTools';
import { Theme } from '@material-ui/core';
import { connect } from 'react-redux';
import { HmiSchemaAction } from '../../../core/actions';
import { RootState } from '../../../core/store';
import { appConfig } from '../../../config/appConfig';
import { blobToFile } from '../../../helpers/blobToFile';
import { Spinner } from '../../../modules/Hr/ui/components/Spinner/Spinner';

interface IFormValues {
    id?: number;
    name: string;
    picture: string | File;
}

interface IImageData {
    width: number;
    height: number;
    naturalWidth: number;
    naturalHeight: number;
}

interface IProps {
    label?: string;
    errors?: IError;
    openSidebar: boolean;
    toggleForm: (opened: boolean, name?: string) => void;
    storeSchema: (data: IHmiSchema) => void;
    updateSchema: (data: IHmiSchema) => void;
    resetSchema: (data: IHmiSchema, reset: boolean) => void;
    editSchema: (data: IHmiSchema, reset: boolean, picture: Blob) => void;
    schema: IHmiSchema | null;
    picture: File;
    schemas: IHmiSchema[];
    getSchema: (data: IHmiSchema) => void;
}

interface IState {
    initialValues: IFormValues;
    enableReinitialize: boolean;
    pictureContent: string | null;
    grab: boolean;
    select: boolean;
    zoom: number | null;
    loadingImage: boolean;
    newPhoto: boolean;
}

/**
 * The HMI form component
 *
 * @class FormHmi
 */
class FormHmi extends React.Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t } = props;

        this.validationSchema = yup.object().shape({
            name: yup.string()
                .trim()
                .max(30, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '30' }))
                .test('file-empty', t('THE_HMI_SCHEMA_HAS_ALREADY_BEEN_TAKEN'), (val) => this.checkNameScheme(val))
                .required(t('HMI_NAME_IS_REQUIRED')),
            picture: yup.mixed()
                .test('file-empty', t('HMI_IMAGE_IS_REQUIRED'), this.checkImage),
        });

        this.cropperRef = React.createRef();

        this.firstInitScale = null;

        this.handleSubmit = this.handleSubmit.bind(this);

        this.closeSidebar = this.closeSidebar.bind(this);

        this.onDropZoneFileUpload = this.onDropZoneFileUpload.bind(this);

        this.onFileUpload = this.onFileUpload.bind(this);

        this.onDropRejected = this.onDropRejected.bind(this);

        this.onReset = this.onReset.bind(this);

        this.onGrab = this.onGrab.bind(this);

        this.onZoom = this.onZoom.bind(this);

        this.onSelect = this.onSelect.bind(this);

        this.onReady = this.onReady.bind(this);

        this.zoom = this.zoom.bind(this);

        this.onCrop = this.onCrop.bind(this);

        this.checkMime = this.checkMime.bind(this);

        this.checkSize = this.checkSize.bind(this);
    }

    /**
     * Current state
     */
    readonly state: IState = {
        initialValues: {
            name: '',
            picture: '',
        },
        enableReinitialize: true,
        pictureContent: null,
        grab: true,
        select: false,
        zoom: null,
        loadingImage: true,
        newPhoto: false,
    };

    componentDidMount() {

        const getImagePictureTimeout = setTimeout(() => {

            this.getImagePicture();

            clearTimeout(getImagePictureTimeout);

        }, 50);

    }

    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>) {

        if (this.props.schema && this.props.schema.scale && this.props.schema.scale !== prevProps.schema?.scale) {
            this.initZoom = this.props.schema.scale;
            this.setState({ zoom: this.props.schema.scale });
        }

        if (!this.initZoom && this.state.zoom && !this.props.schema?.scale) {

            // this.initZoom = this.state.zoom;
        }

        if (this.props.schema && this.props.schema.id !== prevProps.schema?.id) {

            this.getImagePicture();
        }
    }

    componentWillUnmount() {

        this.resetSchemaAction();

        this.setState(this.state);
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IFormValues>>;

    /**
     * Image cropper reference
     *
     * @type {RefObject<HTMLImageElement>}
     */
    private readonly cropperRef: RefObject<HTMLImageElement>;

    /**
     * @type {number}
     */
    private readonly maxFileSize = 10000000;

    /**
     * @type {string}
     */
    private readonly acceptMime = 'image/png,image/jpeg,image/bmp';

    /**
     * @type {number}
     */
    private readonly minZoom = 0.0001;

    /**
     * @type {number}
     */
    private readonly maxZoom = 5;

    /**
     * @type {number}
     */
    private initZoom = 0;

    /**
     * First init scale.
     *
     * @type {number | null}
     * @private
     */
    private firstInitScale: null | number;

    /**
     * Get current image
     */
    getImagePicture() {

        const { schema, getSchema } = this.props;

        if (schema) {
            getSchema(schema);
            this.setState({
                initialValues: schema,
                pictureContent: appConfig.hrApiEndpoint + `/hmi-schemas/${schema.id}/picture`,
            });
        }
    }

    /**
     *  Handler submit form
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        const { schema } = this.props,
            { select, newPhoto, initialValues } = this.state;
        const imageElement: any = this.cropperRef.current;

        const cropper: any = imageElement?.cropper;

        if (cropper) {

            const dataUrl = cropper.getCroppedCanvas().toDataURL();

            const sendScale = this.state.zoom !== this.firstInitScale || !schema ? this.state.zoom : schema!.scale;

            cropper.getCroppedCanvas().toBlob((blob: Blob) => {

                const newValues = values;
                newValues.picture = dataUrl;
                this.props.editSchema({ ...schema, ...newValues as IHmiSchema, scale: sendScale }, false, dataUrl);

                if (!schema?.id) {

                    blobToFile(blob, values.picture as string).then(value => {

                        this.props.storeSchema({
                            ...values as IHmiSchema,
                            picture: value,
                            opacity: 1,
                            showMinimap: '1',
                            showObjects: '1',
                            scale: sendScale !== 0 ? sendScale : 1,
                        });
                    });

                } else {

                    blobToFile(blob, schema.picture as string).then(value => {

                        const { name } = initialValues;

                        if (select || newPhoto || name !== values.name) {

                            this.props.updateSchema({
                                ...schema, ...newValues as IHmiSchema,
                                picture: value,
                                showMinimap: '1',
                                showObjects: '1',
                                scale: sendScale !== 0 ? sendScale : 1,
                            });
                        } else {

                            this.props.updateSchema({
                                ...schema, ...newValues as IHmiSchema,
                                scale: sendScale !== 0 ? sendScale : 1,
                            });
                        }
                    });
                }
            });
        }


        this.props.toggleForm(false, 'hmi-editor');
    }

    /**
     * Sidebar close handler
     */
    closeSidebar(): void {
        const { toggleForm } = this.props;

        toggleForm(this.props.openSidebar);

        this.resetSchemaAction();
    }

    resetSchemaAction() {

        const { resetSchema, schema } = this.props;

        if (schema) {

            resetSchema(schema, true);
        }
    }

    /**
     * Handle HMI picture upload via drop zone
     *
     * @param {File[]} files
     * @param props
     */
    onDropZoneFileUpload(files: File[], props: FormikProps<IFormValues>) {

        const [file] = files;

        if (file) {

            this.onFileUpload(file, props);
        }
    }

    /**
     * On drop rejection
     *
     * @param {Files} files
     * @param props
     * @returns {void}
     */
    onDropRejected(files: File[], props: FormikProps<IFormValues>): void {

        const [file] = files;

        const { t } = this.props;

        const errors: string[] = [];
        
        if (!this.checkMime(file)) {
            const message = t('DROPZONE_ERROR_ACCEPTABLE_FORMATS_FOR_UPLOADING_FORMAT', {
                format: this.acceptMime.replace(/image\//g, ' '),
            });

            errors.push(message);
        }

        if (!this.checkSize(file)) {
            const message = t('DROPZONE_ERROR_FILE_MUST_NOT_EXCEED_SIZE_MB', {
                size: this.maxFileSize / 1000000,
            });

            errors.push(message);
        }

        if (!errors.length) {
            props.setFieldValue('picture', file);        
        } else {
            const [error] = errors;

            props.setFieldError('picture', error);
        }

        // third param responsible for revalidate field
        props.setFieldTouched('picture', true, !errors.length);
    }

    /**
     * @returns {boolean}
     */
    checkImage(value: File): boolean {
        return !!value;
    }

    /**
     * Checking if a name is busy
     */
    checkNameScheme(valueField: string): boolean {

        const { schemas, schema } = this.props;

        const schemaName = schemas.find(value => valueField &&
            value.name.trim().toLocaleLowerCase() === valueField.trim().toLocaleLowerCase());

        return !schemaName || !(schemaName?.id !== schema?.id);
    }

    /**
     * Check file mime type is accept
     * 
     * @param {File} file
     *
     * @returns {boolean}
     */
    checkMime(file: File): boolean {

        return file && this.acceptMime.split(',').includes(file.type);
    }

    /**
     * Check file size is not more that max file size
     * 
     * @param {File} file
     *
     * @returns {boolean}
     */
    checkSize(file: File): boolean {

        return file && file.size < this.maxFileSize;
    }

    /**
     * Calc zoom scale
     * 
     * @param {IImageData} imageData 
     * @returns {number}
     */
    calcZoomScale(
        imageData: IImageData,
    ): number {

        const { width, height, naturalWidth, naturalHeight } = imageData;

        const naturalHypot = Math.hypot(naturalHeight, naturalWidth);

        const sizeHypot = Math.hypot(height, width);

        const zoom = sizeHypot / naturalHypot;

        return zoom;
    }

    /**
     * On reset method
     * 
     * @param {any} e
     * @returns {void}
     */
    onReset(e: any): void {

        e.preventDefault();

        if (this.cropperRef?.current) {

            const imageElement: any = this.cropperRef?.current;
            const cropper: any = imageElement?.cropper;

            cropper.setDragMode('crop').clear().reset();

            const { imageData } = cropper;

            const zoom = this.calcZoomScale(imageData);

            this.setState({
                zoom: zoom,
                grab: false,
                select: true,
            });
        }
    }

    /**
     * On zoom method slider
     * 
     * @param {any} e 
     * @param {any} val 
     * @returns {void}
     */
    onZoom(e: any, val: any): void {

        if (this.cropperRef?.current && !this.state.loadingImage) {

            const imageElement: any = this.cropperRef?.current;

            const cropper: any = imageElement?.cropper;

            const containerData = cropper.getContainerData();

            this.setState({ zoom: val });

            cropper.zoomTo(val, {
                x: containerData.width / 2,
                y: containerData.height / 2,
            });
        }
    }

    /**
     * On grab handler 
     * 
     * @returns {void}
     */
    onGrab(): void {

        if (this.cropperRef?.current) {

            const imageElement: any = this.cropperRef?.current;

            const cropper: any = imageElement?.cropper;

            const { grab } = this.state;

            cropper.setDragMode(grab ? 'crop' : 'move');

            this.setState({ grab: !grab, select: false });
        }
    }

    /**
     * On select action
     * 
     * @param {CustomEvent} event
     * @returns {void} 
     */
    onSelect(event: CustomEvent): void {

        const { grab } = this.state;

        if (event?.detail.width && event?.detail.height && !grab) {
            this.setState({ select: true });
        }
    }

    /**
     * @returns {void}
     */
    onReady(): void {

        const image = this.cropperRef.current as any;

        const cropper = image.cropper as any;

        const { schema } = this.props;

        if (cropper) {

            const { initialValues } = this.state;

            cropper.getCroppedCanvas().toBlob((blob: Blob) => {

                if (initialValues.picture) {
                    blobToFile(blob, initialValues.picture as string).then(value => {

                        this.setState({
                            ...this.state,
                            initialValues: { ...initialValues, picture: value as unknown as File },
                            loadingImage: false,
                        });
                    });

                } else {
                    this.setState({
                        loadingImage: false,
                    });
                }
            });

            cropper.setDragMode('move');
        }

        if (schema) {
            const cropperZoomToData = (schema?.scale !== null 
                ? schema.scale === 0 
                    ? 0.5 : schema.scale
                : null) || this.minZoom;

            cropper.zoomTo(cropperZoomToData);
        } else if (cropper) {
            const { imageData } = cropper;

            const zoom = this.calcZoomScale(imageData);

            cropper.zoomTo(zoom);
        }
    }

    /**
     * On crop cropper box
     * 
     * @returns {void}
     */
    onCrop(): void {

        if (this.cropperRef?.current) {

            const imageElement: any = this.cropperRef?.current;

            const cropper: any = imageElement?.cropper;

            cropper.setDragMode('crop');

            this.setState({ select: true, grab: false });
        }
    }

    /**
     * Zoom handler cropper 
     * 
     * @param {Cropper.ZoomEvent} e 
     * @returns {void}
     */
    zoom(e: Cropper.ZoomEvent): void {

        if (e.detail.ratio > this.maxZoom || e.detail.ratio < this.minZoom) {
            e.preventDefault();
        }

        if (!this.firstInitScale) {

            this.firstInitScale = e.detail.ratio;
        }

        this.setState({ zoom: e.detail.ratio });
    }

    /**
     * Handle HMI picture upload via file input
     *
     * @param {File} file
     * @param {FormikProps<IFormValues>} props
     */
    async onFileUpload(file: File, props: FormikProps<IFormValues>) {
        const { t } = this.props;

        const errors: string[] = [];

        if (!this.checkMime(file)) {
            const message = t('DROPZONE_ERROR_ACCEPTABLE_FORMATS_FOR_UPLOADING_FORMAT', {
                format: this.acceptMime.replace(/image\//g, ' '),
            });

            errors.push(message);
        }

        if (!this.checkSize(file)) {
            const message = t('DROPZONE_ERROR_FILE_MUST_NOT_EXCEED_SIZE_MB', {
                size: this.maxFileSize / 1000000,
            });

            errors.push(message);
        }

        if (!errors.length) {

            const initialValues = {
                picture: file,
                name: props.values.name,
            };

            const pictureContent = await readFile(file, false);

            this.setState({
                pictureContent: pictureContent,
                newPhoto: true,
                initialValues,
            });
            
        } else {

            this.setState({
                pictureContent: null,
                newPhoto: false,
            });

            const [error] = errors;

            props.setFieldValue('picture', '', false);

            props.setFieldError('picture', error);
        }

        // third param responsible for revalidate field
        props.setFieldTouched('picture', true, !errors.length);
    }

    /**
     * DropZone theme
     * 
     * @param {bool} invalid
     * @returns {Theme}
     */
    dropZoneTheme(invalid = false): Theme {
        return createTheme({
            overrides: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                MuiDropzoneArea: {
                    root: {
                        border: '1px dashed',
                        borderColor: invalid ? '#ff3b30' : '#4092F5',
                        borderRadius: '10px',
                        backgroundColor: '#fbfcfe',
                    },
                    text: {
                        marginTop: '74px',
                        color: invalid ? '#ff3b30' : '#c4c6c7',
                        fontSize: '18px',
                        fontFamily: '"IBM Plex Sans", Arial, Helvetica, sans-serif',
                        whiteSpace: 'pre-line',
                        padding: '0 10px',
                    },
                    icon: {
                        color: invalid ? '#ff3b30' : '#4092F5',
                    },
                },
            },
        });
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { errors = {}, t, schema } = this.props;

        const { pictureContent, grab, select, zoom, loadingImage } = this.state;

        return (
            <React.Fragment>
                <div className={'form-box form-box-space node-form hmi-form' + (pictureContent ? ' wide' : '')}>
                    <Formik
                        initialValues={this.state.initialValues}
                        enableReinitialize
                        validationSchema={this.validationSchema}
                        onSubmit={this.handleSubmit}
                    >
                        {props => (
                            <form onSubmit={props.handleSubmit} noValidate>
                                <div className="section wrap-form-node">
                                    <div className="table-header">
                                        <div className="title">{t(schema ? 'EDIT_HMI' : 'ADD_HMI')}</div>
                                        <FunctionIcon className={'title-icon'} />
                                    </div>
                                    <CommonError errors={errors} />
                                    <div className="table-body">
                                        <div className="form-group">
                                            <TextInput
                                                className={
                                                    'form-field '
                                                    +
                                                    (props.touched.name || errors.field === 'name' ?
                                                        props.errors.name || errors.field === 'name' ? 'error-field'
                                                            : 'success-field' : ''
                                                    )
                                                }
                                                label={this.props.t('NAME')}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.name}
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder={this.props.t('HMI_NAME')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                {props.touched.name && (props.errors.name || errors.field === 'name') &&
                                                    <div className="validation-massage">{props.errors.name}</div>
                                                }
                                            </TextInput>
                                        </div>
                                        <div>
                                            <label className="upload-hmi-label">{t('UPLOAD_THE_HMI_IMAGE')}</label>
                                            <hr />
                                        </div>
                                        {!pictureContent &&
                                            (<div className="upload-block">
                                                <div>
                                                    <MuiThemeProvider theme={this.dropZoneTheme(props.errors.picture && props.touched.picture ? true : false)}>
                                                        {/*
                                                            TS Ignore due to https://github.com/Yuvaleros/material-ui-dropzone/issues/246
                                                            TODO: Remove after the bug fix release
                                                        */}
                                                        {/*@ts-ignore*/}
                                                        <DropzoneArea Icon={props.errors.picture && props.touched.picture ? ReplayIcon : FolderIcon}
                                                            multiple={false}
                                                            showAlerts={false}
                                                            maxFileSize={this.maxFileSize}
                                                            showPreviews={false}
                                                            showPreviewsInDropzone={false}
                                                            acceptedFiles={this.acceptMime.split(',')}
                                                            dropzoneText={props.errors.picture ? props.errors.picture : t('DRAG_AND_DROP_YOUR_FILES_HERE')}
                                                            onChange={(files) => this.onDropZoneFileUpload(files, props)}
                                                            onDropRejected={(files) => this.onDropRejected(files, props)}
                                                        />
                                                    </MuiThemeProvider>
                                                </div>
                                                <div className="drop-upload-separator">
                                                    -- {t('OR').toUpperCase()} --
                                                </div>
                                                <div className="form-field file-field">
                                                    <FileUpload
                                                        id="upload-hmi-image"
                                                        name={t('UPLOAD_THE_IMAGE')}
                                                        accept={this.acceptMime}
                                                        onFileUpload={(file) => this.onFileUpload(file, props)}
                                                    />
                                                </div>
                                             </div>)
                                        }
                                        {pictureContent &&
                                            (<div className="preview-block">
                                                <Spinner active={loadingImage} />
                                                <Cropper
                                                    src={pictureContent}
                                                    checkOrientation={false}
                                                    ref={this.cropperRef}
                                                    className="cropper"
                                                    autoCrop={false}
                                                    background={false}
                                                    crop={this.onSelect}
                                                    ready={this.onReady}
                                                    zoom={this.zoom}
                                                />
                                                <FormHmiTools
                                                    onReset={this.onReset}
                                                    onGrab={this.onGrab}
                                                    onZoom={this.onZoom}
                                                    onCrop={this.onCrop}
                                                    zoom={zoom || 0}
                                                    minZoom={this.minZoom}
                                                    maxZoom={this.maxZoom}
                                                    grab={grab}
                                                    select={select}
                                                />
                                             </div>)
                                        }
                                        <div className="btn-row">
                                            {pictureContent &&
                                                (<FileUpload
                                                    id="choose-different-hmi-image"
                                                    name={t('CHOOSE_DIFFERENT_IMAGE')}
                                                    accept={this.acceptMime}
                                                    onFileUpload={(file) => this.onFileUpload(file, props)}
                                                />)
                                            }
                                            <div className="form-group btn-group">
                                                <Button
                                                    id="cancel"
                                                    type="reset"
                                                    color="primary"
                                                    onClick={this.closeSidebar}
                                                >
                                                    {t('CANCEL')}
                                                </Button>
                                                <Button
                                                    id="next"
                                                    type="submit"
                                                    color="secondary"
                                                    disabled={loadingImage}
                                                >
                                                    {t('NEXT')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => {

    const { hmiSchemaFormState, hmiState } = state;
    const { schema, picture } = hmiSchemaFormState;
    const { schemas } = hmiState;

    return {
        schema,
        schemas,
        picture,
        errors: hmiSchemaFormState.errors,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    storeSchema: HmiSchemaAction.store,
    updateSchema: HmiSchemaAction.update,
    resetSchema: HmiSchemaAction.editSchema,
    editSchema: HmiSchemaAction.editSchema,
    getSchema: HmiSchemaAction.getSchema,
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormHmi)));
