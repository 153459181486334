import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AuthService, UserService } from '../services';
import { userConstants } from '../constants';
import { history } from '../../helpers';
import { IUser, IErrors, IOrder, IAuthState, IUserChangeState, IUserListState } from '../interfaces';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * User related actions
 *
 * @type {Object}
 */
export const UserActions = {

    /**
     * User login action
     *
     * @param {String} email User email
     * @param {String} password User password
     *
     * @return {Promise<Object>}
     */
    login: (email: string, password: string):
        (dispatch: ThunkDispatch<IAuthState, void, AnyAction>) => void => {

        //Action creators
        const success = (user: IUser) => {

            return {
                type: userConstants.LOGIN_SUCCESS,
                user,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: userConstants.LOGIN_FAILURE,
                errors,
            };

        }, service = new AuthService();


        return (dispatch) => {

            const authErrorHandler = (errors: AxiosError): IErrors => {

                if (errors.response?.status === 401) {

                    return { errors: { common: 'LOGIN_OR_PASSWORD_IS_NOT_CORRECT' } };
                }

                return service.errorHandler(errors);
            };

            service.login(email, password)
                .then(({ data }: AxiosResponse) => {

                    localStorage.setItem('auth_token', data.access_token);

                    dispatch(success(data.user));

                    history.push('/');
                })
                .catch((error) => {

                    dispatch(failure(authErrorHandler(error)));
                });
        };
    },

    /**
     * User change password action
     *
     * @param {String} oldPassword User old password
     * @param {String} newPassword User new password
     *
     * @return {Promise<Object>}
     */
    changePassword: (oldPassword: string, newPassword: string):
        (dispatch: ThunkDispatch<IAuthState, void, AnyAction>) => void => {

        //Action creators
        const success = () => {

            return {
                type: userConstants.CHANGE_PASSWORD_SUCCESS,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: userConstants.CHANGE_PASSWORD_FAILURE,
                errors,
            };

        }, service = new AuthService();


        return (dispatch) => {

            service.changePassword(oldPassword, newPassword)
                .then(() => {

                    dispatch(success());

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * User change password action
     *
     * @return {Promise<Object>}
     */
    typingPassword: ():
        (dispatch: ThunkDispatch<IAuthState, void, AnyAction>) => void => {

        //Action creators
        const success = () => {

            return {
                type: userConstants.TYPING_PASSWORD,
            };

        };


        return (dispatch) => {

            dispatch(success());

        };
    },

    /**
     * Get current user profile action
     *
     * @return {Promise<Object>}
     */
    profile: ():
        (dispatch: ThunkDispatch<IAuthState, void, AnyAction>) => void => {

        //Action creators
        const success = (user: IUser) => {

            return {
                type: userConstants.PROFILE_SUCCESS,
                user,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: userConstants.PROFILE_FAILURE,
                errors: errors,
            };

        }, service = new AuthService();

        return (dispatch) => {

            service.profile()
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * User logout action
     */
    logout: (): Record<string, string> => {

        localStorage.removeItem('auth_token');

        return {
            type: userConstants.LOGOUT,
        };
    },

    /**
     * Request link to reset user password by given email
     *
     * @param {string} email User email
     *
     * @return {Promise<Object>}
     */
    requestPassword: (email: string):
        (dispatch: ThunkDispatch<IAuthState, void, AnyAction>) => void => {

        //Action creators
        const request = () => {

            return {
                type: userConstants.FORGOT_PASSWORD_REQUEST,
            };

        }, success = () => {

            return {
                type: userConstants.FORGOT_PASSWORD_SUCCESS,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: userConstants.FORGOT_PASSWORD_FAILURE,
                errors,
            };

        }, service = new AuthService();

        return (dispatch) => {

            dispatch(request());

            service.requestPassword(email)
                .then(() => {

                    dispatch(success());
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Set password action
     *
     * @param {String} password New password
     * @param {String} confirmation Password confirmation
     * @param {String} token Access token
     *
     * @return {Promise<Object>}
     */
    setPassword: (password: string, confirmation: string, token: string):
        (dispatch: ThunkDispatch<IAuthState, void, AnyAction>) => void => {

        //Action creators
        const success = () => {

            return {
                type: userConstants.SET_PASSWORD_SUCCESS,
            };

        }, failure = ({ message }: IErrors) => {

            return {
                type: userConstants.SET_PASSWORD_FAILURE,
                errors: { common: message },
            };

        }, service = new AuthService();


        return (dispatch) => {

            service.setPassword(password, confirmation, token)
                .then(() => {

                    dispatch(success());

                    history.push('/login');
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Get list of users
     *
     * @params {String} search Search string
     * @param {string} search
     * @param {Object} order Sort settings
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'desc' }):
        (dispatch: ThunkDispatch<IUserListState, void, AnyAction>) => void => {

        //Action creators
        const success = (users: IUser[]) => {

            return {
                type: userConstants.LIST_SUCCESS,
                users,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: userConstants.LIST_FAILURE,
                errors,
            };
        }, service = new UserService();


        return (dispatch) => {

            service.list(search, order)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Create new user account
     *
     * @param {IUser} user
     *
     * @return {Promise<Object>}
     */
    store: (user: IUser):
        (dispatch: ThunkDispatch<IUserChangeState, void, AnyAction>) => void => {

        //Action creators
        const success = (user: IUser) => {

            return {
                type: userConstants.STORE_SUCCESS,
                user,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: userConstants.STORE_FAILURE,
                errors,
            };

        }, service = new UserService();

        return (dispatch) => {

            service.store(user)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Update user account
     *
     * @param {IUser} user
     *
     * @return {Promise<Object>}
     */
    update: (user: IUser):
        (dispatch: ThunkDispatch<IUserChangeState, void, AnyAction>) => void => {

        //Action creators
        const success = (user: IUser) => {

            return {
                type: userConstants.UPDATE_SUCCESS,
                user,
            };

        }, failure = (errors: IErrors) => {
            return {
                type: userConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new UserService();

        return (dispatch) => {

            service.update(user)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Remove user by ID
     *
     * @param {number} userId
     *
     * @return {Promise<Object>}
     */
    remove: (userId: number):
        (dispatch: ThunkDispatch<IUserChangeState, void, AnyAction>) => void => {

        //Action creators
        const success = () => {

            return {
                type: userConstants.DELETE_SUCCESS,
                user: { id: userId },
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: userConstants.DELETE_FAILURE,
                errors,
            };

        }, service = new UserService();

        return (dispatch) => {

            service.remove(userId)
                .then(() => {

                    dispatch(success());
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },
};
