import React, { PureComponent } from 'react';
import LayerWrapper from '../LayerWrapper';
import CollapsedLayout from '../Items/CollapsedLayout';
import { IHRDashboard, IHRDashboardUpdateModel } from '../../../interfaces';
import { IHrState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { statesActions } from '../../../../../core/actions';
import { Merge } from '../../../../../helpers/mergeType';
import { RootState } from '../../../../../core/store';

interface OwnProps {
    name?: string
    monitoringTree?: null | IHRDashboard;
    searchField: string;
    deselectStates: () => void;
    selectionState: Record<string, number>;
}

type Props = OwnProps;

class EditMonitoringTree extends PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.editable = true;
    }

    componentWillUnmount() {

        if (this.props.selectionState?.duration) {

            this.props.deselectStates();
        }
    }


    /**
     * Ability to edit
     *
     * @type {boolean}
     * @private
     */
    private readonly editable: boolean;

    render() {

        const { monitoringTree, searchField } = this.props;

        if (!monitoringTree) {
            return null;
        }

        const updatedModel: IHRDashboardUpdateModel = {
            id: monitoringTree.id,
            isMinimized: monitoringTree.isMinimized,
        };

        return (
            <LayerWrapper dashboard={updatedModel} expandedDefault={!monitoringTree.isMinimized} visibleSideBar maxWidthSideBar={320} editable={this.editable}>
                {monitoringTree && monitoringTree.departments.map((department, index) => {
                    if (department.id)
                        return (
                            <CollapsedLayout
                                key={department.id}
                                department={department}
                                searchField={searchField}
                                index={index}
                                editable={this.editable}
                                firstChild={index === 0}
                                maxWidthSideBar={320}
                            />
                        );
                    },
                )}
            </LayerWrapper>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: Merge<RootState |{
    hr: IHrState,
}>) => {

    const { monitoringTreeState } = state.hr;
    const { stateSelection } = state;

    const { monitoringTree } = monitoringTreeState;

    return {
        monitoringTree,
        selectionState: stateSelection.state,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    deselectStates: statesActions.deselectAllStates,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMonitoringTree);
