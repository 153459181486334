import { stateConstants } from '../../constants';
import { IStateHoveredState, IStateSelectionAction } from '../../interfaces';


/**
 * State selection reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function stateHovered(state: IStateHoveredState = {}, action: IStateSelectionAction): IStateHoveredState {

    switch (action.type) {

        case stateConstants.HOVERED:

            return {
                state: action.state,
                position: action.position,
                sensorId: action.sensorId,
            };

        case stateConstants.UNHOVERED:

            return {};

        default:

            return state;
    }
}
