import { LocationFormActions, LocationFormActionTypes } from '../actions/locationFormActions';
import { EditorMode, IGpsPin, IGpsPinIdent, ILocation } from '../../interfaces';

export interface ILocationFormState {
    isLoading: boolean;
    pins: Map<IGpsPinIdent, IGpsPin>;
    location: ILocation | null;
    editorMode: EditorMode;
    error: any;
}

export const initialState: ILocationFormState = {
    isLoading: false,
    pins: new Map<IGpsPinIdent, IGpsPin>(),
    location: null,
    editorMode: EditorMode.DEFAULT,
    error: null,
};

export function locationFormReducer(state = initialState, action: LocationFormActions): ILocationFormState {
    switch (action.type) {
        case LocationFormActionTypes.SAVE_LOCATION_FORM_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case LocationFormActionTypes.SAVE_LOCATION_FORM_SUCCESS: {
            const { location } = action.payload;

            return {
                ...state,
                isLoading: false,
                location,
            };
        }

        case LocationFormActionTypes.SAVE_LOCATION_FORM_FAILURE: {
            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case LocationFormActionTypes.RESET_WITH_LOCATION_MODEL: {
            const { location } = action.payload;

            const locationPins = location?.pins || [];
            const pins = new Map(locationPins.map(pin => [pin.localId, pin]));

            return {
                ...initialState,
                location,
                pins,
            };
        }

        case LocationFormActionTypes.SET_EDITOR_MODE_ACTION: {
            const { mode } = action.payload;

            return {
                ...state,
                editorMode: mode,
            };
        }

        case LocationFormActionTypes.SAVE_GPS_PIN_IN_LOCATION_FORM: {
            const { key, model } = action.payload;

            const pins = new Map(state.pins);

            const pinByKey = pins.get(key) || {
                lng: 0,
                lat: 0,
                x: 0,
                y: 0,
                localId: key,
                isDraft: true,
            };

            pins.set(key, { ...pinByKey, ...model });

            return {
                ...state,
                pins,
            };
        }

        case LocationFormActionTypes.REMOVE_GPS_PIN_IN_LOCATION_FORM: {
            const { key } = action.payload;

            const pins = new Map(state.pins);

            pins.delete(key);

            return {
                ...state,
                pins,
            };
        }

        case LocationFormActionTypes.CLEAR_INVALID_ERROR: {
            return {
                ...state,
                error: null,
            };
        }

        default: {
            return state;
        }
    }
}
