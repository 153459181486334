import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { ISensor } from '../../interfaces';


const selectConfigurationTreeStore = (state: RootState) => state.configurationTree;


export const selectAllFactoryItemInTree = createSelector(
    selectConfigurationTreeStore,
    configurationTree => configurationTree && configurationTree.items.filter(value => value.type === 'factory'),
);


export const selectAllProcessItemInTree= createSelector(
    selectConfigurationTreeStore,
    configurationTree => configurationTree.items.filter(value => value.type === 'process'),
);

export const selectAllUnitItemInTree = createSelector(
    selectConfigurationTreeStore,
    configurationTree => configurationTree.items.filter(value => value.type === 'unit'),
);

export const selectAllSensorInTree = createSelector(
    selectConfigurationTreeStore,
    configurationTree => configurationTree.items.filter(value => value.type === 'sensor') as unknown as ISensor[],
);

export const selectAllSensorItemInConfigurationTree = createSelector(
    selectAllUnitItemInTree,
    selectAllSensorInTree,
    (units, sensors) => [...units.map(unit=> unit.data).flat(1) as ISensor[], ...sensors],
);

