import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { IFlatTreeUnit, ISensor } from '../../interfaces';
import { sortOrder } from '../../../helpers/sortOrder';
import isEqual from 'lodash/isEqual';

// The previous value of the array of sensors
let sensorArrayOld: Map<number, ISensor> = new Map();

const getActiveSensor = (state: RootState) => {

    const sensorArray: Map<number, ISensor> = new Map();

    for (const treeItem of state.monitoringTree.monitoringTree) {

        if (treeItem.type === 'unit') {

            if (!(treeItem as IFlatTreeUnit).isMinimized && (treeItem as IFlatTreeUnit).data) {
                sortOrder((treeItem as IFlatTreeUnit).data.filter(value => value.isVisible)).map(s => sensorArray.set(s.id, s));
            }

            if ((treeItem as IFlatTreeUnit).isMinimized && (treeItem as IFlatTreeUnit).data) {
                sortOrder((treeItem as IFlatTreeUnit).data.filter(value => value.isVisible && value.isKeyParameter)).map(s => sensorArray.set(s.id, s));
            }
        }
    }

    if (isEqual(sensorArrayOld, sensorArray) && sensorArrayOld.size > 0) {

        return sensorArrayOld;

    } else {

        sensorArrayOld = sensorArray;

        return sensorArray;

    }
};

export const getAllSensor = (state: RootState): Map<number, ISensor> => {

    const sensorArray: Map<number, ISensor> = new Map();

    for (const treeItem of state.monitoringTree.monitoringTree) {

        if (treeItem.type === 'unit') {

            if ((treeItem as IFlatTreeUnit).data) {
                (treeItem as IFlatTreeUnit).data.map(s=>sensorArray.set(s.id, s));
            }
        }
    }

    if (isEqual(sensorArrayOld, sensorArray) && sensorArrayOld.size > 0) {

        return sensorArrayOld;

    } else {

        sensorArrayOld = sensorArray;

        return sensorArray;

    }
};


export const selectActiveSensorInTree = createSelector(
    [getActiveSensor],
    (activeSensor) => activeSensor,
);

export const selectAllSensorInTree = createSelector(
    [getAllSensor],
    (activeSensor) => activeSensor,
);

export const selectActiveSensorInTreeForMinimap = createSelector(
    [getActiveSensor],
    (activeSensor) => Array.from(activeSensor.values()).slice(0, 26),
);
