import { Handler } from './Handler';

/**
 * Shortcut handler
 *
 * @class ShortcutHandler
 */
export class ShortcutHandler {

    /**
     * Main handler instance
     *
     * @type {Handler}
     */
    private handler: Handler;

    /**
     * Constructor
     *
     * @param {Handler} handler
     */
    constructor(handler: Handler) {

        this.handler = handler;
    }

    /**
     * Check if Escape key is pressed
     *
     * @param {KeyboardEvent} e
     *
     * @returns {boolean}
     */
    isEscape(e: KeyboardEvent): boolean {

        return e.keyCode === 27;
    }

    /**
     * Check if Delete key is pressed
     *
     * @param {KeyboardEvent} e
     *
     * @returns {boolean}
     */
    isDelete(e: KeyboardEvent): boolean {

        return e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 127;
    }

    /**
     * Check if Arrow key is pressed
     *
     * @param {KeyboardEvent} e
     *
     * @returns {boolean}
     */
    isArrow(e: KeyboardEvent): boolean {

        return e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40;
    }
}
