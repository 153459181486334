import React, { useCallback, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import NodeTitle from './NodeTitle';
import Unit from './Unit';
import {
    IFlatTreeProcess,
    IProcessDragDropTreeProps,
} from '../../../core/interfaces';
import { useParamSelector } from '../../../hooks/useParamSelector';
import {
    selectFlatItemById,
} from '../../../core/selectors/configurationTree/configurationTreeCollectionSelector';


/**
 * Drag'n'drop tree process node
 *
 * @param process
 * @param factory
 * @param index
 * @param maxProductInUnit
 * @param filterValue
 * @param placeholderProps
 * @param scrollLeft
 * @param setAlertRules
 * @param setDraggedItem
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Process: React.FC<IProcessDragDropTreeProps> = (
    {
        process,
        factory,
        maxProductInUnit,
        filterValue,
        placeholderProps,
        scrollLeft,
        setAlertRules,
        setDraggedItem,
    }: IProcessDragDropTreeProps,
) => {

    const [collapseItem, setCollapseItem] = useState<{ height: number, status: boolean }>({ height: 40, status: true });

    const processItem = useParamSelector(selectFlatItemById, process, 'process') as IFlatTreeProcess;

    /**
     *  Handle for open the draggable element
     *
     * @param {number} id
     * @param {string} paramArr
     * @param event
     */
    const handleClick = useCallback((id: number, paramArr: string, event: boolean) => {

        setCollapseItem({ height: 40, status: !event });

    }, []);

    if (!processItem) {

        return null;
    }

    return (
        <div className="factory-item">
            <Accordion
                className="expansion-panel process"
                defaultExpanded
                expanded={collapseItem.status}
            >
                <AccordionSummary
                    aria-controls="panel12a-content"
                    id={'process' + process.toString()}
                >
                    <NodeTitle
                        handleClick={handleClick}
                        node={processItem as IFlatTreeProcess}
                        rightSectionClass={'left-padding-2'}
                        nodeName={'process'}
                        nodeNameMenu={'process'}
                        parent={factory}
                        getOpenStatus={collapseItem.status}
                        scrollLeft={scrollLeft}
                        maxProductInUnit={factory.meta.maxProductsInUnit}
                        collapseStateItem={collapseItem}
                    />
                </AccordionSummary>
                <AccordionDetails className="process-container">
                    {processItem.data ?
                        processItem.data
                            .map((unit) => (
                                <Unit
                                    key={unit}
                                    unit={unit}
                                    process={processItem}
                                    filterValue={filterValue}
                                    placeholderProps={placeholderProps}
                                    maxProductInUnit={maxProductInUnit}
                                    setAlertRules={setAlertRules}
                                    setDraggedItem={setDraggedItem}
                                    scrollLeft={scrollLeft}
                                />
                            ))
                        :
                        null
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Process;
