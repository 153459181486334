import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { selectGraphPeriodRange } from '../graphPeriod/graphPeriodSelector';


const selectGraphMinimapBrush =  (state: RootState) => state.graphMinimapBrush;

export const selectGraphMinimapBrushRule = createSelector(
    selectGraphMinimapBrush,
    (graphMinimapBrush) => graphMinimapBrush.brushRule,
);

export const selectGraphMinimapTimeRule = createSelector(
    selectGraphMinimapBrush,
    (graphMinimapBrush) => graphMinimapBrush.timerRule,
);

export const selectBrushSelection = createSelector(
    selectGraphMinimapBrush,
    (graphMinimapBrush) => graphMinimapBrush.selection,
);

export const calcRealTimeIndentation = createSelector(
    selectGraphPeriodRange,
    (selection)=> selection && new Date(selection.endDate).getTime() + (5 * 60 * 1000) >= new Date().getTime() ? 26 : 0,
);

