import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';
import { Store } from 'redux';
import { IGpsPinIdent } from '../../interfaces';

export const selectLocationFormState = createSelector(
    selectHrModuleState,
    state => state.locationFormState,
);

export const selectLocationFormModel = createSelector(
    selectLocationFormState,
    state => state.location,
);

export const selectLocationFormEditorMode = createSelector(
    selectLocationFormState,
    state => state.editorMode,
);

export const selectLocationFormEditorErrors = createSelector(
    selectLocationFormState,
    state => state.error,
);

export const selectLocationFormPinsMap = createSelector(
    selectLocationFormState,
    state => state.pins,
);

export const selectLocationFormPins = createSelector(
    selectLocationFormPinsMap,
    pins => Array.from(pins.values()),
);

export const getPinByKey =
    (state: Store, props: { key: IGpsPinIdent }) => selectLocationFormPinsMap(state).get(props.key)!;

export const selectLocationFormPinByKey = createSelector(
    getPinByKey,
    pin => pin,
);
