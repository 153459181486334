import React, { useCallback, useEffect, useState } from 'react';
import { CommonError, ConfirmDialog } from '../../../../../../core/ui/components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IFormValues, LayerForm } from './LayerForm/LayerForm';
import {
    selectLayerFormLocation,
    selectLayerFormModel,
} from '../../../../store/selectors/layerFormSelector';
import { layerThunks } from '../../../../store/thunks/layerThunks';
import { useFormHandlers } from '../../../../utils/useFormHandlers';
import { locationThunks } from '../../../../store/thunks/locationThunks';
import { ILayer } from '../../../../interfaces';
import { fabric } from 'fabric';
import { formatToDecimals } from '../../../../utils/formatToDecimals';
import { selectLocationsErrors } from '../../../../store/selectors/locationSelector';

/**
 * Form HR Layer Component
 */
const FormLayerSidebar: React.FC<IProps> = (props: IProps) => {
    const {
        toggleForm,
        openSidebar,
        reloadData,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState<IFormValues>({
        name: '',
        picture: '',
        angle: 0,
    });

    const location = useSelector(selectLayerFormLocation);
    const model = useSelector(selectLayerFormModel);
    const errors = useSelector(selectLocationsErrors);

    useEffect(() => {

        return () => {
            dispatch(layerThunks.closeForm());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!location) {
            return;
        }

        dispatch(locationThunks.fetchPictureContent(location));
    }, [dispatch, location]);

    useEffect(() => {
        setInitialValues({
            id: model?.id || 0,
            name: model?.name || '',
            picture: model?.picture || '',
            angle: formatToDecimals(model?.angle || 0),
        });
    }, [model]);

    const [validationSchema] = useState(yup.object().shape({
        name: yup.string()
            .trim()
            .max(30, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '30' }))
            .required(t('LAYER_NAME_IS_REQUIRED')),
        picture: yup.string()
            .trim()
            .required(t('SVG_IMAGE_IS_REQUIRED')),
        angle: yup.number()
            .typeError(t('VALUE_MUST_BE_AN_NUMBER'))
            .max(360, t('LAYER_ANGLE_RESTRICTION_MAX', { value: 360 }))
            .min(0, t('LAYER_ANGLE_RESTRICTION_MIN', { value: 0 }))
            .label(t('LAYER_ANGLE'))
            .required(t('LAYER_ANGLE_IS_REQUIRED')),
    }));

    const formHandlers = useFormHandlers({
        setInitialValues,
        toggleForm,
        openSidebar,
        reloadData,
    });

    const {
        confirm,
        handleSubmit,
        onCancel,
        onCancelDialog,
        onConfirmDialog,
    } = formHandlers;

    const [svgRef, setSvgRef] = useState<fabric.Image | undefined>();

    const onUpdateLayer = useCallback((params) => {
        setSvgRef(params);
    }, []);

    const onConfirmDialogCustom = useCallback(async() => {
        if (!location || !svgRef) {
            return;
        }

        const layerProps = {
            top: svgRef.top || 0,
            left: svgRef.left || 0,
            angle: svgRef.angle || 0,
            scaleX: svgRef.scaleX || 1,
            scaleY: svgRef.scaleY || 1,
            opacity: svgRef.opacity || 0,
        };

        const modelToSave: ILayer = {
            id: initialValues.id || 0,
            name: initialValues.name,
            picture: initialValues.picture,
            ...layerProps,
        };

        await dispatch(layerThunks.saveLayer(location, modelToSave));
        
        setTimeout(() => {
            onConfirmDialog();
        }, 200);
        dispatch(locationThunks.fetchLocations());
    }, [dispatch, initialValues, location, onConfirmDialog, svgRef]);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    });

    /**
     * Checking Errors
     * @type {() => ({common?: string | undefined} | undefined)}
     */
    const checkingErrors= useCallback(():{common?: string | undefined} | undefined => {

        const loadLayerImageError = model?.error?.errors?.common[0];

        if (errors) {
            // eslint-disable-next-line no-prototype-builtins
            if (errors.hasOwnProperty('message')) {

                if (errors.message === 'Internal server error' || errors.message[0] === 'Layer not found') {

                    return {
                        common: 'LAYER_NOT_FOUND_RELOAD_THE_PAGE',
                    };
                }

                return {
                    common: errors?.message.toString() || undefined,
                };

            } else {

                return errors.errors || errors;
            }
        }

        if (loadLayerImageError) {

            return {
                common: loadLayerImageError,
            };
        }

    }, [errors, model]);

    return (
        <>
            <div className="node-form">
                <CommonError errors={checkingErrors()} />
                {
                    location &&
                    <LayerForm
                        location={location}
                        formikProps={formik}
                        layerData={model}
                        onCancel={onCancel}
                        onUpdateLayer={onUpdateLayer}
                    />
                }
            </div>
            <ConfirmDialog
                heading={model ? t('EDIT_LAYER_Q') : t('ADD_LAYER_Q')}
                onAccept={onConfirmDialogCustom}
                onClose={onCancelDialog}
                open={confirm}
            />
        </>
    );
};

export default FormLayerSidebar;

interface IProps {
    openSidebar: boolean;
    toggleForm: (opened: boolean, name?: string) => void;
    reloadData: () => void;
}
