import ApiProvider from '../providers/apiProvider';
import { IItemsOptions } from '../interfaces';
import { AxiosResponse } from 'axios';

export class GraphAndStateDataService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {
        return '/graph-data/unit';
    }

    /**
     * Get histogram items
     * @param {IItemsOptions} arg
     * @return {Promise<AxiosResponse>}
     */
    async items(arg:IItemsOptions): Promise<AxiosResponse> {

        const { from, to, numberOfDataPoints, units, sensors } = arg;

        const accessToken = localStorage.getItem('auth_token');

        return await this.http
            .post(this.url(), {
                messageId: 9999999,
                from: from,
                to: to,
                numberOfDataPoints: numberOfDataPoints,
                units: units,
                sensors: sensors,
                subscribe: true,
                access_token: accessToken,
            }, {
                headers: this.headers,
            });
    }
}