import React  from 'react';
import { Select } from '../../../../core/ui/components';
import { FormikValues } from 'formik';

interface IFormSelectProps {
    formProps: FormikValues;
    handleChangeSelect: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    options: {
        label: string;
        value: string;
    }[];
    disabledSelect: boolean;
    name: string;
    placeholder: string;
    label: string;
    reset: boolean;
}

const FormSelect: React.FC<IFormSelectProps> = (
    {
        handleChangeSelect,
        formProps,
        options,
        disabledSelect,
        name,
        placeholder,
        label,
        reset,
    }: IFormSelectProps,
) => {

    const changeHandler = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {

        handleChangeSelect(event);

        formProps.handleChange(event);

        if (reset) {

            formProps.handleReset();
        }
    };

    return (
        <Select
            className={
                'form-field '
                +
                (formProps.touched[name] ? formProps.errors[name] ? 'error-field' : 'success-field' : '')
            }
            onChange={changeHandler}
            placeholder={placeholder}
            label={label}
            value={formProps.values[name] ? formProps.values[name] : ''}
            disabled={disabledSelect}
            options={options}
            name={name}
        >
            {((formProps.touched[name] && formProps.errors[name])) &&
            <div
                className="validation-massage"
            >{formProps.errors[name]}
            </div>
            }
        </Select>
    );
};

export default FormSelect;