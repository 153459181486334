import { LayerActions, LayerActionTypes } from '../actions/layerActions';
import { ILayer } from '../../interfaces';

export interface ILayerState {
    isLoading: boolean;
    layers: ILayer[];
    error: any;
}

export const initialState: ILayerState = {
    isLoading: false,
    layers: [],
    error: null,
};

export function layerReducer(state = initialState, action: LayerActions): ILayerState {
    switch (action.type) {
        case LayerActionTypes.FETCH_LAYERS_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case LayerActionTypes.FETCH_LAYERS_SUCCESS: {
            const { layers } = action.payload;

            return {
                ...state,
                isLoading: false,
                layers,
            };
        }

        case LayerActionTypes.FETCH_LAYERS_FAILURE: {
            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case LayerActionTypes.DELETE_LAYER_ACTION: {
            const { layer } = action.payload;

            const layers = state.layers.filter(({ id }) => id !== layer.id);

            return {
                ...state,
                layers,
            };
        }

        case LayerActionTypes.SAVE_LAYER: {
            const { layer } = action.payload;

            const layersMapper = new Map(state.layers.map(layer => [layer.id, layer]));

            layersMapper.set(layer.id, layer);

            const layers = Array.from(layersMapper.values());

            return {
                ...state,
                layers,
            };
        }

        default: {
            return state;
        }
    }
}
