import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IActiveProducts, IMonitoringTreeKeySensorProps, ISensorTargetValueState } from '../../../../core/interfaces';
import { graphConstants } from '../../../../core/constants';
import { ReactComponent as FolderKeyIcon } from '../../../../core/ui/assets/images/icons/folder-key.svg';
import { Tooltip } from '../../../../core/ui/components';
import { HistogramChart } from '../../../../base/components';
import { isMobile } from 'react-device-detect';
import { selectHistogramHeight } from '../../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { selectGraphBarHeight } from '../../../../core/selectors/graphBarHeight/graphBarHeightSelector';
import { selectHmiHoverItem } from '../../../../core/selectors/hmi/playerHoverItem';
import { HmiObjectAction } from '../../../../core/actions/hmiObjectAction';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import {
    selectActiveProductByUnitId,
} from '../../../../core/selectors/unitActiveProducts/activeProductDataSelector';
import Skeleton from '@material-ui/lab/Skeleton';

/**
 * Monitoring tree key sensor functional component
 *
 * @param {any} sensor
 * @param {any} unit
 * @param {number} histogramHeight
 * @param {number} maxWidthSideBar
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const KeySensor: React.FC<IMonitoringTreeKeySensorProps> = (
    {
        sensor,
        unit,
        visibleSideBar,
        maxWidthSideBar,
    }: IMonitoringTreeKeySensorProps,
) => {

    const dispatch = useDispatch();

    const [delay, setDelay] = useState(true);

    const [sensorTargetValue, setSensorTargetValue] = useState<ISensorTargetValueState | undefined>(undefined);

    const activeProductData = useParamSelector(selectActiveProductByUnitId, unit.id) as IActiveProducts[] | undefined;

    const histogramHeight = useSelector(selectHistogramHeight);

    const graphHeight = useSelector(selectGraphBarHeight);

    const hoverItem = useSelector(selectHmiHoverItem);

    const { targetValues } = sensor;
    /**
     * Stop click even propagation
     *
     * @param {React.MouseEvent} event
     */
    const stopClickPropagation = useCallback((event: React.MouseEvent) => {

        event.stopPropagation();
    }, []);

    /**
     * Hover sensor on tree Callback
     */
    useEffect(() => {

        if (hoverItem?.sensorId === sensor.id) {

            dispatch(HmiObjectAction.hoverSensorOnTree(sensor));
        }

    }, [hoverItem, sensor, dispatch]);

    useEffect(() => {

        if (activeProductData && activeProductData.length > 0  && targetValues) {

            const sensorTargetValueData = {
                activeProducts: activeProductData,
                targetValues: targetValues,
            };

            setSensorTargetValue(sensorTargetValueData);

        }
    }, [activeProductData, targetValues]);

    /**
     * Rendering delay
     */
    useEffect(() => {


        const delayTimeout = setTimeout(() => setDelay(false), 200);

        return () => clearTimeout(delayTimeout);

    }, []);

    /**
     * Mouse enter callback  for the hmi player
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseEnterForPlayer = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent> |  React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        dispatch(HmiObjectAction.hoverSensorOnTree(sensor));

    }, [dispatch, sensor]);

    /**
     * Mouse leave callback  for the hmi player
     *
     * @type {() => void}
     */
    const onMouseLeaveForPlayer = useCallback(() => {

        dispatch(HmiObjectAction.hoverSensorOnTree(undefined));

    }, [dispatch]);

    /**
     * Rendering delay
     */
    if (delay) {

        const skeletonStyle = {
            lineHeight: 'inherit',
            borderBottom: '1px solid #e9ecef',
            width: '100%',
            background: '#FFFFFF',
        };

        return (
            <div
                    className={`item-title no-pointer ${histogramHeight === graphConstants.histogramSmallHeight ? '' : 'extended'} ${hoverItem?.sensorId === sensor.id ? 'hover' : ''}`}
            >
                <div
                    className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding' : 'right-section sensor-name left-padding-2'}
                    style={{
                        maxWidth: maxWidthSideBar,
                        minWidth: maxWidthSideBar,
                        transition: 'max-width 0.2s linear',
                    }}
                >
                    <FolderKeyIcon style={{
                        height: 24,
                        width: 24,
                        marginRight: 8,
                        minWidth: 24,
                    }}
                    />
                        <span className={`sensor-name ${isMobile ? 'is-mobile' : ''}`}>{unit.name + ' (' + sensor.name + ')'}</span>
                </div>
                <div className={'left-section with-chart'}>
                        <Skeleton
                            variant={'rect'}
                            style={skeletonStyle}
                            height={sensor.sensorType === 'state' ? Number(graphHeight) : histogramHeight}
                            animation={'pulse'}
                        />
                </div>
            </div>
        );
    }


    if (sensor && sensor.isKeyParameter && sensor.isVisible && unit.isMinimized) {

        return (
            <div
                className={`item-title no-pointer ${histogramHeight === graphConstants.histogramSmallHeight ? '' : 'extended'} ${hoverItem?.sensorId === sensor.id ? 'hover' : ''}`}
                onMouseEnter={onMouseEnterForPlayer}
                onTouchStart={onMouseEnterForPlayer}
                onMouseLeave={onMouseLeaveForPlayer}
                onTouchEnd={onMouseLeaveForPlayer}
            >
                <div
                    className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding' : 'right-section sensor-name left-padding-2'}
                    style={{
                        maxWidth: maxWidthSideBar,
                        minWidth: maxWidthSideBar,
                        transition: 'max-width 0.2s linear',
                    }}
                >
                    <FolderKeyIcon style={{
                        height: 24,
                        width: 24,
                        marginRight: 8,
                        minWidth: 24,
                    }}
                    />
                    <Tooltip
                        title={`${unit.name} (${sensor.name})`}
                        enterDelay={500}
                        style={{ zIndex: 1200 }}
                        enterTouchDelay={200}
                        leaveTouchDelay={2000}
                        disableTouchListener
                    >
                        <span className={`sensor-name ${isMobile ? 'is-mobile' : ''}`}>{unit.name + ' (' + sensor.name + ')'}</span>
                    </Tooltip>
                </div>
                <div className={sensor.isVisible ? 'left-section with-chart' : 'left-section'}
                     onClick={stopClickPropagation}
                >
                    {sensor.isVisible && sensor.sensorType === 'graph' ?
                        <HistogramChart
                            sensor={sensor}
                            sensorTargetValue={sensorTargetValue}
                            hrMode={false}
                        />
                        :
                        null
                    }
                </div>
            </div>
        );
    }

    return null;
};


export default React.memo(KeySensor);
