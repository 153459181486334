import React, { ReactNode, useRef, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';

import { IMouseState } from '../../../interfaces';

import './styles/CursorPopover.scss';

interface IProps {
    mouse: IMouseState;
    open: boolean;
    children?: ReactNode;
}

/**
 * A popover functional component that follows cursor position
 *
 * @param {React.PropsWithChildren<IProps>} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const CursorPopover: React.FC<IProps> = ({ ...props }:IProps) => {

    const { open, mouse } = props,
        targetRef = useRef<HTMLDivElement>(null),
        [position, setPosition] = useState({ top: mouse.y || 0, left: mouse.x || 0 });

    useLayoutEffect(() => {

        if (targetRef && targetRef.current) {

            const bounds = targetRef.current.getBoundingClientRect(),
                xOverflow = window.innerWidth - (mouse.x + bounds.width),
                yOverflow = window.innerHeight - (mouse.y + bounds.height);

            const correctionX = xOverflow < 0 ? xOverflow : 0,
                correctionY = yOverflow < 0 ? (bounds.height + 30) * -1 : 0;

            setPosition({
                top: mouse.y + correctionY,
                left: mouse.x + correctionX,
            });
        }

    }, [targetRef, mouse]);

    return (<React.Fragment>
        {open ? (
            <div
                ref={targetRef}
                className="MuiPaper-root MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded"
                style={{ top: position.top, left: position.left }}
            >
                {props.children}
            </div>
        ) : null}
            </React.Fragment>);
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: {mouse: IMouseState}) => {

    const { mouse } = state;

    return {
        mouse,
    };
};

export default connect(mapStateToProps)(CursorPopover);
