import { AxiosResponse } from 'axios';
import HmiProvider from './../providers/hmiProvider';
import { IHmiObjectTree } from '../interfaces';
import { trackPromise } from 'react-promise-tracker';

/**
 * @class HmiObjectTreeService
 */
export class HmiObjectTreeService extends HmiProvider {

    /**
     * @type {string}
     */
    get urlPattern(): string {
        return '/hmi-objects/tree(/:type(/:id))';
    }

    /**
     * Update sensor tree
     * 
     * @param {IHmiObjectTree} data 
     * @param {string} type
     * @returns {Promise<AxiosResponse>}
     */
    update(data: IHmiObjectTree, type: string, detach = false): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ type }), { ...data, detach }, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove object by type
     * 
     * @param {string} type 
     * @param {number} id 
     * @returns {Promise<AxiosResponse}
     */
    remove(type: string, id: number): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .delete(this.url({ type, id }), {
                    headers: this.headers,
                }),
        );
    }
}