import React, { useCallback, useEffect, useState } from 'react';
import { Slider as MaterialUISlider } from '@material-ui/core';
import styles from './Slider.module.scss';

export const Slider: React.FC<IProps> = ({ label, value: initialValue = 1, onChangeValue }: IProps) => {
    const [sliderValue, setSliderValue] = useState(initialValue);

    useEffect(() => {
        setSliderValue(initialValue);
    }, [initialValue]);

    const onChange = useCallback((_, value) => {
        setSliderValue(value);

        onChangeValue(value);
    }, [onChangeValue]);

    return (
        <div className={styles.wrapper}>
            <span className={styles.label}>
                {label}
            </span>
            <div
                className={styles.slider}
            >
                <MaterialUISlider
                    value={sliderValue}
                    min={0}
                    step={0.01}
                    max={1}
                    onChange={onChange}
                />
            </div>
            <span className={styles.value}>
                {(sliderValue * 100).toFixed(0)}%
            </span>
        </div>
    );
};

interface IProps {
    label: string;
    value?: number;
    onChangeValue: (value: number) => void;
}
