import { createSelector } from 'reselect';
import { RootState } from '../../store';

export const selectConfigurationProductStore = (state: RootState) => state.configurationProduct;

export const selectProductUnit = createSelector(
    selectConfigurationProductStore,
    configurationProduct => configurationProduct.unit,
);

export const selectUnitVisible = createSelector(
    selectConfigurationProductStore,
    configurationProduct => configurationProduct.visible,
);

