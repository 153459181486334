import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../ui/components/Button/Button';
import { connect } from 'react-redux';
import { IHrState } from '../../../../../store/reducers';
import { selectSettingPageState } from '../../../../../store/selectors/settingPageSelector';
import { selectEmployees } from '../../../../../store/selectors/employeeSelector';
import { selectBeaconState } from '../../../../../store/selectors/beaconSelector';
import { SettingPageThunks } from '../../../../../store/thunks/settingPageThunks';
import TextInput from '../../../../../ui/components/Input/TextInput';

import './BeaconForm.scss';
import { IOptions } from '../../../../../../../core/interfaces';
import { ConfirmDialog } from '../../../../../../../core/ui/components';
import { IBeacon, IEmployee, IErrors } from '../../../../../interfaces';
import { BeaconThunks } from '../../../../../store/thunks/beaconThunks';
import { InputLabel } from '@material-ui/core';
import Select from '../../../../../ui/components/Select/Select';
import { EmployeeThunks } from '../../../../../store/thunks/employeeThunks';
import FormControl from '@material-ui/core/FormControl';

interface IBeaconFormProps {
    model?: IBeacon;
    toggleForm: (formOpening: boolean, formName?: string, model?: IBeacon) => void;
    saveBeacon: (model: IBeacon) => void;
    employees: IEmployee[];
    error: IErrors;
    clearError: () => void;
}

const employeesToOptions = (employee: IEmployee): IOptions => {

    return { label: `${employee.surname} ${employee.firstName}`, value: employee.id };
};

const returnSelectedValue = (data: IEmployee | number): number => {

    return typeof data === 'number' ? data : data.id;
};

const BeaconForm: React.FC<IBeaconFormProps> = (
    {
        model,
        toggleForm,
        saveBeacon,
        employees,
        error,
        clearError,
    }: IBeaconFormProps,
) => {

    const { t } = useTranslation();

    const [initialValues, setInitialValues] = useState<IBeacon>({
        id: '',
        name: '',
        active: 0,
        bleMac: null,
        lastSeen: null,
        lowBattery: 0,
    });

    const [formValue, setFormValue] = useState<IBeacon | null>(null);

    const [employeeOption, setEmployeeOption] = useState<IOptions[] | null>(null);

    const validationSchema = yup.object().shape({
        id: yup.string().notRequired(),
        name: yup.string()
            .trim()
            .max(63, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: 63 }))
            .nullable(true),
        employee: yup.object().nullable(true).notRequired(),
    });

    /**
     * Handler cancel dialog form
     *
     * @type {() => void}
     */
    const handleCancel = useCallback(() => {

        setFormValue(null);

        clearError();

    }, []);

    /**
     * Handler the form submit
     *
     * @param value
     */
    const handleSubmit = useCallback((value: IBeacon) => {

        setFormValue(value);

    }, [setFormValue]);

    /**
     * Popup handler. Unset anchor element.
     */
    const handleClose = useCallback(() => {

        toggleForm(false, '');

        handleCancel();

    }, [toggleForm, handleCancel]);

    useEffect(() => {

        if (model) {

            setInitialValues(model);
        }

    }, [model]);

    useEffect(() => {

        const options = employees.map(employee => employeesToOptions(employee));

        if (model && model.employee && !options.find(option => model.employee?.id === option.value)) {

            const selectedEmployee = model.employee as IEmployee;
            options.push(employeesToOptions(selectedEmployee));
        }

        setEmployeeOption(options);

    }, [model, employees]);

    /**
     * On confirm change
     *
     * @type {() => void}
     */
    const onConfirmDialogCustom = useCallback(() => {

        if (formValue) {

            const { lowBattery, active, name } = formValue;

            const sendData: IBeacon = {
                ...formValue,
                name: name.trim(),
                lowBattery: lowBattery && (lowBattery > 1 || lowBattery < 0) ? 1 : lowBattery,
                active: active && (active > 1 || active < 0) ? 1 : active,
            };

            saveBeacon(sendData);
        }

    }, [formValue, saveBeacon]);

    /**
     *
     * @type {() => void}
     */
    const onCancelDialog = useCallback(() => {

        setFormValue(null);

    }, [setFormValue]);

    /**
     * Handle change name method, clear error state on change name field
     * 
     * @param e 
     * @param props 
     */
    const onHandleChangeName = ((e: React.ChangeEvent<any>, props: FormikProps<IBeacon>) => {
        props.handleChange(e);

        if (error.message) {
            clearError();
        }
    });

    return (
        <React.Fragment>
            <div className="form-box form-box-space node-form beacon-edit-form">
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props: FormikProps<IBeacon>) => (
                        <form
                            noValidate
                            onSubmit={props.handleSubmit}
                        >
                            <div className="table-header">
                                <div
                                    className={'title'}
                                >
                                    {t(!model ? 'ADD_BEACON' : 'EDIT_BEACON')}
                                </div>
                            </div>
                            <div className="table-body">
                                <div className="form-group">
                                    <TextInput
                                        className={
                                            'form-field '
                                            +
                                            (props.touched.name ? props.errors.name || error.message ? 'error-field' : 'success-field' : '')
                                        }
                                        label={t('NAME')}
                                        onChange={(e) => onHandleChangeName(e, props)}
                                        onBlur={props.handleBlur}
                                        value={props.values.name ? props.values.name : ''}
                                        name="name"
                                        type="text"
                                        placeholder={'NAME'}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        {((props.touched.name && props.errors.name) || !!error.message) &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.name || t('BEACON_NAME_ALREADY_TAKEN')}
                                            </div>
                                        }
                                    </TextInput>
                                </div>
                                <div className="form-field info-field">
                                    <FormControl component="fieldset">
                                        <p>{t('BLE-MAC')}</p>
                                        {props.values.bleMac}
                                    </FormControl>
                                </div>
                                <div className="form-field info-field">
                                    <FormControl component="fieldset">
                                        <p>{t('UUID')}</p>
                                        {props.values.id}
                                    </FormControl>
                                </div>
                                <div className="form-field info-field">
                                    <FormControl component="fieldset">
                                        <p>{t('BATTERY_STATUS')}</p>
                                        {props.values.lowBattery ? t('LOW') : t('NORMAL')}
                                    </FormControl>
                                </div>
                                <div className="form-group select">
                                    <InputLabel>{t('ASSIGNED_EMPLOYEE')}</InputLabel>
                                    <Select
                                        className={
                                            'form-field '
                                            +
                                            (props.touched.employee ? props.errors.employee ? 'error-field' : 'success-field' : '')
                                        }
                                        value={props.values.employee ? returnSelectedValue(props.values.employee) : ''}
                                        name="employee"
                                        placeholder={t('NONE')}
                                        onChange={props.handleChange}
                                        displayEmpty
                                        options={employeeOption ? employeeOption : []}
                                        MenuProps={{
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300,
                                            },
                                        }}
                                    />
                                </div>
                                <div className="form-group btn-group">
                                    <Button
                                        type="reset"
                                        color={'primary'}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={'secondary'}
                                    >
                                        {t(!model ? 'ADD_BEACON' : 'SAVE_CHANGES')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {formValue ?
                <ConfirmDialog
                    heading={model ? t('EDIT_BEACON_Q') : t('ADD_BEACON_Q')}
                    onAccept={onConfirmDialogCustom}
                    onClose={onCancelDialog}
                    open={Boolean(formValue)}
                />
                :
                null
            }
        </React.Fragment>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: IHrState) => {

    const { formOpening, formName, model } = selectSettingPageState(state);

    const { beacons, error } = selectBeaconState(state);

    const employees = selectEmployees(state);

    return {
        formOpening,
        formName,
        model: model as IBeacon,
        error,
        beacons,
        employees,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    toggleForm: SettingPageThunks.toggleForm,
    saveBeacon: BeaconThunks.saveBeacon,
    clearError: BeaconThunks.clearError,
    fetchEmployees: EmployeeThunks.fetchEmployees,
});

export default connect(mapStateToProps, mapDispatchToProps)(BeaconForm);
