import { graphConstants } from '../../constants';
import { IGraphHistogramModeState } from '../../interfaces';


/**
 * Graph histogram mode reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphHistogramMode(state: IGraphHistogramModeState = {}, action: {type: string}): IGraphHistogramModeState {

    switch (action.type) {

        case graphConstants.histogramSwitchLinear:

            return {
                mode: graphConstants.histogramModeLinear,
            };

        case graphConstants.histogramSwitchLogarithmic:

            return {
                mode: graphConstants.histogramModeLogarithmic,
            };

        case graphConstants.histogramSwitchOverlapping:

            return {
                mode: graphConstants.histogramModeOverlapping,
            };

        case graphConstants.histogramModeDisable:

            return {
                mode: graphConstants.histogramModeDisable,
            };

        default:

            return state;
    }
}
