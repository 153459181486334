import { createSelector } from 'reselect';
import { RootState } from '../../store';

const selectAppSetting = (state: RootState) => state.appSetting;

export const selectAppSettings = createSelector(
    selectAppSetting,
    appSetting => appSetting.settings,
);

export const selectHMISettings = createSelector(
    selectAppSettings,
    settings => settings.hmi,
);

export const selectMainIntervals = createSelector(
    selectAppSettings,
    settings => settings.mainIntervals,
);
