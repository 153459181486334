import {
    FetchDepartmentsRequest,
    FetchDepartmentsSuccess,
    FetchDepartmentsFailure,
    SaveDepartmentAction,
    SaveDepartmentFailure,
    DeleteDepartmentAction,
    DeleteDepartmentFailure,
    UpdateDepartmentSuccess,
    UpdateDepartmentFailure, ClearDepartmentError,
} from '../actions/departmentActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IFilter, IJoin, IOrder } from '../../../../core/interfaces';
import { IEmployeeState } from '../reducers/employeeReducer';
import { DepartmentService } from '../../services/departmentService';
import { IDepartment } from '../../interfaces';
import { OpeningForm } from '../actions/settingPageActions';
import { EmployeeService } from '../../services/employeeService';
import { FetchEmployeesSuccess } from '../actions/employeeActions';

export const DepartmentThunks = {

    fetchDepartments: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }, join?: IJoin, filter?: IFilter) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void>  => {

        const departmentService = new DepartmentService();

        try {

            dispatch(new FetchDepartmentsRequest());

            const departments = await departmentService.list(search, order, join, filter);

            dispatch(new FetchDepartmentsSuccess({ departments }));

        } catch (errors) {

            const error = departmentService.errorHandler(errors);

            dispatch(new FetchDepartmentsFailure({ error }));
        }
    },

    saveDepartment: (model: IDepartment) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        const departmentService = new DepartmentService();

        try {

            const department = await departmentService.store(model);

            dispatch(new SaveDepartmentAction({ department }));

            dispatch(new OpeningForm({ formOpening: false  }));

        } catch (errors) {

            const error = departmentService.errorHandler(errors);

            dispatch(new SaveDepartmentFailure({ error }));
        }
    },

    updateDepartment: (model: IDepartment) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        const departmentService = new DepartmentService();

        try {

            const department = await departmentService.update(model);

            dispatch(new UpdateDepartmentSuccess({ department }));

            dispatch(new OpeningForm({ formOpening: false  }));

        } catch (errors) {

            const error = departmentService.errorHandler(errors);

            dispatch(new UpdateDepartmentFailure({ error }));
        }
    },

    deleteDepartment: (department: IDepartment) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        const departmentService = new DepartmentService(),
            employeeService = new EmployeeService();

        try {



             await departmentService.delete(department);


            dispatch(new DeleteDepartmentAction({ department }));

            const employees = await employeeService.list('', {
                column: 'id',
                dir: 'asc',
            }, undefined, { field: ['department.id||$isnull'] });


            dispatch(new FetchEmployeesSuccess({ employees }));

        } catch (errors) {

            const error = departmentService.errorHandler(errors);

            dispatch(new DeleteDepartmentFailure({ error }));
        }
    },

    clearDepartmentError: () => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        dispatch(new ClearDepartmentError());
    },
};
