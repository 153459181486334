import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Draggable, DraggableProvided, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import KpiStateItem from '../Items/KpiStateItem';
import SensorItem from '../Items/SensorItem';
import {
    IActiveProducts,
    IEditStructuralTreeSensorProps,
    ISensorTargetValueState,
} from '../../../../core/interfaces';
import { selectHistogramHeight } from '../../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { isDesktop, isMobile } from 'react-device-detect';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectActiveProductByUnitId } from '../../../../core/selectors/unitActiveProducts/activeProductDataSelector';

/**
 * Edit structural tree sensor node
 *
 * @param sensor
 * @param unit
 * @param process
 * @param index
 * @param onMouseDown
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Sensor: React.FC<IEditStructuralTreeSensorProps> = (
    {
        sensor,
        unit,
        index,
        onMouseDown,
    }: IEditStructuralTreeSensorProps,
) => {

    const [delay, setDelay] = useState(true);

    const { targetValues } = sensor;

    const histogramHeight = useSelector(selectHistogramHeight);
    const activeProducts = useParamSelector(selectActiveProductByUnitId, unit.id) as IActiveProducts[] | undefined;

    const [sensorTargetValue, setSensorTargetValue] = useState<ISensorTargetValueState | undefined>(undefined);

    const onMouseDownCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        if (isDesktop) {
            event.preventDefault();

            onMouseDown(sensor);
        }

    }, [onMouseDown, sensor]);

    const onTouchStartCallback = useCallback(() => {

        if (isMobile) {
            onMouseDown(sensor);
        }

    }, [onMouseDown, sensor]);


    useEffect(() => {

        if (activeProducts && activeProducts.length > 0 && sensor.targetValues && sensor.type !== 'state') {

            const sensorTargetValueData = {
                activeProducts: activeProducts,
                targetValues: sensor.targetValues || [],
            };

            setSensorTargetValue(sensorTargetValueData);

        }
    }, [activeProducts, targetValues, sensor]);

    /**
     * Rendering delay
     */
    useEffect(() => {

        const delayTimeout = setTimeout(() => setDelay(false), 200);

        return () => clearTimeout(delayTimeout);

    }, []);

    const getDraggableStyle = (style: DraggingStyle | NotDraggingStyle | undefined): any => {
        const baseStyle = {
            userSelect: 'none',
            width: '100%',
            minHeight: 40,
            background: 'transparent',
        };

        return style ? { ...style, baseStyle } : baseStyle;
    };


    /**
     * Rendering delay
     */
    if (delay) {

        return null;
    }

    return (
        <Draggable 
            index={index} 
            draggableId={`${sensor.id}`}
            disableInteractiveElementBlocking 
            shouldRespectForcePress
        >
            {(provided: DraggableProvided) => (
                <div
                    key={'sensor-key-' + sensor.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="custom-collapse-item"
                    style={getDraggableStyle(provided.draggableProps.style)}
                    onMouseDown={onMouseDownCallback}
                    onTouchEnd={onTouchStartCallback}
                >
                    {sensor.sensorType === 'state' ?
                            <KpiStateItem
                                sensor={sensor}
                                unit={unit}
                                sensorIndex={index}
                            />
                        :
                            <SensorItem
                                histogramHeight={histogramHeight}
                                sensor={sensor}
                                sensorTargetValue={sensorTargetValue}
                            />
                    }
                </div>
            )}
        </Draggable>);
};

export default React.memo(Sensor);
