import React from 'react';
import { fabric } from 'fabric';
import { Handler } from './handlers/Handler';
import { FabricCanvas } from '../../../../../base/components/Editor/interfaces';

import '../../../../../base/components/Editor/styles/Canvas.scss';

const defaultCanvasOptions = {
    width: 300,
    height: 150,
    selection: true,
    defaultCursor: 'default',
    backgroundColor: '#fff',
};

const defaultGripOptions = {
    enabled: false,
    grid: 10,
    snapToGrid: false,
    lineColor: '#ebebeb',
    borderColor: '#cccccc',
};

/**
 * Workarea canvas
 *
 * @class Canvas
 */
export class Canvas extends React.Component<any> {

    /**
     * Main handler instance
     *
     * @type {Handler}
     */
    //@ts-ignore
    public handler: Handler;

    /**
     * Interactive canvas model
     *
     * @type {FabricCanvas}
     */
    //@ts-ignore
    public canvas: FabricCanvas;

    /**
     * Canvas container
     *
     * @type {React.RefObject<HTMLDivElement>}
     */
    public container = React.createRef<HTMLDivElement>();

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        const { width, height, ...props } = this.props;

        const options = Object.assign({}, defaultCanvasOptions, {
            width,
            height,
            selection: true,
        });

        this.canvas = new fabric.Canvas('map-editor-canvas', options);
        this.canvas.setBackgroundColor(options.backgroundColor, this.canvas.renderAll.bind(this.canvas));
        this.canvas.renderAll();

        this.handler = new Handler({
            canvas: this.canvas,
            container: this.container.current,
            editable: true,
            ...props,
        });

        this.handler.interactionHandler.grab();

        this.handler.eventHandler.attachEvents();
    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount() {

        this.handler.eventHandler.detachEvents();
    }

    /**
     * Render the component
     *
     * @returns {JSX.Element}
     */
    render() {

        return (
            <div ref={this.container} className="canvas">
                <canvas id="map-editor-canvas" />
            </div>
        );
    }
}
