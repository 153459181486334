import {
    IGraphDataLastValueAction,
    IGraphDataLastValueState,
} from '../../interfaces';
import { graphDataLastValueConstants } from '../../constants/graphDataLastValueConstants';


/**
 * State selection reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphDataLastValue(state: IGraphDataLastValueState = {
    items: [],
}, action: IGraphDataLastValueAction): IGraphDataLastValueState {

    switch (action.type) {

        case graphDataLastValueConstants.getGraphDataLastValueSuccess:

            return {
                items: action.items,
                errors: undefined,
            };

        case graphDataLastValueConstants.getGraphDataLastValueFailure:

            return {
                ...state,
                errors: action.errors,
            };

        default:

            return state;
    }
}
