import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

import { Button, TextInput } from '../../../core/ui/components';
import { IAuthState, IDashboard, IData, IError, IFormState, IUser } from '../../../core/interfaces';
import { FormActions, DashboardActions } from '../../../core/actions';

import './DashboardForm.scss';


interface IFormValues {
    id?: number;
    name: string;
    graphs_switch?: boolean;
}

interface IProps {
    errors: IError;
    dialogOpened: boolean;
    toggleForm: (opened: boolean) => void;
    storeDashboard: (dashboard: IDashboard) => void;
    updateDashboard: (dashboard: IDashboard) => void;
    model: IData | null;
    listDashboard: () => void;
    user?: IUser
}

interface IState {
    initialValues: IFormValues;
}

/**
 * Create dashboard form
 *
 * @class DashboardForm
 */
class DashboardForm extends React.Component<IProps & WithTranslation, IState> {


    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, model } = this.props;

        if (model) {

            this.state = {
                initialValues: {
                    id: parseInt(model.id.toString()),
                    name: model.name.toString() || '',
                    graphs_switch: Boolean(model.graphs_switch),
                },
            };

        } else {

            this.state = {
                initialValues: {
                    name: '',
                },
            };
        }


        this.validationSchema = yup.object().shape({
            name: yup
                .string().trim().min(1, t('DASHBOARD_VALIDATION'))
                .transform(value => {
                    return value.replace(/[^a-zA-ZА-Яа-яЁё0-9]/gi, '').replace(/\s+/gi, ', ');
                })
                .matches(RegExp('^[A-zА-яЁё0-9#№]'), t('DASHBOARD_VALIDATION'))
                .required(t('DASHBOARD_NAME_IS_REQUIRED')),
        });

        this.handleCancel = this.handleCancel.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }


    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IFormValues>>;


    /**
     * Dashboard form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        const { user } = this.props;

        if (user) {
            if (!values.id) {

                this.props.storeDashboard({
                    id: 0,
                    name: values.name,
                    comment: '',
                    user: user.id,
                });

            } else {

                this.props.updateDashboard({
                    id: values.id,
                    name: values.name,
                    graphs_switch: Boolean(values.graphs_switch),
                    user: user.id,
                });
            }
        }

        this.handleCancel();
    }

    /**
     * Dashboard form cancel handler
     */
    handleCancel() {

        this.props.toggleForm(true);
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, errors = {} } = this.props,
            { initialValues } = this.state;

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
            >
                {props => (
                    <form onSubmit={props.handleSubmit}
                          noValidate
                    >
                        <TextInput
                            className={
                                'form-field ' +
                                (props.touched.name ? (props.errors.name || errors.name) ? 'error-field' : 'success-field' : '')
                            }
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.name}
                            name="name"
                            type="text"
                            placeholder={t('DASHBOARD_NAME')}
                            inputProps={{ maxLength: 50, autoComplete: 'off' }}
                        >
                            {((props.touched.name && props.errors.name) || errors.name) &&
                            <div className="validation-massage">{props.errors.name || errors.name}</div>
                            }
                        </TextInput>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                                <Button type="button" color="primary" className="btn-gray"
                                        onClick={this.handleCancel}
                                >
                                    {t('CANCEL')}
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Button type="submit" color="primary" className="btn-gray"
                                        onClick={() => props.handleSubmit()}
                                >
                                    {initialValues.id ? t('RENAME') : t('CREATE')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: { form: IFormState, auth: IAuthState }) => {

    const errors = {}, { dialogOpened } = state.form, { user } = state.auth;

    return {
        errors,
        dialogOpened,
        user,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggleDialog,
    storeDashboard: DashboardActions.store,
    updateDashboard: DashboardActions.update,
    listDashboard: DashboardActions.list,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardForm));
