import { fabric } from 'fabric';
import FontFaceObserver from 'fontfaceobserver';

/**
 * GpsPin object definition
 *
 * @type {any}
 */
const GpsPin = fabric.util.createClass(fabric.IText, {
    type: 'gpsPin',
    superType: 'drawing',
    initialize(text: string, options: any) {

        options.label = text;
        options.fontFamily = 'Font Awesome 5 Free';
        options.editable = false;
        options.hasControls = false;
        options.hasBorders = false;
        options.hasRotatingPoint = false;
        options.originX = 'center';
        options.originY = 'bottom';
        options.fill = '#4092f5';
        options.objectCaching = false;
        options.ignoreZoom = true;
        options._fontSizeFraction = 0.12;
        options._fontSizeMult = 1;

        this.callSuper('initialize', '\uf041', options);
        this.font = new FontFaceObserver('Font Awesome 5 Free');

        this.font && this.font.load().then(() => {

            if (!this.loaded) {

                this.canvas.requestRenderAll();

                this.loaded = true;

            }
        });
    },
    transform: function(ctx: CanvasRenderingContext2D) {
        this.callSuper('transform', ctx);

        if (this.ignoreZoom && !this.group && this.canvas) {

            const zoom = 1 / this.canvas.getZoom();

            this.scale(zoom);

            if (this.prevZoom && this.prevZoom !== zoom) {

                this.canvas.requestRenderAll();

            }

            this.prevZoom = zoom;
        }
    },
    _render(ctx: CanvasRenderingContext2D) {
        this.callSuper('_render', ctx);

        ctx.save();

        const coordsText = `${this.lat || 0}' ${this.lng || 0}'`;

        ctx.fillStyle = '#000';
        ctx.font = '14px IBM Plex Sans';
        ctx.textAlign = 'center';
        ctx.strokeStyle = '#fff';
        ctx.strokeText(coordsText, 0, 32);
        ctx.fillText(coordsText, 0, 32);

        ctx.restore();
    },
});

GpsPin.fromObject = (options: any, callback: Function) => {

    const { text = '' } = options;

    return callback(new GpsPin(text, options));
};

//@ts-ignore
window.fabric.GpsPin = GpsPin;

export default GpsPin;
