import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, ILastValueUpdate, ISensor } from '../interfaces';
import { AxiosResponse } from 'axios';
import { GraphDataLastValueService } from '../services/graphDataLastValueService';
import { graphDataLastValueConstants } from '../constants/graphDataLastValueConstants';
import store from '../store';
import { selectGraphDataLastValues } from '../selectors/graphDataLastValue/graphDataLastValueSelector';

/**
 * Process related actions
 *
 * @type {Object}
 */
export const GraphDataLastValueAction = {

    /**
     * Store alert rules
     *
     *
     * @return {Promise<Object>}
     */
    getLastValue: () => {

        const success = (items: ISensor[]) => {

            return {
                type: graphDataLastValueConstants.getGraphDataLastValueSuccess,
               items,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: graphDataLastValueConstants.getGraphDataLastValueSuccess,
                errors,
            };
        }, service = new GraphDataLastValueService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            service.getLastValue().then(({ data }: AxiosResponse) => {
                dispatch(success(data));
            }).catch(error => {
                dispatch(failure(service.errorHandler(error)));
            });
        };
    },

    updateLastValue: (item: ILastValueUpdate) => {

        const success = (items: ISensor[]) => {

            return {
                type: graphDataLastValueConstants.getGraphDataLastValueSuccess,
               items,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: graphDataLastValueConstants.getGraphDataLastValueSuccess,
                errors,
            };
        }, service = new GraphDataLastValueService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            const currentData = selectGraphDataLastValues(store.getState());

            service.updateLocalData(item, currentData).then((data: ISensor[]) => {
                dispatch(success(data));
            }).catch(error => {
                dispatch(failure(service.errorHandler(error)));
            });
        };
    },
};
