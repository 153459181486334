import React from 'react';
import { connect } from 'react-redux';
import roles from './roles';
import { IAuthState } from '../core/interfaces';

/**
 * Check user permission to access given route or action
 *
 * @param {string} action
 * @param {string} route
 *
 * @param {JSX.Element} children
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Can: React.FC<IProps> = ({
    auth,
    action,
    route,
    children,
    onForbidden,
    onUnauthorized,
}) => {

    if (!auth.user) {

        return onUnauthorized || null;
    }

    const { role } = auth.user;

    if (role) {

        const permissions = roles[role] ? roles[role] : null;

        if (permissions) {

            if ((action && permissions.actions.includes(action)) || (route && permissions.routes.includes(route))) {

                return children;
            }
        }
    }

    return onForbidden || null;
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: { auth: IAuthState }) => {

    const { auth } = state;

    return {
        auth,
    };
};

export default connect(mapStateToProps)(Can);

interface IProps {
    auth: IAuthState;
    action?: string;
    route?: string;
    children: any;
    onForbidden?: any;
    onUnauthorized?: any;
}
