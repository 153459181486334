import { graphConstants } from '../../constants';
import { IGraphBarHeightState } from '../../interfaces';


/**
 * Bar chart height reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphBarHeight(state: IGraphBarHeightState = {
    height: graphConstants.barSmallHeight,
}, action: { type: string }): IGraphBarHeightState {

    switch (action.type) {

        case graphConstants.barSwitchNormal:

            return {
                height: graphConstants.barNormalHeight,
            };

        case graphConstants.barSwitchSmall:

            return {
                height: graphConstants.barSmallHeight,
            };

        default:

            return state;
    }
}
