import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as ArrowIcon } from '../../../ui/assets/images/icons/dropdown-arrow.svg';
import { ReactComponent as ZoomInIcon } from '../../../ui/assets/images/icons/zoom-in.svg';
import { RootState } from '../../../store';
import { IStateHoveredState } from '../../../interfaces';

interface IProps {
    sensorId: string | number;
    stateHovered: IStateHoveredState;
    hrMode: boolean;
}

/**
 * State drop-down menu button
 *
 * @param {Object} stateHovered
 * @param {number} screenWidth
 * @param {Date[]} selection
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const StateMenuBtn: React.FC<IProps> = ({ stateHovered, sensorId, hrMode }: IProps) => {

    const isHovered = Object.keys(stateHovered).length > 0;
    if (isHovered) {
        const { state, position } = stateHovered;

        if (position && position && state) {
            if ((stateHovered.sensorId === sensorId) && position.width > 30) {

                // @ts-ignore
                const emptyState = !hrMode ? !state.causeId : !state.zoneName;

                const style = {
                    position: 'absolute' as const,
                    top: emptyState ? 4 : 10,
                    left: emptyState ? position.left : position.left + (position.width - 28),
                    pointerEvents: 'none' as const,
                };

                return emptyState ? <ZoomInIcon style={style} /> : <ArrowIcon style={style} />;
            }
        }
    }

    return null;
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { stateHovered } = state;

    return {
        stateHovered,
    };
};

export default connect(mapStateToProps)(StateMenuBtn);
