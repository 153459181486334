import { alertConstants } from '../../constants';
import { IAlertDetailsState, IStateToggleDetailsAction } from '../../interfaces';


/**
 * Toggle state details reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function alertDetails(state: IAlertDetailsState = {}, action: IStateToggleDetailsAction): IAlertDetailsState {

    switch (action.type) {

        case alertConstants.SHOW_DETAILS:

            return {
                visible: true,
                state: action.state,
            };

        case alertConstants.HIDE_DETAILS:

            return {
                visible: false,
            };

        default:

            return state;
    }
}
