import { useCallback, useState } from 'react';

interface IUseFormHandlersProps<FormValues> {
    setInitialValues: (value: (((prevState: FormValues) => FormValues) | FormValues)) => void
    openSidebar: boolean;
    toggleForm: (opened: boolean, name?: string) => void;
    reloadData: () => void;
    openEditor?: () => void;
}

export const useFormHandlers = <FormValues>({ setInitialValues, toggleForm, openSidebar, reloadData, openEditor }: IUseFormHandlersProps<FormValues>) => {
    const [confirm, setConfirm] = useState(false);

    /**
     *  Handler submit form
     *
     * @param {IFormValues} values
     */
    const handleSubmit = useCallback((values: FormValues) => {
        setConfirm(true);
        setInitialValues(values);
    }, [setConfirm, setInitialValues]);

    const onCancel = useCallback(() => {
        toggleForm(openSidebar);
    }, [openSidebar, toggleForm]);

    /**
     * Handler for the cancel form dialog
     * Reset field form
     */
    const onCancelDialog = useCallback(() => {
        setConfirm(false);
    }, [setConfirm]);

    /**
     * Handler confirm dialog form
     */
    const onConfirmDialog = useCallback(() => {

        reloadData();

        onCancelDialog();

        toggleForm(openSidebar);

        openEditor && openEditor();

    }, [reloadData, onCancelDialog, toggleForm, openSidebar]);

    return {
        confirm,
        handleSubmit,
        onCancel,
        onCancelDialog,
        onConfirmDialog,
    };
};
