import { Action } from 'redux';
import { IHRDashboard, IHRDashboardUpdateModel } from '../../interfaces';
import { IErrors } from '../../../../core/interfaces';

export enum MonitoringTreeActionTypes {
    FETCH_MONITORING_TREE_REQUEST = '[MonitoringTree] Fetch MonitoringTree Request',
    FETCH_MONITORING_TREE_SUCCESS = '[MonitoringTree] Fetch MonitoringTree Success',
    FETCH_MONITORING_TREE_FAILURE = '[MonitoringTree] Fetch MonitoringTree Failure',
    UPDATE_MONITORING_TREE_SUCCESS = '[MonitoringTree] Update MonitoringTree Success',
    UPDATE_MONITORING_TREE_FAILURE = '[MonitoringTree] Update MonitoringTree Failure',
    PROCESS_HR_DATA = '[MonitoringTree] Process Hr Data',
    PROCESS_HR_DATA_UPDATE = '[MonitoringTree] Process Hr Data Update',
    PROCESS_HR_DATA_RANGE_UPDATE = '[MonitoringTree] Process Hr Data Range Update',
    PROCESS_HR_DATA_RANGE = '[MonitoringTree] Process Hr Data Range',
    CLEAR_MONITORING_TREE_ERROR = '[MonitoringTree] Clear MonitoringTree error',
    ENABLE_MINIMAP = '[MonitoringTree] Enable/Disable minimap',
}

export class FetchMonitoringTreeRequest implements Action {

    readonly type = MonitoringTreeActionTypes.FETCH_MONITORING_TREE_REQUEST;
}

export class FetchMonitoringTreeSuccess implements Action {

    readonly type = MonitoringTreeActionTypes.FETCH_MONITORING_TREE_SUCCESS;

    constructor(
        public payload: {
            monitoringTree: IHRDashboard
        },
    ) {}
}

export class FetchMonitoringTreeFailure implements Action {

    readonly type = MonitoringTreeActionTypes.FETCH_MONITORING_TREE_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        },
    ) {}
}

export class UpdateMonitoringTreeSuccess implements Action {

    readonly type = MonitoringTreeActionTypes.UPDATE_MONITORING_TREE_SUCCESS;

    constructor(
        public payload: {
            monitoringTree: IHRDashboardUpdateModel,
        },
    ) {}
}

export class UpdateMonitoringTreeFailure implements Action {

    readonly type = MonitoringTreeActionTypes.UPDATE_MONITORING_TREE_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        },
    ) {}
}
export class ProcessHrData implements Action {

    readonly type = MonitoringTreeActionTypes.PROCESS_HR_DATA;

    constructor(
        public payload: {
            dataHr: any,
            override: boolean,
            range: boolean
        },
    ) {}
}

export class ProcessHrDataUpdate implements Action {

    readonly type = MonitoringTreeActionTypes.PROCESS_HR_DATA_UPDATE;

    constructor(
        public payload: {
            dataHr: any,
        },
    ) {}
}

export class ProcessHrDataRange implements Action {

    readonly type = MonitoringTreeActionTypes.PROCESS_HR_DATA_RANGE;

    constructor(
        public payload: {
            dataHrRange: any,
            override: boolean,
            range: boolean
        },
    ) {}
}

export class ProcessHrDataRangeUpdate implements Action {

    readonly type = MonitoringTreeActionTypes.PROCESS_HR_DATA_RANGE_UPDATE;

    constructor(
        public payload: {
            dataHrRange: any,
        },
    ) {}
}

export class ClearMonitoringTreeError implements Action {

    readonly type = MonitoringTreeActionTypes.CLEAR_MONITORING_TREE_ERROR;
}


export class ToggleMinimapMonitoringTree implements Action {

    readonly type = MonitoringTreeActionTypes.ENABLE_MINIMAP;

    constructor(
        public payload: {
            enableMinimap: any,
        },
    ) {}
}

export type MonitoringTreeActions =
    FetchMonitoringTreeRequest |
    FetchMonitoringTreeSuccess |
    FetchMonitoringTreeFailure |
    UpdateMonitoringTreeFailure |
    UpdateMonitoringTreeSuccess |
    ClearMonitoringTreeError |
    ProcessHrData |
    ProcessHrDataRange |
    ProcessHrDataRangeUpdate |
    ToggleMinimapMonitoringTree |
    ProcessHrDataUpdate;
