import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core';
import { isIE } from 'react-device-detect';

import AddAction from './AddAction/AddAction';
import Location from './Location/Location';
import Layer from './Layer/Layer';

import { ReactComponent as HRIcon } from '../../ui/assets/images/icons/hr.svg';
import { ReactComponent as DropdownArrowIcon } from '../../ui/assets/images/icons/dropdown-arrow.svg';

import { ILayer, ILocation } from '../../interfaces';

import './Configuration.scss';
import { FormActions } from '../../../../core/actions';
import { locationThunks } from '../../store/thunks/locationThunks';
import { selectLocationsAsc } from '../../store/selectors/locationSelector';
import { layerThunks } from '../../store/thunks/layerThunks';
import { noop } from '../../../../base/helpers/noop';
import DeleteDialog from '../../ui/components/Dialog/DeleteDialog';
import { IcomoonPreloader } from '../../ui/components/IcomoonPreloader/IcomoonPreloader';

/**
 * HR Configuration tree component
 *
 * @class Configuration
 */
class Configuration extends React.Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {
        super(props);

        this.openLocationForm = this.openLocationForm.bind(this);
        this.openLocationEditor = this.openLocationEditor.bind(this);
        this.openLayerForm = this.openLayerForm.bind(this);
        this.openDeleteLocationModal = this.openDeleteLocationModal.bind(this);
        this.openDeleteLayerModal = this.openDeleteLayerModal.bind(this);
        this.onConfirmDeleteLocation = this.onConfirmDeleteLocation.bind(this);
        this.onConfirmDeleteLayer = this.onConfirmDeleteLayer.bind(this);
        this.onCloseDeleteDialog = this.onCloseDeleteDialog.bind(this);
    }

    state: IState = {
        removeLayer: null,
        removeLocation: null,
    };

    componentDidMount(): void {
        this.props.fetchLocations();
    }

    async openLocationForm(location: ILocation | null = null) {
        await this.props.openLocationFormWithModel(location);
        this.props.toggleForm(false, 'hr-location');
    }

    openLocationEditor(location: ILocation) {
        this.props.openLocationEditorWithModel(location);
        this.props.toggleForm(false, 'editor');
    }

    async openLayerForm(location: ILocation, layer: ILayer | null = null) {
        await this.props.openLayerFormWithModel(location, layer);
        this.props.toggleForm(false, 'hr-layer');
    }

    openDeleteLocationModal(location: ILocation) {
        this.setState({ removeLocation: location });
    }

    openDeleteLayerModal(layer: ILayer) {
        this.setState({ removeLayer: layer });
    }

    onCloseDeleteDialog() {
        this.setState({
            removeLocation: null,
            removeLayer: null,
        });
    }

    onConfirmDeleteLocation() {
        const removeLocation = this.state.removeLocation;

        if (!removeLocation) {
            return;
        }

        this.props.deleteLocation(removeLocation);
    }

    onConfirmDeleteLayer() {
        const removeLayer = this.state.removeLayer;

        if (!removeLayer) {
            return;
        }

        this.props.deleteLayer(removeLayer);
    }

    /**
     * Component render
     *
     * @returns {JSX.Element}
     */
    render() {

        const { t, scrollLeft, width } = this.props;

        const { locations } = this.props;

        const { removeLocation, removeLayer } = this.state;

        return (
            <section className="wrap-hr" style={{ width: width }}>
                <div className="title-block-wrap">
                    <div className="title-block padding-1"
                         style={{
                             position: isIE ? 'relative' : 'sticky',
                             left: isIE ? scrollLeft : 0,
                             height: 40,
                         }}
                    >
                        <HRIcon style={{ height: 25, width: 25 }} />
                        {t('HR_GRAPH')}
                        <IcomoonPreloader />
                    </div>
                </div>
                {locations.length > 0 ?
                    locations.map((location: ILocation) => (

                        <Accordion
                            square
                            className="expansion-panel-hr"
                            key={location.id}
                        >
                            <AccordionSummary
                                id={'expansion-header-Hr-' + location.id}
                                aria-controls={'expansion-panel-Hr-' + location.id}
                                className="panel-title"
                            >
                                <Location
                                    location={location}
                                    padding="padding-0"
                                    scrollLeft={scrollLeft}
                                    onOpen={this.openLocationForm}
                                    onEditor={this.openLocationEditor}
                                    onDelete={this.openDeleteLocationModal}
                                    expandIcon={<DropdownArrowIcon />}
                                />
                            </AccordionSummary>
                            <AccordionDetails className="panel-detail">
                                {location.layers.map((layer: ILayer) => (
                                    <Layer
                                        key={layer.id}
                                        location={location}
                                        layer={layer}
                                        padding="padding-2"
                                        scrollLeft={scrollLeft}
                                        onOpen={this.openLayerForm}
                                        onDelete={this.openDeleteLayerModal}
                                    />
                                ))}
                            </AccordionDetails>
                            <AddAction
                                title={t('ADD_LAYER')}
                                parent={location}
                                action={this.openLayerForm}
                                disabled={!location.picture}
                                padding="padding-2"
                                scrollLeft={scrollLeft}
                            />
                        </Accordion>
                    ))
                    :
                    <Accordion
                        square
                        className="expansion-panel-hr"
                        defaultExpanded
                    >
                        <AccordionSummary
                            id="expansion-header-hr-1"
                            aria-controls="expansion-panel-hr-1"
                            className="panel-title"
                        >
                            <AddAction
                                title={t('ADD_LOCATION')}
                                action={this.openLocationForm}
                                disabled={false}
                                padding="padding-1"
                                scrollLeft={scrollLeft}
                                expandIcon={<DropdownArrowIcon />}
                            />
                        </AccordionSummary>
                        <AccordionDetails className="panel-detail">
                            <AddAction
                                title={t('ADD_LAYER')}
                                action={noop}
                                disabled
                                padding="padding-2"
                                scrollLeft={scrollLeft}
                            />
                        </AccordionDetails>
                    </Accordion>
                }
                {locations.length > 0 &&
                    <AddAction
                        title={t('ADD_LOCATION')}
                        action={this.openLocationForm}
                        disabled={false}
                        padding="padding-1"
                        scrollLeft={scrollLeft}
                    />
                }
                {
                    removeLocation &&
                    <DeleteDialog
                        open
                        removeId={removeLocation.id}
                        heading={t('DELETE_LOCATION_Q', { name: removeLocation.name })}
                        body={t('THIS_ACTION_WILL_DELETE_LOCATION_AND_CANNOT_BE_UNDONE')}
                        onAccept={this.onConfirmDeleteLocation}
                        onClose={this.onCloseDeleteDialog}
                    />
                }
                {
                    removeLayer &&
                    <DeleteDialog
                        open
                        removeId={removeLayer.id}
                        heading={t('DELETE_LAYER_Q', { name: removeLayer.name })}
                        body={t('THIS_ACTION_WILL_DELETE_LAYER_AND_CANNOT_BE_UNDONE')}
                        onAccept={this.onConfirmDeleteLayer}
                        onClose={this.onCloseDeleteDialog}
                    />
                }
            </section>
        );
    }
}

const mapStateToProps = (state: any) => ({
    locations: selectLocationsAsc(state),
});

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
    openLocationFormWithModel: locationThunks.openForm,
    openLocationEditorWithModel: locationThunks.openEditor,
    openLayerFormWithModel: layerThunks.openForm,
    fetchLocations: locationThunks.fetchLocations,
    deleteLocation: locationThunks.deleteLocation,
    deleteLayer: layerThunks.deleteLayer,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Configuration));

interface IProps {
    scrollLeft: number;
    toggleForm: (opened: boolean, name?: string) => void;
    openLocationFormWithModel: (model: ILocation | null) => Promise<void>;
    openLocationEditorWithModel: (model: ILocation | null) => void;
    openLayerFormWithModel: (location: ILocation, model: ILayer | null) => Promise<void>;
    deleteLocation: (location: ILocation) => void;
    deleteLayer: (layer: ILayer) => void;
    locations: ILocation[];
    fetchLocations: () => void;
    width: number;
}

interface IState {
    removeLocation: ILocation | null;
    removeLayer: ILayer | null;
}
