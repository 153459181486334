import { Action } from 'redux';
import { IBeacon } from '../../interfaces';
import { IErrors } from '../../../../core/interfaces';
import { IBeaconIndentOnly } from '../../services/beaconService';

export enum BeaconActionTypes {
    FETCH_BEACONS_REQUEST = '[Beacon] Fetch Beacons Request',
    FETCH_BEACONS_SUCCESS = '[Beacon] Fetch Beacons Success',
    FETCH_BEACONS_FAILURE = '[Beacon] Fetch Beacons Failure',
    SAVE_BEACON_ACTION = '[Beacon] Save Beacon Action',
    DELETE_BEACON_ACTION = '[Beacon] Delete Beacon Action',
    CLEAR_BEACON_ERROR = '[Beacon] Clear Beacon error',
}

export class FetchBeaconsRequest implements Action {

    readonly type = BeaconActionTypes.FETCH_BEACONS_REQUEST;
}

export class FetchBeaconsSuccess implements Action {

    readonly type = BeaconActionTypes.FETCH_BEACONS_SUCCESS;

    constructor(
        public payload: {
            beacons: IBeacon[]
        },
    ) {}
}

export class FetchBeaconsFailure implements Action {

    readonly type = BeaconActionTypes.FETCH_BEACONS_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        },
    ) {}
}

/**
 * Save beacon action
 *
 * @class SaveBeaconAction
 */
export class SaveBeaconAction implements Action {
    readonly type = BeaconActionTypes.SAVE_BEACON_ACTION;

    constructor(
        public payload: {
            beacon: IBeacon,
        },
    ) {}
}

/**
 * Delete beacon action
 *
 * @class DeleteBeaconAction
 */
export class DeleteBeaconAction implements Action {
    readonly type = BeaconActionTypes.DELETE_BEACON_ACTION;

    constructor(
        public payload: {
            beacon: IBeaconIndentOnly
        },
    ) {}
}


export class ClearBeaconError implements Action {

    readonly type = BeaconActionTypes.CLEAR_BEACON_ERROR;
}

export type BeaconActions =
    FetchBeaconsRequest |
    FetchBeaconsSuccess |
    FetchBeaconsFailure |
    SaveBeaconAction |
    ClearBeaconError |
    DeleteBeaconAction;
