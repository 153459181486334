import React from 'react';
import Unit from './Unit';
import { IFlatTreeProcess, IMonitoringTreeProcessProps } from '../../../../core/interfaces';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectFlatMonitoringTreeItemById } from '../../../../core/selectors/monitoringTree/monitoringTreeSelector';


/**
 * Monitoring tree process functional component
 *
 * @param {Object} process
 * @param {number} factoryIndex
 * @param {number} index
 * @param {Object} props
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Process: React.FC<IMonitoringTreeProcessProps> = (
    {
        processID,
        factoryIndex,
        index,
        ...props
    }: IMonitoringTreeProcessProps,
) => {

    const process = useParamSelector(selectFlatMonitoringTreeItemById, processID, 'process') as IFlatTreeProcess;

    if (!process) {

        return null;
    }

    return (
        <React.Fragment>
            {process.data.map((unit, unitIndex: number) =>
                (
                    <Unit key={'unit-list-item-' + unit + unitIndex}
                      unitID={unit}
                      process={process}
                      index={unitIndex}
                      processIndex={index}
                      factoryIndex={factoryIndex}
                      {...props}
                    />
                ),
            )}
        </React.Fragment>
    );
};

export default React.memo(Process);
