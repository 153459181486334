import { Action } from 'redux';
import { EditorMode, IGpsPin, IGpsPinIdent, ILocation } from '../../interfaces';

export enum LocationFormActionTypes {
    RESET_WITH_LOCATION_MODEL = '[Location Form] Reset with location model',
    SAVE_LOCATION_FORM_REQUEST = '[Location Form] Save Location Form Request',
    SAVE_LOCATION_FORM_SUCCESS = '[Location Form] Save Location Form Success',
    SAVE_LOCATION_FORM_FAILURE = '[Location Form] Save Location Form Failure',
    SET_EDITOR_MODE_ACTION = '[Location Form] Set Editor Mode Action',
    SAVE_GPS_PIN_IN_LOCATION_FORM = '[Location Form] Set Gps pin in location form action',
    REMOVE_GPS_PIN_IN_LOCATION_FORM = '[Location Form] Remove Gps pin in location form action',
    CLEAR_INVALID_ERROR = '[Location Form] Clear error key',
}

export class SaveLocationFormRequest implements Action {
    readonly type = LocationFormActionTypes.SAVE_LOCATION_FORM_REQUEST;
}

export class SaveLocationFormSuccess implements Action {
    readonly type = LocationFormActionTypes.SAVE_LOCATION_FORM_SUCCESS;

    constructor(public payload: {
        location: ILocation
    }) {
    }
}

export class SaveLocationFormFailure implements Action {
    readonly type = LocationFormActionTypes.SAVE_LOCATION_FORM_FAILURE;

    constructor(public payload: {
        error: any
    }) {
    }
}

export class ClearInvalidError implements Action {
    readonly type = LocationFormActionTypes.CLEAR_INVALID_ERROR;
}

export class ResetWithLocationModelAction implements Action {
    readonly type = LocationFormActionTypes.RESET_WITH_LOCATION_MODEL;

    constructor(public payload: {
        location: ILocation | null,
    }) {
    }
}

export class SetEditorModeAction implements Action {
    readonly type = LocationFormActionTypes.SET_EDITOR_MODE_ACTION;

    constructor(public payload: {
        mode: EditorMode,
    }) {
    }
}

export class SaveGpsPinInLocationForm implements Action {
    readonly type = LocationFormActionTypes.SAVE_GPS_PIN_IN_LOCATION_FORM;

    constructor(public payload: {
        key: IGpsPinIdent,
        model: Partial<IGpsPin>,
    }) {
    }
}

export class DeleteGpsPinInLocationForm implements Action {
    readonly type = LocationFormActionTypes.REMOVE_GPS_PIN_IN_LOCATION_FORM;

    constructor(public payload: {
        key: IGpsPinIdent
    }) {
    }
}

export type LocationFormActions =
    SaveLocationFormRequest |
    SaveLocationFormSuccess |
    SaveLocationFormFailure |
    ResetWithLocationModelAction |
    SetEditorModeAction |
    SaveGpsPinInLocationForm |
    DeleteGpsPinInLocationForm |
    ClearInvalidError;
