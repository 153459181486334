import React, { useCallback } from 'react';
import { Dialog as MuiDialog, DialogProps, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'reshadow';

import Button from '../Button/Button';

const styles = require('./Dialog.scss');

/**
 * An entity confirmation dialog
 *
 * @param {boolean} open Dialog open/close switcher
 * @param {string} heading Dialog heading text
 * @param {string} body Dialog body text
 * @param {function} onAccept Callback when user confirms delete
 * @param {number} removeId An entity ID to remove
 * @param {function} onClose Callback when user denies delete
 * @param {object} props The rest props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const DeleteDialog: React.FC<DeleteDialogProps> = ({
    open,
    heading,
    body,
    onAccept,
    removeId,
    onClose,
    ...props
}) => {

    const { t } = useTranslation();

    const handleClose = useCallback(
        e => {
            e.stopPropagation();
            onClose && onClose({}, 'backdropClick');
        },
        [onClose],
    );

    return styled(styles)(
        <MuiDialog
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            open={open}
            onClose={handleClose}
            {...props}
            disableEnforceFocus
        >
            <div className="dialog-popup">
                <h2 id="dialog-title">{heading}</h2>
                <p id="dialog-description">{body}</p>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="delete-popup-cancel-button"
                            type="button"
                            color={'primary'}
                            onClick={handleClose}
                        >{t('CANCEL')}
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="delete-popup-delete-button"
                            type="button"
                            color={'primary'}
                            style={{ color: 'red' }}
                            onClick={(e) => {

                                if (removeId || removeId === 0) {

                                    onAccept(removeId);
                                }

                                handleClose(e);
                            }}
                            disableFocusRipple
                            autoFocus
                        >{t('DELETE')}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </MuiDialog>,
    );
};

interface IProps {
    heading: string;
    body?: string;
    onAccept: (id: number) => void;
    removeId: number | null;
}

export type DeleteDialogProps = IProps & DialogProps;

export default DeleteDialog;
