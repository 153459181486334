export function getWidthFromMousePosition(position: { x: number } | null): number {

    if (!position) {

        return 0;
    }

    const minWidth = 500;

    const { body: { offsetWidth, offsetLeft } } = document;

    const newWidth = offsetWidth - (position.x - offsetLeft);

    return newWidth > minWidth ? newWidth : minWidth;
}
