import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardActions, ProductActions } from '../../core/actions';
import { selectAppSettings } from '../../core/selectors/appSetting/appSettingSelector';
import { appConfig } from '../../config/appConfig';
import { AppRoles } from '../../rbac/roles';
import { history } from '../../helpers';
import { isMobile } from 'react-device-detect';

import {
    selectBrushSelection,
} from '../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import {
    selectMonitoringTree,
    selectUnitsForCurrentDashboard,
} from '../../core/selectors/monitoringTree/monitoringTreeSelector';
import { selectCurrentUser } from '../../core/selectors/auth/authSelector';
import { selectGraphPeriodRange } from '../../core/selectors/graphPeriod/graphPeriodSelector';
import { usePrevious } from '../usePrevious';
import {
    selectMaxWidthSideBar,
} from '../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { GraphDataAction } from '../../base/store/actions';

export const useMainSocket = (): void => {

        const dispatch = useDispatch();

    const sideBarMaxWidth = useSelector(selectMaxWidthSideBar),
        prevSideBarMaxWidth = usePrevious(sideBarMaxWidth),
        settings = useSelector(selectAppSettings),
        brushSelection = useSelector(selectBrushSelection),
        arrOfUnit = useSelector(selectUnitsForCurrentDashboard),
        user = useSelector(selectCurrentUser),
        brushRange = useSelector(selectGraphPeriodRange),
        monitoringTree = useSelector(selectMonitoringTree),
        monitoringTreeLength = monitoringTree.length > 0;

    /**
     * Update screen width available for charts
     */
    const updateScreenWidth = useCallback(() => {
        if (prevSideBarMaxWidth !== sideBarMaxWidth) {

            const sideBarWidth = JSON.parse(localStorage.getItem('sidebar') as string) ? appConfig.dashboardLeftOffsetSmall : appConfig.dashboardLeftOffsetLarge;

            const screenWidth = window.innerWidth < 960 ? 960 - sideBarWidth : window.innerWidth - sideBarWidth;

            dispatch(DashboardActions.updateScreenWidth(screenWidth));
        }
    }, [
        dispatch,
        sideBarMaxWidth,
        prevSideBarMaxWidth,
    ]);


    useEffect(() => {

        window.addEventListener('resize', updateScreenWidth);

        if (isMobile) {
            window.addEventListener('orientationchange', updateScreenWidth);
        }

        return () => {
            window.removeEventListener('resize', updateScreenWidth);

            if (isMobile) {
                window.removeEventListener('orientationchange', updateScreenWidth);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (monitoringTree.length === 0) {

            dispatch(DashboardActions.processSensorsDataLoaded);

        }

    }, [monitoringTree, dispatch]);


    useLayoutEffect(() => {

        updateScreenWidth();

    }, [
        updateScreenWidth,
    ]);

    useEffect(() => {
        if (!settings.isConfigured && !settings.hasDashboards && user && user.role === AppRoles.SUPER) {

            history.push('/configuration');

        }
    }, [settings, user]);

    useEffect(() => {

        if (monitoringTreeLength) {

            const { startDate, endDate } = brushRange;

            startDate.setSeconds(0);
            startDate.setMilliseconds(0);

            endDate.setSeconds(0);
            endDate.setMilliseconds(0);

            dispatch(GraphDataAction.getHistogramData(
                new Date(startDate).toISOString(),
                new Date(endDate).toISOString(),
            ));
        }

    }, [
        dispatch,
        brushRange,
        monitoringTreeLength,
    ]);

    useEffect(() => {

        if (monitoringTreeLength) {

            const [from, to] = brushSelection;

            const { startDate, endDate } = brushRange;

            startDate.setSeconds(0);
            startDate.setMilliseconds(0);

            endDate.setSeconds(0);
            endDate.setMilliseconds(0);

            if (from.getTime() === startDate.getTime() && to.getTime() === endDate.getTime()) {
                return;
            } 

            from.setSeconds(0);
            from.setMilliseconds(0);

            to.setSeconds(0);
            to.setMilliseconds(0);

            dispatch(GraphDataAction.getHistogramDataRange(
                new Date(from).toISOString(),
                new Date(to).toISOString(),
            ));
        }

    }, [
        dispatch,
        brushSelection,
        monitoringTreeLength,
        brushRange,
    ]);

    useEffect(() => {

        if (arrOfUnit.length > 0) {

            const { startDate, endDate } = brushRange;

            startDate.setSeconds(0);
            startDate.setMilliseconds(0);

            endDate.setSeconds(0);
            endDate.setMilliseconds(0);

            dispatch(ProductActions.getActivateProductsOnLineAction({
                startTime: new Date(startDate).toISOString(),
                endTime: new Date(endDate).toISOString(),
                units: arrOfUnit,
            }));
        }

    }, [arrOfUnit, brushRange, dispatch]);

};