import { stateConstants } from '../../constants/';
import { IStateChangeAction, IStateChangeState } from '../../interfaces';

/**
 * User add/update/delete reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function stateChange(state: IStateChangeState = {
    errors: {},
    state: null,
    unit: null,
}, action: IStateChangeAction): IStateChangeState {

    switch (action.type) {

        case stateConstants.STORE_STATES_ITEM_SUCCESS:
        case stateConstants.UPDATE_SUCCESS:
        case stateConstants.DELETE_SUCCESS:

            return {
                state: action.state.data,
                unit: action.state.unit,
                errors: {},
            };

        case stateConstants.STORE_STATES_ITEM_FAILURE:
        case stateConstants.UPDATE_FAILURE:
        case stateConstants.DELETE_FAILURE:

            return {
                state: null,
                unit: null,
                errors: action.errors,
            };

        default:

            return state;
    }
}
