import { v4 } from 'uuid';
import { EventHandler as BaseEventHandler } from '../../../../../../base/components/Editor/canvas/handlers/EventHandler';
import { FabricObject, IFabricEvent } from '../../../../../../base/components/Editor/interfaces';
import { editorConstants } from '../../constants';
import { changeSizeByTypeObjectWithScale } from '../../functions/changeSizeByTypeObject';
import { isMobile } from 'react-device-detect';

export class EventHandler extends BaseEventHandler {

    /**
     * Mouse down event handler
     *
     * @param {IFabricEvent} evt
     */
    onMouseDown(evt: IFabricEvent): void {

        if (isMobile) {

            super.onMouseMove(evt);

            const { target } = evt,
                { onHover } = this.handler;

            if (target && onHover) {

                onHover(target);
            }
        }


        if (this.handler.interactionMode === editorConstants.interaction.modeGrab) {

            this.panning = true;

            return;
        }

        const event = evt as IFabricEvent<MouseEvent>;

        const { editable } = this.handler;

        const { target } = event;

        if (editable) {

            if (this.handler.interactionMode === editorConstants.interaction.modePolygon) {

                this.handler.canvas.discardActiveObject();

                if (target && this.handler.pointArray.length && target.id === this.handler.pointArray[0].id) {

                    if (this.handler.pointArray.length < 3) {

                        return;
                    }

                    this.handler.drawingHandler.polygon.finish(this.handler.pointArray);

                } else {

                    this.handler.drawingHandler.polygon.addPoint(event);
                }

            } else {

                const { absolutePointer } = event;

                if (absolutePointer) {

                    const { x, y } = absolutePointer;

                    const objectsMapping = {
                        [editorConstants.interaction.modeEngine]: editorConstants.objects.engine,
                        [editorConstants.interaction.modeSensor]: editorConstants.objects.sensor,
                        [editorConstants.interaction.modeValve]: editorConstants.objects.valve,
                        [editorConstants.interaction.modeIndicator]: editorConstants.objects.indicator,
                        [editorConstants.interaction.modeRectangle]: editorConstants.objects.rectangle,
                        [editorConstants.interaction.modeOval]: editorConstants.objects.oval,
                        [editorConstants.interaction.modeTriangle]: editorConstants.objects.triangle,
                        [editorConstants.interaction.modeStar]: editorConstants.objects.star,
                    };

                    const optionsMapping = {
                        [editorConstants.interaction.modeRectangle]: {
                            strokeWidth: 3,
                            stroke: '#74797d',
                            fill: '#cdd0d4',
                            opacity: 1,
                            width: 18,
                            height: 18,
                            left: x - 9,
                            top: y - 9,
                            objectCaching: false,
                        },
                        [editorConstants.interaction.modeOval]: {
                            strokeWidth: 1,
                            stroke: '#74797d',
                            fill: '#cdd0d4',
                            opacity: 1,
                            radius: 9,
                            left: x - 9,
                            top: y - 9,
                            objectCaching: false,
                        },
                        [editorConstants.interaction.modeTriangle]: {
                            strokeWidth: 1,
                            stroke: '#74797d',
                            fill: '#cdd0d4',
                            opacity: 1,
                            width: 18,
                            height: 18,
                            left: x - 9,
                            top: y - 9,
                            objectCaching: false,
                        },
                    };

                    const defaultOptions = {
                        id: v4(),
                        type: objectsMapping[this.handler.interactionMode],
                        left: x,
                        top: y,
                        zIndex: this.handler.canvas.getObjects().length + 10,
                        pidCodePosition: 'top',
                    };

                    this.handler.add({
                        ...defaultOptions,
                        ...optionsMapping[this.handler.interactionMode],
                    });

                    const addedObject: undefined | FabricObject = this.handler.canvas.getObjects().find((value: FabricObject) => value.id === defaultOptions.id);

                    if (addedObject) {

                        const labelItemOption = {
                            id: v4(),
                            type: 'pitCode',
                            label: 'Select sensor',
                            left: addedObject.left || 0,
                            top: addedObject.top || 0,
                            objectId: 0,
                            modeEditor: false,
                            unitId: undefined,
                            factoryId: undefined,
                            objectType: defaultOptions.type,
                            layoutNumber: 1,
                            zIndex: this.handler.canvas.getObjects().length + 1000,
                            dataValue: 'No data',
                            suppressCallback: true,
                            itemId: defaultOptions.id,
                            pidCodePosition: 'top',
                            schemaId: 0,
                            visible: true,
                            fontSize: addedObject.fontSizeLabel,
                        };


                        this.handler.add(labelItemOption);
                    }

                    this.handler.interactionHandler.selection();
                }
            }
        }
    }

    /**
     * Mouse move event handler
     *
     * @param {IFabricEvent} evt
     */
    onMouseMove(evt: IFabricEvent): void {

        super.onMouseMove(evt);
        const { target } = evt,
            { onHover } = this.handler;

        if (target && onHover) {

            onHover(target);
        }
    }

    /**
     * Scaling event handler
     *
     * @param {IFabricEvent} evt
     */
    onObjectScaling(evt: IFabricEvent): void {

        const obj = this.handler.canvas.getActiveObject();

        if (obj && obj.type && changeSizeByTypeObjectWithScale(obj?.type || 'default')) {

            obj.set({
                dirty: true,
                scaleX: obj.scaleX,
                scaleY: obj.scaleY,
                // objectCaching: false,
            });

        } else if (obj?.type !== 'polygon')  {

            const w = obj.width! * obj.scaleX!,
                h = obj.height! * obj.scaleY!,
                s = obj.strokeWidth;

            obj.set({
                dirty: true,
                height: Math.abs(h),
                width: Math.abs(w),
                scaleX: 1,
                scaleY: 1,
                // objectCaching: false,
            });

            obj.setCoords();
        }

        //Smooth movement of the pid-code behind the object
        this.handler.canvas.forEachObject((newObject: FabricObject) => {

            if (obj && (obj as FabricObject).id === newObject.itemId) {

                const position = this.handler.getPositionRelativeToObject(obj, newObject);

                newObject.set(position);
                newObject.setCoords();

            }
        });

    }
}
