import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { calculateScrollbarWidth } from '../../helpers/calculateScrollbarWidthTreeSlider';

export const selectDashboardStore = (state: RootState) => state.dashboard;

export const selectDashboard = createSelector(
    selectDashboardStore,
    dashboard => dashboard.dashboard,
);

export const selectDashboards = createSelector(
    selectDashboardStore,
    dashboard => dashboard.dashboards,
);

export const selectRefreshListDashboard = createSelector(
    selectDashboardStore,
    dashboard => dashboard.refreshListDashboard,
);

export const selectScreenWidth = createSelector(
    selectDashboardStore,
    calculateScrollbarWidth,
    (dashboard, scrollWidth) => dashboard.screenWidth - scrollWidth,

);

export const selectDashboardOnline = createSelector(
    selectDashboardStore,
    dashboard => dashboard.online,
);

export const selectAllSensorDataForHistogram = createSelector(
    selectDashboardStore,
    dashboard => dashboard.sensorsData,
);

export const selectAllSensorDataForHistogramRange = createSelector(
    selectDashboardStore,
    dashboard => dashboard.sensorsDataRange,
);

export const selectOverrideSensors = createSelector(
    selectDashboardStore,
    dashboard => dashboard.overrideSensors || [],
);

export const selectOverrideSensorsRange = createSelector(
    selectDashboardStore,
    dashboard => dashboard.overrideSensorsRange || [],
);

export const selectDashboardLastUpdate = createSelector(
    selectDashboardStore,
    dashboard => dashboard.lastUpdate,
);
