import { CanvasObject as BaseCanvasObject } from '../../../../../base/components/Editor/canvas/CanvasObject';
import { ICanvasObjectSchema } from '../../../../../base/components/Editor/interfaces';
import Marker from '../objects/Marker';
import GpsPin from '../objects/GpsPin';

const CanvasObject: ICanvasObjectSchema = { ...BaseCanvasObject };

CanvasObject.marker = {

    create: ({ text, ...options }) => {

        return new Marker(text, options);
    },
};

CanvasObject.gpsPin = {

    create: ({ text, ...options }) => {

        return new GpsPin(text, options);
    },
};

export default CanvasObject;
