export const processConstants = {
    LIST_SUCCESS: 'PROCESS_LIST_SUCCESS',
    LIST_FAILURE: 'PROCESS_LIST_FAILURE',

    BULK_UPDATE_SUCCESS: 'PROCESS_BULK_UPDATE_SUCCESS',
    BULK_UPDATE_FAILURE: 'PROCESS_BULK_UPDATE_FAILURE',

    REFRESH_TREE: 'PROCESS_REFRESH_TREE',

    CLEAR_PROCESS_CHANGE_ERROR: 'CLEAR_PROCESS_CHANGE_ERROR',
};
