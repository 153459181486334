import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';
import { ILastValueUpdate, ISensor } from '../interfaces';

export class GraphDataLastValueService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {
        return '/graph-data/last-values';
    }

    /**
     *
     * @returns
     */
    async getLastValue(): Promise<AxiosResponse> {

        return this.http
            .post(this.url(), {
                'sensors': 0,
            }, {
                headers: this.headers,
            });
    }

    /**
     * Update local store
     * @param {ILastValueUpdate} updateItem
     * @param {ISensor[]} lastValueData
     * @return {Promise<ISensor[]>}
     */
    async updateLocalData(updateItem: ILastValueUpdate, lastValueData: ISensor[]): Promise<ISensor[]> {

        return lastValueData.map(sensor => {

            if (sensor.sensorId === updateItem.sensorId && sensor.controllerId === updateItem.controllerId) {
                sensor.latestValue = updateItem.value;
            }

            return sensor;
        });
    }
}