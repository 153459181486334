import { causesConstants, formConstants, stateConstants } from '../../constants';
import { IFormAction, IFormState } from '../../interfaces';

/**
 * Form reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function form(state: IFormState = {
    formOpened: false,
    dialogOpened: false,
}, action: IFormAction): IFormState {

    switch (action.type) {

        case formConstants.OPEN:

            return {
                ...state,
                formOpened: true,
                formName: action.name,
                formModel: action.model,
            };

        case causesConstants.STORE_CAUSES_SUCCESS:
        case causesConstants.UPDATE_SUCCESS:
        case stateConstants.STORE_CATEGORY_SUCCESS:
        case stateConstants.UPDATE_STATES_SUCCESS:
        case formConstants.CLOSE:

            return {
                ...state,
                formOpened: false,
                formName: action.name,
            };

        case formConstants.DIALOG_OPEN:

            return {
                ...state,
                dialogOpened: true,
            };

        case formConstants.DIALOG_CLOSE:

            return {
                ...state,
                dialogOpened: false,
            };

        default:

            return state;
    }
}
