import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { selectDashboardStore } from '../dashboard/dashboardSelector';
import { selectGraphMinimapBrushRule } from '../graphMinimapBrush/graphMinimapBrushSelector';
import { IHistogramData } from '../../interfaces';
import { Merge } from '../../../helpers/mergeType';
import { IHrState } from '../../../modules/Hr/store/reducers';

const selectmonitoringTreeStateHr = (state: Merge<RootState | { hr: IHrState }>)=> state.hr.monitoringTreeState;

const getSensorData = createSelector(
    selectmonitoringTreeStateHr,
    dashboard=> dashboard.hrData,
);

const getSensorDataRange = createSelector(
    selectmonitoringTreeStateHr,
    dashboard=> dashboard.hrDataRange,
);

export const getDataRangeRole = createSelector(
    selectDashboardStore,
    dashboard=> dashboard.range,
);

const getSensorId = (state: RootState, sensorId: number) => sensorId;

export const selectSensorHrDataBySensorId = createSelector(
    getSensorData,
    getSensorDataRange,
    getDataRangeRole,
    selectGraphMinimapBrushRule,
    getSensorId,
    (sensorsData, sensorsDataRange, range, brushRule, sensorId) =>
        (!brushRule ? sensorsData : sensorsDataRange).find(sensorData => sensorData.id === sensorId
        && sensorData.type === 'graph') as IHistogramData,
);
