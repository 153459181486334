import { causesConstants, formConstants, stateConstants } from '../../constants';
import { IStateListAction, IStateListState } from '../../interfaces';

/**
 * User list reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function statesList(state: IStateListState = {
    states: [],
    errors: {},
    refresh: Date.now(),
}, action: IStateListAction): IStateListState {

    switch (action.type) {

        case stateConstants.LIST_SUCCESS:

            return {
                ...state,
                states: action.states,
                errors: {},
            };

        case stateConstants.LIST_FAILURE:

            return {
                ...state,
                states: [],
                errors: action.errors,
            };

        case stateConstants.STORE_CATEGORY_FAILURE:
        case causesConstants.STORE_CAUSES_FAILURE:
        case causesConstants.UPDATE_FAILURE:
        case causesConstants.DELETE_FAILURE:

            return {
                ...state,
                errors: action.errors,
            }; 
        case stateConstants.UPDATE_STATES_FAILURE:

            return {
                ...state,
                errors: action.errors,
            };

        case formConstants.CLOSE:

            return {
                ...state,
                errors: {},
            };

        case stateConstants.STORE_CATEGORY_SUCCESS:
        case stateConstants.STORE_REASON_SUCCESS:
        case causesConstants.STORE_CAUSES_SUCCESS:
        case stateConstants.STORE_ON_DRAG_END_SUCCESS:
        case stateConstants.UPDATE_STATES_SUCCESS:
        case stateConstants.UPDATE_REASON_SUCCESS:
        case causesConstants.UPDATE_SUCCESS:
        case stateConstants.DELETE_STATES_SUCCESS:
        case stateConstants.DELETE_REASON_SUCCESS:
        case causesConstants.DELETE_SUCCESS:

            return {
                ...state,
                refresh: Date.now(),
                errors: {},
            };

        default:

            return state;
    }
}