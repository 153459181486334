import React, { useCallback } from 'react';
import {
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { Input, ListItem } from '@material-ui/core';
import { ReactComponent as ProductView } from '../../../assets/images/icons/product-view.svg';
import EditMenu from '../../EditMenu/EditMenu';

interface IDroppableItemUnsortedProps {
    unsortedItem: any;
    index: number;
    editDU: any[];
    editItem: any;
    option: any;
    draggableName: string[];
    phone: boolean;
    beacon: boolean;
    activeDraggable: (id: number, name: string) => void;
    unActiveDraggable: (id: number, name: string) => void;
    droppableId: number | null;
}

const DroppableItemUnsorted: React.FC<IDroppableItemUnsortedProps> = ({
                                                                          unsortedItem,
                                                                          index,
                                                                          editDU,
                                                                          editItem,
                                                                          option,
                                                                          draggableName,
                                                                          phone,
                                                                          beacon,
                                                                          activeDraggable,
                                                                          droppableId
                                                                      }: IDroppableItemUnsortedProps) => {

    /**
     * Default style for dragged item
     *
     * @param isDragging { boolean }
     * @param draggableStyle - default style
     */
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
        return { // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            width: '100%',
            height: 40,
            // change background colour if dragging
            background: '#ffffff',
            // background: isDragging ? 'lightgreen' : '#ffffff',

            // styles we need to apply on draggables
            ...draggableStyle,
        };
    };

    /**
     * edit action callback
     *
     * @type {(event) => void}
     */
    const editActionCallback = useCallback((event) => {

        if (editItem && (editItem.product || editItem.unsorted || editItem.category)) {

            if (editItem.editAction && editItem.unsorted) {

                if (editItem.unsorted.id !== unsortedItem.id) {

                    editItem.editAction(event, false, 'clean');
                }

            } else {

                editItem.editAction(event, false, 'clean');
            }
        }
    }, [editItem, unsortedItem]);

    /**
     * On change callback
     *
     * @type {(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void}
     */
    const onChangeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (editItem.editAction) {

            editItem.editAction(event, false, 'unsorted');

        }

    }, [editItem]);

    /**
     * On key press callback
     *
     * @type {(event: React.KeyboardEvent<HTMLDivElement>) => void}
     */
    const onKeyPressCallback = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {

        if (event.key === 'Enter' && editItem.editAction) {

            editItem.editAction(event, true, 'unsorted');
        }

    }, [editItem]);

    /**
     * Activate Droppable callback
     *
     * @type {() => void}
     */
    const activeDraggableCallback = useCallback(() => {

        activeDraggable(unsortedItem.id!, 'unsorted');

    }, [activeDraggable, unsortedItem]);

    const draggableItemClasses = `list-draggable__item-wrap ${editDU.indexOf(unsortedItem.id!) ? '' : 'active'} ${droppableId ? droppableId === unsortedItem.id ? 'active' : 'inactive': ''}`;

    return (
        <Draggable
            index={index}
            key={unsortedItem.id}
            draggableId={'drag-' + unsortedItem.id}
        >
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <div
                    // className={editDU.indexOf(unsortedItem.id!) < 0 ? 'list-draggable__item-wrap' : 'list-draggable__item-wrap active'}
                    className={draggableItemClasses}
                    style={{ position: 'relative' }}
                    onClick={editActionCallback}
                >
                    <ListItem
                        className={'list-draggable__item-include unsorted'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                        )}
                    >
                        <ProductView />
                        <span
                            className="item-description-wrap"
                        >
                            {editItem && editItem.unsorted && editItem.unsorted.id === unsortedItem.id ?
                                <Input
                                    autoFocus={Boolean(editItem.unsorted.id)}
                                    disableUnderline
                                    value={editItem.unsorted.name}
                                    className={'edit-unsorted ' + (option.errorStatus ? 'error-field' : '')}
                                    inputProps={{
                                        'maxLength': 30,
                                    }}
                                    onKeyPress={onKeyPressCallback}
                                    onChange={onChangeCallback}
                                />
                                :
                                <React.Fragment>
                                    <span>
                                        {draggableName.map(value => `${unsortedItem[value]} `).join(' ')}
                                    </span>
                                    {phone && unsortedItem.phone ?
                                        <span>{unsortedItem.phone}</span>
                                        : null
                                    }
                                    {beacon && unsortedItem.beacon ?
                                        <span>
                                            <p>
                                                <ProductView />
                                                {unsortedItem.beacon.name}
                                            </p>
                                        </span>
                                        : null
                                    }
                                </React.Fragment>
                            }
                        </span>
                    </ListItem>
                    <div className="wrap-menu-edit"
                         onClick={activeDraggableCallback}
                    >
                        {option.menu ?
                            <EditMenu
                                menuList={option.menu}
                                dataItem={unsortedItem}
                                nodeName={'unsorted'}
                            />
                            : null}
                    </div>
                </div>
            )}
        </Draggable>

    );
};

export default DroppableItemUnsorted;
