import { HrModuleActions, HrModuleActionTypes } from '../actions/hrModuleActions';

export interface IHrModuleState {
    isConfigurationBlockActive: boolean;
    moduleStatus: boolean;
}

export const initialState: IHrModuleState = {
    isConfigurationBlockActive: false,
    moduleStatus: false,
};

export function hrModuleReducer(state = initialState, action: HrModuleActions): IHrModuleState {
    switch (action.type) {
        case HrModuleActionTypes.ACTIVATE_CONFIGURATION_BLOCK: {
            return {
                ...state,
                isConfigurationBlockActive: true,
            };
        }

        case HrModuleActionTypes.GET_STATUS_HR_MODULE: {

            const { moduleStatus } = action.payload;

            return {
                ...state,
                moduleStatus: moduleStatus.moduleStatus,
            };
        }

        default: {
            return state;
        }
    }
}
