export const productCategoryConstants = {
    LIST_SUCCESS: 'PRODUCT_CATEGORY_LIST_SUCCESS',
    LIST_FAILURE: 'PRODUCT_CATEGORY_LIST_FAILURE',

    BULK_UPDATE_SUCCESS: 'PRODUCT_CATEGORY_BULK_UPDATE_SUCCESS',
    BULK_UPDATE_FAILURE: 'PRODUCT_CATEGORY_BULK_UPDATE_FAILURE',

    STORE_SUCCESS: 'PRODUCT_CATEGORY_STORE_SUCCESS',
    STORE_FAILURE: 'PRODUCT_CATEGORY_STORE_FAILURE',

    UPDATE_SUCCESS: 'PRODUCT_CATEGORY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PRODUCT_CATEGORY_UPDATE_FAILURE',

    DELETE_SUCCESS: 'PRODUCT_CATEGORY_DELETE_SUCCESS',
    DELETE_FAILURE: 'PRODUCT_CATEGORY_DELETE_FAILURE',
};
