import { fabric } from 'fabric';
import { Handler } from './Handler';

export class ZoomHandler {

    /**
     * Main handler instance
     *
     * @type {Handler}
     */
    private readonly handler: Handler;

    /**
     * Constructor
     *
     * @param {Handler} handler
     */
    constructor(handler: Handler) {

        this.handler = handler;
    }

    /**
     * Zoom to point
     *
     * @param {Point} point
     * @param {number} zoom
     */
    zoomToPoint(point: fabric.Point, zoom: number): void {

        const { minZoom, maxZoom } = this.handler;

        let zoomRatio = zoom;

        if (zoom <= minZoom / 100) {

            zoomRatio = minZoom / 100;

        } else if (zoom >= maxZoom / 100) {

            zoomRatio = maxZoom / 100;
        }

        this.updateStrokeForLines(zoomRatio);

        this.handler.canvas.zoomToPoint(point, zoomRatio);

        this.handler.canvas.renderAll();

        if (this.handler.onZoom) {

            this.handler.onZoom(zoomRatio);
        }
    }

    private updateStrokeForLines(zoomRatio: number): void {
        const polygons = this.handler.getObjects().filter(object => object.type === 'polygon');

        polygons.forEach(line => {
            line.set({
                strokeWidth: (line.strokeWidthInitial || 0) / zoomRatio,
            });
        });
    }

    /**
     * Zoom to a given value
     *
     * @param {number} value
     */
    zoomToValue(value: number): void {

        const center = this.handler.canvas.getCenter();

        this.zoomToPoint(new fabric.Point(center.left, center.top), value);
    }

    /**
     * Zoom to fit the screen
     */
    zoomToFit(): void {

        const { workarea } = this.handler;

        if (workarea && workarea.width && workarea.height) {

            const { width, height } = workarea;

            const canvasWidth = this.handler.canvas.getWidth(),
                canvasHeight = this.handler.canvas.getHeight();

            let ratio = canvasWidth / width;

            if (height >= width) {

                ratio = canvasHeight / height;

                if (canvasWidth < width * ratio) {

                    ratio = ratio * (canvasWidth  / (width * ratio));
                }

            } else {

                if (canvasHeight < height * ratio) {

                    ratio = ratio * (canvasHeight / (height * ratio));
                }
            }

            const center = this.handler.canvas.getCenter();

            this.handler.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);

            this.handler.workareaHandler.centerInCanvas();
            this.zoomToPoint(new fabric.Point(center.left, center.top), ratio);
        }
    }

    /**
     * Zoom to value the screen and set center
     */
    zoomAndCenter(value?: number): void {

        const { workarea } = this.handler;

        if (workarea && workarea.width && workarea.height) {

            const { width, height } = workarea;

            const canvasWidth = this.handler.canvas.getWidth(),
                canvasHeight = this.handler.canvas.getHeight();

            let ratio = canvasWidth / width;

            if (height >= width) {

                ratio = canvasHeight / height;

                if (canvasWidth < width * ratio) {

                    ratio = ratio * (canvasWidth  / (width * ratio));
                }

            } else {

                if (canvasHeight < height * ratio) {

                    ratio = ratio * (canvasHeight / (height * ratio));
                }
            }

            const center = this.handler.canvas.getCenter();

            this.handler.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);

            this.handler.workareaHandler.centerInCanvas();
            this.zoomToPoint(new fabric.Point(center.left, center.top), value || ratio);
        }
    }

    /**
     * Zoom in
     */
    zoomIn(): void {

        let zoomRatio = this.handler.canvas.getZoom();

        zoomRatio += 0.05;

        const center = this.handler.canvas.getCenter();

        this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    }

    /**
     * Zoom out
     */
    zoomOut(): void {

        let zoomRatio = this.handler.canvas.getZoom();

        zoomRatio -= 0.05;

        const center = this.handler.canvas.getCenter();

        this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    }
}
