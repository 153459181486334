import { fabric } from 'fabric';
import { Handler } from './Handler';
import { FabricObject, WorkareaObject, ILayer } from '../../interfaces';

const defaultOptions: Partial<WorkareaObject> = {
    width: 600,
    height: 400,
    workareaWidth: 600,
    workareaHeight: 400,
    lockScalingX: true,
    lockScalingY: true,
    scaleX: 1,
    scaleY: 1,
    // backgroundColor: '#fff',
    hasBorders: false,
    hasControls: false,
    selectable: false,
    lockMovementX: true,
    lockMovementY: true,
    hoverCursor: 'default',
    name: '',
    id: 'workarea',
    type: 'image',
    layout: 'fixed',
    tooltip: {
        enabled: false,
    },
    isElement: false,
};

/**
 * Workarea handler
 *
 * @class WorkareaHandler
 */
export class WorkareaHandler {

    /**
     * Main handler instance
     *
     * @type {Handler}
     */
    private readonly handler: Handler;

    /**
     * Constructor
     *
     * @param {Handler} handler
     */
    constructor(handler: Handler) {

        this.handler = handler;

        const { workareaOptions } = this.handler;

        const options = Object.assign({}, defaultOptions, workareaOptions);

        const image = new Image(options.width, options.height);

        if (options.src) {

            image.src = options.src;
            image.onload = () => {

                 this.handler.canvas.renderAll();
            };
        }

        this.handler.workarea = new fabric.Image(image, options) as WorkareaObject;
        this.handler.canvas.add(this.handler.workarea);

        if (options.layers) {

            const loadedLayers: FabricObject[] = [];

            options.layers.forEach(async(layer: ILayer, index: number) => {

                if (layer.pictureContent) {
                    const blob = new Blob([layer.pictureContent], { type: 'image/svg+xml' });
                    const url = URL.createObjectURL(blob);

                    fabric.Image.fromURL(url, image => {

                        const svgRef = image;

                        svgRef.setOptions({
                            id: 'editor-layer-' + layer.id,
                            top: layer.top + 20, // +20 use to align layer position considering header 40px
                            left: layer.left,
                            scaleX: layer.scaleX,
                            scaleY: layer.scaleY,
                            angle: layer.angle,
                            visible: true,
                            name: layer.name,
                            opacity: layer.opacity,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            lockRotation: true,
                            zIndex: index + 1,
                            type: 'layer',
                            selectable: false,
                            evented: false,
                            originY: 'center',
                            originX: 'center',
                        });

                        this.handler.canvas.add(svgRef);

                        this.handler.canvas.moveTo(svgRef, index + 1);

                        URL.revokeObjectURL(url);

                        loadedLayers.push(svgRef);

                        if (loadedLayers.length === options.layers.length && this.handler.onLoadLayers) {
                            this.handler.onLoadLayers(loadedLayers);
                        } 
                    });
                }
            });
        }

        this.handler.objects = this.handler.getObjects();
        this.handler.canvas.renderAll();
    }

    centerInCanvas(): void {
        const { width, height } = this.handler.workarea;
        const center = this.handler.canvas.getCenter();

        if (!width || !height) {
            return;
        }

        this.handler.canvas.absolutePan(new fabric.Point((width / 2) - center.left, (height / 2) - center.top));
        this.handler.canvas.renderAll();
    }
}
