import React from 'react';
import { Tooltip as CustomTooltip, TooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Base styles for the Components
 *
 * @type {StylesHook<Styles<Theme, {}, string>>}
 */
const useStyles = makeStyles(theme => ({
    itemIcon: {
        minWidth: 24,
        cursor: 'pointer',
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 'none',
        zIndex: 1,
    },
    popper: {
        zIndex: 1200,
    },
}));

const Tooltip:React.FC<TooltipProps> = ({ title, children, ...props }:TooltipProps) => {

    const classes = useStyles();

    return (
        <CustomTooltip
            classes={{
                tooltip: classes.tooltip,
                popper: classes.popper,
            }}
            enterDelay={500}
            style={{ zIndex: 1200 }}
            title={title}
            {...props}
        >
            {children}
        </CustomTooltip>
    );
};

export default Tooltip;
