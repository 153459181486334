import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { IFlatTreeFactory, ISensor, IFlatTreeProcess } from '../../interfaces';
import { getFlatTreeItemsByType, getId, getType } from '../../helpers/selectorsHelper';

const selectConfigurationTreeState = (state: RootState) => state.configurationTree;

export const selectConfigurationTree = createSelector(
    selectConfigurationTreeState,
    configurationTree => configurationTree.items || [],
);

export const selectConfigurationTreeUnsortedSensor = createSelector(
    selectConfigurationTreeState,
    configurationTree => configurationTree.items.filter(value => value.type === 'sensor') || [],
);

export const selectConfigurationTreeUnit = createSelector(
    selectConfigurationTree,
    items => getFlatTreeItemsByType(items || [], 'unit'),
);

export const selectConfigurationTreeFactory = createSelector(
    selectConfigurationTree,
    items => getFlatTreeItemsByType(items || [], 'factory') as IFlatTreeFactory[],
);

export const selectConfigurationTreeUnsorted = createSelector(
    selectConfigurationTree,
    items => getFlatTreeItemsByType(items || [], 'sensor') as unknown as ISensor[],
);

export const selectConfigurationTreeProcess = createSelector(
    selectConfigurationTree,
    items => getFlatTreeItemsByType(items || [], 'process') as IFlatTreeProcess[],
);

export const selectFlatItemById = createSelector(
    selectConfigurationTree,
    getId,
    getType,
    (items, ID, type) => items.find(value => value.type === type && value.id === ID),
);