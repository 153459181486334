import ApiProvider from '../providers/apiProvider';
import { IFilter, IJoin, IOrder, IProduct } from '../interfaces';
import { trackPromise } from 'react-promise-tracker';
import { AxiosResponse } from 'axios';

/**
 * Service to work with user API resources
 *
 * @class ProductService
 */
export class ProductService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/products(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['username', 'email'];
    }

    /**
     * Get list of products
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<AxiosResponse>  {

            return this.http
                .get(this.url(), {
                    params: this.prepareListParams(search, order, join, filter),
                    headers: { ...this.headers, 'Pragma': 'no-cache' },
                });
    }
    /**
     * Create new user account
     *
     * @param {IProduct} product
     *
     * @return {Promise<Object>}
     */
    store(product: IProduct): Promise<AxiosResponse>  {

        return trackPromise(
            this.http
                .post(this.url(), product, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Update product
     *
     * @param {IProduct} product
     *
     * @return {Promise<Object>}
     */
    update(product: IProduct): Promise<AxiosResponse>  {

        return trackPromise(
            this.http
                .patch(this.url({ id: product.id }), product, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove product by ID
     *
     * @param {number} productId
     *
     * @return {Promise<Object>}
     */
    remove(productId: number): Promise<AxiosResponse>  {

        return trackPromise(
            this.http
                .delete(this.url({ id: productId }), {
                    headers: this.headers,
                }),
        );
    }


}