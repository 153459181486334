import { createSelector } from 'reselect';
import { RootState } from '../../store';

const stateSelector = (state: RootState) => state.hmiHoverItemState;

export const selectHmiHoverItem = createSelector(
    stateSelector,
    hmiHoverItemState => hmiHoverItemState.hoverElement,
);

export const selectHmiHoverSensor = createSelector(
    stateSelector,
    hmiHoverItemState => hmiHoverItemState.hoverSensor,
);

export const selectHoverHMILogic = createSelector(
    selectHmiHoverItem,
    selectHmiHoverSensor,
    (item, sensor)=> Boolean(item || sensor),
);
