import { ZoneActions, ZoneActionTypes } from '../actions/zoneActions';
import { IZoneApiModel } from '../../services/zoneService';

export interface IZoneState {
    isLoading: boolean;
    zones: IZoneApiModel[];
    error: any;
}

export const initialState: IZoneState = {
    isLoading: false,
    zones: [],
    error: null,
};

export function zoneReducer(state = initialState, action: ZoneActions): IZoneState {

    switch (action.type) {

        case ZoneActionTypes.FETCH_ZONES_REQUEST: {

            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case ZoneActionTypes.FETCH_ZONES_SUCCESS: {

            const { zones } = action.payload;

            return {
                ...state,
                isLoading: false,
                zones,
            };
        }

        case ZoneActionTypes.FETCH_ZONES_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case ZoneActionTypes.DELETE_ZONE_ACTION: {

            const modifyZones = state.zones.filter(zone => zone.id !== action.payload.zone.id);

            return {
                ...state,
                zones: modifyZones,
            };
        }

        case ZoneActionTypes.SAVE_ZONE_ACTION: {

            const modifyZones = state.zones.map((zone) => zone.id === action.payload.zone.id ? action.payload.zone : zone);

            if (modifyZones.findIndex(zone => zone.id === action.payload.zone.id) === -1) modifyZones.push(action.payload.zone);

            return {
                ...state,
                zones: modifyZones,
            };
        }

        case ZoneActionTypes.CLEAR_ZONES_ACTION: {

            return {
                ...state,
                zones: [],
            };
        }

        default: {

            return state;
        }
    }
}
