import { createSelector } from 'reselect';
import { RootState } from '../../store';

const selectHmiSchemaFormState =  (state: RootState) => state.hmiSchemaFormState;

export const selectHmiOpenScheme = createSelector(
    selectHmiSchemaFormState,
    (hmiSchemaFormState) => hmiSchemaFormState.schema,
);

export const selectHmiOpenSchemeObjects = createSelector(
    selectHmiSchemaFormState,
    (hmiSchemaFormState) => hmiSchemaFormState.schema?.hmiObjects || [],
);

export const selectHmiSchemaPicture = createSelector(
    selectHmiSchemaFormState,
    hmiSchemaFormState => hmiSchemaFormState.picture,
);

export const selectHmiObjectErrors = createSelector(
    selectHmiSchemaFormState,
    (hmiSchemaFormState) => hmiSchemaFormState.errorsObject,
);

export const selectHmiErrors = createSelector(
    selectHmiSchemaFormState,
    (hmiSchemaFormState) => hmiSchemaFormState.errors,
);
