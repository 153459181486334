import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';

/**
 * Service to work with user API resources
 *
 * @class ProductService
 */
export class ConfigurationService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/configuration-tree(/:type/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [''];
    }

    /**
     * Get list of products
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    update(type: string, item: number, data: { isMinimized: boolean, position?: number }): Promise<AxiosResponse> {

        return this.http
            .patch(this.url({ type: type, id: item }), data, {
                headers: this.headers,
            });
    }

    /**
     * Get list of products
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    remove(type: string, item: { id: number }): Promise<AxiosResponse> {

        return this.http
            .delete(this.url({ type: type, id: item.id }), {
                headers: this.headers,
            });
    }
}