import { IHmiState, IHMIStateAction } from '../../interfaces';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function hmiObjectFormState(state: IHmiState = {
    errors: {},
    objects: [],
    schemas: [],
}, action: IHMIStateAction): IHmiState {

    switch (action.type) {


        default:

            return state;
    }

}