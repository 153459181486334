import {
    SaveBarrierAction,
    FetchBarriersRequest,
    FetchBarriersSuccess,
    FetchBarriersFailure,
    DeleteBarrierAction,
    ClearBarriersAction,
} from '../actions/barrierActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IBarrierApiModel, IBarrierIndentOnly } from '../../services/barrierService';
import { BarrierService } from '../../services/barrierService';
import { IOrder } from '../../../../core/interfaces';
import { ILocation } from '../../interfaces';

/**
 * Barrier thunks
 */
export const barrierThunks = {

    /**
     * Fetch list of barriers
     *
     * @param {ILocation} location
     * @param {string} search
     * @param {IOrder} order
     *
     * @returns {Promise<void>}
     */
    fetchBarriers: (location: ILocation, search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            dispatch(new FetchBarriersRequest());

            const service = new BarrierService();

            const filter = 'plan.id||$eq||' + location.id;

            const barriers = await service.list(search, order, undefined, { field: [filter] });

            dispatch(new FetchBarriersSuccess({ barriers }));

        } catch (error) {

            dispatch(new FetchBarriersFailure({ error }));
        }
    },

    /**
     * Delete barrier
     *
     * @param {IBarrierApiModel} barrier
     *
     * @returns {Promise<void>}
     */
    deleteBarrier: (barrier: IBarrierIndentOnly) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new BarrierService();

            service.delete(barrier);

            dispatch(new DeleteBarrierAction({ barrier }));

        } catch (error) {

            //nothing to do
        }
    },

    /**
     * Save barrier
     *
     * @param {IBarrierApiModel} model
     */
    saveBarrier: (model: IBarrierApiModel) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new BarrierService();

            const barrier = await service.save(model);

            dispatch(new SaveBarrierAction({ barrier }));

        } catch (error) {

            console.warn(error);

            throw error;
        }
    },

    /**
     * Clear barriers state
     */
    clearBarriers: () => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        dispatch(new ClearBarriersAction());
    },
};
