import React from 'react';
import { EditorMode } from '../../../../../interfaces';
import { ReactComponent as MapPin } from '../../../../../ui/assets/images/icons/map-pin.svg';
import { ReactComponent as MapPinUnselected } from '../../../../../ui/assets/images/icons/map-pin-unselected.svg';

import styles from './AddGpsIcon.module.scss';

export const AddGpsIcon = ({ editorMode }: IProps) => {
    if (editorMode === EditorMode.ADD) {
        return <MapPin className={styles.icon} />;
    } else {
        return <MapPinUnselected className={styles.icon} />;
    }
};

interface IProps {
    editorMode: EditorMode;
}
