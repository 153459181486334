import store from '../store';
import { hmiPlayerConstants } from '../constants';
import {
    selectHmiPlayerMode,
    selectHmiPlayerValue,
    selectHmiPlayerSpeed,
    selectHmiPlayerRealTimeStatus,
} from '../selectors/hmi/playerSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { PlayerMode } from '../interfaces';
import * as d3 from 'd3';
import { selectScreenWidth } from '../selectors/dashboard/dashboardSelector';
import { selectDrawerWidth, selectPositionDrawer } from '../selectors/layout/responsiveDrawerSelector';

/**
 * Watcher update interval in milliseconds
 *
 * @type {number}
 */
const INTERVAL = 500;

/**
 * HMI PLayer watcher
 *
 * @class HmiPlayerWatcher
 */
export class HmiPlayerWatcher {

    /**
     * @type {function}
     */
    subscription: () => void;

    /**
     * @type {NodeJS.Timeout}
     */
    interval: NodeJS.Timeout | null;

    /**
     * @type {ScaleTime<number, number>}
     */
    scale: d3.ScaleTime<number, number>;

    /**
     * HmiPlayerWatcher constructor
     */
    constructor() {

        this.subscription = store.subscribe(() => {

            this.listener();
        });

        this.interval = null;
        this.scale = d3.scaleTime();
    }

    /**
     * Unsubscribe from store
     */
    unsubscribe(): void {

        this.subscription();
    }

    /**
     * Store listener
     */
    listener(): void {

        const state = store.getState();

        const playerMode = selectHmiPlayerMode(state),
            anchor: 'right' | 'bottom'  = selectPositionDrawer(state) as 'right' | 'bottom',
            screenWidthOrigin = selectScreenWidth(state),
            drawWidth = selectDrawerWidth(state),
            screenWidth = screenWidthOrigin - (anchor === 'right' ? drawWidth : 0),
            realTimeIndentation = calcRealTimeIndentation(state),
            selection = selectBrushSelection(state),
            realTimeStatus = selectHmiPlayerRealTimeStatus(state);

        this.scale.range([0, screenWidth - realTimeIndentation])
            .domain(selection);
        if (playerMode === PlayerMode.MODE_PLAY && !this.interval) {

            const setValueAction = (value: number) => {

                return {
                    type: hmiPlayerConstants.SET_VALUE,
                    value,
                };
            };

            const stopPlayAction = () => {

                return {
                    type: hmiPlayerConstants.STOP,
                };
            };

            this.interval = setInterval(() => {

                const state = store.getState();

                const speed = selectHmiPlayerSpeed(state);
                let value = selectHmiPlayerValue(state);

                value += (INTERVAL * speed);

                const [, endDate] = selectBrushSelection(state);

                if (value >= endDate.getTime() + this.scale.invert(screenWidth + realTimeIndentation).getTime()) {

                    store.dispatch(stopPlayAction());

                } else {

                    // temporary change
                    // store.dispatch(setValueAction(value));
                    store.dispatch(setValueAction(realTimeStatus ? new Date().getTime() : value));
                }

            }, INTERVAL);

        } else if (playerMode !== PlayerMode.MODE_PLAY && this.interval) {

            clearInterval(this.interval);

            this.interval = null;
        }
    }
}
