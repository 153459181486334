import { productConstants } from '../../constants';
import { IError, ISensor, ITargetValue } from '../../interfaces';

interface IProductTargetValueAction {
    type: string;
    targetValue: ITargetValue[],
    errors: IError,
    allTargetValue: ISensor[]
}

interface IProductTargetValueState {
    allTargetValue: ISensor[];
    errors: IError;
    targetValue: ITargetValue[];
}

/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function productTargetValue(state: IProductTargetValueState = {
    allTargetValue: [],
    errors: {},
    targetValue: [],
}, action: IProductTargetValueAction): IProductTargetValueState {

    switch (action.type) {

        case productConstants.GET_ALL_TARGET_VALUE_SUCCESS:

            return {
                ...state,
                allTargetValue: action.allTargetValue,
                errors: {},
            };

        case productConstants.GET_ALL_TARGET_VALUE_FAILURE:

            return {
                ...state,
                errors: action.errors,
                allTargetValue: [],
            };

        case productConstants.GET_TARGET_VALUE_SUCCESS:
        case productConstants.UPDATE_TARGET_VALUE_SUCCESS:

            return {
                ...state,
                targetValue: action.targetValue,
                errors: {},
            };

        case productConstants.GET_TARGET_VALUE_FAILURE:
        case productConstants.UPDATE_TARGET_VALUE_FAILURE:

            return {
                ...state,
                targetValue: [],
                errors: action.errors,
            };

        default:

            return state;
    }

}
