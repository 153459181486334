import React from 'react';
import { Message } from '../../../../core/ui/components';
import { noop } from '../../../../base/helpers/noop';

interface IProps {
    message: string;
}

const yellowMessageStyle = {
    maxWidth: '100%',
};

export const YellowMessage: React.FC<IProps> = (props: IProps) => (
    <Message
        message={props.message}
        hidden
        handleClick={noop}
        style={yellowMessageStyle}
    />
);
