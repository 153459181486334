import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorMode, IGpsPin, IGpsPinIdent, IImageSize, ILayer, IMeasure, IOffset, IPinCoordinates } from '../../../../../interfaces';
import { Canvas } from '../../../../Editor/canvas/Canvas';
import { FabricObject, IEditorTool, IFabricEvent, WorkareaObject } from '../../../../../../../base/components/Editor/interfaces';
import { editorConstants } from '../../../../Editor/constants';
import './SvgPreview.scss';
import { useEditor } from './useEditor';
import { noop } from '../../../../../../../base/helpers/noop';
import { LeftPanel } from '../../../../../../../base/components/Editor/panels/LeftPanel';
import { ScalePanel } from '../../../../../../../base/components/Editor/panels/ScalePanel';
import { fabric } from 'fabric';
import { Slider } from '../../../../../../../base/components/Controls/Slider/Slider';
import { Spinner } from '../../../../../ui/components/Spinner/Spinner';
import { formatToDecimals } from '../../../../../utils/formatToDecimals';
import FontFaceObserver from 'fontfaceobserver';
import { calcZoomScale, calcImageMeterCoef } from '../../../../Editor/helpers/gateway-location.helper';
import CanvasObject from '../../../../Editor/canvas/CanvasObject';


const ADD_GPS_PIN_TOOL: IEditorTool = {
    id: editorConstants.interaction.modeGpsPin,
    title: editorConstants.interaction.modeGpsPin,
    superType: 'drawing',
    type: editorConstants.interaction.modeGpsPin,
};

const isValidAngleValue = (angle: number): boolean => {
    if (!Number.isFinite(Number(angle))) {
        return false;
    }

    return 0 <= angle && angle <= 360;
};

export const SvgPreview: React.FC<ISvgPreviewProps> = (props: ISvgPreviewProps) => {
    const {
        svg,
        layerSvg,
        pins = [],
        addPin = noop,
        openPin = noop,
        editorMode,
        onUpdateLayer = noop,
        layerData,
        angle,
        onRotating,
        onScale,
        onChangeMeasure,
        meterHeight,
        meterWidth,
        planAngle,
        measure,
    } = props;

    const [svgUrl, setSvgUrl] = useState<string | null>(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const [canvasRef, setCanvasRef] = useState<MutableRefObject<Canvas | null>>({ current: null });

    const [zoom, setZoom] = useState<number>(0.3);
    const [scale, setScale] = useState<number>(props.scale || (10 / 800));
    const [fitScreen, setFitScreen] = useState<boolean>(false);

    const workareaHeight = 400, workareaWidth = 800;

    useEffect(() => {
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        const image = new Image();
        image.onload = () => {
            setWidth(image.width);
            setHeight(image.height);
            setSvgUrl(url);
        };
        image.src = url;

        return () => {
            URL.revokeObjectURL(url);
            setSvgUrl(null);
        };
    }, [svg]);

    const onRefUpdate = useCallback((ref) => {
        setCanvasRef({ current: ref });
    }, []);

    const {
        onSelect,
        onAdd,
        onRemove,
        onZoom,
        selectTool,
        sliderOptions,
        grab,
        switchGrab,
        sliderZoomChange,
        zoomRatio,
    } = useEditor(canvasRef);

    const [layerOnCanvas, setLayerOnCanvas] = useState<fabric.Image | null>(null);

    useEffect(() => {
        if (!layerSvg || !canvasRef.current) {
            return;
        }
        const ref = canvasRef.current;

        let svgRef: fabric.Image | null = null;

        const blob = new Blob([layerSvg], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        fabric.Image.fromURL(url, (image => {
            svgRef = image;

            svgRef.set({
                type: editorConstants.objects.layer,
                top: ref.handler.workarea.top,
                left: ref.handler.workarea.left,
                originY: 'center',
                originX: 'center',
            });

            if (layerData && layerSvg === layerData.pictureContent) {
                svgRef.set({
                    top: layerData.top,
                    left: layerData.left,
                    scaleX: layerData.scaleX,
                    scaleY: layerData.scaleY,
                    angle: layerData.angle,
                    opacity: layerData.opacity,
                    lockMovementY: true,
                    lockMovementX: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingY: true,
                    lockSkewingX: true,
                    selectable: false,
                });
            }

            ref.canvas.add(svgRef);
            onAdd(svgRef);
            onUpdateLayer(svgRef);

            setLayerOnCanvas(svgRef);

            if (pins) {
                pinsToFront(ref, pins);
            }

            ref.canvas.discardActiveObject();
        }));

        return () => {
            if (svgRef) {
                ref.canvas.remove(svgRef);
            }
            URL.revokeObjectURL(url);
            setLayerOnCanvas(null);
        };

    }, [canvasRef, layerSvg, layerData, onAdd, onUpdateLayer]);

    useEffect(() => {
        if (editorMode === EditorMode.ADD) {
            selectTool(ADD_GPS_PIN_TOOL);
        }
    }, [editorMode, selectTool]);

    useEffect(() => {

        if (canvasRef.current) {

            canvasRef.current.handler.zoomHandler.zoomToFit();

            const workarea = canvasRef.current.handler.workarea;

            if (workarea && measure) {

                calcWorkareaPosition(workarea);
            }

            if (props.scale) {

                canvasRef.current.handler.zoomHandler.zoomToValue(props.scale);
            }
        }

    }, [canvasRef]);

    /**
     * Calculate postion of a workarea according to measure scale params
     * 
     * @param workarea 
     * @param measure 
     */
    const calcWorkareaPosition = (workarea: WorkareaObject) => {

        workarea.set({
            top: (workareaHeight / 2) * (height / workareaHeight),
            left: (workareaWidth / 2) * (width / workareaWidth),
            originY: 'center',
            originX: 'center',
        });
    };

    const onFitToScreen = useCallback(() => {

        if (canvasRef.current) {

            const workarea = canvasRef.current.handler.workarea;

            if (workarea && workarea.height) {

                const canvHeight = canvasRef.current.handler.canvas.getHeight();

                const center = canvasRef.current.handler.canvas.getCenter();

                const zoomValue = !fitScreen ? canvHeight / workarea.height : zoom;

                canvasRef.current.handler.canvas.zoomToPoint(new fabric.Point(center.left, center.top), zoomValue);

                const vpsCoords = canvasRef.current.canvas.vptCoords;

                const scaleX = measure?.scaleX || 1;
                const scaleY = measure?.scaleY || 1;

                const { imageHypot, meterPixelCoef, canvToImagePixel } = calcImageMeterCoef(
                    { meterHeight, meterWidth },
                    { workareaHeight, workareaWidth },
                    { height, width },
                    { scaleX, scaleY },
                );

                if (vpsCoords) {
                    // calc scale
                    const scale = calcZoomScale(vpsCoords, imageHypot, meterPixelCoef, canvToImagePixel);

                    setScale(scale);
                }
            }
        }

        setFitScreen(!fitScreen);
    }, [fitScreen, canvasRef, zoom, scale]);

    const onAddCustom = useCallback((target) => {
        onAdd(target);

        openTargetedPin(target, addPin);
    }, [addPin, onAdd]);

    useOpenPinEffect(canvasRef, addPin, openPin);

    usePinUpdate(canvasRef, pins, grab, { width, height });

    const onChangeOpacity = useCallback((value) => {
        if (!layerOnCanvas || !canvasRef.current || !layerSvg) {
            return;
        }

        layerOnCanvas.opacity = value;
        layerOnCanvas.dirty = true;
        canvasRef.current.canvas.renderAll();
    }, [layerOnCanvas, canvasRef, layerSvg]);

    useEffect(() => {

        if (!canvasRef.current || !layerOnCanvas || angle === undefined || !isValidAngleValue(angle)) {
            return;
        }

        layerOnCanvas.rotate(angle);

        canvasRef.current.canvas.renderAll();

    }, [canvasRef, layerOnCanvas, angle]);

    useEffect(() => {
        if (!layerOnCanvas || !onRotating) {
            return;
        }

        const onRotatingHandler = (event: IFabricEvent) => {
            const target = event.transform?.target;

            if (!target) {
                return;
            }

            onRotating(formatToDecimals(target?.angle || 0));
        };

        layerOnCanvas.on('rotating', onRotatingHandler);

        return () => {
            layerOnCanvas.off('rotating', onRotatingHandler);
        };
    }, [layerOnCanvas, onRotating]);


    const onZoomCallBack = useCallback((number: number) => {

        onZoom(number);

        setZoom(number);

        setFitScreen(false);

        if (onScale) onScale(number);

    }, []);

    useEffect(() => {

        const vpsCoords = canvasRef.current?.canvas.vptCoords;

        const scaleX = measure?.scaleX || 1;
        const scaleY = measure?.scaleY || 1;

        const { imageHypot, meterPixelCoef, canvToImagePixel } = calcImageMeterCoef(
            { meterHeight, meterWidth },
            { workareaHeight, workareaWidth },
            { height, width },
            { scaleX, scaleY },
        );

        if (vpsCoords) {
            // calc scale
            const scale = calcZoomScale(vpsCoords, imageHypot, meterPixelCoef, canvToImagePixel);

            setScale(scale);
        }

    }, [zoom, canvasRef]);

    /**
     * On rotation handler
     */
    const onRotationHandler = (workarea: WorkareaObject, onRotating: Function) => {

        const handler = (event: IFabricEvent) => {
            const target = event.transform?.target;

            if (target) {
                onRotating(formatToDecimals(target?.angle || 0));
            }
        };

        workarea.on('rotating', handler);

        return () => {
            workarea.off('rotating', handler);
        };
    };

    useEffect(() => {

        if (!layerOnCanvas && canvasRef.current) {

            canvasRef.current.handler.getObjects().forEach(obj => {
                obj.editable = !grab;
                obj.selectable = !grab;
            });

            if (!layerData && canvasRef.current) {

                const workarea = canvasRef.current.handler.workarea;

                if (workarea) {
                    workarea.set('hasBorders', !grab);
                    workarea.set('hasControl', !grab);
                    workarea.set('selectable', !grab);
                    workarea.set('lockScalingX', grab);
                    workarea.set('lockScalingY', grab);

                    canvasRef.current.canvas.requestRenderAll();
                }

                if (onChangeMeasure) {

                    const onMeasureHandler = ((event: IFabricEvent) => {
                        const target = event.transform?.target;

                        if (!target) {
                            return;
                        }

                        const { scaleX, scaleY } = target;

                        onChangeMeasure({ scaleX, scaleY } as IMeasure);
                    });

                    workarea.on('scaling', onMeasureHandler);

                    return () => {
                        workarea.off('scaling', onMeasureHandler);
                    };
                }

                if (onRotating) {

                    return onRotationHandler(workarea, onRotating);
                }
            }
        }

        if (layerOnCanvas) {

            layerOnCanvas.set({
                lockScalingX: grab,
                lockScalingY: grab,
                selectable: !grab,
            });
        }
    }, [grab, canvasRef, layerOnCanvas]);

    useEffect(() => {

        if (canvasRef.current) {

            const workarea = canvasRef.current.handler.workarea;

            if (workarea && planAngle) {

                workarea.rotate(planAngle);

                canvasRef.current.canvas.requestRenderAll();
            }

            if (workarea && measure) {
                workarea.scaleX = measure.scaleX;
                workarea.scaleY = measure.scaleY;

                canvasRef.current.canvas.requestRenderAll();
            }
        }

    }, [canvasRef, planAngle, measure]);

    useEffect(() => {

        if (!layerData && canvasRef.current) {

            const workarea = canvasRef.current.handler.workarea;

            if (onRotating) {

                return onRotationHandler(workarea, onRotating);
            }
        }

    }, [layerData, canvasRef, onRotating]);

    const { t } = useTranslation();

    if (!svgUrl) {

        return (
            <div className={'svg-preview'}>
                <Spinner active />
            </div>
        );
    }

    return (
        <div className={'svg-preview'}>
            <Spinner active={Boolean(layerSvg && !layerOnCanvas)} />
            <div className="font-preloader">.</div>
            <div className="opacity-slider">
                {
                    layerSvg &&
                    <Slider
                        label={t('LAYER_OPACITY')}
                        value={layerData ? layerData.opacity : 1}
                        onChangeValue={onChangeOpacity}
                    />
                }
            </div>
            <LeftPanel
                slider={sliderOptions}
                grabActive={grab}
                onZoomChange={(event: React.ChangeEvent<{}> | null, value: number | number[]) => {
                    sliderZoomChange(event, value);

                    if (onScale && !Array.isArray(value)) onScale(value / 100);
                }}
                fitScreenActive={fitScreen}
                onFitToScreen={onFitToScreen}
                onGrabSwitch={switchGrab}
            />
            <ScalePanel scale={scale} />
            <Canvas ref={onRefUpdate}
                key={svgUrl}
                minZoom={30}
                maxZoom={300}
                workareaOptions={{
                    src: svgUrl,
                    width: width,
                    height: height,
                }}
                width={workareaWidth}
                height={workareaHeight}
                fabricObjects={CanvasObject}
                onAdd={onAddCustom}
                onRemove={onRemove}
                onSelect={onSelect}
                onZoom={onZoomCallBack}
            />
        </div>
    );
};

interface ISvgPreviewProps {
    svg: string;
    layerSvg?: string;
    pins?: IGpsPin[];
    scale?: number | null;
    addPin?: (key: IGpsPinIdent, pin: IPinCoordinates) => void;
    openPin?: (key: IGpsPinIdent) => void;
    onUpdateLayer?: (model: fabric.Image) => void;
    editorMode: EditorMode;
    layerData?: ILayer | null;
    angle?: number;
    onRotating?: (angle: number) => void;
    onScale?: (scale: number) => void;
    onChangeMeasure?: (measure: IMeasure) => void;
    meterWidth: number;
    meterHeight: number;
    planAngle: number;
    measure?: IMeasure;
}

/**
 * Open pin caused by any event
 * @param target
 * @param addPin
 */
function openTargetedPin(target: FabricObject, addPin: (key: IGpsPinIdent, pin: IPinCoordinates) => void) {
    if (!(target.type === ADD_GPS_PIN_TOOL.type && target.id && target.left && target.top)) {
        return;
    }

    const { left: x = 0, top: y = 0 } = target;

    addPin(target.id, { x, y });
}

/**
 * Pins to front of canvas objects
 * 
 * @param canvasRef
 * @param pins
 */
function pinsToFront(canvasRef: Canvas, pins: IGpsPin[]): void {
    pins.forEach(pin => {
        const obj = canvasRef.handler.findById(pin.localId);

        if (obj) {
            canvasRef.canvas.bringToFront(obj);
        }
    });
}

/**
 * Effect for handling click event on GPS Pin
 * @param canvasRef
 * @param addPin
 * @param openPin
 */
function useOpenPinEffect(canvasRef: MutableRefObject<Canvas | null>, addPin: (key: IGpsPinIdent, pin: IPinCoordinates) => void, openPin: (key: IGpsPinIdent) => void) {
    useEffect(() => {
        const canvas = canvasRef.current?.handler.canvas || null;

        if (!canvas) {
            return;
        }

        const onClick = (event: IFabricEvent) => {
            const target = event.target;

            if (!event.isClick || !target) {
                return;
            }

            openTargetedPin(target, addPin);
        };

        canvas.on('mouse:up', onClick);

        return () => {
            canvas.off('mouse:up', onClick);
        };
    }, [canvasRef, addPin, openPin]);
}

/**
 * Calc pin offset workarea
 * 
 * @param workarea 
 * @param pin 
 * @param imageSize 
 * @returns
 */
function calcPinOffsetWorkarea(
    workarea: WorkareaObject,
    pin: IGpsPin,
    imageSize: IImageSize,
): IOffset {
    const workareaHeight = 400, workareaWidth = 800;

    const workraeaCenter = workarea.getCenterPoint();

    const top = (workareaHeight / 2) * (imageSize.height / workareaHeight); // top center postion of plan image
    const left = (workareaWidth / 2) * (imageSize.width / workareaWidth);   // left center postion of plan image

    // get diff offset between current workarea center and pin by x 
    const leftWorkareaDiff = workraeaCenter.x - pin.x;

    // get diff offset between current workarea center and pin by y
    const topWorkareaDiff = workraeaCenter.y - pin.y;

    // get offset between current center and center after render by x
    const offsetX = left - leftWorkareaDiff;

    // get offset between current center and center after render by y
    const offsetY = top - topWorkareaDiff;

    return { offsetX, offsetY };
}

/**
 * Effect for update canvas when pin array updates
 * @param canvasRef
 * @param pins
 */
function usePinUpdate(canvasRef: MutableRefObject<Canvas | null>, pins: IGpsPin[], grab: boolean, imageSize: IImageSize) {

    useEffect(() => {
        const currentRef = canvasRef.current;
        const canvas = canvasRef.current?.handler.canvas || null;

        if (!currentRef || !canvas) {
            return;
        }

        const workarea = currentRef.handler.workarea;

        const objects = currentRef.handler
            .getObjects()
            .filter(({ type }) => type === ADD_GPS_PIN_TOOL.type);

        objects.forEach(object => {
            const pin = pins.find(({ localId }) => object.id === localId);

            if (!pin) {
                canvas.remove(object);
                return;
            }

            const { offsetX, offsetY } = calcPinOffsetWorkarea(workarea, pin, imageSize);

            pin.offsetX = offsetX;
            pin.offsetY = offsetY;

            object.dirty = true;
            object.editable = !grab;
            object.selectable = !grab;
            object.set({
                lat: pin.lat || 0,
                lng: pin.lng || 0,
            } as Partial<Record<string, unknown>>);

            currentRef.canvas.bringToFront(object);
        });

        const newPins = pins.filter(pin => !objects.some(object => object.id === pin.localId));
        const font = new FontFaceObserver('Font Awesome 5 Free');

        let loaded = false;

        font.load().then(() => {

            loaded = true;

        });

        newPins.forEach((pin, index) => {

            const { offsetX, offsetY } = calcPinOffsetWorkarea(workarea, pin, imageSize);

            pin.offsetX = offsetX;
            pin.offsetY = offsetY;

            currentRef.handler.add({
                id: pin.localId,
                text: '',
                type: ADD_GPS_PIN_TOOL.type,
                left: pin.x,
                top: pin.y,
                suppressCallback: true,
                lat: pin.lat || 0,
                lng: pin.lng || 0,
                zIndex: index + 1,
                loaded: loaded,
                font: font,
                // editable: grab !== undefined? !grab: false,
                // selectable: grab !== undefined? !grab: false,
            });
        });

        canvas.discardActiveObject();

        canvas.renderAll();

    }, [canvasRef, pins, grab]);
}
