import React, { useCallback } from 'react';
import { AccordionSummary, Input } from '@material-ui/core';
import { ReactComponent as DropdownArrow } from '../../../../ui/assets/images/icons/dropdown-arrow.svg';
import EditMenu from '../../EditMenu/EditMenu';

interface IDroppableItemTitleProps {
    droppableItem: Record<string, number | string>;
    editItem: any;
    droppableName: string[];
    activeItemWrap: boolean;
    getItem: (id: number) => any;
    option: any;
    activeDroppable: (id: number) => void;
    unActiveDroppable: (id: number) => void;
    handleClick: (index: number | string, isOpen: boolean | undefined) => void;
    droppableId: number | null;
}

const DroppableItemTitle: React.FC<IDroppableItemTitleProps> = ({
                                                                    droppableItem,
                                                                    editItem,
                                                                    droppableName,
                                                                    activeItemWrap,
                                                                    getItem,
                                                                    option,
                                                                    activeDroppable,
                                                                    unActiveDroppable,
                                                                    handleClick,
                                                                    droppableId
                                                                }: IDroppableItemTitleProps) => {

    /**
     * edit action callback
     *
     * @type {(event) => void}
     */
    const editActionCallback = useCallback((event) => {

        if (editItem && (editItem.product || editItem.unsorted || editItem.category)) {

            if (editItem.editAction && editItem.category) {

                if (editItem.category.id !== droppableItem.id) {

                    editItem.editAction(event, false, 'clean');
                }

            } else {

                editItem.editAction(event, false, 'clean');
            }
        }
    }, [editItem, droppableItem]);

    /**
     * handle click callback
     *
     * @type {() => void}
     */
    const handleClickCallback = useCallback(() => {

        handleClick(droppableItem.id, getItem(droppableItem.id as number) ? getItem(droppableItem.id as number).isOpen : undefined);

    }, [handleClick, droppableItem, getItem]);

    /**
     * On change callback
     *
     * @type {(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void}
     */
    const onChangeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (editItem.editAction) {

            editItem.editAction(event, false, 'category');

        }

    }, [editItem]);

    /**
     * On key press callback
     *
     * @type {(event: React.KeyboardEvent<HTMLDivElement>) => void}
     */
    const onKeyPressCallback = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {

        if (event.key === 'Enter' && editItem.editAction) {

            editItem.editAction(event, true, 'category');

        }

    }, [editItem]);

    /**
     * Activate Droppable callback
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const activeDroppableCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        event.stopPropagation();

        activeDroppable(droppableItem.id as number);

    }, [activeDroppable, droppableItem]);

    const unActiveDroppableCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        event.stopPropagation();

        unActiveDroppable(droppableItem.id as number);

    }, [unActiveDroppable, droppableItem]);

    const draggableItemClasses = `list-draggable__item-wrap ${activeItemWrap ? '' : 'active'} ${droppableId ? droppableId === droppableItem.id ? 'active' : 'inactive' : ''}`;

    return (
        <AccordionSummary
            aria-controls="panel1a-content"
            id={'category-' + droppableItem.id.toString()}
        >
            <div
                className={draggableItemClasses}
                style={{
                    position: 'relative',
                    backgroundColor: '#fff',
                    width: '100%',
                }}
                onClick={editActionCallback}
            >

                <div
                    className={(getItem(droppableItem.id as number) && getItem(droppableItem.id as number).isOpen) ? 'list-draggable__item MuiListItem-root unsorted MuiListItem-gutters open' : 'list-draggable__item MuiListItem-root unsorted MuiListItem-gutters'}
                    onClick={handleClickCallback}
                >
                    <DropdownArrow />
                    {option.colorField ?
                        <div
                            className="color-circle"
                            style={{
                                backgroundColor: droppableItem.color as string,
                            }}
                        />
                        : null}
                    {editItem && editItem.category && editItem.category.id === droppableItem.id ?
                        <Input
                            autoFocus={Boolean(editItem.category.id)}
                            disableUnderline
                            value={editItem.category.name}
                            className={'edit-category ' + (option.errorStatus ? 'error-field' : '')}
                            inputProps={{
                                'maxLength': 30,
                            }}
                            onChange={onChangeCallback}
                            onKeyPress={onKeyPressCallback}
                        />
                        :
                        <React.Fragment>
                            {droppableName.map(value => droppableItem[value])}
                        </React.Fragment>
                    }
                </div>
                <div className="wrap-menu-edit"
                    onChange={unActiveDroppableCallback}
                    onMouseLeave={unActiveDroppableCallback}
                    onClick={activeDroppableCallback}
                >
                    {option.menu ?
                        <EditMenu
                            menuList={option.menu}
                            dataItem={droppableItem}
                            nodeName={'category'}
                        />
                        : null}
                </div>
            </div>
        </AccordionSummary>
    );
};

export default DroppableItemTitle;
