import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ClipLoader } from 'react-spinners';

interface IProps {
    active: boolean;
}

export const Spinner: React.FC<IProps> = (props: IProps) => {
    const { active = false } = props;

    return (
        <LoadingOverlay
            active={active}
            spinner={
                <ClipLoader color={'#ffffff'} loading={active} />
            }
        />
    );
};

