import React, { Component } from 'react';
import { Button, ConfirmDialog, Message, TextInput } from '../../../core/ui/components';
import { Formik, FormikProps } from 'formik';
import './SensorForm.scss';
import { ReactComponent as SensorIcon } from '../../../core/ui/assets/images/icons/parameter-v-2.svg';
import { ReactComponent as StateIcon } from '../../../core/ui/assets/images/icons/state_icon.svg';
import { IData, IOptions, ISensor, ISensorFormProps, IUpdateMonitoringTree } from '../../../core/interfaces';
import * as yup from 'yup';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Select from '../../../core/ui/components/Select/Select';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormActions, MonitoringActions, SensorActions, UnitActions, HmiObjectTreeAction } from '../../../core/actions';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';
import { RootState } from '../../../core/store';
import {
    selectConfigurationTreeFactory,
    selectConfigurationTreeProcess,
} from '../../../core/selectors/configurationTree/configurationTreeCollectionSelector';


/**
 * Default props and state
 */
interface IState {
    initialValues: ISensor;
    validationSchema: ObjectSchema<Shape<ValidateOptions, Record<string, unknown>>>;
    selectedValue: string;
    confirm: boolean;
    newSensor: ISensor | unknown;
    confirmBody: ISensor | undefined;
    optionsUnits: IOptions[];
    changedData: ISensor | null;
}

/**
 * SensorForm component
 *
 * @class SensorForm
 *
 */
class SensorForm extends Component<ISensorFormProps & WithTranslation, IState> {

    constructor(props: ISensorFormProps & WithTranslation) {

        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.closeSidebar = this.closeSidebar.bind(this);

        this.handleChangeSensorType = this.handleChangeSensorType.bind(this);

        const { sensor, sensorParentUnit } = this.props.formModel as {
            sensor: ISensor,
            sensorParentUnit:  IData | null | undefined
        };
        this.state = {

            initialValues: {
                id: sensor.id,
                um: sensor.um,
                comment: sensor.comment,
                controllerId: sensor.controllerId,
                sensorId: sensor.sensorId,
                latestValue: sensor.latestValue,
                targetValues: sensor.targetValues ? sensor.targetValues : [],
                name: sensor.name,
                pIdCode: sensor.pIdCode || '',
                unit: sensorParentUnit?.unit ? sensorParentUnit.unit?.id || '' : '',
                isKeyParameter: sensor.isKeyParameter,
                sensorType: sensor.sensorType || sensor.type!,
                isNew: sensor.isNew,
            },
            validationSchema: yup.object().shape({
                id: yup.string().required(),
                um: yup.string()
                    .trim()
                    .max(30, this.props.t('MAX_ITEM_LENGTH', { name: 'UM', length: '30' })),
                comment: yup.string()
                    .max(300, this.props.t('MAX_COMMENT_COMMENT'))
                    .nullable(true),
                latestValue: yup.string().transform(value => (value === null ? '' : value)),
                targetValues: yup.array(),
                GID: yup.number(),
                unit: yup.number().transform(value => {
                    return parseInt(value);
                }),
                name: yup.string()
                    .trim()
                    .max(150, this.props.t('MAX_ITEM_LENGTH', { name: this.props.t('NAME'), length: '150' }))
                    .required(this.props.t('SENSOR_NAME_IS_REQUIRED')),
                key_param: yup.boolean().nullable(true),
                pIdCode: yup.string()
                    .trim()
                    .max(15, this.props.t('MAX_ITEM_LENGTH', { name: this.props.t('PID_CODE'), length: '15' })),

            }),
            selectedValue: '',
            confirm: false,
            newSensor: {},
            optionsUnits: [],
            confirmBody: undefined,
            changedData: null,
        };
    }


    /**
     *  Default state
     *
     */


    componentDidMount() {

        this.props.loadUnit(undefined, undefined, undefined, { field: ['process||$notnull||true'] });

    }

    /**
     * Update component when changed props
     *
     * */
    componentDidUpdate(prevProps: Readonly<ISensorFormProps>): void {

        if (prevProps.units !== this.props.units) {

            this.updateOptions();
        }
    }


    /**
     * Handler cancel dialog form
     *
     */
    onConfirmDialog() {

        const { initialValues, changedData } = this.state,
            { update, toggleForm, openSidebar, dashboards, updateMonitoringTree } = this.props;

        const { factories, processes, updateHmiObject } = this.props;

        if (changedData && changedData !== initialValues) {

            changedData.unit = isNaN(parseInt(changedData.unit)) ? '' : parseInt(changedData.unit);

            changedData.isNew = false;

            const process = processes.find(process => process.data.includes(changedData.unit));

            if (process && changedData.unit !== initialValues.unit) {

                const factory = factories.find(factory => factory.data.includes(process.id));
                
                if (factory) {

                    const data = {
                        fabric: factory.id,
                        unit: changedData.unit,
                        sensorId: changedData.id, 
                    };

                    updateHmiObject(data, 'sensor');
                }
            }


            if (update) {

                update({
                    ...changedData,
                    um: changedData.um.trim(),
                    type: changedData.sensorType,
                });

                if (dashboards.length > 0) {

                    const sendData: IUpdateMonitoringTree = { name: changedData.name, isKeyParameter: changedData.isKeyParameter, sensorType: changedData.sensorType };

                    if (this.props.formModel?.sensorParentUnit?.unit?.id && this.props.formModel.sensorParentUnit.unit.id !== changedData.unit) {

                        sendData.moveSensor = {
                            sensor: changedData,
                            unitId: changedData.unit ? changedData.unit.id : null,
                        };
                    }

                    dashboards.forEach(dashboard => {

                            updateMonitoringTree(dashboard.id, 'sensor', changedData.id, sendData, true);
                        },
                    );
                }

            }

        }

        if (changedData && changedData === initialValues) {

            toggleForm(openSidebar);

        }

        this.handleCancel();

    }

    /**
     *  Handler cancel dialog form
     *  reset field form
     *
     */
    handleCancel() {

        this.setState({ confirm: false });

    }


    /**
     *  Handle submit form
     *  verification ke_param in Unit and Sensor.
     *
     * @param {ISensor} values
     *
     */
    handleSubmit(values: ISensor) {

        this.setState({ changedData: values, confirm: true });

    }


    /**
     * Handel Change select parent Unit for Sensor
     *
     * @param {React.ChangeEvent<{ value: unknown }>} event
     * */
    handleChangeSelect(event: React.ChangeEvent<{ value: unknown }>) {

        this.setState({ selectedValue: event.target.value as string });

    }

    /**
     * function for the update the 'Options'
     */
    updateOptions() {
        const optionsUnitsProps: { label: string, value: string }[] = [],
            { units = [] } = this.props;

        units.forEach((value) => {

            optionsUnitsProps.push({
                label: value.name.toString(),
                value: value.id.toString(),
            });

        });

        this.setState({ optionsUnits: optionsUnitsProps });
    }

    /**
     * Sidebar close handler
     */
    closeSidebar():void {

        this.props.toggleForm(this.props.openSidebar);

    }

    /**
     * Handle change sensor
     * 
     * @param {FormikProps<ISensor>} props 
     */
    handleChangeSensorType(props: FormikProps<ISensor>) {
        props.setFieldValue('sensorType', 
            props.values.sensorType === 'state' ? 'graph' : 'state',
        );
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { errors, t } = this.props;

        return (
            <React.Fragment>
                <div className="form-box form-box-space edit-sensor">
                    <Formik
                        initialValues={this.state.initialValues}
                        enableReinitialize
                        validationSchema={this.state.validationSchema}
                        onSubmit={this.handleSubmit}
                    >{props => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <div className="section wrap-edit-sensor">
                                <div className="table-header">
                                    <div className={'title'}>{t('CONFIG_SENSOR')}</div>
                                    {props.values.sensorType === 'state' ?
                                        <StateIcon className={'sensor'} />
                                        :
                                        <SensorIcon className={'sensor'} />
                                    }
                                </div>
                                {errors && errors.field === 'name' &&
                                <div className="common-error">{t('THE_SENSOR_HAS_ALREADY_BEEN_TAKEN')}</div>}
                                {errors && errors.field !== 'name' && errors.message &&
                                <div className="common-error">{t(`${errors.message}`)}</div>}
                                <div className="table-subheader">
                                    <div className="current-options">
                                        <div className="current-options__id">
                                            <div className="name">{t('SENSOR')} ID:</div>
                                            <div
                                                className="value"
                                            >{props.values.controllerId || t('NOT_SET')}.{props.values.sensorId || t('NOT_SET')}
                                            </div>
                                        </div>
                                        <div className="current-options__value">
                                            <div className="name">{t('VALUE')}:</div>
                                            <div className="value">{props.values.latestValue !== null ? props.values.latestValue : t('NOT_SET')} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-body">
                                    <div className="form-group">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.name || errors.field === 'name' ? props.errors.name || errors.field === 'name' ? 'error-field' : 'success-field' : '')
                                            }
                                            label={t('NAME')}
                                            onChange={props.handleChange}
                                            value={props.values.name ? props.values.name : ''}
                                            name="name"
                                            type="text"
                                            placeholder={this.props.t('ENTER_NAME')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {props.touched.name && props.errors.name &&
                                            <div
                                                className="validation-massage"
                                            >
                                                {props.errors.name}
                                            </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.comment ? props.errors.comment ? 'error-field' : 'success-field' : '')
                                            }
                                            label={t('COMMENT')}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.comment ? props.values.comment : ''}
                                            name="comment"
                                            type="text"
                                            placeholder={this.props.t('ENTER_COMMENT')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {props.touched.comment && props.errors.comment &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.comment}
                                            </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group">
                                        <TextInput
                                            label={t('PID_CODE')}
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.pIdCode ? props.errors.pIdCode || (errors && errors.message === 'duplicate key' && errors.field !== 'name') ? 'error-field' : 'success-field' : '')
                                            }
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.pIdCode}
                                            name="pIdCode"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={this.props.t('ENTER_PID_CODE')}
                                        >
                                            {((props.touched.pIdCode && props.errors.pIdCode) || (errors && errors.message === 'duplicate key' && errors.field !== 'name')) &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.pIdCode || (errors.message? t('PID_CODE_ALREADY_TAKEN'): '')}
                                            </div>
                                            }
                                        </TextInput>
                                    </div><div className="form-group">
                                        <TextInput
                                            label="UM"
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.um ? props.errors.um ? 'error-field' : 'success-field' : '')
                                            }
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.um}
                                            name="um"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={this.props.t('ENTER_UM')}
                                        >
                                            {props.touched.um && props.errors.um &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.um}
                                            </div>
                                            }
                                        </TextInput>
                                          </div>
                                    <div className="form-group select-unit select">
                                        <Select
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.unit ? props.errors.unit ? 'error-field' : 'success-field' : '')
                                            }
                                            onChange={props.handleChange}
                                            placeholder={this.props.t('SELECT_UNITS')}
                                            label={this.props.t('SELECT_UNIT')}
                                            value={isNaN(props.values.unit) ? '' : props.values.unit}
                                            options={this.state.optionsUnits}
                                            name="unit"
                                        >
                                            {((props.touched.unit && props.errors.unit)) &&
                                            <div className="validation-massage">{props.errors.unit}</div>
                                            }
                                        </Select>
                                    </div>
                                    {(props.values.sensorType) !== 'state' &&
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={props.values.unit === ''}
                                                    color={'secondary'}
                                                    checked={!!props.values.isKeyParameter}
                                                    onChange={props.handleChange}
                                                    name={'isKeyParameter'}
                                                />
                                            }
                                            label={t('KEY_PARAMETER_Q_P1')}
                                        />
                                    </div>
                                    }
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color={'secondary'}
                                                    checked={props.values.sensorType === 'state'}
                                                    onChange={() => this.handleChangeSensorType(props)}
                                                    name={'sensorType'}
                                                />
                                            }
                                            label={t('DISPLAY_AS_STATE')}
                                        />
                                    </div>
                                    <div className="form-group btn-group">

                                        <Button
                                            type="reset"
                                            color={'primary'}
                                            onClick={this.closeSidebar}
                                        >{t('CANCEL')}
                                        </Button>

                                        <Button
                                            type="submit"
                                            color={'secondary'}
                                        >{this.props.t('SAVE_CHANGES')}
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    )}
                    </Formik>
                </div>
                <ConfirmDialog
                    heading={this.props.t('CHANGE_SENSOR_Q')}
                    onAccept={this.onConfirmDialog}
                    onClose={this.handleCancel}
                    body={this.state.confirmBody ? this.props.t('KEY_PARAMETER_Q_P1') + 'Par. ' + this.state.confirmBody.name + this.props.t('KEY_PARAMETER_Q_P2') : undefined}
                    open={this.state.confirm}
                />
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { units } = state.unit,
        { errors } = state.editSensor,
        { dashboards } = state.dashboard,
        { formModel } = state.form;

    const factories = selectConfigurationTreeFactory(state); 
    
    const processes = selectConfigurationTreeProcess(state);

    return {
        units,
        errors,
        dashboards,
        formModel,
        factories,
        processes,
    };
};


/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    loadSensor: SensorActions.list,
    loadUnit: UnitActions.list,
    update: SensorActions.update,
    updateMonitoringTree: MonitoringActions.update,
    toggleForm: FormActions.toggle,
    updateHmiObject: HmiObjectTreeAction.update,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SensorForm));
