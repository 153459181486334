import { LocationEditorActions, LocationEditorActionTypes } from '../actions/locationEditorActions';
import { ILocation } from '../../interfaces';

export interface ILocationEditorState {
    location: ILocation | null;
}

export const initialState: ILocationEditorState = {
    location: null,
};

export function locationEditorReducer(state = initialState, action: LocationEditorActions): ILocationEditorState {

    switch (action.type) {

        case LocationEditorActionTypes.RESET_WITH_LOCATION_MODEL: {

            const { location } = action.payload;

            return {
                ...initialState,
                location,
            };
        }

        default: {

            return state;
        }
    }
}
