import React from 'react';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import moment from 'moment';

import { DashboardActions, HmiPlayerActions } from '../../../actions';
import './styles/MiniMap.scss';
import { ISensor, IStateItem } from '../../../interfaces';
import { RootState } from '../../../store';
import { GraphActions } from '../../../../base/store/actions';
import { modules } from '../../../../modules';
import {
    selectActiveSensorInTree,
    getAllSensor,
} from '../../../selectors/monitoringTree/minimapGetActiveSensorInTreeSelector';
import { selectRBAC } from '../../../selectors/auth/authSelector';
import { selectGraphPeriodRange } from '../../../selectors/graphPeriod/graphPeriodSelector';
import {
    selectMaxWidthSideBar,
    selectVisibleSideBar,
} from '../../../selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { 
    selectDashboardOnline, 
    selectScreenWidth,
} from '../../../selectors/dashboard/dashboardSelector';
import { selectMonitoringTree } from '../../../selectors/monitoringTree/monitoringTreeSelector';
import { selectAppSettings } from '../../../selectors/appSetting/appSettingSelector';
import { selectHmiPlayerSchema } from '../../../selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../../selectors/hmi/visibilitySelector';
import {
    selectDrawerWidthWithDrawRules,
    selectPositionDrawer,
} from '../../../selectors/layout/responsiveDrawerSelector';
import { appConfig } from '../../../../config/appConfig';
import { ThunkDispatch as Dispatch } from 'redux-thunk/es/types';
import { AnyAction, bindActionCreators } from 'redux';
import { selectGraphDataAll, selectGraphDataRefresh } from '../../../selectors/graphData/graphDataSelector';
import { CancelablePromise } from 'cancelable-promise';

export interface IIntervals {
    sensorId: number;
    intervalsAlerts: number[];
    intervals: {
        x: number;
        width: number;
        color: string;
        warning: boolean;
    }[];
    intervalsWithAlert: {
        x: number;
        width: number;
        color: string;
        warning: boolean;
    }[];
}

interface OwnProps {
    [key: string]: any;
}

type IProps = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface IState {
    [key: string]: any;
}

/**
 * A D3 Bar chart component
 *
 * @class MiniMap
 */
class MiniMap extends React.PureComponent<IProps, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps) {

        super(props);

        this.brushCoords = [];
        this.brushDates = [];
        this.brushIsMoving = false;

        this.state = {
            graphs: [],
        };

        const { startDate, endDate } = this.props.minimapRange;

        this.scale = d3.scaleTime()
            .range([0, this.props.screenWidth]).domain([startDate, endDate]);

        this.drawMinimapRef = null;

        this.onPointerDown = this.onPointerDown.bind(this);

        this.onPointerUp = this.onPointerUp.bind(this);

        this.onClick = this.onClick.bind(this);

        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.handlerRef = this.handlerRef.bind(this);

        this.updateMiniatureCanvas = this.updateMiniatureCanvas.bind(this);

        this.drawMinimap = this.drawMinimap.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.initBrush();

        this.customizeBrushHandles();

        this.updateMiniatureCanvas();

        this.updateMinimap();

    }

    /**
     * Callback after data update
     *
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {

        const { minimapRange, selection } = this.props;

        if (minimapRange !== prevProps.minimapRange || selection !== prevProps.selection) {

            this.updateMinimap();
        }

        if (prevProps.screenWidth !== this.props.screenWidth) {

            // updating position for resetting chart brush
            this.scale.range([0, this.props.screenWidth]);

            // New init brush
            // this.initBrush();

            if (prevProps.screenWidth <= 0 && this.props.screenWidth) {

                this.customizeBrushHandles();
            } else {

                this.initBrush();
            }
        }

        if (this.props.currentPeriod !== prevProps.currentPeriod || this.props.currentPeriodRefresh !== prevProps.currentPeriodRefresh) {

            this.brushSelection.call(this.brushX.clear, [0, this.props.screenWidth]);

        }

        if (JSON.stringify(this.props.minimapRange) !== JSON.stringify(prevProps.minimapRange)) {
            this.brushSelection.call(this.brushX.clear, [0, this.props.screenWidth]);
        }

        if (
            (this.props.minimapRange && this.props.minimapRange !== prevProps.minimapRange) 
            || this.props.screenWidth !== prevProps.screenWidth
        ) {

            this.brushX.extent([[0, 0], [this.props.screenWidth, 55]]);

            if (this.brushCoords[0] !== 0 && this.brushCoords[1] !== this.props.screenWidth) {

                const [leftBrushDate, rightBrushDate] = this.brushDates;

                this.brushSelection.call(this.brushX.move, [this.scale(leftBrushDate), this.scale(rightBrushDate)]);

            } else {

                this.brushSelection.call(this.brushX.clear, [0, this.props.screenWidth]);
            }

            this.setState({ sensorsLoaded: false });

        }

        if (this.props.screenWidth !== prevProps.screenWidth
            || this.props.minimapRange !== prevProps.minimapRange
            || this.props.graphDataRefresh !== prevProps.graphDataRefresh
            || this.props.sensorInTree.size !== prevProps.sensorInTree.size) {

            this.drawMinimap();
            //     .then(value => {
            //
            //     this.setState({
            //
            //         graphs: [...value],
            //     });
            //
            //     this.updateMiniatureCanvas();
            // });

        }

        if (prevProps.screenWidth !== this.props.screenWidth || prevState.graphs !== this.state.graphs) {

            this.updateMiniatureCanvas().then(r => r);
        }

    }


    /**
     * The brush reference
     */
    private brush: SVGGElement | null | undefined;

    /**
     * The X scale
     */
    private readonly scale: d3.ScaleTime<number, number>;

    /**
     * The brush handles selection
     */
    private brushHandles: any;

    /**
     *  Brush init
     */
    private brushX: any;

    /**
     *  Brush selection
     */
    private brushSelection: any;

    /**
     * Brush coordinates in pixels
     */
    private brushCoords: number[];

    /**
     * Brush selected dates
     */
    private brushDates: Date[];

    /**
     * Flag to check if brush is moving
     */
    private brushIsMoving: boolean;

    /**
     * Range of Date
     *
     * @type {any[] | undefined}
     * @private
     */
    private rangeDate: any[] | undefined;

    /**
     * Canvas canvas reference
     *
     * @type {HTMLCanvasElement | undefined}
     * @private
     */
    private minimapRef: HTMLCanvasElement | undefined;

    /**
     * Miniatures canvas reference
     *
     * @type {CanvasRenderingContext2D | null | undefined}
     * @private
     */
    private miniatureRef: CanvasRenderingContext2D | null | undefined;

    /**
     *
     * @type {Boolean}
     * @private
     */
    private firstLoad = true;

    /**
     *
     * @type {CancelablePromise<unknown> | null}
     * @private
     */
    private drawMinimapRef: null | CancelablePromise<unknown>;

    /**
     * Customize brush handles
     */
    customizeBrushHandles() {

        const g = this.brushHandles = this.brushSelection.selectAll('.brush-handle')
            .data([{ type: 'w' }, { type: 'e' }])
            .enter()
            .append('g')
            .attr('fill', 'none')
            .attr('fill-rule', 'evenodd')
            .attr('cursor', 'ew-resize')
            .attr('class', 'brush-handle');

        g.append('path')
            .attr('fill', '#4092f5')
            .attr('d', 'M14 0h2v55h-2zM4 16h10v23H4a4 4 0 0 1-4-4V20a4 4 0 0 1 4-4z');

        g.append('path')
            .attr('fill', '#ffffff')
            .attr('d', 'M5 22h2v11H5zM9 22h2v11H9z')
            .attr('opacity', '.3');

        this.brushSelection.call(this.brushX.clear, [0, this.props.screenWidth]);
    }

    /**
     * Init brush
     */
    initBrush() {
        //init brush (Посмотреть)
        this.brushX = d3.brushX()
            .extent([[0, 0], [this.props.screenWidth, 55]])
            .on('brush end', this.brushed.bind(this));
        if (this.brush) {
            this.brushSelection = d3.select(this.brush)
                .call(this.brushX);
        }
    }

    /**
     * Chart brush move event handler
     */
    brushed() {

        const selection = d3.event.selection || this.scale.range();

        if (!d3.event.selection && this.props.dashboardCurrentTime) {

            this.props.setDashboardOnline();

        } else if (this.props.dashboardCurrentTime && d3.event.selection) {

            this.props.setDashboardOffline();

            if (this.props.HMIIsEnable) {
                this.props.realTime(false);
                this.props.stopPlayer();
            }
        }

        this.brushHandles.attr('transform', (d: any, i: number) => {

            if (i > 0) {

                return `translate(${selection[i] + 16}, 0) scale(-1, 1)`;
            }

            return `translate(${selection[i] - 16}, 0)`;
        });

        const [start, end] = selection;

        if (start === 0 && end === this.props.screenWidth) {

            this.brushHandles.attr('style', 'display: none');
        } else {

            this.brushHandles.attr('style', 'display: auto');
        }

        this.brushCoords = [...selection];

        this.brushDates = selection.map(this.scale.invert, this.scale);

        // // const { minimapRange } = this.props;
        //
        // if (d3.event.selection && this.scale.range()) {
        //     // if (minimapRange.startDate !== this.brushDates[0] || minimapRange.endDate !== this.brushDates[1])
        //     this.props.brushed(this.brushDates, !this.brushIsMoving);
        //     this.firstInit = false;
        //
        // } else {
        //     if (!this.firstInit && !d3.event.selection) {
        //
        //         // if (minimapRange.startDate !== this.brushDates[0] || minimapRange.endDate !== this.brushDates[1])
        //         this.props.brushed(this.brushDates, !this.brushIsMoving);
        //         this.firstInit = true;
        //     }
        // }

        // if (isMobile || isBrowser) {
        //
        //     if (!this.brushIsMoving) {
        //
        //         this.props.brushed(this.brushDates, !this.brushIsMoving);
        //
        //     }
        // } else {
        //
        //     this.props.brushed(this.brushDates, !this.brushIsMoving);
        //
        // }

        // if (this.brush?.getAttribute('pointer-events') === 'all') {

        if (this.brush?.getAttribute('pointer-events') === 'all' && ((new Date(this.props.selection[0]).getMinutes() !== new Date(this.brushDates[0]).getMinutes() ||
            new Date(this.props.selection[1]).getMinutes() !== new Date(this.brushDates[1]).getMinutes()) ||
            this.firstLoad)
        ) {
            const { minimapRange } = this.props;

            const sendBrashDate = new Date(minimapRange.endDate).getTime() < new Date(this.brushDates[1]).getTime() ?
                [this.brushDates[0], minimapRange.endDate] : this.brushDates;

            this.props.brushed(sendBrashDate, !this.brushIsMoving);
            this.firstLoad = false;

        }
    }

    /**
     *  Update all axis and brushed range
     */
    updateMinimap() {

        const domain = this.generateScaleDates();

        this.scale.range([0, this.props.screenWidth]).domain(domain);
    }

    /**
     * Receive data for Minimap and transform it into lines
     *
     */
    drawMinimap():void {

        const { sensorInTree, graphDataAll } = this.props;


        if (sensorInTree.size !== 0) {

            const { minimapRange } = this.props;

            const minimapStart: Date = minimapRange.startDate;

            const timeInPx = Math.abs(new Date(minimapRange.endDate).getTime() - new Date(minimapStart).getTime()) / this.props.screenWidth;

            if (timeInPx > 0) {

                const graphs: IIntervals[] = [];

                for (const sensor of sensorInTree.values()) {
                    if (!graphDataAll.has(sensor.id)) continue;

                    const currentSensorData = graphDataAll.get(sensor.id);

                    if (currentSensorData) {
                        const alertSensor = currentSensorData?.alerts;

                        const intervals: IIntervals = {
                            sensorId: sensor.id,
                            intervals: [],
                            intervalsAlerts: [],
                            intervalsWithAlert: [],
                        };

                        for (const interval of (currentSensorData.miniMap || currentSensorData.states || [])) {

                            intervals.intervals.push({
                                x: this.scale(new Date(interval.start_time
                                    || (interval as unknown as IStateItem).startTime).getTime()) as number,
                                width: this.scale(new Date(interval.end_time
                                    || (interval as unknown as IStateItem).endTime).getTime()) as number - (this.scale(new Date(interval.start_time
                                    || (interval as unknown as IStateItem).startTime).getTime()) as number),
                                color: sensor.sensorType === 'state' ? (interval as unknown as IStateItem).color || '#979797' : '#eeeeee',
                                warning: false,
                            });

                            // intervals.intervals.push({
                            //     x: Math.round(Math.abs(new Date(interval.start_time
                            //         || (interval as unknown as IStateItem).startTime).getTime() - new Date(minimapStart).getTime()) / timeInPx),
                            //     width: Math.round(Math.abs(new Date(interval.end_time
                            //         || (interval as unknown as IStateItem).endTime).getTime() - new Date(interval.start_time
                            //         || (interval as unknown as IStateItem).startTime).getTime()) / timeInPx),
                            //     color: sensor.sensorType === 'state' ? (interval as unknown as IStateItem).color || '#979797': '#eeeeee',
                            //     warning: false,
                            // });
                        }

                        if (alertSensor && alertSensor.length > 0) {

                            for (const alert of alertSensor) {


                                const intervalsAlertPoint = Math.round(Math.abs(new Date(alert.startTime).getTime() - new Date(minimapStart).getTime()) / timeInPx);

                                if (intervals.intervalsAlerts.indexOf(intervalsAlertPoint) === -1) {

                                    intervals.intervalsAlerts.push(intervalsAlertPoint);
                                    intervals.intervalsWithAlert.push({
                                        x: this.scale(new Date(alert.startTime).getTime()) as number,
                                        width: this.scale(new Date(alert.endTime).getTime()) as number - (this.scale(new Date(alert.startTime).getTime()) as number),
                                        color: '#ff3b30',
                                        warning: true,
                                    });

                                    // intervals.intervalsWithAlert.push({
                                    //     x: Math.round(Math.abs(new Date(alert.startTime).getTime() - new Date(minimapStart).getTime()) / timeInPx),
                                    //     width: Math.round(Math.abs(new Date(alert.endTime).getTime() - new Date(alert.startTime).getTime()) / timeInPx),
                                    //     color: '#ff3b30',
                                    //     warning: true,
                                    // });
                                }
                            }
                        }

                        graphs.push(intervals);
                    }
                }
                this.setState({

                    graphs: [...graphs],
                });

                // this.updateMiniatureCanvas();

                // resolve(graphs);

            }
        } else {
            this.miniatureRef && this.miniatureRef.clearRect(0, 0, this.props.screenWidth, 55);
        }

        // return await new Promise((resolve, reject) => {
        //
        // });
    }

    /**
     * Get top & bottom axis params
     *
     * @return {Object}
     */
    axisParam() {

        const smallScreen = window.innerWidth <= 960;

        switch (this.props.scaleDates) {

            case 1:

                return {
                    axisTop: {
                        tick: d3.timeDay.every(1),
                        timeFormat: d3.timeFormat('%d'),
                    },
                    axisBottom: {
                        tick: d3.timeHour.every(smallScreen ? 3 : 1),
                        timeFormat: d3.timeFormat('%H:%M'),
                    },
                };

            case 7:

                return {
                    axisTop: {
                        tick: d3.timeDay.every(1),
                        timeFormat: d3.timeFormat('%d'),
                    },
                    axisBottom: {
                        tick: smallScreen ? d3.timeDay.every(1) : d3.timeHour.every(9),
                        timeFormat: d3.timeFormat('%H:%M'),
                    },
                };

            case moment().daysInMonth():

                return {
                    axisTop: {
                        tick: d3.timeDay.every(moment().daysInMonth()),
                        timeFormat: d3.timeFormat('%B'),
                    },
                    axisBottom: {
                        tick: d3.timeDay.every(smallScreen ? 2 : 1),
                        timeFormat: d3.timeFormat('%d '),
                    },
                };

            case 365:

                return {
                    axisTop: {
                        tick: d3.timeYear.every(1),
                        timeFormat: d3.timeFormat('%Y'),
                    },
                    axisBottom: {
                        tick: d3.timeMonth.every(1),
                        timeFormat: d3.timeFormat('%B'),
                    },
                };

            default:

                return {
                    axisTop: {
                        tick: d3.timeDay.every(1),
                        timeFormat: d3.timeFormat('%d'),
                    },
                    axisBottom: {
                        tick: d3.timeHour.every(smallScreen ? 3 : 1),
                        timeFormat: d3.timeFormat('%H:%M'),
                    },
                };
        }
    }

    /**
     * Generate dates for the X-axis scale
     *
     * @return {Date[]}
     */
    generateScaleDates() {

        moment.locale('ru'); //TODO: Any reason why is this needed?

        if (this.props.minimapRange) {

            if (moment(this.props.minimapRange.endDate).add({ h: 23.98 }).isBefore(new Date())) {

                return [
                    new Date(this.props.minimapRange.startDate),
                    new Date(this.props.minimapRange.endDate),
                ];
            }

            return [
                new Date(this.props.minimapRange.startDate),
                new Date(this.props.minimapRange.endDate),
            ];
        }

        return [
            moment().subtract({ d: this.props.scaleDates ? this.props.scaleDates : 1 }).toDate(),
            new Date(),
        ];
    }

    /**
     * Get ticks for the axis
     *
     * @param params
     *
     * @return {Object[]}
     */
    getTicks(params: any) {

        const output = [];

        const ticks = this.scale.ticks(params.tick);

        for (let i = 0; i < ticks.length; i++) {

            const position = this.scale(ticks[i]) as number;

            output.push({
                value: params.timeFormat(ticks[i]),
                position: position,
                width: i < ticks.length - 1 ? this.scale(ticks[i + 1]) as number - position : 'auto',
            });
        }

        return output;
    }

    onPointerDown() {

        this.brushIsMoving = true;
    }

    onPointerUp() {

        this.brushIsMoving = false;
    }

    onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        event.stopPropagation();

        this.brushIsMoving = false;
    }

    onMouseLeave() {

        this.brushIsMoving = false;
    }

    /**
     * Position On Vertical Line
     *
     * @return {number}
     */
    positionOnVerticalLine(): number {

        const { sensorInTree } = this.props;

        return sensorInTree ? sensorInTree.size : 26;
    }

    /**
     * Update miniature on canvas.
     */
    async updateMiniatureCanvas() {

        const { sensorInTree, screenWidth } = this.props,
            { graphs } = this.state;

        this.miniatureRef && this.miniatureRef.clearRect(0, 0, screenWidth, 55);

        (sensorInTree as Map<number, ISensor>).forEach((sensor, key) => {
            const index: number = Array.from((sensorInTree as Map<number, ISensor>)).findIndex(value => value[0] === key);

            this.miniMapMiniature(sensor, index, graphs);
        });

    }

    /**
     * Drawing thumbnails for alerts
     *
     * @param {any[]} line
     */
    miniMapAlertMiniature(line: any[] = []) {
        line.forEach((value) => {
            if (this.miniatureRef) {
                this.miniatureRef.beginPath();
                this.miniatureRef.strokeStyle = '#ff3b30';
                this.miniatureRef.rect(value, 0, 1, 55);
                this.miniatureRef.stroke();
            }
        });
    }

    /**
     * Make a given color opacity
     *
     *
     * @return {string}
     *
     * @param hex
     */
    hexToRgbA(hex: string): string {
        let c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.5)';
        }
        return hex;
    }

    /**
     * Drawing thumbnails for minimap
     *
     * @param {ISensor} sensor
     * @param {number} index
     * @param {any[]} graphs
     */
    miniMapMiniature(sensor: ISensor, index: number, graphs: IIntervals[]) {

        const currentGraphData: IIntervals | undefined = graphs.find((value: IIntervals) => value.sensorId === sensor.id);

        if (this.miniatureRef && currentGraphData) {

            if (index <= 26) {

                for (const line of currentGraphData.intervals) {

                    const fillStyle = line.warning ? '#ff3b30' :
                        sensor.sensorType !== 'state' && sensor.graphPreferences && sensor.graphPreferences[0].color ?
                            sensor.graphPreferences[0].color || 'yellow' : line.color ? line.color : '#e0f1df';
                        
                        this.miniatureRef.moveTo(line.x, (index * 2) + index);
                        this.miniatureRef.fillStyle = this.hexToRgbA(fillStyle);

                        this.miniatureRef.fillRect(line.x, (index * 2) + index, line.width > 1 ? line.width : 1, 2);


                }

                for (const line of currentGraphData.intervalsWithAlert) {

                    const fillStyle ='#ff3b30';


                        this.miniatureRef.moveTo(line.x, (index * 2) + index);
                        this.miniatureRef.fillStyle = this.hexToRgbA(fillStyle);

                        this.miniatureRef.fillRect(line.x, (index * 2) + index, line.width > 1 ? line.width : 1, 2);


                }
            }

            if (currentGraphData.intervalsAlerts.length > 0) {

                this.miniMapAlertMiniature(currentGraphData.intervalsAlerts);
            }
        }
    }

    /**
     * Handle canvas ref creation
     *
     * @param {Object} ref
     */
    handlerRef(ref: HTMLCanvasElement | null) {

        if (ref) {

            this.minimapRef = ref;
            const { screenWidth } = this.props;

            ref.width = screenWidth;
            ref.height = 55;

            this.miniatureRef = ref.getContext('2d');
        }
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { minimapVisible = true, screenWidth, rbac } = this.props,
            style = {
                display: minimapVisible ? 'flex' : 'none',
            };
        if (screenWidth <= 0) {
            return null;
        }
        const axisParams = this.axisParam();

        return (
            <div className="mini-map"
                 style={style}
                 onPointerDown={this.onPointerDown}
                 onPointerUp={this.onPointerUp}
                 onClick={this.onClick}
                 onMouseLeave={this.onMouseLeave}
            >
                <svg className="brush" width={screenWidth < 0 ? 0 : screenWidth}>
                    <g ref={brush => this.brush = brush} />
                </svg>
                <canvas
                    ref={this.handlerRef}
                    className="miniatures"
                    height={55}
                    width={screenWidth}
                />
                {
                    modules.map((module) => {

                        if (rbac.can('hr:monitoring-tree:manage')) {
                            return module.getMinimap({
                                rangeDate: this.rangeDate,
                                numberOfDataPoints: this.props.screenWidth,
                                positionOnVerticalLine: this.positionOnVerticalLine(),
                            });
                        }
                    })
                }
                <div className="axis top">
                    {this.getTicks(axisParams.axisTop).map((tick, index) => (
                        <div key={index}>
                            <div className="tick" style={{ left: tick.position, width: tick.width }} title={tick.value}>
                                <p>{tick.value}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="axis bottom">
                    {this.getTicks(axisParams.axisBottom).map((tick, index) => (
                        <div key={index}>
                            <div className="tick" style={{ left: tick.position, width: tick.width }} title={tick.value}>
                                <p>{tick.value}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const {
        graphMinimapVisibility,
        graphPeriod,
        dashboard,
    } = state;
    
    const allSensorInTree = getAllSensor(state);

    const schema = selectHmiPlayerSchema(state);
    const isVisible = selectHmiPlayerVisibility(state) && schema !== null;
    const drawWidth = selectDrawerWidthWithDrawRules(state);

    const sensorInTree = selectActiveSensorInTree(state),
        rbac = selectRBAC(state),
        minimapRange= selectGraphPeriodRange(state),
        visibleSideBar = selectVisibleSideBar(state),
        maxWidthSideBar = selectMaxWidthSideBar(state),
        selection = selectBrushSelection(state),
        dashboardOnline = selectDashboardOnline(state),
        anchor: 'right' | 'bottom' = selectPositionDrawer(state) as 'right' | 'bottom',
        screenWidthOrigin = selectScreenWidth(state),
        realTimeIndentation = calcRealTimeIndentation(state),
        screenWidth = screenWidthOrigin - (isVisible && anchor === 'right' ? drawWidth : 0) - realTimeIndentation - appConfig.correctionFactorForDrawingTheTimeline,
        monitoringTree = selectMonitoringTree(state),
        appSetting = selectAppSettings(state),
        graphDataAll =selectGraphDataAll(state),
        graphDataRefresh = selectGraphDataRefresh(state);

    return {
        minimapVisible: graphMinimapVisibility.visible,
        minimapRange,
        currentPeriod: graphPeriod.currentPeriod,
        currentPeriodRefresh: graphPeriod.currentPeriodRefresh,
        scaleDates: graphPeriod.scaleDates,
        visibleSideBar,
        maxWidthSideBar,
        dashboardOnline,
        dashboardCurrentTime: dashboard.currentTime,
        screenWidth: screenWidth > 0 ? screenWidth : 1,
        selection,
        monitoringTree,
        rbac,
        sensorInTree,
        HMIIsEnable: appSetting.hmi.isEnabled,
        allSensorInTree,
        graphDataAll,
        graphDataRefresh,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = (dispatch: Dispatch<RootState, void, AnyAction>) => ({
    dispatch,
    ...bindActionCreators({
    brushed: GraphActions.minimapBrushed,
    setDashboardOnline: DashboardActions.setOnline,
    setDashboardOffline: DashboardActions.setOffline,
    realTime: HmiPlayerActions.setRealTime,
    stopPlayer: HmiPlayerActions.stop,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniMap);
