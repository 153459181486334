import React from 'react';
import { isIE } from 'react-device-detect';

import styles from './AddGpsButton.module.scss';
import { AddGpsIcon } from '../AddGpsIcon/AddGpsIcon';
import { EditorMode } from '../../../../../interfaces';

export const AddGpsButton = (props: IProps) => {
    const { onClick, title, editorMode } = props;

    return (
        <div
            className={[
                'left-section-hr',
                'add-action',
                'padding-1',
                styles.addAction,
                isIE ? styles.ieFix : '',
            ].join(' ')}
            onClick={onClick}
        >
            <AddGpsIcon editorMode={editorMode} />
            <div className={styles.title}>{title}</div>
        </div>
    );
};

interface IProps {
    onClick: () => void;
    title: string;
    editorMode: EditorMode;
}
