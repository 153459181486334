import { userConstants } from '../../constants';
import { IAuthState, IUserAction } from '../../interfaces';
import Rbac from '../../../rbac/Rbac';

/**
 * Auth reducer. Process auth actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current user action
 *
 * @return {Object}
 */
export function auth(state: IAuthState | Record<string, unknown> = {}, action: IUserAction):IAuthState | Record<string, unknown> {

    switch (action.type) {

        case userConstants.LOGIN_SUCCESS:
        case userConstants.PROFILE_SUCCESS:

            return {
                user: action.user,
                rbac: new Rbac(action.user),
                errors: {},
            };

        case userConstants.LOGIN_FAILURE:
        case userConstants.PROFILE_FAILURE:

            return {
                user: null,
                errors: action.errors,
            };

        case userConstants.LOGOUT:

            return {
                user: null,
                errors: {},
            };

        case userConstants.FORGOT_PASSWORD_REQUEST:

            return {
                errors: {},
                success: false,
            };

        case userConstants.FORGOT_PASSWORD_SUCCESS:

            return {
                errors: {},
                success: true,
            };

        case userConstants.FORGOT_PASSWORD_FAILURE:

            return {
                errors: action.errors,
                success: false,
            };

        case userConstants.SET_PASSWORD_SUCCESS:

            return {
                errors: {},
            };

        case userConstants.SET_PASSWORD_FAILURE:

            return {
                errors: action.errors,
            };

        case userConstants.TYPING_PASSWORD:

            return {
                ...state,
                errors: [],
            };

        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                errors: action.errors,
                success: false,
            };

        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                errors: {},
                success: true,

            };
        default:

            return state;
    }
}
