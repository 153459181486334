import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { selectGraphMinimapBrushRule } from '../graphMinimapBrush/graphMinimapBrushSelector';
import { IHistogramData } from '../../interfaces';
import { getDataRangeRole } from '../HistogramChartHr/sensorDataHrSelector';

//TODO narrow logic
const stateSelector = (state: RootState) => state.hmiDashboardDataState;

export const selectHmiNarrowData = createSelector(
    stateSelector,
    hmiDashboardDataState => hmiDashboardDataState.sensorData,
);

export const selectHmiNarrowDataRange = createSelector(
    stateSelector,
    hmiDashboardDataState => hmiDashboardDataState.sensorDataRange,
);

const getSensorId = (state: RootState, sensorId: number) => sensorId;

export const selectNarrowSensorDataBySensorId = createSelector(
    selectHmiNarrowData,
    selectHmiNarrowDataRange,
    getDataRangeRole,
    selectGraphMinimapBrushRule,
    getSensorId,
    (sensorsData, sensorsDataRange, range, brushRule, sensorId) =>
        (range || !brushRule ? sensorsData : sensorsDataRange).find(sensorData => sensorData.id === sensorId
            && sensorData.type === 'graph') as IHistogramData,
);