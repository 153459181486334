import { IHmiObjectTree } from '../interfaces';
import { HmiObjectTreeService } from '../services/hmiObjectTreeService';
import { hmiObjectTreeConstant } from '../constants';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, IFilter, IJoin, IOrder, IHmiSchema } from '../interfaces';
import { hmiSchemasService } from '../services';
import { hmiSchemaConstants } from '../constants';
import { AxiosResponse } from 'axios';

/**
 * Hmi object tree action 
 */
export const HmiObjectTreeAction = {

    /**
     * Updated action
     * 
     * @param {IHmiObjectTree} data 
     * @param {string} type 
     * @return {Promise<Object>}
     */
    update: (data: IHmiObjectTree, type: string, detach = false) => {

        const success = () => {
            return {
                type: hmiObjectTreeConstant.HMI__OBJECT_TREE_UPDATE_SUCCESS,
            };
        };

        const failure = () => {
            return {
                type: hmiObjectTreeConstant.HMI__OBJECT_TREE_UPDATE_FAILURE,
            };
        };

        const service = new HmiObjectTreeService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            service.update(data, type, detach)
                .then(
                    () => dispatch(success()),
                    () => dispatch(failure()),
                );
        };
    },

    /**
     * Remove action
     * 
     * @param {strng} type 
     * @param {number} id 
     * @return {Promise<Object>} 
     */
    remove: (type: string, id: number) => {

        const success = () => {
            return {
                type: hmiObjectTreeConstant.HMI__OBJECT_TREE_DELETE_SUCCESS,
            };
        };

        const failure = () => {
            return {
                type: hmiObjectTreeConstant.HMI__OBJECT_TREE_DELETE_FAILURE,
            };
        };

        const service = new HmiObjectTreeService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            service.remove(type, id)
                .then(
                    () => {
                        dispatch(success());
                        dispatch(HmiObjectTreeAction.refreshHmiSchemaList());
                    },
                    () => dispatch(failure()),
                );
        };
    },

    /**
     * Remove bulk action
     * 
     * @param {string} type 
     * @param {number[]} ids 
     */
    removeBulk: (type: string, ids: number[]) => {

        const success = () => {
            return {
                type: hmiObjectTreeConstant.HMI__OBJECT_TREE_DELETE_SUCCESS,
            };
        };

        const failure = () => {
            return {
                type: hmiObjectTreeConstant.HMI__OBJECT_TREE_DELETE_FAILURE,
            };
        };

        const service = new HmiObjectTreeService();

        return async(
            dispatch: Dispatch<Record<string, unknown>, void, AnyAction>,
        ) => {

            for await (const id of ids) {

                await service.remove(type, id)
                    .then(
                        () => dispatch(success()),
                        () => dispatch(failure()),
                    );
            }

            dispatch(HmiObjectTreeAction.refreshHmiSchemaList());
        };
    },

    /**
     * Refresh hmi schema list
     * 
     * @param {string} search 
     * @param {IOrder} order 
     * @param {IJoin} join 
     * @param {IFilter} filter 
     * @returns 
     */
    refreshHmiSchemaList: (
        search = '', 
        order: IOrder = { column: 'id', dir: 'asc' },
        join?: IJoin,
        filter?: IFilter,
    ) => {
        //Action creators
        const success = (hmiSchemas: IHmiSchema[]) => {

            return {
                type: hmiSchemaConstants.LIST_HMI_SCHEMA_SUCCESS,
                schemas: hmiSchemas,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: hmiSchemaConstants.LIST_HMI_SCHEMA_FAILURE,
                errors,
            };
        }, service = new hmiSchemasService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join, filter)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },
};