import {
    FetchEmployeesFailure,
    FetchEmployeesRequest,
    FetchEmployeesSuccess,
    SaveEmployeeAction,
    SaveEmployeeFailure,
    UpdateEmployeeSuccess,
    UpdateEmployeeFailure,
    DeleteEmployeeAction, DeleteEmployeeFailure, GetEmployeeSuccess, GetEmployeeFailure, ClearEmployeeError,
} from '../actions/employeeActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { EmployeeService } from '../../services/employeeService';
import { IFilter, IJoin, IOrder } from '../../../../core/interfaces';
import { IEmployeeState } from '../reducers/employeeReducer';
import { IEmployee, IFormEmployee } from '../../interfaces';
import { OpeningForm } from '../actions/settingPageActions';
import { DepartmentService } from '../../services/departmentService';
import { DeleteEmployeeInDepartmentAction, FetchDepartmentsSuccess } from '../actions/departmentActions';

export const EmployeeThunks = {

    fetchEmployees: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    },  join?: IJoin, filter?: IFilter) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void>  => {

        const employeeService = new EmployeeService();

        try {

            const employees = await employeeService.list(search, order, join, filter);

            dispatch(new FetchEmployeesRequest());

            dispatch(new FetchEmployeesSuccess({ employees }));

        } catch (errors) {

            const error = employeeService.errorHandler(errors);

            dispatch(new FetchEmployeesFailure({ error }));

        }
    },

    createEmployee: (model: IFormEmployee) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void>  => {

        const employeeService = new EmployeeService(),
            departmentService = new DepartmentService();

        try {

            const employee = await employeeService.store(model);

            dispatch(new SaveEmployeeAction({ employee }));

            dispatch(new OpeningForm({ formOpening: false  }));

            if (model.beacon) {

                const employees = await employeeService.list('', {
                    column: 'id',
                    dir: 'asc',
                }, undefined, { field: ['department.id||$isnull'] });

                const departments = await departmentService.list('', {
                    column: 'id',
                    dir: 'asc',
                }, { table: ['employees', 'employees.beacon'] });

                dispatch(new FetchDepartmentsSuccess({ departments }));

                dispatch(new FetchEmployeesSuccess({ employees }));
            }

        } catch (errors) {

            const error = employeeService.errorHandler(errors);

            dispatch(new SaveEmployeeFailure({ error }));

        }
    },

    getEmployee: (model: IEmployee) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void>  => {

        const employeeService = new EmployeeService();

        try {

            const employee = await employeeService.getEmployee(model);

            dispatch(new GetEmployeeSuccess({ employee }));

        } catch (errors) {

            const error = employeeService.errorHandler(errors);

            dispatch(new GetEmployeeFailure({ error }));

        }
    },

    updateEmployee: (model: IFormEmployee) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void>  => {

        const employeeService = new EmployeeService(),
            departmentService = new DepartmentService();

        try {

            const employee = await employeeService.update(model);

            const employees = await employeeService.list('', {
                column: 'id',
                dir: 'asc',
            }, undefined, { field: ['department.id||$isnull'] });

            const departments = await departmentService.list('', {
                column: 'id',
                dir: 'asc',
            }, { table: ['employees', 'employees.beacon'] });

            dispatch(new UpdateEmployeeSuccess({ employee }));

            dispatch(new FetchDepartmentsSuccess({ departments }));

            dispatch(new FetchEmployeesSuccess({ employees }));

            dispatch(new OpeningForm({ formOpening: false  }));

        } catch (errors) {

            const error = employeeService.errorHandler(errors);

            dispatch(new UpdateEmployeeFailure({ error }));

        }
    },

    deleteEmployee: (employee: IEmployee) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {


        const employeeService = new EmployeeService();

        try {

            await employeeService.delete(employee);

            dispatch(new DeleteEmployeeAction({ employee }));

            dispatch(new DeleteEmployeeInDepartmentAction({ employee }));

        } catch (errors) {

            const error = employeeService.errorHandler(errors);

            dispatch(new DeleteEmployeeFailure({ error }));

        }
    },

    clearEmployeeError: () => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        dispatch(new ClearEmployeeError());

    },

};
