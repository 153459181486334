import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';
import { IBeacon, IEmployee } from '../interfaces';

export interface IBeaconApiModel {
    id: string,
    name: string,
    bleMac: string | null,
    lowBattery: number,
    active: number,
    lastSeen: null | Date;
    employee?: null | IEmployee;
}

export type IBeaconIndentOnly = Pick<IBeaconApiModel, 'id'>;

/**
 * Service to work with Employee API resources
 *
 * @class EmployeeService
 */
export class BeaconService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/beacons(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of Employee
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<IBeacon[]> {

        return await this.http
            .get<IBeaconApiModel[]>(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            })
            .then(this.getDataExtractor())
            .then(list => list.map(mapToBeaconModel));
    }

    /**
     * Create beacon
     * @param model
     */
    store(model: IBeacon): Promise<IBeacon> {
        const url = this.url();

        const dataToSend: any = model as IBeaconApiModel;

        if (!dataToSend.name || !dataToSend.name.trim()) {
            dataToSend.name = null;
        }

        if (dataToSend.employee && typeof dataToSend.employee === 'object') {
            dataToSend.employee = dataToSend.employee.id;
        }

        return trackPromise(
            this.http
                .post<IBeaconApiModel>(url, dataToSend, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToBeaconModel),
        );
    }

    /**
     * Update beacon
     * @param model
     */
    update(model: IBeacon): Promise<IBeacon> {
        const url = this.url({ id: model.id }) + '?join=employee';

        const dataToSend: any = model as IBeaconApiModel;

        if (!dataToSend.name || !dataToSend.name.trim()) {
            dataToSend.name = null;
        }

        if (dataToSend.employee && typeof dataToSend.employee === 'object') {
            dataToSend.employee = dataToSend.employee.id;
        }

        if (dataToSend.bleMac) {
            delete dataToSend.bleMac;
        }

        return trackPromise(
            this.http
                .patch<IBeaconApiModel>(url, dataToSend, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToBeaconModel),
        );
    }

    save(beacon: IBeacon): Promise<IBeacon> {

        if (beacon.id) {

            return this.update(beacon);
        }

        return this.store(beacon);
    }

    /**
     * Delete beacon
     * @param model
     */
    delete(model: IBeaconIndentOnly): Promise<boolean> {

        const url = this.url({ id: model.id });

        return trackPromise(
            this.http
                .delete<IBeaconApiModel>(url, {
                    headers: this.headers,
                })
                .then(Boolean),
        );
    }

}

export function mapToBeaconModel(rawBeacon: IBeaconApiModel): IBeacon {
    return {
        id: rawBeacon.id,
        active: rawBeacon.active,
        bleMac: rawBeacon.bleMac,
        lastSeen: rawBeacon.lastSeen,
        lowBattery: rawBeacon.lowBattery,
        name: rawBeacon.name || '',
        employee: rawBeacon.employee ? rawBeacon.employee : null,
    };
}
