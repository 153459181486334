import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { WithTranslation } from 'react-i18next';

import Header from '../Header/Header';
import LeftSidebar from '../Sidebar/LeftSidebar';
import Password from './Password/Password';
import Products from './Products/Products';
import States from './States/States';
import UserList from './User/UserList';
import Factory from './Factory';
import Process from './Process';
import Unit from './Unit';
import { PrivateRoute } from '../../core/routes';

import { FormActions } from '../../core/actions';
import { IAppState, IUser } from '../../core/interfaces';

import { modules } from '../../modules';
import { RootState } from '../../core/store';


interface IProps {
    user: IUser;
    match: Record<string, unknown>;
    closeSidebar?: (close: boolean, name: string) => void;
    settings: IAppState;
    isLoaded: boolean;
}

interface IState {
    listMenu:  ({
        itemName: string,
        component: ComponentType<any>,
        path: string,
        route: string,
        permission: string,
        action: (close: boolean) => void
    })[];
    moduleMount: boolean;
}

/**
 * Class component for the Setting page
 *
 * @class Setting
 */
class Setting extends Component<WithTranslation & IProps, IState> {

    /**
     * Constructor
     *
     * @param {WithTranslation & IProps} props
     */
    constructor(props: WithTranslation & IProps) {

        super(props);

        this.state = {
            listMenu: [
                {
                    itemName: 'FACTORIES',
                    component: Factory,
                    path: '/factory',
                    route: 'setting:factory',
                    permission: 'factory:manage',
                    action: this.closeSidebar.bind(this),
                },
                {
                    itemName: 'PROCESSES',
                    component: Process,
                    path: '/process',
                    route: 'setting:process',
                    permission: 'process:manage',
                    action: this.closeSidebar.bind(this),
                },
                {
                    itemName: 'UNITS',
                    component: Unit,
                    path: '/unit',
                    route: 'setting:unit',
                    permission: 'unit:manage',
                    action: this.closeSidebar.bind(this),
                },
                {
                    itemName: 'PRODUCTS',
                    component: Products,
                    path: '/product',
                    route: 'setting:product',
                    permission: 'product:manage',
                    action: this.closeSidebar.bind(this),
                },
                {
                    itemName: 'STATES',
                    component: States,
                    path: '/state',
                    route: 'setting:state',
                    permission: 'state:manage',
                    action: this.closeSidebar.bind(this),
                },
                {
                    itemName: 'USERS',
                    component: UserList,
                    path: '/user',
                    route: 'setting:user',
                    permission: 'user:manage',
                    action: this.closeSidebar.bind(this),
                },
                {
                    itemName: 'PASSWORD',
                    component: Password,
                    path: '/password',
                    route: 'setting:password',
                    permission: 'password:update',
                    action: this.closeSidebar.bind(this),
                },
            ],
            moduleMount: false,
        };

        this.stopPropagation = this.stopPropagation.bind(this);

    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.moduleMountFunction();
    }

    /**
     * Callback after component props update
     *
     */
    componentDidUpdate() {

        this.moduleMountFunction();

    }

    /**
     * Checking Permission to Mount a Module
     */
    moduleMountFunction() {
        const { settings, isLoaded } = this.props,
            { moduleMount, listMenu } = this.state;

        if (!moduleMount && isLoaded) {

            modules.forEach((module) => {

                /**
                 * optional parameter for control from the main api
                 */
                if (module.id === 'qv-hr') {

                    module.setModuleStatus(settings.qvhr.isEnabled);
                }

                const routes = module.routes();

                if (routes.settings) {

                    routes.settings.map((route) => {

                        listMenu.push({
                            itemName: route.name,
                            component: route.component,
                            path: route.path,
                            route: route.route,
                            permission: route.permission,
                            action: this.closeSidebar.bind(this),
                        });

                        return route;
                    });
                }
            });

            this.setState({ listMenu: [...listMenu], moduleMount: true });
        }
    }

    /**
     * Close sidebar
     */
    closeSidebar() {

        if (this.props.closeSidebar) {

            this.props.closeSidebar(true, '');
        }
    }

    /**
     * Stop Propagation
     *
     * @param {React.MouseEvent<HTMLElement, MouseEvent>} event
     */
    stopPropagation(event: React.MouseEvent<HTMLElement, MouseEvent>) {

        event.stopPropagation();
    }

    /**
     * Component render
     *
     * @returns {JSX.Element}
     */
    render() {

        const { match } = this.props,
            { listMenu } = this.state;

        return (
            <React.Fragment>
                <Header />
                <LeftSidebar listItem={[...listMenu]} />
                <main className="content" onMouseMove={this.stopPropagation}>
                    {listMenu.map((menu) => (

                        <PrivateRoute
                            key={menu.itemName}
                            path={match.path + menu.path}
                            component={menu.component}
                            route={menu.route}
                        />
                    ))}
                </main>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { settings, isLoaded } = state.appSetting;

    return {
        settings,
        isLoaded,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    closeSidebar: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(Setting);

