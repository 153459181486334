import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as DropdownArrowIcon } from '../../../core/ui/assets/images/icons/dropdown-arrow.svg';
import { ReactComponent as FactoryIcon } from '../../../core/ui/assets/images/icons/folder-factory.svg';
import { ReactComponent as ProcessIcon } from '../../../core/ui/assets/images/icons/folder-process.svg';
import { ReactComponent as UnitIcon } from '../../../core/ui/assets/images/icons/folder-unit.svg';
import { ReactComponent as FolderKeyIcon } from '../../../core/ui/assets/images/icons/folder-key.svg';
import { DeleteDialog, EditMenu, Tooltip } from '../../../core/ui/components';
import AddProduct from './AddProduct/AddProduct';
import { useDispatch, useSelector } from 'react-redux';
import {
    INodeTitleProps,
    IProduct,
    ISensor,
    IUnit,
} from '../../../core/interfaces';
import { isIE } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { 
    ConfigurationActions,
    FormActions,
    HmiObjectTreeAction,
} from '../../../core/actions';
import { selectAllTargetValue } from '../../../core/selectors/product/allTargetValueSelector';
import { appConfig } from '../../../config/appConfig';


interface ISensorTarget extends ISensor {
    productTargetValues?: ITargetProduct[]
}

interface ITargetProduct {
    maxTargetValue?: number;
    minTargetValue?: number;
    product: IProduct;
}

/**
 * Drag'n'drop tree node title functional component
 *
 * @param handleClick
 * @param node
 * @param handlerNodeMouseEnter
 * @param getOpenStatus
 * @param nodeName
 * @param rightSectionClass
 * @param parent
 * @param nodeNameMenu
 * @param keyParamData
 * @param processRule
 * @param maxProductInUnit
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const NodeTitle: React.FC<INodeTitleProps> = (
    {
        handleClick,
        node,
        menuUnit,
        getOpenStatus,
        nodeName,
        rightSectionClass,
        parent,
        nodeNameMenu,
        keyParamData,
        processRule,
        maxProductInUnit,
        filterValue = '',
        products,
        scrollLeft,
        collapseStateItem,
        sensorInUnit,
    }: INodeTitleProps,
) => {

    const [editMenuActive, setEditMenuActive] = useState(false);

    const [elementContainer, setElementContainer] = useState<Element | null>(null);

    const [removeId, setRemoveId] = useState<{ type: string, id: number, data?: number[] } | null>(null);
    const [removeType, setRemoveType] = useState<string | null>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch(),
        allTargetValue = useSelector(selectAllTargetValue);

    /**
     * Confirm remove Node
     *
     * @param {Object} data
     */
    const removeConfirmationNode = (data: { parent: Record<string, unknown>, current: { id: number, type: string } }) => {

        const { current } = data;

        if (current) {

            setRemoveId(current);
            setRemoveType('node');
        }

    };

    /**
     *   Open form 'Edit node'
     *
     * @param {Object} node
     * @param {boolean} opened
     * @param {string} name
     *
     */
    const editNode = (node: Record<string, unknown>, opened: boolean, name: string) => {

        dispatch(FormActions.toggle(false, name, node));
    };

    const menuNode: {
        title: string;
        action: (...props: any) => void;
        color?: string;
        icon?: React.ReactNode;
    }[] = [
        {
            title: t('EDIT_CONFIG'),
            action: editNode,
            color: '',
        },
        {
            title: t('DELETE'),
            action: removeConfirmationNode,
            icon: '',
            color: 'red',
        },
    ];

    /**
     * Remove Node
     */
    const removeNode = useCallback(() => {

        if (removeId && removeId.type !== 'sensor') {

            dispatch(ConfigurationActions.remove(removeId.type, { id: removeId.id }));

            if (removeId.type === 'process' && removeId.data?.length) {
    
                dispatch(HmiObjectTreeAction.removeBulk('unit', removeId.data));
            }

            if (removeId.type === 'factory') {

                dispatch(HmiObjectTreeAction.remove('fabric', removeId.id));
            }

        }

        setRemoveId(null);
        setRemoveType(null);

    }, [dispatch, setRemoveId, setRemoveType, removeId]);

    /**
     * Cancel delete. For the popup dialog
     */
    const onDeleteNodeDialogClose = useCallback(() => {

        setRemoveId(null);

    }, [setRemoveId]);


    useEffect(() => {

        setElementContainer(document.getElementsByClassName('configure-draggable')[0]);

    }, [setElementContainer]);
    /**
     * Return empty block
     *
     * @param {number} value
     *
     * @return {JSX}
     */
    const emptyProductSpace = (value: number) => {

        const content = [];

        for (let i = 0; i < value; i++) {

            content.push(
                <div key={i + 1}
                     className={`empty-product-space ${nodeName} ${value === (i + 1) ? 'last-child' : ''}`}
                     style={{
                         width: value === (i + 1) ? 144 : 152,
                         marginRight: value === (i + 1) ? 8 : 0,
                         paddingRight: value === (i + 1) ? 0 : 8,
                         background: '#fff',
                         borderBottom: '1px solid #e9ecef',
                     }}
                />,
            );
        }

        return content;
    };

    /**
     * Return empty block
     *
     * @param {number} value
     *
     * @return {JSX}
     */
    const emptyTargetValue = (value: number) => {

        const content = [];

        const baseClassName = 'MuiListItemText-root description__targetvalue';

        for (let i = 0; i < value; i++) {
            if (processRule) {
                content.push(
                    <div key={i + 1}
                         className={`${baseClassName} ${processRule ? '' : 'empty'} ${value === (i + 1) ? 'last-child' : ''}`}
                    >
                        <span
                            className={`MuiTypography-root MuiListItemText-primary MuiTypography-body1 ${filteredDataLength() === 0 ? 'border-bottom' : ''}`}
                        >
                             <ul className="target-list">
                                <li className="target-item"
                                    style={{ background: keyParamData ? '#f5f6f7' : 'inherit' }}
                                />
                                <li className="target-item"
                                    style={{ background: keyParamData ? '#f5f6f7' : 'inherit' }}
                                />
                             </ul>
                        </span>
                    </div>,
                );
            }
        }

        return content;
    };

    /**
     * Get target value for current product
     *
     * @param {IProduct} product
     */
    const getCurrentTargetValue = (product: IProduct) => {


        const currentSensorTargetList: ISensorTarget | undefined = allTargetValue && keyParamData ?
            allTargetValue.find(value => value.id === keyParamData[0].id) : undefined;

        if (currentSensorTargetList) {

            const { productTargetValues = [] } = currentSensorTargetList;

            const targetValues = productTargetValues.find(targetValue => targetValue.product && targetValue.product.id === product.id);

            if (targetValues) {

                return targetValues;
            }
        }

        return {
            maxTargetValue: undefined,
            minTargetValue: undefined,
        };
    };

    /**
     * Returns the target value of the key sensor
     *
     * @return {JSX}
     */
    const descriptionTargetValue = () => {

        const content: React.ReactNode[] = [];

        if (products && keyParamData && products.length > 0 && keyParamData.length > 0 && allTargetValue) {

            const baseClassName = 'MuiListItemText-root description__targetvalue';

            for (const product of products) {
                const index: number = products.indexOf(product);

                const currentTargetValue = getCurrentTargetValue(product);

                content.push(
                    <div key={product.id}
                         className={`${baseClassName} ${processRule ? '' : 'empty'} ${products.length - 1 === index ? 'last-child' : ''}`}
                    >
                        <span
                            className={`MuiTypography-root MuiListItemText-primary MuiTypography-body1 ${filteredDataLength() === 0 ? 'border-bottom' : ''}`}
                        >
                            {processRule &&
                            (
                                <ul className="target-list">
                                    <li className="target-item"
                                        style={{ background: keyParamData ? '#f5f6f7' : 'inherit' }}
                                    >
                                        <Tooltip
                                            title={currentTargetValue?.minTargetValue || ''}
                                            leaveDelay={0}
                                            enterDelay={0}
                                            disableTouchListener
                                        >
                                            <span>{currentTargetValue.minTargetValue ? currentTargetValue.minTargetValue : ''}</span>
                                        </Tooltip>
                                    </li>
                                    <li className="target-item"
                                        style={{ background: keyParamData ? '#f5f6f7' : 'inherit' }}
                                    >
                                        <Tooltip
                                            title={currentTargetValue?.maxTargetValue || ''}
                                            leaveDelay={0}
                                            enterDelay={0}
                                            disableTouchListener
                                        >
                                            <span>{currentTargetValue.maxTargetValue ? currentTargetValue.maxTargetValue : ''}</span>
                                        </Tooltip>
                                    </li>
                                </ul>
                            )
                            }
                        </span>
                    </div>,
                );
            }
        }

        return content;
    };

    /**
     *  onClick Callback
     *
     * @type {() => void}
     */
    const onClickCallback = useCallback(() => {
        
        if (handleClick) {
            
            handleClick(node.id, nodeName, (collapseStateItem ? collapseStateItem.status : !getOpenStatus));
            
        }
        
    }, [node, nodeName, getOpenStatus, handleClick, collapseStateItem]);

    /**
     * stopPropagation Callback
     *
     * @type {(event) => void}
     */
    const stopPropagationCallback = useCallback((event) => {

        event.stopPropagation();

    }, []);

    /**
     *  Callback for the close editMenu
     *
     * @type {(event) => void}
     */
    const editMenuCloseCallback = useCallback((event) => {

        setEditMenuActive(Boolean(event));

    }, [setEditMenuActive]);

    /**
     *  Callback for the open editMenu
     *
     * @type {(event) => void}
     */
    const editMenuOpenCallback = useCallback((event) => {

        setEditMenuActive(Boolean(event));

    }, [setEditMenuActive]);

    /**
     * Number of Filtered Sensors
     *
     * @return {number}
     */
    const filteredDataLength = (): number => {

        const filteredData = node.data as ISensor[];

        return filteredData.filter((value) =>
            value.name.toLowerCase().match(filterValue as string),
        ).length;
    };

    /**
     * Calculate altitude based on filtered data
     *
     * @return {number}
     */
    const filteredHeight = (): number => {
        const { defaultHeightConfigTreeItem } = appConfig;

        return collapseStateItem ?
            collapseStateItem.status ? (defaultHeightConfigTreeItem * filteredDataLength()) + defaultHeightConfigTreeItem
                : defaultHeightConfigTreeItem
            : getOpenStatus ? (defaultHeightConfigTreeItem * filteredDataLength()) + defaultHeightConfigTreeItem : defaultHeightConfigTreeItem;
    };


    return (
        <div className={'item-title'}
             onClick={onClickCallback}
        >
            <div
                className={`right-section ${rightSectionClass} ${collapseStateItem ? collapseStateItem.status ? 'active' : '' : getOpenStatus ? 'active' : ''}`}
                style={{
                    left: isIE ? scrollLeft : 0,
                    position: isIE ? 'relative' : 'sticky',
                }}
            >
                <DropdownArrowIcon />
                {nodeName === 'factory' &&
                <FactoryIcon style={{ height: 24, width: 24, marginRight: 8 }} />
                }
                {nodeName === 'process' &&
                <ProcessIcon style={{ height: 24, width: 24, marginRight: 8 }} />
                }
                {nodeName === 'unit' && !keyParamData &&
                <UnitIcon style={{ height: 24, width: 24, marginRight: 8 }} />
                }
                {nodeName === 'unit' && Boolean(keyParamData) && sensorInUnit && keyParamData && keyParamData.length > 0 &&
                <FolderKeyIcon style={{ height: 24, width: 24, marginRight: 8 }} />
                }
                {nodeName === 'unit' && Boolean(keyParamData) && keyParamData && keyParamData.length <= 0 &&
                <UnitIcon style={{ height: 24, width: 24, marginRight: 8 }} />
                }
                {node.name}
                <span
                    className={editMenuActive ? 'wrap-edit-menu-node active' : 'wrap-edit-menu-node'}
                    onClick={stopPropagationCallback}
                >
                        <EditMenu
                            menuList={menuUnit || menuNode}
                            dataItem={{ parent: parent, current: node }}
                            nodeName={nodeNameMenu}
                            onClose={editMenuCloseCallback}
                            container={elementContainer}
                            onOpen={editMenuOpenCallback}
                        />
                </span>
            </div>
            {Boolean(keyParamData) && sensorInUnit ?
                <div className={'left-section edit-sensor-list with-key-param ' + nodeName}
                     onClick={stopPropagationCallback}
                >
                    <div className="MuiListItemText-root description__id" style={{ height: 40 }}>
                        <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1" />
                    </div>
                    <div className="MuiListItemText-root description__pit-code" style={{ height: 40 }}>
                        <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">
                            <span className="value">
                                {keyParamData && keyParamData.length > 0 ? keyParamData[0].pIdCode : ''}
                            </span>
                        </span>
                    </div>
                    <div className="MuiListItemText-root description__um" style={{ height: 40 }}>
                        <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">
                            <span className="value">
                                {keyParamData && keyParamData.length > 0 ? keyParamData[0].um : ''}
                            </span>
                        </span>
                    </div>
                    <div className="MuiListItemText-root description__comment" style={{ height: 40 }}>
                        <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1" />
                    </div>
                    <div className="MuiListItemText-root description__value" style={{ height: 40 }}>
                        <span className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">
                            {keyParamData && keyParamData.length > 0 ? keyParamData[0].latestValue : ''}
                        </span>
                    </div>
                    {keyParamData && keyParamData.length > 0 && products ? descriptionTargetValue() : emptyTargetValue(maxProductInUnit)}
                    {sensorInUnit && node.data ?
                        <AddProduct
                            height={filteredHeight()}
                            class={processRule ? '' : 'empty'} currentUnit={node as IUnit}
                        />
                        :
                        null
                    }
                </div>
                :
                <div className={'left-section with-empty-space ' + nodeName} onClick={stopPropagationCallback}>
                    <div className="setting-box--empty-space" />
                    {emptyProductSpace(maxProductInUnit)}
                </div>
            }
            <DeleteDialog
                open={removeId !== null}
                removeId={removeId ? removeId?.id : null}
                heading={t(removeType === 'sensor' ? 'REMOVE_SENSOR_Q' : 'REMOVE_NODE_Q')}
                body={t(removeType === 'sensor' ? 'THIS_ACTION_WILL_DELETE_SENSOR_Q' : 'THIS_ACTION_WILL_DELETE_NODE_AND_CANNOT_BE_UNDONE')}
                onAccept={removeNode}
                onClose={onDeleteNodeDialogClose}
                className={'configuration-popover'}
            />
        </div>
    );
};

export default NodeTitle;