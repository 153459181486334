import { fabric } from 'fabric';
import { editorConstants } from '../../constants';

const Sensor = fabric.util.createClass(fabric.Object, {

    type: editorConstants.objects.sensor,

    superType: 'drawing',

    initialize(options: any) {

        options.originX = 'center';
        options.originY = 'center';
        options.width = 24;
        options.height = 24;

        this.callSuper('initialize', options || {});
    },

    _render(ctx: CanvasRenderingContext2D) {

        const { strokeColor = '#74797d', fillColor = '#cdd0d4' } = this;

        ctx.fillStyle = strokeColor;

        ctx.beginPath();
        ctx.moveTo(-12, -11);
        ctx.bezierCurveTo(-12, -11.5, -11.5, -12, -11, -12);
        ctx.lineTo(11, -12);
        ctx.bezierCurveTo(11.5, -12, 12, -11.5, 12, -11);
        ctx.lineTo(12, 11);
        ctx.bezierCurveTo(12, 11.5, 11.5, 12, 11, 12);
        ctx.lineTo(-11, 12);
        ctx.bezierCurveTo(-11.5, 12, -12, 11.5, -12, 11);
        ctx.lineTo(-12, -11);
        ctx.closePath();

        ctx.fill();

        ctx.fillStyle = fillColor;

        ctx.beginPath();
        ctx.moveTo(-10, -9);
        ctx.bezierCurveTo(-10, -9.5, -9.5, -10, -9, -10);
        ctx.lineTo(9, -10);
        ctx.bezierCurveTo(9.5, -10, 10, -9.5, 10, -9);
        ctx.lineTo(10, 9);
        ctx.bezierCurveTo(10, 9.5, 9.5, 10, 9, 10);
        ctx.lineTo(-9, 10);
        ctx.bezierCurveTo(-9.5, 10, -10, 9.5, -10, 9);
        ctx.lineTo(-10, -9);
        ctx.closePath();

        ctx.fill();

        ctx.restore();
    },
});

Sensor.fromObject = (options: any, callback: Function) => {

    return callback(new Sensor(options));
};

// @ts-ignore
window.fabric.Sensor = Sensor;

export default Sensor;
