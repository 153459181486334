export const configurationConstants = {
    LIST_CONFIGURATION_SUCCESS: 'LIST_CONFIGURATION_SUCCESS',
    LIST_CONFIGURATION_FAILURE: 'LIST_CONFIGURATION_FAILURE',

    UPDATE_CONFIGURATION_SUCCESS: 'UPDATE_CONFIGURATION_SUCCESS',
    UPDATE_CONFIGURATION_FAILURE: 'UPDATE_CONFIGURATION_FAILURE',

    REMOVE_CONFIGURATION_SUCCESS: 'REMOVE_CONFIGURATION_SUCCESS',
    REMOVE_CONFIGURATION_FAILURE: 'REMOVE_CONFIGURATION_FAILURE',

    UPDATE_CONFIGURATION_PRODUCT_SUCCESS: 'UPDATE_CONFIGURATION_PRODUCT_SUCCESS',

};