import { IOptions } from "../core/interfaces";

/**
 * @type {Record<string, string>}
 */
export const operands: Record<string, string> = {
    '=': '=',
    '!=': '≠',
    '>': '>',
    '<': '<',
    '>=': '≥',
    '<=': '≤',
};

/**
 * @type {IOptions[]}
 */
export const operandsOption: IOptions[] = [
    { value: '=', label: '=' },
    { value: '!=', label: '≠' },
    { value: '>', label: '>' },
    { value: '<', label: '<' },
    { value: '>=', label: '≥' },
    { value: '<=', label: '≤' },
    { value: 'null', label: 'none' },
];