import React, { SyntheticEvent, useCallback, useState } from 'react';
import { EditMenu } from '../../../../../core/ui/components';
import clsx from 'clsx';

const stopPropagation = (event: SyntheticEvent) => {
    event.stopPropagation();
};

export const MenuOptions: React.FC<IProps> = (props: IProps) => {
    const { menuNode } = props;

    const [editMenuActive, setEditMenuActive] = useState(false);

    const onClose = useCallback(() => {
        setEditMenuActive(false);
    }, []);

    const onOpen = useCallback(() => {
        setEditMenuActive(true);
    }, []);

    return (
        <span
            className={clsx('wrap-edit-menu-node', { 'active': editMenuActive })}
            onClick={stopPropagation}
        >
            <EditMenu
                menuList={menuNode}
                dataItem={{ parent: null, current: null }}
                onClose={onClose}
                onOpen={onOpen}
            />
        </span>
    );
};

export type MenuNodeType = {
    title: string;
    action: (...props: any) => void;
    color?: string;
};

interface IProps {
    menuNode: MenuNodeType[];
}
