import { combineReducers } from 'redux';
import { auth } from './user/auth';
import { userList } from './user/userList';
import { userChange } from './user/userChange';
import { sensor } from './sensor/sensor';
import { editSensor } from './sensor/editSensor';
import { form } from './common/form';
import { mouse } from './common/mouse';
import { factory } from './factory/factory';
import { factoryChange } from './factory/factoryChange';
import { process } from './process/process';
import { processChange } from './process/processChange';
import { unit } from './unit/unit';
import { unitProducts } from './unit/unitProducts';
import { unitChange } from './unit/unitChange';
import { unitActiveProducts } from './unit/unitActiveProducts';
import { stateChange } from './state/statesChange';
import { statesList } from './state/statesList';
import { stateDetails } from './state/stateDetails';
import { stateSelection } from './state/stateSelection';
import { stateHovered } from './state/stateHovered';
import { dashboard } from '../../base/store/reducers/dashboard/dashboard';
import { dashboardSelect } from '../../base/store/reducers/dashboard/dashboardSelect';
import { graphRule } from '../../base/store/reducers/graph/graphRule';
import { graphHistogramHeight } from './graph/graphHistogramHeight';
import { graphMinimapVisibility } from './graph/graphMinimapVisibility';
import { graphMinimapBrush } from '../../base/store/reducers/graph/graphMinimapBrush';
import { graphPeriod } from '../../base/store/reducers/graph/graphPeriod';
import { graphHistogramMode } from './graph/graphHistogramMode';
import { graphBarHeight } from './graph/graphBarHeight';
import { graphBarDescriptionVisibility } from './graph/graphBarDescriptionVisibility';
import { graphStructuralTreeVisibility } from './graph/graphStructuralTreeVisibility';
import { graphSelection } from './graph/graphSelection';
import { graphHMIVisibility } from './graph/graphHMIVisibility';
import { graphBarTableVisibility } from '../../base/store/reducers/graph/graphBarTableVisibility';
import { product } from './products/product';
import { productTargetValue } from './products/productTargetValue';
import { productCategory } from './products/productCategory';
import { configurationProduct } from './configuration/configurationProduct';
import { editKpi } from './kpi/editKPI';
import { editAlert } from './alert/editAlert';
import { configurationTree } from './configuration/configurationTree';
import { alertDetails } from './alert/alertDetails';
import { causesList } from './causes/causesList';
import { appSetting } from './appSetting/appSetting';
import { monitoringTree } from './monitoringTree/monitoringTree';
import { monitoringTreeCollection } from './monitoringTree/monitoringTreeCollection';
import { stateOverride } from './state/stateOverride';
import { hmiState } from './hmi/hmiStateReducer';
import { hmiSchemaFormState } from './hmi/hmiSchemaFormReducer';
import { exportData } from './exportData/exportData';
import { notification } from './notification/notificationReducer';
import { productSortedByCategory } from './products/productSortedByCategory';
import { hmiPlayer } from './hmi/hmiPlayer';
import { responsiveDrawer } from './layout/responsiveDrawer';
import { hmiObjectFormState } from './hmi/hmiObjectFormReducer';
import { hmiHoverItemState } from './hmi/hmiHoverItemReducer';
import { graphDataLastValue } from './graph/graphDataLastValue';

import { modules } from '../../modules';
import { hmiDashboardDataState } from './hmi/hmiDashboardDataReducer';
import { graphDataReducer } from '../../base/store/reducers/dashboard/graphDataReducer';

const moduleReducers = modules.reduce((reducer, module) => {
    return { ...reducer, ...module.reducers() };
}, {});

/**
 * Combine reducers to manage global application state
 */
export const rootReducer = combineReducers({
    auth,
    userList,
    userChange,
    sensor,
    editSensor,
    form,
    mouse,
    factory,
    factoryChange,
    process,
    processChange,
    unit,
    unitChange,
    stateChange,
    statesList,
    stateDetails,
    stateSelection,
    dashboard,
    dashboardSelect,
    graphRule,
    graphHistogramHeight,
    graphMinimapVisibility,
    graphMinimapBrush,
    graphPeriod,
    graphHistogramMode,
    graphBarHeight,
    graphBarDescriptionVisibility,
    graphStructuralTreeVisibility,
    graphBarTableVisibility,
    graphHMIVisibility,
    product,
    productCategory,
    configurationProduct,
    editKpi,
    editAlert,
    configurationTree,
    graphSelection,
    alertDetails,
    unitProducts,
    unitActiveProducts,
    stateHovered,
    productTargetValue,
    causesList,
    appSetting,
    monitoringTree,
    monitoringTreeCollection,
    stateOverride,
    exportData,
    hmiState,
    hmiSchemaFormState,
    notification,
    productSortedByCategory,
    hmiPlayer,
    responsiveDrawer,
    hmiObjectFormState,
    hmiDashboardDataState,
    hmiHoverItemState,
    graphDataLastValue,
    graphDataReducer,
    ...moduleReducers,
});
