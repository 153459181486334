import { unitConstants } from '../../constants';
import { IUnitListAction, IUnitsState } from '../../interfaces';

/**
 * Unit reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function unit(state: IUnitsState = {
    units: [],
    errors: {},
}, action: IUnitListAction): IUnitsState {

    switch (action.type) {

        case unitConstants.LIST_SUCCESS:
            return {
                units: action.units,
                errors: {},
            };

        case unitConstants.LIST_FAILURE:

            return {
                units: [],
                errors: action.errors,
            };

        default:

            return state;
    }

}
