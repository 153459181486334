import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, IFilter, IJoin, IOrder, IHmiObject, IHmiSchema, ISensor } from '../interfaces';
import { hmiObjectsService, hmiSchemasService } from '../services';
import { AxiosResponse } from 'axios';
import { hmiObjectConstants } from '../constants';
import { hmiSchemaConstants } from '../constants';

/**
 * Unit related actions
 *
 * @type {Object}
 */
export const HmiObjectAction = {

    /**
     *  Get list States
     *
     * @param { String } search Search string
     * @param { Object } order Sort settings
     * @param { IJoin } join
     * @param { IFilter } filter
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'desc' }, join?: IJoin, filter?: IFilter) => {

        //Action creators
        const success = (hmiObjects: IHmiObject[]) => {

            return {
                type: hmiObjectConstants.LIST_HMI_OBJECT_SUCCESS,
                objects: hmiObjects,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: hmiObjectConstants.LIST_HMI_OBJECT_FAILURE,
                errors,
            };
        }, service = new hmiObjectsService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join, filter)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * store cause
     *
     *
     *  @return {Promise<Object>}
     * @param hmiObject
     */
    store: (hmiObject: IHmiObject) => {

        //Action creators
        const success = (hmiObjectResponse: IHmiObject) => {

            return {
                type: hmiObjectConstants.STORE_HMI_OBJECT_SUCCESS,
                hmiObject: hmiObjectResponse,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: hmiObjectConstants.STORE_HMI_OBJECT_FAILURE,
                errors,
            };

        }, storeLocalScheme = (hmiSchemes: IHmiSchema[]) => {

            return {
                type: hmiSchemaConstants.UPDATE_LOCAL_HMI_SCHEMA_SUCCESS,
                schemas: hmiSchemes,
            };

        }, storeOpenScheme = (hmiSchemes: IHmiSchema) => {

            return {
                type: hmiSchemaConstants.UPDATE_LOCAL_OPEN_HMI_SCHEMA_SUCCESS,
                schema: hmiSchemes,
            };

        }, service = new hmiObjectsService(), serviceScheme = new hmiSchemasService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.store(hmiObject)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(hmiObject));

                    const openScheme = serviceScheme.updateLocalOpenScheme(data, 'storeObject');
                    const allSchemas = serviceScheme.updateLocalScheme(data, 'storeObject');

                    dispatch(storeOpenScheme({ ...openScheme }));
                    dispatch(storeLocalScheme(allSchemas));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Updated cause
     *
     *
     *  @return {Promise<Object>}
     * @param hmiObject
     */
    update: (hmiObject: IHmiObject) => {

        //Action creators
        const success = (hmiObjectResponse: IHmiObject) => {

            return {
                type: hmiObjectConstants.UPDATE_HMI_OBJECT_SUCCESS,
                hmiObject: hmiObjectResponse,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: hmiObjectConstants.UPDATE_HMI_OBJECT_FAILURE,
                errors,
            };

        }, updateLocalScheme = (hmiSchemes: IHmiSchema[]) => {

            return {
                type: hmiSchemaConstants.UPDATE_LOCAL_HMI_SCHEMA_SUCCESS,
                schemas: hmiSchemes,
            };

        }, updateOpenScheme = (hmiSchemes: IHmiSchema) => {

            return {
                type: hmiSchemaConstants.UPDATE_LOCAL_OPEN_HMI_SCHEMA_SUCCESS,
                schema: hmiSchemes,
            };

        }, service = new hmiObjectsService(), serviceScheme = new hmiSchemasService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {



            service.update(hmiObject)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(hmiObject));

                    const allSchemas = serviceScheme.updateLocalScheme(data, 'updateObject'),
                        openScheme = serviceScheme.updateLocalOpenScheme(data, 'updateObject');

                    dispatch(updateLocalScheme(allSchemas));
                    dispatch(updateOpenScheme({ ...openScheme }));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Delete hmiObject by ID
     *
     *  @return {Promise<Object>}
     * @param hmiObject
     */
    delete: (hmiObject: IHmiObject) => {

        const deleteReason = (hmiObjectResponse: IHmiObject) => {

            return {
                type: hmiObjectConstants.DELETE_HMI_OBJECT_SUCCESS,
                hmiObject: hmiObjectResponse,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: hmiObjectConstants.DELETE_HMI_OBJECT_FAILURE,
                errors,
            };

        }, deleteLocalScheme = (hmiSchemes: IHmiSchema[]) => {

            return {
                type: hmiSchemaConstants.UPDATE_LOCAL_HMI_SCHEMA_SUCCESS,
                schemas: hmiSchemes,
            };

        }, deleteOpenScheme = (hmiSchemes: IHmiSchema) => {

            return {
                type: hmiSchemaConstants.UPDATE_LOCAL_OPEN_HMI_SCHEMA_SUCCESS,
                schema: hmiSchemes,
            };

        }, service = new hmiObjectsService(), serviceScheme = new hmiSchemasService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.remove(hmiObject)
                .then(({ data }: AxiosResponse) => {

                    dispatch(deleteReason(hmiObject));
                    // Temporarily disabled
                    // const allSchema  xs = serviceScheme.updateLocalScheme(hmiObject, 'deleteObject'),
                        const openScheme = serviceScheme.updateLocalOpenScheme(hmiObject, 'deleteObject');
                    // Temporarily disabled
                    // dispatch(deleteLocalScheme([...allSchemas]));
                    dispatch(deleteOpenScheme({ ...openScheme }));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Delete hmiObject by ID
     *
     *  @return {Promise<Object>}
     * @param hmiObject
     */
    clearErrorsInForm: () => {

        const success = () => {

            return {
                type: hmiObjectConstants.CLEAR_HMI_OBJECT_ERRORS,
            };

        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(success());
        };
    },


    /**
     * Hover HMI item on map
     *
     * @param {IHmiObject} item
     * 
     *  @return {Promise<Object>}
     */
    hoverItemOnMap: (item?: IHmiObject) => {

        const success = (hmiSchemaResponse?: IHmiObject) => {

            return {
                type: hmiObjectConstants.HOVER_HMI_OBJECT,
                item: hmiSchemaResponse,
            };

        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(success(item));
        };
    },

    /**
     * Hover HMI item on map
     *
     * @param {IHmiObject} item
     *
     *  @return {Promise<Object>}
     */
    hoverSensorOnTree: (item?: ISensor) => {

        const success = (sensorItem?: ISensor) => {

            return {
                type: hmiObjectConstants.HOVER_SENSOR_ON_TREE,
                sensor: sensorItem,
            };

        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(success(item));
        };
    },
};
