import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { DataExportService } from '../services';
import { exportDataConstants } from '../constants';
import { IErrors, IExportDataState } from '../interfaces';
import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

/**
 * File name clearing
 *
 * @param { string } contentDisposition
 */
const getFileNameByContentDisposition = (contentDisposition: string): string => {

    const regex = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/;
    const matches = regex.exec(contentDisposition);
    let filename = '';

    if (matches !== null && matches[3]) {

        filename = matches[3].replace(/['"]/g, '');

    }

    return decodeURI(filename.split(';')[0]);
};

/**
 * Export data actions
 *
 * @type {Object}
 */
export const ExportDataActions = {

    /**
     * Export state data
     *
     * @param from
     * @param to
     * @param controllerId
     * @param sensorId
     *
     * @param fileName
     * @return {Promise<Object>}
     */
    exportState: (from: string, to: string, controllerId: string, sensorId: string, fileName: string):
        (dispatch: ThunkDispatch<IExportDataState, void, AnyAction>) => void => {

        //Action creators
        const success = (): AnyAction => {

            return {
                type: exportDataConstants.EXPORT_DATA_STATE_SUCCESS,
            };

        }, failure = ({ errors }: IErrors): AnyAction => {

            return {
                type: exportDataConstants.EXPORT_DATA_STATE_FAILURE,
                errors,
            };

        }, service = new DataExportService();


        return (dispatch) => {

            service.exportState(from, to, controllerId, sensorId)
                .then((response: AxiosResponse) => {

                    const fileNameHeaderDescription = 'content-disposition';

                    const suggestedFileNameDescription = response.headers[fileNameHeaderDescription];

                    const effectiveFileName = (suggestedFileNameDescription === undefined
                        ? `${fileName}.xlsx`
                        : getFileNameByContentDisposition(suggestedFileNameDescription));

                    saveAs(response.data, effectiveFileName);

                    dispatch(success());

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },
    /**
     * Export state data
     *
     * @param from
     * @param to
     * @param fileName
     * @param dashboardId
     *
     * @return {Promise<Object>}
     */
    exportAllState: (from: string, to: string, fileName: string, dashboardId: number):
        (dispatch: ThunkDispatch<IExportDataState, void, AnyAction>) => void => {

        //Action creators
        const success = (): AnyAction => {

            return {
                type: exportDataConstants.EXPORT_ALL_DATA_STATE_SUCCESS,
            };

        }, failure = ({ errors }: IErrors): AnyAction => {

            return {
                type: exportDataConstants.EXPORT_ALL_DATA_STATE_FAILURE,
                errors,
            };

        }, service = new DataExportService();

        return (dispatch) => {

            service.exportAllState(from, to, dashboardId)
                .then((response: AxiosResponse) => {

                    const fileNameHeaderDescription = 'content-disposition';

                    const suggestedFileNameDescription = response.headers[fileNameHeaderDescription];

                    const effectiveFileName = (suggestedFileNameDescription === undefined
                        ? `${fileName}.xlsx`
                        : getFileNameByContentDisposition(suggestedFileNameDescription));

                    saveAs(response.data, effectiveFileName);

                    dispatch(success());

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    closeLoadingNotification: (): (dispatch: ThunkDispatch<IExportDataState, void, AnyAction>) => void => {

        //Action creators
        const success = (): AnyAction => {

            return {
                type: exportDataConstants.EXPORT_DATA_CLOSE_NOTIFICATION,
            };

        };

        return (dispatch) => {

            dispatch(success());

        };
    },

};
