import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ConfigurationFlatTreeService, UnitService } from '../services';
import { configurationConstants, formConstants, unitConstants } from '../constants';
import {
    IUnit,
    IErrors,
    IOrder,
    IData,
    IJoin,
    IFilter,
    IUnitsState,
    IUnitProductsState,
    IProduct,
} from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Unit related actions
 *
 * @type {Object}
 */
export const UnitActions = {

    /**
     * Get list of units
     *
     * @param { String } search Search string
     * @param { Object } order Sort settings
     * @param { IJoin } join
     * @param {IFilter} filter
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'asc' }, join?: IJoin, filter?: IFilter):
        (dispatch: ThunkDispatch<IUnitsState, void, AnyAction>) => void => {

        //Action creators
        const success = (units: IUnit[]) => {

            return {
                type: unitConstants.LIST_SUCCESS,
                units,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: unitConstants.LIST_FAILURE,
                errors,
            };
        }, service = new UnitService();


        return (dispatch) => {

            service.list(search, order, join, filter)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Get list of units
     *
     * @param { String } search Search string
     * @param { Object } order Sort settings
     * @param { IJoin } join
     * @param {IFilter} filter
     *
     * @return {Promise<Object>}
     */
    listUnitProduct: (search = '', order: IOrder = { column: 'id', dir: 'asc' }, join?: IJoin, filter?: IFilter):
        (dispatch: ThunkDispatch<IUnitProductsState, void, AnyAction>) => void => {

        //Action creators
        const success = (units: IUnit[]) => {

            return {
                type: unitConstants.LIST_PRODUCT_SUCCESS,
                units,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: unitConstants.LIST_PRODUCT_FAILURE,
                errors,
            };
        }, service = new UnitService();


        return (dispatch) => {

            service.list(search, order, join, filter)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Units bulk update
     *
     * @param {IData[]} units
     *
     * @return {Promise<Object>}
     */
    bulkUpdate: (units: IData[]):
        (dispatch: ThunkDispatch<IUnitsState, void, AnyAction>) => void => {

        //Action creators
        const success = () => {

            return {
                type: unitConstants.BULK_UPDATE_SUCCESS,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: unitConstants.BULK_UPDATE_FAILURE,
                errors,
            };

        }, service = new UnitService();

        return (dispatch) => {

            service.bulkUpdate(units)
                .then(() => {

                    dispatch(success());
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Units bulk update
     *
     * @param {IData} unit
     * @param type
     * @param product
     * @param refreshTree
     *
     * @return {Promise<Object>}
     */
    update: (unit: IUnit, type: string, product: IProduct | null, refreshTree = false):
        (dispatch: ThunkDispatch<IUnitsState, void, AnyAction>) => void => {

        //Action creators
        const success = () => {

            return {
                type: unitConstants.UPDATE_SUCCESS,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: unitConstants.UPDATE_FAILURE,
                errors,
            };

        }, updateConfigurationTree = (data: any[]) => {
            return {
                type: configurationConstants.UPDATE_CONFIGURATION_PRODUCT_SUCCESS,
                payload: {
                    items: data,
                },

            };
        }, service = new UnitService(), configurationService = new ConfigurationFlatTreeService();

        return (dispatch) => {

            service.update(unit)
                .then(() => {
                    if (refreshTree) {

                        dispatch(success());
                    }
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));

                })
                .finally(() => {

                    if (!refreshTree) {

                        /**
                         * After the update is completed, one element of the tree is updated
                         */
                        configurationService.getPartOfList('unit', unit.id).then(({ data }: AxiosResponse) => {

                            const updatedConfigurationTree = configurationService.updateLocalTree({
                                unit,
                                type,
                                product,
                            }, data, 'unit');

                            dispatch(updateConfigurationTree(updatedConfigurationTree));
                        });
                    }
                });
        };
    },

    /**
     * Clear Error in Unit
     *
     * @return {Promise<Object>}
     */
    clearErrorInUnitChange: ():
        (dispatch: ThunkDispatch<IUnitsState, void, AnyAction>) => void => {

        //Action creators
        const success = () => {

            return {
                type: formConstants.CLEAR_FAILURE,
            };

        };

        return (dispatch) => {
            dispatch(success());
        };
    },
};
