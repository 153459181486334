import { Action } from 'redux';
import { EditorMode, ILayer, ILocation } from '../../interfaces';

export enum LayerFormActionTypes {
    RESET_WITH_LAYER_MODEL = '[Layer Form] Reset with layer model',
    SAVE_LAYER_FORM_REQUEST = '[Layer Form] Save Layer Form Request',
    SAVE_LAYER_FORM_SUCCESS = '[Layer Form] Save Layer Form Success',
    SAVE_LAYER_FORM_FAILURE = '[Layer Form] Save Layer Form Failure',
    SET_EDITOR_MODE_ACTION = '[Layer Form] Set Editor Mode Action',
}

export class SaveLayerFormRequest implements Action {
    readonly type = LayerFormActionTypes.SAVE_LAYER_FORM_REQUEST;
}

export class SaveLayerFormSuccess implements Action {
    readonly type = LayerFormActionTypes.SAVE_LAYER_FORM_SUCCESS;

    constructor(public payload: {
        layer: ILayer
    }) {
    }
}

export class SaveLayerFormFailure implements Action {
    readonly type = LayerFormActionTypes.SAVE_LAYER_FORM_FAILURE;

    constructor(public payload: {
        error: any
    }) {
    }
}

export class ResetWithLayerModelAction implements Action {
    readonly type = LayerFormActionTypes.RESET_WITH_LAYER_MODEL;

    constructor(public payload: {
        location: ILocation | null,
        layer: ILayer | null,
    }) {
    }
}

export class SetEditorModeAction implements Action {
    readonly type = LayerFormActionTypes.SET_EDITOR_MODE_ACTION;

    constructor(public payload: {
        mode: EditorMode,
    }) {
    }
}

export type LayerFormActions =
    SaveLayerFormRequest |
    SaveLayerFormSuccess |
    SaveLayerFormFailure |
    ResetWithLayerModelAction |
    SetEditorModeAction;
