import { unitConstants } from '../../constants';
import { IUnitListAction, IUnitProductsState } from '../../interfaces';

/**
 * Unit reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function unitProducts(state: IUnitProductsState = {
    errors: {},
    unit: [],
}, action: IUnitListAction): IUnitProductsState {

    switch (action.type) {

        case unitConstants.LIST_PRODUCT_SUCCESS:

            return {
                unit: action.units,
                errors: {},
            };

        case unitConstants.LIST_PRODUCT_FAILURE:

            return {
                unit: [],
                errors: action.errors,
            };

        default:

            return state;
    }

}
