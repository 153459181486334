export function getHeightFromMousePosition(position: { y: number } | null, visible = true): { height: number, margin: number } {

    const { body: {  offsetTop } } = document,
    offsetHeight = window.innerHeight;
    // temporarily disabled
    // const structureTree = document.getElementsByClassName('structure-tree')[0];
    // const structureTreeTop: number | undefined = structureTree?.getBoundingClientRect().top || 0;
    const structureTreeTop: number = visible ? 163 : 108;

    if (!position) {
        return { height: (offsetHeight / 2), margin: offsetHeight / 2 };

    }

    const minHeight = 450;

    const newHeight = offsetHeight - (position.y - offsetTop);
    const newMargin = newHeight > minHeight ?
        position.y > (structureTreeTop) ?
            position.y : (structureTreeTop)
        : offsetHeight - minHeight;
    const sendHeight = newHeight > offsetHeight - (structureTreeTop) ?
        offsetHeight - (structureTreeTop) : newHeight;

    return {
        height: newHeight > minHeight ? sendHeight : minHeight,
        margin: newMargin,
    };
}
