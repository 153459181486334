/**
 * Application configuration
 *
 * @type {Object}
 */
interface IAppConfig {
    apiEndpoint: string;
    socketEndpoint: string;
    hrSocketEndpoint: string;
    hrApiEndpoint: string;
    hmiApiEndpoint: string;
    dashboardLeftOffsetLarge: number;
    correctionFactorForDrawingTheTimeline: number;
    dashboardLeftOffsetSmall: number;
    minimumYear: number;
    paddingForGraphsInPixels: number;
    language: {
        name: string;
        value: string;
    }[];
    valueRepeatTimeout: number;
    defaultMonitoringView: string;
    defaultHeightConfigTreeItem: number;
    enableHMI: boolean;
    stateCommentRequired: boolean;
    umanSensorSubString: string;
    notDownTimeSensorSubString: string;
    demoAccount: boolean;
    hideMinimap: boolean;
    hmiPlayerRealtimeTimeout: number;
    autoOpenHmiPlayer: boolean;
}

export const appConfig: IAppConfig = {
    apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'https://dev-api.qivisor.com',
    socketEndpoint: process.env.REACT_APP_SOCKET_ENDPOINT || 'https://dev-api.qivisor.com',
    hrApiEndpoint: process.env.REACT_APP_HR_API_ENDPOINT || 'https://dev-api-hr.qivisor.com',
    hmiApiEndpoint: process.env.REACT_APP_HMI_API_ENDPOINT || 'https://dev-api-hr.qivisor.com',
    hrSocketEndpoint: process.env.REACT_APP_HR_SOCKET_ENDPOINT || 'https://dev-api-hr.qivisor.com',
    dashboardLeftOffsetLarge: 320,
    dashboardLeftOffsetSmall: 76,
    minimumYear: 5,
    paddingForGraphsInPixels: 40,
    language: [
        {
            name: 'Ru',
            value: 'ru',
        },
        {
            name: 'En',
            value: 'en',
        },
        {
            name: 'Ua',
            value: 'ua',
        },
    ],
    valueRepeatTimeout: process.env.REACT_APP_HISTOGRAM_REPEAT_TIMEOUT ? parseInt(process.env.REACT_APP_HISTOGRAM_REPEAT_TIMEOUT) : 300000,
    defaultMonitoringView: process.env.REACT_APP_DEFAULT_MONITORING_VIEW || 'day',
    defaultHeightConfigTreeItem: 40,
    enableHMI: Boolean(process.env.ENABLE_HMI) || false,
    stateCommentRequired: Boolean(process.env.REACT_APP_STATE_COMMENT_REQUIRED) || false,
    umanSensorSubString: process.env.REACT_APP_UMAN_SENSOR_SUB_STRING || 'Сорт пива',
    notDownTimeSensorSubString: process.env.REACT_APP_NOT_DOWN_TIME_SENSOR_SUBSTRING || 'Сорт пива',
    correctionFactorForDrawingTheTimeline: 1,
    demoAccount: Boolean(process.env.REACT_APP_DEMO_ACCOUNT) || false,
    hideMinimap: Boolean(process.env.REACT_APP_HIDE_MINIMAP) || false,
    hmiPlayerRealtimeTimeout: parseInt(process.env.HMI_PLAYER_REALTIME_TIMEOUT as string) || 30000,
    autoOpenHmiPlayer: process.env.REACT_APP_OPEN_HMI === 'on',
};
