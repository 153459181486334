import React, { useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import LoadingOverlay from 'react-loading-overlay-ts';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { RootState } from '../../../store';

/**
 * Loading spinner component
 *
 * @return {JSX.Element}
 */
const LoadingSpinner: React.FC<any> = ({ loaded, children }) => {

    const [loadedData, setLoadedData] = useState(false);
    const { promiseInProgress } = usePromiseTracker({ delay: 0 });

    useEffect(()=>{

        if (loaded !== undefined) {

            loaded? setLoadedData(loaded): setTimeout(()=>setLoadedData(loaded), 100);
        }

    }, [loaded]);

    return (
        <LoadingOverlay
            active={promiseInProgress || loadedData}
            spinner={<ClipLoader color={'#ffffff'} loading={promiseInProgress || loadedData} />}
        >{
            children
        }</LoadingOverlay>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { loaded } = state.dashboard;

    return {
        loaded: loaded,
    };
};

export default connect(mapStateToProps, null)(LoadingSpinner);
