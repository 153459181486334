import { fabric } from 'fabric';
import { editorConstants } from '../../constants';
import { roundRect } from '../../../../../../core/helpers/roundRect';

const Indicator = fabric.util.createClass(fabric.Object, {

    type: editorConstants.objects.indicator,

    superType: 'drawing',

    initialize(options: any) {

        options.originX = 'center';
        options.originY = 'center';
        options.height = options.height || 18;
        options.width = options.width || 18 * 3;
        options.dataValue = options.dataValue || 'No data';
        options.objectCaching = false;
        options.fontSize = options.fontSize || 12;
        options.textPositionInObject = options.textPositionInObject || 'left';
        options.fillColor = '#ffffff';

        this.callSuper('initialize', options || {});
    },

    _render(ctx: CanvasRenderingContext2D) {

        const { fillColor = '#ffffff', strokeColor = '#74797d' } = this;

        ctx.fillStyle = fillColor;

        ctx.save();

        ctx.beginPath();

        ctx.font = `700 ${this.fontSize}px/${this.fontSize} IBM Plex Sans, arial`;

        let textPositionX = -(this.width) / 2,
            textPositionY = 1;

        ctx.textAlign = 'center';

        ctx.textBaseline = 'middle';

        if (this.textPositionInObject === 'top') {
            //top
            textPositionX = 0;
            textPositionY = -((this.height - this.fontSize) / 2);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'top';
        }
        if (this.textPositionInObject === 'bottom') {
            //bottom
            textPositionX = 0;
            textPositionY = (this.height - this.fontSize) / 2;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
        }
        if (this.textPositionInObject === 'topLeft') {
            //topLeft
            textPositionY = -((this.height - this.fontSize) / 2);
            ctx.textAlign = 'left';
            ctx.textBaseline = 'top';
        }
        if (this.textPositionInObject === 'topRight') {
            //topRight
            textPositionX = this.width / 2;
            textPositionY = -(this.height - this.fontSize) / 2;
            ctx.textAlign = 'right';
            ctx.textBaseline = 'top';
        }
        if (this.textPositionInObject === 'rightBottom') {
            //rightBottom
            textPositionX = this.width / 2;
            textPositionY = (this.height - this.fontSize) / 2;
            ctx.textAlign = 'right';
            ctx.textBaseline = 'bottom';
        }
        if (this.textPositionInObject === 'right') {
            //right
            textPositionX = this.width / 2;
            ctx.textAlign = 'right';
            ctx.textBaseline = 'middle';
        }
        if (this.textPositionInObject === 'left') {
            //left
            textPositionX = -this.width / 2;
            ctx.textAlign = 'left';
            ctx.textBaseline = 'middle';
        }
        if (this.textPositionInObject === 'bottomLeft') {
            //bottomLeft
            textPositionY = -((this.height - this.fontSize) / 2);
            textPositionY = (this.height - this.fontSize) / 2;
            ctx.textAlign = 'left';
            ctx.textBaseline = 'bottom';
        }

        ctx.fillStyle = this.type === 'indicator'?fillColor:'';
        roundRect(ctx, (-(this.width) / 2) - 3, (-(this.height / 2)), (this.width) + 6, this.height, 2, true, false);

        ctx.fill();

        ctx.lineWidth = 3;
        ctx.strokeStyle = strokeColor;

        ctx.shadowBlur = 0;

        roundRect(ctx, (-(this.width) / 2) - 4, (-(this.height / 2) -1), (this.width) + 8, this.height + 2, 2, false, true);
        ctx.stroke();

        ctx.shadowBlur = 0;

        const squarePath = new Path2D();
        squarePath.rect((-(this.width) / 2) - 2.5, (-(this.height / 2)) + 0.5, (this.width) + 5, this.height - 1);
        ctx.clip(squarePath);

        ctx.fillStyle = '#333333';
        ctx.fillText(this.dataValue, textPositionX, textPositionY);

        ctx.restore();
        this.callSuper('_render', ctx);
    },
});

Indicator.fromObject = (options: any, callback: Function) => {

    return callback(new Indicator(options));
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.fabric.Indicator = Indicator;

export default Indicator;
