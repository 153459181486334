import { graphConstants } from '../../constants';
import { IGraphMinimapVisibilityState } from '../../interfaces';


/**
 * Graph Minimap visibility reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphMinimapVisibility(state: IGraphMinimapVisibilityState = {
    visible: true,
}, action: {type: string}): IGraphMinimapVisibilityState {

    switch (action.type) {

        case graphConstants.minimapShow:

            return {
                visible: true,
            };

        case graphConstants.minimapHide:

            return {
                visible: false,
            };

        default:

            return state;
    }
}
