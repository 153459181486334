import React, { FC, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { ReactComponent as Config } from '../../core/ui/assets/images/icons/config.svg';
import { ReactComponent as Add } from '../../core/ui/assets/images/icons/add.svg';
import { FormDialog } from '../../core/ui/components';
import { DashboardForm } from '../';
import {
    DashboardActions,
    FormActions,
    HmiPlayerActions,
    HmiSchemaAction,
    MonitoringActions,
} from '../../core/actions';
import {
    IDashboard,
    IDashboardDuplicate,
    IData,
    ILeftNavItemProps,
    IUserTabBar,
} from '../../core/interfaces';
import { Scrollbar } from 'react-scrollbars-custom';

import { history } from '../../helpers';

import './Header.scss';
import DraggableItem from './DraggableItem';
import { GraphActions } from '../../base/store/actions';
import { selectCurrentUser, selectRBAC } from '../../core/selectors/auth/authSelector';
import { selectSelectedDashboard } from '../../core/selectors/dashboardSelect/selectedDashboardSelector';
import {
    selectDashboardOnline,
    selectDashboards,
    selectRefreshListDashboard,
} from '../../core/selectors/dashboard/dashboardSelector';
import {
    selectMonitoringTreeCollection,
    selectMonitoringTreeCollectionFirstLoad,
} from '../../core/selectors/monitoringTree/monitoringTreeCollectionSelector';
import { selectForm } from '../../core/selectors/form/formSelector';
import { selectConfigurationTree } from '../../core/selectors/configurationTree/configurationTreeCollectionSelector';
import { LayoutActions } from '../../core/actions/layout';
import { selectAppSettings } from '../../core/selectors/appSetting/appSettingSelector';
import { selectBrushSelection } from '../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import {
    convertDataToUrlFormat,
    generateSearchParam,
    getParamQuery, setGroupParamQuery,
    setUrl, URLFormat,
    useQuery,
} from '../../core/helpers/historyHalper';
import moment from 'moment';

/**
 * Default interface
 */

interface IMouseEnterTab {
    [key: number]: boolean;
}

/**
 * Base styles for the Components
 *
 * @type {StylesHook<Styles<Theme, {}, string>>}
 */
const useStyles = makeStyles(theme => ({
    itemIcon: {
        minWidth: 24,
        cursor: 'pointer',
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 'none',
        zIndex: 1,
    },
    popper: {
        zIndex: 1200,
    },
}));


/**
 * Functional component LeftNavItem
 *
 * @param {Object} props
 *
 * @return {JSX.Element}
 */
const LeftNavItem: FC<ILeftNavItemProps> = (
    {
        handlerChangeRoute,
    }: ILeftNavItemProps,
) => {

    const classes = useStyles(),
        [dashboardPages, setDashboardPages] = useState<IDashboard[]>([]),
        [mouseEnterTabs, setMouseEnterTabs] = useState<IMouseEnterTab>({}),
        [editable, setEditable] = useState<IData | null>(null),
        [onOffGraphsSwitch, setOnOffGraphsSwitch] = useState<number[]>([]);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { dialogOpened = false } = useSelector(selectForm),
        dashboards = useSelector(selectDashboards),
        selectedDashboard = useSelector(selectSelectedDashboard),
        user = useSelector(selectCurrentUser),
        rbac = useSelector(selectRBAC),
        refreshListDashboard = useSelector(selectRefreshListDashboard),
        trees = useSelector(selectMonitoringTreeCollection),
        treesFirstLoad = useSelector(selectMonitoringTreeCollectionFirstLoad),
        configurationTree = useSelector(selectConfigurationTree),
        appSetting = useSelector(selectAppSettings),
        selection = useSelector(selectBrushSelection),
        dashboardOnline = useSelector(selectDashboardOnline);
    const query = useQuery();


    /**
     * Getting data from the page session
     */
    const pageReloaded = JSON.parse(window.sessionStorage.getItem('dashboardOnline') || 'false');
    const offlineStatus = JSON.parse(window.sessionStorage.getItem('pageReloaded') || 'false');

    useEffect(() => {

        window.sessionStorage.setItem('dashboardOnline', JSON.stringify(dashboardOnline));

    }, [dashboardOnline]);


    /**
     * Show add dashboard page dialog
     */
    const addDashboardPage = useCallback((event) => {

        event.preventDefault();

        setEditable(null);

        dispatch(FormActions.toggleDialog(dialogOpened));

    }, [setEditable, dispatch, dialogOpened]);

    /**
     * Remove dashboard page
     *
     * @param {IData} data
     */
    const removeDashboardPage = (data: IData) => {

        const pages = Array.from(dashboardPages);

        const index = pages.findIndex(p => p.id === data.id);

        if (index !== -1) {

            pages.splice(index, 1);

            setDashboardPages([
                ...pages,
            ]);

        }

        setGroupParamQuery([{ name: 'dashboard', value: pages[index > 0 ? index - 1 : index].id.toString() }]);

        dispatch(DashboardActions.remove(data as IDashboard));
        dispatch(DashboardActions.select({ ...pages[index > 0 ? index - 1 : index] }));
    };

    /**
     * Duplicate dashboard page
     *
     * @param {IData} data
     */
    const duplicateDashboardPage = (data: IData) => {

        if (data && user.id) {

            const dDashboard: IDashboardDuplicate = {
                dashboard: data.id,
                newName: String(data.name),
                user: user.id,
            };

            dispatch(DashboardActions.duplicate(dDashboard));

            setTimeout(() => dispatch(DashboardActions.list('', {
                column: 'position',
                dir: 'asc',
            }, undefined, { field: [`userId||$eq||${user.id}`] }, dashboards.length > 0)), 200);

            setMouseEnterTabs({});

            dispatch(GraphActions.barToggleTableView(true, 0));
        }
    };

    /**
     * On selected dashboard graphs
     * Stored in the app
     *
     * @param {IData} data
     */
    const onDashboardGraphs = (data: IData) => {

        dispatch(GraphActions.barToggleTableView(true, 0));

        handlerChangeRoute('/');

        const index = onOffGraphsSwitch.findIndex(p => p === data.id);

        if (index !== -1) {

            setOnOffGraphsSwitch(onOffGraphsSwitch.slice(index, 1));

            setGroupParamQuery([
                { name: 'dashboard', value: data.id.toString() },
                { name: 'from', value: convertDataToUrlFormat(new Date(selection[0])) },
                { name: 'to', value: convertDataToUrlFormat(new Date(selection[1])) },
            ]);

            dispatch(DashboardActions.select({ ...data as IDashboard, graphs_switch: true }));

        }

        setMouseEnterTabs({});
    };

    /**
     * Off selected dashboard graphs
     * Stored in the app
     *
     * @param {IData} data
     *
     */
    const offDashboardGraphs = (data: IData) => {

        dispatch(GraphActions.barToggleTableView(true, 0));

        const index = onOffGraphsSwitch.findIndex(p => p === data.id);

        if (index === -1) {

            setOnOffGraphsSwitch([...onOffGraphsSwitch, data.id]);

        }

        setGroupParamQuery([
            { name: 'dashboard', value: data.id.toString() },
            { name: 'from', value: convertDataToUrlFormat(new Date(selection[0])) },
            { name: 'to', value: convertDataToUrlFormat(new Date(selection[1])) },
        ]);

        dispatch(DashboardActions.select({ ...data as IDashboard, graphs_switch: false }));

        setMouseEnterTabs({});

        // disabled as part of a debug issue of switching between tabs
        // history.push('/');

    };

    /**
     * Show edit dashboard dialog
     *
     * @param {IData} data
     */
    const renameDashboardPage = (data: IData) => {

        if (data) {

            setEditable({
                id: data.id,
                name: data.name,
            });

            dispatch(FormActions.toggleDialog(dialogOpened));
        }
    };

    /**
     * A dashboard page context menu
     *
     * @type {array}
     */
    const menu = [
        {
            title: t('RENAME'),
            action: renameDashboardPage,
        },
        {
            title: t('DUPLICATE'),
            action: duplicateDashboardPage,
        },
        {
            title: t('DELETE'),
            action: removeDashboardPage,
            color: 'red',
        },
        {
            title: t('TURN_OFF_ALL_GRAPHS'),
            action: offDashboardGraphs,
        },
        {
            title: t('TURN_ON_ALL_GRAPHS'),
            action: onDashboardGraphs,
        },
    ];

    /**
     * Update dashboard page array
     */
    useEffect(() => {

        if (dashboards && dashboards.length > 0) {

            const newDashboards: IDashboard[] = dashboards.map(value => {

                value['graphs_switch'] = onOffGraphsSwitch.indexOf(value.id) === -1;

                return value;
            });

            setDashboardPages(newDashboards);

            const [from, to] = selection;

            const dashboardId = parseInt(query.get('dashboard') || '');

            if (history.location.pathname === '/') {

                if (!selectedDashboard && !dashboardId) {

                    dispatch(DashboardActions.select({ ...newDashboards[0], graphs_switch: true }));
                    dispatch(GraphActions.periodSelect(appSetting.defaultMonitoringView));
                    setUrl('/', generateSearchParam({
                        dashboard: newDashboards[0].id.toString(),
                        from: decodeURIComponent(convertDataToUrlFormat(from)),
                        to: decodeURIComponent(convertDataToUrlFormat(to)),
                    }));


                } else {

                    if (dashboardId) {

                        const [from, to] = selection;
                        const dashboard = dashboards.find(value => value.id === dashboardId);
                        if (dashboard) {

                            dispatch(DashboardActions.select({ ...dashboard, graphs_switch: true }));
                            const fromURL = getParamQuery('from'),
                                toUrl = getParamQuery('to');

                            setUrl('/', generateSearchParam({
                                dashboard: dashboard.id.toString(),
                                from: decodeURIComponent(convertDataToUrlFormat(fromURL && !pageReloaded? moment(fromURL, URLFormat).toDate() : from)),
                                to: decodeURIComponent(convertDataToUrlFormat(toUrl && !pageReloaded ? moment(toUrl, URLFormat).toDate() : to)),
                            }));

                            const historyLocationState = history.location.state || {};

                            if ((!offlineStatus && pageReloaded) || 'prevLocation' in historyLocationState) {

                                const currentDate = new Date();

                                currentDate.setSeconds(0);
                                currentDate.setMilliseconds(0);
                                
                                if (fromURL && (new Date(toUrl ? moment(toUrl, URLFormat).toDate() : to).getTime() < currentDate.getTime())) {

                                    dispatch(DashboardActions.setOffCurrentTime());
                                    dispatch(DashboardActions.setOffline());

                                } else {
                                    dispatch(DashboardActions.setOnline());
                                }
                            }

                        }
                    }

                }

                // window.sessionStorage.removeItem('pageReloaded');

            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboards, dispatch, setDashboardPages, pageReloaded]);

    useEffect(() => {

        if (
            treesFirstLoad &&
            (
                dashboards.length !== Object.keys(trees).length
                || history.location.pathname !== '/configuration'
            )
        ) {
            getMonitoringTreeAll(dashboards, selectedDashboard);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dashboards, selectedDashboard, configurationTree, treesFirstLoad]);


    /**
     * Re-order dashboard pages after drag
     *
     * @param {DropResult} result
     */
    const onDragEnd = useCallback((result: DropResult) => {

        if (!result.destination) {

            return;
        }

        const pages = reorder(
            dashboardPages,
            result.source.index,
            result.destination.index,
        );

        setDashboardPages(pages);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardPages, setDashboardPages]);

    /**
     * Do actual re-order
     *
     * @param {IDashboard[]} list
     * @param {number} startIndex
     * @param {number} endIndex
     *
     * @return array
     */
    const reorder = (list: IDashboard[], startIndex: number, endIndex: number) => {

        const result = Array.from(list);

        const [removed] = result.splice(startIndex, 1);

        const draggedEl: IUserTabBar = {
            id: removed.id,
            comment: removed.comment ? removed.comment : '',
            name: removed.name,
            position: endIndex,
            user: user.id,
        };

        dispatch(DashboardActions.dragged(draggedEl));

        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * A dashboard tab mouse enter handler
     *
     * @param {IDashboard} page
     */
    const mouseEnterHandler = useCallback((page: IDashboard) => {

        const tab: IMouseEnterTab = {};

        tab[page.id] = true;

        setMouseEnterTabs(tab);

    }, []);

    /**
     * A dashboard tab mouse enter handler
     *
     * @param {IDashboard} page
     */
    const touchEnterHandler = useCallback((page: IDashboard) => {

        const tab: IMouseEnterTab = {};

        tab[page.id] = true;

        setMouseEnterTabs(tab);
    }, []);

    /**
     * A dashboard tab mouse leave handler
     *
     */
    const mouseLeaveHandler = useCallback(() => {

        setMouseEnterTabs({});

    }, [setMouseEnterTabs]);

    /**
     * Get monitoring tree all
     * 
     * @param {IDashboard[]} dashboards 
     * @param {IDashboard | undefined} page 
     * @returns {void}
     */
    const getMonitoringTreeAll = (dashboards: IDashboard[], page: IDashboard | undefined): void => {
        const ids = dashboards.map((dashboard: IDashboard) => dashboard.id);

        const selectedId = page?.id;

        const sortedIds = ids.sort((x, y) => x === selectedId ? -1 : y === selectedId ? 1 : 0);

        dispatch(MonitoringActions.listAll(sortedIds, selectedId));
    };

    /**
     * Select a dashboard page by mouse click
     *
     * @param {IDashboard} page
     */
    const selectPage = useCallback((page: IDashboard) => {

        page['graphs_switch'] = onOffGraphsSwitch.indexOf(page.id) === -1;

        dispatch(DashboardActions.select(page));

        dispatch(FormActions.toggle(false));

        dispatch(GraphActions.barToggleTableView(true, 0));

        const [from, to] = selection;

        // get monitoring tree when you come from configuration page to a dashboard page
        if (history.location.pathname === '/configuration') {
            getMonitoringTreeAll(dashboards, page);
        }

        setUrl('/', generateSearchParam({
            dashboard: page.id.toString(),
            from: decodeURIComponent(convertDataToUrlFormat(from)),
            to: decodeURIComponent(convertDataToUrlFormat(to)),
            prevLocation: window.location.pathname,
        }));

    }, [dispatch, onOffGraphsSwitch, selection, dashboards]);

    /**
     * load Dashboards
     */
    useEffect(() => {

        dispatch(DashboardActions.list('', {
            column: 'position',
            dir: 'asc',
        }, undefined, { field: [`userId||$eq||${user.id}`] }, dashboards.length > 0));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, refreshListDashboard]);

    /**
     * hide tooltip and turn off menu dotted if the focus out
     */
    useEffect(() => {

        return () => {

            if (dialogOpened) {
                setMouseEnterTabs({});
            }
        };
    }, [dialogOpened]);

    /**
     * Go to the configuration page
     *
     * @type {() => void}
     */
    const handlerChangeRouteCallback = useCallback((event) => {

            event.preventDefault();

            if (appSetting.hmi.isEnabled) {

                dispatch(GraphActions.toggleHMI(true));
                dispatch(HmiPlayerActions.setSchema(null));
                dispatch(LayoutActions.drawerPosition('right'));
                dispatch(HmiSchemaAction.editSchema(null,  true));
                dispatch(HmiPlayerActions.setRealTime(false));
            }

            handlerChangeRoute('/configuration');

        window.sessionStorage.setItem('dashboardOnline', JSON.stringify(true));

        }, [handlerChangeRoute, appSetting, dispatch],
    );

    const listItemDashboardCreateStyle = { borderLeft: '1px solid #e6eaed' },
        scrollbarStyle = { maxHeight: 40 };

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="configuration-pages" direction="horizontal">
                    {(provided) => (
                        <List
                            className="right-navigation"
                            disablePadding
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <ListItem
                                id="configurationButton"
                                component={Link}
                                to="/configuration"
                                selected={history.location.pathname === '/configuration'}
                                onClick={handlerChangeRouteCallback}
                            >
                                <ListItemIcon
                                    classes={{
                                        root: classes.itemIcon,
                                    }}
                                >
                                    <Config className="configuration-svg" />
                                </ListItemIcon>
                                <ListItemText primary={t('CONFIGURATION')} />
                            </ListItem>
                            {dashboardPages.length > 0 ?
                                <Scrollbar
                                    noScrollY
                                    translateContentSizesToHolder
                                    style={scrollbarStyle}
                                >
                                    {dashboardPages.map((page, key) => (
                                        <DraggableItem
                                            key={page.id || key}
                                            index={key}
                                            page={page}
                                            classes={classes}
                                            mouseEnterTabs={mouseEnterTabs}
                                            selectedDashboard={selectedDashboard}
                                            mouseEnterHandler={mouseEnterHandler}
                                            mouseLeaveHandler={mouseLeaveHandler}
                                            touchEnterHandler={touchEnterHandler}
                                            selectPage={selectPage}
                                            rbac={rbac}
                                            menu={menu}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </Scrollbar>
                                : null}

                            {rbac.can('dashboard:create') ?
                                <ListItem
                                    onClick={addDashboardPage}
                                    style={listItemDashboardCreateStyle}
                                >
                                    <ListItemIcon
                                        classes={{
                                            root: classes.itemIcon,
                                        }}
                                    >
                                        <Add className={'add-panel'} />
                                    </ListItemIcon>
                                </ListItem>
                                : null
                            }

                        </List>
                    )}
                </Droppable>
            </DragDropContext>

            <FormDialog
                open={dialogOpened}
                heading={editable ? t('RENAME_DASHBOARD') : t('CREATE_NEW_DASHBOARD')}
            >
                <DashboardForm model={editable} />
            </FormDialog>
        </React.Fragment>
    );
};

export default React.memo(LeftNavItem);