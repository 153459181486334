import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectEmployeeState = createSelector(
    selectHrModuleState,
    state => state.employeeState,
);

export const selectEmployees = createSelector(
    selectEmployeeState,
    state => state.employees,
);

export const selectEmployee = createSelector(
    selectEmployeeState,
    state => state.employee,
);

export const selectEmployeesNoCategory = createSelector(
    selectEmployees,
    employees => employees,
);
