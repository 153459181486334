import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles/CommonError.scss';

/**
 * Component to show an error from the server that are not related to any input
 *
 * @param {object} errors
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const CommonError: React.FC<ICommonErrorProps> = ({ errors }: ICommonErrorProps) => {

    const { t } = useTranslation();

    return errors && errors.common ? <div className="common-error">{t(errors.common)}</div> : null;
};

interface ICommonErrorProps {
    errors?: {
        common?: string;
    };
}

export default CommonError;
