import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { kpiConstants } from '../constants';

/**
 * Process related actions
 *
 * @type {Object}
 */
export const EditKpiAction = {

    /**
     * Get list of processes
     * @param unit
     *
     * @return {Promise.<Object>}
     */
    store: (unit: any | null) => {

        const success = (unit: any) => {

            return {
                    type: kpiConstants.STORE_KPI_SUCCESS,
                    kpi: unit.kpi,
                    unit: unit.unit,
                };

            },
        clear=()=>{
            return {
                type: kpiConstants.CLEAR_KPI_SUCCESS,
            };
        };


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(unit?success(unit): clear());
        };
    },

};
