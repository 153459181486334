import React, { useCallback } from 'react';
import { IProduct } from '../../../../core/interfaces';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as ProductViewIcon } from '../../../../core/ui/assets/images/icons/product.svg';

interface IMenuItemProps {
    product: IProduct;
    selectProduct: (product:IProduct)=>void;
    resetAnchorEl: (event:HTMLButtonElement | null) => void;
    chooseHandler: (id: number) => void;
}
const MenuItemProduct: React.FC<IMenuItemProps> = ({ product, selectProduct, resetAnchorEl, chooseHandler }:IMenuItemProps) => {

    /**
     * Click handler
     *
     * @type {(event) => void}
     */
    const clickHandler = useCallback((event)=>{

        selectProduct(product);

        resetAnchorEl(null);

        chooseHandler(event.currentTarget.value);

    }, [selectProduct, product, resetAnchorEl, chooseHandler]);

    return (
        <MenuItem
            key={product.id}
            value={product.id}
            onClick={clickHandler}
        >
            <ProductViewIcon className="default-icon-size m8" />
            <p style={{ margin: 0 }}>{product.name}</p>
        </MenuItem>
    );
};

export default MenuItemProduct;
