/**
 * Sort function by order
 *
 * @param {ISensor[]} value
 *
 * @return {ISensor[]}
 */
import { ISensor } from '../core/interfaces';

export const sortOrder = (value: ISensor[]): ISensor[] => {

    return value.sort((a, b) => {

        if (a.position === undefined) {

            a.position = 0;
        }

        if (b.position === undefined) {

            b.position = 0;
        }

        return a.position < b.position ? -1 : 1;
    });

};