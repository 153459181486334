import React, { useCallback } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import { FormControlLabel, IconButton, ListSubheader, Menu, MenuItem, RadioGroup } from '@material-ui/core';
import CheckBox from '@material-ui/core/Checkbox';
import './styles/PanelMenu.scss';
import { ExportDataActions, FormActions, HmiPlayerActions } from '../../../actions';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import { ReactComponent as Linear } from '../../assets/images/icons/linear-off.svg';
import { ReactComponent as LinearActive } from '../../assets/images/icons/linear-on.svg';
import { ReactComponent as Logarithmic } from '../../assets/images/icons/logarithmic-off.svg';
import { ReactComponent as LogarithmicActive } from '../../assets/images/icons/logarithmic-on.svg';
import { ReactComponent as Cubism } from '../../assets/images/icons/cubism-off.svg';
import { ReactComponent as CubismActive } from '../../assets/images/icons/cubism-on.svg';
import { ReactComponent as Export } from '../../assets/images/icons/export.svg';
import { graphConstants } from '../../../constants';
import {
    selectGraphBarDescriptionVisibility,
} from '../../../selectors/graphBarDescriptionVisibility/graphBarDescriptionVisibilitySelector';
import { selectDashboardOnline } from '../../../selectors/dashboard/dashboardSelector';
import { selectHistogramMode } from '../../../selectors/graphHistogramMode/graphHistogramModeSelector';
import { selectMinimapVisibility } from '../../../selectors/graphMinimapVisibility/graphMinimapVisibilitySelector';
import moment from 'moment';
import {
    selectBrushSelection,
} from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectSelectedDashboard } from '../../../selectors/dashboardSelect/selectedDashboardSelector';
import { GraphActions } from '../../../../base/store/actions';


const PanelMenu: React.FC = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const barDescriptionVisible = useSelector(selectGraphBarDescriptionVisibility),
        minimapVisible = useSelector(selectMinimapVisibility),
        histogramMode = useSelector(selectHistogramMode) || graphConstants.histogramModeLinear,
        dashboardOnline = useSelector(selectDashboardOnline),
        selection = useSelector(selectBrushSelection),
        selectedDashboard = useSelector(selectSelectedDashboard);


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null),
        open = Boolean(anchorEl),
        handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {

            event.stopPropagation();

            setAnchorEl(event.currentTarget);
        }, []),
        handleClose = useCallback(() => {

            setAnchorEl(null);
        }, []);

    /**
     * Handler for loading a report for all states
     */
    const exportData = useCallback((event) => {

        event.preventDefault();

        event.stopPropagation();

        if (selection && selectedDashboard) {

            const { id } = selectedDashboard;

            dispatch(ExportDataActions.exportAllState(moment(selection[0]).format(), moment(selection[1]).format(), `${moment().format('DD.MM.YY  HH-mm')} sensors report`, id));

        }

    }, [
        selection,
        dispatch,
        selectedDashboard,
    ]);


    /**
     * Stop Propagation handler
     */
    const stopPropagationOnClick = useCallback((event) => {

        dispatch(FormActions.toggle(false));
        isMobile && dispatch(HmiPlayerActions.setRealTime(false));

        event.stopPropagation();

    }, [dispatch]);

    /**
     * Handle bar chart description switch
     */
    const barDescriptionSwitch = useCallback(() => {

        dispatch(GraphActions.barToggleDescription(barDescriptionVisible));

    }, [
        dispatch,
        barDescriptionVisible,
    ]);

    /**
     * Handle Minimap visibility switch
     */
    const minimapSwitch = useCallback(() => {

        dispatch(GraphActions.minimapToggle(minimapVisible));

    }, [
        dispatch,
        minimapVisible,
    ]);

    /**
     * Handler for switching histogram view
     */
    const histogramSwitchModeCallback = useCallback((event)=>{

        event.stopPropagation();

        dispatch(GraphActions.histogramSwitchMode(event.target.checked? event.target.value : 'default'));

    }, [
        dispatch,
    ]);


    return (
        <div className="panel-menu action-menu">
            <IconButton
                aria-controls="panel-menu"
                aria-haspopup="true"
                onClick={handleClick}
                disableRipple
            >
                <MoreVertIcon  />
            </IconButton>
            {anchorEl ?
                <Menu
                    id="panel-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    onMouseLeave={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    className={isMobileOnly?'panel-menu-mobile':''}
                >
                    {isMobileOnly ?
                        <IconButton
                            aria-controls="panel-menu"
                            aria-haspopup="true"
                            onClick={handleClose}
                            className="close-button"
                        >
                            <CloseIcon />
                        </IconButton>
                        : null}
                    <ListSubheader
                        className={'subheader'}
                        disableSticky
                    >
                        {t('GRAPH_SETTING')}
                    </ListSubheader>
                    <MenuItem className="desc-item" disableRipple>
                        <FormControlLabel
                            className="field-checkbox"
                            onClick={stopPropagationOnClick}
                            control={
                                <CheckBox
                                    value="desc"
                                    name="showDesc"
                                    checked={barDescriptionVisible}
                                    onChange={barDescriptionSwitch}
                                    color="primary"
                                    icon={<span className="checkbox-icon" />}
                                    disableFocusRipple
                                    disableRipple
                                />
                            }
                            label={t('SHOW_DESCRIPTIONS')}
                        />
                    </MenuItem>
                    <MenuItem className="minimap-item" disableRipple>
                        <FormControlLabel
                            className="field-checkbox"
                            onClick={stopPropagationOnClick}
                            control={
                                <CheckBox
                                    value="minimap"
                                    name="showMinimap"
                                    checked={minimapVisible}
                                    onChange={minimapSwitch}
                                    color="primary"
                                    icon={<span className="checkbox-icon" />}
                                    disableFocusRipple
                                    disableRipple
                                />
                            }
                            label={t('SHOW_MINIMAP')}
                        />
                    </MenuItem>
                    <hr />
                    <ListSubheader
                        className={'subheader'}
                        disableSticky
                    >
                        {t('GRAPH_VIEW')}
                    </ListSubheader>
                    <MenuItem className="histogram-type-item" disableRipple>
                            <FormControl onClick={stopPropagationOnClick} >
                                <RadioGroup defaultValue="linear" aria-label="histogramType" name="histogramType">
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                value="linear"
                                                name="histogramType"
                                                checked={histogramMode === graphConstants.histogramModeLinear}
                                                onChange={histogramSwitchModeCallback}
                                                color="default"
                                                icon={<Linear />}
                                                checkedIcon={<LinearActive />}
                                                className="histogram-type"
                                                disableFocusRipple
                                                disableRipple
                                            />
                                        }
                                        label={t('LINER')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                value="logarithmic"
                                                name="histogramType"
                                                checked={histogramMode === graphConstants.histogramModeLogarithmic}
                                                onChange={histogramSwitchModeCallback}
                                                color="default"
                                                icon={<Logarithmic />}
                                                checkedIcon={<LogarithmicActive />}
                                                className="histogram-type"
                                                disableFocusRipple
                                                disableRipple
                                            />
                                        }
                                        label={t('LOGARITHMIC')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                value="overlapping"
                                                name="histogramType"
                                                checked={histogramMode === graphConstants.histogramModeOverlapping}
                                                onChange={histogramSwitchModeCallback}
                                                color="default"
                                                icon={<Cubism />}
                                                checkedIcon={<CubismActive />}
                                                className="histogram-type"
                                                disableFocusRipple
                                                disableRipple
                                            />
                                        }
                                        label={t('OVERLAPPING')}
                                    />
                                </RadioGroup>
                            </FormControl>
                    </MenuItem>
                    <hr />
                    <ListSubheader
                        className={'subheader'}
                        disableSticky
                    >
                        {t('REPORT')}
                    </ListSubheader>
                    <MenuItem
                        className="export-item"
                        onClick={exportData}
                        disableRipple
                    >
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            className="export-item-icon"
                            disableFocusRipple
                            disableRipple
                        >
                            <Export />
                        </IconButton>
                        {t('EXPORT_EXCEL')}
                    </MenuItem>
                    <hr />
                    <ListSubheader
                        className={'subheader'}
                        disableSticky
                    >
                        {t('DATA_STATUS')}
                    </ListSubheader>
                    <MenuItem className="minimap-item">
                        {dashboardOnline ?
                            <label className="dashboard-mode online"><i /><span>{t('REALTIME')}</span></label>
                            :
                            <label className="dashboard-mode offline"><i /><span>{t('OFFLINE')}</span></label>
                        }
                    </MenuItem>
                </Menu>
            : null}
        </div>
    );
};

export default PanelMenu;