import {
    IChartAlert,
    IChartData,
    IHistogramData,
    IHmiObject,
    INotification,
    ISensor,
    IStateItem,
} from '../../core/interfaces';
import { useParamSelector } from '../useParamSelector';
import {
    selectSensorHrDataBySensorId,
} from '../../core/selectors/HistogramChartHr/sensorDataHrSelector';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAllNotificationMapping,
} from '../../core/selectors/notification/notificationSelector';
import { selectHmiPlayerSchema } from '../../core/selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../core/selectors/hmi/visibilitySelector';
import { selectPositionDrawer } from '../../core/selectors/layout/responsiveDrawerSelector';
import { HmiPlayerActions } from '../../core/actions';
import { selectBrushSelection } from '../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';

import {
    chartBrashRole,
    selectGraphDataById,
} from '../../core/selectors/graphData/graphDataSelector';
import { cancelable, CancelablePromise } from 'cancelable-promise';
import { selectMinAlertInterval } from './../../helpers';
import { usePrevious } from '../usePrevious';

export interface IChartDataWithColor extends IChartData{
    color: string;
    originColor: string;
    alert: IChartAlert | INotification | null;
}

export interface IHmiObjectWithData extends IHmiObject {
    dataHistogram : IChartDataWithColor[] |  IStateItem[] | undefined;
    sensor: ISensor;
}



export const useDataHistogram = (sensor: ISensor, hrMode: boolean, mixMode: boolean, color: string):[IChartDataWithColor[], IChartAlert[], boolean] => {

    const [dataWithAlertColor, setDataWithAlertColor] = useState<IChartDataWithColor[]>([]);
    const [poolingGapsData, setPoolingGapsData] = useState<IHistogramData | null>(null);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const dispatch = useDispatch();
    const colorDataRef = useRef<CancelablePromise<unknown>>();

    const data: IHistogramData = useParamSelector(selectGraphDataById, sensor.id),
        range = useSelector(chartBrashRole),
        dataHr: IHistogramData = useParamSelector(selectSensorHrDataBySensorId, sensor.id),
        notifications = useSelector(selectAllNotificationMapping),
        prevNotifications = usePrevious(notifications),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        selection: Date[] | undefined = useSelector(selectBrushSelection),
        alertData: IChartAlert[] = !hrMode ? data?.alerts || [] : [];

    const schema = useSelector(selectHmiPlayerSchema);
    const isVisible = useSelector(selectHmiPlayerVisibility) && schema !== null;

    useEffect(() => {

        if (!hrMode ? data?.values : dataHr?.values) {

            const values = !hrMode ? data : dataHr;

            if (data?.showLoader !== undefined) {

                setShowLoader(data?.showLoader);
            }

            if (JSON.stringify(values) !== JSON.stringify(poolingGapsData)) {

                setPoolingGapsData(values);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dataHr, setPoolingGapsData, anchor, range, isVisible]);
// }, [data, dataHr, setPoolingGapsData, isVisible, anchor, dataNarrow, range]);

    const selectedTimeInterval = selectMinAlertInterval();

    const updateData = async(chartData: IChartDataWithColor[]) => {

        const [from, to] = selection;

        const fromTime = new Date(from).getTime();

        const toTime = new Date(to).getTime();

        const timeDiff = toTime - fromTime;

        const selectionLongInterval = timeDiff > selectedTimeInterval;

        return  await new Promise((resolve) => {

            const newChartData = chartData.reduce((dataArr: IChartDataWithColor[], data) => {
                if (new Date(data.timestamp).getTime() > new Date(from).getTime() &&
                    new Date(data.timestamp).getTime() < new Date(to).getTime()) {

                    let defaultColor = color;
                    if (alertData) {
                        data.alert = null;
                        for (const value of alertData) {
                            const notificationEndTime = value.endTime ? new Date(value.endTime) : new Date();
                            if (new Date(data.timestamp).getTime() >= new Date(value.startTime).getTime() &&
                                new Date(data.timestamp).getTime() <= new Date(notificationEndTime).getTime() &&
                                !mixMode) {

                                if (!selectionLongInterval) {
                                    defaultColor = '#e30c0c';
                                }

                                data.alert = value;
                            }


                            if (new Date(data.timestamp).getTime() >= new Date(value.startTime).getTime() &&
                                new Date(data.timestamp).getTime() <= new Date(notificationEndTime).getTime()) {

                                const currentNotification = notifications.get(value.notificationId);

                                if (currentNotification) {

                                    const isNew = currentNotification ? currentNotification.isNew : value.isNew;
                                    const comment = currentNotification ? currentNotification.comment : value.comment;
                                    data.alert = {
                                        ...data.alert,
                                        comment: comment || '',
                                        um: value.um,
                                        isNew: isNew,
                                        value: value.value,
                                        id: value.notificationId,
                                    } as INotification;
                                }
                            }

                        }
                    }
                    data.color = defaultColor;
                    data.originColor = defaultColor;

                    dataArr.push(data);
                }
                return dataArr;

            }, []);

            resolve(newChartData);
        });
    };

    useEffect(() => {

        if (poolingGapsData?.values && selection) {

            if (colorDataRef.current?.cancel) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                colorDataRef.current!.cancel();
            }

            colorDataRef.current =  cancelable(updateData(poolingGapsData.values as IChartDataWithColor[]));

            colorDataRef.current.then(value => {

                if (dataWithAlertColor !== value || prevNotifications !== notifications) {

                    setDataWithAlertColor(value as unknown as IChartDataWithColor[]);

                }
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poolingGapsData, color, selection, notifications, prevNotifications]);

    useEffect(() => {
        if (schema) {
            const hmiObjectBySensor = schema?.hmiObjects && schema?.hmiObjects.find(value => value.sensorId === sensor.id);

            if (hmiObjectBySensor) {
                const data: IHmiObjectWithData = { ...hmiObjectBySensor, dataHistogram: dataWithAlertColor, sensor: sensor };

                dispatch(HmiPlayerActions.setHMIObjectWIthData(data));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataWithAlertColor, dispatch, sensor, schema]);


    return [
        dataWithAlertColor,
        alertData,
        showLoader,
    ];
};