import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AppSettingConstant } from '../constants';
import { AppSetting } from '../services';
import { IAppSettingState, IError } from '../interfaces';
import { AxiosResponse } from 'axios';


/**
 * Process related actions
 *
 * @type {Object}
 */
export const AppAction = {

    /**
     * Show/hide state details popover
     **
     * @return {Promise<Object>}
     */
    getAppSetting: () => {

        const success = (settings: Record<string, unknown>) => {

            return {
                type: AppSettingConstant.GET_APP_SETTING_SUCCESS,
                settings: settings,
            };

        }, failure = (errors: IError) => {

            return {
                type: AppSettingConstant.GET_APP_SETTING_FAILURE,
                errors: errors,
            };
        }, service= new AppSetting();

        return (dispatch: Dispatch<IAppSettingState, void, AnyAction>) => {

            service.appSetting()
                .then(({ data }: AxiosResponse) => {

                dispatch(success(data));

            }).catch(reason => {

                dispatch(failure(reason));
            });

        };
    },

    languageSwitch: (language: string)=> {

        const switchL = (language: string) =>{

            return {
                type: AppSettingConstant.LANGUAGE_SWITCH,
                language,
            };
        };

        return (dispatch: Dispatch<IAppSettingState, void, AnyAction>) => {

            dispatch(switchL(language));
        };
    },

};
