import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectEditorZoneState = createSelector(
    selectHrModuleState,
    state => state.editorZoneState,
);

export const selectEditorZones = createSelector(
    selectEditorZoneState,
    state => state.zones,
);
