import moment from 'moment';

export const graphConstants = {

    //graph params
    histogramSmallHeight: 40,
    histogramNormalHeight: 110,
    histogramModeLinear: 'linear',
    histogramModeLogarithmic: 'logarithmic',
    histogramModeOverlapping: 'overlapping',
    histogramModeDisable: 'default',

    barSmallHeight: 40,
    barNormalHeight: 110,

    //graph actions
    peakMouseEnter: 'GRAPH_PEAK_MOUSE_ENTER',
    peakMouseLeave: 'GRAPH_PEAK_MOUSE_LEAVE',

    peakEnterEmptyLine: 'GRAPH_PEAK_MOUSE_EMPTY_ENTER',

    peakTouchAction: 'GRAPH_PEAK_TOUCH_ACTION',

    histogramSwitchNormal: 'GRAPH_HISTOGRAM_SWITCH_NORMAL',
    histogramSwitchSmall: 'GRAPH_HISTOGRAM_SWITCH_SMALL',
    histogramSwitchLinear: 'GRAPH_HISTOGRAM_SWITCH_LINEAR',
    histogramSwitchLogarithmic: 'GRAPH_HISTOGRAM_SWITCH_LOGARITHMIC',
    histogramSwitchOverlapping: 'GRAPH_HISTOGRAM_SWITCH_OVERLAPPING',

    barSwitchNormal: 'GRAPH_BAR_SWITCH_NORMAL',
    barSwitchSmall: 'GRAPH_BAR_SWITCH_SMALL',
    barDescriptionShow: 'GRAPH_BAR_DESCRIPTION_SHOW',
    barDescriptionHide: 'GRAPH_BAR_DESCRIPTION_HIDE',
    barTableViewShow: 'GRAPH_BAR_TABLE_VIEW_SHOW',
    barTableViewHide: 'GRAPH_BAR_TABLE_VIEW_HIDE',

    minimapShow: 'GRAPH_MINIMAP_SHOW',
    minimapHide: 'GRAPH_MINIMAP_HIDE',
    minimapBrush: 'GRAPH_MINIMAP_BRUSH',
    minimapBrushTimerOnOff: 'GRAPH_MINIMAP_TIMER_ON_OFF',

    minimapSelectPeriod: 'MINIMAP_PERIOD',
    minimapRangeSelect: 'MINIMAP_RANGE',

    minimapSelectZoomIn: 'MINIMAP_PERIOD_ZOOM_IN',
    minimapSelectZoomOut: 'MINIMAP_PERIOD_ZOOM_OUT',

    minimapShifrRight: 'MINIMAP_SHIFT_RIGHT',
    minimapShiftLeft: 'MINIMAP_SHIFT_LEFT',

    minimapReset: 'MINIMAP_RESET_PERIOD',

    minimapScaleDatesDay: 1,
    minimapScaleDatesWeek: 7,
    minimapScaleDatesMonth: moment().daysInMonth(),
    minimapScaleDatesYear: 365,

    sideBarSTShow: 'STRUCTURAL_TREE_SIDEBAR_SHOW',
    sideBarSTHide: 'STRUCTURAL_TREE_SIDEBAR_HIDE',

    histogramSwitchMode: 'HISTOGRAM_SWITCH_MODE',

    SELECT: 'ALERT_SELECT',
    DESELECT_ALL: 'ALERT_DESELECT_ALL',

    HOVERED: 'ALERT_HOVER',
    UNHOVERED: 'ALERT_UNHOVER',

    hmiShow: 'GRAPH_HMI_SHOW',
    hmiHide: 'GRAPH_HMI_HIDE',
};
