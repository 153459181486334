import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import './ColorPicker.scss';

/**
 * Color picker functional component
 *
 * @param {React.PropsWithChildren<IProps>} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const ColorPicker: React.FC<IProps> = ({
    label,
    value,
    onChange,
    colors,
    ...props
}) => {


    return (
        <FormControl component="fieldset" className="color-picker">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup row aria-label="color" name="color" value={value} onChange={onChange}>
                {colors.map((color: string, index: number) => (
                    <Radio
                        key={index}
                        value={color}
                        icon={<span className="icon" style={{ backgroundColor: color }} />}
                        checkedIcon={<span className="icon checked" style={{ backgroundColor: color }} />}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default ColorPicker;

interface IProps {
    label: string;
    value: string;
    colors: string[];
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}