import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { graphConstants } from '../../constants';

const selectGraphHistogramHeight = (state: RootState) => state.graphHistogramHeight;

export const selectHistogramHeight = createSelector(
    selectGraphHistogramHeight,
    (graphHistogramHeight) => graphHistogramHeight.height || graphConstants.histogramSmallHeight,
);
