/**
 * A useful stuff for operations with objects
 *
 * @class ObjectHelper
 */
export class ObjectHelper {

    /**
     * Get object value from a given path
     *
     * @param {string} path
     * @param {object} obj
     *
     * @return {mixed}
     */
    static getPathValue(path: string, obj: Record<string, unknown>) {

        return path.split('.').reduce((accumulator: any, currentValue: string) => {

            return accumulator ? accumulator[currentValue] : null;

        }, obj);
    }

    /**
     * Get object value by key
     *
     * @param {Object} obj
     * @param {string} key
     *
     * @return {mixed}
     */
    static getKeyValue<T, K extends keyof T>(obj: T, key: K): T[K] {

        return obj[key];
    }
}
