import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectMonitoringTreeState = createSelector(
    selectHrModuleState,
    state => state.monitoringTreeState || null,
);

export const selectMonitoringTreeStateHR = createSelector(
    selectHrModuleState,
    state => state.monitoringTreeState || null,
);


