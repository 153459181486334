import ApiProvider from '../providers/apiProvider';
import { IData, IOrder } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with factory API resources
 *
 * @class FactoryService
 */
export class FactoryService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/factories';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of factories
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    list(search: string, order: IOrder): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order),
                headers: this.headers,
            });
    }

    /**
     * Bulk factories update
     *
     * @param {IData[]} factories
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    bulkUpdate(factories: IData[]): Promise<AxiosResponse> {

        return this.http
            .patch(this.url() + '/bulk', factories, {
                headers: this.headers,
            });
    }
}
