import { useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GraphDataLastValueAction } from '../../core/actions/grapDataLastValue';
import { appConfig } from '../../config/appConfig';
import { selectHmiPlayerRealTimeStatus } from '../../core/selectors/hmi/playerSelector';
import { selectCurrentUser } from '../../core/selectors/auth/authSelector';

export const useRealTimeSensorDataSocket = (): void => {
  const dispatch = useDispatch();

  const realtimeStatus = useSelector(selectHmiPlayerRealTimeStatus);
  const user = useSelector(selectCurrentUser);
  const interval = useRef(0);

  useLayoutEffect(() => {
    if (user) {
      interval.current = window.setInterval(() => {
        dispatch(GraphDataLastValueAction.getLastValue());
      }, appConfig.hmiPlayerRealtimeTimeout);

      dispatch(GraphDataLastValueAction.getLastValue());
    } else {
      if (interval) {
        clearInterval(interval.current);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtimeStatus, user]);
};
