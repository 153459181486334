import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { notificationConstants } from '../constants';
import { IChartAlert, IErrors, INotification } from '../interfaces';
import { notificationsService } from '../services';
import { AxiosResponse } from 'axios';
import { selectAllNotification, selectAllNotificationMap } from '../selectors/notification/notificationSelector';
import store from '../store';

/**
 * Process related actions
 *
 * @type {Object}
 */
export const NotificationAction = {

    /**
     * Show/hide state details popover
     *
     *
     * @return {Promise<Object>}
     * @param from
     * @param to
     */
    list: (from: Date, to: Date) => {

        const success = (notification: INotification[]) => {

            return {
                type: notificationConstants.LIST_NOTIFICATION_SUCCESS,
                notifications: notification,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: notificationConstants.LIST_NOTIFICATION_FAILURE,
                errors,
            };
        }, service = new notificationsService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            service.list(from, to)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));
                })
                .catch(errors => {

                    dispatch(failure(service.errorHandler(errors)));

                });

        };
    },
    
    /**
     * Mark As Read Action
     *
     *
     * @return {Promise<Object>}
     * @param {INotification} notification
     */
    markAsReadAction: (notification: INotification) => {

        const success = (data: INotification) => {

            return {
                type: notificationConstants.MASK_AS_READ_NOTIFICATION_SUCCESS,
                markAsRead: data,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: notificationConstants.MASK_AS_READ_NOTIFICATION_FAILURE,
                errors,
            };
        }, service = new notificationsService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            const state = store.getState(),
            notifications = selectAllNotificationMap(state);

            const currentNotification = notifications.get(notification.id || (notification as unknown as  IChartAlert).notificationId);

            service.markAsRead(notification)
                .then(({ data }: AxiosResponse) => {
                    if (currentNotification) {
                        dispatch(success({ ...currentNotification, isNew: false }));
                    }
                })
                .catch(errors => {

                    dispatch(failure(service.errorHandler(errors)));

                });

        };
    },

    /**
     * Mark All As Read Action
     *
     * @return {Promise<Object>}
     */
    markAllAsReadAction: () => {

        const success = (notifications: INotification[]) => {

            return {
                type: notificationConstants.MASK_ALL_AS_READ_NOTIFICATION_SUCCESS,
                notifications,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: notificationConstants.MASK_ALL_AS_READ_NOTIFICATION_FAILURE,
                errors,
            };
        }, service = new notificationsService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            const allNotification = selectAllNotification(store.getState());
            if (allNotification) {

                const notifications = service.markAsReadAllLocalData(allNotification);

                dispatch(success(notifications));

            }

            service.markAsReadAll()
                .catch(errors => {

                    dispatch(failure(service.errorHandler(errors)));

                });

        };
    },

    /**
     * Show/hide state details popover
     *
     *
     * @return {Promise<Object>}
     * @param notification
     */
    updateCommentAction: (notification: INotification) => {

        const success = (data: INotification) => {

            return {
                type: notificationConstants.UPDATE_COMMENT_NOTIFICATION_SUCCESS,
                markAsRead: data,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: notificationConstants.UPDATE_COMMENT_NOTIFICATION_FAILURE,
                errors,
            };
        }, service = new notificationsService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            service.comment(notification)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(notification));
                })
                .catch(errors => {

                    dispatch(failure(service.errorHandler(errors)));

                });

        };
    },

};
