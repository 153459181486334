import { AppSettingConstant, dashboardConstants } from '../../constants';
import { IAppSettingAction, IAppSettingState } from '../../interfaces';
import { appConfig } from '../../../config/appConfig';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function appSetting(state: IAppSettingState = {
    settings: {
        qivisor: {
            isEnabled: true,
        },
        qvhr: {
            isEnabled: true,
        },
        hmi: {
            isEnabled: appConfig.enableHMI,
        },
        mainIntervals: {
            twoHours: false,
            twelveHours: true,
            day: true,
            week: true,
            month: true,
            year: true,
        },
        isConfigured: true,
        hasDashboards: true,
        language: 'ru',
        valueRepeatTimeout: appConfig.valueRepeatTimeout,
        defaultMonitoringView: appConfig.defaultMonitoringView,
    },
    isLoaded: false,
    errors: {},
}, action: IAppSettingAction): IAppSettingState {

    switch (action.type) {

        case AppSettingConstant.GET_APP_SETTING_SUCCESS:

            return {
                ...state,
                settings: { ...state.settings, ...action.settings },
                isLoaded: true,
                errors: {},
            };

        case AppSettingConstant.GET_APP_SETTING_FAILURE:

            return {
                ...state,
                errors: action.errors,
            };

        case AppSettingConstant.LANGUAGE_SWITCH:
            
            state.settings.language = action.language;
            
            return {
                ...state,
                settings: state.settings,
            };

        case dashboardConstants.STORE_SUCCESS:

            state.settings.hasDashboards = true;

            return {
                ...state,
                settings: state.settings,
            };

        case dashboardConstants.LIST_FAILURE:

            state.settings.hasDashboards = false;

            return {
                ...state,
                settings: state.settings,
            };

        default:

            return state;
    }

}