import { graphConstants } from '../../../../core/constants';
import { IGraphBarTableVisibilityAction, IGraphBarTableVisibilityState } from '../../../../core/interfaces';


/**
 * Bar chart table view visibility reducer. Process actions and change application global state
 *
 * @param {IGraphBarTableVisibilityState} state Current application state
 * @param {IGraphBarTableVisibilityAction} action Current action
 *
 * @return {IGraphBarTableVisibilityState}
 */
export function graphBarTableVisibility(state: IGraphBarTableVisibilityState = {
    visible: false,
    sensorId: null,
    unit: null,
    hrMode: false,
}, action: IGraphBarTableVisibilityAction): IGraphBarTableVisibilityState {

    switch (action.type) {

        case graphConstants.barTableViewShow:

            return {
                visible: true,
                top: action.top,
                sensorId: action.sensorId,
                unit: action.currentUnit,
                ref: action.ref,
                hrMode: action.hrMode? action.hrMode: false,
            };

        case graphConstants.barTableViewHide:

            return {
                visible: false,
                sensorId: null,
                unit: null,
                hrMode: action.hrMode? action.hrMode: false,
            };

        default:

            return state;
    }
}
