import { hmiPlayerConstants, hmiSchemaConstants } from '../../constants';
import { IHMIPlayerAction, IHMIPlayerState, PlayerMode } from '../../interfaces';

const initialState = {
    mode: PlayerMode.MODE_STOP,
    speed: 1,
    value: 0,
    schema: null,
    defaultSchema: null,
    showObjects: true,
    showHmiMap: true,
    opacityHmiMap: null,
    realTime: false,
    objectsWithData: [],
};

/**
 * HMI Player reducer
 *
 * @param {IHMIPlayerState} state Current application state
 * @param {IHMIPlayerAction} action Current action
 *
 * @returns {IHMIPlayerState}
 */
export function hmiPlayer(state: IHMIPlayerState = initialState, action: IHMIPlayerAction): IHMIPlayerState {

    switch (action.type) {

        case hmiPlayerConstants.PLAY:

            return {
                ...state,
                mode: PlayerMode.MODE_PLAY,
            };

        case hmiPlayerConstants.PAUSE:

            return {
                ...state,
                mode: PlayerMode.MODE_PAUSE,
            };

        case hmiPlayerConstants.REALTIME:

            return {
                ...state,
                mode: action.realTime? PlayerMode.MODE_REALTIME: PlayerMode.MODE_STOP,
                realTime: action.realTime,
            };

        case hmiPlayerConstants.STOP:

            return {
                ...state,
                mode: PlayerMode.MODE_STOP,
                value: 0,
            };

        case hmiPlayerConstants.SET_VALUE:

            return {
                ...state,
                value: action.value,
            };

        case hmiPlayerConstants.SET_SPEED:

            return {
                ...state,
                speed: action.speed,
            };

        case hmiPlayerConstants.SET_SCHEMA:

            return {
                ...state,
                schema: action.schema,
                defaultSchema: action.schema? action.schema: state.defaultSchema,
                showHmiMap: action.schema ? Boolean(parseInt(action.schema.showMinimap)): true,
                showObjects: action.schema ? Boolean(parseInt(action.schema.showObjects)): true,
            };


        case hmiSchemaConstants.CLEAR_HMI_SCHEMA_STATE:
            return {
                ...state,
                schema: null,
            };

        case hmiPlayerConstants.SHOW_HMI_SCHEMA:

            return {
                ...state,
                showHmiMap: !state.showHmiMap,
            };

        case hmiPlayerConstants.SHOW_OBJECT:

            return {
                ...state,
                showObjects: !state.showObjects,
            };

        case hmiPlayerConstants.SET_OPACITY:

            return {
                ...state,
                opacityHmiMap: action.opacityHmiMap,
            };

        case hmiPlayerConstants.STORE_HISTOGRAM_DATA:

            return {
                ...state,
                objectsWithData: action.objectsWithData,
            };

        case hmiPlayerConstants.CLEAR_HISTOGRAM_DATA:

            return {
                ...state,
                objectsWithData: [],
            };

        default:

            return state;
    }
}
