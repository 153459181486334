import moment from 'moment';
import { appConfig } from './../config/appConfig';

export const selectMinAlertInterval = () => {

    const { defaultMonitoringView } = appConfig;

    const currentDay = moment().utc().startOf('day');

    switch (defaultMonitoringView) {
        case 'day':
            return currentDay.diff(moment().utc().subtract({ d: 1 }).startOf('day'));
        case 'week':
            return currentDay.diff(moment().utc().subtract({ d: 7 }).startOf('day'));
        case 'month':
            return currentDay.diff(moment().utc().subtract({ d: moment().daysInMonth() }).startOf('day'));
        default:
            return 0;    
    }
};