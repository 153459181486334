import { IFlatTreeFactory, IFlatTreeProcess, IFlatTreeUnit } from '../interfaces';
import { RootState } from '../store';

export const getFlatTreeItemsByType = (items: (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[], type: string): (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[] => {

    return items.filter(value => value.type === type);
};

export const getId = (state: RootState, ID: number): number => ID;

export const getSecondId = (state: RootState, ID: number, secondID: number): number => secondID;

export const getType = (state: RootState, ID: number, type: string): string => type;