/**
 * Read file content
 *
 * @param {File} file
 * @param {boolean} asText
 *
 * @return Promise<string>
 */
export const readFile = (file: File, asText = true): Promise<string> => {

    return new Promise((resolve, reject) => {

        const reader = new FileReader();

        reader.onload = () => {

            resolve(reader.result as string || '');
        };

        reader.onerror = () => {

            reject(new DOMException('Failed to read file'));
        };

        if (asText) {

            reader.readAsText(file);

        } else {

            reader.readAsDataURL(file);
        }
    });
};
