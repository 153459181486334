export const alertConstants = {
    STORE_ALERT_SUCCESS: 'ALERT_STORE_SUCCESS',
    CLEAR_ALERT_SUCCESS: 'CLEAR_STORE_ALERT_SUCCESS',
    STORE_ALERT_FAILURE: 'ALERT_STORE_FAILURE',

    BULK_STORE_ALERT_SUCCESS: 'BULK_ALERT_STORE_SUCCESS',
    BULK_STORE_ALERT_FAILURE: 'BULK_ALERT_STORE_FAILURE',

    SHOW_DETAILS: 'ALERT_SHOW_DETAILS',
    HIDE_DETAILS: 'ALERT_HIDE_DETAILS',

};