import React, { useCallback, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import NodeTitle from './NodeTitle';
import Process from './Process';
import { IFactoryDragDropTreeProps } from '../../../core/interfaces';



/**
 * Drag'n'drop tree factory node
 *
 * @param factory
 * @param index
 * @param menuNode
 * @param maxProductInUnit
 * @param filterValue
 * @param placeholderProps
 * @param scrollLeft
 *
 * @param setAlertRules
 * @param setDraggedItem
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Factory: React.FC<IFactoryDragDropTreeProps> = (
    {
        factory,
        index,
        maxProductInUnit,
        filterValue,
        placeholderProps,
        scrollLeft,
        setAlertRules,
        setDraggedItem,
    }: IFactoryDragDropTreeProps,
) => {

    const [collapseItem, setCollapseItem] = useState<{ height: number, status: boolean }>({ height: 40, status: true });

    /**
     *  Handle for open the draggable element
     *
     * @param {number} id
     * @param {string} paramArr
     * @param event
     */
    const handleClick = useCallback((id: number, paramArr: string, event: boolean) => {

        setCollapseItem({ height: 40, status: !event });

    }, [setCollapseItem]);

    return (
        <div className="factory expansion-panel">
            <Accordion defaultExpanded expanded={collapseItem.status}>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id={'factory' + factory.id.toString()}
                >
                    <NodeTitle
                        handleClick={handleClick}
                        node={factory}
                        nodeName="factory"
                        nodeNameMenu="factory"
                        rightSectionClass="first-item"
                        parent={null}
                        getOpenStatus={collapseItem.status}
                        scrollLeft={scrollLeft}
                        maxProductInUnit={maxProductInUnit}
                        collapseStateItem={collapseItem}
                    />
                </AccordionSummary>
                <AccordionDetails className={'factory-details'}>
                    <div className={'display-flex-row'}>
                        {factory.data ?
                            factory.data
                                .map((process, processIndex: number) => (
                                    <Process
                                        key={'process-key-' + process}
                                        process={process}
                                        index={processIndex}
                                        factoryIndex={index}
                                        scrollLeft={scrollLeft}
                                        filterValue={filterValue}
                                        placeholderProps={placeholderProps}
                                        maxProductInUnit={maxProductInUnit}
                                        setAlertRules={setAlertRules}
                                        setDraggedItem={setDraggedItem}
                                        factory={factory}
                                    />
                                ))
                            :
                            null
                        }
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Factory;
