import { mouseConstants } from '../../constants';
import { IMouseAction, IMouseState } from '../../interfaces';


/**
 * Mouse reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function mouse(state: IMouseState = {
    x: 0,
    y: 0,
}, action: IMouseAction): IMouseState {

    switch (action.type) {

        case mouseConstants.MOVE:

            return {
                x: action.x,
                y: action.y,
            };

        default:

            return state;
    }
}
