import { createSelector } from 'reselect';
import { RootState } from '../../store';


const selectGraphPeriod =  (state: RootState) => state.graphPeriod;

export const selectGraphPeriodRange = createSelector(
    selectGraphPeriod,
    (graphPeriod) => graphPeriod.range,
);

export const selectGraphCurrentPeriod = createSelector(
    selectGraphPeriod,
    (graphPeriod) => graphPeriod.currentPeriod,
);
