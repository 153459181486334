import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as KeyIcon } from '../../../../core/ui/assets/images/icons/key.svg';
import { ReactComponent as SensorIcon } from '../../../../core/ui/assets/images/icons/parameter-v-2.svg';
import { ReactComponent as StateIcon } from '../../../../core/ui/assets/images/icons/state_icon.svg';

import {
    IActiveProducts,
    IMonitoringTreeSensorProps,
    ISensorTargetValueState,
} from '../../../../core/interfaces';
import { statesActions } from '../../../../core/actions';
import { HistogramChart, StateChart } from '../../../../base/components';
import { isMobile } from 'react-device-detect';
import { selectHistogramHeight } from '../../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { selectGraphBarHeight } from '../../../../core/selectors/graphBarHeight/graphBarHeightSelector';
import { selectForm } from '../../../../core/selectors/form/formSelector';
import {
    selectHmiHoverItem,
    selectHmiHoverSensor,
    selectHoverHMILogic
} from '../../../../core/selectors/hmi/playerHoverItem';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import { HmiObjectAction } from '../../../../core/actions/hmiObjectAction';
import { usePrevious } from '../../../../hooks/usePrevious';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectActiveProductByUnitId } from '../../../../core/selectors/unitActiveProducts/activeProductDataSelector';
import { isVisibleHMIPlayerScheme, selectHmiPlayerSchema } from '../../../../core/selectors/hmi/playerSelector';
import Skeleton from '@material-ui/lab/Skeleton';


/**
 * Monitoring tree sensor functional component
 *
 * @param {any} sensor
 * @param {any} unit
 * @param {any} process
 * @param {any} factory
 * @param {any} keySensor
 * @param {number} histogramHeight
 * @param {boolean} visibleSideBar
 * @param {number} maxWidthSideBar
 *
 * @param activeProductData
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Sensor: React.FC<IMonitoringTreeSensorProps> = (
    {
        sensor,
        unit,
        process,
        factory,
        keySensor,
        visibleSideBar,
        maxWidthSideBar,
    }: IMonitoringTreeSensorProps,
) => {
    const [delay, setDelay] = useState(false);

    const [sensorTargetValue, setSensorTargetValue] = useState<ISensorTargetValueState | undefined>(undefined);
    const [editPreferences, setEditPreferences] = useState(false);
    const activeProductData = useParamSelector(selectActiveProductByUnitId, unit.id) as IActiveProducts[] | undefined;

    const histogramHeight = useSelector(selectHistogramHeight);

    const graphHeight = useSelector(selectGraphBarHeight);
    const selectFormData = useSelector(selectForm);
    const selectedDashboard = useSelector(selectSelectedDashboard);
    const prewSelectedDashboard = usePrevious(selectedDashboard);
    const hoverItem = useSelector(selectHmiHoverItem);

    const schema = useSelector(selectHmiPlayerSchema);
    const visibilityScheme = useSelector(isVisibleHMIPlayerScheme);
    const dedicatedSensor = useSelector(selectHmiHoverSensor);
    const hoverHmiPlayerLogic = useSelector(selectHoverHMILogic);

    let  delayTimeout: NodeJS.Timeout | undefined = undefined;

    const dispatch = useDispatch();

    const { targetValues } = sensor;

    /**
     * Hover sensor on tree Callback
     */
    useEffect(() => {

        if (hoverItem?.sensorId === sensor.id) {

            dispatch(HmiObjectAction.hoverSensorOnTree(sensor));
        }
    }, [hoverItem, sensor, dispatch]);

    /**
     * Stop click even propagation
     *
     * @param {React.MouseEvent} event
     */
    const stopClickPropagation = useCallback((event: React.MouseEvent) => {

        event.stopPropagation();
    }, []);

    useEffect(() => {

        if (selectFormData?.formName === 'histogramForm' && selectFormData?.formModel) {

            if (selectFormData.formModel.sensor === sensor.id) {

                setEditPreferences(true);

            }
        }
        return ()=> setEditPreferences(false);

    }, [selectFormData]);


    /**
     * Rendering delay
     */
    useEffect(() => {

        if (prewSelectedDashboard?.id !== selectedDashboard?.id) {

            delayTimeout = setTimeout(() => {
                setDelay(false);
                delayTimeout && clearTimeout(delayTimeout);
            }, 100);
        }

        return ()=> delayTimeout && clearTimeout(delayTimeout);

    }, []);
    // }, [delayTimeout, selectedDashboard, prewSelectedDashboard]);

    useEffect(() => {

        if (activeProductData && targetValues) {

            const activeProducts = activeProductData;

            const sensorTargetValueData = {
                activeProducts: activeProducts,
                targetValues: targetValues,
            };
            setSensorTargetValue(sensorTargetValueData);
        }

    }, [activeProductData, targetValues]);

    /**
     * deselect states
     * @type {() => void}
     */
    const deselectStates = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        event.preventDefault();

        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);


    // useEffect(() => {
    //
    //     if (prewSelectedDashboard?.id !== selectedDashboard?.id) {
    //
    //         setDelay(true);
    //     }
    //
    // }, []);

    const skeletonStyle = {
        borderBottom: '1px solid #e9ecef',
        background: '#ffffff',
        width: '100%',
    };

    /**
     * Mouse enter callback  for the hmi player
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseEnterForPlayer = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent> |  React.TouchEvent<HTMLDivElement>) => {

        const sendItem = schema?.hmiObjects?.find(obj=> obj.sensorId === sensor.id);

        dispatch(HmiObjectAction.hoverItemOnMap(sendItem));
        dispatch(HmiObjectAction.hoverSensorOnTree(sendItem ? sensor : undefined));

    }, [dispatch, sensor, schema]);

    /**
     * Mouse leave callback  for the hmi player
     *
     * @type {() => void}
     */
    const onMouseLeaveForPlayer = useCallback(() => {

        if (hoverHmiPlayerLogic) {

            dispatch(HmiObjectAction.hoverSensorOnTree(undefined));
            dispatch(HmiObjectAction.hoverItemOnMap(undefined));
        }

    }, [dispatch, hoverHmiPlayerLogic]);


    if (sensor && sensor.isVisible) {

        /**
         * Rendering delay
         */
        if (delay) {

            return (
                <div key={'sensor-key-' + sensor.id}>
                    <div className={`item-title no-pointer ${editPreferences ? 'editPreferences' : ''} ${hoverItem?.sensorId === sensor.id ? 'hover' : ''} ${visibilityScheme?'open-hmi':''}}`}>
                        <div
                            className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding' : 'right-section sensor-name left-padding-2'}
                        >
                            {sensor.sensorType === 'state' ?
                                (
                                    <React.Fragment>
                                        <StateIcon className="default-icon-size m8" />
                                        <span className={`sensor-name ${isMobile ? 'is-mobile' : ''}`}>{sensor.name}</span>
                                    </React.Fragment>
                                )
                                :
                                (
                                    <React.Fragment>
                                        {sensor.isKeyParameter ?
                                            <KeyIcon className="key-param default-icon-size" />
                                            : null
                                        }
                                        <SensorIcon className="sensor default-icon-size m8" />
                                        <span className={`sensor-name ${sensor.name.length > 35 ? 'rtl' : ''} ${isMobile ? 'is-mobile' : ''}`}>
                                            {sensor.name ? ' ' + sensor.name : ' ' + sensor.id}
                                        </span>
                                    </React.Fragment>
                                )
                            }
                        </div>
                        <div
                            className="left-section with-chart skeleton"
                        >
                            <Skeleton
                                variant={'rect'}
                                style={skeletonStyle}
                                height={sensor.sensorType === 'state' ? Number(graphHeight) : histogramHeight}
                                animation={'pulse'}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        const rightSectionSensorNameStyle: CSSProperties = {
            maxWidth: maxWidthSideBar,
            minWidth: maxWidthSideBar,
            transition: 'max-width 0.2s linear',
            height: sensor.sensorType === 'state' ? Number(graphHeight) : histogramHeight,
        };

        const hoverPlayerLogic = dedicatedSensor && hoverItem ? dedicatedSensor?.id === hoverItem?.sensorId &&  dedicatedSensor?.id === sensor.id
    : hoverItem?.sensorId === sensor.id;

        return (
            <div key={'sensor-key-' + sensor.id} onClick={deselectStates}>
                <div
                    className={`item-title no-pointer ${hoverPlayerLogic ? 'hover' : ''} ${editPreferences ? 'editPreferences' : ''} ${visibilityScheme ? 'open-hmi' : ''}`}
                >
                    <div
                        className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding' : 'right-section sensor-name left-padding-2'}
                        style={rightSectionSensorNameStyle}
                        onClick={deselectStates}
                        onMouseEnter={onMouseEnterForPlayer}
                        onTouchStart={onMouseEnterForPlayer}
                        onMouseLeave={onMouseLeaveForPlayer}
                        // onTouchEnd={onMouseLeaveForPlayer}
                    >
                        {sensor.sensorType === 'state' ?
                            (
                                <React.Fragment>
                                    <StateIcon className="default-icon-size m8" />
                                    <span className={`sensor-name ${isMobile ? 'is-mobile' : ''}`}>{sensor.name}</span>
                                </React.Fragment>
                            )
                            :
                            (
                                <React.Fragment>
                                    {sensor.isKeyParameter ?
                                        <KeyIcon className="key-param default-icon-size" />
                                        : null
                                    }
                                    <SensorIcon className="sensor default-icon-size m8" />
                                    <span className={`sensor-name ${sensor.name.length > 35 ? 'rtl' : ''} ${isMobile ? 'is-mobile' : ''}`}>
                                        {sensor.name ? ' ' + sensor.name : ' ' + sensor.id}
                                    </span>
                                </React.Fragment>
                            )
                        }
                    </div>
                    <div
                        className={sensor.isVisible ? 'left-section with-chart' : 'left-section'}
                        style={{ height: sensor.sensorType === 'state' ? graphHeight : histogramHeight }}
                        onClick={stopClickPropagation}
                        onTouchMove={onMouseLeaveForPlayer}
                    >
                        {sensor.sensorType === 'graph' ?
                            <HistogramChart
                                sensor={sensor}
                                sensorTargetValue={sensorTargetValue}
                                hrMode={false}
                            />
                            :
                            null
                        }

                        {sensor.sensorType === 'state' ?
                            <StateChart
                                sensor={sensor}
                                currentUnit={unit}
                                tableTitle={`${factory.name}/${process.name}/${unit.name}`}
                                keySensor={keySensor}
                                hrMode={false}
                            />
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default Sensor;
