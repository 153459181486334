import { formConstants, userConstants } from '../../constants';
import { IUserAction, IUserChangeState } from '../../interfaces';

/**
 * User add/update/delete reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function userChange(state: IUserChangeState = {
    user: null,
    errors: {},
}, action: IUserAction): IUserChangeState {

    switch (action.type) {

        case userConstants.STORE_SUCCESS:
        case userConstants.UPDATE_SUCCESS:
        case userConstants.DELETE_SUCCESS:

            return {
                user: action.user,
                errors: {},
            };

        case userConstants.STORE_FAILURE:
        case userConstants.UPDATE_FAILURE:
        case userConstants.DELETE_FAILURE:

            return {
                user: null,
                errors: action.errors,
            };

        case formConstants.CLOSE:

            return {
                ...state,
                errors: action.errors,
            };

        default:

            return state;
    }
}
