import React, { ComponentType, useCallback } from 'react';
import { history } from '../../../helpers';
import { Link } from 'react-router-dom';
import { ListItemText, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IMenuListItemProps {
    path: string;
    item: {
        itemName: string,
        component: ComponentType<any>,
        path: string,
        route: string,
        permission: string,
        action: () => void
    };
}

const MenuListItem: React.FC<IMenuListItemProps> = ({ path, item }: IMenuListItemProps) => {
    
    const { t } = useTranslation();

    /**
     * On click callback
     *
     * @type {() => void}
     */
    const onClickCallback = useCallback(() => {

        if (item.action) item.action();

    }, [item]);
    
    return (
        <ListItem
            button
            selected={history.location.pathname === path + item.path}
            component={Link}
            to={path + item.path} key={item.itemName}
            onClick={onClickCallback}
        >
            <ListItemText primary={t(item.itemName)} />
        </ListItem>
    );
};

export default MenuListItem;
