import React from 'react';
import { TextField, InputAdornment, InputLabel, IconButton } from '@material-ui/core';
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import styled from 'reshadow';

import calendar from '../../assets/images/icons/calendar.svg';
import search from '../../assets/images/icons/search.svg';

const styles = require('./TextInput.scss');

//Possible text input icons
const icons = {
	calendar,
	search,
	// other name of the icons
};

/**
 * A generic text input component. Can contain clickable icon
 *
 * @class TextInput
 */
class TextInput extends React.Component<TextFieldProps> {

	/**
	 * Render the component
	 *
	 * @return {JSX.Element}
	 */
	render() {

		const { children, label, className, ...props } = this.props;

		const withIcon = props.icon ? props.icon : null;

		return styled(styles)(
			<div className={className}>
				<InputLabel>{label}</InputLabel>
				<TextField
					{...props}
					variant="outlined"
					size="small"
					fullWidth
					placeholder={props.placeholder}
					className=""
					InputProps={
						{
							endAdornment: withIcon && isValidIcon(withIcon) ? (
								<InputAdornment position="end">
									<IconButton>
										<img src={icons[withIcon]} alt="icon" />
									</IconButton>
								</InputAdornment>
							) : null,
						}
					}
				/>
				{children}
			</div>,
		);
	}
}

/**
 * Check if given input icon exists in the list of avaiable icons
 *
 * @param {string} value
 *
 * @return {boolean}
 */
const isValidIcon = (value: string): value is keyof typeof icons => {

	return value in icons;
};

interface IProps {
	icon?: string;
}

export type TextFieldProps = IProps & MuiTextFieldProps;

export default TextInput;
