import { createSelector } from 'reselect';
import { selectMousePosition } from '../mouse/mouseSelector';
import { RootState } from '../../store';
import { getWidthFromMousePosition } from '../../functions/getWidthFromMousePosition';
import { getHeightFromMousePosition } from '../../functions/getHeightFromMousePosition';
import { selectScreenWidth } from '../dashboard/dashboardSelector';
import { selectMinimapVisibility } from '../graphMinimapVisibility/graphMinimapVisibilitySelector';

const stateSelector = (state: RootState) => state.responsiveDrawer;

export const selectDrawerIsResize = createSelector(
    stateSelector,
    drawer => drawer.isResize,
);

export const selectDrawerLastMousePosition = createSelector(
    stateSelector,
    drawer => drawer.lastMousePosition,
);

export const selectPositionDrawer = createSelector(
    stateSelector,
    drawer => drawer.positionDrawer,
);

export const selectIsFullScreenDrawer = createSelector(
    stateSelector,
    drawer => drawer.isFullScreen,
);
export const selectStartTouchEventDrawer = createSelector(
    stateSelector,
    drawer => drawer.startTouchEvent,
);

// TODO: Do a similar calculation for height, when player position is `top` or `bottom`
// TODO: Use left sidebar width to limit player resize
export const selectDrawerWidthOrigin = createSelector(
    selectDrawerIsResize,
    selectMousePosition,
    selectDrawerLastMousePosition,
    (isResize, position, lastMousePosition) => isResize ?
        getWidthFromMousePosition(position) : getWidthFromMousePosition(lastMousePosition),
);

export const selectDrawerWidth = createSelector(
    selectDrawerWidthOrigin,
    selectScreenWidth,
    (drawerWidth, screenWidth) => drawerWidth > 0 ? drawerWidth : screenWidth / 2 > 500 ? screenWidth / 2 : 500,
);

export const selectDrawerHeight = createSelector(
    selectDrawerIsResize,
    selectMousePosition,
    selectDrawerLastMousePosition,
    selectMinimapVisibility,
    (isResize, position, lastMousePosition, visible) => isResize ?
        getHeightFromMousePosition(position, visible) : getHeightFromMousePosition(lastMousePosition, visible),
);

let prevWidth = 0;

export const selectDrawerWidthWithDrawRules = createSelector(
    selectDrawerWidth,
    selectDrawerIsResize,
    (width, resizeLogic) => {
        if (!resizeLogic) {
            prevWidth = width;
        }
        return resizeLogic ? prevWidth : width;
    },
);