import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import './MultipleSelect.scss';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0.5, 0, 3),
            width: '100%',
            '& > .MuiInput-formControl': {
                border: '1px solid #adb5bd',
                fontSize: '0.9rem',
                maxWidth: '18.5vw',

                [theme.breakpoints.down('xl')]: {
                    maxWidth: '334px !important',
                },

                '&:before, &:after, &:active, &:hover': {
                    'content': 'none',
                },
                '& > .MuiSelect-select:focus': {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                },
                '& > .MuiSelect-select': {
                    paddingLeft: '1.2vh',
                },
            },
            '& > .form-field': {
                marginBottom: 0,
            },
            '& > .success-field': {
                borderColor: '#72c26a',
            },
            '& > .error-field': {
                borderColor: '#ff3b30',
            },
        },
    }),
);

const MultipleSelect: React.FC<SelectProps> = ({
    children,
    ...props
}) => {

    const [open, setOpen] = useState<boolean>(false);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const classes = useStyles();

    const { options, value, placeholder } = props;

    const placeholderValue = placeholder ?
        (<MenuItem value="" disabled>
            <span className="placeholder">{placeholder}</span>
         </MenuItem>) : null;

    const selectedOption: string[] = options.filter(option => {
        return value.find(value => value === option.value);
    }).map(option => option.label);

    const selectedLables = selectedOption.join(', ');

    return (
        <FormControl className={classes.formControl}>
            <Tooltip
                title={selectedLables}
                open={open}
                disableTouchListener
            >
                <Select
                    multiple
                    input={<Input />}
                    renderValue={(selected: any) => (selected as string[]).join(', ')}
                    displayEmpty
                    onMouseEnter={() => setOpen(selectedLables.length > 45 && !menuOpen)}
                    onMouseLeave={() => setOpen(false)}
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}
                    {...props}
                >
                    {placeholderValue}
                    {options.map(option => (
                        <MenuItem key={option.value} value={option.value} className="multiselect-checkbox-paper">
                            <ListItemText primary={option.label} />
                            <Checkbox checked={value.indexOf(option.value) > -1} />
                        </MenuItem>
                    ))}
                </Select>
            </Tooltip>
        </FormControl>
    );
};

interface IOptions {
    label: string;
    value: string | number;
}

interface IProps {
    value: (string | number)[];
    options: IOptions[];
}


export type SelectProps = IProps & MuiSelectProps;

export default MultipleSelect;