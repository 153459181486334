import { v4 } from 'uuid';
import { Canvas } from '../canvas/Canvas';
import { IHmiObject } from '../../../../../core/interfaces';
import { editorConstants } from '../constants';

/**
 * Draw object on canvas
 *
 * @param {Canvas} canvas
 * @param {string} type
 * @param {IHmiObject} object
 * @param {label} label
 * @param fontSizeLabel
 * @param visible
 * @param dataValue
 */
export function drawObject(canvas: Canvas, type: string, object: IHmiObject, label: string, fontSizeLabel: number, visible: boolean, dataValue?: string): void {

    const itemPoints = object.points ?
        object.points.map((point: number[]) => {

            const [x = 0, y = 0] = point;

            return { x: x, y: y };
        }) : [];
    const optionsMapping = {
        [editorConstants.interaction.modeRectangle]: {
            strokeWidth: 3,
            stroke: '#74797d',
            fill: '#cdd0d4',
            opacity: 1,
            width: 18,
            height: 18,
            objectCaching: false,
        },
        [editorConstants.interaction.modeOval]: {
            strokeWidth: 1,
            stroke: '#74797d',
            fill: '#cdd0d4',
            opacity: 1,
            radius: 9,
            objectCaching: false,
        },
        [editorConstants.interaction.modeTriangle]: {
            strokeWidth: 1,
            stroke: '#74797d',
            fill: '#cdd0d4',
            opacity: 1,
            width: 18,
            height: 18,
            objectCaching: false,
        },
        [editorConstants.interaction.modePolygon]: {
            stroke: '#999999',
            fill: '#cccccc',
            strokeUniform: true,
            strokeWidth: 3,
            strokeWidthInitial: 3,
            opacity: 1,
            objectCaching: false,
            superType: 'drawing',
        },
    };
    const drawScale = object.scale && (type === 'sensor' ||
            type === 'engine' ||
            type === 'star' ||
            type === 'oval' ||
            type === 'triangle' ||
            type === 'valve') ? {
            scaleX: object.scale.x,
            scaleY: object.scale.y,
        } : {
            height: object.height || 18,
            width: object.width || 18 * 3,
            scaleX: 1,
            scaleY: 1,
        },
        baseOption = {
            id: v4(),
            type: type,
            left: object.position?.x | 0,
            top: object.position?.y | 0,
            points: itemPoints,
            objectId: object.id,
            unitId: object.unit,
            factoryId: object.fabric,
            angle: object.angle,
            fontSize: typeof object.fontSize === 'number' ? object.fontSize : 12,
            zIndex: 20 + (object.zIndex || 1),
            layoutNumber: object.zIndex || 1,
            suppressCallback: true,
            pidCodePosition: object.pidCodePosition,
            textPositionInObject: object.textPositionInObject,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockRotation: true,
            fontSizeLabel: fontSizeLabel,
            visible: visible,
            sensorId: object.sensorId,
        },
        labelItemOption = {
            id: v4(),
            type: 'pitCode',
            objectType: type,
            label: label,
            left: object.position?.x | 0,
            top: object.position?.y | 0,
            objectId: object.id,
            unitId: object.unit,
            factoryId: object.fabric,
            layoutNumber: object.zIndex || 1,
            zIndex: 20 + (object.zIndex || 1),
            suppressCallback: true,
            itemId: baseOption.id,
            pidCodePosition: object.pidCodePosition,
            fontSize: fontSizeLabel,
            dataValue,
            visible: visible,
            sensorId: object.sensorId,
        };

    canvas.handler.add({ ...baseOption, ...optionsMapping[type], ...drawScale });

    // Provides correct positioning for label
    const addLabelTimeout = setTimeout(() => {

        canvas.handler.add(labelItemOption);

        clearTimeout(addLabelTimeout);

    }, 0);

}
