import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';
import { ILayer, ILocation } from '../interfaces';
import { serialize as objectToFormData } from 'object-to-formdata';

/**
 * Service to work with layer API resources
 *
 * @class LayerService
 */
export class LayerService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/layers(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of layers
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<ILayer[]> {

        return trackPromise(
            this.http
                .get<ILayerApiModel[]>(this.url(), {
                    params: this.prepareListParams(search, order, join, filter),
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(list => list.map(mapToLayerModel)),
        );
    }

    /**
     * Create layer
     * @param location
     * @param layer
     */
    store(location: ILocation, layer: ILayer): Promise<ILayer> {
        const layerPayload: ILayerApiModel = mapLayerModelToApiModel(layer);

        const formData = objectToFormData({ ...layerPayload, plan: location.id });

        return trackPromise(
            this.http
                .post<ILayerApiModel>(this.url(), formData, {
                    headers: {
                        ...this.headers,
                        ...this.formDataHeaders,
                    },
                })
                .then(this.getDataExtractor())
                .then(mapToLayerModel),
        );
    }

    /**
     * Update layer
     * @param location
     * @param layer
     */
    update(location: ILocation, layer: ILayer): Promise<ILayer> {
        const layerPayload: ILayerApiModel = mapLayerModelToApiModel(layer);

        const formData = objectToFormData({ ...layerPayload, plan: location.id });

        return trackPromise(
            this.http
                .patch<ILayerApiModel>(this.url({ id: layer.id }), formData, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToLayerModel),
        );
    }

    /**
     * Delete layer
     * @param layer
     */
    delete(layer: ILayer): Promise<boolean> {

        return trackPromise(
            this.http
                .delete<ILayerApiModel>(this.url({ id: layer.id }), {
                    headers: this.headers,
                })
                .then(Boolean),
        );
    }

    /**
     * Save new or existing model
     * @param location <ILocation>
     * @param layer <ILayer>
     * @return Promise<ILayer>
     */
    saveLayer(location: ILocation, layer: ILayer): Promise<ILayer> {

        if (layer.id >= 1) {
            return this.update(location, layer);
        }

        return this.store(location, layer);
    }

    /**
     * Fetch SVG picture data
     * @param layer
     */
    getPicture(layer: ILayer): Promise<string> {

        const url = this.url({ id: layer.id }) + '/picture';

        return trackPromise(
            this.http
                .get<string>(url, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor()),
        );
    }
}

export function mapLayerModelToApiModel(layer: ILayer): ILayerApiModel {
    return {
        id: layer.id,
        name: layer.name,
        picture: layer.picture as unknown as File,
        position: {
            y: layer.top,
            x: layer.left,
        },
        angle: layer.angle,
        scaleX: layer.scaleX,
        scaleY: layer.scaleY,
        opacity: layer.opacity,
    };
}

export function mapToLayerModel(rawLayer: ILayerApiModel): ILayer {
    return {
        id: rawLayer.id,
        name: rawLayer.name,
        picture: rawLayer.picture as unknown as string,
        top: rawLayer.position?.y || 0,
        left: rawLayer.position?.x || 0,
        scaleX: rawLayer.scaleX || 1,
        scaleY: rawLayer.scaleY || 1,
        angle: rawLayer.angle || 0,
        opacity: rawLayer.opacity,
    };
}

export interface ILayerApiModel {
    id: number;
    name: string;
    picture: File;
    position: ILayerPosition | null;
    angle: number | null;
    scaleX: number | null;
    scaleY: number | null;
    opacity: number;
}

interface ILayerPosition {
    y: number;
    x: number;
}
