import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectHrModuleSettingsState = createSelector(
    selectHrModuleState,
    state => state.hrModule,
);

export const selectIsConfigurationBlockActive = createSelector(
    selectHrModuleSettingsState,
    state => state.isConfigurationBlockActive,
);

export const selectAppSettingHrModule = createSelector(
    selectHrModuleSettingsState,
    state => state.moduleStatus,
);
