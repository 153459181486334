import axios, { AxiosStatic, AxiosError } from 'axios';
import UrlPattern from 'url-pattern';
import { appConfig } from '../../config/appConfig';
import { UserActions } from '../actions';
import { history } from '../../helpers';
import { IHeaders, IErrors, IOrder, IJoin, IFilter } from '../interfaces';

/**
 * REST API provider. Provides access to REST API
 *
 * @class ApiProvider
 */
export default class HmiProvider {

    /**
     * Axios HTTP Client
     *
     * @type {AxiosStatic}
     */
    public http: AxiosStatic;

    /**
     * HTTP headers getter
     *
     * @return {IHeaders}
     */
    get headers(): IHeaders {

        const headers: IHeaders = { 'Content-Type': 'application/json' },
            authToken = localStorage.getItem('auth_token');

        if (authToken) {

            headers['Authorization'] = 'Bearer ' + authToken;
        }

        return headers;
    }

    /**
     * The API resource URL pattern
     */
    get urlPattern(): string {

        return '';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [];
    }

    get formDataHeaders(): Record<string, string> {
        return {
            'Content-Type': 'multipart/form-data',
        };
    }

    protected apiEndpoint = appConfig.hrApiEndpoint;

    /**
     * Constructor
     */
    constructor() {

        this.http = axios;
    }

    /**
     * Server errors handler
     *
     * @param {AxiosError} error
     *
     * @return {IErrors}
     */
    errorHandler(error: AxiosError): IErrors {

        if (error.response) {
            //The request was made and the server responded with a status code

            const publicRoutes = [
                /^\/login$/,
                /^\/forgot-password$/,
                /^\/restore\/.*$/,
                /^\/registration\/.*$/,
            ];

            const { pathname } = history.location;

            switch (error.response.status) {

                case 400:
                case 422:

                    return error.response.data;

                case 401:

                    UserActions.logout();

                    if  (publicRoutes.findIndex(route => pathname.search(route) !== -1) === -1) {

                        history.push('/login', { deleted: error.response.data.deleted });
                    }

                    return { errors: { } };

                case 403:

                    return { errors: { common: 'FORBIDDEN' } };

                case 500:

                    return error.response.data;

                default:

                    return { errors: { common: error.response.data.message } };
            }

        } else if (error.request) {
            //The request was made but no response was received

            return { errors: { common: 'UNKNOWN_SERVER_ERROR' } };

        } else {
            //Something happened in setting up the request that triggered an error

            return { errors: { common: 'UNKNOWN_ERROR' } };
        }
    }

    /**
     * Get API resource URL from given params and pattern
     *
     * @param {object} params URL params (optional)
     * @param {string} pattern URL pattern (optional)
     *
     * @return {string}
     */
    url(params = {}, pattern = null): string {

        return this.apiEndpoint + (
            new UrlPattern(pattern || this.urlPattern)
        ).stringify(params);
    }

    /**
     * Prepare search and sort parameters for a grid
     *
     * @param {string} search A search string
     * @param {IOrder} order A sort parameters
     * @param join
     * @param filter
     *
     * @return {URLSearchParams}
     */
    prepareListParams(search: string, order: IOrder, join?: IJoin, filter?: IFilter): URLSearchParams {

        const params = new URLSearchParams();

        if (search) {

            for (const column of this.searchable) {

                params.append('or', column + '||$cont||' + search);
            }
        }

        if (join) {

            join.table.forEach(value => params.append('join', value));

        }

        if (filter) {

            filter.field.map(value => params.append('filter', value));

        }

        params.append('sort', order.column + ',' + order.dir.toUpperCase());

        return params;
    }
}
