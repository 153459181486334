import { Action } from 'redux';
import { ILayer } from '../../interfaces';

export enum LayerActionTypes {
    SAVE_LAYER = '[Layer] Save Layer',
    FETCH_LAYERS_REQUEST = '[Layer] Fetch Layers Request',
    FETCH_LAYERS_SUCCESS = '[Layer] Fetch Layers Success',
    FETCH_LAYERS_FAILURE = '[Layer] Fetch Layers Failure',
    DELETE_LAYER_ACTION = '[Layer] Delete Layer Action',
}

export class FetchLayersRequest implements Action {
    readonly type = LayerActionTypes.FETCH_LAYERS_REQUEST;
}

export class FetchLayersSuccess implements Action {
    readonly type = LayerActionTypes.FETCH_LAYERS_SUCCESS;

    constructor(
        public payload: {
            layers: ILayer[]
        },
    ) {}
}

export class FetchLayersFailure implements Action {
    readonly type = LayerActionTypes.FETCH_LAYERS_FAILURE;

    constructor(
        public payload: {
            error: any
        },
    ) {}
}

export class SaveLayerAction implements Action {
    readonly type = LayerActionTypes.SAVE_LAYER;

    constructor(
        public payload: {
            layer: ILayer,
        },
    ) {}
}

export class DeleteLayerAction implements Action {
    readonly type = LayerActionTypes.DELETE_LAYER_ACTION;

    constructor(
        public payload: {
            layer: ILayer
        },
    ) {
    }
}

export type LayerActions =
    FetchLayersRequest |
    FetchLayersSuccess |
    FetchLayersFailure |
    SaveLayerAction |
    DeleteLayerAction;
