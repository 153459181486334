import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

import { history } from '../../helpers';
import { UserActions } from '../../core/actions';
import { Button, TextInput, CommonError, InfoModal } from '../../core/ui/components';
import { IAuthState, IForgotPasswordProps, IForgotPasswordState } from '../../core/interfaces';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';


interface IFormValues {
    email: string;
}

/**
 * Forgot password page component
 *
 * @class ForgotPassword
 */
class ForgotPassword extends React.Component<IForgotPasswordProps & WithTranslation, IForgotPasswordState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IForgotPasswordProps & WithTranslation) {

        super(props);

        const { t, user } = this.props;

        if (user) {

            history.push('/');
        }

        this.props.resetError();

        this.initialValues = {
            email: '',
        };

        this.validationSchema = yup.object().shape({
            email: yup
                .string()
                .email(t('EMAIL_MUST_BE_A_VALID_EMAIL'))
                .required(t('EMAIL_IS_REQUIRED')),
        });

        this.state = {
            showSuccessModal: false,
        };

        this.handleModalClose = this.handleModalClose.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        document.body.classList.add('login-page');
    }

    /**
     * Component props update handler
     *
     * @param {IProps} nextProps Updated component properties
     */
    componentDidUpdate(nextProps: IForgotPasswordProps) {

        const { success } = this.props;

        if (nextProps.success !== success && success) {

            this.setState({
                showSuccessModal: true,
            });
        }
    }

    /**
     *  Callback is invoked immediately before a component is unmounted and destroyed
     */
    componentWillUnmount() {

        document.body.classList.remove('login-page');
    }

    /**
     * Form initial values
     *
     * @type {IFormValues}
     */
    private readonly initialValues: IFormValues;

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IFormValues>>;

    /**
     * Login form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        this.initialValues.email = values.email;

        this.props.requestPassword(values.email);

    }

    /**
     * Success modal close handler
     */
    handleModalClose() {

        this.setState({
            showSuccessModal: false,
        });

        history.push('/login');
    }


    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, errors = {} } = this.props,
            { showSuccessModal } = this.state;

        return (
            <section className="auth-section">
                <Helmet>
                    <meta name="viewport" content="width=device-width, user-scalable=none" />
                </Helmet>
                <LanguageSwitcher variant={'standard'} class={'login-page'} defaultLanguage={'en'} />
                <div className="container">
                    <InfoModal open={showSuccessModal} onClose={this.handleModalClose}>
                        {t('PASSWORD_RESET_LINK_HAS_BEEN_SENT_TO_YOUR_EMAIL', { email: this.initialValues.email })}
                    </InfoModal>
                    <h1>{t('FORGOT_YOUR_PASSWORD')}</h1>
                    <p className="form-intro">{t('ENTER_YOUR_EMAIL_BELOW_AND_WELL_GET_YOUR_BACK_IN_QIVISOR')}</p>
                    <div className="form-box">
                        <CommonError errors={errors} />
                        <Formik
                            initialValues={this.initialValues}
                            enableReinitialize
                            validationSchema={this.validationSchema}
                            onSubmit={this.handleSubmit}
                        >
                            {props => (
                                <form onSubmit={props.handleSubmit} noValidate>
                                    <TextInput
                                        className={
                                            'form-field ' +
                                            (props.touched.email ? (props.errors.email || errors.email) ? 'error-field' : 'success-field' : '')
                                        }
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                        name="email"
                                        type="email"
                                        placeholder={t('EMAIL')}
                                    >
                                        {((props.touched.email && props.errors.email) || errors.email) &&
                                        <div className="validation-massage">{props.errors.email || errors.email}</div>
                                        }
                                    </TextInput>
                                    <Button
                                        type="submit"
                                        color={'primary'}
                                    >
                                        {t('SEND_RESTORATION_LINK')}
                                    </Button>
                                    <p className="link-box">
                                        <Link to="/login">{t('BACK_TO_SIGN_IN')}</Link>
                                    </p>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: { auth: IAuthState }) => {

    const { user, errors, success } = state.auth;

    return {
        user,
        errors,
        success,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    requestPassword: UserActions.requestPassword,
    resetError: UserActions.logout,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ForgotPassword));
