import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import { Checkbox } from '@material-ui/core';

import { SearchInput } from '../../../core/ui/components';
import Panel from '../../../core/ui/components/Board/Panel';
import MiniMap from '../../../core/ui/components/Board/MiniMap';
import Axis from '../../../core/ui/components/Board/Axis';
import Rule from '../../../core/ui/components/Board/Rule';
import { FormActions, HmiPlayerActions, statesActions } from '../../../core/actions';
import { IStructuralTreeProps, IStructuralTreeState, PlayerMode } from '../../../core/interfaces';

import EditStructuralTree from './EditStructuralTree';
import MonitoringTree from './MonitoringTree';
import StateForm from '../Forms/StateForm/StateForm';
import HistogramSettingsForm from '../Forms/HistogramSettingsForm/HistogramSettingsForm';
import RightSidebar from '../../Sidebar/RightSidebar';
import { ReactComponent as HierarchyViewIcon } from '../../../core/ui/assets/images/icons/hierarchy-view.svg';
import { ReactComponent as EditTreeIcon } from '../../../core/ui/assets/images/icons/edit-tree.svg';
import { ReactComponent as HierarchyViewSelectedIcon } from '../../../core/ui/assets/images/icons/hierarchy-view-active.svg';
import { ReactComponent as EditTreeSelectedIcon } from '../../../core/ui/assets/images/icons/edit-tree-selected.svg';
import { ReactComponent as NavigationResizeButtonIcon } from '../../../core/ui/assets/images/icons/navigation-resize-button.svg';
import { ReactComponent as NavigationResizeButtonRightIcon } from '../../../core/ui/assets/images/icons/navigation-resize-button-right.svg';

import './StructuralTree.scss';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RootState } from '../../../core/store';
import PopUpDetails from './PopUpDetails/PopUpDetails';
import { GraphActions } from '../../../base/store/actions';
import { StateTable } from '../../../base/components';
import StateTableHr from '../../../base/components/Board/StateTableHr';
import { selectMonitoringTreeCollectionByDashboardId } from '../../../core/selectors/monitoringTree/monitoringTreeCollectionSelector';
import {
    selectHmiPlayerMode,
    selectHmiPlayerRealTimeStatus,
    selectHmiPlayerSchema,
} from '../../../core/selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../../core/selectors/hmi/visibilitySelector';
import { selectIsFullScreenDrawer } from '../../../core/selectors/layout/responsiveDrawerSelector';
import { selectMonitoringTree } from '../../../core/selectors/monitoringTree/monitoringTreeSelector';
import { selectSelectedDashboard } from '../../../core/selectors/dashboardSelect/selectedDashboardSelector';


/**
 * Structural tree component
 *
 * @class StructuralTree
 */
class StructuralTree extends React.PureComponent<IStructuralTreeProps & WithTranslation, IStructuralTreeState> {

    constructor(props: IStructuralTreeProps & WithTranslation) {

        super(props);

        this.monitoringTreeRef = React.createRef();
        this.editStructureTreeRef = React.createRef();
        this.sideBarShow = JSON.parse(localStorage.getItem('sidebar') as string);
        this.state = {
            treeData: this.props.monitoringTree,
            selectMode: {
                monitoringMode: true,
            },
            scroll: null,
            searchField: '',
        };

        this.scrollContainer = React.createRef();

        this.deselectAction = this.deselectAction.bind(this);

        this.stopPropagation = this.stopPropagation.bind(this);

        this.checkBoxChangeHandler = this.checkBoxChangeHandler.bind(this);

        this.searchHandler = this.searchHandler.bind(this);

        this.handleChangeMode = this.handleChangeMode.bind(this);

    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        if (this.props.toggleSideBar) {

            this.props.toggleSideBar(this.sideBarShow);
        }
    }

    /**
     * Callback after the component props update
     */
    componentDidUpdate(prevProps: IStructuralTreeProps) {

        if (!this.state.selectMode.monitoringMode && this.props.toggleSideBar) {

            this.props.toggleSideBar(false);
        }

        if (this.props.stateTableVisibility &&
            prevProps.stateTableVisibility &&
            this.props.stateTableVisibility.top &&
            !prevProps.stateTableVisibility.top &&
            isMobile) {

            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`;
        }

        if (this.props.stateTableVisibility && this.props.stateTableVisibility.top === undefined && isMobile) {

            document.body.style.position = '';
            document.body.style.top = '';
        }

        if (this.scrollContainer.current) {

            this.scrollToRef();
        }

    }
    componentWillUnmount() {
        if (this.scrollToRefTimeout) {
            clearTimeout(this.scrollToRefTimeout);
        }
    }

    /**
     * Monitoring Tree Ref
     *
     * @type {React.RefObject<HTMLDivElement>}
     * @private
     */
    private readonly monitoringTreeRef: RefObject<HTMLDivElement>;

    /**
     * EditStructure Tree Ref
     *
     * @type {React.RefObject<HTMLDivElement>}
     * @private
     */
    private readonly editStructureTreeRef: RefObject<HTMLDivElement>;

    /**
     * Sidebar display logic.
     */
    private readonly sideBarShow: boolean;

    /**
     * Ref on scroll container
     *
     * @type {React.RefObject<HTMLDivElement>}
     */
    scrollContainer: RefObject<HTMLDivElement>;

    private scrollToRefTimeout: NodeJS.Timeout | undefined;

    /**
     * Scroll to ref in the current scroll container.
     */
    scrollToRef() {

        const selectedRef = this.props.stateTableVisibility.ref;

        const currentTree = this.monitoringTreeRef.current || this.editStructureTreeRef.current;

        if (selectedRef && currentTree && this.scrollContainer.current) {

            this.scrollToRefTimeout = setTimeout(() => {

                const monitoringTreeTop = currentTree.getBoundingClientRect().top;
                const scrollTop = selectedRef.getBoundingClientRect().top - monitoringTreeTop;

                if (this.scrollContainer.current) this.scrollContainer.current.scrollTo(0, scrollTop);

                if (this.scrollToRefTimeout) {

                    clearTimeout(this.scrollToRefTimeout);
                }
            }, 0);
        }
    }

    /**
     * Edit mode handler
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    handleChangeMode(event: React.ChangeEvent<HTMLInputElement>) {

        const { selectMode } = this.state,
            { modeAction, mode = true } = this.props,
        name:'editMode' | 'monitoringMode' = event.currentTarget.value as 'editMode' | 'monitoringMode';

        if (modeAction) {

            modeAction(!mode);
        }

        selectMode.monitoringMode = name === 'monitoringMode';

        this.props.barToggleTableView(true, 0);

        this.setState({ selectMode });
    }

    /**
     * Deselect Alert and Graph
     */
    deselectAction() {
        const { realTimeStatus, HMIPlayerStatus, realTimeHmi, pause } = this.props;

        this.props.deselectStates();

        this.props.deselectAlertGraph();

        if (realTimeStatus || HMIPlayerStatus) {

            if (HMIPlayerStatus && !realTimeStatus) {

                pause();

            }
            if (HMIPlayerStatus && realTimeStatus) {

                realTimeHmi(false);

            }
        }
    }

    /**
     *  StopPropagation
     */
    stopPropagation(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        event.stopPropagation();
    }

    /**
     * Checkbox Change handler
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event
     * @param {boolean} checked
     */
    checkBoxChangeHandler(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {

        if (this.props.toggleSideBar && this.state.selectMode.monitoringMode) {

            this.props.toggleSideBar(checked);
        }
    }

    /**
     * Search Handler
     */
    searchHandler(value: string) {

        this.props.barToggleTableView(false, 0);

        this.setState({ searchField: value.toLowerCase().trim() });

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { rbac } = this.props,
            show = JSON.parse(localStorage.getItem('sidebar') as string);

        const {
            visibleSideBar = show,
            maxWidthSideBar,
            minimapVisible = true,
            stateTableVisibility,
            formUnit,
            unitForTable,
            graphBarHeight = 40,
            monitoringTree,
            dashboards,
            isFullScreen,
        } = this.props;

        const rootElement: HTMLElement | null = document.getElementById('root');

        if (rootElement) {

            visibleSideBar ? rootElement.classList.remove('sidebar-visible') : rootElement.classList.add('sidebar-visible');

        }

        return (
            <React.Fragment>
                <PopUpDetails />
                <div className={visibleSideBar ? 'structural-tree-wrap close-sidebar' : 'structural-tree-wrap'}
                     onClick={this.deselectAction}
                >
                    <div
                        className={minimapVisible ? 'header-structural-tree' : 'header-structural-tree minimapVisible'}
                    >
                        <Checkbox
                            icon={<NavigationResizeButtonIcon style={{ height: 24, width: 24 }} />}
                            checkedIcon={
                                <NavigationResizeButtonRightIcon
                                    style={{ height: 24, width: 24 }}
                                />
                            }
                            value="hideSidebar"
                            onClick={this.stopPropagation}
                            onChange={this.checkBoxChangeHandler}
                            checked={visibleSideBar}
                            className={`hide-sidebar ${!this.state.selectMode.monitoringMode ? 'edit-page' : ''} ${visibleSideBar ? 'close' : 'open'}`}
                            disableRipple
                            disabled={!this.state.selectMode.monitoringMode}
                            style={{
                                left: visibleSideBar ? 60 : 306,
                            }}
                        />
                        <div
                            className={visibleSideBar ? 'header-structural-tree-left' : 'header-structural-tree-left open'}
                            style={{
                                maxWidth: maxWidthSideBar,
                                minWidth: maxWidthSideBar,
                            }}
                            onClick={this.deselectAction}
                        >
                            <div className="search-group" style={{ maxWidth: 190, width: '100%' }}
                                 hidden={visibleSideBar}
                            >
                                <SearchInput
                                    searchHandler={this.searchHandler}
                                    options={{}}
                                    placeholder={this.props.t('SEARCH')}
                                />
                            </div>
                            <div className={visibleSideBar ? 'btn-group hidden' : 'btn-group'}>
                                <Checkbox
                                    icon={<EditTreeIcon className="default-icon-size p4" />}
                                    checkedIcon={<EditTreeSelectedIcon className="default-icon-size p4" />}
                                    value="editMode"
                                    onClick={this.stopPropagation}
                                    onChange={this.handleChangeMode}
                                    checked={!this.state.selectMode.monitoringMode}
                                    className={'editMode'}
                                    disableRipple
                                    disabled={!rbac.can('monitoring-tree:update')}
                                />
                                <Checkbox
                                    icon={<HierarchyViewIcon className="default-icon-size" />}
                                    checkedIcon={<HierarchyViewSelectedIcon className="default-icon-size" />}
                                    value="monitoringMode"
                                    onClick={this.stopPropagation}
                                    onChange={this.handleChangeMode}
                                    checked={this.state.selectMode.monitoringMode}
                                    className={'monitoringMode'}
                                    disableRipple
                                    disabled={!rbac.can('monitoring-tree:update')}
                                />
                            </div>
                        </div>
                        <div className={`header-structural-tree-right ${isFullScreen ? 'hmi-full-screen' : ''}`}>
                            <Panel />
                            <MiniMap />
                            <Axis />
                            <Rule />
                        </div>
                    </div>
                    <div
                        ref={this.scrollContainer}
                        id="structural-tree-slider"
                        className={
                            `structural-tree-slider ${isMobile ? 
                                'mobile' : 
                                'desktop'
                            } ${minimapVisible ? 
                                'minimap-visible' : 
                                'minimap-invisible'
                            } ${stateTableVisibility.top ? 
                                'scrollTop-hidden' : 
                                'scrollTop-scroll'
                            } ${isIOS ?
                                'is-ios' :
                                isAndroid ? 
                                    'is-android' : 
                                    ''
                            }`
                            // ${(realTimeStatus || HMIPlayerStatus) && isVisibleHMI?
                            //     'pointer-event-none' :
                            //     ''}`
                        }
                    >
                        {!this.state.selectMode.monitoringMode ?
                            <EditStructuralTree
                                structureTreeRef={this.editStructureTreeRef}
                                treeData={monitoringTree && dashboards.length > 0 ? monitoringTree: []}
                                visibility={!this.state.selectMode.monitoringMode}
                                visibleSideBar={visibleSideBar}
                                maxWidthSideBar={maxWidthSideBar}
                                scrollHeight={stateTableVisibility.top? '2000vh': 'auto'}
                                searchField={this.state.searchField}
                            />
                            :
                            <MonitoringTree
                                structureTreeRef={this.monitoringTreeRef}
                                treeData={monitoringTree && dashboards.length > 0 ? monitoringTree: []}
                                visibility={this.state.selectMode.monitoringMode}
                                visibleSideBar={visibleSideBar}
                                maxWidthSideBar={maxWidthSideBar}
                                scrollHeight={stateTableVisibility.top? '2000vh': 'auto'}
                                searchField={this.state.searchField}
                            />
                        }
                    </div>

                    {stateTableVisibility && stateTableVisibility.visible && unitForTable ?
                        stateTableVisibility.hrMode?
                        <StateTableHr
                            title={''}
                            style={{ paddingLeft: maxWidthSideBar, top: minimapVisible? 122 + graphBarHeight: 66 + graphBarHeight }}
                        />
                        :
                        <StateTable
                            title={unitForTable.tableTitle ? unitForTable.tableTitle : ''}
                            style={{ paddingLeft: maxWidthSideBar, top: minimapVisible? 122 + graphBarHeight: 66 + graphBarHeight }}
                            unit={unitForTable}
                        />
                    :
                        null
                    }
                </div>
                <RightSidebar
                    data={{}}
                    openSidebar={this.props.openSidebar && (this.props.formName === 'stateForm' || this.props.formName === 'histogramForm')}
                >
                    {this.props.formName && Boolean(this.props.formData) && this.props.formName === 'stateForm' &&
                    <StateForm
                        model={this.props.formData}
                        dataUnit={formUnit?formUnit: null}
                    />
                    }
                    {this.props.formName && this.props.formName === 'histogramForm' &&
                    <HistogramSettingsForm model={this.props.formModel? this.props.formModel: null} />
                    }
                </RightSidebar>

            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { auth } = state,
        { dashboards, screenWidth } = state.dashboard;
    const monitoringTree = selectMonitoringTree(state);
    const selectedDashboard = selectSelectedDashboard(state);
    const monitoringTreeCollection = selectMonitoringTreeCollectionByDashboardId(state, selectedDashboard?.id);


    const realTimeStatus = selectHmiPlayerRealTimeStatus(state);
    const HMIPlayerStatus = selectHmiPlayerMode(state);
    const schema = selectHmiPlayerSchema(state);
    const isVisibleHMI = selectHmiPlayerVisibility(state) && schema !== null;

    const isFullScreen = selectIsFullScreenDrawer(state) || false;

    return {
        selectedDashboard,
        visibleSideBar: state.graphStructuralTreeVisibility.visible,
        maxWidthSideBar: state.graphStructuralTreeVisibility.maxWidth,
        minimapVisible: state.graphMinimapVisibility.visible,
        stateTableVisibility: state.graphBarTableVisibility,
        unitForTable: state.graphBarTableVisibility.unit,
        openSidebar: state.form.formOpened,
        formName: state.form.formName,
        formModel: state.form.formModel, //that is the optimal way to pass a model to form values
        formData: state.stateChange.state, //in that way you need to call one more method to store a model in the storage + one more reducer. TODO: use the optimal way.
        formUnit: state.stateChange.unit,
        mode: state.graphHistogramHeight.mode,
        rbac: auth.rbac,
        screenWidth,
        histogramHeight: state.graphHistogramHeight.height,
        graphBarHeight: state.graphBarHeight.height,
        monitoringTree: monitoringTreeCollection || monitoringTree,
        dashboards,
        realTimeStatus,
        HMIPlayerStatus: HMIPlayerStatus === PlayerMode.MODE_PLAY,
        isVisibleHMI,
        isFullScreen,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleSideBar: GraphActions.sideBarSTToggle,
    toggleForm: FormActions.toggle,
    modeAction: GraphActions.histogramToggleMode,
    barToggleTableView: GraphActions.barToggleTableView,
    deselectStates: statesActions.deselectAllStates,
    deselectAlertGraph: GraphActions.deselectAlertGraph,
    pause: HmiPlayerActions.pause,
    realTimeHmi: HmiPlayerActions.setRealTime,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StructuralTree));

