import React, { useCallback } from 'react';
import { FormikValues } from 'formik';
import { TextInput } from '../../../core/ui/components';
import { ISensor } from '../../../core/interfaces';

interface IActionFieldForFormProps {
    formProps: FormikValues;
    enableInput: boolean;
    name: string;
    placeholder: string;
    maxLength?: number;
    handleChangeClickInput: (name: string, status: boolean) => void;
    handleSubmit: (data: ISensor) => void;
    maxWidth?: number | string;
}

const ActionFieldForForm: React.FC<IActionFieldForFormProps> = (
    {
        formProps,
        enableInput,
        name,
        placeholder,
        maxLength = 30,
        handleChangeClickInput,
        handleSubmit,
        maxWidth = 'inherit',
    }: IActionFieldForFormProps,
) => {

    const onFocusCallback = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.target.selectionStart = event.target.value.length;

        event.target.selectionEnd = event.target.value.length;

    }, []);

    const onBlurCallback = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        formProps.handleBlur(event);
        if (!enableInput) {
            handleChangeClickInput(name, true);
            formProps.handleReset();
        }

    }, [handleChangeClickInput, formProps, enableInput, name]);

    const onMouseLeaveCallback = useCallback(() => {

        if (!enableInput) {
            handleChangeClickInput(name, true);
            formProps.handleReset();
        }

    }, [handleChangeClickInput, formProps, enableInput, name]);

    const onKeyPressCallback = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {

        if (event.key === 'Enter') {
            handleSubmit(formProps.values);

            handleChangeClickInput(name, true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleSubmit, formProps, handleChangeClickInput]);

    return (
        <TextInput
            className={
                'form-field '
            }
            onFocus={onFocusCallback}
            autoFocus
            onChange={formProps.handleChange}
            onBlur={onBlurCallback}
            value={formProps.values[name] || ''}
            onMouseLeave={onMouseLeaveCallback}
            onKeyPress={onKeyPressCallback}
            inputProps={{
                'maxLength': maxLength,
            }}
            name={name}
            type="text"
            placeholder={placeholder}
            style={{ maxWidth: maxWidth }}
        />
    );
};

export default ActionFieldForForm;
