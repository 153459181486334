export const userConstants = {
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

    TYPING_PASSWORD: 'TYPING_PASSWORD',

    LOGOUT: 'USERS_LOGOUT',

    PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

    LIST_SUCCESS: 'USER_LIST_SUCCESS',
    LIST_FAILURE: 'USER_LIST_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'USER_FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'USER_FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'USER_FORGOT_PASSWORD_FAILURE',

    SET_PASSWORD_SUCCESS: 'USER_SET_PASSWORD_SUCCESS',
    SET_PASSWORD_FAILURE: 'USER_SET_PASSWORD_FAILURE',

    STORE_SUCCESS: 'USER_STORE_SUCCESS',
    STORE_FAILURE: 'USER_STORE_FAILURE',

    UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_DELETE_FAILURE',
};
