import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { graphConstants } from '../../constants';

const selectGraphBarHeightReducer = (state: RootState) => state.graphBarHeight;

export const selectGraphBarHeight = createSelector(
    selectGraphBarHeightReducer,
    (graphBarHeight) => graphBarHeight.height || graphConstants.barSmallHeight,
);
