import React, { useCallback, useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as ZoomInIcon } from '../../../../core/ui/assets/images/icons/plus-icon-noborder.svg';
import { FormDialog, Button, InfoModal } from '../../../../core/ui/components';
import { ReactComponent as AddIcon } from '../../../../core/ui/assets/images/icons/add.svg';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateUtils from '@date-io/moment';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    IProduct,
    IProductCategory,
    IProductData,
    IUser,
} from '../../../../core/interfaces';
import { FormActions, ProductActions } from '../../../../core/actions';
import { history } from '../../../../helpers';

import './ChangeProduct.scss';
import MenuItemProduct from './MenuItem';
import TimeGroupItem from './TimeGroupItem';
import { selectProductSortedByCategory } from '../../../../core/selectors/product/productSortedByCategorySelector';
import {
    selectActiveProductInCurrentTime,
} from '../../../../core/selectors/unitActiveProducts/activeProductDataSelector';
import { selectAllProducts } from '../../../../core/selectors/product/productsSelector';
import { selectUnitProductsUnit } from '../../../../core/selectors/unitProducts/unitProductsSelector';
import { selectCurrentUser } from '../../../../core/selectors/auth/authSelector';

interface IProps {
    unitId: string | number;
}

interface IForm {
    startTime: Date;
    endTime: Date;
    product: number;
    unit: string | number;
    enableEndTime: boolean;
}

interface IFilteredData {
    filteredProduct: IProduct[];
    filteredCategory: IProductCategory[];
    countOfProduct: number;
}

const ChangeProduct: React.FC<IProps> = (
    {
        unitId,
    }: IProps,
) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const products = useSelector(selectAllProducts);
    const productData = useSelector(selectUnitProductsUnit) as IProductData[];
    const deactivateProduct = useSelector(selectActiveProductInCurrentTime);
    const user = useSelector(selectCurrentUser) as IUser | undefined;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [selectProduct, setSelectProduct] = React.useState<IProduct | null>(null);

    const [initialValues, setInitialValues] = React.useState<IForm>({
        startTime: new Date(),
        endTime: new Date(),
        product: 0,
        unit: unitId,
        enableEndTime: false,
    });

    const [timeGroupItemErrorStart, setTimeGroupItemErrorStart] = useState<boolean>(false);

    const [timeGroupItemErrorEnd, setTimeGroupItemErrorEnd] = useState<boolean>(false);

    const category = useSelector(selectProductSortedByCategory);

    const [filteredData, setFilteredData] = useState<IFilteredData>({
        filteredProduct: [],
        filteredCategory: [],
        countOfProduct: 0,
    });

    const [info, setInfo] = useState(false);

    const validationSchema = yup.object().shape({
        startTime: yup.date(),
        endTime: yup.date(),
        product: yup.number(),
        unit: yup.number(),
    });

    /**
     *  Popup handler. Set anchor element
     *
     * @param event
     */
    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {

        setAnchorEl(event.currentTarget);

        setInitialValues({
            startTime: new Date(),
            endTime: new Date(),
            product: 0,
            unit: unitId,
            enableEndTime: false,
        });

        setTimeGroupItemErrorStart(false);

        setTimeGroupItemErrorEnd(false);

    }, [unitId, setInitialValues, setTimeGroupItemErrorStart, setTimeGroupItemErrorEnd, setAnchorEl]);

    /**
     * Popup handler. Unset anchor element.
     */
    const handleClose = useCallback(() => {

        setAnchorEl(null);

    }, [setAnchorEl]);

    /**
     * Handler the form submit
     *
     * @param value
     */
    const handleSubmit = useCallback((value: IForm) => {

        if (value.enableEndTime) {

            dispatch(ProductActions.activateProduct({
                startTime: moment.utc(value.startTime)
                    .set('seconds', 0)
                    .set('milliseconds', 0).toDate(),
                endTime: moment.utc(value.endTime)
                    .set('seconds', 0)
                    .set('milliseconds', 0).toDate(),
                product: value.product,
                unit: parseInt(value.unit.toString()),
            }));

        } else {

            dispatch(ProductActions.activateProduct({
                startTime: moment.utc(value.startTime)
                    .set('seconds', 0)
                    .set('milliseconds', 0).toDate(),
                product: value.product,
                unit: parseInt(value.unit.toString()),
            }));

        }

        setSelectProduct(null);

    }, [setSelectProduct, dispatch]);

    /**
     * Error handling function for start time
     */
    const errorHandlerStartTime = useCallback((event: boolean) => {

        return setTimeGroupItemErrorStart(event);

    }, [setTimeGroupItemErrorStart]);

    /**
     * Error handling function for end time
     */
    const errorHandlerEndTime = useCallback((event: boolean) => {

        return setTimeGroupItemErrorEnd(event);

    }, [setTimeGroupItemErrorEnd]);

    /**
     * Handler choosing product
     *
     * @param value
     */
    const chooseHandler = useCallback((value: number) => {

        initialValues.product = value;

        setInitialValues(initialValues);

    }, [initialValues, setInitialValues]);

    /**
     * Checking whether the current time range is busy.
     *
     * @param {IForm} values
     */
    const handleDateChange = useCallback((values: IForm) => {

        setInitialValues(values);

        dispatch(ProductActions.getActivateProduct({
            id: parseInt(String(values.unit)),
            startTime: moment.utc(values.startTime).format(),
            endTime: values.enableEndTime ? moment.utc(values.endTime).format() : moment.utc(new Date()).format(),
        }));

    }, [setInitialValues, dispatch]);

    /**
     * Filtered category of product
     *
     * @param {IProductData} data
     *
     * @return { filteredProduct, filteredCategory }
     */
    const productWithCategory = useCallback((data: IProductData[]): IFilteredData => {

        const currentUnit = data.find(unit => unit.id === unitId);
        const productLength: number[] = [];

        const filteredProduct: typeof products = products.filter(value => {

            return currentUnit ? currentUnit.products.find(dataValue => dataValue.id === value.id) : [];
        });

        productLength.push(filteredProduct.length);

        const filteredCategory: typeof category = category.map(category => {

            const products = category.products?.filter(product => {

                return currentUnit ? currentUnit.products.find(dataValue => dataValue.id === product.id) : [];
            });

            if (products) {

                productLength.push(products.length);

            }

            return { ...category, products };
        });

        return {
            filteredProduct, filteredCategory, countOfProduct: Math.max(...productLength),
        };

    }, [category, unitId, products]);

    /**
     * Product filtration.
     */
    useEffect(() => {

        if (productData && category) {

            setFilteredData(productWithCategory(productData));

        }

    }, [productWithCategory, category, setFilteredData, productData]);

    /**
     * "Add new" action for link
     */
    const addNew = useCallback(() => {

        if (user && user.role === 'super') {

            dispatch(FormActions.toggleDialog(true));

            dispatch(FormActions.toggle(true));

            if (user.role === 'super') {

                history.push('/configuration');

            } else {
                setInfo(true);
            }
        }
    }, [dispatch, user, setInfo]);

    /**
     * Close modal "the info"
     */
    const closeInfoModal = useCallback(() => {

        setInfo(false);

        dispatch(FormActions.toggle(false));

    }, [setInfo, dispatch]);

    /**
     *  Sort product by orderInCategory
     *
     * @param {IProduct[]} value
     * @return {IProduct[]}
     */
    const sortOrderInCategory = (value: IProduct[]) => {

        return value.sort((a, b) => {

            if (a.position === undefined) {

                a.position = 0;
            }

            if (b.position === undefined) {

                b.position = 0;
            }

            return a.position < b.position ? -1 : 1;
        });

    };

    /**
     * Reset selection product
     *
     */
    const reset = useCallback(() => {

        setSelectProduct(null);

        dispatch(FormActions.toggle(false));

    }, [setSelectProduct, dispatch]);

    const preloadProductCategory = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        event.preventDefault();

        if (unitId) {

            dispatch(ProductActions.getProductByUnitId(Number(unitId)));
        }

    }, [unitId, dispatch]);


    const open = Boolean(anchorEl);
    const id = open ? `select-product-line-${unitId}` : undefined;

    return (
        <div
            className="product-select-wrap-monitor"
        >
            <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                size={'small'}
                onClick={handleClick}
                onMouseEnter={preloadProductCategory}
                disableFocusRipple
                disableElevation
                className={anchorEl ? 'selected button' : 'button'}
            >
                {anchorEl ? t('CHANGE_PRODUCT')
                    :
                    <ZoomInIcon className="default-icon-size-small" />
                }
            </Button>
            {open ?
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={'product-select-monitor'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{ paper: 'ul-wrap' }}
            >
                {filteredData.filteredCategory.map(value => {
                    if (value.products && value.products?.length > 0) {
                        return (
                            <React.Fragment key={value.id}>
                                <MenuItem
                                    disableRipple
                                    disableTouchRipple
                                    className={'title'}
                                >
                                    {value.id ? value.name : t('UNSORTED')}
                                </MenuItem>
                                {sortOrderInCategory(value.products).map(product => (
                                    <MenuItemProduct
                                        key={product.id}
                                        product={product}
                                        chooseHandler={chooseHandler}
                                        resetAnchorEl={setAnchorEl}
                                        selectProduct={setSelectProduct}
                                    />
                                ))}
                            </React.Fragment>);
                    }
                    return null;
                })}
                {filteredData.countOfProduct === 0 &&
                <MenuItem>
                    {t('NO_PRODUCTS')}
                </MenuItem>}
                {user && user.role === 'super' &&
                <MenuItem
                    disableRipple
                    disableTouchRipple
                    component={Link}
                    to={user.role === 'super' ? '/configuration' : '/'}
                    onClick={addNew}
                >
                    <AddIcon className="default-icon-size m8" />
                    {t('ADD_NEW')}
                </MenuItem>}
            </Popover> : null}
            {selectProduct && selectProduct.name ?
                <FormDialog
                    open={Boolean(selectProduct)}
                    heading={`${t('ACTIVATE')} "${selectProduct ? selectProduct.name : ''}"?`}
                >
                    <div className={'body-wrap'}>
                        <div className="text">
                            {deactivateProduct && deactivateProduct.length === 1 ?
                                t('THIS_ACTION_WILL_DEACTIVATE', { name: deactivateProduct[0].product.name })
                                : null
                            }
                            {deactivateProduct && deactivateProduct.length > 1 ?
                                t('THIS_ACTION_WILL_DEACTIVATE_CHANGE', {
                                    name: deactivateProduct.map((value) => {
                                        return ` ${value.product.name}`;

                                    }),
                                })
                                : null}
                        </div>
                        <div className="time-wrap">
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}

                            >
                                {(props: FormikProps<IForm>) => (
                                    <form onSubmit={props.handleSubmit}
                                          noValidate
                                    >
                                        <Grid container className={'time-group'}>
                                            <div className="time-group-item">
                                                <div className="label start-time"> {t('START_TIME')} *</div>
                                                <MuiPickersUtilsProvider
                                                    utils={DateUtils}
                                                    locale={'en-gb'}
                                                    libInstance={moment}
                                                >
                                                    <TimeGroupItem
                                                        value={props.values.startTime}
                                                        maxDate={props.values.endTime}
                                                        datePickerClass={'calendar-picker-startDate'}
                                                        datePickerId={'date-picker-start-date'}
                                                        enableEndTime={props.values.enableEndTime}
                                                        datePosition={'startDate'}
                                                        handleDateChange={handleDateChange}
                                                        values={props.values}
                                                        errorHandler={errorHandlerStartTime}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="form-group error">
                                                {((props.touched.startTime && props.errors.startTime)) &&
                                                <div className="validation-massage">{props.errors.startTime}</div>
                                                }
                                            </div>
                                            <div className="time-group-item">
                                                <div className="label">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color={'secondary'}
                                                                checked={props.values.enableEndTime}
                                                                onChange={props.handleChange}
                                                                name={'enableEndTime'}
                                                                style={{ margin: 0, padding: 8 }}
                                                            />
                                                        }
                                                        label={t('END_TIME')}
                                                    />
                                                </div>
                                                <MuiPickersUtilsProvider
                                                    utils={DateUtils}
                                                    locale={'en-gb'}
                                                    libInstance={moment}
                                                >
                                                    <TimeGroupItem
                                                        value={props.values.endTime}
                                                        minDate={props.values.startTime}
                                                        maxDate={new Date()}
                                                        datePickerClass={'calendar-picker-endDate'}
                                                        datePickerId={'date-picker-end-date'}
                                                        enableEndTime={props.values.enableEndTime}
                                                        datePosition={'endDate'}
                                                        handleDateChange={handleDateChange}
                                                        values={props.values}
                                                        errorHandler={errorHandlerEndTime}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="form-group error">
                                                {((props.touched.endTime && props.errors.endTime)) &&
                                                <div className="validation-massage">{props.errors.endTime}</div>
                                                }
                                            </div>
                                        </Grid>
                                        <div className="form-group btn-group">
                                            <Button
                                                type="reset"
                                                color={'primary'}
                                                onClick={reset}
                                            >
                                                {t('CANCEL')}
                                            </Button>
                                            <Button
                                                type="submit"
                                                color={'secondary'}
                                                disabled={timeGroupItemErrorStart || (props.values.enableEndTime ? timeGroupItemErrorEnd : false)}
                                            >
                                                {t('ACTIVATE')}
                                            </Button>

                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </FormDialog>
                : null
            }
            <InfoModal
                open={info}
                onClose={closeInfoModal}
            >
                {t('AVAILABLE_FOR_SUPER_USER_ONLY_CHANGE_PRODUCT')}
            </InfoModal>
        </div>
    );
};

export default ChangeProduct;
