import { InteractionHandler as BaseInteractionHandler } from '../../../../../../base/components/Editor/canvas/handlers/InteractionHandler';
import { editorConstants } from '../../constants';

export class InteractionHandler extends BaseInteractionHandler {

    /**
     * Check if handler is switched to drawing mode
     *
     * @returns {boolean}
     */
    isDrawingMode(): boolean {

        return this.handler.interactionMode === editorConstants.interaction.modePolygon
            || this.handler.interactionMode === editorConstants.interaction.modeEngine
            || this.handler.interactionMode === editorConstants.interaction.modeSensor
            || this.handler.interactionMode === editorConstants.interaction.modeValve
            || this.handler.interactionMode === editorConstants.interaction.modeIndicator
            || this.handler.interactionMode === editorConstants.interaction.modeRectangle
            || this.handler.interactionMode === editorConstants.interaction.modeOval
            || this.handler.interactionMode === editorConstants.interaction.modeTriangle
            || this.handler.interactionMode === editorConstants.interaction.modeStar;
    }
}
