import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectEditorBuildingState = createSelector(
    selectHrModuleState,
    state => state.editorBuildingState,
);

export const selectEditorBuildings = createSelector(
    selectEditorBuildingState,
    state => state.buildings,
);
