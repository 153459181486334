import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { IUser } from '../../interfaces';

const selectAuth = (state: RootState) => state.auth;

export const selectCurrentUser = createSelector(
    selectAuth,
    selectAuth => selectAuth.user as IUser,
);

export const selectRBAC = createSelector(
    selectAuth,
    selectAuth => selectAuth.rbac,
);
