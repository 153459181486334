import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ScrollContainer } from '../../../ui/components/ScrollContainer/ScrollContainer';
import { isMobile } from 'react-device-detect';
import BeaconTable from './BeaconTable/BeaconTable';
import { IFilter, IOrder } from '../../../../../core/interfaces';
import { IBeacon, IJoin } from '../../../interfaces';
import { selectBeacons } from '../../../store/selectors/beaconSelector';
import { IHrState } from '../../../store/reducers';
import { BeaconThunks } from '../../../store/thunks/beaconThunks';
import { EmployeeThunks } from '../../../store/thunks/employeeThunks';
import LongTextSnackbar from '../../../ui/components/Message/Message';

import './beacon.scss';
import { SettingPageThunks } from '../../../store/thunks/settingPageThunks';

interface IProps {
    beacons: IBeacon[];
    isLandscape: boolean;
    fetchBeacons: (search: string, order: IOrder, join?: IJoin, filter?: IFilter) => void;
    fetchEmployees: () => void;
    toggleForm: (formOpening: boolean, formName?: string) => void;
}

/**
 * Beacon component
 *
 * @class Beacon
 */
class Beacon extends React.Component<IProps & WithTranslation> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);
        this.isLandscape = this.isLandscape.bind(this);
    }

    componentDidMount() {

        this.props.fetchBeacons('', {
            column: 'id',
            dir: 'asc',
        }, { table: ['employee'] });

        this.props.fetchEmployees();
    }

    componentWillUnmount() {

        this.props.toggleForm(false, '');
    }


    /**
     * Chek landscape.
     *
     * @return {boolean}
     */
    isLandscape() {

        return (window.orientation === 90 || window.orientation === -90);
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t } = this.props;

        return (
            <React.Fragment>
                <div className="wrapper with-list-component">
                    <ScrollContainer
                        maxHeightSlider={`calc(100vh - ${isMobile ? this.isLandscape() ? 40 : 220 : 40}px)`}
                        bodyStyle={{
                            paddingTop: 10,
                        }}
                        headerScroll={
                            <React.Fragment>
                                <h2 className={'content-title beacon'}>
                                    {t('BEACONS')}
                                    <LongTextSnackbar message={t('BEACON_MESSAGE_INFO')} hidden />
                                </h2>
                            </React.Fragment>
                        }
                    >
                        <BeaconTable />
                    </ScrollContainer>
                </div>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: IHrState) => {

    const beacons = selectBeacons(state);

    return {
        beacons,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    fetchBeacons: BeaconThunks.fetchBeacon,
    fetchEmployees: EmployeeThunks.fetchEmployees,
    toggleForm: SettingPageThunks.toggleForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Beacon));
