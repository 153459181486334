import {
    SaveBuildingAction,
    FetchBuildingsRequest,
    FetchBuildingsSuccess,
    FetchBuildingsFailure,
    DeleteBuildingAction,
    ClearBuildingsAction,
} from '../actions/buildingActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IBuildingApiModel } from '../../services/buildingService';
import { BuildingService } from '../../services/buildingService';
import { IOrder } from '../../../../core/interfaces';
import { ILocation } from '../../interfaces';

/**
 * Building thunks
 */
export const buildingThunks = {

    /**
     * Fetch list of buildings
     *
     * @param {ILocation} location
     * @param {string} search
     * @param {IOrder} order
     *
     * @returns {Promise<void>}
     */
    fetchBuildings: (location: ILocation, search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            dispatch(new FetchBuildingsRequest());

            const service = new BuildingService();

            const filter = 'plan.id||$eq||' + location.id;

            const buildings = await service.list(search, order, undefined, { field: [filter] });

            dispatch(new FetchBuildingsSuccess({ buildings }));

        } catch (error) {

            dispatch(new FetchBuildingsFailure({ error }));
        }
    },

    /**
     * Delete building
     *
     * @param {IBuildingApiModel} building
     *
     * @returns {Promise<void>}
     */
    deleteBuilding: (building: IBuildingApiModel) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new BuildingService();

            service.delete(building);

            dispatch(new DeleteBuildingAction({ building }));

        } catch (error) {

            //nothing to do
        }
    },

    /**
     * Save building
     *
     * @param {IBuildingApiModel} model
     */
    saveBuilding: (model: IBuildingApiModel) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        // eslint-disable-next-line no-useless-catch
        try {

            const service = new BuildingService();

            const building = await service.save(model);

            dispatch(new SaveBuildingAction({ building }));

        } catch (error) {

            throw error;
        }
    },

    /**
     * Clear buildings state
     */
    clearBuildings: () => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {
     
        dispatch(new ClearBuildingsAction());
    },
};
