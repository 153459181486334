import store from '../store';
import { selectGraphBarTableVisible } from '../selectors/graphBarTableVisibility/graphBarTableVisibilitySelector';

let lastScrollbarWidth = 0;

export const calculateScrollbarWidth = (): number => {

    const state = store.getState();

    const graphBarTableVisible = selectGraphBarTableVisible(state);


    const element = document.getElementById('structural-tree-slider');

    if (graphBarTableVisible) {

        return lastScrollbarWidth;
    }

    if (!element) {
        const currentScrollbarWidth = window.innerWidth - document.body.clientWidth;
        if (currentScrollbarWidth !== 0) lastScrollbarWidth = currentScrollbarWidth;
        // Return the body scrollbar width, when no element was specified.
        return lastScrollbarWidth;
    } else {


        const currentScrollbarWidth = element.offsetWidth - element.clientWidth;
        if (currentScrollbarWidth !== 0) lastScrollbarWidth = currentScrollbarWidth;
        // When an element is specified, return its specific scrollbar width.
        return lastScrollbarWidth;
    }

};
