import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IAlertRuleApi } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with auth API resources
 *
 * @class AppSetting
 */
export class alertService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/alert-rules(/:id)';
    }

    /**
     * Create alert-rules
     *
     *
     * @param {IAlertRuleApi} alert
     * @return {Promise<AxiosResponse<T>>}
     */
    store<T>(alert: IAlertRuleApi): Promise<AxiosResponse<T>> {

        return trackPromise(
            this.http
                .post(this.url(), alert, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Bulk alert-rules create
     *
     *
     * @param {IAlertRuleApi[]} alerts
     * @return {Promise<AxiosResponse<T>>}
     */
    bulk<T>(alerts: IAlertRuleApi[]): Promise<AxiosResponse<T>> {

        return trackPromise(
            this.http
                .post(`${this.url()}/bulk`, { bulk: alerts }, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Update causes
     *
     * @param {IAlertRuleApi} alerts
     *
     * @return {Promise<Object>}
     */
    update<T>(alerts: IAlertRuleApi): Promise<AxiosResponse<T>> {

        return trackPromise(
            this.http
                .patch(this.url({ id: alerts.id }), alerts, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * bulk update causes
     *
     * @param {IAlertRuleApi} alerts
     *
     * @return {Promise<Object>}
     */
    bulkUpdate<T>(alerts: IAlertRuleApi[]): Promise<AxiosResponse<T>> {

        return trackPromise(
            this.http
                .patch(`${this.url()}/bulk`, { bulk: alerts }, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove alert-rule by ID
     *
     * @param {IAlertRuleApi} alerts
     *
     * @return {Promise<Object>}
     */
    remove<T>(alerts: IAlertRuleApi): Promise<AxiosResponse<T>> {

        return trackPromise(
            this.http
                .delete(this.url({ id: alerts.id }), {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove alert-rule by ID
     *
     * @param {IAlertRuleApi} alerts
     *
     * @return {Promise<Object>}
     */
    bulkRemove<T>(alerts: IAlertRuleApi[]): Promise<AxiosResponse<T>> {

        const removeData = { bulk: alerts.map(value => value.id) };

        return trackPromise(
            this.http
                .delete(this.url({}, '/alert-rules/bulk'), {
                    headers: this.headers,
                    data: removeData,
                }),
        );
    }

}