import { formConstants, sensorConstants } from '../../constants';
import { IEditSensorAction, IEditSensorState } from '../../interfaces';

/**
 * sensor reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function editSensor(state: IEditSensorState = {
    errors: {},
    loading: false,
    openSidebar: false,
    sensor: null,
}, action: IEditSensorAction): IEditSensorState {

    switch (action.type) {

        case sensorConstants.EDIT_OPEN_RIGHT_SIDEBAR:
            return {
                loading: true,
                sensor: action.sensor,
                openSidebar: action.open,
                errors: {},
            };

        case sensorConstants.EDIT_CLOSE_RIGHT_SIDEBAR:
            return {
                loading: false,
                openSidebar: action.open,
                sensor: null,
                errors: {},
            };

        case sensorConstants.EDIT_FAILURE:
            return {
                ...state,
                errors: action.errors,
            };

        case formConstants.CLOSE:
            return {
                ...state,
                errors: {},
            };

        default:

            return state;
    }
}