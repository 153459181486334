import { GatewayActions, GatewayActionTypes } from '../actions/gatewayActions';
import { IGatewayApiModel } from '../../services/gatewayService';
import { IError } from '../../interfaces';

export interface IGatewayState {
    isLoading: boolean;
    gateways: IGatewayApiModel[];
    error: IError | null;
}

export const initialState: IGatewayState = {
    isLoading: false,
    gateways: [],
    error: null,
};

export function gatewayReducer(state = initialState, action: GatewayActions): IGatewayState {

    switch (action.type) {

        case GatewayActionTypes.FETCH_GATEWAYS_REQUEST: {

            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case GatewayActionTypes.FETCH_GATEWAYS_SUCCESS: {

            const { gateways } = action.payload;

            return {
                ...state,
                isLoading: false,
                gateways,
            };
        }

        case GatewayActionTypes.FETCH_GATEWAYS_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case GatewayActionTypes.DELETE_GATEWAY_ACTION: {

            const modifyGateways = state.gateways.filter(zone => zone.id !== action.payload.gateway.id);

            return {
                ...state,
                 gateways: modifyGateways,
            };
        }

        case GatewayActionTypes.DELETE_GATEWAY_EDITOR_ACTION: {

            return {
                ...state,
            };
        }

        case GatewayActionTypes.SAVE_GATEWAY_EDITOR_ACTION: {

            return {
                ...state,
            };
        }

        case GatewayActionTypes.SAVE_GATEWAY_ACTION: {

            const modifyGateways = state.gateways.map((zone) => zone.id === action.payload.gateway.id ? action.payload.gateway : zone);

            if (modifyGateways.findIndex(zone => zone.id === action.payload.gateway.id) === -1) modifyGateways.push(action.payload.gateway);

            return {
                ...state,
                gateways: modifyGateways,
            };
        }

        case GatewayActionTypes.CLEAR_GATEWAYS_ACTION: {

            return {
                ...state,
                gateways: [],
            };
        }

        case GatewayActionTypes.CLEAR_GATEWAY_ERROR: {
            return {
                ...state,
                error: {},
            };
        }

        default: {

            return state;
        }
    }
}
