import {
    IGraphDataAction,
    IGraphDataState,
} from '../../../../core/interfaces';
import { graphDataMapperConstants } from '../../../../core/constants/graphDataMapperConstants';
import { graphConstants } from '../../../../core/constants';


/**
 * Select dashboard reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphDataReducer(state: IGraphDataState = {
    graphData: new Map(),
    graphDataRAnge: new Map(),
    refresh: new Date().getTime(),
    refreshRange: new Date().getTime(),
    showLoader: true,
}, action: IGraphDataAction): IGraphDataState {

    switch (action.type) {

        case graphDataMapperConstants.GRAPH_DATA_MAPPER_SUCCESS:

            return {
                ...state,
                graphData: new Map(action.graphData.entries()),
                refresh: new Date().getTime(),
                showLoader: action.showLoader,
            };

        case graphConstants.minimapShifrRight:
        case graphConstants.minimapShiftLeft:

            return {
               ...state,
                showLoader: true,
            };

        case graphDataMapperConstants.GRAPH_DATA_RANGE_MAPPER_SUCCESS:

            return {
               ...state,
                graphDataRAnge: new Map(action.graphDataRange.entries()),
                refreshRange: new Date().getTime(),
            };

        default:

            return state;
    }
}
