import { createSelector } from 'reselect';
import { RootState } from '../../store';

const selectMonitoringTreeCollectionState = (state: RootState) => state.monitoringTreeCollection;

const getDashboardId = (state: any, dashboardId?: number) => dashboardId;

export const selectMonitoringTreeCollection = createSelector(
    selectMonitoringTreeCollectionState,
    monitoringTreeCollection => monitoringTreeCollection.trees,
);

export const selectMonitoringTreeCollectionFirstLoad = createSelector(
    selectMonitoringTreeCollectionState,
    monitoringTreeCollection => monitoringTreeCollection.firstLoad,
);


export const selectMonitoringTreeCollectionByDashboardId = createSelector(
    selectMonitoringTreeCollection,
    getDashboardId,
    (trees, dashboardId) => trees && dashboardId && trees[dashboardId],
);