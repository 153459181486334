import { IFlatTreeFactory, IFlatTreeProcess, IFlatTreeUnit } from '../interfaces';

/**
 * Get all units from dashboard
 *
 * @param {IMonitoringTree[]} monitoringTree
 *
 * @return {number[]}
 */
export const currentUnitsForDashboard = (monitoringTree:  (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[]): number[] => {

    const arrOfUnit: number[] = [];

    if (monitoringTree) {

        monitoringTree.forEach(treeItem => {

            if (treeItem.type === 'process') {

                arrOfUnit.push(...(treeItem as IFlatTreeProcess).data);
            }
        });
    }

    // if (isEqual(arrOfUnitOld, arrOfUnit) && arrOfUnit.length > 0) {
    //
    //     return arrOfUnitOld;
    //
    // } else {
    //
    //     arrOfUnitOld = arrOfUnit;

        return arrOfUnit;
    // }
};
