import { graphConstants } from '../../constants';
import { IAlertGraphSelectionAction, IGraphSelectionState } from '../../interfaces';


/**
 * State selection reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphSelection(state: IGraphSelectionState = {}, action: IAlertGraphSelectionAction): IGraphSelectionState {

    switch (action.type) {

        case graphConstants.SELECT:

            return {
                ...state,
                alert: action.alert,
                position: action.position,
            };

        case graphConstants.DESELECT_ALL:

            return {};

        case graphConstants.HOVERED:

            return {
                ...state,
                alertHover: action.alert,
                positionHover: action.position,
            };

        case graphConstants.UNHOVERED:

            return {
                ...state,
                alertHover: null,
                positionHover: null,
            };

        default:

            return state;
    }
}
