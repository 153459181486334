import React, { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import { FormikProps } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';

import 'react-phone-input-2/lib/material.css';
import './PhoneField.scss';


interface IPhoneFieldProps extends WithTranslation {
    formProps: FormikProps<any>;
    fieldName: string;
    label: string;
    inputClass: string;
}

const PhoneField: React.FC<IPhoneFieldProps> = ({ formProps, fieldName, label, inputClass }: IPhoneFieldProps) => {

    const handlerChangeCallback = useCallback((phone: string) => {

        formProps.values[fieldName] = '+' + phone;

    }, [formProps, fieldName]);

    return (
        <React.Fragment>
            <PhoneInput
                inputClass={inputClass}
                autoFormat
                country={'ua'}
                preferredCountries={['ru', 'ua', 'by']}
                value={formProps.values[fieldName] as string}
                onChange={handlerChangeCallback}
                specialLabel={label}
                containerClass={'form-field phone-field '
                +
                (formProps.touched[fieldName] ? formProps.errors[fieldName] ? 'error-field' : 'success-field' : '')
                }
            />
            {formProps.touched[fieldName] && formProps.errors[fieldName] &&
            <div
                className="validation-massage"
            >{formProps.errors[fieldName]}
            </div>
            }
        </React.Fragment>

    );
};

export default withTranslation()(PhoneField);
