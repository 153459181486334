import { productSortedByCategory } from '../reducers/products/productSortedByCategory';

export const productConstants = {
    LIST_SUCCESS: 'PRODUCT_LIST_SUCCESS',
    LIST_FAILURE: 'PRODUCT_LIST_FAILURE',

    LIST_ACTIVE_PRODUCT_SUCCESS: 'ACTIVE_PRODUCT_LIST_SUCCESS',
    LIST_ACTIVE_PRODUCT_FAILURE: 'ACTIVE_PRODUCT_LIST_FAILURE',

    ACTIVE_PRODUCT_IN_CURRENT_TIME_SUCCESS: 'ACTIVE_PRODUCT_IN_CURRENT_TIME_SUCCESS',
    ACTIVE_PRODUCT_IN_CURRENT_TIME_FAILURE: 'ACTIVE_PRODUCT_IN_CURRENT_TIME_FAILURE',

    BULK_UPDATE_SUCCESS: 'PRODUCT_BULK_UPDATE_SUCCESS',
    BULK_UPDATE_FAILURE: 'PRODUCT_BULK_UPDATE_FAILURE',

    STORE_SUCCESS: 'PRODUCT_STORE_SUCCESS',
    STORE_FAILURE: 'PRODUCT_STORE_FAILURE',

    STORE_ACTIVE_PRODUCT_SUCCESS: 'ACTIVE_PRODUCT_STORE_SUCCESS',
    STORE_ACTIVE_PRODUCT_FAILURE: 'ACTIVE_PRODUCT_STORE_FAILURE',

    UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PRODUCT_UPDATE_FAILURE',

    DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
    DELETE_FAILURE: 'PRODUCT_DELETE_FAILURE',

    RECEIVE_ACTIVE_PRODUCT_DATA: 'RECEIVE_ACTIVE_PRODUCT_DATA',
    UPDATE_SENSORS_DATA: 'UPDATE_SENSORS_DATA',

    GET_ALL_TARGET_VALUE_SUCCESS: 'GET_ALL_TARGET_VALUE_SUCCESS',
    GET_ALL_TARGET_VALUE_FAILURE: 'GET_ALL_TARGET_VALUE_FAILURE',

    GET_TARGET_VALUE_SUCCESS: 'GET_TARGET_VALUE_SUCCESS',
    GET_TARGET_VALUE_FAILURE: 'GET_TARGET_VALUE_FAILURE',

    UPDATE_TARGET_VALUE_SUCCESS: 'UPDATE_TARGET_VALUE_SUCCESS',
    UPDATE_TARGET_VALUE_FAILURE: 'UPDATE_TARGET_VALUE_FAILURE',

    GET_All_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS: 'GET_All_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS',
    GET_All_PRODUCTS_SORTED_BY_CATEGORY_FAILURE: 'GET_All_PRODUCTS_SORTED_BY_CATEGORY_FAILURE',

    GET_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS: 'GET_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS',
    GET_PRODUCTS_SORTED_BY_CATEGORY_FAILURE: 'GET_PRODUCTS_SORTED_BY_CATEGORY_FAILURE',

    GET_ACTIVE_PRODUCT_ON_LINE_SUCCESS: 'GET_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS',
    GET_ACTIVE_PRODUCT_ON_LINE_FAILURE: 'GET_PRODUCTS_SORTED_BY_CATEGORY_FAILURE',
};
