export const hmiPlayerConstants = {
    PLAY: 'HMI_PLAYER_PLAY',
    PAUSE: 'HMI_PLAYER_PAUSE',
    STOP: 'HMI_PLAYER_STOP',
    REALTIME: 'HMI_PLAYER_REALTIME',
    SET_VALUE: 'HMI_PLAYER_SET_VALUE',
    SET_SPEED: 'HMI_PLAYER_SET_SPEED',
    SET_SCHEMA: 'HMI_PLAYER_SET_SCHEMA',
    SHOW_OBJECT: 'SHOW_OBJECT',
    SHOW_HMI_SCHEMA: 'SHOW_HMI_SCHEMA',
    SET_OPACITY: 'HMI_PLAYER_SET_OPACITY',
    STORE_HISTOGRAM_DATA: 'STORE_HISTOGRAM_DATA',
    CLEAR_HISTOGRAM_DATA: 'CLEAR_HISTOGRAM_DATA',
};
