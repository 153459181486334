
export const graphDataMapperConstants = {

    //graph params
   GRAPH_DATA_MAPPER_SUCCESS: 'GRAPH_DATA_MAPPER_SUCCESS',
   GRAPH_DATA_MAPPER_FAILURE: 'GRAPH_DATA_MAPPER_FAILURE',

   GRAPH_DATA_RANGE_MAPPER_SUCCESS: 'GRAPH_DATA_RANGE_MAPPER_SUCCESS',
   GRAPH_DATA_RANGE_MAPPER_FAILURE: 'GRAPH_DATA_RANGE_MAPPER_FAILURE',
};
