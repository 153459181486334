import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Drawer, List, makeStyles } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import './LeftSidebar.scss';
import { RootState } from '../../core/store';
import { IUser } from '../../core/interfaces';
import MenuListItem from './LeftSidebar/MenuListItem';


const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',

    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        paddingTop: 40,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
}));

/**
 * A left sidebar component
 *
 * @param {object} props A component props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const LeftSidebar = (props: any) => {

    const classes = useStyles();

    const { path } = useRouteMatch();

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            className={'sidebar-left'}
        >
            {props.listItem ?
                <List disablePadding>
                    {props.listItem.map((item: {
                        itemName: string,
                        component: ComponentType<any>,
                        path: string,
                        route: string,
                        permission: string,
                        action: () => void
                    }) => (
                        <React.Fragment key={item.itemName}>
                            {props.rbac.can(item.permission) ?
                                <MenuListItem
                                    item={item}
                                    path={path}
                                />
                                :
                                null
                            }
                        </React.Fragment>

                    ))}
                </List>
                : null}
            {props.children}
        </Drawer>
    );
};


/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { auth } = state;

    return {
        user: auth.user as IUser,
        rbac: auth.rbac,
    };
};

export default connect(mapStateToProps)(withTranslation()(LeftSidebar));
