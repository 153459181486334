import { productConstants } from '../../constants';
import { IProductListAction, IProductListState } from '../../interfaces';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function product(state: IProductListState = {
    errors: {},
    products: [],
    product: null,
}, action: IProductListAction): IProductListState {

    switch (action.type) {

        case productConstants.LIST_SUCCESS:

            return {
                ...state,
                products: action.products,
                errors: {},
            };

        case productConstants.STORE_SUCCESS:
        case productConstants.UPDATE_SUCCESS:
        case productConstants.DELETE_SUCCESS:

            return {
                ...state,
                product: action.product,
                errors: {},
            };

        case productConstants.LIST_FAILURE:

            return {
                ...state,
                products: [],
                errors: action.errors,
            };

        case productConstants.DELETE_FAILURE:
        case productConstants.UPDATE_FAILURE:
        case productConstants.STORE_FAILURE:

            return {
                ...state,
                product: null,
                errors: action.errors,
            };


        default:

            return state;
    }

}
