import { useEffect } from 'react';
import { Canvas } from '../../components/ConfigurationTree/HMI/Editor/canvas/Canvas';
import { selectHmiValueBySensorId } from '../../core/selectors/hmi/playerSelector';
import { useSelector } from 'react-redux';
import { FabricObject } from '../../base/components/Editor/interfaces';
import { maxString } from '../../core/helpers/fittingStringHelper';
import { selectHmiHoverItem, selectHmiHoverSensor } from '../../core/selectors/hmi/playerHoverItem';
import { fabric } from 'fabric';
import { selectStartTouchEventDrawer } from '../../core/selectors/layout/responsiveDrawerSelector';

export const useHmiSensorsData = async(canvas: Canvas | null): Promise<void> => {

    const hmiValueBySensorId = useSelector(selectHmiValueBySensorId);
    const dedicatedSensor = useSelector(selectHmiHoverSensor);
    const startTouchEventDrawer = useSelector(selectStartTouchEventDrawer);
    const hoverItem = useSelector(selectHmiHoverItem);

    useEffect(() => {

        let setLabelPosition: NodeJS.Timeout | undefined = undefined;

        if (canvas && !startTouchEventDrawer) {

            const hmiValueBySensorIdMapped = new Map(hmiValueBySensorId.map(value => [value?.id, value]));

            const includeSensor = canvas.canvas.getObjects().find((value:  FabricObject) => value.objectId === hoverItem?.id || value.sensorId === dedicatedSensor?.id);


            canvas.canvas.forEachObject(async(obj: FabricObject) => {

                // const hmiData = hmiValueBySensorId.find(value => value?.id === obj.objectId);
                const hmiData = hmiValueBySensorIdMapped.get(obj.objectId);

                if (hmiData) {

                    const formatData = hmiData.value === undefined || hmiData.value === null ? hmiData.value : Number(hmiData.value).toFixed(2);

                    obj.set({
                        dirty: true,
                        shadow: hmiData.hoverEffect.shadow ?
                            new fabric.Shadow({
                                color: 'rgba(0,0,0,0.4)',
                                blur: 10,
                            }) : 'null',
                        opacity: hmiData.hoverEffect.opacity,
                    });

                    const dataValue = typeof hmiData.value === 'number' ?
                        (Number(formatData)).toString().length >= 10 ?
                            maxString((Number(formatData)).toString(), 10)
                            :
                            formatData
                            + ' ' + hmiData.um : 'No data';

                    if (obj.type === 'pitCode') {

                        obj.set('dirty', true);

                        obj.set('dataValue', dataValue);

                        obj.set('sensorType', hmiData.sensorType);

                        obj.set('label', hmiData.sensorName);

                        obj.set('text', hmiData.sensorName);

                    } else {

                        obj.set('dirty', true);

                        obj.set('fillColor', hmiData.color);
                        obj.set('fill', hmiData.color);

                        if (hmiData.frameColor) {

                            obj.set('strokeColor', hmiData.frameColor);
                            obj.set('stroke', hmiData.frameColor);
                        }

                        if (obj.type === 'indicator' && hmiData.sensorType === 'state') {

                            const stateText = hmiData?.stateText || 'No data';

                            obj.set('dataValue', stateText);
                            obj.set('fillColor', hmiData.color);
                            obj.set('fill', hmiData.color);
                        }

                        if (obj.type === 'indicator' && hmiData.sensorType !== 'state') {

                            obj.set('dataValue', dataValue);
                            obj.set('fillColor', '#FFFFFF');
                            obj.set('fill', '#FFFFFF');
                            obj.set('strokeColor', hmiData.color);
                            obj.set('stroke', hmiData.color);
                        }

                    }
                } else {

                    // remove shadow
                    obj.set({
                        dirty: true,
                        shadow: 'null',
                        opacity: dedicatedSensor && includeSensor ? 0.5 : obj.id === 'workarea' ? obj.prevOpacity : 1,
                    });
                }

                obj.lockMovementX = true;
                obj.lockMovementY = true;
                obj.lockScalingX = true;
                obj.lockScalingY = true;
                obj.lockRotation = true;

            }).requestRenderAll();

            setLabelPosition = setTimeout(()=>{

                for (let i = 0; i < hmiValueBySensorId.length; i++) {
                    const value = hmiValueBySensorId[i];

                    const currentTargetObject: FabricObject | undefined = canvas.canvas.getObjects().find((obj: FabricObject)=>obj.objectId === value?.id && obj.type !== 'pitCode');
                    const currentTargetLabelObject: FabricObject | undefined = canvas.canvas.getObjects().find((obj: FabricObject)=>obj.objectId === value?.id && obj.type === 'pitCode');

                    if (currentTargetObject && currentTargetLabelObject) {

                        const position = canvas.handler.getPositionRelativeToObject(currentTargetObject, currentTargetLabelObject);

                        if (
                            (currentTargetLabelObject as FabricObject).top !== position.top ||
                            (currentTargetLabelObject as FabricObject).left !== position.left
                        ) {

                            (currentTargetLabelObject as FabricObject).set(position);
                            (currentTargetLabelObject as FabricObject).setCoords();
                        }
                    }
                }

                canvas.canvas.renderAll();

                setLabelPosition && clearTimeout(setLabelPosition);

            }, 0);

            const sortedObject = canvas.canvas.getObjects().sort((a: FabricObject, b: FabricObject) => a.zIndex < b.zIndex ? -1 : 1);

            for (const value of sortedObject) {
                const index = sortedObject.indexOf(value);
                value.set({ dirty: true });
                value.moveTo(index);
                value.setCoords();
            }
            canvas.canvas.renderAll();
        }

        return () => {

            setLabelPosition && clearTimeout(setLabelPosition);
        };

    }, [hmiValueBySensorId, canvas, dedicatedSensor, startTouchEventDrawer, hoverItem]);



};