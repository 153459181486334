import { fabric } from 'fabric';
import { editorConstants } from '../../constants';

const Valve = fabric.util.createClass(fabric.Object, {

    type: editorConstants.objects.valve,

    superType: 'drawing',

    initialize(options: any) {

        options.originX = 'center';
        options.originY = 'center';
        options.width = 24;
        options.height = 16;

        this.callSuper('initialize', options || {});
    },

    _render(ctx: CanvasRenderingContext2D) {

        const { strokeColor = '#74797d', fillColor = '#cdd0d4' } = this;

        ctx.fillStyle = strokeColor;

        ctx.beginPath();
        ctx.moveTo(-12, -7);
        ctx.bezierCurveTo(-12, -7.5, -11.5, -8, -11, -8);
        ctx.lineTo(11, -8);
        ctx.bezierCurveTo(11.5, -8, 12, -7.5, 12, -7);
        ctx.lineTo(12, 7);
        ctx.bezierCurveTo(12, 7.5, 11.5, 8, 11, 8);
        ctx.lineTo(-11, 8);
        ctx.bezierCurveTo(-11.5, 8, -12, 7.5, -12, 7);
        ctx.lineTo(-12, -7);
        ctx.closePath();

        ctx.fill();

        ctx.fillStyle = fillColor;

        ctx.beginPath();
        ctx.moveTo(-10, -5);
        ctx.bezierCurveTo(-10, -5.5, -9.5, -6, -9, -6);
        ctx.lineTo(9, -6);
        ctx.bezierCurveTo(9.5, -6, 10, -5.5, 10, -5);
        ctx.lineTo(10, 5);
        ctx.bezierCurveTo(10, 5.5, 9.5, 6, 9, 6);
        ctx.lineTo(-9, 6);
        ctx.bezierCurveTo(-9.5, 6, -10, 5.5, -10, 5);
        ctx.lineTo(-10, -5);
        ctx.closePath();

        ctx.fill();

        ctx.fillStyle = '#ffffff';
        ctx.strokeStyle = '#74797d';

        ctx.beginPath();
        ctx.moveTo(-7.5, 3);
        ctx.lineTo(-7.5, 3.75);
        ctx.lineTo(-7, 3.5);
        ctx.lineTo(0, 0.5);
        ctx.lineTo(6.8, 3.5);
        ctx.lineTo(7.5, 3.75);
        ctx.lineTo(7.5, 3);
        ctx.lineTo(7.5, -3);
        ctx.lineTo(7.5, -3.8);
        ctx.lineTo(6.8, -3.5);
        ctx.lineTo(0, -0.5);
        ctx.lineTo(-7, -3.5);
        ctx.lineTo(-7.5, -3.8);
        ctx.lineTo(-7.5, -3);
        ctx.lineTo(-7.5, 3);
        ctx.closePath();

        ctx.fill();
        ctx.stroke();

        ctx.restore();
    },
});

Valve.fromObject = (options: any, callback: Function) => {

    return callback(new Valve(options));
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.fabric.Valve = Valve;

export default Valve;
