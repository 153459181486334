import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle, useMemo } from 'react';
import { useStore } from 'react-redux';
import { selectAllTargetValue } from '../../../core/selectors/product/allTargetValueSelector';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextInput, Select, Tooltip } from '../../../core/ui/components';
import { IAlertRule, ICause, IOptions, ISensor } from '../../../core/interfaces';
import ListMultiple from './ListMultiple';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert } from "@material-ui/lab";
import { operandsOption as operands } from './../../../helpers/alertOperands';
import AutoCompleteState from './AutoCompleteState';

interface IProps {
    index: number;
    sensors: IOptions[];
    sensorsOrigin: ISensor[];
    productName: string;
    data: IAlertRule;
    onDelete: (index: number) => void;
}

interface IAlertRuleRef {
    getFormData: () => Promise<{
        index: number,
        data: IAlertRule,
        hasError: boolean;
    }>;
}

interface IRuleCauseContexts {
    id: number;
    cause: ICause;
}

const AlertRule: React.FC<any> = forwardRef<IAlertRuleRef, IProps>(({
    index,
    sensors,
    productName,
    sensorsOrigin,
    data,
    onDelete,
}: IProps, ref) => {

    const { t } = useTranslation();

    const [initialValues, setInitialValues] = useState<IAlertRule>({
        sensor: '',
        operand: '',
        value: '',
        time: 0,
        ruleCauseContexts: [],
        comment: '',
        send_notifications: false,
        address: '',
        subject: '',
        message: '',
        states: [],
        isValid: true,
        stateSensors: [],
    });

    const [unit, product] = productName.split('-');

    useEffect(() => {
        setInitialValues(data);
    }, [data]);

    yup.addMethod(yup.string, 'multipleEmails', function(this: yup.StringSchema, msg: string) {

        return this.test({
            name: 'multipleEmails',
            message: msg,
            test: (value) => {

                if (value) {

                    const emails = value.split(',');

                    if (value.match(/\s/) && !value.match(',')) {
                        return false;
                    }

                    const emailSchema = yup.string().email();

                    for (const email of emails) {

                        if (!emailSchema.isValidSync(email.replace(/\s/g, ''))) {

                            return false;
                        }
                    }
                }

                return true;
            },
        });
    });

    const validationSchema = yup.object().shape({
        sensor: yup
            .string()
            .required(t('REQUIRED')),
        states: yup
            .array()
            .of(yup.number()),
        operand: yup
            .string()
            .required(t('REQUIRED')),
        isValid: yup
            .boolean(),
        value: yup
            .string()
            .trim()
            .test({
                name: 'stateSensors',
                exclusive: false,
                params: { sensorsOrigin },
                message: t('REQUIRED') as string,
                test: function(value) {

                    return (value === 'none' || !isNaN(parseInt(value)));
                },
            })
            .required(t('REQUIRED')),
        time: yup
            .number()
            .min(10, t('THE_MINIMUM_VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO', { value: 10 }))
            .typeError(t('VALUE_MUST_BE_AN_INTEGER_NUMBER')),
        ruleCauseContexts: yup.array(),
        comment: yup.string(),
        send_notifications: yup.boolean(),
        address: yup
            .string()
            .trim()
            .when('send_notifications', {
                is: true,
                //@ts-ignore
                then: yup.string().required(t('ADDRESS_IS_REQUIRED')).multipleEmails(t('ONE_OR_MORE_EMAILS_ARE_NOT_VALID')),
            }),
        subject: yup
            .string()
            .trim()
            .when('send_notifications', {
                is: true,
                then: yup.string().required(t('SUBJECT_IS_REQUIRED')),
            }),
        message: yup
            .string()
            .trim()
            .when('send_notifications', {
                is: true,
                then: yup.string().required(t('MESSAGE_IS_REQUIRED')),
            }),
        stateSensors: yup
            .array()
            .test({
                name: 'stateSensors',
                exclusive: false,
                params: { sensorsOrigin },
                message: t('REQUIRED') as string,
                test: function(stateSensors) {
                    
                    const currentSensor = sensorsOrigin && sensorsOrigin.find((s:ISensor)=> s.id === parseInt(this.parent.sensor));
                    
                    if (currentSensor?.sensorType !== 'graph') {
                        return true;
                    }

                    return !(this.parent.ruleCauseContexts.length > 0 && stateSensors.length === 0);
                },
            }),
    });
    const filedSensorStyle: React.CSSProperties = {
        maxWidth: 'calc(100% + 40px)',
        width: 'calc(100% + 40px)',
        marginRight: 5,
    };

    const store = useStore();

    const allSensor = selectAllTargetValue(store.getState());

    const emailList = [
        { value: 'email', label: t('E-MAIL') },
    ];

    const {
        values,
        errors,
        touched,
        validateForm,
        submitForm,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: () => console.info('on submit'),
        onReset: () => console.info('on reset'),
    });

    useImperativeHandle(ref, () => ({
        
        getFormData: async() => {

            await submitForm();

            const errors = await validateForm();

            const hasError = !!Object.keys(errors).length;

            return {
                data: values,
                index,
                hasError: hasError,
            };
        },

    }));

    const validatedClass = useCallback((name: string): string => {

        // @ts-ignore
        const classString = touched && touched[name] ? errors && errors[name] ? 'error-field' : 'success-field' : '';

        return `form-field ${name} ${classString}`;

    }, [touched, errors]);

    const changeTypeTimeInput = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        const dataValueType: 'days' | 'hours' | 'minutes' | 'seconds' | string | null = event.target.getAttribute('data-value-type');

        const value = event.target.value || '0';
        
        const setValue = (seconds: number): void => {

            setFieldValue('time', seconds);

            generateMessage({ time: seconds });
        };

        const days: number = moment.duration(values.time, 'seconds').get('days'),
            hours: number = moment.duration(values.time, 'seconds').get('hours'),
            minutes: number = moment.duration(values.time, 'seconds').get('minutes'),
            seconds: number = moment.duration(values.time, 'seconds').get('seconds');

        switch (dataValueType) {
            case 'days': {

                const newTime = moment.duration({
                    seconds: seconds,
                    minutes: minutes,
                    hours: hours,
                    days: parseInt(value),
                }).asSeconds();

                setValue(newTime);

                break;
            }
            case 'hours': {

                const newTime = moment.duration({
                    seconds: seconds,
                    minutes: minutes,
                    hours: parseInt(value),
                    days: days,
                }).asSeconds();

                setValue(newTime);

                break;
            }
            case 'minutes': {

                const newTime = moment.duration({
                    seconds: seconds,
                    minutes: parseInt(value),
                    hours: hours,
                    days: days,
                }).asSeconds();

                setValue(newTime);

                break;
            }
            case 'seconds': {

                const newTime = moment.duration({
                    seconds: parseInt(value),
                    minutes: minutes,
                    hours: hours,
                    days: days,
                }).asSeconds();

                setValue(newTime);

                break;
            }
            default: {
                break;
            }
        }

    }, [values]);

    const generateMessage = useCallback((payload: Partial<IAlertRule>) => {

        const data = { ...values, ...payload };

        if (data.send_notifications) {

            const selectedSensor = getSensorOrigin(parseInt(data.sensor));

            const productMessageName = `${unit.trim()} - ${product.trim()}`;

            if (selectedSensor) {
                
                const sensorLabel = selectedSensor?.name || '';

                if (data.sensor) {
                    setFieldValue('subject', `${unit.trim()} - ${sensorLabel} - ${product.trim()}`);
                }

                const textUm = selectedSensor?.um || '';

                const operandLabel = (data.operand ? operands.find(operand => operand.value === data.operand)?.label : '');

                const newValue = data.value 
                    ? data.value.replace(/[^\d.-]/g, '')
                    : ''; 

                const message = buildAlertMessage(
                    productMessageName,
                    sensorLabel,
                    operandLabel || '',
                    newValue,
                    textUm,
                    data.time,
                );

                setFieldValue('message', message);
            } else {
                setFieldValue('subject', productName);
            }
        }

    }, [values]);

    const getSensorOrigin = (id: number): ISensor | undefined => {

        return sensorsOrigin.find((s: any) => s.id === id);
    };

    const umRenderFunction = (id: number): React.ReactNode => {

        if (!getSensorOrigin(id)?.um) {

            return null;
        }

        const umText = getSensorOrigin(id)?.um || '';

        return (
            <Tooltip
                disableTouchListener
                title={umText}
            >
                <span className="inscription-um-wrapper">
                    (<div className="inscription-um" >{umText}</div>)
                </span>
            </Tooltip>
        );
    };

    const graphTypeRule = useCallback((): boolean => {

        const originSensor = sensorsOrigin.find((s: ISensor) => s.id === parseInt(values.sensor));

        return originSensor?.sensorType === 'graph';

    }, [values]);

    const checkDisplayValueField = useCallback((): string => {

        return values.sensor && (!graphTypeRule() || values.operand === 'null') ? 'none' : 'inherit';

    }, [values]);

    const validateSensorOutput = (): { validationRule: boolean, disabledValue?: IOptions } => {

        const validationRule = sensors && sensors.some(sensor => sensor.value === values.sensor);

        if (!validationRule) {

            if (allSensor) {

                const existingSensor = allSensor
                    .findIndex((sensor: ISensor) => sensor.id === parseInt(values.sensor));

                const disabledValue = {
                    label: allSensor[existingSensor]?.name || '',
                    value: allSensor[existingSensor]?.id || 'empty',
                };

                return {
                    validationRule: validationRule,
                    disabledValue: disabledValue,
                };
            }
        }
        return {
            validationRule: validationRule,
        };
    };

    const onChangeSensor = useCallback((e: React.ChangeEvent<{name?: string, value: unknown}>) => {

        const value = e.target.value as string;

        const currentSensor = getSensorOrigin(parseInt(values.sensor));

        const selectedSensor = getSensorOrigin(parseInt(value));

        setFieldValue('sensor', value);

        const payload: Partial<IAlertRule> = {};

        if ((currentSensor?.sensorType === 'state' && selectedSensor?.sensorType === 'graph') || selectedSensor?.sensorType === 'graph') {
            setFieldValue('operand', '<');
            setFieldValue('value', '0');
        
            if (values.send_notifications) {

                payload.operand = '<';

                generateMessage(payload);
            }
        }
        
        if ((currentSensor?.sensorType === 'graph' && selectedSensor?.sensorType === 'state') || selectedSensor?.sensorType === 'state') {
            setFieldValue('operand', 'null');

            setFieldValue('value', 'none');

            if (values.send_notifications) {

                payload.operand = 'null';

                generateMessage(payload);
            }
        }

        if (values.send_notifications) {

            payload.sensor = value;

            generateMessage(payload);
        }

    }, [values]);

    const ruleCauseSelect = useCallback(() => {
        
        const result: Array<{id: number, name: string}> = [];

        const ruleCauseContexts = values.ruleCauseContexts as unknown as IRuleCauseContexts[];

        ruleCauseContexts.forEach((context) => {
        
            if (context.cause) {

                result.push(context.cause as any);
            }
        });

        return result;
    }, [values]);


    const buildAlertMessage = (
        productName: string,
        sensorLabel: string,
        operandLabel: string,
        newValueForMessage: any,
        textUm: string | undefined,
        time: number,
    ): string => {

        const header = `${productName}`;

        if (operandLabel !== 'none') {

            const oneDefaultMessage = `${t('DEFAULT_MESSAGE_COMMENT_IN_ALERT_ONE', {
                sensorLabel: sensorLabel.trim(),
            })}`;

            const twoDefaultMessage = `${t('DEFAULT_MESSAGE_COMMENT_IN_ALERT_TWO', {
                value: `${newValueForMessage} ${textUm? `(${textUm})`: ''}`,
                time, 
            })}`;

            return `${header}\n${oneDefaultMessage} ${operandLabel} ${twoDefaultMessage}`;

        } else {

            const threeDefaultMessage = `${t('DEFAULT_MESSAGE_COMMENT_IN_ALERT_THREE', {
                sensorLabel: sensorLabel.trim(),
                time,
            })}`;

            return `${header}\n${threeDefaultMessage}`;
        }
    };

    const selectedCauses = useMemo(() => ruleCauseSelect(), [values]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="row-wide">
                <div 
                    className={data.isValid ? 'valid' : 'not-valid'}
                >
                    <>
                    {((!data.isValid) || (touched && touched.currentSensor && errors && errors.currentSensor)) &&
                        (<Grid container>
                            <Alert 
                                icon={false}
                                severity="error"
                                style={{ width: '100%', marginBottom: 20 }}
                            >
                                {t('BROKEN_RULES')}
                            </Alert>
                        </Grid>)
                    }
                        <Grid container spacing={2}>
                            <Grid item className={'field-remove'}>
                                <IconButton className={'red-btn'}
                                    onClick={() => onDelete(index)}
                                    style={{zIndex: 2}}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Grid>
                            <Grid item className={'field-content'}>
                                <Grid container  spacing={2} style={filedSensorStyle}>
                                    <Grid item className={'field-sensor'}>
                                        <Select
                                            className={validatedClass('sensor')}
                                            options={sensors}
                                            name="sensor"
                                            value={values.sensor || ''}
                                            disabledselectoption={validateSensorOutput()?.disabledValue}
                                            placeholder={t('PARAMETER_TO_WATCH')}
                                            searchable
                                            onChange={onChangeSensor}
                                            onBlur={handleBlur}
                                        >
                                            {(touched && touched.sensor && errors && errors.sensor) &&
                                                <div className="validation-massage">{errors.sensor}</div>
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid item className="form-fields-inline">
                                        {t('IS')}
                                    </Grid>
                                    <Grid item className={'field-oparands'}>
                                        <Select
                                            className={validatedClass('operand')}
                                            options={operands}
                                            displayEmpty
                                            name="operand"
                                            value={values.operand || ''}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e);
                                                generateMessage({ operand: e.target.value as unknown as string });
                                            }}
                                        >
                                            {(touched && touched.operand && errors && errors.operand) &&
                                                <div className="validation-massage">{errors.operand}</div>
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid item className="field-value field-value-with-um"
                                        style={{ display: checkDisplayValueField() }}
                                    >
                                        <TextInput
                                            className={validatedClass('value')}
                                            type="text"
                                            name="value"
                                            placeholder={t('VALUE')}
                                            value={values.value}
                                            onChange={(e) => {
                                                handleChange(e);
                                                generateMessage({ value: e.target.value });
                                            }}
                                            onBlur={handleBlur}
                                            inputProps={{ maxLength: 20 }}
                                        >
                                            {(touched && touched.value && errors && errors.value) &&
                                                <div className="validation-massage">{errors.value}</div>
                                            }
                                        </TextInput>
                                        {values.sensor ? umRenderFunction(parseInt(values.sensor)) : null}
                                    </Grid>
                                    <Grid item className="form-fields-inline">
                                        {t('FOR')}
                                    </Grid>
                                    <Grid item className={'field-time days'}>
                                        <TextInput
                                            className={validatedClass('time')}
                                            title={'days'}
                                            type="text"
                                            name="time"
                                            placeholder={t('TIME')}
                                            value={Math.floor(moment.duration(values.time, 'seconds').asDays())}
                                            inputAdornment={t('DAYS')}
                                            onChange={changeTypeTimeInput}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                'data-value-type': 'days',
                                            }}
                                        >
                                            <div className="validation-massage" style={{color: '#74797D', fontStyle: 'italic'}}>
                                                {t('ONE_DAY_IS_24H')}
                                            </div>
                                        </TextInput>
                                    </Grid>
                                    <span className="colon">:</span>
                                    <Grid item className={'field-time hours'}>
                                        <TextInput
                                            className={validatedClass('time')}
                                            name="time"
                                            type="text"
                                            placeholder={t('TIME')}
                                            value={moment.duration(values.time, 'seconds').get('hours')}
                                            title={'hours'}
                                            inputAdornment={t('H')}
                                            onChange={changeTypeTimeInput}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                maxLength: 24,
                                                'aria-valuemax': 24,
                                                'data-value-type': 'hours',
                                            }}
                                        />
                                    </Grid>
                                    <span className="colon">:</span>
                                    <Grid item className={'field-time minutes'}>
                                        <TextInput
                                            className={validatedClass('time')}
                                            name="time"
                                            type="text"
                                            title={'minutes'}
                                            placeholder={t('TIME')}
                                            value={moment.duration(values.time, 'seconds').get('minutes')}
                                            inputAdornment={t('M')}
                                            onChange={changeTypeTimeInput}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                maxLength: 20,
                                                'aria-valuemax': 60,
                                                'data-value-type': 'minutes',
                                            }}
                                        />
                                    </Grid>
                                    <span className="colon">:</span>
                                    <Grid item className={'field-time seconds'}>
                                        <TextInput
                                            className={validatedClass('time')}
                                            name="time"
                                            type="text"
                                            placeholder={t('TIME')}
                                            title={'seconds'}
                                            value={moment.duration(values.time, 'seconds').get('seconds')}
                                            inputAdornment={t('S')}
                                            onChange={changeTypeTimeInput}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                maxLength: 20,
                                                'aria-valuemax': 60,
                                                'data-value-type': 'seconds',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid  container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-start"
                                        className="validation-massage-time-container"
                                > {
                                    (touched && touched.time && errors && errors.time) &&
                                        <div className="validation-massage time-errors">{errors.time} {t('SEC')}</div>
                                }
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item className="form-fields-inline left">
                                        {t('FOR_STATUS')}
                                    </Grid>
                                    <Grid item className={'filed-status'}>
                                        <div
                                            className={validatedClass('ruleCauseContexts')}
                                        >
                                            <ListMultiple
                                                selected={selectedCauses}
                                                name="ruleCauseContexts"
                                                onChange={selected => {
                                                    setFieldValue('ruleCauseContexts', selected);
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                {graphTypeRule() && values.ruleCauseContexts.length > 0 && 
                                    <AutoCompleteState
                                        sensors={sensorsOrigin}
                                        stateSensors={values.stateSensors}
                                        validatedClass={validatedClass}
                                        onChange={(value) => setFieldValue('stateSensors', value)}
                                        errorMessage={touched?.stateSensors && errors?.stateSensors ? errors.stateSensors as unknown as string : ''}
                                    />
                                }
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={'field-comment'}>
                                        <TextInput
                                            className={validatedClass('comment')}
                                            name="comment"
                                            type="text"
                                            placeholder={t('COMMENT') + '...'}
                                            value={values.comment}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            multiline
                                            rows="4"
                                            inputProps={{ maxLength: 600 }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormControlLabel
                                            className={'field-checkbox'}
                                            control={
                                                <Checkbox
                                                    name="send_notifications"
                                                    checked={values.send_notifications}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        generateMessage({ send_notifications: !values.send_notifications });
                                                    }}
                                                    value={values.send_notifications}
                                                    color="primary"
                                                    icon={<span className="checkbox-icon"/>}
                                                />
                                            }
                                            label={t('SEND_NOTIFICATIONS_VIA')}
                                        />
                                    </Grid>
                                    <Grid item className={'field-email'}>
                                        <Select
                                            className="form-field"
                                            options={emailList}
                                            value={'email'}
                                            disabled={!values.send_notifications}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    values.send_notifications && (
                                        <>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className={'field-comment'}>
                                                    <TextInput
                                                        className={validatedClass('address')}
                                                        name="address"
                                                        type="text"
                                                        value={values.address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        label={t('ADDRESS')}
                                                        placeholder={t('E-MAIL')}
                                                    >
                                                        {(touched && touched.address && errors && errors.address) &&
                                                            <div className="validation-massage">{errors.address}</div>
                                                        }
                                                    </TextInput>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className={'field-comment'}>
                                                    <TextInput
                                                        className={validatedClass('subject')}
                                                        name="subject"
                                                        type="text"
                                                        value={values.subject}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        label={t('SUBJECT')}
                                                        placeholder={productName || t('SUBJECT')}
                                                        inputProps={{ maxLength: 60 }}
                                                    >
                                                        {(touched && touched.subject && errors && errors.subject) &&
                                                            <div className="validation-massage" >{errors.subject}</div>
                                                        }
                                                    </TextInput>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className={'field-comment'}>
                                                    <TextInput
                                                        className={validatedClass('message-rule')}
                                                        name="message"
                                                        type="text"
                                                        value={values.message}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        label={t('MESSAGE')}
                                                        multiline
                                                        rows="4"
                                                        inputProps={{ maxLength: 600 }}
                                                    >
                                                        {(touched && touched.message && errors && errors.message) &&
                                                            <div
                                                                className="validation-massage"
                                                            >
                                                                {errors.message}
                                                            </div>
                                                        }
                                                    </TextInput>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }
                            </Grid>
                        </Grid>
                        <hr />
                    </>
                </div>
            </div>
        </form>
    );
});

AlertRule.displayName = 'AlertRule';

export default React.memo(AlertRule);