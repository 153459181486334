import React from 'react';
import { Dialog as MuiDialog, DialogProps } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'reshadow';

const styles = require('./Dialog.scss');

/**
 * A popup window with form
 *
 * @param {boolean} open Dialog open/close switcher
 * @param {string} heading Dialog heading text
 * @param {function} onClose Callback when user denies an action
 * @param {object} props The rest props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const FormDialog: React.FC<FormDialogProps> = ({
    open,
    heading,
    onClose,
    children,
    ...props
}) => {

    return styled(styles)(
        <MuiDialog
            aria-labelledby="form-dialog-title"
            open={open || false}
            onClose={onClose}
            {...props}
            className={`form-dialog ${props.name}`}
            disableEnforceFocus
        >
            {onClose ?
                (<MuiDialogTitle disableTypography>
                    <IconButton aria-label="close" size="small" onClick={onClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                 </MuiDialogTitle>) : null
            }
            <div className="dialog-popup">
                {heading ? <h2 id="form-dialog-title">{heading}</h2> : null}
                {children}
            </div>
        </MuiDialog>,
    );
};

interface IProps {
    heading?: string;
    onClose?: () => void;
    name?: string
}

export type FormDialogProps = IProps & DialogProps;

export default FormDialog;
