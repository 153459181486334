import { Action } from 'redux';
import { IEmployee } from '../../interfaces';
import { IErrors } from '../../../../core/interfaces';

export enum EmployeeActionTypes {
    SAVE_EMPLOYEE_SUCCESS = '[Employee] Save Employee Success',
    SAVE_EMPLOYEE_FAILURE = '[Employee] Save Employee Failure',
    GET_EMPLOYEE_SUCCESS = '[Employee] Get Employee Success',
    GET_EMPLOYEE_FAILURE = '[Employee] Get Employee Failure',
    FETCH_EMPLOYEES_REQUEST = '[Employee] Fetch Employees Request',
    FETCH_EMPLOYEES_SUCCESS = '[Employee] Fetch Employees Success',
    FETCH_EMPLOYEES_FAILURE = '[Employee] Fetch Employees Failure',
    UPDATE_EMPLOYEE_SUCCESS = '[Employee] Update Employee Success',
    UPDATE_EMPLOYEE_FAILURE = '[Employee] Update Employee Failure',
    DELETE_EMPLOYEE_ACTION = '[Employee] Delete Employee Action',
    DELETE_EMPLOYEE_FAILURE = '[Employee] Delete Employee Failure',
    CLEAR_EMPLOYEE_ERROR = '[Employee] Clear employee error',
}

export class FetchEmployeesRequest implements Action {

    readonly type = EmployeeActionTypes.FETCH_EMPLOYEES_REQUEST;
}

export class FetchEmployeesSuccess implements Action {

    readonly type = EmployeeActionTypes.FETCH_EMPLOYEES_SUCCESS;

    constructor(
        public payload: {
            employees: IEmployee[]
        },
    ) {}
}

export class FetchEmployeesFailure implements Action {

    readonly type = EmployeeActionTypes.FETCH_EMPLOYEES_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        },
    ) {}
}

export class SaveEmployeeAction implements Action {

    readonly type = EmployeeActionTypes.SAVE_EMPLOYEE_SUCCESS;

    constructor(
        public payload: {
            employee: IEmployee,
        },
    ) {}
}

export class GetEmployeeSuccess implements Action {

    readonly type = EmployeeActionTypes.GET_EMPLOYEE_SUCCESS;

    constructor(
        public payload: {
            employee: IEmployee,
        },
    ) {}
}

export class GetEmployeeFailure implements Action {

    readonly type = EmployeeActionTypes.GET_EMPLOYEE_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        },
    ) {}
}

export class SaveEmployeeFailure implements Action {

    readonly type = EmployeeActionTypes.SAVE_EMPLOYEE_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        },
    ) {}
}

export class UpdateEmployeeSuccess implements Action {

    readonly type = EmployeeActionTypes.UPDATE_EMPLOYEE_SUCCESS;

    constructor(
        public payload: {
            employee: IEmployee,
        },
    ) {}
}

export class UpdateEmployeeFailure implements Action {

    readonly type = EmployeeActionTypes.UPDATE_EMPLOYEE_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        },
    ) {}
}


export class DeleteEmployeeAction implements Action {

    readonly type = EmployeeActionTypes.DELETE_EMPLOYEE_ACTION;

    constructor(
        public payload: {
            employee: IEmployee
        },
    ) {
    }
}

export class DeleteEmployeeFailure implements Action {

    readonly type = EmployeeActionTypes.DELETE_EMPLOYEE_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        },
    ) {}
}

export class ClearEmployeeError implements Action {

    readonly type = EmployeeActionTypes.CLEAR_EMPLOYEE_ERROR;
}

export type EmployeeActions =
    FetchEmployeesRequest |
    FetchEmployeesSuccess |
    FetchEmployeesFailure |
    SaveEmployeeAction |
    UpdateEmployeeSuccess |
    UpdateEmployeeFailure |
    SaveEmployeeFailure |
    DeleteEmployeeFailure |
    GetEmployeeSuccess |
    GetEmployeeFailure |
    ClearEmployeeError |
    DeleteEmployeeAction;
