import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';
import { FormActions } from '../../../core/actions';
import { IError, IStateCategory } from '../../../core/interfaces';
import { Button, TextInput, ConfirmDialog } from '../../../core/ui/components';
import { Radio, FormControl, FormLabel, RadioGroup } from '@material-ui/core';
import './StatesForm.scss';
import { RootState } from '../../../core/store';


interface IFormValues {
    id?: number | null;
    name: string;
    color: string;
}

interface IProps {
    errors: IError;
    formOpened: boolean;
    toggleForm: (opened: boolean) => void;
    model: IStateCategory | null;
    confirmAdd: (item: IStateCategory, name: string) => void;
    confirmUpdate: (item: IStateCategory, name: string) => void;
}

interface IState {
    initialValues: IFormValues;
    colorList: string[];
    confirm: boolean;
}

/**
 * State item add/edit form
 *
 * @class StateForm
 */
class StatesForm extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, model } = this.props;

        this.state = {
            initialValues: {
                id: model && model.id ? parseInt(model.id.toString()) : null,
                name: model ? model.name.toString() : '',
                color: model ? model.color.toString() : '#75c36e',
            },
            colorList: [
                '#75c36e', '#f0d186', '#d08259', '#fa5252', '#b3de8e', '#fcbe75', 
                '#9D9C9C', '#d0d4d6', '#AC94FE', '#EA9999', '#F9CB9C', '#FDE599', 
                '#A2C4C9', '#B4A7D6', '#40A8F7', '#F49831', '#FDF733', '#76F013',
                '#b0a430', '#5984ea', '#11f061', '#e5586d', '#58b063', '#dc7736',
                '#e3b597', '#72cce9', '#c5d8a1', '#aebaeb', '#94dbcb', '#e6afd3',
                '#7BABB0', '#73768A', '#BFB5B0', '#D9C9B0', '#EDE3C7', '#ABABAB',
            ],
            confirm: false,
        }

        ;

        this.validationSchema = yup.object().shape({
            color: yup
                .string()
                .required(t('COLOR_IS_REQUIRED')),
            name: yup
                .string()
                .trim()
                .max(30, t('MAX_ITEM_LENGTH', { name: t('CATEGORY'), length: '30' }))
                .required(t('CATEGORY_IS_REQUIRED')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleCloseSidebar = this.handleCloseSidebar.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.handleChangeColor = this.handleChangeColor.bind(this);
    }

    /**
     * Component props update handler
     *
     * @param {IProps} nextProps Updated component properties
     */
    componentDidUpdate(nextProps: IProps) {

        const { model } = this.props;

        if (nextProps.model !== model && model && model.id) {

            this.setState({
                initialValues: {
                    id: parseInt(model.id.toString()),
                    name: model.name.toString(),
                    color: model.color.toString(),
                },
            });
        }
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IFormValues>>;

    /**
     * State item form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        if (values.name && values.color) {

            this.setState({ confirm: true, initialValues: values });

        }

    }

    /**
     * State item form cancel handler
     */
    handleCancel() {


        this.setState({ confirm: false });

    }

    /**
     * Color Change Handler
     *
     * @param {string} event
     */
    handleChangeColor(event: React.ChangeEvent<HTMLElement>) {

        const { initialValues } = this.state;

        initialValues.color = event.currentTarget.title;

        this.setState({ initialValues: initialValues });
    }

    /**
     * Handler cancel dialog form
     */
    onConfirmDialog() {

        const { initialValues } = this.state;

        if (!initialValues.id) {

            this.props.confirmAdd({
                name: initialValues.name,
                color: initialValues.color,
            }, 'states');

        } else {

            this.props.confirmUpdate({
                id: initialValues.id,
                name: initialValues.name,
                color: initialValues.color,
            }, 'states');
        }

        this.handleCancel();

    }

    /**
     *  Close sidebar handler
     */
    handleCloseSidebar() {

        this.props.toggleForm(this.props.formOpened);

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render(): JSX.Element {

        const { t, errors } = this.props,
            { initialValues } = this.state;

        return (
            <div className={'form-add-edit-states'}>
                <h3 className={'title'}>{this.props.t(!initialValues.id ? 'ADD_CATEGORY' : 'EDIT_CATEGORY')}</h3>
                {errors && errors.field === 'name' ?
                    <div className="common-error">
                        {t('THE_CATEGORY_HAS_ALREADY_BEEN_TAKEN')}
                    </div>
                    :
                    null
                }
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} noValidate style={{ paddingBottom: 80 }}>
                            <TextInput
                                className={
                                    'form-field ' +
                                    (props.touched.name ? (props.errors.name || errors.field === 'name') ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                label={this.props.t('NAME_CATEGORY')}
                                value={props.values.name}
                                name="name"
                                type="text"
                                placeholder={this.props.t('NAME_CATEGORY')}
                                inputProps={{ maxLength: 50 }}
                            >
                                {((props.touched.name && props.errors.name) ||  errors.field === 'name') ?
                                    <div
                                        className="validation-massage"
                                    >
                                        {props.errors.name}
                                    </div>
                                    :
                                    null
                                }
                            </TextInput>
                            <FormControl component="fieldset" className={'list-color-radio-btn'}>
                                <FormLabel component="legend">{this.props.t('CHOOSE_COLOR')}</FormLabel>
                                <RadioGroup aria-label="position" name="position" value={props.values.color}
                                            onChange={props.handleChange}
                                            className={
                                                'form-field ' +
                                                (props.touched.color ? props.errors.color ? 'error-field' : 'success-field' : '')
                                            }
                                            row
                                >
                                    {this.state.colorList.map((value) =>
                                        (
                                            <Radio
                                                key={value}
                                                value={value}
                                                style={{ color: value }}
                                                onChange={this.handleChangeColor}
                                                name="color"
                                                inputProps={{ 'aria-label': '$(value) ' }}
                                            />
                                        ),
                                    )}
                                </RadioGroup>
                                {props.touched.color && props.errors.color ?
                                    <div
                                        className="validation-massage"
                                    >
                                        {props.errors.color}
                                    </div>
                                    :
                                    null
                                }
                            </FormControl>
                            <div className="button-row">
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={this.handleCloseSidebar}
                                >
                                    {t('CANCEL')}
                                </Button>
                                <Button type="submit" color="secondary">
                                    {t(initialValues.id ? 'SAVE_CHANGES' : 'ADD')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
                {this.state.confirm?
                    <ConfirmDialog
                        heading={t(initialValues.id ? 'CHANGE_CATEGORY_Q' : 'ADD_CATEGORY_Q')}
                        onAccept={this.onConfirmDialog}
                        onClose={this.handleCancel}
                        open={this.state.confirm}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { errors } = state.statesList,
        { formOpened, formName } = state.form;

    return {
        errors,
        formOpened,
        formName,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StatesForm));