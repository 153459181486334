import { combineReducers } from 'redux';
import { locationReducer, ILocationState } from './locationReducer';
import { ILocationFormState, locationFormReducer } from './locationFormReducer';
import { ILocationEditorState, locationEditorReducer } from './locationEditorReducer';
import { ILayerState, layerReducer } from './layerReducer';
import { ILayerFormState, layerFormReducer } from './layerFormReducer';
import { IHrModuleState, hrModuleReducer } from './hrModuleReducer';
import { IEmployeeState, employeeReducer } from './employeeReducer';
import { IDepartmentState, departmentReducer } from './departmentReducer';
import { IBuildingState, buildingReducer } from './buildingReducer';
import { ISettingPageState, settingPageReducer } from './settingPageReducer';
import { IBeaconState, beaconReducer } from './beaconReducer';
import { IGatewayState, gatewayReducer } from './gatewayReducer';
import { barrierReducer, IBarrierState } from './barrierReducer';
import { IZoneState, zoneReducer } from './zoneReducer';
import { IMonitoringTreeState, monitoringTreeReducer } from './monitoringTreeReducer';

export interface IHrState {
    locationsState: ILocationState;
    locationFormState: ILocationFormState;
    locationEditorState: ILocationEditorState;
    layersState: ILayerState;
    layerFormState: ILayerFormState;
    hrModule: IHrModuleState;
    employeeState: IEmployeeState;
    departmentState: IDepartmentState;
    editorBuildingState: IBuildingState;
    editorGatewayState: IGatewayState;
    editorZoneState: IZoneState;
    editorBarrierState: IBarrierState;
    settingPageState: ISettingPageState;
    beaconState: IBeaconState;
    monitoringTreeState: IMonitoringTreeState;
}

export const reducers = combineReducers<IHrState>({
    locationsState: locationReducer,
    locationFormState: locationFormReducer,
    locationEditorState: locationEditorReducer,
    layersState: layerReducer,
    layerFormState: layerFormReducer,
    hrModule: hrModuleReducer,
    employeeState: employeeReducer,
    departmentState: departmentReducer,
    editorBuildingState: buildingReducer,
    editorGatewayState: gatewayReducer,
    editorZoneState: zoneReducer,
    editorBarrierState: barrierReducer,
    settingPageState: settingPageReducer,
    beaconState: beaconReducer,
    monitoringTreeState: monitoringTreeReducer,
});

export const HR_STORE_KEY = 'hr';
