import React, { useCallback } from 'react';
import { isIE } from 'react-device-detect';
import { ILocation } from '../../../interfaces';
import { ReactComponent as AddIcon } from '../../../ui/assets/images/icons/add.svg';

import './AddAction.scss';

/**
 * Add Layer/Location to configuration tree button FC
 *
 * @param {IProps} props
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const AddAction: React.FC<IProps> = (props: IProps) => {
    const { title, padding, action, parent, disabled, scrollLeft, expandIcon } = props;

    const onClick = useCallback(() => {

        if (!disabled) {

            action(parent);
        }
    }, [disabled, action, parent]);

    return (
        <div
            className={`left-section-hr add-action ${padding} ${disabled ? 'disabled' : ''}`}
            onClick={onClick}
            style={{
                position: isIE ? 'relative' : 'sticky',
                left: isIE ? scrollLeft : 0,
            }}
            aria-disabled={disabled}
        >
            {expandIcon}
            <AddIcon style={{ height: 25, width: 25 }} />
            <div className="title">{title}</div>
        </div>
    );
};

export default AddAction;

interface IProps {
    scrollLeft: number;
    title: string;
    padding: 'padding-1' | 'padding-2';
    action: (...props: any) => void;
    parent?: ILocation;
    disabled: boolean;
    expandIcon?: React.ReactNode;
}
