import { v4 } from 'uuid';
import i18next from 'i18next';
import { EventHandler as BaseEventHandler } from '../../../../../../base/components/Editor/canvas/handlers/EventHandler';
import { IFabricEvent } from '../../../../../../base/components/Editor/interfaces';
import { editorConstants } from '../../constants';

export class EventHandler extends BaseEventHandler {

    /**
     * Attach events to canvas
     */
    attachEvents(): void {

        super.attachEvents();

        if (this.handler.editable) {

            this.handler.canvas.on({
                'selection:created': this.onCreateSelection.bind(this),
            });
        }
    }

    /**
     * Mouse down event handler
     *
     * @param {IFabricEvent} evt
     */
    onMouseDown(evt: IFabricEvent): void {

        const activeObj = this.handler.canvas.getActiveObject();

        const { colors, objects } = editorConstants;

        this.handler.canvas.getObjects()
            .forEach(obj => obj.fill !== colors.inActive && obj.type === objects.marker ? obj.fill = colors.inActive : null);

        if (activeObj?.type === objects.marker && activeObj.fill === colors.inActive) {
            activeObj.fill = colors.active;
        }

        if (this.handler.interactionMode === editorConstants.interaction.modeGrab) {

            this.panning = true;

            return;
        }

        const event = evt as IFabricEvent<MouseEvent>;

        const { editable } = this.handler;

        const { target } = event;

        if (editable) {

            if (this.handler.interactionMode === editorConstants.interaction.modePolygon) {

                this.handler.canvas.discardActiveObject();

                if (target && this.handler.pointArray.length && target.id === this.handler.pointArray[0].id) {

                    if (this.handler.pointArray.length < 3) {

                        return;
                    }

                    this.handler.drawingHandler.polygon.finish(this.handler.pointArray);

                } else {

                    this.handler.drawingHandler.polygon.addPoint(event);
                }
            }

            if (this.handler.interactionMode === editorConstants.interaction.modeMarker) {

                const { absolutePointer } = event;

                if (absolutePointer) {

                    const { x, y } = absolutePointer;

                    this.handler.add({
                        id: v4(),
                        text: i18next.t('G'),
                        type: 'marker',
                        left: x,
                        top: y,
                        zIndex: this.handler.canvas.getObjects().length,
                    });

                    this.handler.interactionHandler.selection();

                    this.handler.canvas.discardActiveObject();
                }
            }

            if (this.handler.interactionMode === editorConstants.interaction.modeGpsPin) {

                const { absolutePointer } = event;

                if (absolutePointer) {

                    const { x, y } = absolutePointer;

                    this.handler.add({
                        id: v4(),
                        text: '',
                        type: 'gpsPin',
                        left: x,
                        top: y,
                        zIndex: this.handler.canvas.getObjects().length + 10,
                    });

                    this.handler.interactionHandler.selection();

                    this.handler.canvas.discardActiveObject();
                }
            }
        }
    }

    /**
     * Create selection event handler
     *
     * @param e
     */
    onCreateSelection(e: any) {

        const activeSelection = e.target;

        const { menuActionMode } = this.handler;

        const { objects, interaction } = editorConstants;

        if (activeSelection) {

            activeSelection.setOptions({
                hasControls: e.selected && (e.selected.length > 1 || [objects.marker, objects.gpsPin].includes(activeSelection.type)) ? false : true,
                lockMovementX: !(menuActionMode === interaction.modeEdit),
                lockMovementY: !(menuActionMode === interaction.modeEdit),
            });

            this.handler.workarea.set({
                lockMovementX: true,
                lockMovementY: true,
            });
        }

        /**
         * Disabling the selection of a group of objects
         */
        if (e.target.type === 'activeSelection') {

            this.handler.canvas.discardActiveObject();

        }
    }
}
