import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectDepartmentState = createSelector(
    selectHrModuleState,
    state => state.departmentState,
);

export const selectDepartments = createSelector(
    selectDepartmentState,
    state => state.departments,
);

