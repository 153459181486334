import { Action } from 'redux';
import { IAppSetting } from '../../services/appSettingsService';

export enum HrModuleActionTypes {
    ACTIVATE_CONFIGURATION_BLOCK = '[Hr Module] Activate configuration block',
    GET_STATUS_HR_MODULE = '[Hr Module] Get module status',
}

export class ActivateConfigurationBlock implements Action {
    readonly type = HrModuleActionTypes.ACTIVATE_CONFIGURATION_BLOCK;
}

export class GetAppSettingModule implements Action {
    readonly type = HrModuleActionTypes.GET_STATUS_HR_MODULE;

    constructor(
        public payload: {
            moduleStatus: IAppSetting,
        },
    ) {}
}

export type HrModuleActions = ActivateConfigurationBlock | GetAppSettingModule;
