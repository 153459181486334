import { sensorConstants } from '../../constants';
import { ISensorListAction, ISensorState } from '../../interfaces';


/**
 * Sensor reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function sensor(state: ISensorState = {
    sensors: [],
    errors: {},
}, action: ISensorListAction): ISensorState {

    switch (action.type) {

        case sensorConstants.LIST_SUCCESS:

            return {
                sensors: action.sensors,
                errors: {},
            };

        case sensorConstants.LIST_FAILURE:

            return {
                sensors: [],
                errors: action.errors,
            };

        default:

            return state;
    }
}
