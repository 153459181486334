import React, { ChangeEvent, Component } from 'react';
import { Button, CommonError, DeleteDialog, DraggableList, Message, ScrollContainer } from '../../../core/ui/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import RightSidebar from '../../Sidebar/RightSidebar';
import StatesForm from './StatesForm';
import { connect } from 'react-redux';
import { causesActions, FormActions, statesActions } from '../../../core/actions';
import { ICause, IStateCategory, IStatesPageState, IStatesProps } from '../../../core/interfaces';
import ReasonForm from './ReasonForm';
import './StatesForm.scss';
import { DraggableLocation } from 'react-beautiful-dnd';
import { RootState } from '../../../core/store';
import { isMobile } from 'react-device-detect';


/**
 *  base interface
 */


interface IMove {
    source: Item[];
    destination: Item[];
    droppableSource: DraggableLocation;
    droppableDestination: DraggableLocation;
    sourceIndex: string | number;
    Destination: string | number;
    destinationCategory: Array<IStateCategory>;
    moveCause: ICause;
}

interface Item {
    id?: number;
    name: string;
    color?: string;
}

interface IReorder {
    draggable: Item[];
    draggableUnsorted: Item[];
    droppable:Item[];
    endIndex: number;
    index: number;
    moved: ICause;
}

/**
 *  list State
 *
 *  @class States
 */
class States extends Component<IStatesProps & WithTranslation, IStatesPageState> {

    constructor(props: IStatesProps & WithTranslation) {

        super(props);

        this.handleSearch = this.handleSearch.bind(this);

        this.editNode = this.editNode.bind(this);

        this.removeConfirmation = this.removeConfirmation.bind(this);

        this.onDragEndStates = this.onDragEndStates.bind(this);

        this.onSidebarClose = this.onSidebarClose.bind(this);

        this.openSidebar = this.openSidebar.bind(this);

        this.confirmAdd = this.confirmAdd.bind(this);

        this.confirmUpdate = this.confirmUpdate.bind(this);

        this.confirmAdd = this.confirmAdd.bind(this);

        this.confirmUpdate = this.confirmUpdate.bind(this);

        this.removeNode = this.removeNode.bind(this);

        this.onDeleteNodeDialogClose = this.onDeleteNodeDialogClose.bind(this);

        this.removeConfirmation = this.removeConfirmation.bind(this);


        this.isLandscape = this.isLandscape.bind(this);
    }

    /**
     *  Default state component
     */
    readonly state: IStatesPageState = {
        addInput: false,
        option: {
            search: {
                value: '',
                handleSearch: this.handleSearch.bind(this),
            },
            menu: [
                {
                    title: this.props.t('EDIT'),
                    action: this.editNode.bind(this),
                    color: '',
                },
                {
                    title: this.props.t('DELETE'),
                    action: this.removeConfirmation.bind(this),
                    color: 'red',
                },
            ],
            colorField: true,
        },
        unsorted: [],

        nodeForm: {
            title: '',
            nodeData: {},
        },
        removeId: null,
        removeType: null,
        removeItemName: null,
    };

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.dataLoader();
    }

    componentDidUpdate(prevProps: Readonly<IStatesProps & WithTranslation>): void {

        if (prevProps !== this.props) {

            this.setState({
                nodeForm: { title: '', nodeData: {} },
            });

        }

        if (this.props.refresh && this.props.refresh !== prevProps.refresh) {

            this.dataLoader();

        }
    }

    /**
     *  Edit current category
     *
     * @param dataItem
     * @param status
     * @param nodeName
     */
    editNode(dataItem: ICause | IStateCategory, status: boolean, nodeName: string) {

        const { nodeForm } = this.state;

        const nodeData: ICause | IStateCategory | Record<string, unknown> = {};

        if (nodeName === 'category') {

            nodeData[nodeName] = dataItem;

            nodeForm['nodeData'] = nodeData;

            this.props.toggleForm(false, nodeName);

        } else {

            nodeData['causes'] = dataItem;

            nodeForm['nodeData'] = nodeData;

            this.props.toggleForm(false, 'causes');

        }

        this.setState({

            nodeForm: nodeForm,

        });


    }

    /**
     *  Remove current category
     *
     * @param dataItem
     * @param status
     * @param nodeName
     */
    removeConfirmation(dataItem: ICause | IStateCategory, status: boolean, nodeName: string) {

        //Remove request
        this.setState({ removeId: dataItem, removeType: nodeName, removeItemName: dataItem.name });

    }

    /**
     *  Handler for the search
     *
     * @param {ChangeEvent<HTMLInputElement>} event
     */
    handleSearch(event: ChangeEvent<HTMLInputElement>) {

        const { option } = this.state;

        option.search.value = event.target.value;

        this.setState({ option: option });

        if (event.target.value.length === 0) {

            this.dataLoader();
        }
    }

    /**
     * Data load handler
     */
    dataLoader() {

        const { loadCauses } = this.props;

        this.props.loadStates('', { column: 'id', dir: 'asc' }, { table: ['causes'] });

        if (loadCauses) {

            loadCauses('', { column: 'id', dir: 'asc' }, undefined, { field: ['stateCategory||$isnull'] });
        }
    }

    /**
     * Sidebar close callback
     */
    onSidebarClose() {

        //anything to do?
    }

    /**
     * Chek landscape.
     *
     * @return {boolean}
     */
    isLandscape() {

        return (window.orientation === 90 || window.orientation === -90);
    }


    /**
     * Cancel delete. For the popup dialog
     */
    onDeleteNodeDialogClose() {

        this.setState({ removeId: null, removeType: null, removeItemName: '' });
    }

    /**
     * Remove Node
     */
    removeNode() {
        const { removeType, removeId } = this.state;

        if (removeType === 'category') {

            if (this.props.deleteState) {

                this.props.deleteState(removeId as IStateCategory);

            }
        } else {

            if (this.props.deleteCauses) {

                this.props.deleteCauses(removeId as ICause);

            }
        }

    }

    /**
     *sidebar opening handler
     *
     * @param {React.MouseEvent<HTMLElement>} event
     * @param {string} title
     */
    openSidebar(event: React.MouseEvent<HTMLElement>, title: string) {

        event.preventDefault();

        event.stopPropagation();

        this.setState({
            nodeForm: {
                nodeData: {},
                title,
            },
        });

        const name = !this.props.openSidebar ? title : '';

        this.props.toggleForm(this.props.openSidebar, name);
    }

    /**
     * 'Confirm add' handler
     *
     * @param {object} data
     * @param {string} name
     */
    confirmAdd(data: ICause | IStateCategory, name: string) {
        const { storeState, storeCauses } = this.props;

        if (name === 'states' && storeState) {

            storeState(data as IStateCategory);

        }

        if (name === 'reason' && storeCauses) {

            storeCauses(data as ICause);

        }

    }

    /**
     * 'Confirm update' handler
     *
     * @param {object} data
     * @param {string} name
     */
    confirmUpdate(data: ICause | IStateCategory, name: string) {

        const { updateState, updateCause } = this.props;

        if (name === 'states' && updateState) {
            updateState(data as IStateCategory);
        }

        if (name === 'reason' && updateCause) {
            updateCause(data as ICause);
        }

    }

    /**
     * 'Drag end' handler
     *
     * @param state
     * @param action
     */
    onDragEndStates(state: IMove | IReorder, action: string) {

        if (action === 'move') {

            const move = state as IMove;

            if (move.destinationCategory.length > 0) {

                this.props.updateCause({
                    id: move.moveCause.id,
                    name: move.moveCause.name,
                    stateCategory: Number(move.destinationCategory[0].id),
                    position: move.droppableDestination.index,
                });

            } else {

                this.props.updateCause({
                    id: move.moveCause.id,
                    name: move.moveCause.name,
                    stateCategory: null,
                    position: move.droppableDestination.index,
                });

            }

        }

        if (action === 'reorder') {

            const move = state as IReorder;

            const updateData = {
                id: move.moved.id,
                name: move.moved.name,
                position: move.endIndex,
                stateCategory: move.index.toString() !== 'unsorted' ? move.index: null,
            };

            this.props.updateCause(updateData);
        }

    }

    /**
     *  Sort cause by orderInCategory
     *
     * @param {IProduct[]} value
     * @return {IProduct[]}
     */
    sortOrderInCategory(value: ICause[]) {

        return value.sort((a, b) => {

            if (a.position === undefined) {

                a.position = 0;
            }

            if (b.position === undefined) {

                b.position = 0;
            }

            return a.position < b.position ? -1 : 1;
        });

    }

    /**
     *  Filter data for Item
     */
    filterFunctionCategory(data: IStateCategory[]) {

        const { option } = this.state;

        const result: IStateCategory[] = [];

        data.forEach(value => {

            if (option.search.value.length > 0) {

                if (value.causes) {

                    const sorted = this.sortOrderInCategory(value.causes);

                    value.causes = sorted.filter((item: { name: string }) => {

                        return item.name.toLowerCase().match(option.search.value.toLowerCase());
                    });
                }

                if (value.name.toLowerCase().includes(option.search.value.toLowerCase()) || value.causes?.length) {

                    result.push(value);
                }

            } else {
                if (value.causes) {

                    const sorted = this.sortOrderInCategory(value.causes);

                    value.causes = sorted;
                }
                result.push(value);
            }
        });

        return result;
    }

    /**
     *  Filter data for Item
     */
    filterFunctionCauses(data: ICause[]) {

        const { option } = this.state;

        if (option.search.value.length > 0) {

            return this.sortOrderInCategory(data).filter(value => value.name.toLowerCase().includes(option.search.value.toLowerCase()));

        } else {

            return this.sortOrderInCategory(data);

        }
    }



    render(): React.ReactElement | string | number | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const { t, formName, unsorted, states, errors } = this.props;

        return (
            <div className="wrapper-states">
                <ScrollContainer
                    maxHeightSlider={`calc(100vh - ${isMobile ? this.isLandscape() ? 40 : 220 : 40}px)`}
                    headerScroll={
                        <React.Fragment>
                            {errors.common &&
                                <>
                                    <CommonError
                                        errors={{
                                            common: t(errors?.status === '404' ? 'STATE_IS_NOT_FOUND' : 'UNKNOWN_SERVER_ERROR')
                                        }}
                                    />
                                </>
                            }
                            <h2 className={'content-title'}>{this.props.t('STATES')}</h2>
                            <div className="btn-wrap states">
                                <Button
                                    type="button"
                                    color={'primary'}
                                    onClick={(e) => this.openSidebar(e, 'category')}
                                >
                                    {this.props.t('ADD_CATEGORY')}
                                </Button>
                                <Button
                                    type="button"
                                    color={'primary'}
                                    onClick={(e) => this.openSidebar(e, 'causes')}
                                >{this.props.t('ADD_STATE')}
                                </Button>
                                <Message
                                    message={this.props.t('DRAG_AND_DROP_TO_REARRANGE')}
                                    hidden
                                />
                            </div>
                        </React.Fragment>
                    }
                    bodyStyle={{
                        paddingTop: 0,
                    }}
                >

                    <DraggableList
                        droppable={states ? this.filterFunctionCategory(states) : []}
                        draggableUnsorted={unsorted ? this.filterFunctionCauses(unsorted) : []}
                        draggable={states ? states : []}
                        addInput={this.state.addInput}
                        option={this.state.option}
                        onDragEnd={this.onDragEndStates}
                        table={'causes'}
                        rightSideContext
                        leftSideColorIcon
                    />
                </ScrollContainer>
                <RightSidebar
                    openSidebar={this.props.openSidebar && (formName === 'category' || formName === 'causes')}
                    close={this.onSidebarClose}
                >

                    {formName && formName === 'category' &&
                    <StatesForm
                        model={Object.keys(this.state.nodeForm.nodeData).length > 0 ? this.state.nodeForm.nodeData.category : null}
                        confirmAdd={this.confirmAdd}
                        confirmUpdate={this.confirmUpdate}
                    />
                    }

                    {formName && formName === 'causes' &&
                    <ReasonForm
                        model={Object.keys(this.state.nodeForm.nodeData).length > 0 ? this.state.nodeForm.nodeData.causes : null}
                        confirmAdd={this.confirmAdd}
                        confirmUpdate={this.confirmUpdate}
                    />
                    }
                </RightSidebar>
                {this.state.removeId !== null ?
                    <DeleteDialog
                        open
                        removeId={this.state.removeId.id as number}
                        heading={this.props.t(this.state.removeType === 'category' ? 'REMOVE_CATEGORY_Q' : 'REMOVE_REASON_Q') + ' "' + this.state.removeItemName + '"?'}
                        body={this.state.removeType === 'category' ? this.props.t('THIS_ACTION_WILL_DELETE_STATES_AND_CANNOT_BE_UNDONE') : this.props.t('THIS_ACTION_WILL_DELETE_REASON_AND_CANNOT_BE_UNDONE')}
                        onAccept={this.removeNode}
                        onClose={this.onDeleteNodeDialogClose}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
    loadStates: statesActions.list,
    loadCauses: causesActions.list,
    updateState: statesActions.updateStates,
    updateCause: causesActions.update,
    storeState: statesActions.store,
    storeCauses: causesActions.store,
    onDragEndStates: statesActions.onDragEndStates,
    deleteState: statesActions.delete,
    deleteCauses: causesActions.delete,
});

const mapStateToProps = (state: RootState) => {

    const { errors, states, refresh } = state.statesList,
        { causes } = state.causesList,
        { formOpened, formName } = state.form;

    return {
        errors,
        states,
        unsorted: causes,
        openSidebar: formOpened,
        formName: formName,
        refresh,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(States));
