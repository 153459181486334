export const hmiObjectConstants = {

    LIST_HMI_OBJECT_SUCCESS: 'HMI_OBJECT_LIST_SUCCESS',
    LIST_HMI_OBJECT_FAILURE: 'HMI_OBJECT_LIST_FAILURE',

    STORE_HMI_OBJECT_SUCCESS: 'HMI_OBJECT_STORE_SUCCESS',
    STORE_HMI_OBJECT_FAILURE: 'HMI_OBJECT_STORE_FAILURE',

    UPDATE_HMI_OBJECT_SUCCESS: 'HMI_OBJECT_UPDATE_SUCCESS',
    UPDATE_HMI_OBJECT_FAILURE: 'HMI_OBJECT_UPDATE_FAILURE',

    DELETE_HMI_OBJECT_SUCCESS: 'HMI_OBJECT_DELETE_SUCCESS',
    DELETE_HMI_OBJECT_FAILURE: 'HMI_OBJECT_DELETE_FAILURE',

    CLEAR_HMI_OBJECT_ERRORS: 'CLEAR_HMI_OBJECT_ERRORS',

    HOVER_HMI_OBJECT: 'HOVER_HMI_OBJECT',
    HOVER_SENSOR_ON_TREE: 'HOVER_SENSOR_ON_TREE',
};
