import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AlertAction, FormActions, NotificationAction, statesActions } from '../../../core/actions';
import {
    IActiveProducts,
    INotification,
    ISensor,
    IStateData,
    IStateItem,
} from '../../../core/interfaces';
import RuleText from '../../../core/ui/components/Board/RuleText';

import settingsIcon from '../../../core/ui/assets/images/settings.png';
import '../../../core/ui/components/Board/styles/Chart.scss';
import { GraphActions } from '../../store/actions';
import { selectHistogramHeight } from '../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { selectGraphBarHeight } from '../../../core/selectors/graphBarHeight/graphBarHeightSelector';
import { selectScreenWidth } from '../../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectRBAC } from '../../../core/selectors/auth/authSelector';
import {
    selectMaxWidthSideBar,
} from '../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import {
    selectGraphSelectionAlert,
    selectGraphSelectionAlertHover,
} from '../../../core/selectors/graphSelection/graphSelectionSelector';
import * as d3 from 'd3';
import { IChartDataWithColor, useDataHistogram } from '../../../hooks/histogramChart/useDataHistogram';
import {
    selectHmiPlayerMode,
    selectHmiPlayerRealTimeStatus,
    selectHmiPlayerSchema,
    selectHmiPlayerValue,
} from '../../../core/selectors/hmi/playerSelector';
import {
    selectDrawerIsResize,
    selectDrawerWidthWithDrawRules,
    selectPositionDrawer,
    selectStartTouchEventDrawer,
} from '../../../core/selectors/layout/responsiveDrawerSelector';
import { selectHmiPlayerVisibility } from '../../../core/selectors/hmi/visibilitySelector';
import { usePrevious } from '../../../hooks/usePrevious';
import isEqual from 'lodash/isEqual';
import { isMobileOnly } from 'react-device-detect';
import HistogramWatcher from '../../../core/watchers/histogramWatcher';
import { selectHistogramMode } from '../../../core/selectors/graphHistogramMode/graphHistogramModeSelector';
import { cancelable, CancelablePromise } from 'cancelable-promise';
import HistogramSkeleton from './HistogramSkeleton';


interface IProps {
    forceMix?: boolean;
    dataState?: IStateData;
    sensor: ISensor;
    sensorTargetValue?: {
        activeProducts: IActiveProducts[],
        targetValues: {
            maxTargetValue: number | null,
            minTargetValue: number | null,
            productId: number;
            sensorId: number;
        }[]
    };
    hrMode?: boolean;
}

const scalePosition = d3.scaleTime();

let prevDrawWidth = 500;
/**
 * A D3 Bar chart component
 *
 * @class HistogramChart
 */
const HistogramChart: React.FC<IProps> = (
    {
        sensor,
        sensorTargetValue,
        hrMode = false,
        forceMix = false,
        dataState,
    }: IProps,
) => {

    const dispatch = useDispatch();
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const alertRef = useRef<HTMLCanvasElement | null>(null);
    const targetValueRef = useRef<HTMLCanvasElement | null>(null);
    const updateChartRef = useRef<CancelablePromise<unknown>>();
    const calcPointNotificationTitleRef = useRef<CancelablePromise<unknown>>();
    const createWrapRectangleRef = useRef<CancelablePromise<unknown>>();
    const graphSelectionAlertHoverRef = useRef<CancelablePromise<unknown>>();
    const chartWrapperRef = useRef<HTMLDivElement | null>(null);
    const [chartContext, setChartContext] = useState<CanvasRenderingContext2D | null>(null);
    const [alertContext, setAlertContext] = useState<CanvasRenderingContext2D | null>(null);
    const [targetValueContext, setTargetValueContext] = useState<CanvasRenderingContext2D | null>(null);
    const [mixMode, setMixMode] = useState<boolean>(forceMix);
    const [barWidth] = useState<number>(1);
    const [dataPointItem, setDataPointItem] = useState< any[]>([]);
    const [supportsTouch, setSupportsTouch] = useState<boolean>(false);
    const [selectedAlert, setSelectedAlert] = useState<any | null>(null);

    const watcher = new HistogramWatcher(sensor, mixMode, hrMode, forceMix, dataState);

    useEffect(() => {

        watcher.setStates(dataState?.states||[]);

    }, [dataState, watcher]);


    const [
        dataWithAlertColor,
        alertData,
        showLoader,
    ] = useDataHistogram(sensor, hrMode, mixMode, watcher.sensorPreferences.color);

    const schema = useSelector(selectHmiPlayerSchema);
    const isVisible = useSelector(selectHmiPlayerVisibility) && schema !== null;
    const preDataWithAlertColor = usePrevious(dataWithAlertColor);
    const drawWidth = useSelector(selectDrawerWidthWithDrawRules);
    const isResize = useSelector(selectDrawerIsResize);
    const realTimeStatus = useSelector(selectHmiPlayerRealTimeStatus);

    const histogramHeight = useSelector(selectHistogramHeight),
        stateHeight = useSelector(selectGraphBarHeight),
        mode = useSelector(selectHistogramMode),
        prevMode = usePrevious(mode),
        selection: Date[] = useSelector(selectBrushSelection),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        screenWidthOrigin = useSelector(selectScreenWidth),
        screenWidth = screenWidthOrigin - (!isMobileOnly && isVisible && anchor === 'right' ? isResize ? prevDrawWidth : drawWidth : 0),
        brushSelection = useSelector(selectBrushSelection),
        rbac = useSelector(selectRBAC),
        maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        graphSelectionAlert = useSelector(selectGraphSelectionAlert),
        graphSelectionAlertHover = useSelector(selectGraphSelectionAlertHover),
        value = useSelector(selectHmiPlayerValue),
        sideBarLogic = JSON.parse(localStorage.getItem('sidebar') as string),
        scaleTime: d3.ScaleTime<number, number> = d3.scaleTime(),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode),
        realTimeIndentation = useSelector(calcRealTimeIndentation),
        startTouchEventDrawer = useSelector(selectStartTouchEventDrawer);

    const { graphPreferences } = sensor;

    scalePosition.range([0, screenWidth - 1.5])
        .domain(selection);

    let isMounted = true;

    const height = forceMix ? stateHeight : histogramHeight;

    useEffect(() => {

        if (!isResize) {

            prevDrawWidth = drawWidth;
        }

    }, [isResize, drawWidth]);


    useEffect(() => {

        checkTouchSupport();

        if (chartRef.current) {

            chartRef.current.width = screenWidth - realTimeIndentation;
            chartRef.current.height = height;

            setChartContext(chartRef.current.getContext('2d'));
        }

        if (alertRef.current) {

            alertRef.current.width = screenWidth - realTimeIndentation;
            alertRef.current.height = height;

            setAlertContext(alertRef.current.getContext('2d'));
        }

        if (targetValueRef.current) {

            targetValueRef.current.width = screenWidth - realTimeIndentation;
            targetValueRef.current.height = height;

            setTargetValueContext(targetValueRef.current.getContext('2d'));
        }

        return ()=> {
            watcher.unsubscribe();
            isMounted = false;
            if (calcPointNotificationTitleRef.current?.cancel()) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                calcPointNotificationTitleRef.current!.cancel();
            }
            if (createWrapRectangleRef.current?.cancel()) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                createWrapRectangleRef.current!.cancel();
            }
            if (graphSelectionAlertHoverRef.current?.cancel()) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                graphSelectionAlertHoverRef.current!.cancel();
            }
            if (updateChartRef.current?.cancel) {

                updateChartRef.current.cancel();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {


        if (alertContext && alertData.length > 0) {

            if (calcPointNotificationTitleRef.current?.cancel()) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                calcPointNotificationTitleRef.current!.cancel();
            }

            calcPointNotificationTitleRef.current = cancelable(watcher.calcPointNotificationTitle(isMounted, alertContext, alertData, barWidth));

            if (createWrapRectangleRef.current?.cancel()) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                createWrapRectangleRef.current!.cancel();
            }
            if (graphSelectionAlert) {

                createWrapRectangleRef.current = cancelable(watcher.createWrapRectangle(alertContext, alertData, graphSelectionAlert));
            }

            if (graphSelectionAlertHoverRef.current?.cancel()) {

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                graphSelectionAlertHoverRef.current!.cancel();
            }
            if (graphSelectionAlertHover) {

                graphSelectionAlertHoverRef.current = cancelable(watcher.createWrapRectangle(alertContext, alertData, graphSelectionAlertHover));
            }


        }

    }, [watcher, selectedAlert, alertContext, alertData, dataWithAlertColor, graphSelectionAlert, graphSelectionAlertHover, isVisible, barWidth, isMounted]);


    /**
     * Check supports touch
     */
    const checkTouchSupport = () => {

        if ('ontouchstart' in window) {

            //iOS & android
            setSupportsTouch(true);

            // this.props.peakEnterTouch({ touches: [{ clientX: 440 }] }, this.supportsTouch, this.props.data);
        }
    };

    /**
     * Get State by timestamp
     *
     * @param {number} timestamp
     * @return {IStateItem | null}
     */
    const getStateByTimestamp = useCallback((timestamp: number) => {

        const statesPerInterval = watcher.generateStateIntervals();

        const result = statesPerInterval.find(s => s.start !== null && s.start <= timestamp && s.end !== null && s.end > timestamp);

        return result ? result.state : null;

    }, [watcher]);


    /**
     * Render chart peak
     *
     * @param {number} index
     * @param {number} y
     * @param {number} height
     * @param {string} color
     * @param {string} key
     *
     * @return {JSX.Element}
     */
    const renderPeak = useCallback((index: number, y: number, height: number, color: string, barWidth: number, timestamp: Date) => {
        scaleTime.range([0, (screenWidth - realTimeIndentation)])
            .domain(selection);
        const tick: number = index === (dataWithAlertColor.length - 1) ?
            new Date(dataWithAlertColor[index].timestamp).getTime() - new Date(dataWithAlertColor[index - 1]?.timestamp).getTime()
            :
            new Date(dataWithAlertColor[index + 1]?.timestamp).getTime() - new Date(dataWithAlertColor[index].timestamp).getTime();

        const currentTimestamp = new Date(timestamp);
        const nextTimestamp = new Date(new Date(timestamp).getTime() + tick);
        const scaledIndex: number = Math.floor(scaleTime(currentTimestamp) as number);
        const scaledIndexNext: number = Math.ceil(scaleTime(nextTimestamp) as number);

        if (chartContext) {

            chartContext.moveTo(scaledIndex, y);

            chartContext.fillStyle = color;

            chartContext.fillRect(
                scaledIndex,
                y,
                (scaledIndexNext - scaledIndex),
                height,
            );

        }

    }, [chartContext, dataWithAlertColor, realTimeIndentation, scaleTime, screenWidth, selection]);


    /**
     * Draw a horizontal line for the target value
     *
     * @param {number} x1
     * @param {number} x2
     * @param {number} y
     * @param {string} color
     */
    const drawLine = useCallback((x1: number, x2: number, y: number, color: string) => {

        if (targetValueContext) {

            // targetValueContext.clearRect(0, 0, (screenWidth - realTimeIndentation), height);

            targetValueContext.beginPath();
            targetValueContext.strokeStyle = color;
            targetValueContext.moveTo(x1, y);
            targetValueContext.lineWidth = 0.5;
            targetValueContext.lineTo(x2, y);
            targetValueContext.stroke();
            targetValueContext.closePath();
        }
    }, [targetValueContext]);

    //
    // useEffect(() => {
    //
    //     if (dataWithAlertColor.length > 0) {
    //
    //         if (isMobileOnly ? (!isEqual(dataWithAlertColor, preDataWithAlertColor)
    //             || (!isVisible && isEqual(dataWithAlertColor, preDataWithAlertColor))
    //             || (isVisible && !isEqual(dataWithAlertColor, preDataWithAlertColor))) : dataWithAlertColor) {
    //
    //             const calculateBarWidth = Math.abs((screenWidth - realTimeIndentation) / (dataWithAlertColor.length));
    //
    //         setBarWidth(!isFinite(calculateBarWidth) ?
    //                 barWidth : calculateBarWidth !== 0 ? +calculateBarWidth: 1);
    //         }
    //     }
    //
    // }, [
    //     dataWithAlertColor,
    //     barWidth,
    //     realTimeIndentation,
    //     screenWidth,
    //     isVisible,
    //     preDataWithAlertColor,
    // ]);

    const setDataPointItemCallback = useCallback((dataPoint: any[]) => {
        setDataPointItem(dataPoint);
    }, []);

    /**
     * Update chart with new data
     */
    const updateChart = useCallback(() => {

        if (brushSelection && chartContext && (screenWidth >= 0 ||
            JSON.stringify(preDataWithAlertColor) !== JSON.stringify(dataWithAlertColor) ||
            (mode !==prevMode)
        )) {

            if (dataWithAlertColor && !startTouchEventDrawer) {
                
                if (updateChartRef.current?.cancel) {

                    updateChartRef.current.cancel();
                }

                updateChartRef.current = cancelable(watcher.calcPointForDraw(dataWithAlertColor, isMounted, barWidth).then(dataPoint => {

                        if (!startTouchEventDrawer) {

                            chartContext.clearRect(0, 0, (screenWidth - realTimeIndentation), height);

                            setDataPointItemCallback(dataPoint);

                            dataPoint.map(dataPointItem => renderPeak(
                                dataPointItem.index,
                                dataPointItem.y,
                                dataPointItem.height,
                                dataPointItem.color,
                                dataPointItem.barWidth,
                                dataPointItem.timestamp,
                            ));
                        }
                    })
                    .then(() => {

                            watcher.calcPointTargetValue(isMounted, sensorTargetValue).then(dataPoint => {
                                if (!startTouchEventDrawer) {
                                    if (targetValueContext) {

                                        targetValueContext.clearRect(0, 0, (screenWidth - realTimeIndentation), height);

                                        dataPoint.map(dataPointItem => drawLine(
                                            dataPointItem.x1,
                                            dataPointItem.x2,
                                            dataPointItem.y,
                                            dataPointItem.color,
                                        ));
                                    }
                                }
                            });
                        }),
                );
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dataWithAlertColor,
        chartContext,
        screenWidth,
        sensorTargetValue,
        height,
        preDataWithAlertColor,
        isMounted,
        startTouchEventDrawer,
        mode,
        prevMode,
        barWidth,
        dataState,
        graphPreferences,
        setDataPointItemCallback,
    ]);

    useEffect(() => {

        //update render check
        // if (!isEqual(dataWithAlertColor, preDataWithAlertColor)
        //     ||(!isVisible && isEqual(dataWithAlertColor, preDataWithAlertColor))
        //     ||(isVisible && !isEqual(dataWithAlertColor, preDataWithAlertColor))
        // ) {
        if (!startTouchEventDrawer) {

            if (isMobileOnly ? (!isEqual(dataWithAlertColor, preDataWithAlertColor)
                || (!isVisible && isEqual(dataWithAlertColor, preDataWithAlertColor))
                || (isVisible && !isEqual(dataWithAlertColor, preDataWithAlertColor))) : dataWithAlertColor) {

                updateChart();
            }
        }
    }, [dataWithAlertColor, preDataWithAlertColor, updateChart, isVisible, startTouchEventDrawer]);

    /**
     * Show state details, when it have a comment
     *
     * @param state
     */
    const showStateDetails = useCallback((state: IStateItem | null) => {

        if (state) {

            dispatch(statesActions.toggleStateDetails(true, state));

        } else {

            dispatch(statesActions.toggleStateDetails(false));
        }
    }, [dispatch]);

    /**
     * Hide graph settings icon
     */
    const hideStateDetails = useCallback((event) => {

        event.preventDefault();

        if (mixMode) {

            showStateDetails(null);

        }

        if (!supportsTouch) {

            if (HMIPlayerStatus === 'stop') {

                dispatch(GraphActions.peakLeave());
            }

            if (HMIPlayerStatus === 'pause') {

                dispatch(GraphActions.peakEnterEmptyLine(Math.ceil(scalePosition(new Date(value)) || 0)));
            }
        }

        dispatch(AlertAction.toggleStateDetails(false));

    }, [dispatch, mixMode, showStateDetails, supportsTouch, HMIPlayerStatus, value]);

    /**
     * Show graph settings edit form
     */
    const editSettings = useCallback((event) => {

        event.preventDefault();
        event.stopPropagation();

        if (sensor.graphPreferences && sensor.graphPreferences[0]) {

            const preference = sensor.graphPreferences[0];

            preference['sensor'] = sensor.id;

            dispatch(FormActions.toggle(false, 'histogramForm', { ...preference, sensorName: sensor.name }));
        }
    }, [dispatch, sensor]);


    /**
     * Toggle histogram mix mode with state chart
     */
    const toggleMixMode = useCallback((event) => {

        event.preventDefault();

        if (!forceMix && sensor.isKeyParameter && dataState) {

            if (mixMode) {

                showStateDetails(null);
            }

            setMixMode(!mixMode);
        }
    }, [mixMode, setMixMode, sensor, forceMix, dataState, showStateDetails]);

    const getCurrentValueByTimeScale = useCallback((
        data: IChartDataWithColor[],
        x: number,
    ): IChartDataWithColor => {

        const [from, to] = selection;

        const screenScale = d3.scaleLinear().domain([from, to].map(d => d.getTime())).range([0, screenWidth - realTimeIndentation]);
        const valueScale = d3.scaleLinear().domain(
            [new Date(data[0]?.timestamp || selection[0]).getTime(),
                new Date(data[data.length - 1]?.timestamp || selection[1]).getTime()],
        ).range([0, data.length - 1]);

        const realTimePositionTimestamp = screenScale.invert(screenWidth - realTimeIndentation);
        const realTimePositionInValues = valueScale(realTimePositionTimestamp);

        // convert x to index in valueScale
        // convert x to index in valueScale
        const xTimestamp = screenScale.invert(x);
        const valueIndex = valueScale(xTimestamp);
        let index = Math.round(valueIndex as number);

        if (realTimeStatus && isVisible) {

            index = Math.round(realTimePositionInValues as number);
        }

        if (index < 0) {

            index = 0;
        }

        const returnData = data[Math.abs(index)];


        return returnData ? returnData : x > 0 ? data[data.length - 1] : data[0];

    }, [
        isVisible,
        realTimeStatus,
        screenWidth,
        selection,
        realTimeIndentation,
    ]);

    /**
     * Show state details, when it have a comment
     *
     * @param index
     */
    const showAlertDetails = useCallback(async(index: number) => {

        if (alertData.length > 0) {

            if (selection) {
                const dataWithAlert = getCurrentValueByTimeScale(dataWithAlertColor, index);

                if (dataWithAlert?.alert) {

                    dispatch(AlertAction.toggleStateDetails(true, dataWithAlert.alert));
                } else if (!dataWithAlertColor.length && alertData.length) {

                    scalePosition.range([0, screenWidth - realTimeIndentation])
                        .domain(selection);

                    const cursorTime = scalePosition.invert(index);

                    const alert = alertData.find(alert => {

                        if (alert.startTime && alert.endTime) {
                            return cursorTime >= new Date(alert.startTime) && cursorTime <= new Date(alert.endTime);
                        } else if (alert.startTime && !alert.endTime) {
                            return cursorTime >= new Date(alert.startTime);
                        } 
    
                        return false;
                    }) || false;

                    alert 
                        ? dispatch(AlertAction.toggleStateDetails(true, alert))
                        : dispatch(AlertAction.toggleStateDetails(false));

                } else {

                    dispatch(AlertAction.toggleStateDetails(false));
                }

            }
        }

    }, [dispatch, selection, alertData, dataWithAlertColor, getCurrentValueByTimeScale, scalePosition, screenWidth]);

    /**
     * Handling Alert Selection in state
     *
     * @param { index } index
     */
    const selectedAlertCallback = useCallback(async(index: number) => {

        if (!isMounted) {
            return;
        }

        if (selection) {

            const dataWithAlert = getCurrentValueByTimeScale(dataWithAlertColor, index);

            if (dataWithAlert?.alert) {

                dispatch(FormActions.toggle(false, 'alert-sidebar'));

                if (dataWithAlert.alert.isNew) {


                    dispatch(NotificationAction.markAsReadAction({
                        ...dataWithAlert.alert as unknown as INotification,
                        isNew: false,
                    }));

                }

                const x1 = scaleTime(new Date(dataWithAlert.alert.startTime)) || 0,
                    x2 = scaleTime(new Date(dataWithAlert.alert.endTime || selection[1])) || 0;
                const width = (x2) - (x1);

                dispatch(GraphActions.selectAlertGraph(dataWithAlert.alert, {
                    left: x1,
                    width: width,
                }));

                setSelectedAlert(dataWithAlert.alert);

            } else if (!dataWithAlertColor.length && alertData.length) {

                scalePosition.range([0, screenWidth - realTimeIndentation])
                    .domain(selection);

                const cursorTime = scalePosition.invert(index);

                const alert = alertData.find(alert => {

                    if (alert.startTime && alert.endTime) {
                        return cursorTime >= new Date(alert.startTime) && cursorTime <= new Date(alert.endTime);
                    } else if (alert.startTime && !alert.endTime) {
                        return cursorTime >= new Date(alert.startTime);
                    } 

                    return false;
                });

                if (alert) {

                    const alertData = { ...alert, id: alert.notificationId };

                    dispatch(FormActions.toggle(false, 'alert-sidebar'));

                    const x1 = scaleTime(new Date(alertData.startTime)) || 0;

                    const x2 = scaleTime(new Date(alertData.endTime || selection[1])) || 0;

                    const width = x2 - x1;

                    dispatch(GraphActions.selectAlertGraph(alertData, { left: x1, width }));

                    setSelectedAlert(alertData);
                }

            } else {
                dispatch(GraphActions.deselectAlertGraph());
            }

        }

    }, [dispatch, setSelectedAlert, mixMode, scaleTime, screenWidth, selection, realTimeIndentation, isMounted, scalePosition]);

    /**
     * On click handler
     *
     * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
     */
    const onClickCanvasHandler = useCallback((event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {

        event.preventDefault();

        const index = (event.pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar));

        selectedAlertCallback(index).then(value1 => value1);

    }, [maxWidthSideBar, sideBarLogic, selectedAlertCallback]);

    /**
     * Оn mouse move handler
     *
     * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
     */
    const onMouseMoveCanvasHandler = useCallback(async(event: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        const settingLogic = event.currentTarget.getAttribute('itemID') === 'realtime-setting';

        const index = (event.pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar));

        if (settingLogic) {
            event.stopPropagation();
        }

        //this one is too heavy. Try to do anything around, when backend will be ready
        if (mixMode && !settingLogic) {

            const dataValue = dataWithAlertColor[Math.ceil(index / barWidth)];

            const state = dataValue && getStateByTimestamp(new Date(dataValue.timestamp).getTime());

            if (state) {
                showStateDetails(state);
            }
        }

        if (alertData.length && !settingLogic) {

            showAlertDetails(index).then(value1 => value1);
        }

        if ((HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') && !settingLogic) {

            dispatch(GraphActions.peakEnter(index, supportsTouch));
        }

    }, [
        dispatch,
        supportsTouch,
        alertData,
        showStateDetails,
        getStateByTimestamp,
        maxWidthSideBar,
        sideBarLogic,
        showAlertDetails,
        dataWithAlertColor,
        mixMode,
        HMIPlayerStatus,
        barWidth,
    ]);


    /**
     * On touch start handler
     *
     * @param {React.TouchEvent<HTMLCanvasElement>} event
     */
    const onTouchStartCanvasHandler = useCallback((event: React.TouchEvent<HTMLCanvasElement | HTMLDivElement>) => {

        // event.preventDefault();

        const settingLogic = event.currentTarget.getAttribute('itemId') === 'realtime-setting';

        if ((HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') && !settingLogic) {
            const index = event.touches[0].pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar);

            dispatch(GraphActions.peakEnterTouch(index >= 0? index : 0, supportsTouch));
        }

        if (settingLogic) {
            event.stopPropagation();
        }

    }, [dispatch, maxWidthSideBar, supportsTouch, sideBarLogic, HMIPlayerStatus]);

    /**
     * On touch move handler
     *
     * @param {React.TouchEvent<HTMLCanvasElement>} event
     */
    const onTouchMoveCanvasHandler = useCallback((event: React.TouchEvent<HTMLCanvasElement | HTMLDivElement>) => {

        // event.preventDefault();

        const settingLogic = event.currentTarget.getAttribute('itemId') === 'realtime-setting';

        if ((HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') && !settingLogic) {

            dispatch(GraphActions.peakEnterTouch((event.touches[0].pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar)), supportsTouch));
        }

        if (settingLogic) {

            event.stopPropagation();
        }

    }, [dispatch, supportsTouch, maxWidthSideBar, sideBarLogic, HMIPlayerStatus]);

    /**
     * deselect states
     * @type {() => void}
     */
    const deselectStates = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        event.preventDefault();

        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);

    const leftPositionSettingIcon: React.CSSProperties = { left: screenWidth - 26};
    
    return (
        <div
            className="chart-wrapper"
            ref={chartWrapperRef}
            onClick={deselectStates}
            onDoubleClick={toggleMixMode}
            onMouseLeave={hideStateDetails}
        >
            <HistogramSkeleton
                height={height}
                realTimeIndentation={realTimeIndentation}
                screenWidth={screenWidth}
                dataWithAlertColor={dataPointItem}
                sensorType={sensor.sensorType}
                showLoader={showLoader}
            />
            <RuleText hrMode={hrMode} data={dataWithAlertColor || []}  sensor={sensor} barWidth={barWidth} />
            {rbac.can('histogram-setting:update') && !hrMode && !forceMix ? (
                <div
                    className={`graph-settings ${selection && new Date(selection[1]) > (new Date()) ? 'realtime': ''}`}
                    onClick={editSettings}
                    onMouseMove={onMouseMoveCanvasHandler}
                    onTouchStart={onTouchStartCanvasHandler}
                    onTouchMove={onTouchMoveCanvasHandler}
                    style={leftPositionSettingIcon}
                    itemID={realTimeIndentation > 0 ? 'realtime-setting': 'setting'}
                >
                    <img src={settingsIcon} alt="icon" />
                </div>
            ) : null}
            <canvas
                ref={chartRef}
                className="chart"
                width={screenWidth - realTimeIndentation}
                height={height}
            />
            <canvas
                ref={targetValueRef}
                className="chart-target-value"
                width={screenWidth - realTimeIndentation}
                height={height}
            />
            <canvas
                ref={alertRef}
                className="chart-alert"
                width={screenWidth - realTimeIndentation}
                height={height}
                onClick={onClickCanvasHandler}
                onMouseMove={onMouseMoveCanvasHandler}
                onTouchStart={onTouchStartCanvasHandler}
                onTouchMove={onTouchMoveCanvasHandler}
            />
        </div>
    );
};

export default React.memo(HistogramChart);