import { createSelector } from 'reselect';
import { RootState } from '../../store';


const selectGraphHistogramMode =  (state: RootState) => state.graphHistogramMode;

export const selectHistogramMode = createSelector(
    selectGraphHistogramMode,
    (graphHistogramMode) => graphHistogramMode.mode || 'default',
);
