import { stateConstants } from '../../constants';
import { IStateDetailsState, IStateToggleDetailsAction } from '../../interfaces';



/**
 * Toggle state details reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function stateDetails(state: IStateDetailsState = {
    visible: false,
}, action: IStateToggleDetailsAction): IStateDetailsState {

    switch (action.type) {

        case stateConstants.SHOW_DETAILS:

            return {
                visible: true,
                state: action.state,
            };

        case stateConstants.HIDE_DETAILS:

            return {
                visible: false,
            };

        default:

            return state;
    }
}
