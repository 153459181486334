import React, { Component } from 'react';
import { Button, TextInput, ConfirmDialog } from '../../../core/ui/components';
import { Formik } from 'formik';
import './FormNode.scss';
import {
    IData,
    IError,
    IFactory,
    IFlatTreeFactory,
    IFlatTreeProcess,
    IFlatTreeUnit,
    IOrder,
} from '../../../core/interfaces';
import * as yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as FactoryIcon } from '../../../core/ui/assets/images/icons/folder-factory.svg';
import { connect } from 'react-redux';
import { ConfigurationActions, FactoryActions, FormActions } from '../../../core/actions';
import FormSelect from './FormSelect/FormSelect';
import { RootState } from '../../../core/store';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';


/**
 * Default Interface
 */

interface IOptions {
    label: string;
    value: string;
}

interface IFormValues {
    id?: number;
    factories: string;
    name: string;
    comment: string;
}

interface IProps {
    label?: string;
    load?: (search?: string, order?: IOrder) => void;
    factories: IFactory[];
    bulkUpdate: (factories: IData[], updateConfig: boolean, reloadTree: boolean) => void;
    factoryData: IData | null | undefined;
    activeFactory?: (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[];
    errors?: IError;
    i18n?: WithTranslation;
    tReady?: WithTranslation;
    t?: WithTranslation;
    openSidebar: boolean;
    toggleForm: (opened: boolean, name?: string) => void;
    loadTree: (trackerLogic?: boolean) => void;
    clearErrorChange: () => void;
}

interface IState {
    initialValues: IFormValues;
    enableReinitialize: boolean;
    selectedValue: string;
    options: IOptions[];
    confirm: boolean;
    closeFormAwaitLogic: boolean;
}

/**
 * FormSelect
 *
 * @class FormFactory
 */
class FormFactory extends Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t } = props;

        this.validationSchema = yup.object().shape({
            factories: yup.string().required(t('FACTORY_IS_REQUIRED')),
            name: yup.string()
                .trim()
                .max(30, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '30' }))
                .required(t('FACTORY_NAME_IS_REQUIRED')),
            comment: yup.string()
                .max(300, t('MAX_COMMENT_COMMENT'))
                .nullable(),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.handleChangeSelect = this.handleChangeSelect.bind(this);

        this.closeSidebar = this.closeSidebar.bind(this);

        this.handleChangeSelect = this.handleChangeSelect.bind(this);

        this.closeSidebar = this.closeSidebar.bind(this);

        this.closeFormAwait = this.closeFormAwait.bind(this);
    }

    /**
     * Current state
     */
    readonly state: IState = {
        initialValues: {
            factories: this.props.factoryData?.current.id.toString() || '',
            comment: this.props.factoryData?.current.comment || '',
            name: this.props.factoryData?.current.name || '',
        },
        enableReinitialize: true,
        selectedValue: '',
        options: [],
        confirm: false,
        closeFormAwaitLogic: false,
    };


    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        if (this.props.load) {

            this.props.load();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>) {

        if (this.props.factoryData && this.props.factoryData !== prevProps.factoryData) {

            this.updateInitValue();
        }

        if (prevProps.factories !== this.props.factories) {
            const { options, initialValues } = this.state,
                { factories, factoryData } = this.props;

            if (factories) {
                factories.forEach((value) => {
                    if (Boolean(factoryData) && factoryData?.current.id === value.id && value.id) {

                        initialValues.comment = value.comment;
                        options.push({
                            label: value.name.toString(),
                            value: value.id.toString(),
                        });
                    }
                    if (!factoryData && !value.isConfigured && value.id) {
                        options.push({
                            label: value.name.toString(),
                            value: value.id.toString(),
                        });
                    }
                });
            }
            this.setState({ options: options, initialValues: initialValues });
        }
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, { name: string; factories: string; comment: string | null }>>;

    /**
     *  Handler submit form
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        if (values.factories && values.name) {

            this.setState({ confirm: true, initialValues: values });
        }
    }

    /**
     * Handler for the cancel form dialog
     * Reset field form
     */
    handleCancel() {

        this.setState({ confirm: false });

    }

    updateInitValue() {
        const { initialValues } = this.state;
        if (this.props.factoryData && this.props.factoryData.current) {
            initialValues.id = this.props.factoryData.current.id;
            initialValues.name = this.props.factoryData.current.name;
            this.setState({ initialValues: initialValues });
        }
    }

    /**
     * Handler cancel dialog form
     */
    onConfirmDialog() {

        const { initialValues } = this.state,
            { bulkUpdate, factoryData } = this.props;

        const data: IData[] = [];

        data.push({
            id: initialValues.id || factoryData?.current.id,
            name: initialValues.name.trim(),
            comment: initialValues.comment,
            isConfigured: true,
        });

        bulkUpdate(data, true, !factoryData?.current.id);

        this.closeFormAwait();

    }

    /**
     * Clear error in form
     */
    closeFormAwait() {
        const { toggleForm } = this.props;

        const setTimeoutCallback = setTimeout(() => {

            if (this.props.errors && 'message' in this.props.errors) {

                return;
            }

            toggleForm(true, '');

            this.setState({ closeFormAwaitLogic: false });

            clearTimeout(setTimeoutCallback);
        }, 150);

    }


    /**
     *  Handler Change select
     *  re-init  initialValues with choose param
     *
     * @param {React.ChangeEvent} event
     */
    handleChangeSelect(event: React.ChangeEvent<{ name?: string; value: unknown }>) {

        const { initialValues } = this.state;
        const fieldData = this.props.factories.filter(value => value.id === parseInt(String(event.target.value)));

        initialValues['factories'] = String(event.target.value);
        initialValues['name'] = String(fieldData[0].name.toString());
        initialValues['comment'] = String((fieldData[0].comment || '').toString());
        initialValues['id'] = parseInt(String(event.target.value));

        this.setState({ initialValues: initialValues });

        this.forceUpdate();
    }

    /**
     * Sidebar close handler
     */
    closeSidebar():void {

        this.props.toggleForm(this.props.openSidebar);

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { errors = {}, t, clearErrorChange } = this.props;

        return (
            <React.Fragment>
                <div className="form-box form-box-space node-form">

                    <Formik
                        initialValues={this.state.initialValues}
                        enableReinitialize
                        validationSchema={this.validationSchema}
                        onSubmit={this.handleSubmit}
                    >{props => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <div className="section wrap-form-node">
                                <div className="table-header">
                                    <div
                                        className={'title'}
                                    >{this.props.factoryData ? this.props.t('CHANGE_FACTORY') : this.props.t('ADD_FACTORY')}
                                    </div>
                                    <FactoryIcon className={'title-icon'} />
                                </div>
                                {errors && errors.field === 'name' &&
                                <div className="common-error">{t('THE_FACTORY_HAS_ALREADY_BEEN_TAKEN')}</div>}
                                <div className="table-body">
                                    <div className="form-group select-unit select">
                                        <FormSelect
                                            formProps={props}
                                            handleChangeSelect={this.handleChangeSelect}
                                            options={this.state.options}
                                            disabledSelect={Boolean(this.props.factoryData)}
                                            name={'factories'}
                                            placeholder={this.props.t('SELECT_FACTORY')}
                                            label={this.props.t('SELECT_AN_AVAILABLE_FACTORY')}
                                            reset
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.name ||  errors.field === 'name' ? props.errors.name || errors.field === 'name' ? 'error-field' : 'success-field' : '')
                                            }
                                            label={this.props.t('CHANGE_NAME')}
                                            onChange={event => {
                                                props.handleChange(event);

                                                clearErrorChange();
                                            }}
                                            onBlur={props.handleBlur}
                                            value={props.values.name}
                                            name="name"
                                            type="text"
                                            placeholder={this.props.t('ENTER_NAME')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {props.touched.name && (props.errors.name || errors.field === 'name') &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.name}
                                            </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.comment ? props.errors.comment ? 'error-field' : 'success-field' : '')
                                            }
                                            label={this.props.t('COMMENT')}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.comment?props.values.comment:''}
                                            name="comment"
                                            type="text"
                                            placeholder={this.props.t('ENTER_COMMENT')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {((props.touched.comment && props.errors.comment)) &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.comment}
                                            </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group btn-group">

                                        <Button
                                            type="reset"
                                            color={'primary'}
                                            onClick={this.closeSidebar}
                                        >
                                            {this.props.t('CANCEL')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color={'secondary'}
                                        >
                                            {this.props.factoryData ? this.props.t('SAVE_CHANGES') : this.props.t('ADD')}
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    )}
                    </Formik>
                </div>
                <ConfirmDialog
                    heading={this.props.factoryData ? this.props.t('CHANGE_FACTORY_Q') : this.props.t('ADD_FACTORY_Q')}
                    onAccept={this.onConfirmDialog}
                    onClose={this.handleCancel}
                    open={this.state.confirm}
                />
            </React.Fragment>
        );
    }
}


/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */

const mapStateToProps = (state: RootState) => {

    const { factories } = state.factory,
        { items } = state.configurationTree,
        { formModel } = state.form,
        { errors } = state.factoryChange;

    return {
        factories,
        errors,
        activeFactory: items,
        factoryData: formModel || null,
    };
};


/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    load: FactoryActions.list,
    bulkUpdate: FactoryActions.bulkUpdate,
    clearErrorChange: FactoryActions.clearErrorChange,
    toggleForm: FormActions.toggle,
    loadTree: ConfigurationActions.list,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormFactory));

