import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

import { UserActions } from '../../core/actions';
import { Button, TextInput, CommonError } from '../../core/ui/components';
import { IAuthState, IRestorePasswordProps } from '../../core/interfaces';
import { Helmet } from 'react-helmet';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';


interface IFormValues {
    password: string;
    password_confirmation: string;
}

/**
 * Restore password page component
 *
 * @class RestorePassword
 */
class RestorePassword extends React.Component<IRestorePasswordProps & WithTranslation> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IRestorePasswordProps & WithTranslation) {

        super(props);

        const { t } = this.props;

        this.initialValues = {
            password: '',
            password_confirmation: '',
        };

        this.validationSchema = yup.object().shape({
            password: yup
                .string()
                .required(t('PASSWORD_IS_REQUIRED'))
                .min(6, t('PASSWORD_MUST_CONTAIN_AT_LEAST_6_CHARACTERS'))
                .matches(/^(?=.*[0-9])(?=.*[a-z\u0430-\u044f])(?=\S+$).*$/i, t('PASSWORD_POSSIBLE_CHARACTERS_ERROR')),
            password_confirmation: yup
                .string()
                .required(t('PASSWORD_CONFIRMATION_IS_REQUIRED'))
                .oneOf([yup.ref('password'), ''], t('PASSWORD_CONFIRMATION_DOES_NOT_MATCH_PASSWORD')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     *
     */
    componentDidMount() {

        document.body.classList.add('login-page');

    }

    /**
     *  Callback is invoked immediately before a component is unmounted and destroyed
     */
    componentWillUnmount() {

        document.body.classList.remove('login-page');

    }

    /**
     * Form initial values
     *
     * @type {IFormValues}
     */
    private readonly initialValues: IFormValues;

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IFormValues>>;

    /**
     * Login form submit handler
     *
     * @param {Object} values
     */
    handleSubmit(values: IFormValues) {

        this.props.setPassword(values.password, values.password_confirmation, this.props.match.params.token);

    }

    /**
     * Render the component
     *
     * @return {*}
     */
    render() {

        const { t, errors = {} } = this.props;

        return (
            <section className="auth-section">
                <Helmet>
                    <meta name="viewport" content="width=device-width, user-scalable=none" />
                </Helmet>
                <LanguageSwitcher variant={'standard'} class={'login-page'} defaultLanguage={'en'} />
                <div className="container">
                    <h1>{t('PASSWORD_RESTORATION')}</h1>
                    <p className="form-intro">{t('ENTER_YOUR_NEW_PASSWORD_FOR_QIVISOR')}</p>
                    <div className="form-box form-box-space">
                        <CommonError errors={errors} />
                        <Formik
                            initialValues={this.initialValues}
                            validationSchema={this.validationSchema}
                            onSubmit={this.handleSubmit}
                        >
                            {props => (
                                <form onSubmit={props.handleSubmit} noValidate>
                                    <TextInput
                                        className={
                                            'form-field ' +
                                            (props.touched.password ? props.errors.password ? 'error-field' : 'success-field' : '')
                                        }
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.password}
                                        name="password"
                                        type="password"
                                        placeholder={t('ENTER_PASSWORD')}
                                    >
                                        {((props.touched.password && props.errors.password) || errors.password) &&
                                            <div className="validation-massage">{props.errors.password || errors.password}</div>
                                        }
                                    </TextInput>
                                    <TextInput
                                        className={
                                            'form-field ' +
                                            (props.touched.password_confirmation ? props.errors.password_confirmation ? 'error-field' : 'success-field' : '')
                                        }
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.password_confirmation}
                                        name="password_confirmation"
                                        type="password"
                                        placeholder={t('REENTER_PASSWORD')}
                                    >
                                        {props.touched.password_confirmation && props.errors.password_confirmation &&
                                            <div className="validation-massage">{props.errors.password_confirmation}</div>
                                        }
                                    </TextInput>
                                    <Button
                                        type="submit"
                                        color={'primary'}
                                    >
                                        {t('SAVE_NEW_PASSWORD')}
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </section>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: { auth: IAuthState }) => {

    const { errors } = state.auth;

    return {
        errors,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    setPassword: UserActions.setPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RestorePassword));

