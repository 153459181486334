import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, ListItemText } from '@material-ui/core';
import { isIE } from 'react-device-detect';
import { SearchInput } from '../../../core/ui/components';
import ManufacturingMenu from './ManufacturingMenu/ManufacturingMenu';
import { ISensorListHeaderDragDropTreeProps } from '../../../core/interfaces';


/**
 * Sensor list header for configuration tree
 *
 * @param      options
 * @param width
 * @param scrollLeft
 * @param emptyProductSpace
 * @param searchHandler
 * @constructor
 */
const SensorListHeader: React.FC<ISensorListHeaderDragDropTreeProps> = ({
    width,
    scrollLeft,
    emptyProductSpace,
    searchHandler,
}: ISensorListHeaderDragDropTreeProps) => {

    const { t } = useTranslation();

    return (
        <Grid container component="div" className="header-sensor-list" style={{ width: width }}>
            <div className="sensor__parameter search-group"
                 style={{
                     alignItems: 'center',
                     position: isIE ? 'relative' : 'sticky',
                     left: isIE ? scrollLeft : 0,
                 }}
            >
                <SearchInput
                    searchHandler={searchHandler}
                    options={{}}
                    placeholder={t('SEARCH')}
                />
                <ManufacturingMenu />
            </div>
            <div className="sensor-list-value" style={{ alignItems: 'center', display: 'flex', flex: 1 }}>
                <div className="header-sensor-list-right">
                    <ListItemText className="description__idH">
                        #ID
                    </ListItemText>
                    <ListItemText className="description__pit-codeH">
                        {t('PID_CODE')}
                    </ListItemText>
                    <ListItemText className="description__umH">
                        UM
                    </ListItemText>
                    <ListItemText className="description__commentH">
                        {t('COMMENT')}
                    </ListItemText>
                    <ListItemText className="description__valueH">
                        {t('VALUE')}
                    </ListItemText>
                    {emptyProductSpace}
                </div>
            </div>
        </Grid>
    );
};

export default SensorListHeader;
