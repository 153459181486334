import { configurationProductConstant } from '../../constants';
import { IConfigurationProductAction, IConfigurationProductState } from '../../interfaces';


/**
 * Graph Minimap visibility reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function configurationProduct(state: IConfigurationProductState = {}, action: IConfigurationProductAction): IConfigurationProductState {

    switch (action.type) {

        case configurationProductConstant.selectedShow:

            return {
                visible: true,
                unit: action.unit,
            };

        case configurationProductConstant.selectedHide:

            return {
                visible: false,
                unit: null,
            };

        default:

            return state;
    }
}
