import React from 'react';

interface IProps {
    condition: boolean;
    wrapper: React.ReactNode;
    children: React.ReactNode;
}

/**
 * Component to wrap any other React component by a condition
 *
 * @param {boolean} condition
 * @param {React.ReactNode} wrapper
 * @param {React.ReactNode} children
 *
 * @constructor
 */
const ConditionalWrapper: React.FC<IProps> = ({ condition, wrapper, children }) => {

    //@ts-ignore
    return condition ? wrapper(children) : children;
};



export default ConditionalWrapper;
