import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import styled from 'reshadow';

const styles = require('./Button.scss');

/**
 * A generic button component
 *
 * @param {object} children
 * @param {string} color
 * @param {boolean} disabled
 * @param {string} label
 * @param {string} size
 * @param {object} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Button: React.FC<ButtonProps> = ({
	children,
	color,
	disabled,
	size,
	className,
	...props
}) => {
	const classList = 'btn' + (className ? ' ' + className : '');
	return styled(styles)(
		<MuiButton
			variant="contained"
			color={color}
			size={size}
			disabled={disabled}
			{...props}
			className={classList}
		>
			{children}
		</MuiButton>,
	);
};

export default Button;
