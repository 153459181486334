import React, { FC, useCallback, useEffect, useState } from 'react';
import './ManufacturingMenu.scss';
import { Menu, MenuItem, MenuProps, withStyles } from '@material-ui/core';
import { Button } from '../../../../core/ui/components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FactoryIcon } from '../../../../core/ui/assets/images/icons/folder-factory.svg';
import { ReactComponent as ProcessIcon } from '../../../../core/ui/assets/images/icons/folder-process.svg';
import { ReactComponent as UnitIcon } from '../../../../core/ui/assets/images/icons/folder-unit.svg';
import { ReactComponent as FunctionIcon } from '../../../../core/ui/assets/images/icons/function-v2.svg';
import { ReactComponent as AlertIcon } from '../../../../core/ui/assets/images/icons/alert.svg';
import { modules } from '../../../../modules';
import { useDispatch, useSelector } from 'react-redux';
import { FormActions } from '../../../../core/actions';
import { selectHMISettings } from '../../../../core/selectors/appSetting/appSettingSelector';
import { isMobile } from 'react-device-detect';


interface IMenuManufacturing {
    title: string;
    labelForm: string;
    action: (nameNode: string, titleNode: string) => void;
    icon: () => React.ReactNode;
    color?: string;
    active?: boolean;
    enable: boolean;
    index: number;
}

const StyledMenu = withStyles({
    paper: {
        marginTop: 10,
        boxShadow: '0 2px 6px 0 rgba(33, 37, 41, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: 4,
        backgroundColor: '#ffffff',
    },
    list: {
        padding: '16px 0',
    },

})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(() => ({
    root: {
        '&:focus': {
            backgroundColor: 'transparent',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: 'red',
            },
        },
    },
}))(MenuItem);

/**
 * Functional Component ManufacturingMenu
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const ManufacturingMenu: FC = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const hmi = useSelector(selectHMISettings);

    /**
     * Open form 'Add node'
     *
     * @param {string} nameNode
     * @param {string} titleNode
     */
    const openNodeAdd = useCallback((nameNode: string) => {

        dispatch(FormActions.toggle(false, nameNode));

    }, [dispatch]);

    const [menuManufacturing, setMenuManufacturing] = useState<IMenuManufacturing[]>([
        {
            title: t('FACTORY'),
            labelForm: 'factory',
            action: openNodeAdd,
            // eslint-disable-next-line react/display-name
            icon: () => <FactoryIcon style={{ height: 24, width: 24 }} />,
            color: '',
            active: false,
            enable: true,
            index: 0,
        },
        {
            title: t('PROCESS'),
            labelForm: 'process',
            action: openNodeAdd,
            // eslint-disable-next-line react/display-name
            icon: () => <ProcessIcon style={{ height: 24, width: 24 }} />,
            color: '',
            active: false,
            enable: true,
            index: 1,
        },
        {
            title: t('UNITS'),
            labelForm: 'units',
            action: openNodeAdd,
            // eslint-disable-next-line react/display-name
            icon: () => <UnitIcon style={{ height: 24, width: 24 }} />,
            color: '',
            active: false,
            enable: true,
            index: 2,
        },
        {
            title: t('KPI'),
            labelForm: 'kpi',
            action: openNodeAdd,
            // eslint-disable-next-line react/display-name
            icon: () => <FunctionIcon style={{ height: 24, width: 24 }} />,
            color: '',
            active: true,
            enable: true,
            index: 3,
        },
        {
            title: t('ALERT'),
            labelForm: 'alert',
            action: openNodeAdd,
            // eslint-disable-next-line react/display-name
            icon: () => <AlertIcon style={{ height: 24, width: 24 }} />,
            color: '',
            active: false,
            enable: true,
            index: 4,
        },
        {
            title: t('HMI'),
            labelForm: 'hmi',
            action: openNodeAdd,
            // eslint-disable-next-line react/display-name
            icon: () => <FunctionIcon style={{ height: 24, width: 24 }} />,
            color: '',
            active: isMobile? isMobile : !hmi.isEnabled,
            enable: true,
            index: 6,
        },
    ]);

    useEffect(() => {
        modules.forEach((module) => {

            const configurationMenu = module.configurationMenu(dispatch);

            if (configurationMenu.manufacturing) {

                setMenuManufacturing([...menuManufacturing, ...configurationMenu.manufacturing].sort((a, b) => a.index - b.index))
                ;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);


    /**
     * Click handler
     *
     * @param {React.MouseEvent<HTMLElement>} event
     */
    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {

        setAnchorEl(event.currentTarget);

        const updatingMenu = menuManufacturing.map(value => {

            if (value.index === 6) {

                value.active = isMobile? isMobile : !hmi.isEnabled;

                return value;
            }

            return value;
        });

        setMenuManufacturing(updatingMenu);

        dispatch(FormActions.toggle(true, ''));

    }, [setAnchorEl, dispatch, hmi]);

    /**
     * Close handler
     */
    const handleClose = useCallback(() => {

        setAnchorEl(null);

    }, [setAnchorEl]);


    /**
     * Menu item
     *
     * @param value
     * @return {JSX.Element}
     */
    const menuItem = (value: IMenuManufacturing) => {

        function clickItem() {

            value.action(value.labelForm.toLowerCase(), value.title);

            handleClose();
        }

        return (
            <span key={value.title}>
                {value.enable &&
                <StyledMenuItem
                    id={value.labelForm}
                    key={value.title}
                    onClick={clickItem}
                    disabled={value.active}
                    style={{ color: value.color }}
                    className={'menu-item'}
                >
                    {value.icon ? value.icon() : null}
                    {value.title}
                </StyledMenuItem>
                }
            </span>
        );
    };

    return (
        <React.Fragment>
            <Button
                id="addMenuButton"
                style={{
                    minWidth: 40,
                    width: 'auto',
                    padding: 0,
                    marginRight: 8,
                    marginLeft: 8,
                }}
                onClick={handleClick}
                className={anchorEl ? 'active' : ''}
            >
                {t('ADD')}
            </Button>
            {anchorEl ?
                <StyledMenu
                    className={'edit-dots__menu-manufacturing'}
                    id={'test'}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {menuManufacturing.map((value) => menuItem(value))}
                </StyledMenu> : null}
        </React.Fragment>

    );
};

export default React.memo(ManufacturingMenu);