import ApiProvider from '../providers/apiProvider';
import {  INotification } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with notification API resources
 *
 * @class AppSetting
 */
export class notificationsService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/notifications';
    }

    /**
     * Get list of causes
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    async list(from: Date, to: Date): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: {
                    from: new Date(from).toISOString(),
                    to: new Date(to).toISOString(),
                },
                headers: { ...this.headers, 'Pragma': 'no-cache' },
            });
    }

    /**
     * Mark as read
     *
     * @param {INotification} notification
     * @return {Promise<AxiosResponse>}
     */
    markAsRead(notification: INotification): Promise<AxiosResponse> {

        return this.http
            .post(`${this.url()}/mark-as-read/${notification.id}`, notification, {
                headers: this.headers,
            })
            ;
    }

    /**
     * Mark all as read
     *
     * @return {Promise<AxiosResponse>}
     */
    markAsReadAll(): Promise<AxiosResponse> {

        return this.http
            .post(`${this.url()}/mark-all-as-read`, {}, {
                headers: this.headers,
            })
            ;
    }

    /**
     *
     * @param {INotification} notification
     * @return {Promise<AxiosResponse>}
     */
    comment(notification: INotification): Promise<AxiosResponse> {

        return this.http
            .post(`${this.url()}/comment/${notification.id}`, { text: notification.comment }, {
                headers: this.headers,
            })
            ;
    }

    /**
     * Updating local data of notifications
     *
     * @param {INotification[]} allNotification
     * @return {INotification[]}
     */
    markAsReadAllLocalData(allNotification: INotification[]): INotification[] {
        return allNotification.map(notification => {
                notification.isNew = false;

                return { ...notification };
            },
        );
    }
}