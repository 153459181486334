import { SettingPageActions, SettingPageActionTypes } from '../actions/settingPageActions';
import { IBeacon, IDepartment, IEmployee } from '../../interfaces';
import { IGatewayApiModel } from '../../services/gatewayService';

export interface ISettingPageState {
    formOpening: boolean;
    model?: IEmployee | IDepartment | IGatewayApiModel | IBeacon;
    formName?: string;
}

export const initialState: ISettingPageState = {
    formName: '',
    formOpening: false,
};

export function settingPageReducer(state = initialState, action: SettingPageActions): ISettingPageState {

    switch (action.type) {
        case SettingPageActionTypes.OPENING_FORM_ON_THE_PAGE_SETTING: {
            const { formOpening, formName = '', model } = action.payload;
            return {
                ...state,
                formOpening: formOpening,
                formName: formName,
                model: model,
            };
        }

        default: {
            return state;
        }
    }
}
