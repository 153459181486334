import React, { ReactElement, useEffect } from 'react';
import Employee from './components/Settings/Employee/Employee';
import Gateway from './components/Settings/Gateway/Gateway';
import Beacon from './components/Settings/Beacon/Beacon';
import Configuration from './components/Configuration/Configuration';

import { ReactComponent as HRIcon } from './ui/assets/images/icons/hr.svg';

import en_locale from './translations/en.json';
import ru_locale from './translations/ru.json';
import ua_locale from './translations/ua.json';

import { HR_STORE_KEY, reducers as hrReducers } from './store/reducers';
import { IAppModules, IModuleRoute, IConfigurationTreeProps } from '../interfaces';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction, Reducer } from 'redux';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { hrModuleThunks } from './store/thunks/hrModuleThunks';
import { selectIsConfigurationBlockActive } from './store/selectors/hrModuleSelector';
import { locationThunks } from './store/thunks/locationThunks';
import { selectLocations } from './store/selectors/locationSelector';
import FormLocationSidebar from './components/Configuration/Location/FormLocationSidebar/FormLocationSidebar';
import FormLayerSidebar from './components/Configuration/Layer/FormLayerSidebar/FormLayerSidebar';
import Editor from './components/Editor/Editor';
import { IConfigurationTreeSidebarsProps } from '../interfaces/IConfigurationTreeSidebarsProps';
import { getVh } from '../../base/helpers/screen-size.helper';
import { IGraphStructuralTreeVisibilityState } from '../../core/interfaces';
import MonitoringTreeWrapper from './components/Monitoring/MonitoringTreeWrapper';
import MinimapHr from './components/Minimap/MiniMapHr';

/**
 * HR Module
 *
 * @class Hr
 */
export class Hr extends React.Component implements IAppModules {

    /**
     * Module ID
     *
     * @type {string}
     */
    id = 'qv-hr';

    /**
     * Configuration tree block activation flag
     *
     * @type {boolean}
     */
    configurationBlockActive = false;

    /**
     * Module status flag
     * By default, the module is not controlled by the default api
     *
     * @type {boolean}
     */
    moduleStatus = false;

    /**
     * set moduleHrStatus from connection
     *
     * @param {boolean} status
     */
    setModuleStatus(status: boolean): void {

        this.moduleStatus = status;
    }

    /**
     * Module routes
     *
     * @returns {Object}
     */
    routes(): Record<string, IModuleRoute[]> {

        if (!this.moduleStatus) {

            return {
                settings: [],
            };
        }

        return {
            settings: [
                {
                    path: '/Hr/employee',
                    name: 'HR_EMPLOYEES',
                    component: Employee,
                    route: 'setting:hr:employee',
                    permission: 'hr:employee:manage',
                },
                {
                    path: '/Hr/gateway',
                    name: 'HR_GATEWAYS',
                    component: Gateway,
                    route: 'setting:hr:gateway',
                    permission: 'hr:gateway:manage',
                },
                {
                    path: '/Hr/beacon',
                    name: 'HR_BEACONS',
                    component: Beacon,
                    route: 'setting:hr:beacon',
                    permission: 'hr:beacon:manage',
                },
            ],
        };
    }

    /**
     * Module configuration menu
     *
     * @returns {Object}
     */
    configurationMenu(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): any {

        return {
            manufacturing: [
                {
                    title: 'HR',
                    labelForm: 'hr',
                    action: this.activateConfiguration.bind(this, dispatch),
                    icon: () => <HRIcon style={{ height: 24, width: 24 }} />,
                    color: '',
                    active: false,
                    enable: this.moduleStatus,
                    index: 5,
                },
            ],
        };
    }

    /**
     * Activate configuration tree block
     */
    activateConfiguration(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): void {

        this.configurationBlockActive = true;
        dispatch(hrModuleThunks.activateConfigurationTree());
    }

    /**
     * Module translations
     *
     * @returns {Object}
     */
    translations(): Record<string, unknown> {

        return {
            en: en_locale,
            ru: ru_locale,
            ua: ua_locale,
        };
    }

    /**
     * Render configuration tree block
     *
     * @param {IConfigurationTreeProps} props
     *
     * @returns {JSX.Element}
     */
    renderConfigurationTree({ scrollLeft, index, width }: IConfigurationTreeProps): ReactElement | null {

        if (!this.moduleStatus) {

            return null;
        }
        return <ConfigurationTreeWrapper  moduleStatus={this.moduleStatus} scrollLeft={scrollLeft} key={'hr-configutation-tree-' + index} index={index} width={width} />;
    }

    /**
     * Render minimap
     *
     * @param {IConfigurationTreeProps} props
     *
     * @returns {JSX.Element}
     */
    getMinimap(props: { rangeDate: Date[] | undefined, numberOfDataPoints: number, positionOnVerticalLine: number }): ReactElement | null {

        if (!this.moduleStatus) {

            return null;
        }

        return <MinimapHr key={'hr-brash'} {...props} />;
    }

    /**
     *
     * @param props
     */
    renderConfigurationTreeSidebars(props: IConfigurationTreeSidebarsProps): JSX.Element {
        const {
            index,
            formName,
            openSidebar,
            toggleForm,
            reloadData,
        } = props;

        function openEditor() {

            toggleForm(false, 'editor');
        }

        return (
            <React.Fragment key={index}>
                {formName && formName === 'hr-location' &&
                    <FormLocationSidebar
                        openSidebar={openSidebar}
                        toggleForm={toggleForm}
                        reloadData={reloadData}
                        openEditor={openEditor}
                    />
                }
                {formName && formName === 'hr-layer' &&
                    <FormLayerSidebar
                        openSidebar={openSidebar}
                        toggleForm={toggleForm}
                        reloadData={reloadData}
                    />
                }
            </React.Fragment>
        );
    }

    /**
     * Render map editor
     *
     * @param {number} index
     * @param {number} width
     * @param {number} height
     * @param {Object} style
     *
     * @returns {JSX.Element}
     */
    renderMapEditor(index: number, width = 800, height = 600, style: Record<string, unknown> = {}): JSX.Element {

        return (
            <div key={'hr-editor-' + index} style={{ width: 'calc(100vw - 320px)', height: `${getVh(100) - 40}px`, overflow: 'hidden', ...style }}>
                <Editor workareaWidth={width} workareaHeight={height} />
            </div>
        );
    }

    reducers(): Record<string, Reducer> {
        return {
            [HR_STORE_KEY]: hrReducers,
        };
    }

    renderMonitoringTree(mode: 'monitoringMode' | 'editMode', index: number, searchField: string, graphStructuralTreeVisibility: IGraphStructuralTreeVisibilityState): JSX.Element| null {

        if (!this.moduleStatus) {

            return null;
        }

        return (
            <MonitoringTreeWrapper  moduleStatus={this.moduleStatus} key={index}  data={graphStructuralTreeVisibility} mode={mode} searchField={searchField} />
        );
    }

    /**
     * Render method implementation
     *
     * @return null
     */
    render() {

        return null;
    }
}


const ConfigurationTreeWrapper: React.FC<IConfigurationTreeProps> = (props: IConfigurationTreeProps) => {
    const isConfigurationBlockActive = useSelector(selectIsConfigurationBlockActive);

    const dispatch = useDispatch();

    const store = useStore();

    useEffect(() => {
        const checkHrEnabled = async() => {
            try {
                await dispatch(locationThunks.fetchLocations());

                const locations = selectLocations(store.getState());

                if (locations.length > 0) {
                    dispatch(hrModuleThunks.activateConfigurationTree());
                }
            } catch (error) {
                console.warn(error);
            }
        };

        checkHrEnabled();

    }, [dispatch, store]);


    if (!isConfigurationBlockActive || !props.moduleStatus) {
            return null;
        }

    const { scrollLeft, index, width } = props;

    return <Configuration scrollLeft={scrollLeft} key={index} width={width} />;
};