import React, { ChangeEvent, useCallback, useRef } from 'react';
import { Button } from '../index';
import styles from './FileUpload.module.scss';

const FileUpload: React.FC<IFileUploadProps> = (props: IFileUploadProps) => {

    const { name, onFileUpload, accept = 'image/svg+xml', id } = props;

    const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>;

    const onClick = useCallback(() => {
        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();
    }, [fileInput]);

    const onChange = useCallback((event: ChangeEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();

        const file: File = event.target.files[0];

        if (!file) {
            return;
        }

        onFileUpload(file);
    }, [onFileUpload]);

    return (
        <>
            <input
                id={id}
                className={styles.uploadInput}
                type="file"
                onChange={onChange}
                ref={fileInput}
                accept={accept}
            />
            <Button
                variant="outlined"
                color="secondary"
                onClick={onClick}
            >
                {name}
            </Button>
        </>
    );
};

export default FileUpload;

interface IFileUploadProps {
    id: string;
    name: string;
    accept?: string;
    onFileUpload: (file: File) => void;
}
