import React, { useCallback } from 'react';
import { DateInput } from '../../../../core/ui/components';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../../../../core/ui/assets/images/icons/calendar.svg';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface ITimeGroupItemProps {
    value: Date;
    maxDate: Date;
    minDate?: Date;
    datePickerClass: string;
    datePickerId: string;
    datePosition: string;
    handleDateChange: (values: ITimeValues) => void;
    errorHandler: (status: boolean) => void;
    values: ITimeValues;
    label: string;
    disabled?: boolean;
    downTimeRestriction?: boolean;
    downTimeErrorHandler?: (status: boolean) => void;
}

interface ITimeValues {
    name: string;
    comment: string;
    startTime: Date;
    endTime: Date;
    duration: number;
    cause: string | number | null;
    stateCategoryId?: number;
    causeId?: number;
}


const TimeGroupItemStateForm: React.FC<ITimeGroupItemProps> = (
    {
        value,
        label,
        datePickerClass,
        maxDate,
        datePickerId,
        handleDateChange,
        datePosition,
        minDate,
        values,
        errorHandler,
        disabled = false,
        downTimeRestriction = false,
        downTimeErrorHandler,
    }: ITimeGroupItemProps,
) => {

    /**
     * Change Date function
     *
     * @param { Date | MaterialUiPickersDate } event
     */
    const dateChange = useCallback((event) => {

        if (datePosition === 'startDate') {

            if (moment(values.endTime).isAfter(moment(event))) {

                values.startTime = moment(event).toDate();

            } else {

                values.startTime = values.endTime;
            }

            return values;
        }

        if (datePosition === 'endDate') {

            if (moment(event).isAfter(moment(values.startTime))) {

                values.endTime = moment(event).toDate();

            }

            if (moment(event).isBefore(moment(values.startTime))) {

                values.endTime = values.startTime;
            }

            return values;
        }

        return event;

    }, [values, datePosition]);

    /**
     * Change Date function for DateInput element
     */
    const changeHandlerDateInput = useCallback((event: Date) => {

        if (downTimeRestriction && downTimeErrorHandler) {

            const before = datePosition === 'startDate' && minDate && (event.getTime() / 1000) < moment(minDate).unix();

            const after = datePosition === 'endDate' && maxDate && (event.getTime() / 1000) >= moment(maxDate).unix();

            if (before || after) {
                downTimeErrorHandler(true);
            } else {

                downTimeErrorHandler(false);

                return handleDateChange(dateChange(event));
            }

        } else {
            return handleDateChange(dateChange(event));
        }

    }, [handleDateChange, dateChange, errorHandler, downTimeErrorHandler]);

    /**
     * Change Date function for KeyboardDatePicker. Set day/month/year.
     */
    const changeHandlerKeyboardDatePicker = useCallback((event: MaterialUiPickersDate) => {

        if (event && moment(event).isValid()) {

            const newDate = moment(value);

            newDate.set('year', moment(event).year());

            newDate.set('month', moment(event).month());

            newDate.set('date', moment(event).date());

            if (newDate.isValid()) {

                return handleDateChange(dateChange(newDate.toDate()));

            }
        }

    }, [handleDateChange, dateChange, value]);

    /**
     * Change Date function for KeyboardDatePicker. When focus is lost
     */
    const changeHandlerKeyboardDatePickerOnBlur = useCallback(() => {

        return handleDateChange(dateChange(value));

    }, [handleDateChange, value, dateChange]);

    /**
     * Error handling function
     */
    const errorChangeHandler = useCallback((event) => {

        return errorHandler(event);

    }, [errorHandler]);

    return (
        <React.Fragment>
            <DateInput time={moment(value).toDate()}
                newTime={changeHandlerDateInput}
                datePosition={datePosition}
                inputName="time-picker"
                onError={errorChangeHandler}
                disabled={disabled}
            />
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD.MM.YY"
                margin="normal"
                id={datePickerId}
                label={label}
                className={datePickerClass}
                maxDate={moment(maxDate).toDate()}
                minDate={minDate}
                value={moment(value).toDate()}
                onChange={changeHandlerKeyboardDatePicker}
                onBlur={changeHandlerKeyboardDatePickerOnBlur}
                keyboardIcon={<CalendarIcon />}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                disabled={disabled}
            />
        </React.Fragment>
    );
};

export default TimeGroupItemStateForm;
