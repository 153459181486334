import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';

/**
 * Service to work with auth API resources
 *
 * @class AppSetting
 */
export class AppSetting extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/app-settings';
    }

    /**
     * Current user profile request
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    appSetting(): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .get(this.url(), {
                    headers: this.headers,
                }),
        );
    }

}
