import React, { ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Can from '../../rbac/Can';

/**
 * Private route to limit unauthorized user access to a component
 *
 * @param {object} Component The component which requires access restriction
 * @param {string} route Route name
 * @param {object} otherProps The rest route props
 *
 * @return {JSX.Element}
 */
export const PrivateRoute = ({ component: Component, route, ...otherProps }: IProps) => {

    return (
        <Route {...otherProps} render={props => (
                <Can
                    route={route}
                    onForbidden={<Redirect to={{ pathname: '/', state: { from: props.location } }} />}
                    onUnauthorized={<Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
                >
                    <Component {...props} />
                </Can>
            )}
        />
    );
};

interface IProps {
    exact?: boolean;
    route: string;
    path: string;
    component: ComponentType<any>;
    location?: any;
}
