import React, { useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { appConfig } from '../../config/appConfig';
import './LanguageSwitcher.scss';
import moment from 'moment';
import { ILanguageSwitcherProps } from '../../core/interfaces';

const LanguageSwitcher: React.FC<ILanguageSwitcherProps> = ({ ...props }: ILanguageSwitcherProps) => {
    const [lang, setLang] = useState('en');

    useEffect(() => {

        const language = localStorage.getItem('language');

        if (language) {

            setLang(language);

            moment.locale(language);
        }

    }, []);

    /**
     * Change event handler
     */
    const changeEvent = useCallback((event) => {

        window.location.reload();

        setTimeout(() => i18next.changeLanguage(event.target.value), 200);

        moment.locale(event.target.value);

        setLang(event.target.value);

        localStorage.setItem('language', event.target.value);

    }, []);

    return (
        <FormControl variant={props.variant} className={`language-switcher ${props.class}`}>
            <Select
                onChange={changeEvent}
                id="language"
                value={lang}
                autoWidth
                inputProps={{ 'aria-label': 'Without label' }}
                disableUnderline
            >
                {appConfig.language.map(value =>
                    <MenuItem className={'language-item'} key={value.name} value={value.value}>{value.name}</MenuItem>,
                )}
            </Select>
        </FormControl>
    );
};

export default LanguageSwitcher;
