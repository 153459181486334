import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuNodeType } from './MenuOptions';

interface IProps {
    onEdit: () => void;
    onOpenEditor?: () => void;
    onDelete: () => void;
}

export const useMenuOption = ({ onEdit, onOpenEditor, onDelete }: IProps): MenuNodeType[] => {
    const { t } = useTranslation();
    
    const [menuNode, setMenuNode] = useState<MenuNodeType[]>([]);

    useEffect(() => {

        const menu = [
            {
                title: t('EDIT'),
                action: onEdit,
            },
            {
                title: t('DELETE'),
                action: onDelete,
                color: 'red',
            },
        ];

        if (onOpenEditor) {

            menu.splice(1, 0, {
                title: t('OPEN_EDITOR'),
                action: onOpenEditor,
            });
        }

        setMenuNode(menu);
    }, [t, onEdit, onOpenEditor, onDelete]);

    return menuNode;
};
