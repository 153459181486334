import React, { useState } from 'react';
import { Tooltip as CustomTooltip, TooltipProps, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Base styles for the Components
 *
 * @type {StylesHook<Styles<Theme, {}, string>>}
 */
const useStyles = makeStyles(theme => ({
    itemIcon: {
        minWidth: 24,
        cursor: 'pointer',
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 'none',
        zIndex: 1,
    },
    popper: {
        zIndex: 1200,
    },
}));


const TooltipClickCopy: React.FC<TooltipProps> = ({
    title,
    children,
    ...props
}: TooltipProps) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {

        setOpen(false);
    };

    const handleTooltipOpen = () => {

        navigator.clipboard.writeText(children.props.children);

        setOpen(true);
    };

    return (
        <ClickAwayListener
            onClickAway={handleTooltipClose}
        >
            <CustomTooltip
                classes={{
                    tooltip: classes.tooltip,
                    popper: classes.popper,
                }}
                onClose={handleTooltipClose}
                open={open}
                style={{ zIndex: 1200 }}
                title={title}
                {...props}
                onClick={() => handleTooltipOpen()}
            >{children}
            </CustomTooltip>
        </ClickAwayListener>
    );
};

export default TooltipClickCopy;