import React from 'react';
import Header from '../Header/Header';
import StructuralTree from './StructuralTree/StructuralTree';
import Notification from '../LoadingNotification/LoadingNotification';

/**
 * Dashboard component
 *
 * @class Dashboard
 */
const Dashboard: React.FC = () => {
    return (
        <React.Fragment>
            <Notification />
            <Header />
            <StructuralTree />
        </React.Fragment>
    );
};

export default Dashboard;

