import {
    SaveLocationAction,
    FetchLocationsFailure,
    FetchLocationsRequest,
    FetchLocationsSuccess,
    SetLocationPictureAction,
    DeleteLocationAction,
    SetLocationPictureErrorAction,
} from '../actions/locationActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction, Store } from 'redux';
import { IGpsPin, IGpsPinIdent, ILayer, ILocation } from '../../interfaces';
import {
    SaveLocationFormFailure,
    SaveLocationFormRequest,
    SaveLocationFormSuccess,
    ResetWithLocationModelAction,
    SaveGpsPinInLocationForm,
    DeleteGpsPinInLocationForm,
    ClearInvalidError,
} from '../actions/locationFormActions';
import {
    ResetWithLocationModelAction as ResetEditorWithLocationModelAction,
} from '../actions/locationEditorActions';
import { LocationService } from '../../services/locationService';
import { IOrder } from '../../../../core/interfaces';
import { selectLocationFormPins } from '../selectors/locationFormSelector';
import { LayerService } from '../../services/layerService';

/**
 * Load location picture
 *
 * @param {ILocation | null} location
 *
 * @returns Promise<ILocation | null>
 */
const loadLocationPicture = async(location: ILocation | null): Promise<ILocation | null> => {

    const model = location ? { ...location } : null;

    if (model && model.id && !model.pictureContent) {

        const locationService = new LocationService();

        try {

            model.pictureContent = await locationService.getPicture(model);

            const file = new File([model.pictureContent], 'location.svg', {
                type: 'image/svg+xml',
            });

            model.picture = file as unknown as string;

        } catch (e) {

            model.picture = '';

            model.error =  locationService.errorHandler(e);
        }
    }

    return model;
};

/**
 * Load pictures for location layers
 *
 * @param {ILayer[]} layers
 *
 * @returns Promise<ILayer[]>
 */
const loadLayerPictures = async(layers: ILayer[]): Promise<ILayer[]> => {

    return await Promise.all(layers.map(async(layer: ILayer) => {

        const model = { ...layer };

        const layerService = new LayerService();

        try {

            model.pictureContent = await layerService.getPicture(layer);

            const file = new File([model.pictureContent], 'layer.svg', {
                type: 'image/svg+xml',
            });

            model.picture = file as unknown as string;

        } catch (e) {

            model.picture = '';

            model.error = layerService.errorHandler(e);
        }

        return model;
    }));
};

export const locationThunks = {
    fetchLocations: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {
        try {
            dispatch(new FetchLocationsRequest());

            const locationService = new LocationService();
            const locations = await locationService.list(search, order);

            dispatch(new FetchLocationsSuccess({ locations }));
        } catch (error) {
            dispatch(new FetchLocationsFailure({ error }));
        }
    },
    deleteLocation: (location: ILocation) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {
        try {
            const locationService = new LocationService();
            await locationService.delete(location);

            dispatch(new DeleteLocationAction({ location }));
        } catch (error) {
            console.warn(error);
        }
    },
    openForm: (location: ILocation | null) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        const model = await loadLocationPicture(location);

        dispatch(new ResetWithLocationModelAction({ location: model }));
    },
    closeForm: () => (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): void => {
        dispatch(new ResetWithLocationModelAction({ location: null }));
    },
    openEditor: (location: ILocation | null) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        const model = await loadLocationPicture(location);

        if (model) {

            model.layers = await loadLayerPictures(model.layers);
        }

        dispatch(new ResetEditorWithLocationModelAction({ location: model }));
    },
    closeEditor: () => (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): void => {

        dispatch(new ResetEditorWithLocationModelAction({ location: null }));
    },
    saveLocation: (model: ILocation) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>, getState: () => Store): Promise<void> => {
        try {
            dispatch(new SaveLocationFormRequest());

            const state = getState();
            const pins = selectLocationFormPins(state).map(pin => ({
                ...pin,
                x: pin.offsetX || pin.x,
                y: pin.offsetY || pin.y,
            }));

            const locationService = new LocationService();
            const location = await locationService.saveLocation(model, pins);

            const locationWithPicture = await loadLocationPicture(location);

            if (locationWithPicture?.layers) {

                locationWithPicture.layers = await loadLayerPictures(locationWithPicture.layers);
            }

            dispatch(new SaveLocationFormSuccess({ location }));
            dispatch(new SaveLocationAction({ location }));
            dispatch(new ResetEditorWithLocationModelAction({ location: locationWithPicture }));
        } catch (e) {
            dispatch(new SaveLocationFormFailure({ error: e.response.data ? e.response.data : e }));
            throw e;
        }
    },
    fetchPictureContent: (model: ILocation) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {
        if (model.pictureContent) {
            return;
        }

        const locationService = new LocationService();

        try {
            const pictureContent = await locationService.getPicture(model);

            dispatch(new SetLocationPictureAction({
                locationId: model.id,
                pictureContent,
            }));

        } catch (errors) {

            const error = locationService.errorHandler(errors);

            dispatch(new SetLocationPictureErrorAction({
                error,
            }));

            console.warn(error);
        }
    },
    savePin: (key: IGpsPinIdent, model: Partial<IGpsPin>) => (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): void => {
        dispatch(new SaveGpsPinInLocationForm({ key, model }));
    },
    deletePin: (key: IGpsPinIdent) => (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): void => {
        dispatch(new DeleteGpsPinInLocationForm({ key }));
    },
    clearError: () => (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): void => {
        dispatch(new ClearInvalidError());
    },
};
