import { productCategoryConstants, productConstants } from '../../constants';
import { IProductCategoryListAction, IProductCategoryListState } from '../../interfaces';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function productCategory(state: IProductCategoryListState = {
    category: [],
    errors: {},
    refresh: Date.now(),
}, action: IProductCategoryListAction): IProductCategoryListState {

    switch (action.type) {

        case productCategoryConstants.LIST_SUCCESS:

            return {
                ...state,
                category: action.category,
                errors: {},
            };

        case productCategoryConstants.UPDATE_SUCCESS:
        case productConstants.DELETE_SUCCESS:
        case productCategoryConstants.DELETE_SUCCESS:
        case productCategoryConstants.STORE_SUCCESS:
        case productConstants.UPDATE_SUCCESS:
        case productConstants.STORE_SUCCESS:

            return {
                ...state,
                refresh: Date.now(),
            };

        case productCategoryConstants.LIST_FAILURE:

            return {
                ...state,
                category: [],
                errors: action.errors,
            };

        default:

            return state;
    }

}
