import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IFilter, IJoin, IOrder, IUserTabBar } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with unit API resources
 *
 * @class DashboardService
 */
export class UserTabBarService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/user-tab-bars(/:id)';
    }

    /**
     * Get list of user-tab-bar
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter, trackerLogic?: boolean): Promise<AxiosResponse> {

        if (!trackerLogic) {

            return trackPromise(
                this.http
                    .get(this.url(), {
                        params: this.prepareListParams(search, order, join, filter),
                        headers: this.headers,
                    }),
            );
        } else {
            return this.http
                .get(this.url(), {
                    params: this.prepareListParams(search, order, join, filter),
                    headers: this.headers,
                });
        }
    }

    /**
     * Update User-tab-bar
     *
     * @param {IUserTabBar} userTabBar
     *
     * @return {Promise<Object>}
     */
    update(userTabBar: IUserTabBar): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ id: userTabBar.id }), userTabBar, {
                    headers: this.headers,
                }),
        );
    }

}
