import { IStateItem } from '../core/interfaces';
import { IFlatTreeUnit, ISensor } from '../core/interfaces';

/**
 * Get actual last value in state list
 * 
 * @param {IStateItem[]} dataHistogram 
 * @returns {number}
 */
export const getActualLastValueIndex = (dataHistogram: IStateItem[]): number => {

    const dataHistogramLength = dataHistogram.length - 1;

    let actualLastValueIndex = 1;

    if (dataHistogramLength > 0) {

        const lastDataHistogram = dataHistogram[dataHistogramLength];

        const prevLastDataHistogram = dataHistogram[dataHistogramLength - 1];

        const lastDataEndTime = Math.round(new Date(lastDataHistogram.endTime).getTime() / 1000);
        const prevLastDataEndTime = Math.round(new Date(prevLastDataHistogram.endTime).getTime() / 1000);

        const diffTimesDataPrevLast = lastDataEndTime - prevLastDataEndTime;

        diffTimesDataPrevLast <= 60 ? actualLastValueIndex = 2 : actualLastValueIndex = 1;
    }

    return actualLastValueIndex;
};

/**
 * Get filtered dashboard sensors
 * 
 * @param {any[]} treeCollection 
 * @param {number} selectedDashboard 
 * @returns 
 */
export const getFilteredDashboardSensors = (
    treeCollection: any[],
    selectedDashboard: number,
): {
    minimizedUnitSensors: number[],
    dashboardSensors: Map<number, ISensor>
} => {

    let minimizedUnitSensors: number[] = [];

    const dashboardSensors = new Map<number, ISensor>();

    const collection: IFlatTreeUnit[] = treeCollection[selectedDashboard];

    if (collection) {

          for (const tree of collection) {

            if (tree.type === 'unit' && tree.isMinimized && tree.data.length) {

                const list = tree.data.filter(item => !item.isKeyParameter)
                    .map(item => item.id);

                minimizedUnitSensors = [...minimizedUnitSensors, ...list];
            }

            if (tree.type === 'unit' && !tree.isMinimized && tree.data.length) {

                tree.data.forEach(item => {
                    dashboardSensors.set(item.id, item);
                });
            }
        }
    }

    return {
        dashboardSensors,
        minimizedUnitSensors,
    };
};