import { productConstants } from '../../constants';
import {
    IProductSortedByCategoryAction,
    IProductSortedByCategoryState,
} from '../../interfaces';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function productSortedByCategory(state: IProductSortedByCategoryState = {
    errors: {},
    products: [],
}, action: IProductSortedByCategoryAction): IProductSortedByCategoryState {

    switch (action.type) {

        case productConstants.GET_All_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS:
        case productConstants.GET_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS:

            return {
                products: action.products,
                errors: {},
            };

        case productConstants.GET_All_PRODUCTS_SORTED_BY_CATEGORY_FAILURE:
        case productConstants.GET_PRODUCTS_SORTED_BY_CATEGORY_FAILURE:

            return {
                products: [],
                errors: action.errors,
            };

        default:

            return state;
    }

}
