import { matchPath, useLocation } from 'react-router-dom';
import React from 'react';
import { history } from '../../helpers';
import moment from 'moment';

export const convertDataToUrlFormat = (date: Date): string => {
    return moment(date).format('DD-MM-YYYY,+HH:mm:ss');
};

export const paramFromUrl = (pathPattern: string)=> {

    return matchPath(history.location.pathname + history.location.search, {
        path: pathPattern,
        exact: true,
        strict: true,
    });
};

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function getParamQuery(name: string) {
    const { search } = window.location;
    const param = new URLSearchParams(search).get(name);

    if ((name === 'from' || name === 'to') && param) {

        return moment(param, 'DD-MM-YYYY,+HH:mm:ss');
    }

    return new URLSearchParams(search).get(name);
}

export const URLFormat = 'DD-MM-YYYY,+HH:mm:ss';

export function setParamQuery(name: string, value: string) {
    const { search } = window.location;
    const newSearch = new URLSearchParams(decodeURI(search));
    newSearch.set(name, value);
    history.push({ ...window.location, search: decodeURI(newSearch.toString()) });
}

export function setGroupParamQuery(param: { name: string, value: string }[]) {
    const { search } = history.location;
    const newSearch = new URLSearchParams(decodeURI(search));

    for (const value of param) {

        newSearch.set(value.name, value.value);
    }

   return  history.push({ ...window.location, search: decodeURIComponent(newSearch.toString()) });
}

export const setUrl = (
    pathname: string,
    search?: { name: string, param: string }[],
    excludeQueryParam = ['location'],
): void => {

    const options: Record<string, string> = {
        pathname: pathname,
    }, state: Record<string, string> = {};

    if (search) {

        const newSearch = new URLSearchParams();

        for (const value of search) {

            const { name, param } = value;

            if (!excludeQueryParam.includes(name)) {
                newSearch.set(name, param);
            }

            state[name] = param;
        }

        return history.push({ ...options, search: decodeURIComponent(newSearch.toString()) }, state);
    } else {

        return history.push(options, state);
    }
};

export const generateSearchParam = (data: Record<string, string>): { name: string, param: string }[] => {
    const search: { name: string, param: string }[] = [];

    for (const key in data) {
        search.push({ name: key, param: data[key] });
    }

    return search;
};


export const isDashboard = (): boolean => {
    const { pathname } = history.location;

    return pathname === '/';
};