import React from 'react';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PanToolIcon from '@material-ui/icons/PanTool';
import CropFreeIcon from '@material-ui/icons/CropFree';

import '../styles/LeftPanel.scss';

interface IProps {
    slider: {
        value: number;
        min: number;
        max: number;
    };
    grabActive: boolean;
    onZoomChange: (event: React.ChangeEvent<{}> | null, value: number | number[]) => void;
    onGrabSwitch?: () => void;
    onFitToScreen?: () => void;
    fitScreenActive?: boolean;
    disableGrabIcon?: boolean;
}


/**
 * Editor left panel with some tools
 *
 * @param {Object} slider
 * @param grabActive
 * @param onZoomChange
 * @param onGrabSwitch
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const LeftPanel: React.FC<IProps> = (
    {
        slider,
        grabActive,
        onZoomChange,
        onGrabSwitch,
        onFitToScreen,
        fitScreenActive,
        disableGrabIcon = false,
    }: IProps,
) => {

    return (
        <div className="map-editor-left-panel">
            <div className="slider-btn" onClick={() => {
                onZoomChange(null, slider.value + 15);
            }}
            >
                <AddIcon />
            </div>
            <div className="slider-container">
                <Slider
                    orientation="vertical"
                    value={slider.value}
                    min={slider.min}
                    max={slider.max}
                    track={false}
                    onChange={onZoomChange}
                />
            </div>
            <div className="slider-btn" onClick={() => {
                onZoomChange(null, slider.value - 15);
            }}
            >
                <RemoveIcon />
            </div>

            {onFitToScreen &&
                <div>
                    <IconButton
                        className={`fit-screen-tool ${(fitScreenActive ? ' active' : '')}`}
                        onClick={onFitToScreen}
                    >
                        <CropFreeIcon />
                    </IconButton>
                </div>
            }
            {!disableGrabIcon ?
                <div>
                    <IconButton
                        className={'grab-tool' + (grabActive ? ' active' : '')}
                        onClick={onGrabSwitch}
                    >
                        <PanToolIcon />
                    </IconButton>
                </div>
                : null
            }
        </div>
    );
};
