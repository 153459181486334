import { IHmiHoverItemState, IHMIHoverItemStateAction } from '../../interfaces';
import { hmiObjectConstants } from '../../constants';

/**
 * HMI player hover reducer.
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function hmiHoverItemState(state: IHmiHoverItemState = {
    hoverElement: undefined,
    hoverSensor: undefined,
}, action: IHMIHoverItemStateAction): IHmiHoverItemState {

    switch (action.type) {

        case hmiObjectConstants.HOVER_HMI_OBJECT:

            return {
                hoverElement: action.item,
            };

        case hmiObjectConstants.HOVER_SENSOR_ON_TREE:

            return {
                ...state,
                hoverSensor: action.sensor,
            };

        default:

            return state;
    }

}