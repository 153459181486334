import React, { SyntheticEvent, useCallback } from 'react';
import { isIE } from 'react-device-detect';
import { ILocation } from '../../../interfaces';
import { ReactComponent as LocationIcon } from '../../../ui/assets/images/icons/layer.svg';
import { MenuOptions } from '../MenuOptions/MenuOptions';
import { useMenuOption } from '../MenuOptions/useMenuOption';

const stopPropagation = (event: SyntheticEvent) => event.stopPropagation();

const Location: React.FC<IProps> = (props: IProps) => {
    const {
        location,
        padding,
        scrollLeft,
        expandIcon,
        onOpen,
        onEditor,
        onDelete,
    } = props;

    const onEdit = useCallback(() => {
        onOpen(location);
    }, [onOpen, location]);

    const onOpenEditor = useCallback(() => {

        onEditor(location);

    }, [onEditor, location]);

    const onDeleteCallback = useCallback(() => {
        onDelete(location);
    }, [onDelete, location]);

    const menuNode = useMenuOption({
        onEdit,
        onOpenEditor,
        onDelete: onDeleteCallback,
    });

    return (
        <React.Fragment>
            <div
                className={`left-section-hr dropdown ${padding}`}
                style={{
                    position: isIE ? 'relative' : 'sticky',
                    left: isIE ? scrollLeft : 0,
                }}
            >
                {expandIcon}
                <LocationIcon style={{ height: 25, width: 25 }} />
                {location.name}
                <MenuOptions
                    menuNode={menuNode}
                />
            </div>
            <div className="right-section-hr" onClick={stopPropagation} onTouchStart={stopPropagation} />
        </React.Fragment>
    );
};

export default Location;

interface IProps {
    location: ILocation;
    padding: 'padding-0' | 'padding-1' | 'padding-2';
    scrollLeft: number;
    expandIcon?: React.ReactNode;
    onOpen: (location: ILocation) => void;
    onEditor: (location: ILocation) => void;
    onDelete: (location: ILocation) => void;
}
