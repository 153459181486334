import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';

/**
 * Service to work with zone API resources
 *
 * @class ZoneService
 */
export class ZoneService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/zones(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [];
    }

    /**
     * Get list of zones
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<IZoneApiModel[]> {

        return await this.http
            .get<IZoneApiModel[]>(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            })
            .then(this.getDataExtractor());
    }

    /**
     * Create zone
     * @param model
     */
    store(model: IZoneApiModel): Promise<IZoneApiModel> {
        const url = this.url();

        return trackPromise(
            this.http
                .post<IZoneApiModel>(url, model, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor()),
        );
    }

    /**
     * Update zone
     * @param model
     */
    update(model: IZoneApiModel): Promise<IZoneApiModel> {
        const url = this.url({ id: model.id });

        return trackPromise(
            this.http
                .patch<IZoneApiModel>(url, model, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor()),
        );
    }

    /**
     * Delete zone
     * @param model
     */
    delete(model: IZoneIndentOnly): Promise<boolean> {
        const url = this.url({ id: model.id });

        return trackPromise(
            this.http
                .delete<IZoneApiModel>(url, {
                    headers: this.headers,
                })
                .then(Boolean),
        );
    }

    /**
     * Save zone
     *
     * @param {IZoneApiModel} zone
     *
     * @return {Promise<IZoneApiModel>}
     */
    save(zone: IZoneApiModel): Promise<IZoneApiModel> {

        if (zone.id && zone.id > 0) {

            return this.update(zone);
        }

        return this.store(zone);
    }
}

export interface IZoneApiModel {
    id: number;
    name: string;
    points: Array<Array<number>>;
    color?: string;
    gateways: Array<string | null>;
    barrier: number | null;
    plan: number;
}

export type IZoneIndentOnly = Pick<IZoneApiModel, 'id'>;
