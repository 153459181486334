import React, { SyntheticEvent, useCallback } from 'react';
import { TextInput } from '../../../../../../core/ui/components';
import clsx from 'clsx';
import styles from './AngleInput.module.scss';

export const AngleInput: React.FC<IProps> = (props: IProps) => {
    const {
        handleChange,
        handleBlur,
        setFieldValue,
        isTouched,
        errors,
        fieldName,
        placeholder,
        value,
    } = props;

    const onBlur = useCallback((event) => {
        const numberedValue = Number(value);

        if (value !== '' && Number.isFinite(numberedValue) && numberedValue < 1e21) {
            setFieldValue(fieldName, numberedValue.toFixed(1));
        }
        handleBlur(event);
    }, [handleBlur, setFieldValue, value, fieldName]);

    return (
        <div className="form-group">
            <TextInput
                className={
                    clsx(
                        'form-field',
                        styles.angleInput,
                        {
                            'error-field': isTouched && errors,
                            'success-field': isTouched && !errors,
                        },
                    )
                }
                label={placeholder}
                onChange={handleChange}
                onBlur={onBlur}
                value={value}
                name={fieldName}
                type="text"
                placeholder={placeholder}
                InputLabelProps={{
                    shrink: true,
                }}
            >
                {
                    isTouched && errors &&
                    <div className="validation-massage">
                        {errors}
                    </div>
                }
            </TextInput>
        </div>
    );
};

interface IProps {
    handleChange: (event: SyntheticEvent) => void;
    handleBlur: (event: SyntheticEvent) => void;
    setFieldValue: (fieldName: string, value: any) => void;
    isTouched: boolean;
    errors: string;
    fieldName: string;
    placeholder: string;
    value: number | string;
}
