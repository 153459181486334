import { Action } from 'redux';
import { IZoneApiModel, IZoneIndentOnly } from '../../services/zoneService';

export enum ZoneActionTypes {
    SAVE_ZONE_ACTION = '[Zone] Save Zone',
    FETCH_ZONES_REQUEST = '[Zone] Fetch Zones Request',
    FETCH_ZONES_SUCCESS = '[Zone] Fetch Zones Success',
    FETCH_ZONES_FAILURE = '[Zone] Fetch Zones Failure',
    DELETE_ZONE_ACTION = '[Zone] Delete Zone',
    CLEAR_ZONES_ACTION = '[Zone] Clear Zones',
}

/**
 * Fetch zone request action
 *
 * @class FetchZonesRequest
 */
export class FetchZonesRequest implements Action {
    readonly type = ZoneActionTypes.FETCH_ZONES_REQUEST;
}

/**
 * Fetch zone success action
 *
 * @class FetchZonesSuccess
 */
export class FetchZonesSuccess implements Action {
    readonly type = ZoneActionTypes.FETCH_ZONES_SUCCESS;

    constructor(
        public payload: {
            zones: IZoneApiModel[]
        },
    ) { }
}

/**
 * Fetch zone failure action
 *
 * @class FetchZonesFailure
 */
export class FetchZonesFailure implements Action {
    readonly type = ZoneActionTypes.FETCH_ZONES_FAILURE;

    constructor(
        public payload: {
            error: any
        },
    ) { }
}

/**
 * Save zone action
 *
 * @class SaveZoneAction
 */
export class SaveZoneAction implements Action {
    readonly type = ZoneActionTypes.SAVE_ZONE_ACTION;

    constructor(
        public payload: {
            zone: IZoneApiModel,
        },
    ) { }
}

/**
 * Delete zone action
 *
 * @class DeleteZoneAction
 */
export class DeleteZoneAction implements Action {
    readonly type = ZoneActionTypes.DELETE_ZONE_ACTION;

    constructor(
        public payload: {
            zone: IZoneIndentOnly
        },
    ) { }
}

export class ClearZonesAction implements Action {
    readonly type = ZoneActionTypes.CLEAR_ZONES_ACTION;
}

export type ZoneActions =
    FetchZonesRequest |
    FetchZonesSuccess |
    FetchZonesFailure |
    SaveZoneAction |
    DeleteZoneAction |
    ClearZonesAction;
