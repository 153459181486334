import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { layoutConstants } from '../constants';

/**
 * Actions related to particular layout components
 *
 * @type {Object}
 */
export const LayoutActions = {

    /**
     * Start responsive drawer resize
     *
     * @return {Promise<Object>}
     */
    drawerStartResize: () => {

        const action = () => {

            return {
                type: layoutConstants.DRAWER_RESIZE_START,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Stop responsive drawer resize
     *
     * @return {Promise<Object>}
     */
    drawerStopResize: (lastPosition: MouseEvent | TouchEvent) => {

        const action = (lastPosition: MouseEvent| TouchEvent) => {

            return {
                type: layoutConstants.DRAWER_RESIZE_END,
                lastMousePosition: {
                    x: (lastPosition as TouchEvent).touches !== undefined ? (lastPosition as TouchEvent).touches[0].clientX : (lastPosition as MouseEvent).clientX,
                    y: (lastPosition as TouchEvent).touches !== undefined ? (lastPosition as TouchEvent).touches[0].clientY : (lastPosition as MouseEvent).clientY,
                },
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(lastPosition));
        };
    },

    /**

     * Stop responsive drawer resize
     *
     * @return {Promise<Object>}
     */
    drawerStopResizeTouchEnd: (lastPosition: TouchEvent) => {

        const action = (lastPosition: TouchEvent) => {

            return {
                type: layoutConstants.DRAWER_RESIZE_END,
                lastMousePosition: {
                    x: lastPosition.changedTouches[0].clientX,
                    y: lastPosition.changedTouches[0].clientY,
                },
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(lastPosition));
        };
    },

    drawerPosition: (dataPosition: string) => {

        const action = (position: string) => {

            return {
                type: layoutConstants.DRAWER_POSITION,
                positionDrawer: position,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(dataPosition));
        };
    },

    drawerToggleFullScreen: (fullScreen: boolean) => {

        const action = (fullScreenLogic: boolean) => {

            return {
                type: layoutConstants.DRAWER_TOGGLE_FULL_SCREEN,
                toggleFullScreen: fullScreenLogic,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(fullScreen));
        };
    },

    startTouchEvent: (start: boolean) => {

        const action = (action: boolean) => {

            return {
                type: layoutConstants.DRAWER_START_TOUCH_EVENT,
                startTouchEvent: action,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(start));
        };
    },

};
