import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isIE, isIOS, isMacOs, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { List, ListItemText } from '@material-ui/core';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd';

import { ConfirmDialog } from '../../core/ui/components';
import {
    ISensor,
    IUnit,
} from '../../core/interfaces';
import { 
    FormActions,
    SensorActions,
    HmiObjectTreeAction, 
    ConfigurationActions,
} from '../../core/actions';
import SensorListHeader from './DragDropTree/SensorListHeader';
import Factory from './DragDropTree/Factory';
import SensorItem from './DragDropTree/SensorItem/SensorItem';

import { modules } from '../../modules';

import './DragDropTree.scss';
import HmiSchemaList from './HMI/HmiSchemaList/HmiSchemaList';
import {
    selectConfigurationTreeFactory,
    selectConfigurationTreeUnit,
    selectConfigurationTreeUnsorted,
    selectConfigurationTreeProcess,
} from '../../core/selectors/configurationTree/configurationTreeCollectionSelector';
import { selectDashboards } from '../../core/selectors/dashboard/dashboardSelector';
import { selectAppSettings } from '../../core/selectors/appSetting/appSettingSelector';

/**
 * Draggable configuration tree
 *
 * @class DraggableTree
 */
const DragDropTree: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [filterValue, setFilterValue] = useState<string>('');
    // const [activeNode, setaCtiveNode] = useState<string | null>(null);
    // const [activeSensor, setActiveSensor] = useState<number | null>(null);
    const [placeholderProps, setPlaceholderProps] = useState<{
        id?: any;
        clientY?: any;
        clientX?: any;
        clientHeight?: any;
        clientWidth?: any;
    }>({});
    const [maxProductInUnit, setMaxProductInUnit] = useState<number>(0);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [move, setMove] = useState<{
        unit: IUnit | null;
        sensor: ISensor | null;
    } | null>(null);
    const [scrollLeft, setScrollLeft] = useState<number>(0);
    const [unsorted, setUnsorted] = useState<ISensor[]>([]);

    const [roamingItems, setRoamingItems] = useState<{ startUnit: ISensor[] | IUnit, endUnit?: ISensor[] | IUnit, sensor: ISensor } | null>(null);

    const droppable = useSelector(selectConfigurationTreeFactory),
        units = useSelector(selectConfigurationTreeUnit) as unknown as IUnit[],
        unsortedItem = useSelector(selectConfigurationTreeUnsorted),
        dashboards = useSelector(selectDashboards),
        appConfig = useSelector(selectAppSettings);

    const processes = useSelector(selectConfigurationTreeProcess);


    useEffect(() => {

        setUnsorted(unsortedItem);

    }, [unsortedItem]);
    /**
     * Calculation of the maximum number of products
     */
    const getProductMaxCount = useCallback(() => {

        const arrProductProcess: number[] = [];

        for (const valueFactory of droppable) {
            if (valueFactory.type === 'factory') {
                arrProductProcess.push(valueFactory.meta.maxProductsInUnit);
            }
        }

        setMaxProductInUnit(arrProductProcess.length > 0 ? Math.max.apply(null, arrProductProcess) : 0);
        // setMinProductInUnit(arrProductProcess.length > 0 ? Math.min.apply(null, arrProductProcess) : 0);

    }, [
        setMaxProductInUnit,
        // setMinProductInUnit,
        droppable,
    ]);

    useEffect(() => {

        getProductMaxCount();

    }, [getProductMaxCount, droppable]);

    /**
     *  Handler for the search
     *
     * @param {string} value
     */
    const searchHandler = useCallback((value: string) => {

        setFilterValue(value);

    }, [setFilterValue]);

    /**
     * A little function to help us with Reordering the result
     *
     * @param {ISensor[]} list - Category Item list
     * @param {number} startIndex - index begin dragging
     * @param {number} endIndex - index end dragging
     */
    const reorder = useCallback((list: ISensor[], startIndex: number, endIndex: number) => {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);

        result.splice(endIndex, 0, removed);

        //TODO after API add resort update
    }, []);


    /**
     * Result after dragged element
     *
     * @param {DropResult} result
     */
    const onDragEnd = useCallback((result: DropResult) => {

        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {

            return;
        }

        if (source.droppableId === destination.droppableId) {

            if (roamingItems) {

                const { sensor } = roamingItems;

                reorder(
                    source.droppableId !== 'unsorted' ? roamingItems?.startUnit as ISensor[] : unsorted,
                    source.index,
                    destination.index,
                );

                dispatch(ConfigurationActions.updateAndRefresh('sensor', sensor.id, {
                    isMinimized: sensor.isMinimized || false,
                    position: destination.index,
                }));
            }
        } else {

            const unitId = parseInt(destination.droppableId.replace(/\D+/g, ''));

            const newMove = {
                sensor: roamingItems?.sensor ? roamingItems.sensor : null,
                unit: unitId ? units?.filter((value: IUnit) => value.id === unitId)[0] as IUnit : null,
            };
            

            setMove(newMove);

            if (roamingItems) {
                setRoamingItems({
                    ...roamingItems,
                    sensor: {
                        ...roamingItems.sensor,
                        position: destination.index,
                    },
                });
            }

            setConfirm(true);

            setPlaceholderProps({});
        }
    }, [reorder, roamingItems, units, unsorted]);

    /**
     * Default style for dragged item
     *
     * @param { boolean } isDragging
     * @param {Object} draggableStyle - default style
     *
     * @return {Object}
     */
    const getItemStyle = useCallback((isDragging: boolean, draggableStyle: any) => {

        return {
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            width: '100%',
            minHeight: 40,

            // styles we need to apply on draggables
            ...draggableStyle,
        };
    }, []);

    /**
     * Show set alert rules form
     */
    const setAlertRules = useCallback(() => {

        dispatch(FormActions.toggle(false, 'alert'));

    }, [dispatch]);

    /**
     * Show set alert rules form
     */
    const setFormula = useCallback(() => {

        dispatch(FormActions.toggle(false, 'kpi'));

    }, [dispatch]);

    // // /**
    // //  * Update placeholder when item is dragged
    // //  *
    // //  * @param {DragUpdate} update
    // //  * */
    // // onDragUpdate(update: DragUpdate) {
    // //
    // //     if (!update.destination) {
    // //         return;
    // //     }
    // //
    // //     const draggableId = update.draggableId;
    // //     const destinationIndex = update.destination.index;
    // //     const destinationId = update.destination.droppableId === 'unsorted' ? 'unsorted' : parseInt(update.destination.droppableId.replace(/\D+/g, ''));
    // //     const queryAttr = 'data-rbd-drag-handle-draggable-id';
    // //     const domQuery = `[${queryAttr}='${draggableId}']`;
    // //     const draggedDOM = document.querySelector(domQuery);
    // //
    // //     if (!draggedDOM) {
    // //         return;
    // //     }
    // //
    // //     const { clientHeight, clientWidth } = draggedDOM;
    // //
    // //     if (draggedDOM.parentNode) {
    // //
    // //         const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) + Array.from(draggedDOM.parentNode.children)
    // //             .slice(0, destinationIndex)
    // //             .reduce((total, curr) => {
    // //
    // //                 const style = window.getComputedStyle(curr);
    // //                 const marginBottom = parseFloat(style.marginBottom);
    // //
    // //                 return total + curr.clientHeight + marginBottom;
    // //             }, 0);
    // //
    // //         this.setState({
    // //             placeholderProps: {
    // //                 id: destinationId,
    // //                 clientHeight,
    // //                 clientWidth,
    // //                 clientY,
    // //                 clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft),
    // //             },
    // //         });
    // //     }
    // // }

    /**
     * Add empty element space
     *
     * @param value
     */
    const emptyProductSpace = (value: number) => {

        const content = [];

        for (let i = 0; i < value; i++) {

            content.push(
                <ListItemText key={i + 1} className={'description__targetvalueH'}>
                    {t('TARGET_VALUE')}
                </ListItemText>,
            );
        }

        return content;
    };

    /**
     * Handler cancel dialog form
     *
     */
    const onConfirmDialog = useCallback(() => {

        if (roamingItems) {

            roamingItems.sensor.unit = move?.unit ? move?.unit.id : null;

            roamingItems.sensor.isKeyParameter = false;

            roamingItems.sensor.isNew = false;

            const moveSensor = {
                sensor: roamingItems.sensor,
                unitId: move?.unit ? move?.unit.id : null,
            };

            dispatch(SensorActions.update(roamingItems.sensor, dashboards, { moveSensor: moveSensor }));

            if (moveSensor.unitId !== null) {

                const unit = moveSensor.unitId;

                const process = processes.find(process => process.data.includes(unit));

                if (process) {

                    const factory = droppable.find(factory => factory.data.includes(process.id));

                    if (factory) {

                        const data = {
                            fabric: factory.id,
                            unit,
                            sensorId: moveSensor.sensor.id,
                        };

                        dispatch(HmiObjectTreeAction.update(data, 'sensor'));
                    }
                }
            } else {
                dispatch(HmiObjectTreeAction.update({
                    fabric: null,
                    unit: null,
                    sensorId: moveSensor.sensor.id,
                }, 'sensor', true));
            }
        }
    }, [dispatch, dashboards, roamingItems, move]);

    /**
     * Set Dragged Item
     *
     * @param {ISensor} sensor
     * @param {IUnit | ISensor[]} unit
     */
    const setDraggedItem = useCallback((sensor: ISensor, unit: IUnit | ISensor[]) => {

        setRoamingItems({
            startUnit: unit,
            sensor: sensor,
        });
    }, []);


    /**
     * State item form cancel handler
     */
    const handleCancel = useCallback(() => {

        setConfirm(false);
        setMove(null);
        setPlaceholderProps({});

    }, []);

    const scrollWrapperHandler = useCallback((event: React.UIEvent<HTMLDivElement>) => {

        if (isIE) {

            setScrollLeft(event.currentTarget.scrollLeft);
        }
    }, []);


    // const { droppable } = this.props;
    //
    //     const { move, scrollLeft, minProductInUnit, maxProductInUnit } = this.state;
    //
    const width = 320 + 492 + (152 * maxProductInUnit) + 160;

    const { hmi } = appConfig;

    return (
        <React.Fragment>
            <div
                className={`configure-draggable ${isMacOs ? 'is-mac-os' : ''}`}
                onScroll={scrollWrapperHandler}
                onMouseMove={event => event.stopPropagation()}
            >
                <div
                    style={{
                        width: '100%',
                        zIndex: 1,
                    }}
                    className={`configuration-tree-scroll ${isMobile ? 'mobile' : 'desktop'}  ${isIOS ? 'ios' : ''}`}
                >
                    <List
                        disablePadding
                        component="div"
                        className="sensor-list"
                        subheader={
                            <SensorListHeader
                                width={width}
                                scrollLeft={scrollLeft}
                                emptyProductSpace={emptyProductSpace(maxProductInUnit)}
                                searchHandler={searchHandler}
                            />
                        }
                    >
                        <div className="sensor-list" style={{ width: width }}>
                            {droppable.length > 0 ?
                                <DragDropContext
                                    onDragEnd={onDragEnd}
                                    // onDragUpdate={this.onDragUpdate}
                                >
                                    {droppable.map((factory, factoryIndex: number) => {
                                        return (
                                            <Factory
                                                key={'factory-key-' + factory.id}
                                                factory={factory}
                                                index={factoryIndex}
                                                maxProductInUnit={factory.meta.maxProductsInUnit}
                                                filterValue={filterValue}
                                                placeholderProps={placeholderProps}
                                                scrollLeft={scrollLeft}
                                                setAlertRules={setAlertRules}
                                                setDraggedItem={setDraggedItem}
                                            />
                                        );
                                    })
                                    }
                                    <div className={'unsorted-wrapper'}>
                                        <Droppable
                                            droppableId={'unsorted'}
                                            key={'unsorted'}
                                            direction={'vertical'}
                                            ignoreContainerClipping
                                            isCombineEnabled
                                        >
                                            {(provided: DroppableProvided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    className={'list-draggable'}
                                                    style={{
                                                        padding: 0,
                                                        height: 'auto',
                                                        minHeight: unsorted.length ? 'auto' : 3,
                                                    }}
                                                >
                                                    {unsorted.filter(value => value.name.toLowerCase().includes(filterValue.toLowerCase()))
                                                        .map((unsorted, unsortedIndex, array) => (
                                                        <Draggable
                                                            index={unsortedIndex}
                                                            key={'unsorted-sensor-key-' + unsorted.id}
                                                            draggableId={unsorted.id.toString()}
                                                            disableInteractiveElementBlocking={false}
                                                            shouldRespectForcePress
                                                        >
                                                            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                                <div
                                                                    key={'unsorted-sensor-key-' + unsorted.id}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style,
                                                                    )}
                                                                    onMouseDown={() => setDraggedItem(unsorted, array)}
                                                                    onTouchStart={() => setDraggedItem(unsorted, array)}
                                                                >
                                                                    <SensorItem
                                                                        sensor={unsorted as unknown as ISensor}
                                                                        leftPadding={`first-item ${snapshot.isDragging && 'is-dragging'}`}
                                                                        processRule={false}
                                                                        maxProductInUnit={maxProductInUnit}
                                                                        scrollLeft={scrollLeft}
                                                                    />

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}

                                                    {provided.placeholder}

                                                    {/*{this.state.placeholderProps.id === 'unsorted' &&*/}
                                                    {/*<div className={'placeholder-props'} style={{*/}
                                                    {/*    position: 'absolute',*/}
                                                    {/*    top: this.state.placeholderProps.clientY,*/}
                                                    {/*    left: this.state.scrollLeft + 320 > 812 ? this.state.scrollLeft + 320 - 812 : 0,*/}
                                                    {/*}}*/}
                                                    {/*>{provided.placeholder}*/}
                                                    {/*</div>}*/}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                </DragDropContext>
                                : null}
                        </div>
                        {hmi.isEnabled ?
                            <HmiSchemaList scrollLeft={scrollLeft} width={width} />
                            : null
                        }
                        {/*TODO: make a check for enabled HR block on backend inside the module*/}
                        {
                            modules.map((module: any, index) => module.renderConfigurationTree({
                                    scrollLeft: scrollLeft,
                                    index: index,
                                    width: width,
                                }),
                            )
                        }
                    </List>
                </div>
            </div>
            {move && move.sensor ?
                <ConfirmDialog
                    heading={t('ARE_YOU_SURE_WANT_TO_MOVE_THE_SENSOR_TO_CATEGORY', {
                        item: move && move.sensor ? move.sensor.name : '',
                        category: move ? move.unit ? move.unit.name : t('UNSORTED') : '',
                    })}
                    onAccept={onConfirmDialog}
                    onClose={handleCancel}
                    open={confirm}
                />
                :
                null
            }
        </React.Fragment>
    );
};

export default React.memo(DragDropTree);
