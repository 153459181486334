import React, { useState } from 'react';
import { IconButton, InputBase, Paper, makeStyles } from '@material-ui/core';
import { ReactComponent as Search } from '../../assets/images/icons/search.svg';
import { ReactComponent as Sensor } from '../../assets/images/icons/parameter-v-2.svg';
import { ReactComponent as Function } from '../../assets/images/icons/function-v2.svg';
import './SearchInput.scss';


const useStyles = makeStyles(theme => ({
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        flex: 1,
        borderRadius: 1,
        border: 'solid 1px #adb5bd',
        zIndex: 2,
        boxShadow: 'none',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        height: 32,
    },
    iconButton: {
        padding: 2,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

interface IProps {
    placeholder: string,
    onClick?: ()=> void,
    options: any,
    searchHandler: (value: string) => void
}

export default function SearchInput({ placeholder, onClick, searchHandler, ...props }: IProps) {
    const classes = useStyles();
    const { options } = props;
    const [focused, setFocused] = useState(false);
    return (
        <Paper component="div" className={classes.root + ` search-wrap ${focused? 'active':''} `}>
            {options.pages === 'configuration' ?
                <IconButton className={classes.iconButton} aria-label="search">
                    <Search />
                </IconButton> : null}

            <InputBase
                className={classes.input}
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search sensor' }}
                onFocus={() => setFocused(true)}
                onBlur={()=> setFocused(false)}
                onChange={event => {
                    searchHandler(event.target.value.toLocaleLowerCase());
                }}
            />
            {options.pages === 'configuration' ?
                <React.Fragment>
                    <IconButton type="submit" className={classes.iconButton} aria-label="sensor">
                        <Sensor style={{ width: 24, height: 24 }} />
                    </IconButton>
                    <IconButton color="primary" className={classes.iconButton} aria-label="function">
                        <Function style={{ width: 24, height: 24 }} />
                    </IconButton>
                </React.Fragment>
                :
                <IconButton className={classes.iconButton} aria-label="search">
                    <Search />
                </IconButton>}

        </Paper>
    );
}