import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { configurationProductConstant } from '../constants';
import { IUnit } from '../interfaces';

/**
 * Dashboard related actions
 *
 * @type {Object}
 */
export const ConfigurationAction = {

    /**
     * Show/hide Minimap
     *
     * @param {boolean} visible
     * @param {IUnit} unit
     *
     * @return {Promise.<Object>}
     */
    configurationProductSelected: (visible: boolean, unit: IUnit) => {

        //Action creators
        const show = (unit: IUnit) => {

            return {
                type: configurationProductConstant.selectedShow,
                unit,
            };

        }, hide = () => {

            return {
                type: configurationProductConstant.selectedHide,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(visible ? hide() : show(unit));
        };
    },


};
