import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { currentUnitsForDashboard } from '../../helpers/dashboardHelper';
import { getId, getType } from '../../helpers/selectorsHelper';

const selectMonitoringTreeState = (state: RootState) => state.monitoringTree;

export const selectMonitoringTreeRefresh = createSelector(
    selectMonitoringTreeState,
    monitoringTree => monitoringTree.refresh,

);

export const selectMonitoringTree = createSelector(
    selectMonitoringTreeState,
    monitoringTree => monitoringTree.monitoringTree,
);


export const selectUnitsForCurrentDashboard = createSelector(
    selectMonitoringTreeState, monitoringTree => currentUnitsForDashboard(monitoringTree.monitoringTree));



export const selectFlatMonitoringTreeItemById = createSelector(
    selectMonitoringTree,
    getId,
    getType,
    (items, ID, type) => items.find(value => value.type === type && value.id === ID),
);