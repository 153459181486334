import { Handler } from '../canvas/handlers/Handler';
import { IGpsPin, ILocation, IMeasure, IPinCoordinates, IVptCoords } from '../../../interfaces';
import { fabric } from 'fabric';
import * as geolib from 'geolib';
/**
 * Triangle angle calc method
 * 
 * @param {number} a 
 * @param {number} b 
 * @param {number} c
 * 
 * @return {boolean} 
 */
export const triangleAngles = (
    a: number,
    b: number,
    c: number,
): number => {
    const cosA = (b * b + c * c - a * a) / (2 * b * c);

    const angleA = (Math.acos(cosA) * 180) / Math.PI;

    return angleA;
};

/**
 * Point direction method
 * 
 * @param {IPinCoordinates} firstPin 
 * @param {IPinCoordinates} secondPin 
 * 
 * @return {boolean}
 */
export const pointDirection = (firstPin: IPinCoordinates, secondPin: IPinCoordinates): boolean => {
    return (firstPin.x < secondPin.x && firstPin.y <= secondPin.y) ||
        (firstPin.x < secondPin.x && firstPin.y >= secondPin.y);
};

/**
 * Get rotate point accord to the passed angle value
 * 
 * @param {fabric.Point} center
 * @param {number} angle
 * 
 * @return {fabric.Point}
 */
export const getRotatedPoint = (center: fabric.Point, angle: number, handler: Handler): fabric.Point => {

    if (handler.workarea) {

        const { workarea } = handler;

        if (workarea.width && workarea.height && center) {

            const rads = fabric.util.degreesToRadians(360 - angle);

            const objOrigin = new fabric.Point(center.x, center.y);

            const canvasCenter = workarea.getCenterPoint();

            return fabric.util.rotatePoint(objOrigin, canvasCenter, rads);
        }
    }

    return center;
};

/**
 * Calc scale x method
 *
 * Method responsible to calc offset pin and center according to scale param
 *
 * @param params
 * @returns {x: number, pin: IGpsPin}
 */
const calcScaleX = (
    params: {
        x: number,
        pin: IGpsPin,
        scaleX: number,
        image: { width: number, height: number },
    },
): { x: number, pin: IGpsPin } => {

    const { x, pin, scaleX, image } = params;

    const scaledWidth = image.width * scaleX;

    const imageWidthDiff = Math.abs(image.width - scaledWidth);

    const scaledCenter = (scaledWidth / 2) + (imageWidthDiff / 2);

    const diffPointFromCenter = x - scaledCenter;

    const percentPointDiff = diffPointFromCenter / scaledWidth;

    const originX = (image.width / 2) + percentPointDiff * image.width;

    const diffPinFromCenter = pin.x - scaledCenter;

    const percentPinX = (diffPinFromCenter / scaledWidth);

    pin.x = (image.width / 2) + (percentPinX * image.width);

    return {
        x: originX,
        pin,
    };
};

/**
 * Calc scale y method
 *
 * Method responsible to calc offset pin and center according to scale param
 *
 * @param params
 * @returns {y: number, pin: IGpsPin}
 */
const calcScaleY = (
    params: {
        y: number,
        pin: IGpsPin,
        scaleY: number,
        image: { width: number, height: number }
    },
): { y: number, pin: IGpsPin } => {

    const { y, pin, scaleY, image } = params;

    const scaledHeight = image.height * scaleY;

    const imageHeightDiff = Math.abs(image.height - scaledHeight);

    const scaledCenter = (scaledHeight / 2) + (imageHeightDiff / 2);

    const diffPointFromCenter = y - scaledCenter;

    const percentPointDiff = diffPointFromCenter / scaledHeight;

    const originY = (image.height / 2) + percentPointDiff * image.height;

    const diffPinFromCenter = pin.y - scaledCenter;

    const percentPinX = (diffPinFromCenter / scaledHeight);

    pin.y = (image.height / 2) + (percentPinX * image.height);

    return {
        y: originY,
        pin,
    };
};

/**
 * Get nearest pin function
 * 
 * Get the nearest pin from a list of pins according to the center gateway point
 * 
 * @param {IGpsPin[]} pins 
 * @param {fabric.Point} center 
 * @returns {IGpsPin}
 */
const getNearestPin = (pins: IGpsPin[], center: fabric.Point): IGpsPin => {

    let nearest: { pin: IGpsPin, distance: number } | null = null;

    for (const pin of pins) {
        const widthDiff = Math.abs(pin.x - center.x);
        const heightDiff = Math.abs(pin.y - center.y);
        const distance = Math.hypot(widthDiff, heightDiff);

        if (!nearest) {
            nearest = {
                pin,
                distance,
            };
        } else if (nearest.distance > distance) {
            nearest = {
                pin,
                distance,
            };
        }
    }

    return nearest ? nearest.pin : pins[0];
};

/**
 * Get point lat lng of gateways by passed position point
 * 
 * @param {fabric.Point} center 
 * @param {Handler} handler 
 * @param {ILocation} location 
 * @returns
 */
export const getPointLatLng = (
    center: fabric.Point,
    handler: Handler,
    location: ILocation,
    image?: { width: number, height: number },
): { lat: number; lng: number; } => {

    let { x = 0, y = 0 } = center;

    const locAngle = location.angle || 0;

    const { pins, bounds, measure } = location;

    if (pins.length && bounds?.pixelToMeter && bounds?.bearing) {

        const pin = getNearestPin(pins, center);

        const scaleX = measure?.scaleX || 1;
        const scaleY = measure?.scaleY || 1;

        if (scaleX !== 1 && image) {

            const calcX = calcScaleX({
                x, pin, scaleX, image,
            });

            x = calcX.x;

            pin.x = calcX.pin.x;
        }

        if (scaleY !== 1 && image) {

            const calcY = calcScaleY({
                y, pin, scaleY, image,
            });

            y = calcY.y;

            pin.y = calcY.pin.y;
        }

        if (locAngle && handler) {

            const newLoc = getRotatedPoint({ x: pin.x, y: pin.y } as fabric.Point, locAngle, handler);

            pin.x = newLoc.x;
            pin.y = newLoc.y;
        }

        const heightDiff = Math.abs(pin.y - y);

        const widthDiff = Math.abs(pin.x - x);

        const hypotDiff = Math.hypot(heightDiff, widthDiff);

        const dimensionDistance = bounds.pixelToMeter * hypotDiff;

        const angle = triangleAngles(widthDiff, heightDiff, hypotDiff);

        const angleVal = calcAngleBearing(pin, { x, y } as fabric.Point, angle);

        const location = geolib.computeDestinationPoint(
            { lat: pin.lat, lng: pin.lng },
            dimensionDistance,
            angleVal - bounds.bearing,
        );

        return { lat: location.latitude, lng: location.longitude };
    }

    return { lat: 0, lng: 0 };
};

/**
 * Calc angle bearing 
 * 
 * @param {IGpsPin} pin 
 * @param {fabric.Point} center 
 * @param {number} angle 
 * @returns {number}
 */
const calcAngleBearing = (pin: IGpsPin, center: fabric.Point, angle: number): number => {

    const { x, y } = center;

    let angleVal = 0;

    if (pin.x > x && pin.y > y) {
        angleVal = (360) - angle;
    }

    if (pin.x < x && pin.y > y) {
        angleVal = (0) + angle;
    }

    if (pin.x > x && pin.y < y) {
        angleVal = (180) + angle;
    }

    if (pin.x < x && pin.y < y) {
        angleVal = (180) - angle;
    }

    return angleVal;
};

/**
 * Calc zoom scale
 * 
 * @param {IVptCoords} vpsCoords 
 * @param {number} imageHypot 
 * @param {number} meterPixelCoef 
 * @param {number} canvToImagePixel 
 * 
 * @returns {number}
 */
export const calcZoomScale = (
    vpsCoords: IVptCoords,
    imageHypot: number,
    meterPixelCoef: number,
    canvToImagePixel: number,
): number => {

    // bottom left and top right points
    const { bl, tr } = vpsCoords;

    // horizontal diff pixels
    const diffX = Math.abs(bl.x - tr.x);

    // vertical diff pixels
    const diffY = Math.abs(bl.y - tr.y);

    // current width of diagonal
    const diffXYHypot = Math.hypot(diffX, diffY);

    // diff width between a current visible selgment and full width image
    const diffHypotCoef = imageHypot - diffXYHypot;

    // calc how much percent consists in a visible canvas segment
    const percentPart = 100 - ((diffHypotCoef * 100) / imageHypot);

    // calc meter to pixel coeficient for current visible segment
    const actualMeterCoef = meterPixelCoef * (percentPart / 100);

    // calc scale
    const scale = actualMeterCoef * canvToImagePixel;

    return scale;
};

/**
 * Calc image meter coeficient 
 * 
 * Calc meter to pixel coeficient and canvas to image pixel coeficient
 * 
 * @param meterData 
 * @param workareaData 
 * @param imageData 
 * @returns 
 */
export const calcImageMeterCoef = (
    meterData: { meterHeight: number, meterWidth: number },
    workareaData: { workareaHeight: number, workareaWidth: number },
    imageData: { height: number, width: number },
    measure: IMeasure,
): {
    imageHypot: number,
    meterPixelCoef: number,
    canvToImagePixel: number,
} => {

    const { scaleX = 1, scaleY = 1 } = measure;

    const { meterHeight, meterWidth } = meterData;

    const { workareaHeight, workareaWidth } = workareaData;

    const { height, width } = imageData;

    const meterHypot = Math.hypot(meterHeight, meterWidth);

    const workareaHypot = Math.hypot(workareaHeight, workareaWidth);

    const imageHypot = Math.hypot(height * scaleY, width * scaleX);

    const meterPixelCoef = meterHypot / imageHypot;

    const canvToImagePixel = imageHypot / workareaHypot;

    return {
        imageHypot,
        meterPixelCoef,
        canvToImagePixel,
    };
};