export const notificationConstants = {
    LIST_NOTIFICATION_SUCCESS: 'LIST_NOTIFICATION_SUCCESS',
    LIST_NOTIFICATION_FAILURE: 'LIST_NOTIFICATION_FAILURE',

    MASK_AS_READ_NOTIFICATION_SUCCESS: 'MASK_AS_READ_NOTIFICATION_SUCCESS',
    MASK_AS_READ_NOTIFICATION_FAILURE: 'MASK_AS_READ_NOTIFICATION_FAILURE',

    MASK_ALL_AS_READ_NOTIFICATION_SUCCESS: 'MASK_ALL_AS_READ_NOTIFICATION_SUCCESS',
    MASK_ALL_AS_READ_NOTIFICATION_FAILURE: 'MASK_ALL_AS_READ_NOTIFICATION_FAILURE',

    UPDATE_COMMENT_NOTIFICATION_SUCCESS: 'UPDATE_COMMENT_NOTIFICATION_SUCCESS',
    UPDATE_COMMENT_NOTIFICATION_FAILURE: 'UPDATE_COMMENT_NOTIFICATION_FAILURE',

};