import { IChartDataWithColor } from '../../../hooks/histogramChart/useDataHistogram';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGraphDataShowLoader } from '../../../core/selectors/graphData/graphDataSelector';
import Skeleton from '@material-ui/lab/Skeleton';
import { IStateItem } from '../../../core/interfaces';

type IHistogramSkeleton = {
    height: number;
    screenWidth: number;
    realTimeIndentation: number;
    dataWithAlertColor: IChartDataWithColor[] | IStateItem[];
    sensorType: string;
    showLoader: boolean;
};

const HistogramSkeleton: React.FC<IHistogramSkeleton> = (
    {
        height,
        realTimeIndentation,
        screenWidth,
        dataWithAlertColor,
        sensorType,
        showLoader,
    },
) => {

    const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
    const graphDataShowLoader = useSelector(selectGraphDataShowLoader);

    useEffect(() => {
        if (showLoader) {
            setShowSkeleton(dataWithAlertColor.length <= (sensorType === 'state' ? 0 : 15));
        } else {
            setShowSkeleton(!showLoader && graphDataShowLoader)
        }

        return () => {
            setShowSkeleton(false);
        };
    }, [graphDataShowLoader, dataWithAlertColor, sensorType, showLoader]);

    const skeletonStyle: React.CSSProperties = {
        background: 'rgba(64, 146, 245, 0.36)',
    };
    return (
        <>
            {showSkeleton &&
                <Skeleton
                    variant={'rect'}
                    style={skeletonStyle}
                    height={height}
                    width={screenWidth - realTimeIndentation}
                    animation={'pulse'}
                />
            }
        </>
    );
};

export default React.memo(HistogramSkeleton);