import { createSelector } from 'reselect';
import { calcRealTimeIndentation, selectBrushSelection } from '../graphMinimapBrush/graphMinimapBrushSelector';
import store, { RootState } from '../../store';
import { IChartDataWithColor } from '../../../hooks/histogramChart/useDataHistogram';
import { IStateItem } from '../../interfaces';
import { selectGraphRule } from '../graphRule/graphRuleSelector';
import * as d3 from 'd3';
import { selectDashboardOnline, selectScreenWidth } from '../dashboard/dashboardSelector';
import { selectDrawerWidth, selectPositionDrawer } from '../layout/responsiveDrawerSelector';
import { selectSelectedDashboard } from '../dashboardSelect/selectedDashboardSelector';
import { selectMonitoringTreeCollection } from '../monitoringTree/monitoringTreeCollectionSelector';
import { ISensor } from '../../interfaces';
import { getActualLastValueIndex, getFilteredDashboardSensors } from '../../../helpers/playerSelector';
import { selectGraphDataLastValuesById } from '../graphDataLastValue/graphDataLastValueSelector';
import { isMobileOnly } from 'react-device-detect';
import { selectHmiHoverItem, selectHmiHoverSensor } from './playerHoverItem';
import { selectHmiPlayerVisibility } from './visibilitySelector';

const stateSelector = (state: RootState) => state.hmiPlayer;

export const selectHmiPlayerMode = createSelector(
    stateSelector,
    player => player.mode,
);

export const selectHmiPlayerSpeed = createSelector(
    stateSelector,
    player => player.speed,
);

export const selectHmiPlayerRealTimeStatus = createSelector(
    stateSelector,
    player => player.realTime,
);

export const selectHmiPlayerValue = createSelector(
    stateSelector,
    selectBrushSelection,
    (player, range) => {

        if (player.value > 0) {

            return player.value;
        }

        const [start] = range;

        return start.getTime();
    },
);

export const selectHmiPlayerSchema = createSelector(
    stateSelector,
    player => player.schema,
);

export const selectHmiPlayerSchemaDefault = createSelector(
    stateSelector,
    player => player.defaultSchema,
);

export const selectVisibilityObjectOnScheme = createSelector(
    stateSelector,
    player => player.showObjects,
);

export const selectVisibilitySchemeOnScheme = createSelector(
    stateSelector,
    player => player.showHmiMap,
);

export const selectOpacityScheme = createSelector(
    stateSelector,
    player => player.opacityHmiMap,
);

export const selectPlayerDataHistogram = createSelector(
    stateSelector,
    player => player.objectsWithData,
);

//TODO: create selector for sensor value using selectHmiPlayerValue, selectSensorDataBySensorId & selectStateDataBySensorId

const  scale = d3.scaleTime();

const selectHoverSensorItem = createSelector(
    selectHmiHoverSensor,
    selectHmiHoverItem,
    (hoverSensor, hoverItem)=>{
        return {
            hoverSensor,
            hoverItem,
        };
    },
);

export const isVisibleHMIPlayerScheme = createSelector(
    selectHmiPlayerSchema,
    selectHmiPlayerVisibility,
    (schema, visible) => visible && schema !== null,
);

const getCurrentValueByTimeScale = (
    data: IChartDataWithColor[],
    selection: Date[],
    width: number,
    realTimeIndentation: number,
    x: number,
    realtimeStatus: boolean,
    lastValue: number | null,
): IChartDataWithColor => {

    const [from, to] = selection;

    const screenScale = d3.scaleLinear().domain([from, to].map(d => d.getTime())).range([0, width - 1]);
    const state = store.getState();
    const isDashboardOnline = selectDashboardOnline(state);
    const schema = selectHmiPlayerSchema(state);
    const isVisible = selectHmiPlayerVisibility(state) && schema !== null;

    const valueScale = d3.scaleLinear().domain(
        [new Date(data[0]?.timestamp || selection[0]).getTime(),
            new Date(data[data.length - 1]?.timestamp || selection[1]).getTime()],
    ).range([0, data.length - 1]);

    const realTimePositionTimestamp = screenScale.invert(width -1);
    const realTimePositionInValues = valueScale(realTimePositionTimestamp) as number;

    // convert x to index in valueScale
    // convert x to index in valueScale
    const xTimestamp = screenScale.invert(x);
    const valueIndex = valueScale(xTimestamp) as number;
    let index = Math.round(valueIndex);



    const isRightEdge = index >= (data || []).length - 1;

    const mayFallbackToLeft = isDashboardOnline && isRightEdge;
    const shouldUseLastValue = (realtimeStatus || mayFallbackToLeft) && lastValue !== null;

    if (realtimeStatus && isVisible) {

        index = Math.round(realTimePositionInValues);
    }

    if (index < 0) {

        index = 0;
    }

    const returnData = data[Math.abs(index)];

    if (shouldUseLastValue && lastValue && returnData) {

        returnData.value = lastValue;
    }

    return returnData?returnData: x>0?data[data.length -1]: data[0];
};

export const selectHmiValueBySensorId = createSelector(
    selectHmiPlayerValue,
    selectBrushSelection,
    selectPlayerDataHistogram,
    selectGraphRule,
    selectScreenWidth,
    selectDrawerWidth,
    selectPositionDrawer,
    calcRealTimeIndentation,
    selectSelectedDashboard,
    selectMonitoringTreeCollection,
    selectHmiPlayerRealTimeStatus,
    selectHoverSensorItem,
    (
        playerValue,
        selection,
        hmiObjectWithData,
        graphRule,
        screenWidth,
        drawWidth,
        anchor,
        realTimeIndentation,
        selectedDashboard,
        treeCollection,
        realtimeStatus,
        dedicatedSensorItem,
    ) => {

        if (hmiObjectWithData) {

            let minimizedUnitSensors: number[] = [];

            let dashboardSensors = new Map<number, ISensor>();

            if (selectedDashboard) {

                const filteredSensors = getFilteredDashboardSensors(treeCollection, selectedDashboard.id);

                minimizedUnitSensors = filteredSensors.minimizedUnitSensors;

                dashboardSensors = filteredSensors.dashboardSensors;
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [from, to] = selection;
            const index = Math.abs(graphRule.x < 0 ? 0 : graphRule.x);
            const histogramWidth = screenWidth - (!isMobileOnly && anchor === 'right' ? drawWidth : 0) - realTimeIndentation;

            scale.domain(selection).range([0, histogramWidth]);
            const { hoverItem, hoverSensor } = dedicatedSensorItem;

            const includeSensor = hmiObjectWithData.find(value => value.sensor.id === hoverSensor?.id || value.sensor.id === hoverItem?.sensorId);

            return hmiObjectWithData.map(value => {

                const { dataHistogram, sensor } = value;
                // last value for real-time
                const lastValue = selectGraphDataLastValuesById(store.getState(), sensor.id);

                const dataFromSensor = sensor.sensorType !== 'state' ?
                    (dataHistogram as IChartDataWithColor[])
                    : (dataHistogram as IStateItem[]);

                if (dataHistogram) {

                    const calculateBarWidth = Math.abs((histogramWidth) / dataFromSensor.length);

                    const actualLastValueIndex = sensor.sensorType === 'state'
                        ? getActualLastValueIndex(dataHistogram as IStateItem[])
                        : 1;
                    const currentValue = playerValue <= to.getTime() ?
                        sensor.sensorType !== 'state' ?
                            // dataFromSensor[Math.round(index / (!isFinite(calculateBarWidth) ? 1 : calculateBarWidth))]
                            getCurrentValueByTimeScale(
                                dataFromSensor as IChartDataWithColor[],
                                selection, histogramWidth,
                                realTimeIndentation,
                                index,
                                realtimeStatus,
                                lastValue,
                            )
                            : (dataHistogram as IStateItem[]).find((dh) => {

                                return new Date(dh.startTime).getTime() <= scale.invert(index).getTime() && new Date(dh.endTime || to).getTime() >= scale.invert(index).getTime();

                            })
                        : dataHistogram[dataHistogram.length - actualLastValueIndex];

                    const dashboardSensor = dashboardSensors?.get(value.sensor.id) || value.sensor;

                    if (dashboardSensor.isVisible && !minimizedUnitSensors.includes(value.sensor.id)) {

                        return {
                            id: value.id,
                            color: currentValue?.color || (currentValue as IStateItem)?.zoneColor || '#cdd0d4',
                            frameColor: (currentValue as IStateItem)?.frameColor ? (currentValue as IStateItem)?.frameColor : '#74797d',
                            originColor: (currentValue as IChartDataWithColor)?.originColor || (currentValue as IStateItem)?.zoneColor,
                            value: realtimeStatus? lastValue : currentValue?.value,
                            stateText: sensor.sensorType === 'state'? ((currentValue as IStateItem)?.causeName || (currentValue as IStateItem)?.stateCategoryName): undefined,
                            type: value.sensor.type,
                            sensorType: value.sensor.sensorType,
                            sensorId: value.sensor.id,
                            um: value.sensor.um,
                            sensorName: sensor?.pIdCode || sensor?.name || '',
                            hoverEffect: {
                                dirty: true,
                                shadow: dashboardSensor.isVisible && !minimizedUnitSensors.includes(value.sensor.id) && (hoverSensor?.id === value.sensor.id || hoverItem?.sensorId === value.sensor.id),
                                opacity: (hoverSensor || hoverItem) && includeSensor    ?
                                    !minimizedUnitSensors.includes(value.sensor.id) && (hoverSensor?.id === value.sensor.id || hoverItem?.sensorId === value.sensor.id) ? 1 : 0.5
                                    : 1,
                            },
                        };

                    } else {

                        return {
                            id: value.id,
                            color: '#cdd0d4',
                            frameColor: '#74797d',
                            originColor: '',
                            value: '',
                            type: value.sensor.type,
                            sensorType: value.sensor.sensorType,
                            sensorId: value.sensor.id,
                            um: '',
                            sensorName: sensor?.pIdCode || sensor?.name || '',
                            hoverEffect: {
                                dirty: true,
                                shadow: dashboardSensor.isVisible && !minimizedUnitSensors.includes(value.sensor.id),
                                opacity: (hoverSensor || hoverItem) && includeSensor ?
                                    !minimizedUnitSensors.includes(value.sensor.id) && (hoverSensor?.id === value.sensor.id || hoverItem?.sensorId === value.sensor.id) ? 1 : 0.5
                                    : 1,
                            },
                        };

                    }
                }
            });
        }
        return [];
    },
);