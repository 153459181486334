import { Handler } from './Handler';
import { PolygonEditor } from '../editors/PolygonEditor';

/**
 * Drawing handler
 *
 * @class DrawingHandler
 */
export class DrawingHandler {

    /**
     * Main handler instance
     *
     * @type {Handler}
     */
    protected handler: Handler;

    /**
     * Polygon editor instance
     *
     * @type {PolygonEditor}
     */
    public polygon: PolygonEditor;

    /**
     * Constructor
     *
     * @param {Handler} handler
     */
    constructor(handler: Handler) {

        this.handler = handler;
        this.polygon = new PolygonEditor(handler);
    }
}
