import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Switch, { SwitchClassKey } from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import { DashboardActions, HmiPlayerActions } from '../../../../core/actions';
import {
    selectHmiPlayerMode,
    selectHmiPlayerRealTimeStatus,
    selectHmiPlayerSpeed,
} from '../../../../core/selectors/hmi/playerSelector';
import { PlayerMode } from '../../../../core/interfaces';
import { selectBrushSelection } from '../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { withStyles } from '@material-ui/core';
import { GraphActions } from '../../../../base/store/actions';
import { selectGraphCurrentPeriod } from '../../../../core/selectors/graphPeriod/graphPeriodSelector';
import { appConfig } from '../../../../config/appConfig';
import { selectDashboardOnline } from '../../../../core/selectors/dashboard/dashboardSelector';
import { isIOS, isMobile, isTablet } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            backgroundColor: '#ffffff',
            margin: theme.spacing(1),
        },
    }),
);

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}


const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 48,
            height: 24,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 4,
            '&$checked': {
                transform: 'translateX(24px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#4092F5',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#4092F5',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 20,
            height: 20,
            margin: 2,
        },
        track: {
            borderRadius: 24 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            // transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: {
    classes: Styles;
}) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            className="realtime-switcher"
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

/**
 * HMI Player buttons component
 *
 * @constructor
 */
const Buttons: FC = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [speedMenuAnchor, setSpeedMenuAnchor] = React.useState<null | HTMLElement>(null);

    const { t } = useTranslation();

    const playerMode = useSelector(selectHmiPlayerMode);
    const playerSpeed = useSelector(selectHmiPlayerSpeed);
    const realTimeStatus = useSelector(selectHmiPlayerRealTimeStatus);
    const selection = useSelector(selectBrushSelection);
    const currentPeriod = useSelector(selectGraphCurrentPeriod);
    const dashboardOnline = useSelector(selectDashboardOnline);

    /**
     *  Start/Pause HMI player
     *
     * @type {() => void}
     */
    const startPausePlay = useCallback(() => {

        if (playerMode === PlayerMode.MODE_PLAY && realTimeStatus) {

            dispatch(HmiPlayerActions.setRealTime(false));
            dispatch(HmiPlayerActions.setValue(new Date(selection[0]).getTime()));

            const playTimeout = setTimeout(() => {

                dispatch(HmiPlayerActions.play());

                clearTimeout(playTimeout);

            }, 100);
        }

        if (playerMode === PlayerMode.MODE_PLAY && !realTimeStatus) {

            dispatch(HmiPlayerActions.pause());

        } else if (!realTimeStatus) {

            dispatch(HmiPlayerActions.play());
        }

    }, [playerMode, realTimeStatus, selection, dispatch]);

    /**
     * Stop/Play Callback
     *
     * @type {() => void}
     */
    const stopPlay = useCallback(() => {

        dispatch(HmiPlayerActions.stop());
        dispatch(HmiPlayerActions.setRealTime(false));

    }, [dispatch]);

    /**
     * Open speed menu
     *
     * @param {React.MouseEvent} event
     */
    const openSpeedMenu = useCallback((event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {

        if (!realTimeStatus) {

            setSpeedMenuAnchor(event.currentTarget);
        }
    }, [realTimeStatus, setSpeedMenuAnchor]);

    /**
     * Open speed menu
     *
     * @param {React.MouseEvent} event
     */
    const openSpeedMenuOnClick = useCallback((event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
        if (isMobile) {
            if (!realTimeStatus) {

                setSpeedMenuAnchor(event.currentTarget);
            }
        }
    }, [realTimeStatus, setSpeedMenuAnchor]);

    /**
     * Close speed menu
     */
    function closeSpeedMenu() {

        setSpeedMenuAnchor(null);
    }

    /**
     * Change player speed
     *
     * @param {React.MouseEvent} event
     */
    function changeSpeed(event: React.MouseEvent<HTMLLIElement>) {

        dispatch(HmiPlayerActions.setSpeed(realTimeStatus? 1 :parseFloat(event.currentTarget?.dataset?.speed || '1')));

        // closeSpeedMenu();
    }

    /**
     * Change Real Time function
     *
     * @param {React.ChangeEvent<{}>} event
     * @param {boolean} checked
     */
    const changeRealTime = useCallback((event: React.ChangeEvent<{}>, checked: boolean) => {

        dispatch(HmiPlayerActions.setRealTime(checked));

        if (checked) {

            dispatch(HmiPlayerActions.setValue(selection[1].getTime()));
            dispatch(HmiPlayerActions.play());
            dispatch(HmiPlayerActions.setSpeed(1));

            const changeRealTime = setTimeout(() => {

                if (!dashboardOnline) {

                    dispatch(GraphActions.periodSelect(currentPeriod || appConfig.defaultMonitoringView));
                    dispatch(DashboardActions.setOnCurrentTime());
                }

                clearTimeout(changeRealTime);
            }, 0);

        } else {

            dispatch(HmiPlayerActions.stop());
        }
    }, [dispatch, currentPeriod, selection, dashboardOnline]);

    useEffect(() => {

        if (realTimeStatus) {

            dispatch(HmiPlayerActions.setValue(new Date().getTime()));

        } else {
            if (playerMode !== PlayerMode.MODE_PLAY) {
                dispatch(HmiPlayerActions.setValue(selection[0].getTime()));
            }
        }

    }, [realTimeStatus, selection, dispatch, playerMode]);

    return (
        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
            <Grid item className={`hmi-btn-group ${isIOS ? 'ios' : ''}`} >
                <Fab
                    className={`${classes.btn} ${playerMode === PlayerMode.MODE_PLAY && !realTimeStatus && 'pause-icon'}`}
                    onClick={startPausePlay}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    variant={'circular'}
                >
                    {(playerMode !== PlayerMode.MODE_PLAY || realTimeStatus) && <PlayArrowIcon />}
                    {playerMode === PlayerMode.MODE_PLAY && !realTimeStatus && <PauseIcon />}
                </Fab>
                <Fab
                    variant={'circular'}
                    size="medium"
                    className={classes.btn}
                    onClick={stopPlay}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                >
                    <StopIcon />
                </Fab>
                <Fab
                    variant={'circular'}
                    size="medium"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    className={classes.btn}
                    disabled={realTimeStatus}
                    onMouseEnter={openSpeedMenu}
                    onClick={openSpeedMenuOnClick}
                >
                    {playerSpeed}x
                </Fab>
                <Menu
                    anchorEl={speedMenuAnchor}
                    getContentAnchorEl={null}
                    keepMounted
                    onClose={closeSpeedMenu}
                    open={Boolean(speedMenuAnchor)}
                    elevation={0}
                    variant={'menu'}
                    className="hmi-menu-speed"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            border: '1px solid #d3d4d5',
                        },
                    }}
                    MenuListProps={{
                        onMouseLeave: closeSpeedMenu,
                    }}
                >
                    {[1, 2, 10, 25, 50, 100].map((value: number) => (
                        <MenuItem
                            key={`speed-menu-item-${value}`}
                            onClick={changeSpeed}
                            data-speed={value}
                            selected={value === playerSpeed}
                        >
                            {value}x
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
            <Grid item>
                <FormControlLabel
                    value="real-time"
                    className="realtime-switcher-wrapper"
                    checked={realTimeStatus}
                    control={<AntSwitch />}
                    label={isTablet ? '' : t('REALTIME')}
                    labelPlacement="start"
                    onChange={changeRealTime}
                />
            </Grid>
        </Grid>
    );
};

export default Buttons;
