import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { mouseConstants } from '../constants';

/**
 * Mouse related actions
 *
 * @type {Object}
 */
export const MouseActions = {

    /**
     * Track mouse position
     *
     * @param {Object} event
     *
     * @return {Promise<Object>}
     */
    track: (event: {clientX: number, clientY: number}) => {

        const extractCoords = () => {

            return {
                type: mouseConstants.MOVE,
                x: event.clientX,
                y: event.clientY,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(extractCoords());
        };
    },
};
