import React, { MutableRefObject, useCallback, useState } from 'react';
import { Canvas } from '../../../../Editor/canvas/Canvas';
import { IEditorTool, InteractionMode } from '../../../../../../../base/components/Editor/interfaces';
import { editorConstants } from '../../../../Editor/constants';

export const useEditor = (canvasRef: MutableRefObject<Canvas | null>) => {
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedTool, setSelectedTool] = useState<any>(null);
    const [zoomRatio, setZoomRatio] = useState(1);
    const [editing, setEditing] = useState(false);
    const [objects, setObjects] = useState([]);
    const [grab, setGrab] = useState(true);

    /**
     * Switch between selection and grab modes
     *
     * @returns {boolean}
     */
    const switchGrab = useCallback(() => {

        if (canvasRef.current && !canvasRef.current.handler.interactionHandler.isDrawingMode()) {

            if (canvasRef.current.handler.interactionMode === editorConstants.interaction.modeSelection) {

                canvasRef.current.handler.interactionHandler.grab();

                canvasRef.current.handler.menuActionMode = '';

                setGrab(true);

            } else {

                canvasRef.current.handler.interactionHandler.selection();

                canvasRef.current.handler.menuActionMode = editorConstants.interaction.modeEdit;

                setGrab(false);
            }
        }
    }, [canvasRef]);

    const selectTool = useCallback((tool: IEditorTool) => {

        if (canvasRef.current) {

            //TODO: add check for an active shape
            if (canvasRef.current.handler.interactionHandler.isDrawingMode()) {

                return;
            }

            if (canvasRef.current.handler.interactionMode === editorConstants.interaction.modeGrab) {

                switchGrab();
            }

            if (tool.superType === 'drawing') {

                switch (tool.type) {

                    case editorConstants.interaction.modePolygon:

                        canvasRef.current.handler.drawingHandler.polygon.activate(tool.options);

                        break;

                    case editorConstants.interaction.modeMarker:

                        canvasRef.current.handler.interactionHandler.drawing(editorConstants.interaction.modeMarker as InteractionMode);

                        break;

                    case editorConstants.interaction.modeGpsPin:

                        canvasRef.current.handler.interactionHandler.drawing(editorConstants.interaction.modeGpsPin as InteractionMode, {
                            cursor: `url('${require('../../../../../ui/assets/images/icons/map-pin_2.svg')}') 16 46, auto`,
                        });

                        break;
                }
            }
        }

        setSelectedTool(tool.id);
    }, [canvasRef, switchGrab]);

    const onSelect = useCallback((target: any) => {

        if (target && target.id && target.id !== 'workarea' && target.type !== 'activeSelection') {

            if (selectedItem && target.id === selectedItem!.id) {
                return;
            }

            setSelectedItem(target);

            return;
        }

        setSelectedItem(null);
    }, [selectedItem]);

    /**
     * Create new object callback
     *
     * @param target
     */
    const onAdd = useCallback((target: any) => {
        if (!editing) {

            setEditing(true);
        }

        if (target.type === 'activeSelection') {

            onSelect(null);
        }

        if (canvasRef.current) {

            canvasRef.current.handler.select(target);
        }

        setSelectedTool(null);
    }, [canvasRef, editing, onSelect]);

    const onRemove = useCallback(() => {

        if (!editing) {

            setEditing(true);
        }

        onSelect(null);
    }, [editing, onSelect]);

    /**
     * Zoom callback
     *
     * @param {number} zoom
     */
    const onZoom = useCallback((zoom: number) => {
        setZoomRatio(zoom);
    }, []);

    const sliderOptions = {
        value: zoomRatio * 100,
        min: 30,
        max: 300,
    };

    /**
     * Handler for slider zoom change event
     *
     * @param {React.ChangeEvent} event
     * @param {number | number[]} value
     */
    const sliderZoomChange = useCallback((event: React.ChangeEvent<{}> | null, value: number | number[]) => {

        if (canvasRef.current && !Array.isArray(value)) {

            canvasRef.current.handler.zoomHandler.zoomToValue(value / 100);
        }
    }, [canvasRef]);

    return {
        onSelect,
        onAdd,
        onRemove,
        onZoom,
        selectTool,
        selectedTool,
        zoomRatio,
        objects,
        setObjects,
        sliderOptions,
        grab,
        sliderZoomChange,
        switchGrab,
    };
};
