import { fabric } from 'fabric';
import { CanvasObject as BaseCanvasObject } from '../../../../../base/components/Editor/canvas/CanvasObject';
import { ICanvasObjectSchema } from '../../../../../base/components/Editor/interfaces';

import Engine from '../objects/standard/Engine';
import Sensor from '../objects/standard/Sensor';
import Valve from '../objects/standard/Valve';
import Indicator from '../objects/standard/Indicator';
import Star from '../objects/custom/Star';
import PitCodeLabel from '../objects/standard/PitCodeLabel';

const CanvasObject: ICanvasObjectSchema = { ...BaseCanvasObject };

CanvasObject.rectangle = {

    create: (options) => {

        return new fabric.Rect(options);
    },
};

CanvasObject.oval = {

    create: (options) => {

        return new fabric.Circle(options);
    },
};

CanvasObject.triangle = {

    create: (options) => {

        return new fabric.Triangle(options);
    },
};

CanvasObject.star = {

    create: (options) => {

        return new Star(options);
    },
};

CanvasObject.engine = {

    create: (options) => {

        return new Engine(options);
    },
};

CanvasObject.sensor = {

    create: (options) => {

        return new Sensor(options);
    },
};

CanvasObject.valve = {

    create: (options) => {

        return new Valve(options);
    },
};

CanvasObject.indicator = {

    create: (options) => {

        return new Indicator(options);
    },
};

CanvasObject.pitCode = {

    create: (options) => {

        return new PitCodeLabel(options);
    },
};

export default CanvasObject;
