import { editorConstants as baseEditorConstants } from '../../../../../base/components/Editor/constants';

export const editorConstants = {
    interaction: { ...baseEditorConstants.interaction, ...{
        modeMarker: 'marker',
        modeGpsPin: 'gpsPin',
    } },
    objects: { ...baseEditorConstants.objects, ...{
        building: 'building',
        gateway: 'gateway',
        zone: 'zone',
        barrier: 'barrier',
        layer: 'layer',
        marker: 'marker',
        gpsPin: 'gpsPin',
    } },
    colors: { ...baseEditorConstants.colors },
};

export const gatewaysConstants = [
    {
        label: 'gateway 1',
        value: '1',
    },
    {
        label: 'gateway 2',
        value: '2',
    },
    {
        label: 'gateway 3',
        value: '3',
    },
];
