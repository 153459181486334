import { Action } from 'redux';
import { ILocation } from '../../interfaces';

export enum LocationEditorActionTypes {
    RESET_WITH_LOCATION_MODEL = '[Location Editor] Reset with location model',
}

export class ResetWithLocationModelAction implements Action {
    readonly type = LocationEditorActionTypes.RESET_WITH_LOCATION_MODEL;

    constructor(public payload: {
        location: ILocation | null,
    }) {
    }
}

export type LocationEditorActions = ResetWithLocationModelAction;
