import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import EmployeeItem from './EmployeeItem';
import { ReactComponent as DropdownArrow } from '../../../../../core/ui/assets/images/icons/dropdown-arrow.svg';
import {
    DragDropContext,
    Draggable,
    DraggableProvided, DragUpdate,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd';
import { GraphActions } from '../../../../../base/store/actions';
import { connect, useSelector } from 'react-redux';
import { IEmployeeTree, IHRDashboardUpdateModel } from '../../../interfaces';
import { IHrState } from '../../../store/reducers';
import { monitoringTreeDashboardThunks } from '../../../store/thunks/monitoringTreeDashboardThunks';
import { ICollapsedLayoutProps } from '../../../../interfaces';
import { Merge } from '../../../../../helpers/mergeType';
import { RootState } from '../../../../../core/store';
import { selectScreenWidth } from '../../../../../core/selectors/dashboard/dashboardSelector';
import { calcRealTimeIndentation } from '../../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectHmiPlayerMode } from '../../../../../core/selectors/hmi/playerSelector';

interface IPlaceholderProps {
    id: number
    clientHeight:number,
    clientWidth: number,
    clientY: number,
    clientX: number,
}

const CollapsedLayout: React.FC<ICollapsedLayoutProps> = (
    {
        department,
        editable,
        firstChild,
        maxWidthSideBar,
        peakEnterEmpty,
        peakLeave,
        index,
        monitoringTree,
        updateDashboard,
        searchField,
        selectedDashboard,
    }: ICollapsedLayoutProps,
) => {

    const [expanded, setExpanded] = useState(!department.isMinimize);
    const [employees, setEmployees] = useState<IEmployeeTree[]>(department.employees);
    const [placeholderProps, setPlaceholderProps] = useState<IPlaceholderProps | null>(null);

    const screenWidth = useSelector(selectScreenWidth),
        realTimeIndentation = useSelector(calcRealTimeIndentation),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    useEffect(()=>{

        setExpanded(!department.isMinimize);

        setEmployees(department.employees);

    }, [department]);

    const onChangeCallback = useCallback((event: React.ChangeEvent<Record<string, unknown>>, expanded: boolean) => {

        event.preventDefault();

        if (editable && monitoringTree) {

            const sendData: IHRDashboardUpdateModel = {
                id: monitoringTree.id,
                isMinimized: monitoringTree.isMinimized,
                department: {
                    id: department.id,
                    isMinimized: !expanded,
                },
            };

            updateDashboard(sendData);
        }

        setExpanded(expanded);

    }, [setExpanded, updateDashboard, monitoringTree, department, editable]);

    /**
     * Update placeholder when item is dragged
     *
     * @param {DragUpdate} update
     * */
    const onDragUpdate=useCallback((update: DragUpdate)=> {

        if (!update.destination) {

            return;

        }

        const insertItem = <T, >(arr: T[], item: T, index: number) => [
            ...arr.slice(0, index),
            item,
            ...arr.slice(index),
        ];

        const removeItem = <T, >(arr: T[], index: number) => [
            ...arr.slice(0, index),
            ...arr.slice(index + 1),
        ];

        const swapElements = <T, >(arr: T[], source: number, destination: number) =>
                insertItem(removeItem(arr, source), arr[source], destination),
            draggableAttr = 'data-rbd-drag-handle-draggable-id',
            droppableAttr = 'data-rbd-droppable-id',
            getAttr = (key: string, value: string) => `[${key}="${value}"]`,
            { source, destination } = update,
            draggableQuery = getAttr(draggableAttr, update.draggableId),
            droppableQuery = getAttr(droppableAttr, destination.droppableId),
            draggable = document.querySelector(draggableQuery) as HTMLElement,
            droppable = document.querySelector(droppableQuery) as HTMLElement;


        if (!draggable || !droppable) {

            return;
        }

        const { clientHeight, clientWidth } = draggable;

        const reorderedChildren = source.droppableId === destination.droppableId
            ? swapElements(Array.from(droppable.children), source.index, destination.index)
            : insertItem(Array.from(droppable.children), draggable, destination.index);

        const clientY =
            parseFloat(window.getComputedStyle(droppable).paddingTop) +
            Array.from(reorderedChildren)
                .slice(0, destination.index)
                .reduce((total, curr) => {

                    if (curr) {
                        const style = window.getComputedStyle(curr);
                        const marginBottom = parseFloat(style.marginBottom);

                        return total + curr.clientHeight + marginBottom;
                    }

                    return total;

                }, 0);

        setPlaceholderProps({
            id: parseInt(destination.droppableId),
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(window.getComputedStyle(droppable).paddingLeft),
        });

    }, [setPlaceholderProps]);


    /**
     * Do actual re-order
     *
     * @param {IDashboard[]} list
     * @param {number} startIndex
     * @param {number} endIndex
     *
     * @return array
     */
    const reorder = (list: IEmployeeTree[], startIndex: number, endIndex: number) => {

        const result = Array.from(list);

        const [removed] = result.splice(startIndex, 1);

        const draggedEl: IEmployeeTree = {
            id: removed.id,
            isVisible: removed.isVisible,
            surname: removed.surname,
            middleName: removed.middleName,
            firstName: removed.firstName,
            position: endIndex,
        };

        if (monitoringTree) {

            updateDashboard({
                id: monitoringTree.id,
                isMinimized: monitoringTree.isMinimized,
                employee: {
                    id: draggedEl.id,
                    isVisible: draggedEl.isVisible,
                    position: draggedEl.position,
                },
            });
        }

        result.splice(endIndex, 0, removed);

        return result;
    };

    /**
     * Re-order dashboard pages after drag
     *
     * @param {DropResult} result
     */
    const onDragEnd = useCallback((result: DropResult) => {

        if (!result.destination) {

            setPlaceholderProps(null);

            return;
        }

        const newEmployees = reorder(
            employees,
            result.source.index,
            result.destination.index,
        );

        setEmployees(newEmployees);

        setPlaceholderProps(null);

    }, [employees, setEmployees, setPlaceholderProps]);

    /**
     * Callback on peakLeave
     *
     * @type {() => void}
     */
    const peakLeaveCallback = useCallback(() => {

        peakLeave();

    }, [peakLeave]);

    /**
     * Callback on Mouse Move
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        const index = (event.pageX - maxWidthSideBar);

        if (index <= screenWidth - realTimeIndentation) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {
                peakEnterEmpty(index);
            }
        }

    }, [peakEnterEmpty, maxWidthSideBar, screenWidth, realTimeIndentation, HMIPlayerStatus]);

    /**
     * Default offset for correct display
     * @type {number}
     */
    const defaultOffset = 3000;

    return (
        <Accordion
            className={'expansion-panel department-panel'}
            defaultExpanded={expanded}
            expanded={expanded}
            onChange={onChangeCallback}
        >
            <AccordionSummary
                aria-controls="panel1a-content"
                id={'department'}
            >
                <React.Fragment>
                    <div className="item-title"
                         style={{ display: 'flex', width: '100%' }}
                    >
                        <div
                            className={`right-section breadcrumb ${firstChild ? 'first-child' : ''} ${editable?'padding-1': ''}`}
                            style={{
                                transition: 'max-width 0.2s linear',
                                maxWidth: maxWidthSideBar,
                                minWidth: maxWidthSideBar,
                            }}
                            onMouseEnter={peakLeaveCallback}
                        >
                            <DropdownArrow />
                            {/*<Tooltip title={`${factory.name}/${process.name}/${unit.name}`}>*/}
                            <div className="breadcrumb-text without-dot" style={{ zIndex: 2 }}>
                                <div hidden={false}>
                                    {/*{!visibleSideBar?'HR Graph:':null} */}
                                    {department.name}
                                </div>
                            </div>
                            {/*</Tooltip>*/}
                        </div>
                        <div
                            className="left-section product-line"
                            onMouseMove={onMouseMoveCallback}
                        />
                    </div>
                </React.Fragment>
            </AccordionSummary>
            <AccordionDetails className="department-details">
                <DragDropContext
                    onDragEnd={onDragEnd}
                    onDragUpdate={onDragUpdate}
                >
                <Droppable
                    droppableId={department.id ? (department.id + defaultOffset).toString() : (index + defaultOffset).toString()}
                    key={(department.id || index) + defaultOffset}
                >
                    {(provided: DroppableProvided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            key={department.id + defaultOffset}
                            className="department-item"
                        >
                            {employees.map((employee, index, array) => {
                                const firstVisibleState = array.findIndex(value => value.isVisible);
                                    const fullName = `${employee.firstName} ${employee.middleName} ${employee.surname}`;
                                    const showSearchResult = searchField.length > 0 ? fullName.toLowerCase().indexOf(searchField.toLowerCase()) : 1;

                                if (selectedDashboard && !selectedDashboard.graphs_switch && index !== (firstVisibleState !== -1 ? firstVisibleState : 0)) {

                                    return null;
                                }
                                    if (showSearchResult !== -1) {
                                        return (
                                            <Draggable
                                                key={employee.id}
                                                index={index}
                                                draggableId={`${employee.id}`}
                                            >
                                                {(provided: DraggableProvided) => (
                                                    <div
                                                        key={'sensor-key-' + employee.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="custom-collapse-item"
                                                        style={{
                                                            userSelect: 'none',
                                                            width: '100%',
                                                            minHeight: 40,
                                                            background: 'transparent',
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        <EmployeeItem
                                                            editable={editable}
                                                            employee={employee}
                                                            visibleSideBar={false}
                                                            maxWidthSideBar={320}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    }
                                },
                            )}
                            {provided.placeholder}

                            {/*{placeholderProps && placeholderProps.id === department.id + defaultOffset ?*/}
                            {/*    <div*/}
                            {/*        className={'placeholder-props'}*/}
                            {/*        style={{*/}
                            {/*            position: 'absolute',*/}
                            {/*            top: (placeholderProps.clientY ? placeholderProps.clientY : 0) + 40,*/}
                            {/*            left: placeholderProps.clientX,*/}
                            {/*            height: placeholderProps.clientHeight,*/}
                            {/*            width: placeholderProps.clientWidth,*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                        </div>
                    )}
                </Droppable>
                </DragDropContext>
            </AccordionDetails>


        </Accordion>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: Merge<RootState |{
    hr: IHrState,
}>) => {

    const { monitoringTreeState } = state.hr;
    const { selectedDashboard } = state.dashboardSelect;

    const { monitoringTree } = monitoringTreeState;

    return {
        monitoringTree,
        selectedDashboard,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    peakEnterEmpty: GraphActions.peakEnterEmptyLine,
    peakLeave: GraphActions.peakLeave,
    updateDashboard: monitoringTreeDashboardThunks.updateDashboard,
});


export default connect(mapStateToProps, mapDispatchToProps)(CollapsedLayout);
