import { RootState } from '../../store';
import { createSelector } from 'reselect';
import { selectBrushSelection, selectGraphMinimapBrushRule } from '../graphMinimapBrush/graphMinimapBrushSelector';
import { selectGraphPeriodRange } from '../graphPeriod/graphPeriodSelector';

const selectGraphDataState = (state: RootState) => state.graphDataReducer;

export const selectGraphDataAll = createSelector(
    selectGraphDataState,
    (graphDataReducer) => graphDataReducer.graphData,
);

export const selectGraphDataShowLoader = createSelector(
    selectGraphDataState,
    (graphDataReducer) => graphDataReducer.showLoader,
);

export const selectGraphDataRangeAll = createSelector(
    selectGraphDataState,
    (graphDataReducer) => graphDataReducer.graphDataRAnge,
);

export const selectGraphDataRefresh = createSelector(
    selectGraphDataState,
    (graphDataReducer) => graphDataReducer.refresh,
);

export const selectGraphDataRefreshRange = createSelector(
    selectGraphDataState,
    (graphDataReducer) => graphDataReducer.refreshRange,
);

const getId = (state:RootState, sensorId: number)=> sensorId;

export const chartBrashRole = createSelector(
    selectBrushSelection,
    selectGraphPeriodRange,
    (range, maineRange)=> new Date(range[0]).getTime() === new Date(maineRange.startDate).getTime() && new Date(range[1]).getTime() === new Date(maineRange.endDate).getTime(),
);

export const selectGraphDataById = createSelector(
    selectGraphDataAll,
    selectGraphDataRangeAll,
    selectGraphMinimapBrushRule,
    getId,
    chartBrashRole,
    (graphData, graphDataRAnge, brushRule, sensorId, charBrashRole) => {

        const sendGraphData = graphData && graphData.has(sensorId) && graphData.get(sensorId),
            sendGraphDataRAnge = graphDataRAnge && graphDataRAnge.has(sensorId) && graphDataRAnge.get(sensorId);

        return charBrashRole ? sendGraphData : sendGraphDataRAnge ? sendGraphDataRAnge : sendGraphData;
    },
);