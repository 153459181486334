import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectBeaconState = createSelector(
    selectHrModuleState,
    state => state.beaconState,
);

export const selectBeacons = createSelector(
    selectBeaconState,
    state => state.beacons,
);
