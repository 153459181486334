import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectEditorGatewayState = createSelector(
    selectHrModuleState,
    state => state.editorGatewayState,
);

export const selectEditorGateways = createSelector(
    selectEditorGatewayState,
    state => state.gateways,
);
