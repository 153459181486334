import React from 'react';
import { ConfirmDialog, EditMenu, TextInput } from '../index';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    DragDropContext,
    Draggable,
    DraggableLocation,
    DraggableProvided,
    DraggableStateSnapshot,
    DragUpdate,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd';
import { Accordion, AccordionDetails, AccordionSummary, Input, ListItem } from '@material-ui/core';
import { ReactComponent as DropdownArrow } from '../../assets/images/icons/dropdown-arrow.svg';
import { ReactComponent as ProductView } from '../../assets/images/icons/product-view.svg';
import './DraggableList.scss';
import TooltipClickCopy from './../Tooltip/TooltipClickCopy';

/**
 *  interface for component
 */

interface Item {
    id?: number;
    name: string;
    color?: string;
    frameColor?: string;
    sensorCauseCode?: string;
}

interface IProps {
    i18n?: WithTranslation;
    tReady?: WithTranslation;
    t?: WithTranslation;
    draggable: any[];
    droppable: any[];
    draggableUnsorted: any[];
    addInput: boolean;
    addCategory?: boolean;
    option: any;
    onDragEnd?: (state: any, action: string, data?: any) => void;
    table: string;
    rightSideContext?: boolean;
    leftSideColorIcon?: boolean;
}

interface IState {
    draggableUnsorted: any[];
    draggable: any[];
    droppable: any[];
    edit: number[];
    editD: number[];
    editDU: number[];
    confirm: boolean;
    move: {
        source: Item[];
        destination: Item[];
        droppableSource: DraggableLocation;
        droppableDestination: DraggableLocation;
        sourceIbdex: string | number;
        Destination: string | number;
        destinationCategory: any[];
        moveCause: any;
    } | null;

    placeholderProps: {
        id?: any;
        clientY?: any;
        clientX?: any;
        clientHeight?: any;
        clientWidth?: any;
    },
    droppableId: number | null;
}


/**
 * Dragged items component
 *
 * @class DraggableList
 */
class DraggableList extends React.Component<IProps & WithTranslation, IState> {
    private focusId: number | undefined;

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {
        super(props);

        this.state = {
            draggableUnsorted: this.props.draggableUnsorted,
            draggable: this.props.draggable,
            droppable: this.props.droppable,
            edit: [],
            editD: [],
            editDU: [],
            confirm: false,
            move: null,
            placeholderProps: {},
            droppableId: null,
        };

        this.unActiveDroppable = this.unActiveDroppable.bind(this);
        this.unActiveDraggable = this.unActiveDraggable.bind(this);

    }


    /**
     * Component props update handler
     *
     * @param {IProps} nextProps Updated component properties
     */
    componentDidUpdate(nextProps: IProps) {

        if (this.props.draggable !== nextProps.draggable || this.props.droppable !== nextProps.droppable || this.props.draggableUnsorted !== nextProps.draggableUnsorted) {
                    
            this.setState({
                droppable: this.props.droppable,
                draggable: this.props.draggable,
                draggableUnsorted: this.props.draggableUnsorted,
            });
        }

    }

    /**
     *  a little function to help us with Reordering the result
     * @param list - Category Item[]
     * @param startIndex - index begin dragging
     * @param endIndex - index end dragging
     * @param index - droppable index (category )
     */
    reorder(list: Item[], startIndex: number, endIndex: number, index: string | number) {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        const { table } =this.props;
        result.splice(endIndex, 0, removed);

        const { draggable, draggableUnsorted } = this.state;
        
        let draggableNew = draggable, draggableUnsortedNew = draggableUnsorted;
        
        if (index !== 'unsorted') {
            draggableNew = draggable.map(value => {
                if (value.id === index) {
                    value[table] = result;
                    return value;
                } else {
                    return value;
                }
            });

        } else {
            draggableUnsortedNew = result;
        }

        this.setState({ draggableUnsorted: draggableUnsortedNew, draggable: draggableNew, placeholderProps: {} });

        if (this.props.onDragEnd) {

            const { draggableUnsorted, droppable, draggable } = this.state;

            const data ={ draggable, draggableUnsorted, droppable, endIndex, moved: removed, index };

            this.props.onDragEnd(data, 'reorder', data);
        }

    }

    addOpenStatus(data: any[]) {

        return data;
    }


    /**
     * Moves an item from one list to another list.
     * @param source - item dragging from Item[]
     * @param destination - item dragging to Item[]
     * @param droppableSource - index from Item[]
     * @param droppableDestination - index to Item[]
     * @param sourceIbdex - droppable index in state
     * @param Destination - droppable index in state
     */
    move(
        source: Item[],
        destination: Item[],
        droppableSource: DraggableLocation,
        droppableDestination: DraggableLocation,
        sourceIbdex: string | number,
        Destination: string | number,
    ) {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        const { table } =this.props;

        const indexSource = droppableSource.droppableId !== 'unsorted' ? parseInt(droppableSource.droppableId.replace(/\D+/g, '')) : 'unsorted';
        const indexDestination = droppableDestination.droppableId !== 'unsorted' ? parseInt(droppableDestination.droppableId.replace(/\D+/g, '')) : 'unsorted';

        destClone.splice(droppableDestination.index, 0, removed);

        const { draggable, draggableUnsorted } = this.state;
        const draggableNew = draggable.map(value => {
            if (value.id === indexSource) {
                value[table] = sourceClone;
                return value;
            } else if (value.id === indexDestination) {
                value[table] = destClone;
                return value;
            } else {
                return value;
            }
        });

        function unsortedNew() {
            if (indexSource === 'unsorted') {
                return sourceClone;
            } else if (indexDestination === 'unsorted') {
                return destClone;
            } else {
                return draggableUnsorted;
            }
        }

        this.setState({ draggable: draggableNew, draggableUnsorted: unsortedNew(), placeholderProps: {} });

        if (this.props.onDragEnd) {
            const { droppable, draggable } = this.state, draggableUnsorted = unsortedNew();
            const data = { draggable, draggableUnsorted, droppable };

            this.props.onDragEnd(this.state.move, 'move', data);
        }

    }


    /**
     *  Get item from state
     *
     * @param id { number }
     */
    getItem(id: number) {

        const currentUnit = this.state.draggable.filter(value => value.id === id);

        return currentUnit[0];
    }

    /**
     *  Result after dragged element
     *
     * @param result DropResult
     */
    onDragEnd(result: DropResult) {
        const { source, destination } = result;
        const { table } = this.props;

        // dropped outside the list
        if (!destination) {
            this.setState({ placeholderProps: {}, droppableId: null });
            return;
        }

        if (source.droppableId === destination.droppableId) {

            const index = parseInt(source.droppableId.replace(/\D+/g, ''));
            this.reorder(
                source.droppableId !== 'unsorted' ? this.getItem(index)[table] : this.state.draggableUnsorted,
                source.index,
                destination.index,
                source.droppableId !== 'unsorted' ? index : 'unsorted',
            );

        } else {
            const index = parseInt(source.droppableId.replace(/\D+/g, ''));
            const index2 = parseInt(destination.droppableId.replace(/\D+/g, ''));
            const moveCause = Array.from(source.droppableId !== 'unsorted' ? this.getItem(index)[table] : this.state.draggableUnsorted);

            this.setState({
                confirm: true,
                move: {
                    source: source.droppableId !== 'unsorted' ? this.getItem(index)[table] : this.state.draggableUnsorted,
                    destination: destination.droppableId !== 'unsorted' ? this.getItem(index2)[table] : this.state.draggableUnsorted,
                    droppableSource: source,
                    droppableDestination: destination,
                    sourceIbdex: source.droppableId !== 'unsorted' ? index : 'unsorted',
                    Destination: source.droppableId !== 'unsorted' ? index : 'unsorted',
                    destinationCategory: this.state.droppable.filter(value => value.id === index2),
                    moveCause: source.droppableId !== 'unsorted' ? moveCause[source.index] : moveCause[source.index],
                },
                placeholderProps: {},
                droppableId: null,
            });
        }
    }

    /**
     *  handle for open draggable element
     * @param index
     * @param isOpen
     */
    handleClick(index: number | string, isOpen: boolean | undefined) {
        const { draggable } = this.state;
        const draggableNew: any[] = draggable.map(value => {
            if (value.id === index) {
                value.isOpen = !isOpen;
                return value;
            } else {
                return value;
            }
        });

        if (isOpen === undefined) {
            draggableNew.push({
                id: parseInt(String(index)),
                isOpen: true,
                data: [],
            });
        }

        this.setState({ draggable: draggableNew });

    }

    /**
     * Default style for dragged item
     *
     * @param isDragging { boolean }
     * @param draggableStyle - default style
     */
    getItemStyle(isDragging: boolean, draggableStyle: any) {
        return { // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            width: '100%',
            height: 40,
            // change background colour if dragging
            background: '#ffffff',
            // background: isDragging ? 'lightgreen' : '#ffffff',

            // styles we need to apply on draggables
            ...draggableStyle,
        };
    }

    /**
     *  Active status Droppable
     * @param id
     */
    activeDroppable(id: number) {
        const { edit } = this.state;

        if (edit.indexOf(id) < 0) {

            edit.push(id);
            this.setState({ edit: edit });
        } else if (edit.indexOf(id) >= 0) {

            edit.splice(this.state.edit.indexOf(id), 1);
            this.setState({ edit: edit });
        }
    }

    unActiveDroppable(id: number) {
        const { edit } = this.state;

        edit.splice(this.state.edit.indexOf(id), 1);
        this.setState({ edit: edit });

    }

    /**
     *  Active status for dragged item
     * @param id item
     * @param type draggable or unsorted
     */
    activeDraggable(id: number, type: string) {

        const { editD, editDU } = this.state;
        if (type === 'draggable') {
            if (editD.indexOf(id) < 0) {
                editD.push(id);
            } else if (editD.indexOf(id) >= 0) {

                editD.splice(editD.indexOf(id), 1);
            }
        } else if (type === 'unsorted') {
            if (editDU.indexOf(id) < 0) {
                editDU.push(id);
            } else if (editDU.indexOf(id) >= 0) {

                editDU.splice(editDU.indexOf(id), 1);
            }
        }
        this.setState({ editD: editD, editDU: editDU });
    }

    unActiveDraggable(id: number, type: string) {
        const { editD, editDU } = this.state;
        if (type === 'draggable') {

            editD.splice(editD.indexOf(id), 1);

        } else if (type === 'unsorted') {

            editDU.splice(editDU.indexOf(id), 1);

        }
        this.setState({ editD: editD, editDU: editDU });
    }

    /**
     * Handler cancel dialog form
     */
    onConfirmDialog() {
        const { move } = this.state;
        if (move) {
            this.move(
                move.source,
                move.destination,
                move.droppableSource,
                move.droppableDestination,
                move.sourceIbdex,
                move.Destination,
            );
        }
    }

    /**
     * State item form cancel handler
     */
    handleCancel() {

        this.setState({
            confirm: false,
            move: null,
            placeholderProps: {},
            droppableId: null,
        });
    }

    /**
     * Update placeholder when item is dragged
     * @param {DragUpdate} update
     * */
    onDragUpdate(update: DragUpdate) {

        if (!update.destination) {

            return;

        }

        const insertItem = (arr: any, item: any, index: any) => [
            ...arr.slice(0, index),
            item,
            ...arr.slice(index),
        ];

        const removeItem = (arr: any, index: any) => [
            ...arr.slice(0, index),
            ...arr.slice(index + 1),
        ];

        const swapElements = (arr: any, source: any, destination: any) =>
                insertItem(removeItem(arr, source), arr[source], destination),
            draggableAttr = 'data-rbd-drag-handle-draggable-id',
            droppableAttr = 'data-rbd-droppable-id',
            getAttr = (key: any, value: any) => `[${key}="${value}"]`,
            { source, destination } = update,
            draggableQuery = getAttr(draggableAttr, update.draggableId),
            droppableQuery = getAttr(droppableAttr, destination.droppableId),
            draggable = document.querySelector(draggableQuery),
            droppable = document.querySelector(droppableQuery);


        if (!draggable || !droppable) {

            return;
        }

        const { clientHeight, clientWidth } = draggable;

        const reorderedChildren = source.droppableId === destination.droppableId

            ? swapElements(Array.from(droppable.getElementsByClassName('wrapper-draged-element')[0].children), source.index, destination.index)

            : insertItem(Array.from(droppable.getElementsByClassName('wrapper-draged-element')[0].children), draggable, destination.index);

        const clientY =
            parseFloat(window.getComputedStyle(droppable).paddingTop) +
            [...reorderedChildren]
                .slice(0, destination.index)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);

                    return total + curr.clientHeight + marginBottom;
                }, 0);

        this.setState({
            placeholderProps: {
                id: destination.droppableId,
                clientHeight,
                clientWidth,
                clientY,
                clientX: parseFloat(window.getComputedStyle(droppable).paddingLeft),
            },
            droppableId: Number(update.destination.droppableId),
        });
    }

    /**
     * Get draggable item classes
     * 
     * @param {any} item 
     * @returns {string}
     */
    getDraggableItemClasses(item: any): string {

        const { droppableId, edit } = this.state;

        return `list-draggable__item-wrap ${droppableId ? item.id === droppableId ? 'active' : 'inactive' : ''} ${edit.indexOf(item.id) < 0 ? '' : 'active'}`;
    }

    /**
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    focusOnHover(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        // this.focusId = event.currentTarget.tabIndex;
    }

    /**
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    focusOutHover(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        event.preventDefault();

        // this.focusId = undefined;
    }

    /**
     * Component render
     *
     * @returns {JSX.Element}
     */
    render(): React.ReactElement | string | number | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const { move } = this.state;
        const { option: { editItem }, table, t, rightSideContext, leftSideColorIcon } = this.props;

        const confirmHeading = t('ARE_YOU_SURE_WANT_TO_MOVE_THE_CAUSE_TO_CATEGORY', {
            cause: move ? move.moveCause.name : '',
            category: move ? move?.destinationCategory.length > 0 ? move?.destinationCategory[0].name : t('UNSORTED'): '',
        });

        return (
            <div className={'dragged-list'}>
                <TextInput
                    className={'form-field'}
                    value={this.props.option.search.value}
                    name="search"
                    type="text"
                    placeholder={this.props.t('SEARCH')}
                    icon={'search'}
                    onChange={this.props.option.search.handleSearch}
                />
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)} onDragUpdate={this.onDragUpdate.bind(this)}>
                    <React.Fragment>
                        {this.state.droppable.map((droppableItem, index) =>
// temporary
                            (<div
                                key={droppableItem.id}
                                className={'list-draggable '}
                                style={{ zIndex: 3 + index, overflow: 'hidden' }}
                            >
                                <Droppable
                                    droppableId={String(droppableItem.id.toString())}
                                    key={droppableItem.id}
                                    direction={'vertical'}
                                    ignoreContainerClipping={false}
                                    isCombineEnabled={false}
                                >
                                    {(provided: DroppableProvided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <Accordion
                                                className={`expansion-panel ${this.focusId && this.focusId === droppableItem.id ? 'hover' : ''}`}
                                                defaultExpanded={false}
                                                // defaultExpanded={this.getItem(droppableItem.id) && this.getItem(droppableItem.id).isOpen}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id={'category-' + droppableItem.id.toString()}
                                                >
                                                    <div
                                                        className={this.getDraggableItemClasses(droppableItem)}
                                                        tabIndex={droppableItem.id}
                                                        style={{
                                                            position: 'relative',
                                                            backgroundColor: '#fff',
                                                            width: '100%',
                                                        }}
                                                        onMouseEnter={this.focusOnHover.bind(this)}
                                                        onMouseLeave={this.focusOutHover.bind(this)}
                                                        onClick={event => {
                                                            if (editItem && (editItem.product || editItem.unsorted || editItem.category)) {

                                                                if (editItem.editAction && editItem.category) {

                                                                    if (editItem.category.id !== droppableItem.id) {
                                                                        editItem.editAction(event, false, 'clean');
                                                                    }

                                                                } else {

                                                                    editItem.editAction(event, false, 'clean');
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <div
                                                            className={(this.getItem(droppableItem.id) && this.getItem(droppableItem.id).isOpen) ? 'list-draggable__item MuiListItem-root unsorted MuiListItem-gutters open' : 'list-draggable__item MuiListItem-root unsorted MuiListItem-gutters'}
                                                            onClick={() => {
                                                                this.handleClick(droppableItem.id, this.getItem(droppableItem.id) ? this.getItem(droppableItem.id).isOpen : undefined);
                                                            }}

                                                        >
                                                            <DropdownArrow />
                                                            {this.props.option.colorField ?
                                                                <div className="color-circle" style={{
                                                                    backgroundColor: droppableItem.color,
                                                                }
                                                                }
                                                                />
                                                                : null}
                                                            {editItem && editItem.category && editItem.category.id === droppableItem.id ?
                                                                <Input
                                                                    autoFocus={Boolean(editItem.category.id)}
                                                                    disableUnderline
                                                                    value={editItem.category.name}
                                                                    className={'edit-category ' + (this.props.option.errorStatus? 'error-field': '')}
                                                                    inputProps={{
                                                                        'maxLength': 30,
                                                                    }}
                                                                    onChange={event => {
                                                                        if (editItem.editAction) {
                                                                            editItem.editAction(event, false, 'category');
                                                                        }
                                                                    }}

                                                                    onKeyPress={(event) => {
                                                                        if (event.key === 'Enter' && editItem.editAction) {
                                                                            editItem.editAction(event, true, 'category');
                                                                        }
                                                                    }}
                                                                />
                                                                :
                                                                droppableItem.name
                                                            }
                                                            <span className="category-id">id:{droppableItem.id}</span>
                                                        </div>
                                                        <div className="wrap-menu-edit"
                                                            onChange={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                this.unActiveDroppable(droppableItem.id);
                                                            }}
                                                            onMouseLeave={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                this.unActiveDroppable(droppableItem.id);
                                                            }}
                                                             onClick={(event) => {
                                                                 event.preventDefault();
                                                                 event.stopPropagation();
                                                                 this.activeDroppable(droppableItem.id);
                                                             }}
                                                        >
                                                            {this.props.option.menu ?
                                                                <EditMenu
                                                                    menuList={this.props.option.menu}
                                                                    dataItem={droppableItem}
                                                                    nodeName={'category'}
                                                                />
                                                                : null}
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ width: '100%' }}>

                                                    <div
                                                        className={'wrapper-draged-element'}
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            minHeight: 1,
                                                        }}
                                                    >
                                                        {this.getItem(droppableItem.id)  ? this.getItem(droppableItem.id)[table].filter((value: any)=> !value.deletedAt).map((draggableValue: any, index2: number) =>

                                                            (<Draggable
                                                                index={index2}
                                                                key={draggableValue.id}
                                                                draggableId={`${draggableValue.id}`}
                                                            >
                                                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                                    <div
                                                                        className={this.state.editD.indexOf(draggableValue.id) < 0 ? 'list-draggable__item-wrap' : 'list-draggable__item-wrap active'}
                                                                        style={{
                                                                            position: 'relative',
                                                                        }}
                                                                        onClick={event => {

                                                                            if (editItem && (editItem.product || editItem.unsorted || editItem.category)) {

                                                                                if (editItem.editAction && editItem.product) {

                                                                                    if (editItem.product.id !== draggableValue.id) {
                                                                                        editItem.editAction(event, false, 'clean');
                                                                                    }

                                                                                } else {

                                                                                    editItem.editAction(event, false, 'clean');
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <ListItem
                                                                            className={'list-draggable__item-include'}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={this.getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style,
                                                                            )}
                                                                        >
                                                                            {!this.props.option.colorField ?
                                                                                <ProductView /> :
                                                                                <div className="empty-space" />
                                                                            }
                                                                            
                                                                            {leftSideColorIcon && draggableValue?.color &&
                                                                                <div className="color-circle" 
                                                                                    style={{
                                                                                        backgroundColor: draggableValue?.color,
                                                                                        border: `2px solid ${draggableValue?.frameColor || 'transparent'}`,
                                                                                    }}
                                                                                />
                                                                            }
                                                                            <span style={{
                                                                                margin: 0,
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                width: '100%',
                                                                            }}
                                                                            >
                                                                                {editItem && editItem.product && editItem.product.id === draggableValue.id ?
                                                                                    <Input
                                                                                        autoFocus={Boolean(editItem.product.id)}
                                                                                        disableUnderline
                                                                                        value={editItem.product.name}
                                                                                        className={'edit-product '  + (this.props.option.errorStatus? 'error-field': '')}
                                                                                        inputProps={{
                                                                                            'maxLength': 30,
                                                                                            'minLength': 1,
                                                                                        }}
                                                                                        onChange={event => {
                                                                                            if (editItem.editAction) {
                                                                                                editItem.editAction(event, false, 'product');
                                                                                            }
                                                                                        }}
                                                                                        onKeyPress={(event) => {
                                                                                            if (event.key === 'Enter' && editItem.editAction) {
                                                                                                editItem.editAction(event, true, 'product');
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    :
                                                                                    draggableValue.name
                                                                                }
                                                                            </span>
                                                                            {rightSideContext && draggableValue?.sensorCauseCode !== null && 
                                                                                <TooltipClickCopy
                                                                                     title={t('COPIED') || 'Copied'}
                                                                                     leaveDelay={500}
                                                                                >
                                                                                    <span className="right-side-context">id:{draggableValue.sensorCauseCode}</span>
                                                                                </TooltipClickCopy> 
                                                                            }
                                                                        </ListItem>
                                                                        <div className="wrap-menu-edit"
                                                                             onChange={(event) => {
                                                                                 event.preventDefault();
                                                                                 event.stopPropagation();
                                                                                 this.unActiveDraggable(droppableItem.id, 'draggable');
                                                                                }}

                                                                             onMouseLeave={(event) => {
                                                                                 event.preventDefault();
                                                                                 event.stopPropagation();
                                                                                 this.unActiveDraggable(droppableItem.id, 'draggable');
                                                                                }}
                                                                             onClick={(event) => {
                                                                                 event.preventDefault();
                                                                                 event.stopPropagation();
                                                                                 this.activeDraggable(draggableValue.id, 'draggable');
                                                                             }
                                                                             }
                                                                        >
                                                                            {this.props.option.menu ?
                                                                                <EditMenu
                                                                                    menuList={this.props.option.menu}
                                                                                    dataItem={draggableValue}
                                                                                    nodeName={'list'}
                                                                                />
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                             </Draggable>),
                                                        ) : null}

                                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                                        {this.state.placeholderProps.id === droppableItem.id.toString() && (
                                                            <div
                                                                className="placeholder-props"
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: this.state.placeholderProps.clientY + 40,
                                                                    left: this.state.placeholderProps.clientX,
                                                                    height: this.state.placeholderProps.clientHeight,
                                                                    width: this.state.placeholderProps.clientWidth,
                                                                }}
                                                            >
                                                                {provided.placeholder}
                                                            </div>
                                                        )}

                                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                                    </div>


                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    )}
                                </Droppable>


                             </div>),
                        )}
                        {this.props.addCategory &&
                        <div
                            className={'list-draggable__item-wrap wrap-add-category'}
                            style={{
                                position: 'relative',
                                backgroundColor: '#fff',
                                width: '100%',
                            }}
                        >

                            <div
                                className={'list-draggable__item MuiListItem-root MuiListItem-gutters'}

                            >
                                <DropdownArrow />
                                <Input
                                    autoFocus={this.props.addCategory}
                                    disableUnderline
                                    className={'add-category '  + (this.props.option.errorStatus? 'error-field': '')}
                                    inputProps={{
                                        'maxLength': 30,
                                    }}
                                    onChange={(event) => {
                                        if (this.props.option.addCategoryEvent)
                                            this.props.option.addCategoryEvent.inputHandle(event, this.state.droppable);
                                    }}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter' && this.props.option.addCategoryEvent) {
                                            this.props.option.addCategoryEvent.confirmCreate(event);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        }
                        <Droppable droppableId={'unsorted'} key={'unsorted'}>
                            {(provided: DroppableProvided) => (
                                <React.Fragment>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={'list-draggable'}
                                        style={{ position: 'relative' }}
                                    >
                                        <div className={'wrapper-draged-element'}>
                                            {this.state.draggableUnsorted && this.state.draggableUnsorted.length > 0 ? this.state.draggableUnsorted.map((unsortedItem: Item, unsortedItemIndex: number) =>
                                                (<Draggable
                                                    index={unsortedItemIndex}
                                                    key={unsortedItem.id}
                                                    draggableId={'drag-' + unsortedItem.id}
                                                >
                                                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                        <div
                                                            className={this.state.editDU.indexOf(unsortedItem.id!) < 0 ? 'list-draggable__item-wrap' : 'list-draggable__item-wrap active'}
                                                            style={{ position: 'relative' }}
                                                            onClick={event => {
                                                                if (editItem && (editItem.product || editItem.unsorted || editItem.category)) {

                                                                    if (editItem.editAction && editItem.unsorted) {

                                                                        if (editItem.unsorted.id !== unsortedItem.id) {

                                                                            editItem.editAction(event, false, 'clean');
                                                                        }

                                                                    } else {

                                                                        editItem.editAction(event, false, 'clean');
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <ListItem
                                                                className={'list-draggable__item-include unsorted'}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={this.getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style,
                                                                )}
                                                            >
                                                                <ProductView />
                                                                {leftSideColorIcon && unsortedItem?.color &&
                                                                    <div className="color-circle" 
                                                                        style={{
                                                                            backgroundColor: unsortedItem?.color,
                                                                            border: `2px solid ${unsortedItem?.frameColor || 'transparent'}`,
                                                                        }}
                                                                    />
                                                                }
                                                                <span style={{
                                                                    margin: 0,
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    width: '100%',
                                                                }}
                                                                >
                                                                    {editItem && editItem.unsorted && editItem.unsorted.id === unsortedItem.id ?
                                                                        <Input
                                                                            autoFocus={Boolean(editItem.unsorted.id)}
                                                                            disableUnderline
                                                                            value={editItem.unsorted.name}
                                                                            className={'edit-unsorted '  + (this.props.option.errorStatus? 'error-field': '')}
                                                                            inputProps={{
                                                                                'maxLength': 30,
                                                                            }}
                                                                            onKeyPress={(event) => {
                                                                                if (event.key === 'Enter' && editItem.editAction) {
                                                                                    editItem.editAction(event, true, 'unsorted');
                                                                                }
                                                                            }}
                                                                            onChange={event => {
                                                                                if (editItem.editAction) {
                                                                                    editItem.editAction(event, false, 'unsorted');
                                                                                }
                                                                            }}
                                                                        />
                                                                        :
                                                                        unsortedItem.name
                                                                    }
                                                                </span>
                                                                {rightSideContext && unsortedItem?.sensorCauseCode !== null && 
                                                                    <TooltipClickCopy
                                                                            title={t('COPIED') || 'Copied'}
                                                                            leaveDelay={500}
                                                                    >
                                                                        <span 
                                                                            className="right-side-context"
                                                                        >
                                                                            {unsortedItem.sensorCauseCode}
                                                                        </span>
                                                                    </TooltipClickCopy> 
                                                                }
                                                            </ListItem>
                                                            <div className="wrap-menu-edit"

                                                                 onChange={() => this.unActiveDraggable(unsortedItem.id!, 'unsorted')}
                                                                 onMouseLeave={() => this.unActiveDraggable(unsortedItem.id!, 'unsorted')}
                                                                 onClick={() => this.activeDraggable(unsortedItem.id!, 'unsorted')}
                                                            >
                                                                {this.props.option.menu ?
                                                                    <EditMenu
                                                                        menuList={this.props.option.menu}
                                                                        dataItem={unsortedItem}
                                                                        nodeName={'unsorted'}
                                                                    />
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    )}
                                                 </Draggable>),
                                            ) : null}
                                        </div>

                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                        {provided.placeholder}

                                        {this.state.placeholderProps.id === 'unsorted' && (
                                            <div
                                                className={'placeholder-props'}
                                                style={{
                                                    position: 'absolute',
                                                    top: this.state.placeholderProps.clientY,
                                                    left: this.state.placeholderProps.clientX,
                                                    height: this.state.placeholderProps.clientHeight,
                                                    width: this.state.placeholderProps.clientWidth,
                                                }}
                                            />
                                        )}

                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                        {this.props.addInput ?
                                            <div style={{ position: 'relative' }}>
                                                <ListItem
                                                    className={'list-draggable__item-include unsorted'}
                                                    style={{ backgroundColor: '#fff' }}
                                                >
                                                    <ProductView />
                                                    <Input
                                                        autoFocus={this.props.addInput}
                                                        disableUnderline
                                                        className={'add-item ' + (this.props.option.errorStatus? 'error-field': '')}
                                                        inputProps={{
                                                            'maxLength': 30,
                                                        }}
                                                        onChange={(event) => {
                                                            if (this.props.option.addEvent)
                                                                this.props.option.addEvent.inputHandle(event, this.state.draggableUnsorted);
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (event.key === 'Enter' && this.props.option.addEvent) {
                                                                this.props.option.addEvent.confirmCreate(event);
                                                            }
                                                        }}
                                                    />
                                                </ListItem>
                                            </div>
                                            : null
                                        }

                                    </div>
                                </React.Fragment>

                            )}
                        </Droppable>
                    </React.Fragment>
                </DragDropContext>
                {this.state.confirm?
                    <ConfirmDialog
                        heading={confirmHeading}
                        onAccept={this.onConfirmDialog.bind(this)}
                        onClose={this.handleCancel.bind(this)}
                        open={this.state.confirm}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

export default withTranslation()(DraggableList);