import React, { useCallback, useState } from 'react';
import { INotification } from '../../core/interfaces';
import { Formik, FormikProps } from 'formik';
import { TextareaAutosize } from '@material-ui/core';
import { Button } from '../../core/ui/components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Duration } from '../../core/ui/assets/images/icons/duration.svg';
import moment from 'moment';

interface IProps {
    dataAlert: INotification;
    rbac: any;
    setShowCommentInput: (itemId: number | undefined) => void;
    showCommentInput: boolean;
    updateComment: (data: INotification) => void;
}

const AlertItemForm: React.FC<IProps> = ({ dataAlert, rbac, showCommentInput, setShowCommentInput, updateComment }: IProps) => {

    const [initialValues, setInitialValues] = useState<INotification | undefined>(dataAlert);
    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        comment: yup.string()
            .trim()
            .max(300, t('MAX_COMMENT_COMMENT')),
    });

    /**
     * Handle Submit
     *
     * @type {(value: INotification) => void}
     */
    const handleSubmit = useCallback((value: INotification) => {

        if (value.comment) {

            value.comment = value.comment?.trim();
        }

        updateComment(value);

        setInitialValues(value);

        setShowCommentInput(undefined);

    }, [updateComment, setShowCommentInput]);

    /**
     * Show Comment Input Callback
     *
     * @type {(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void}
     */
    const showCommentInputCallback = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {

        event.preventDefault();

        setShowCommentInput(dataAlert.id);

    }, [setShowCommentInput, dataAlert]);

    return (
        <>
            {initialValues?.comment && !showCommentInput ?
                <div className="alert-item--comment">{initialValues.comment}</div> : null}
            {showCommentInput && initialValues ? <div className="alert-item--add-comment">
                <Formik
                    enableReinitialize={false}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props:FormikProps<INotification>) => (
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                if (!props.isSubmitting) {
                                    props.handleSubmit(e);
                                }
                            }}
                              noValidate
                        >
                            <TextareaAutosize
                                className={
                                    'form-field field-comment '
                                    +
                                    (props.touched.comment ? (props.errors.comment) ? 'error-field' : 'success-field' : '')
                                }
                                autoFocus
                                onFocus={event => {
                                    const text = props.values.comment ? props.values.comment : '';
                                    event.currentTarget.setSelectionRange(text.length, text.length);
                                }}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                onMouseLeave={props.handleBlur}
                                onDrop={event => event.preventDefault()}
                                value={props.values.comment ? props.values.comment : ''}
                                aria-label="minimum height"
                                placeholder={t('COMMENT')}
                                name="comment"
                            />
                            {((props.touched.comment && props.errors.comment)) &&
                            <div className="validation-massage">{props.errors.comment}</div>
                            }
                            <div className="submit-group">
                                <Button
                                    type="reset"
                                    size={'small'}
                                    color={'primary'}
                                    onClick={() => {
                                        setShowCommentInput(undefined);
                                    }}
                                >{t('CANCEL')}
                                </Button>
                                <Button
                                    type="submit"
                                    size={'small'}
                                    color={'secondary'}
                                    onClick={event => {
                                        props.handleSubmit();
                                    }}
                                >
                                    {t(initialValues.comment && initialValues.comment?.length > 0 ? 'SAVE_CHANGES' : 'SAVE')}
                                </Button>
                            </div>
                        </form>)
                    }
                </Formik>
                                                 </div> : null}

            <div className="alert-item--duration">
                <div className="text">
                    <Duration />
                    {moment(dataAlert.startTime).format('D MMM')},&nbsp;
                    {moment(dataAlert.startTime).format('HH:mm')}
                    –
                    {moment(dataAlert.endTime || new Date()).isBefore(moment(dataAlert.startTime).add({ 'd': 1 })) ? ''
                        : `${moment(dataAlert.endTime || new Date()).format('D MMM')}, `}
                    {moment(dataAlert.endTime || new Date()).format('HH:mm')}
                </div>
                {rbac && rbac.can('alert:add-comment') && (!initialValues?.comment ?
                        <span
                            className={'add-comment-btn'}
                            hidden={showCommentInput}
                            onClick={showCommentInputCallback}
                        >
                            {t('ADD_COMMENT')}
                        </span>
                        :
                        <span
                            className={'add-comment-btn'}
                            hidden={showCommentInput}
                            onClick={showCommentInputCallback}
                        >
                            {t('EDIT_COMMENT')}
                        </span>
                )}
            </div>
        </>
    );
};

export default React.memo(AlertItemForm);
