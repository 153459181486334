export const sensorConstants = {
    LIST_SUCCESS: 'SENSOR_LIST_SUCCESS',
    LIST_FAILURE: 'SENSOR_LIST_FAILURE',
    LIST_UNSORTED_SUCCESS: 'SENSOR_LIST_UNSORTED_SUCCESS',
    LIST_UNSORTED_FAILURE: 'SENSOR_LIST_UNSORTED_FAILURE',

    // EDIT SENSOR ITEM
    EDIT_OPEN_RIGHT_SIDEBAR: 'SENSOR_EDIT_OPEN_RIGHT_SIDEBAR',
    EDIT_CLOSE_RIGHT_SIDEBAR: 'SENSOR_EDIT_CLOSE_RIGHT_SIDEBAR',
    EDIT_SUCCESS: 'SENSOR_EDIT_SUCCESS',
    EDIT_FAILURE: 'SENSOR_EDIT_FAILURE',

    UPDATE_SENSOR_PREFERENCE_SUCCESS: 'UPDATE_SENSOR_PREFERENCE_SUCCESS',
    UPDATE_SENSOR_PREFERENCE_FAILURE: 'UPDATE_SENSOR_PREFERENCE_FAILURE',

};
