import React, { useCallback, useEffect } from 'react';
import { makeStyles, Snackbar, SnackbarContent, SnackbarOrigin } from '@material-ui/core';
import { connect } from 'react-redux';
import './LoadingNotification.scss';
import { ExportDataActions } from '../../core/actions';
import { IError, IExportDataState } from '../../core/interfaces';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';

export interface INotificationState extends SnackbarOrigin {
    open: boolean;
}

interface INotificationProps {
    loading?: boolean,
    closeLoadingNotification: () => void;
    errors?: IError;
}

const useStyles = makeStyles(() => ({
    root: {
        background: 'transparent',
    },
}));

const LoadingNotification: React.FC<INotificationProps & WithTranslation> = ({ loading, closeLoadingNotification, errors }: INotificationProps & WithTranslation) => {

    const classes = useStyles();

    const { t } = useTranslation();

    const [state, setState] = React.useState<INotificationState>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });

    const { vertical, horizontal, open } = state;

    /**
     * Close handler
     */
    const handleClose = useCallback(() => {

        setState({ ...state, open: false });

        closeLoadingNotification();

    }, [setState, state, closeLoadingNotification]);

    useEffect(() => {

        if (loading && loading !== state.open) {

            setState({ ...state, open: loading });
        }

    }, [loading, setState]);

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            autoHideDuration={6000}
            key={vertical + horizontal}
            style={{ background: 'transparent' }}
            className="loading-notification"
        >
            <SnackbarContent
                message={!errors?.common ? t('FILE_DOWNLOAD') : t('DOWNLOAD_ERROR', { error: t(errors.common) })}
                classes={classes}
                role={!errors?.common ? 'alertdialog' : 'alert'}
            />
        </Snackbar>
    );
};


/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: { exportData: IExportDataState }) => {

    const { loading, errors } = state.exportData;

    return {
        loading,
        errors,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    closeLoadingNotification: ExportDataActions.closeLoadingNotification,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoadingNotification));
