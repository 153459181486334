import React, { useEffect, useState } from 'react';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import './DurationInput.scss';

/**
 * add format duration function
 */
momentDurationFormatSetup.bind(moment());

/**
 * component DurationInput
 *
 * @param {Date} endTime
 * @param {Date} startTime
 * @param {string } label
 * @param {()=>void} newTime
 * @param {string} durationLimitTextError
 * @param {()=>void} onError
 *
 */
const DurationInput: React.FC<IProps> = ({
                                             endTime,
                                             startTime,
                                             label,
                                             newTime,
                                             onError,
                                             disabled = false,
                                         }: IProps) => {

    const [duration, setDuration] = useState({
        data: '000000',
        firstBlock: 2,
    });

    const [error, setError] = useState(false);


    useEffect(() => {

        const ms = moment(endTime).diff(moment(startTime)),
            s = moment.duration(ms, 'milliseconds').format('hh:mm:ss', {
                trim: false,
            });

        if (s.length <= 8) {

            setDuration({ data: s.toString(), firstBlock: 2 });



        } else {

            const firstBlock = s.length - 6;

            setDuration({ data: s.toString(), firstBlock: firstBlock });
        }
        // eslint-disable-next-line
    }, [endTime, startTime, error]);

    /**
     * Change handler
     *
     * @param {React.ChangeEvent<HTMLInputElement>} duration
     */
    const onChange = (duration: React.ChangeEvent<HTMLInputElement>) => {

        const value: string = duration.target.value,
            newDate = moment(startTime).add({ milliseconds: moment.duration(value).asMilliseconds() }).toDate();


        if (value.length >= 8 && newDate <= moment().toDate()) {

            setTimeout(() => {

                newTime(newDate);
            }, 200);

            setError(false);

            onError(false);
        } else {

            setError(true);

            onError(true);
        }
    };

    return (
        <React.Fragment>
            <div className="form-field duration ">
                <label
                    className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated"
                >{label}
                </label>
                <div
                    className={'MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth' + (error ? ' error' : '')}
                >
                    <div
                        className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense"
                    >
                        {duration.firstBlock === 2 &&
                        <Cleave
                            options={{
                                numericOnly: true,
                                blocks: [2, 2, 2],
                                delimiters: [':'],
                            }}
                            type="tel"
                            className={'MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense'}
                            value={duration.data}
                            onChange={event => {
                                onChange(event);
                            }}
                            onBlur={event => {
                                onChange(event);
                            }}
                            onFocus={event => {
                                onChange(event);
                            }}
                            disabled={disabled}
                        />
                        }
                        {duration.firstBlock === 3 && <Cleave
                            options={{
                                numericOnly: true,
                                blocks: [3, 2, 2],
                                delimiters: [':'],

                            }}
                            type="tel"
                            className={'MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense'}
                            value={duration.data}
                            onChange={event => {
                                onChange(event);
                            }}
                            onBlur={event => {
                                onChange(event);
                            }}
                            onFocus={event => {
                                onChange(event);
                            }}
                            disabled={disabled}
                                                      />}
                        {duration.firstBlock === 4 && <Cleave
                            options={{
                                numericOnly: true,
                                blocks: [4, 2, 2],
                                delimiters: [':'],
                            }}
                            type="tel"
                            className={'MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense'}
                            value={duration.data}
                            onChange={event => {
                                onChange(event);
                            }}
                            onBlur={event => {
                                onChange(event);
                            }}
                            onFocus={event => {
                                onChange(event);
                            }}
                            disabled={disabled}
                                                      />}
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default DurationInput;

interface IProps {
    startTime: Date;
    endTime: Date;
    label?: string;
    newTime: (date: Date) => void;
    onError: (event: boolean) => void;
    disabled?: boolean;
    downTimeRestriction?: boolean;
    downTimeErrorHandler?: (event: boolean) => void;
}