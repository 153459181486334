import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import StructuralTreeTitle from '../Items/StructuralTreeTitle';
import { ReactComponent as ProcessIcon } from '../../../../core/ui/assets/images/icons/folder-process.svg';
import Unit from './Unit';
import { IEditStructuralTreeProcessProps, IFlatTreeProcess } from '../../../../core/interfaces';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectFlatMonitoringTreeItemById } from '../../../../core/selectors/monitoringTree/monitoringTreeSelector';
import { useSelector } from 'react-redux';
import {
    selectVisibleSideBar,
} from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';


/**
 * Edit structural tree process node
 *
 * @param process
 * @param factory
 * @param searchField
 * @param placeholderProps
 * @param onDragEnd
 * @param getOpenStatus
 * @param onChange
 * @param onMouseDown
 * @param handleClick
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Process: React.FC<IEditStructuralTreeProcessProps> = (
    {
    processID,
    factory,
    searchField,
    placeholderProps,
    onDragEnd,
    // onDragUpdate,
    getOpenStatus,
    onChange,
    onMouseDown,
    handleClick,
    collapse,
}: IEditStructuralTreeProcessProps,
    ) => {

    const process = useParamSelector(selectFlatMonitoringTreeItemById, processID, 'process') as IFlatTreeProcess,
        visibleSideBar = useSelector(selectVisibleSideBar);

    if (!process) {

        return null;
    }

    return (
        <DragDropContext
            onDragEnd={onDragEnd}
            // onDragUpdate={onDragUpdate}
        >
            <div className="factory-item">
                <Accordion
                    className="expansion-panel"
                    defaultExpanded={!process.isMinimized}
                    expanded={!(collapse['process'].indexOf(process.id) > -1)}
                    onChange={onChange}
                >
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id={'process' + process.id.toString()}
                    >
                        <StructuralTreeTitle
                            rightSectionClass={visibleSideBar ? 'left-padding-2 limit-padding hidden' : 'left-padding-2'}
                            checkboxDisabled
                            node={process}
                            getOpenStatus={getOpenStatus}
                            handleClick={handleClick}
                            icon={<ProcessIcon className="default-icon-size" />}
                            checkedIcon={<ProcessIcon className="default-icon-size" />}
                            checkBoxValue={'process'}
                            processSelectActionParam={{
                                factoryId: factory.id,
                                processId: process.id,
                                processShow_kpi: false,
                                processShow_state: false,
                            }}
                        />
                    </AccordionSummary>
                    <AccordionDetails className="process-details">
                        {process.data ? process.data.map((unit) => (
                            <Unit
                                key={'unit-list-item-' + unit}
                                unitID={unit}
                                process={process}
                                factory={factory}
                                searchField={searchField}
                                placeholderProps={placeholderProps}
                                onChange={onChange}
                                onMouseDown={onMouseDown}
                                handleClick={handleClick}
                            />
                        )) : null}
                    </AccordionDetails>
                </Accordion>
            </div>
        </DragDropContext>
    );
};


export default React.memo(Process);
