import { processConstants } from '../../constants';
import { IProcessListAction, IProcessListState } from '../../interfaces';

/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function process(state: IProcessListState = {
    processes: [],
    errors: {},
}, action: IProcessListAction): IProcessListState {

    switch (action.type) {

        case processConstants.LIST_SUCCESS:
            return {
                processes: action.processes,
                errors: {},
            };

        case processConstants.LIST_FAILURE:

            return {
                processes: [],
                errors: action.errors,
            };

        default:

            return state;
    }

}
