import React, { CSSProperties } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import  './Message.scss';


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            maxWidth: 600,
        },
    }),
);

export default function LongTextSnackbar({ message, hidden, handleClick, style }: IProps) {
    const classes = useStyles();

    return (
        <div className={hidden ? classes.root + ' visible message' : classes.root + ' message '} style={style}>
            <SnackbarContent message={message} action={
                <Button className={'message-action'}
                        color="secondary"
                        size="small"
                        onClick={handleClick}
                        style={{
                            color: 'transparent',
                            pointerEvents: 'none',
                        }}
                >
                    X
                </Button>
            }
            />
        </div>
    );
}

interface IProps {
    message: string,
    hidden: boolean,
    handleClick?: ()=>void,
    style?: CSSProperties
}
