import { graphConstants } from '../../constants';
import { IGraphHMIVisibilityState } from '../../interfaces';

const initialState = {
    visible: false,
};

export function graphHMIVisibility(state: IGraphHMIVisibilityState = initialState, action: { type: string }): IGraphHMIVisibilityState {

    switch (action.type) {

        case graphConstants.hmiShow:

            return {
                visible: true,
            };

        case graphConstants.hmiHide:

            return {
                visible: false,
            };

        default:

            return state;
    }
}
