import { createStore, applyMiddleware, compose  } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from '../reducers';
import { actionToObjectMiddleware } from '../../helpers/actionToObjectMiddleware';

/**
 * dev tools
 */

let composeEnhancers;
if (
    process.env.NODE_ENV !== 'production' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
    composeEnhancers = compose();
}

const enhancers = composeEnhancers(
    applyMiddleware(
        thunk,
        actionToObjectMiddleware,
    ),
);

export type RootState = ReturnType<typeof rootReducer>;

/**
 * Create redux store
 */
const store = createStore(rootReducer, undefined, enhancers);

export default store;
