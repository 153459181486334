import React, { Component } from 'react';
import './styles/ProductsVerticalLineGraph.scss';
import { IActiveProducts, IUnit } from '../../../interfaces';
import moment from 'moment';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { ScaleTime } from 'd3';
import { RootState } from '../../../store';
import { selectScreenWidth } from '../../../selectors/dashboard/dashboardSelector';
import { calcRealTimeIndentation } from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import {
    selectDrawerIsResize,
    selectDrawerWidthWithDrawRules, selectPositionDrawer,
} from '../../../selectors/layout/responsiveDrawerSelector';
import { isMobileOnly } from 'react-device-detect';
import { selectHmiPlayerSchema } from '../../../selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../../selectors/hmi/visibilitySelector';
import { getActiveProduct } from '../../../selectors/unitActiveProducts/activeProductDataSelector';


interface IProps {
    unitId: string | number,
    products?: IUnit[];
    activeProductData?: IActiveProducts[];
    selection?: Date[];
    screenWidth?: number;
    dashboardOnline?: boolean;
}


/**
 * Products Vertical Line for Unit
 * ProductsVerticalLineGraph
 * @param {string} product - current product
 * @param {Object[]} products -
 * @constructor
 * @return {JSX.Element}
 */
class ProductsVerticalLineGraph extends Component<IProps, Record<string, unknown>> {

    constructor(props: IProps) {

        super(props);

        this.scale = d3.scaleTime();

    }

    /**
     * Callback after the component props update
     */
    componentDidUpdate() {

        this.updateProductLine();

    }

    private scale: ScaleTime<number, number>;

    /**
     * Product Line Update Features
     */
    updateProductLine() {
        if (this.props.screenWidth && this.props.selection) {
            const { selection, dashboardOnline } = this.props;
            const newSelection = selection;

            if (dashboardOnline && newSelection[0] > new Date()) {

                this.scale = d3.scaleTime()
                    .range([0, this.props.screenWidth + 40])
                    .domain(newSelection);

            } else {

                this.scale = d3.scaleTime()
                    .range([0, this.props.screenWidth])
                    .domain(newSelection);

            }
        }
    }

    // Uncomment when implementing "next product" in future releases
    // /**
    //  * check last product on line
    //  *
    //  * @param { number } position
    //  * @param { string } product
    //  */
    // nextProduct(position: number, product?: string) {
    //     const { screenWidth } = this.props;
    //     if (product && screenWidth && screenWidth - position < product.length * 6) {
    //         return -(product.length * 6 + 34);
    //     } else {
    //         return 0;
    //     }
    // }

    getPositionElement(value: { endTime: Date, startTime: Date, id: number, product: { id: number, name: string } }, selection: Date[]) {

        const endTimeSelection = moment(value.endTime || selection[1]).isAfter(selection[1]) ? selection[1] : value.endTime || selection[1],
            startTimeSelection = moment(value.startTime).isBefore(selection[0]) ? selection[0] : value.startTime;

        return {
            left: this.scale(new Date(startTimeSelection)),
            width: this.scale(new Date(endTimeSelection)) as number - (this.scale(new Date(startTimeSelection)) as number),
        };

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        this.updateProductLine();

        const { selection, activeProductData } = this.props;

        return (
            <div className={'product-line-wrap'}>
                {activeProductData && selection && activeProductData.map((value, index, array) => {

                        const [from, to] = selection;

                        if (value.product === null || (value.endTime && (new Date(value.endTime) < new Date(from) || new Date(value.startTime) > new Date(to)))) {

                            return null;

                        }
                        const itemPosition = this.getPositionElement(value, selection);

                        return (
                            <React.Fragment key={index}>
                                {(array.length > 1 || value.product) &&
                                    <div
                                        className={`product-section ${index + 1 === array.length ? 'last-child' : ''} ${value.product ? '' : 'empty'}`}
                                        // Uncomment when implementing "next product" in future releases
                                        // className={`product-section ${index + 1 === array.length ? 'last-child' : ''} ${this.nextProduct(this.scale(moment(value.startTime)), value.product?.name) !== 0 ? 'next' : ''} ${value.product ? '' : 'empty'}`}
                                        key={index}
                                        style={{
                                            left: itemPosition.left,
                                            width: itemPosition.width ? itemPosition.width > 0 ? itemPosition.width : 0 : 'auto',
                                        }}
                                    >
                                        {(itemPosition.left as number) < 0 ? null :
                                            <div className="vertical-line" />}
                                        <div
                                            // Uncomment when implementing "next product" in future releases
                                            // style={{ left: this.nextProduct(this.scale(moment(value.startTime)), value.product?.name) }}
                                            className="product-name"
                                        >{value.product ?
                                            // Uncomment when implementing "next product" in future releases
                                            // <React.Fragment><span>{value.product?.name}→</span>{this.nextProduct(this.scale(moment(value.startTime)), value.product?.name) !== 0 ?'':<span>←{value.product?.name}</span>}</React.Fragment> : ''}
                                            <React.Fragment><span>{value.product?.name}→</span><span>←{value.product?.name}</span></React.Fragment> : ''}
                                        </div>
                                    </div>}
                            </React.Fragment>
                        );
                    },
                )}
            </div>
        );
    }

}

let prevDrawWidth = 500;

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState, ownProps: any) => {
    const { unitId } = ownProps,
        { graphMinimapBrush, dashboard } = state;

    const activeProductData = getActiveProduct(state);
    const { selection } = graphMinimapBrush;
    const currentProductsOnLine = activeProductData.get(unitId);
    const drawWidth = selectDrawerWidthWithDrawRules(state);
    const schema = selectHmiPlayerSchema(state);
    const isVisible = selectHmiPlayerVisibility(state) && schema !== null,
        anchor: 'right' | 'bottom' = selectPositionDrawer(state) as 'right' | 'bottom';
    const isResize = selectDrawerIsResize(state);
    const realTimeIndentation = calcRealTimeIndentation(state);

    if (!isResize) {

        prevDrawWidth = drawWidth;
    }

    const screenWidthOrigin = selectScreenWidth(state) - realTimeIndentation - (!isMobileOnly && isVisible && anchor === 'right' ? isResize ? prevDrawWidth : drawWidth : 0);
    return {
        selection,
        screenWidth: screenWidthOrigin,
        dashboardOnline: dashboard.online,
        activeProductData: currentProductsOnLine ? currentProductsOnLine : [],
    };
};

export default connect(mapStateToProps, null)(ProductsVerticalLineGraph);

