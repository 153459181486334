import React, { useCallback, useMemo } from 'react';
import { ISensor } from '../../../core/interfaces';
import { Autocomplete, FilterOptionsState } from '@material-ui/lab';
import { Chip, makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StatesIcon } from '../../../core/ui/assets/images/icons/state_icon.svg';
import Grid from '@material-ui/core/Grid';

export interface IAOptions {
    value: number,
    label: string,
    type: string
}

const useStyles = makeStyles(() => ({
    root: {
        height: 'auto',
        maxWidth: 800,
        '& > * + *': {
            marginTop: 0,
        },
    },
    inputRoot: {
        height: 'auto',
    },
    tag: {
        zIndex: 2,
    },
}));

const useStylesChip = makeStyles(() => ({
    root: {
        padding: '7px 1px 7px 16px',
        background: '#E6EAED',
        borderColor: '#E6EAED',
    },
    label: {
        paddingLeft: 12,
        color: '#333333',
        fontSize: 14,
        lineHeight: '18px',
        letterSpacing: 0,

    },
}));

interface IProps {
    sensors: ISensor[];
    stateSensors: IAOptions[];
    validatedClass: (name: string) => string;
    onChange: (value: any) => void; 
    errorMessage: string;
}

const AutoCompleteState = ({
    sensors,
    stateSensors,
    errorMessage,
    validatedClass,
    onChange,
}: IProps) => {

    const classes = useStyles();
    const classesChip = useStylesChip();
    const { t } = useTranslation();

     /**
     * Render input for autocomplete field
     *
     * @type {(params: Object) => React.ReactNode}
     */
      const renderInputAutocomplete = useCallback((params: Object): React.ReactNode => {

        return (
            <>
                <TextField
                    {...params}
                    variant="outlined"
                    // placeholder={t(rule.stateSensors.length ? '' : 'SELECT')}
                    placeholder={t('SELECT')}
                    required
                />
            </>
        );
    }, [t]);

    const handleRenderTags = useCallback((value: IAOptions[], getTagProps) => {

        return value.map((option: IAOptions, index: number) => (
            <Chip
                classes={classesChip}
                avatar={<StatesIcon />}
                size={'small'}
                key={option.value}
                variant="outlined"
                label={option.label} {...getTagProps({ index })}
            />
        ));
    }, [classesChip]);



     /**
     * Get option label
     *
     * @type {(option: IHMIOptions) => string}
     */
    const getOptionLabel = useCallback((option: IAOptions): string => {

        return option.label;
    }, []);

    /**
     * Convert item to select options
     *
     * @param item
     * @return {IOptions}
     */
    const itemToOption = useCallback((item: ISensor): IAOptions => {

        const itemType = item.sensorType === 'graph' ? 'Sensor' : 'State';

        return { value: item.id, label: item.name, type: itemType };
    }, []);

    /**
     *
     */
    const handelFilterOptions = useCallback((options: IAOptions[], state: FilterOptionsState<IAOptions>): IAOptions[] => {

        return options.filter(option => {
            return stateSensors.findIndex(stateSensor => stateSensor.value === option.value) === -1 
                && option.label.toLowerCase().includes(state.inputValue.toLowerCase());
        });

    }, [stateSensors]); 

    const options = useMemo(() => {
        return sensors.filter((value: ISensor) => value.sensorType === 'state')
            .map(itemToOption) || [];
    }, [sensors]);

    return (
        <Grid container spacing={2}>
            <Grid item className="form-fields-inline left">
                {t('FOR_STATES')}
            </Grid>
            <Grid item className="field-status">
                <div
                    className={validatedClass('stateSensors')}
                >
                    <Autocomplete
                        id="tags-outline"
                        multiple
                        renderInput={renderInputAutocomplete}
                        filterSelectedOptions
                        filterOptions={handelFilterOptions}
                        onChange={(e, v) => onChange(v)}
                        classes={classes}
                        options={options}
                        size="small"
                        className={'autocomplete-field form-field'}
                        placeholder={t('SELECT')}
                        value={stateSensors}
                        renderTags={handleRenderTags}
                        getOptionLabel={getOptionLabel}
                        noOptionsText={t('NO_OPTIONS')}
                    />
                    {errorMessage && <div className="validation-massage">{errorMessage}</div>}
                </div>
            </Grid>
        </Grid>
    );
};

export default React.memo(AutoCompleteState);