import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FileUpload, TextInput } from '../../../../../../../core/ui/components';
import { ILayer, ILocation } from '../../../../../interfaces';
import { FormikProps } from 'formik';
import { SvgPreview } from '../../../Location/FormLocationSidebar/SvgPreview/SvgPreview';
import styles from './LayerForm.module.scss';
import clsx from 'clsx';
import { readFile } from '../../../../../../../base/helpers/readFile';
import { useSelector } from 'react-redux';
import { selectLayerFormEditorMode, selectLayerFormPins } from '../../../../../store/selectors/layerFormSelector';
import { isSvgFile } from '../../../../../utils/isSvgFile';
import { YellowMessage } from '../../../../../ui/components/YellowMessage';
import { fabric } from 'fabric';
import { AngleInput } from '../../AngleInput/AngleInput';

export const LayerForm: React.FC<IProps> = (props: IProps) => {
    const { location, layerData, onCancel, formikProps, onUpdateLayer } = props;
    const { t } = useTranslation();

    const pictureContent = layerData?.pictureContent;

    const [svgLayer, setSvgLayer] = useState<string>(pictureContent || '');

    useEffect(() => {
        if (pictureContent) {
            setSvgLayer(pictureContent);
        }
    }, [pictureContent]);

    const onSVGFileChange = useCallback(async(file: File) => {
        if (!isSvgFile(file)) {
            setSvgLayer('');
            formikProps.setFieldValue('picture', '');
            formikProps.setTouched({ picture: true });
            return;
        }

        const svgFileContent = await readFile(file);

        setSvgLayer(svgFileContent);

        formikProps.setFieldValue('picture', file);
    }, [formikProps]);

    const editorMode = useSelector(selectLayerFormEditorMode);
    const pins = useSelector(selectLayerFormPins);

    const onDeleteLayer = useCallback(() => {

        setSvgLayer('');

        formikProps.setFieldValue('picture', '');
    }, [formikProps]);

    const onRotating = useCallback((angle) => {
        formikProps.setFieldValue('angle', angle);
    }, [formikProps]);

    return (
        <form onSubmit={formikProps.handleSubmit} noValidate className={styles.form}>
            <div className="section wrap-form-node">
                <div className="table-header">
                    <div className={'title'}>
                        {layerData ? t('EDIT_LAYER') : t('ADD_LAYER')} <span className={styles.locationTitle}>({location.name})</span>
                    </div>
                </div>
                <div className={clsx(
                    'table-body',
                    styles.tableBody,
                )}
                >
                    <YellowMessage
                        message={t('UPLOAD_THE_LAYER_IT_WILL_BE_UNDER_THE_LOCATION_PICTURE_LAYER_COULD_BE_SWITCHED_TO_UNSEEN')}
                    />
                    <div className={
                        'form-group '
                        + styles.nameRow
                    }
                    >
                        <TextInput
                            className={
                                'form-field '
                                + `${styles.nameInput} `
                                + (formikProps.touched.name ? formikProps.errors.name ? 'error-field' : 'success-field' : '')
                            }
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.name}
                            name="name"
                            type="text"
                            placeholder={t('LAYER_NAME')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            {((formikProps.touched.name && formikProps.errors.name)) &&
                                <div
                                    className="validation-massage"
                                >
                                    {formikProps.errors.name}
                                </div>
                            }
                        </TextInput>
                    </div>

                    {
                        location.pictureContent && (
                            <div className={styles.svgContent}>
                                <SvgPreview
                                    svg={location.pictureContent}
                                    pins={pins}
                                    scale={location?.scale}
                                    layerSvg={svgLayer}
                                    editorMode={editorMode}
                                    onUpdateLayer={onUpdateLayer}
                                    layerData={layerData}
                                    angle={formikProps.values.angle}
                                    onRotating={onRotating}
                                    meterHeight={location.height || 10}
                                    meterWidth={location.width || 10}
                                    planAngle={location.angle || 0}
                                    measure={location.measure}
                                />
                            </div>
                        )
                    }

                    <AngleInput
                        fieldName="angle"
                        handleChange={formikProps.handleChange}
                        handleBlur={formikProps.handleBlur}
                        isTouched={Boolean(formikProps.touched.angle)}
                        errors={formikProps.errors.angle || ''}
                        placeholder={t('LAYER_ANGLE')}
                        value={formikProps.values.angle}
                        setFieldValue={formikProps.setFieldValue}
                    />

                    {
                        !svgLayer &&
                        <div className="form-field">
                            <FileUpload
                                id="select-layer-image"
                                name={t('SELECT_SVG_IMAGE')}
                                onFileUpload={onSVGFileChange}
                            />
                            {((formikProps.touched.picture && formikProps.errors.picture)) &&
                                <div
                                    className="validation-massage"
                                >
                                    {formikProps.errors.picture}
                                </div>
                            }
                        </div>
                    }

                    <div className={styles.formButtonRow}>
                        {
                            svgLayer &&
                            <FileUpload
                                id="choose-different-layer-image"
                                name={t('CHOOSE_DIFFERENT_IMAGE')}
                                onFileUpload={onSVGFileChange}
                            />
                        }

                        <div className="form-group btn-group">
                            {
                                svgLayer &&
                                <Button
                                    color="primary"
                                    className="btn-delete"
                                    onClick={onDeleteLayer}
                                >
                                    {t('DELETE')}
                                </Button>
                            }
                            <Button
                                type="reset"
                                color="primary"
                                onClick={onCancel}
                            >
                                {t('CANCEL')}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                onClick={formikProps.submitForm}
                            >
                                {layerData ? t('SAVE_CHANGES') : t('SAVE')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

/**
 * default props and state
 */
interface IProps {
    location: ILocation;
    layerData: ILayer | null;
    onCancel: () => void;
    formikProps: FormikProps<IFormValues>;
    onUpdateLayer: (model: fabric.Image) => void;
}

export interface IFormValues {
    id?: number;
    name: string;
    picture: string;
    angle: number;
}
