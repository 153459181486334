import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ProductCategoriesService } from '../services';
import { productCategoryConstants } from '../constants';
import { IErrors, IOrder, IProductCategory, IJoin } from '../interfaces';

/**
 * Process related actions
 *
 * @type {Object}
 */
export const ProductCategoryActions = {

    /**
     * Get list of processes
     *
     * @params {String} search Search string
     * @param search
     * @param {Object} order Sort settings
     * @param join
     *
     * @return {Promise.<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'asc' }, join: IJoin = { table: ['products'] }) => {

        //Action creators
        const success = (productCategories: IProductCategory[]) => {

            return {
                type: productCategoryConstants.LIST_SUCCESS,
                category: productCategories,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productCategoryConstants.LIST_FAILURE,
                errors,
            };
        }, service = new ProductCategoriesService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Create new user account
     *
     * @param {IProductCategory} category
     *
     * @return {Promise<Object>}
     */
    store: (category: IProductCategory) => {

        //Action creators
        const success = (category: IProductCategory) => {

            return {
                type: productCategoryConstants.STORE_SUCCESS,
                category: category,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productCategoryConstants.STORE_FAILURE,
                errors,
            };

        }, service = new ProductCategoriesService();

        return  (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.store(category)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Update user account
     *
     * @param {IProductCategory} category
     *
     * @return {Promise<Object>}
     */
    update: (category: IProductCategory) => {

        //Action creators
        const success = (category: IProductCategory) => {
            return {
                type: productCategoryConstants.UPDATE_SUCCESS,
                category: category,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productCategoryConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new ProductCategoriesService();

        return  (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(category)
                .then(({ data }: any) => {

                    dispatch(success(data));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Remove product category by ID
     *
     * @param {number} categoryId
     *
     * @return {Promise<Object>}
     */
    remove: (categoryId: number) => {

        //Action creators
        const success = () => {

            return {
                type: productCategoryConstants.DELETE_SUCCESS,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productCategoryConstants.DELETE_FAILURE,
                errors,
            };

        }, service = new ProductCategoriesService();

        return  (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.remove(categoryId)
                .then(() => {

                    dispatch(success());
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },
};
