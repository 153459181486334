import { causesConstants } from '../../constants';
import { ICausesListAction, ICausesListState } from '../../interfaces';

/**
 * User list reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function causesList(state: ICausesListState = {
    errors: {},
    causes: [],
}, action: ICausesListAction): ICausesListState {

    switch (action.type) {

        case causesConstants.LIST_SUCCESS:

            return {
                causes: action.causes,
                errors: {},
            };

        case causesConstants.LIST_FAILURE:

            return {
                causes: [],
                errors: action.errors,
            };
        default:

            return state;
    }
}