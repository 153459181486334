export const causesConstants = {

    LIST_SUCCESS: 'CAUSES_LIST_SUCCESS',
    LIST_FAILURE: 'CAUSES_LIST_FAILURE',

    STORE_CAUSES_SUCCESS: 'CAUSES_STORE_SUCCESS',
    STORE_CAUSES_FAILURE: 'CAUSES_STORE_FAILURE',

    UPDATE_SUCCESS: 'CAUSES_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CAUSES_UPDATE_FAILURE',

    DELETE_SUCCESS: 'CAUSES_DELETE_SUCCESS',
    DELETE_FAILURE: 'CAUSES_DELETE_FAILURE',
};
