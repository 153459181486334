import React, { useCallback, useEffect, useState } from 'react';
import { DateInput } from '../../../../core/ui/components';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../../../../core/ui/assets/images/icons/calendar.svg';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface ITimeGroupItemProps {
    value: Date;
    maxDate: Date;
    minDate?: Date;
    datePickerClass: string;
    datePickerId: string;
    enableEndTime: boolean;
    datePosition: string;
    handleDateChange: (values: ITimeValues) => void;
    errorHandler: (status: boolean) => void;
    values: ITimeValues;
}

interface ITimeValues {
    startTime: Date;
    endTime: Date;
    product: number;
    unit: string | number;
    enableEndTime: boolean;
}


const TimeGroupItem: React.FC<ITimeGroupItemProps> = ({ value, datePickerClass, maxDate, datePickerId, enableEndTime, handleDateChange, datePosition, minDate, values, errorHandler }: ITimeGroupItemProps) => {


    const [dateError, setDateError] = useState<boolean>(false);

    /**
     * Change Date function
     *
     * @param { Date | MaterialUiPickersDate } event
     */
    const dateChange = useCallback((event) => {

        if (datePosition === 'startDate') {

            if (enableEndTime) {

                if (values.endTime > event) {

                    values.startTime = moment(event).toDate();

                    values.enableEndTime = enableEndTime;

                } else {

                    values.startTime = values.endTime;

                    values.enableEndTime = enableEndTime;
                }

            } else {

                values.startTime = moment(event).toDate();

                values.endTime = new Date();
            }

            return values;
        }

        if (datePosition === 'endDate') {

            if (event > moment(values.startTime).toDate()) {

                values.endTime = moment(event).toDate();

                values.enableEndTime = enableEndTime;

            }
            if (event < moment(values.startTime).toDate()) {

                values.endTime = values.startTime;

                values.enableEndTime = enableEndTime;
            }

            return values;
        }

        return event;

    }, [values, datePosition, enableEndTime]);

    /**
     * Change Date function for DateInput element
     */
    const changeHandlerDateInput = useCallback((event: Date) => {

        return handleDateChange(dateChange(event));

    }, [handleDateChange, dateChange]);

    /**
     * Error handling function
     */
    const errorChangeHandler = useCallback((event) => {

        return errorHandler(event);

    }, [errorHandler]);

    /**
     * Change Date function for KeyboardDatePicker. Set day/month/year.
     */
    const changeHandlerKeyboardDatePicker = useCallback((event: MaterialUiPickersDate) => {

        if (event && moment(event).isValid() && moment(event).isBefore(new Date())) {

            const newDate = moment(value);

            newDate.set('year', moment(event).year());

            newDate.set('month', moment(event).month());

            newDate.set('date', moment(event).date());

            if (minDate && !moment(newDate).isBetween(minDate, maxDate)) {

                setDateError(true);

                errorChangeHandler(true);

                return () => null;
            }

            if (newDate.isValid()) {

                setDateError(false);

                errorChangeHandler(false);

                return handleDateChange(dateChange(newDate.toDate()));
            }
        } else {

            setDateError(true);

            errorChangeHandler(true);
        }

    }, [minDate, maxDate, errorChangeHandler, handleDateChange, setDateError, dateChange, value]);

    useEffect(() => {

        return handleDateChange(values);

    }, [enableEndTime]);

    return (
        <div>
            <DateInput time={moment(value).toDate()}
                       newTime={changeHandlerDateInput}
                       datePosition={datePosition}
                       disabled={datePosition === 'endDate' ? !enableEndTime : undefined}
                       inputName="time-picker"
                       onError={errorChangeHandler}
            />
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD.MM.YY"
                margin="normal"
                id={datePickerId}
                className={datePickerClass}
                maxDate={enableEndTime ? maxDate : new Date()}
                minDate={datePosition === 'startDate' ? undefined : minDate}
                error={dateError}
                value={value}
                onChange={changeHandlerKeyboardDatePicker}
                keyboardIcon={<CalendarIcon />}
                disabled={datePosition === 'endDate' ? !enableEndTime : undefined}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}

            />
        </div>
    );
};

export default TimeGroupItem;
