import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
    Checkbox,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    Select,
    TextareaAutosize,
    MenuItem,
} from '@material-ui/core';
import { ReactComponent as Sensor } from '../../core/ui/assets/images/icons/parameter-v-2.svg';
import { ConfirmDialog, TextInput, Button, Message, EditorKpi } from '../../core/ui/components';

import './Kpi.scss';
import { connect } from 'react-redux';
import { EditKpiAction, FormActions, UnitActions } from '../../core/actions';
import { IFormKPIProps, ISensor } from '../../core/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from '../../core/store';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

/**
 * Default interface
 */

interface IState {
    initialValues: IInitValues,
    showMessage: boolean;
    messageText: string;
    availableVariables: ISensor[];
    variables: {
        select: string;
        selected: [];
    };
    editorText: string;
    logic: Record<string, unknown>;
    unitSelect: number;
}

interface IInitValues {
    id?: number | null;
    name: string;
    goalRangeMin: number | string;
    goalRangeMax: number | string;
    viewAsGraph: boolean;
    formula: string;
    goalRange: boolean;
    comment: string;
    idUnit: number;
}

/**
 * Form KPI
 *
 * @param {Object} props
 *
 * @constructor
 *
 * @return {JSX.Element}
 */
class FormKPI extends React.Component<IFormKPIProps & WithTranslation, IState> {

    constructor(props: IFormKPIProps & WithTranslation) {
        super(props);

        const { unit, kpi } = this.props;

        this.state = {
            initialValues: {
                id: kpi && kpi.id ? kpi.id : null,
                name: kpi ? kpi.name : '',
                goalRangeMin: kpi ? kpi.goalRangeMin : '',
                goalRangeMax: kpi ? kpi.goalRangeMax : '',
                viewAsGraph: kpi ? kpi.viewAsGraph : false,
                formula: kpi ? kpi.formula : '',
                goalRange: kpi ? kpi.goalRange : props.goalRangeHidden,
                comment: kpi ? kpi.comment : '',
                idUnit: unit ? unit.id : 0,
            },
            showMessage: true,
            messageText: this.props.t('KPI_IS_CALC'),
            availableVariables: props.availableVariables,
            variables: {
                select: '',
                selected: [],
            },
            editorText: '',
            logic: {},
            unitSelect: unit ? unit.id : 0,
        };

        this.validationSchema =  yup.object().shape({
            name: yup.string().required(),
            comment: yup.string(),
            goalRangeMin: yup.number(),
            goalRangeMax: yup.number(),
        });

        this.handleEditorChange = this.handleEditorChange.bind(this);

        this.handleMessage = this.handleMessage.bind(this);

        this.handleCloseSidebar = this.handleCloseSidebar.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.preventDefault = this.preventDefault.bind(this);

        this.handleTouchStart = this.handleTouchStart.bind(this);
    }



    componentDidMount() {

        this.props.load();

    }

    componentWillUnmount() {

        this.props.store(null);

    }

    /**
     * Validation schema
     *
     * @type {ObjectSchema<Shape<ValidateOptions, {name: string, goalRangeMin: number, comment: string, goalRangeMax: number}>>}
     * @private
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, { name: string; goalRangeMin: number; comment: string; goalRangeMax: number }>>;

    /**
     * Change current unit selection
     *
     * @param { React.ChangeEvent<{ value: unknown }>} event
     */
    handleChange(event: React.ChangeEvent<{ value: unknown }>) {

        this.setState({ unitSelect: event.target.value as number, editorText: '' });
    }

    /**
     * Handle submit for form value. The union result from the from and editor formula
     * @param {Object} values
     */
    handleSubmit(values: IInitValues) {

        const div = document.createElement('div');

        div.innerHTML = this.state.editorText.trim();

        const resultElemSpan: NodeListOf<HTMLSpanElement> = div.querySelectorAll('span');
        const resultElemP: NodeListOf<HTMLSpanElement> = div.querySelectorAll('p');
        let formula = '';

        resultElemP.forEach((value) => {

            formula = formula + value.textContent?.replace(/\s+/g, ' ').trim();

        });

        resultElemSpan.forEach((value) => {

            if (value.id) {

                const temp = value.textContent?.replace(/\s+/g, ' ').trim();

                formula = formula.replace(String(temp), 'id=' + value.id);

            }
        });

        const result = {
            logic: this.state.logic,
            formResult: values,
            editorResult: formula,
        };

        this.props.result(result);
    }

    /**
     * showing message
     */
    handleMessage() {

        this.setState({ showMessage: !this.state.showMessage });

    }

    /**
     * Editor content
     *
     * @param {Object<content: string, logic: object >>} editorText
     */
    handleEditorChange(editorText: { content: string, logic: Record<string, unknown> }) {

        this.setState({ editorText: editorText.content, logic: editorText.logic });


    }

    /**
     * Prevent default
     *
     * @param {React.MouseEvent} event
     */
    preventDefault(event: React.MouseEvent) {

        event.preventDefault();
    }

    /**
     * Handle close sidebar
     *
     * @return {function}
     * */
    handleCloseSidebar() {
        this.props.close(true, '');
    }


    /**
     *  Handler edit item sensor in Editor KPI by touch action
     * */
    handleTouchStart(event: React.TouchEvent<HTMLElement>) {

        if (event.currentTarget.childNodes && event.currentTarget.childNodes[1].textContent) {

            const content = '<span class=\'formula-item mceNonEditable\' id=' + event.currentTarget.getAttribute('itemId') + ' >' + event.currentTarget.childNodes[1].textContent.replace(' ', '_') + '</span>';

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.tinyMCE.execCommand('mceInsertContent', false, content);
        }
    }

    /**
     * get sensor from unit
     *
     * @param {number} id
     *
     * @return ISensor[]
     */
    getVariables(id: number) {

        const { unitsInTree } = this.props;

        return unitsInTree.filter(value => value.id === id)[0].data as ISensor[];
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, kpi, unitsInTree } = this.props,
            { unitSelect, initialValues, editorText, messageText, showMessage } = this.state;

        return (
            <div className={'wrap-kpi'}>
                <h3 className="title">
                    {t(initialValues.id ? 'EDIT_KPI' : 'ADD_KPI')}
                    <span className={'title-description'}>
                        <Select
                            id="unit-select"
                            displayEmpty
                            value={unitSelect}
                            onChange={this.handleChange}
                            className={'unit-select'}
                            disableUnderline
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="0" disabled>
                                {t('SELECT_UNIT')}
                            </MenuItem>
                            {unitsInTree.map(value =>
                                <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>,
                            )}
                        </Select>
                    </span>
                    {/*<Question*/}
                    {/*    onClick={() => {*/}
                    {/*        setState({...state, showMessage: !state.showMessage});*/}
                    {/*    }}*/}
                    {/*    style={{height: 24, width: 24, cursor: 'pointer'}}*/}
                    {/*/>*/}
                </h3>
                <Formik
                    // enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form
                            className={'form-kpi'}
                            onSubmit={props.handleSubmit}
                            noValidate
                            style={{ paddingBottom: 80 }}
                        >
                            <div className={'wrap'}>
                                <div className="field-form-wrap">
                                    <List className={`available-variables ${(unitSelect === 0 ? ' Mui-disabled' : '')}`}
                                          subheader={
                                              <span className={'title-listing'}>
                                                  {this.props.t('AVAILABLE_VARIABLES')}
                                              </span>}
                                    >
                                        {unitSelect !== 0 && this.getVariables(unitSelect).map((value: ISensor) =>
                                            (
                                                <ListItem
                                                    href={value.id.toString()}
                                                    component={'a'}
                                                    className={'available-variables-item'}
                                                    key={value.id}
                                                    itemID={value.id.toString()}
                                                    disabled={unitSelect === 0}
                                                    onClick={this.preventDefault}
                                                    onTouchStart={this.handleTouchStart}
                                                >
                                                    <Sensor style={{ height: 24, width: 24, marginRight: 8 }} />
                                                    {value.name}
                                                </ListItem>
                                            ),
                                        )}
                                    </List>
                                </div>
                                <div className="field-form-wrap">
                                    <div className={'field-form'}>
                                        <TextInput
                                            className={
                                                'form-field field-name '
                                                +
                                                (props.touched.name ? props.errors.name ? 'error-field' : 'success-field' : '')
                                                +
                                                (unitSelect === 0 ? ' MuiListItem-root Mui-disabled' : '')
                                            }
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            onDrop={this.preventDefault}
                                            value={props.values.name}
                                            disabled={unitSelect === 0}
                                            onDragEnd={this.preventDefault}
                                            name="name"
                                            type="text"
                                            placeholder={this.props.t('NAME')}
                                            label={this.props.t('NAME')}
                                        />
                                    </div>
                                    <div className={'field-form'}>
                                        <InputLabel className={`goal-range-group-label ${(unitSelect === 0 ? ' Mui-disabled' : '')}`}>
                                            {this.props.t('GOAL_RANGE')}
                                        </InputLabel>
                                        <div className="goal-range-group">
                                            <TextInput
                                                className={
                                                    'form-field field-range-min '
                                                    +
                                                    (props.touched.goalRangeMin ? props.errors.goalRangeMin ? 'error-field' : 'success-field' : '')
                                                    +
                                                    (unitSelect === 0 ? ' MuiListItem-root Mui-disabled' : '')
                                                }
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                onDrop={this.preventDefault}
                                                value={props.values.goalRangeMin}
                                                disabled={unitSelect === 0}
                                                name="goalRangeMin"
                                                type="text"
                                                placeholder={this.props.t('MIN')}
                                            />
                                            <span className={'delimiter'} style={{ lineHeight: '40px' }}>&mdash;</span>
                                            <TextInput
                                                className={
                                                    'form-field field-range-max '
                                                    +
                                                    (props.touched.goalRangeMax ? props.errors.goalRangeMax ? 'error-field' : 'success-field' : '')
                                                    +
                                                    (unitSelect === 0 ? ' MuiListItem-root Mui-disabled' : '')
                                                }
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                onDrop={this.preventDefault}
                                                value={props.values.goalRangeMax}
                                                disabled={unitSelect === 0}
                                                name="goalRangeMax"
                                                type="text"
                                                placeholder={this.props.t('MAX')}
                                            />
                                        </div>

                                    </div>
                                    <div
                                        className={`field-form ${unitSelect === 0 && 'MuiListItem-root Mui-disabled'}`}
                                    >
                                        <EditorKpi
                                            value={editorText}
                                            onchange={this.handleEditorChange}
                                            availableVariables={unitSelect !== 0 && this.getVariables(unitSelect) ? this.getVariables(unitSelect) : []}
                                        />
                                    </div>
                                    <div className={'field-form'}>
                                        <FormControlLabel
                                            className={'view-as-graph'}
                                            disabled={unitSelect === 0}
                                            control={
                                                <Checkbox
                                                    checked={props.values.viewAsGraph}
                                                    onChange={props.handleChange}
                                                    name={'viewAsGraph'}
                                                />
                                            }
                                            label={t('VIEW_AS_GRAPH')}
                                        />
                                    </div>
                                    <div className={'field-form'}>
                                        <TextareaAutosize
                                            className={
                                                'form-field field-comment '
                                            }
                                            autoFocus={false}
                                            disabled={unitSelect === 0}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            maxRows={1}
                                            onDrop={this.preventDefault}
                                            value={props.values.comment}
                                            placeholder={t('COMMENT') + '...'}
                                            name="comment"
                                        />
                                    </div>
                                    <div className="field-form">
                                        <Message message={messageText} hidden={showMessage}
                                                 handleClick={this.handleMessage}
                                        />
                                    </div>
                                    <div className={'field-form btn-group'}>
                                        {/* Confirm Dialog is temporarily disabled. Add an opening handler and update the title if needed */}
                                        <ConfirmDialog heading={'some header'} onAccept={props.handleSubmit} open={false} />
                                        <Button
                                            type="reset"
                                            color={'primary'}
                                            onClick={this.handleCloseSidebar}
                                        >
                                            {t('CANCEL')}
                                        </Button>
                                        <Button
                                            disabled={unitSelect === 0}
                                            type="submit"
                                            color={'secondary'}
                                        >
                                            {t(kpi && kpi.id ? 'EDIT' : 'ADD')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { units, errors } = state.unit,
        { refresh } = state.unitChange,
        { kpi, unit } = state.editKpi,
        { formOpened, formName } = state.form,
        { sensors } = state.sensor;

    return {
        units,
        errors,
        refresh,
        formOpened,
        formName,
        kpi,
        unit,
        availableVariables: sensors,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    load: UnitActions.list,
    store: EditKpiAction.store,
    bulkUpdate: UnitActions.bulkUpdate,
    close: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormKPI));