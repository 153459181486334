import {
    SaveLayerAction,
    FetchLayersFailure,
    FetchLayersRequest,
    FetchLayersSuccess,
    DeleteLayerAction,
} from '../actions/layerActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ILayer, ILocation } from '../../interfaces';
import {
    SaveLayerFormFailure,
    SaveLayerFormRequest,
    SaveLayerFormSuccess,
    ResetWithLayerModelAction,
} from '../actions/layerFormActions';
import { LayerService } from '../../services/layerService';
import { IOrder } from '../../../../core/interfaces';

export const layerThunks = {
    fetchLayers: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }) => async(dispatch: Dispatch<{}, {}, AnyAction>) => {
        try {
            dispatch(new FetchLayersRequest());

            const layerService = new LayerService();
            const layers = await layerService.list(search, order);

            dispatch(new FetchLayersSuccess({ layers }));
        } catch (error) {
            dispatch(new FetchLayersFailure({ error }));
        }
    },
    deleteLayer: (layer: ILayer) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {
        try {
            const layerService = new LayerService();
            await layerService.delete(layer);

            dispatch(new DeleteLayerAction({ layer }));
        } catch (error) {
            console.warn(error);
        }
    },
    openForm: (location: ILocation, layer: ILayer | null) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {
        const model = layer ? { ...layer } : null;

        const layerService = new LayerService();
        if (model && model.id && !model.pictureContent) {
            try {
                model.pictureContent = await layerService.getPicture(model);

                const file = new File([model.pictureContent], 'layer.svg', {
                    type: 'image/svg+xml',
                });
                model.picture = file as unknown as string;
            } catch (e) {
                model.picture = '';
                model.error = layerService.errorHandler(e);
            }
        }

        dispatch(new ResetWithLayerModelAction({
            location,
            layer: model,
        }));
    },
    closeForm: () => (dispatch: Dispatch<{}, {}, AnyAction>) => {
        dispatch(new ResetWithLayerModelAction({
            location: null,
            layer: null,
        }));
    },
    saveLayer: (location: ILocation, model: ILayer) => async(dispatch: Dispatch<{}, {}, AnyAction>) => {
        try {
            dispatch(new SaveLayerFormRequest());

            const layerService = new LayerService();
            const layer = await layerService.saveLayer(location, model);

            dispatch(new SaveLayerFormSuccess({ layer }));
            dispatch(new SaveLayerAction({ layer }));
        } catch (error) {
            dispatch(new SaveLayerFormFailure({ error }));
            throw error;
        }
    },
};
