import { EmployeeActions, EmployeeActionTypes } from '../actions/employeeActions';
import { IEmployee } from '../../interfaces';
import { IErrors } from '../../../../core/interfaces';

export interface IEmployeeState {
    isLoading: boolean;
    employees: IEmployee[];
    error: IErrors;
    employee: IEmployee | null;
}

export const initialState: IEmployeeState = {
    isLoading: false,
    employees: [],
    error: {},
    employee: null,
};

export function employeeReducer(state = initialState, action: EmployeeActions): IEmployeeState {

    switch (action.type) {

        case EmployeeActionTypes.FETCH_EMPLOYEES_REQUEST: {

            return {
                ...state,
                isLoading: true,
                error: {},
            };
        }

        case EmployeeActionTypes.FETCH_EMPLOYEES_SUCCESS: {

            const { employees } = action.payload;

            return {
                ...state,
                isLoading: false,
                employees,
            };
        }

        case EmployeeActionTypes.GET_EMPLOYEE_SUCCESS: {

            const { employee } = action.payload;

            return {
                ...state,
                isLoading: false,
                employee,
            };
        }

        case EmployeeActionTypes.GET_EMPLOYEE_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case EmployeeActionTypes.FETCH_EMPLOYEES_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case EmployeeActionTypes.SAVE_EMPLOYEE_SUCCESS: {

            const { employee } = action.payload;

            const employees = state.employees.concat([employee]);

            return {
                ...state,
                isLoading: false,
                employees,
            };
        }

        case EmployeeActionTypes.SAVE_EMPLOYEE_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case EmployeeActionTypes.UPDATE_EMPLOYEE_SUCCESS: {

            const { employee } = action.payload;

            const employees = state.employees.map(value => {

                if (value.id === employee.id) {

                    return { ...value, ...employee };
                }

                return value;
            });

            return {
                ...state,
                isLoading: false,
                employees,
            };
        }

        case EmployeeActionTypes.UPDATE_EMPLOYEE_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case EmployeeActionTypes.DELETE_EMPLOYEE_ACTION: {
            const { employee } = action.payload;

            const employees = state.employees.filter(({ id }) => employee.id !== id);

            return {
                ...state,
                employees,
            };
        }

        case EmployeeActionTypes.CLEAR_EMPLOYEE_ERROR: {

            return {
                ...state,
                error: {},
            };
        }

        default: {
            return state;
        }
    }
}
