import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IOrder } from '../../../../../../core/interfaces';
import { connect } from 'react-redux';
import ListStickyHeader from '../../../../ui/components/List/ListStickyHeader';
import { IHrState } from '../../../../store/reducers';
import { selectBeacons } from '../../../../store/selectors/beaconSelector';
import RightSidebar from '../../../../ui/components/Sidebar/RightSidebar';
import { ObjectHelper } from '../../../../../../core/helpers/objectHelper';
import { IData } from '../../../../../interfaces';
import { ReactComponent as Energy } from '../../../../ui/assets/images/icons/energy.svg';
import { ReactComponent as LowBattery } from '../../../../ui/assets/images/icons/low-battery.svg';
import { ReactComponent as FullBattery } from '../../../../ui/assets/images/icons/full-battery.svg';
import BeaconForm from './BeaconForm/BeaconForm';
import { SettingPageThunks } from '../../../../store/thunks/settingPageThunks';
import { IBeaconApiModel, IBeaconIndentOnly } from '../../../../services/beaconService';
import { selectSettingPageState } from '../../../../store/selectors/settingPageSelector';
import DeleteDialog from '../../../../ui/components/Dialog/DeleteDialog';
import { BeaconThunks } from '../../../../store/thunks/beaconThunks';
import './BeaconTable.scss';

import moment from 'moment';
import { EditBeaconButton } from './EditBeaconButton';
import { IBeacon } from '../../../../interfaces';

interface IProps {
    beacons: IBeaconApiModel[];
    toggleForm: (formOpening: boolean, formName?: string, model?: IBeaconApiModel) => void;
    formOpening?: boolean;
    formName?: string;
    deleteBeacon: (beacon: IBeaconIndentOnly) => void;
    saveBeacon: (model: IBeacon) => void;
}

interface IState {
    search: string | null
    order: IOrder | null;
    dialogOpened: boolean;
    filterKey: string | null;
    filterOrder: 'asc' | 'desc';
    selectedFilters: Record<string, unknown>;
    removeId: IData | null;
    formOpened: boolean
}

class BeaconTable extends React.Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t } = this.props;

        this.editState = this.editState.bind(this);

        this.activateBeacon = this.activateBeacon.bind(this);

        const contextMenu: string | any[] = [
            {
                title: t('EDIT'),
                action: this.editState.bind(this),
                color: '',
            },
            {
                title: t('DELETE'),
                action: this.removeConfirmation.bind(this),
                color: 'red',
                disabled: (row: IData) => {

                    return !row.id;
                },
            },
        ];

        this.columns = [
            {
                name: '',
                label: '',
                sortable: false,
                width: '5%',
                headerStyle: {
                    border: 'none',
                },
                cellStyle: {
                    border: 'none',
                    backgroundColor: '#e6eaed',
                },
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    return !row.active ? <EditBeaconButton data={row} action={this.activateBeacon} label={t('ADD')} /> : null;
                },
                disabled: (rows: IData[]): boolean => {

                    return rows.filter(value => !value.active).length === 0;
                },
            },
            {
                name: 'name',
                label: t('NAME'),
                sortable: true,
                width: '30%',
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    return row.name ? row.name : null;
                },
            },
            {
                name: 'id',
                label: `${t('UUID')}/${t('BLE-MAC')}`,
                sortable: true,
                width: '15%',
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    return (
                        <React.Fragment>
                            <span>{row.id}</span><br />
                            <span>{row.bleMac ? `${row.bleMac}` : ''}</span>
                        </React.Fragment>
                    );
                },
            },
            {
                name: 'lowBattery',
                label: <Energy />,
                sortable: true,
                width: '10%',
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    // return row.lowBattery;
                    return row.lowBattery ? <LowBattery /> : <FullBattery />;

                },
            },
            {
                name: 'lastSeen',
                label: t('LAST_ACTIVITY'),
                sortable: true,
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    const momentDate = moment(row.lastSeen);

                    return momentDate.isValid() ? momentDate.format('DD.MM.YYYY HH:mm') : null;
                },
                width: '10%',
            },
            {
                name: 'employee',
                label: t('EMPLOYEE'),
                sortable: true,
                mutate: (value: undefined, row: IData): React.ReactNode => {

                    const employee = row.employee;

                    if (employee) {
                        return <span className={!row.active ? 'activate' : ''} style={{ marginRight: 28 }}>{`${employee.surname} ${employee.firstName} ${employee.middleName}`}</span>;
                    }

                    return (
                        <span className={!row.active ? 'activate' : ''} style={{ marginRight: 28 }}>
                            <EditBeaconButton
                                data={row}
                                action={this.editState}
                                label={t('ASSIGN')}
                            />
                        </span>
                    );
                },
                width: '30%',
                menu: contextMenu.length ? contextMenu : undefined,
            },
        ];

        this.state = {
            search: null,
            order: {
                column: 'id',
                dir: 'desc',
            },
            dialogOpened: false,
            filterKey: null,
            filterOrder: 'asc',
            selectedFilters: {
                stateCategoryName: [],
                causeName: [],
            },
            removeId: null,
            formOpened: false,
        };

        this.setSearchOrder = this.setSearchOrder.bind(this);

        this.getFilteredData = this.getFilteredData.bind(this);

        this.onConfirmDeleteLayer = this.onConfirmDeleteLayer.bind(this);

        this.onCloseDeleteDialog = this.onCloseDeleteDialog.bind(this);
    }


    private readonly columns: (
        {
            mutate: (value: undefined, row: IData) => React.ReactNode; name: string; width: string; label: string | any; sortable: boolean,
            headerStyle?: any,
            cellStyle?: any,
        } |
        { name: string; width: string; action: { handler: () => void; icon: React.ReactNode }; label: string | any; sortable: boolean; disabled?: (rows: IData[]) => boolean } |
        { name: string; width: string; label: string | any; sortable: boolean; menu: any[] | undefined })[];


    /**
     * Open edit state form and fill it with data
     *
     * @param {IData} beacon
     */
    editState(beacon: IData) {

        this.setState({ formOpened: true });

        this.props.toggleForm(true, 'beacon', beacon as IBeaconApiModel);

    }

    /**
     * Open edit state form and fill it with data
     *
     * @param {IData} beacon
     */
    activateBeacon(beacon: IData) {

        const sendData = beacon as IBeacon;

        sendData.active = 1;

        sendData.lowBattery = sendData.lowBattery === 1 ? 1 : 0;

        this.props.saveBeacon(sendData);

    }

    /**
     * Confirm remove state
     *
     * @param {IData} state
     */
    removeConfirmation(state: IData) {

        this.setState({
            removeId: state,
        });
    }

    /**
     * Set search and order settings
     *
     * @param {string} search
     * @param {IOrder} order
     */
    setSearchOrder(search: string, order: IOrder) {

        this.setState({
            search: search,
            order: order,
        });
    }

    /**
     * Apply filters and order settings to data
     *
     * @return {IData[]}
     */
    getFilteredData() {

        let data = [...this.props.beacons];

        const { search, order } = this.state;

        if (order && order.column) {

            const paths: Record<string, string> = {
                'name': 'name',
                'bleMac': 'id',
                'id': 'id',
                'lowBattery': 'lowBattery',
                'lastSeen': 'lastSeen',
                'employee': 'employee.surname',
            };

            data.sort((a: IData, b: IData) => {

                const valueA = order.column === 'lowBattery' ?
                    ObjectHelper.getPathValue(paths[order.column], a) === 0 ? 1 : 0
                    : ObjectHelper.getPathValue(paths[order.column], a),
                    valueB = order.column === 'lowBattery' ?
                        ObjectHelper.getPathValue(paths[order.column], b) === 0 ? 1 : 0
                        : ObjectHelper.getPathValue(paths[order.column], b);

                if ((typeof valueA === 'string' && typeof valueB === 'string' ?
                    String(valueA).toLowerCase() < String(valueB).toLowerCase() :
                    valueA < valueB) || !valueA) {

                    return order.dir === 'asc' ? -1 : 1;
                }

                if ((typeof valueA === 'string' && typeof valueB === 'string' ?
                    String(valueA).toLowerCase() > String(valueB).toLowerCase() :
                    valueA > valueB) || !valueB) {

                    return order.dir === 'asc' ? 1 : -1;
                }

                return 0;
            }).sort((a: IData, b: IData) => {
                if (a.active === b.active) {
                    return 0;
                }

                return a.active < b.active ? 1 : -1;
            });
        }

        if (search) {

            data = data.filter((d: IData) => d.name && d.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        }

        return data;
    }

    /**
     *
     */
    onConfirmDeleteLayer() {

        const removeId = this.state.removeId as IBeacon;

        if (removeId.active) {

            removeId.active = 0;

            this.props.saveBeacon(removeId);

        } else {
            this.props.deleteBeacon(removeId as IBeaconIndentOnly);
        }

        this.setState({ removeId: null });
    }

    /**
     *
     */
    onCloseDeleteDialog() {

        this.setState({ removeId: null });

    }


    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { formOpening, t } = this.props;

        return (
            <div className="setting-page-beacon-table">
                <ListStickyHeader
                    columns={this.columns}
                    data={this.getFilteredData()}
                    refresh={this.setSearchOrder}
                    searchable
                    selectedParam={'id'}
                    searchableField={'name'}
                    searchableField2={'id'}
                    maxHeightSlider={'calc(100vh - 260px)'}
                />
                <RightSidebar openSidebar={formOpening}>
                    <BeaconForm />
                </RightSidebar>
                {this.state.removeId ?
                    <DeleteDialog
                        open
                        removeId={this.state.removeId.id}
                        heading={t('DELETE_BEACON_Q', { name: this.state.removeId.name })}
                        body={t('THIS_ACTION_WILL_REMOVE_BEACON_FROM_SYSTEM')}
                        onAccept={this.onConfirmDeleteLayer}
                        onClose={this.onCloseDeleteDialog}
                    />
                    :
                    null}
            </div>
        );
    }

}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: IHrState) => {

    const beacons = selectBeacons(state);

    const { formOpening, formName, model } = selectSettingPageState(state);

    return {
        beacons,
        formOpening,
        formName,
        model,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    toggleForm: SettingPageThunks.toggleForm,
    deleteBeacon: BeaconThunks.deleteBeacon,
    saveBeacon: BeaconThunks.saveBeacon,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BeaconTable));
