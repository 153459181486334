import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as KeyIcon } from '../../../../core/ui/assets/images/icons/key.svg';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as SensorIcon } from '../../../../core/ui/assets/images/icons/parameter-v-2.svg';
import { ReactComponent as ParameterSelectedIcon } from '../../../../core/ui/assets/images/icons/parameter-selected.svg';

import { IMonitoringTreeSensorItemProps } from '../../../../core/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { FormActions, MonitoringActions, statesActions } from '../../../../core/actions';
import { GraphActions } from '../../../../base/store/actions';
import { HistogramChart } from '../../../../base/components';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import {
    selectMaxWidthSideBar,
    selectVisibleSideBar,
} from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { graphConstants } from '../../../../core/constants';
import { selectForm } from '../../../../core/selectors/form/formSelector';
import {
    isVisibleHMIPlayerScheme,
    selectHmiPlayerMode,
    selectHmiPlayerSchema,
    selectHmiPlayerValue,
} from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline, selectScreenWidth } from '../../../../core/selectors/dashboard/dashboardSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import {
    selectHmiHoverItem,
    selectHmiHoverSensor,
    selectHoverHMILogic
} from '../../../../core/selectors/hmi/playerHoverItem';
import { selectDrawerWidth, selectPositionDrawer } from '../../../../core/selectors/layout/responsiveDrawerSelector';
import * as d3 from 'd3';
import { HmiObjectAction } from '../../../../core/actions/hmiObjectAction';
import { isDesktop, isMobile } from 'react-device-detect';


/**
 * SensorItemDragged component
 *
 * @param {number} histogramHeight
 * @param {ISensor} sensor
 * @param {Object} factory
 * @constructor
 *
 * @return JSX.Element
 */
const SensorItem: React.FC<IMonitoringTreeSensorItemProps> = (
    {
        histogramHeight,
        sensor,
        sensorTargetValue,
    }: IMonitoringTreeSensorItemProps,
) => {

    const dispatch = useDispatch();

    const selectedDashboard = useSelector(selectSelectedDashboard);

    const visibleSideBar = useSelector(selectVisibleSideBar);
    const dashboardRealTime = useSelector(selectDashboardOnline);

    const maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const hoverItem = useSelector(selectHmiHoverItem);

    const [editPreferences, setEditPreferences] = useState(false);

    const schema = useSelector(selectHmiPlayerSchema);
    const isVisibleSchema = useSelector(isVisibleHMIPlayerScheme);
    const dedicatedSensor = useSelector(selectHmiHoverSensor);

    const selectFormData = useSelector(selectForm);
    const screenWidth = useSelector(selectScreenWidth) + useSelector(selectMaxWidthSideBar);
    const realTimeIndentation = useSelector(calcRealTimeIndentation),
        anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        value = useSelector(selectHmiPlayerValue),
        selection = useSelector(selectBrushSelection),
        drawWidth = useSelector(selectDrawerWidth),
        histogramWidth = useSelector(selectScreenWidth) - (isVisibleSchema  && anchor === 'right' ? drawWidth : 0) - realTimeIndentation;

    const hoverHmiPlayerLogic = useSelector(selectHoverHMILogic);

    const scale = d3.scaleTime().range([0, histogramWidth - 1.5])
        .domain(selection);

    const [isVisible, setIsVisible] = useState(sensor.isVisible);

    useEffect(() => {

        setIsVisible(sensor.isVisible);

    }, [sensor]);

    /**
     * Hover sensor on tree Callback
     */
    useEffect(() => {

        if (hoverItem?.sensorId === sensor.id) {

            dispatch(HmiObjectAction.hoverSensorOnTree(sensor));
        }
    }, [hoverItem, sensor, dispatch]);



    useEffect(() => {

        if (selectFormData?.formName === 'histogramForm' && selectFormData?.formModel) {

            if (selectFormData.formModel.sensor === sensor.id) {

                setEditPreferences(true);

            }
        }
        return ()=> setEditPreferences(false);

    }, [selectFormData]);


    /**
     * Deselect all states and alerts
     *
     * @type {() => void}
     */
    const deselectStatesAndAlertsCallback = useCallback(() => {

        dispatch(GraphActions.barToggleTableView(false, 0));

        dispatch(statesActions.deselectAllStates());

        dispatch(GraphActions.deselectAlertGraph());

        dispatch(HmiObjectAction.hoverSensorOnTree(sensor));

    }, [dispatch, sensor]);

    /**
     * Update monitoring tree callback
     *
     * @type {() => void}
     */
    const updateMonitoringTreeCallback = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        event.preventDefault();

        event.stopPropagation();

        if (selectedDashboard && !isMobile) {

            dispatch(MonitoringActions.update(
                selectedDashboard.id,
                'sensor',
                sensor.id,
                {
                    sensorChange: {
                        ...sensor,
                        isVisible: !isVisible,
                        position: sensor.position,
                    },
                },
            ));

            dispatch(FormActions.toggle(false));

            setIsVisible(!isVisible);
        }

    }, [selectedDashboard, sensor, dispatch, setIsVisible, isVisible]);

    /**
     * Update monitoring tree callback
     *
     * @type {() => void}
     */
    const onTouchUpdateMonitoringTreeCallback = useCallback(async(event: React.TouchEvent<HTMLButtonElement>) => {

        event.preventDefault();

        event.stopPropagation();

        if (selectedDashboard && !isDesktop) {

            setIsVisible(!isVisible);

            const setTimeoutUpdate = setTimeout(()=>{
                dispatch(MonitoringActions.update(
                    selectedDashboard.id,
                    'sensor',
                    sensor.id,
                    {
                        sensorChange: {
                            ...sensor,
                            isVisible: !isVisible,
                            position: sensor.position,
                        },
                    },
                ));

                dispatch(FormActions.toggle(false));

                clearTimeout(setTimeoutUpdate);

            }, 50);


        }

    }, [selectedDashboard, sensor, dispatch, setIsVisible, isVisible]);

    /**
     * On mouse move callback
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

                }
            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation]);

    /**
     * On touch start callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchStartCallback = useCallback((event: React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        if (hoverHmiPlayerLogic) {

            dispatch(HmiObjectAction.hoverSensorOnTree(undefined));
            dispatch(HmiObjectAction.hoverItemOnMap(undefined));
        }

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {

                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }

        dispatch(GraphActions.barToggleTableView(false, 0));

        dispatch(statesActions.deselectAllStates());

        dispatch(GraphActions.deselectAlertGraph());

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, screenWidth, realTimeIndentation, hoverHmiPlayerLogic]);

    /**
     * On touch move callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchMoveCallback = useCallback((event: React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        if (hoverHmiPlayerLogic) {

            dispatch(HmiObjectAction.hoverSensorOnTree(undefined));
            dispatch(HmiObjectAction.hoverItemOnMap(undefined));
        }

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop' || HMIPlayerStatus === 'pause') {


                if (dashboardRealTime && screenWidth - realTimeIndentation - 1 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, screenWidth, realTimeIndentation, hoverHmiPlayerLogic]);

    /**
     * On mouse leave callback
     *
     * @type {() => void}
     */
    const onMouseLeaveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());
        }

        if (HMIPlayerStatus === 'pause') {

            dispatch(GraphActions.peakEnterEmptyLine(Math.ceil(scale(new Date(value)) as number)));
        }

    }, [dispatch, HMIPlayerStatus, scale, value]);

    /**
     * Mouse enter callback  for the hmi player
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseEnterForPlayer = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent> |  React.TouchEvent<HTMLDivElement>) => {

        const sendItem = schema?.hmiObjects?.find(obj=> obj.sensorId === sensor.id);

        dispatch(HmiObjectAction.hoverItemOnMap(sendItem));

        dispatch(HmiObjectAction.hoverSensorOnTree(sendItem ? sensor : undefined));

    }, [dispatch, sensor, schema]);

    /**
     * Mouse leave callback  for the hmi player
     *
     * @type {() => void}
     */
    const onMouseLeaveForPlayer = useCallback(() => {

        if (hoverHmiPlayerLogic) {

            dispatch(HmiObjectAction.hoverSensorOnTree(undefined));
            dispatch(HmiObjectAction.hoverItemOnMap(undefined));
        }

    }, [dispatch, hoverHmiPlayerLogic]);


    const rightSectionSensorNameStyle = {
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)',
    };

    const hoverPlayerLogic = dedicatedSensor && hoverItem ? dedicatedSensor?.id === hoverItem?.sensorId &&  dedicatedSensor?.id === sensor.id
        : hoverItem?.sensorId === sensor.id;

    return (
        <div className={`item-title no-pointer ${hoverPlayerLogic ?
            'hover' : ''} ${editPreferences ?
            'editPreferences' : ''} ${histogramHeight === graphConstants.histogramSmallHeight ?
            '' : isVisible ?
                'extended' : ''} 
                ${isVisibleSchema ? 'open-hmi' : ''}`}
        >
            <div
                className="wrap-section"
            >
                <div
                    className={visibleSideBar ? 'right-section sensor-name left-padding-4 limit-padding hidden' : 'right-section sensor-name left-padding-4 '}
                    style={rightSectionSensorNameStyle}
                    onClick={deselectStatesAndAlertsCallback}
                    onTouchStart={deselectStatesAndAlertsCallback}
                    onMouseEnter={onMouseEnterForPlayer}
                    onMouseLeave={onMouseLeaveForPlayer}
                    onTouchEnd={onMouseLeaveForPlayer}
                >
                    {sensor.isKeyParameter ?
                        <KeyIcon
                            className="key-param tree-icon-item"
                        />
                        : null
                    }
                    <Checkbox
                        icon={
                            <SensorIcon
                                className="sensor tree-icon-item"
                            />
                        }
                        onClick={updateMonitoringTreeCallback}
                        onTouchStart={onTouchUpdateMonitoringTreeCallback}
                        checkedIcon={
                            <ParameterSelectedIcon
                                className="tree-icon-item"
                            />
                        }
                        value="factory"
                        checked={isVisible}
                    />
                    <div
                        className={`right-section-text ${isVisible ? Boolean(histogramHeight) : true} ${isMobile ? 'is-mobile' : ''}`}
                    >
                        {sensor.name ? ' ' + sensor.name : ' ' + sensor.id}
                    </div>
                </div>
                <div
                    className={isVisible ? 'left-section with-chart' : 'left-section'}
                    onMouseMove={onMouseMoveCallback}
                    onTouchStart={onTouchStartCallback}
                    onTouchMove={onTouchMoveCallback}
                    onMouseLeave={onMouseLeaveCallback}
                >
                    <span hidden={!isVisible}>
                        <HistogramChart
                            sensor={sensor}
                            sensorTargetValue={sensorTargetValue}
                            hrMode={false}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(SensorItem);