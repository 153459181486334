import { fabric } from 'fabric';
import { ILineOptions } from 'fabric/fabric-impl';

/**
 * PolygonLine object definition
 *
 * @type {any}
 */
const PolygonLine = fabric.util.createClass(fabric.Line, {
    initialize(points: number[], options: ILineOptions) {

        const customOptions = Object.assign({
            strokeWidthInitial: options.strokeWidth,
        }, options);

        this.callSuper('initialize', points, customOptions);
    },
    transform: function(ctx: CanvasRenderingContext2D) {
        this.callSuper('transform', ctx);

        if (!this.group && this.canvas) {
            const zoom = 1 / this.canvas.getZoom();
            this.strokeWidth = this.strokeWidthInitial * zoom;
        }
    },
});

export default PolygonLine;
