import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';
import Button from '../../../../ui/components/Button/Button';
import TextInput from '../../../../ui/components/Input/TextInput';
import { ModalSelectOption } from '../../../../../../base/components/Editor/interfaces';
import { IGateway, IZone, IObject } from '../../interfaces';
import MultipleSelect from '../../../../ui/components/MultipleSelect/MultipleSelect';
import ConfirmDialog from './../../../../ui/components/Dialog/ConfirmDialog';
import { editorConstants } from '../../constants';

interface IProps {
    onSave?: (model: IZone) => void;
    onCancel?: () => void;
    object?: IZone | null;
    gateways: ModalSelectOption[];
    zones: IObject[];
    objects?: IObject[];
}

interface IState {
    initialValues: IZone;
    edit: boolean;
    dialog: boolean;
}

/**
 * Zone form
 *
 * @class ZoneForm
 */
class ZoneForm extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, object, zones } = this.props;

        this.state = {
            initialValues: {
                ...object,
                name: object?.name || '',
                gateways: object?.gateways ? this.getActiveGateway(object.gateways as string[]) : [],
            },
            edit: !!(object && object.id),
            dialog: false,
        };

        this.validationSchema = yup.object().shape({
            name: yup
                .string()
                .trim()
                .test('unique_name', t('THE_ZONE_NAME_HAS_ALREADY_BEEN_TAKEN'), function(val: string) {
                    return zones && zones.length > 0 ?
                        !zones.filter(z => {
                            return z.name.toLocaleLowerCase() === val?.toLowerCase().trim()
                                && object?.name?.toLocaleLowerCase() !== val?.toLowerCase().trim();
                        }).length : true;
                })
                .required(t('NAME_IS_REQUIRED')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IZone>>;

    /**
     * Get Active gateway array.
     *
     * @param {string[]} data
     *
     * @return {string[]}
     */
    getActiveGateway(data: string[]): string[] {

        const { objects } = this.props;

        return data.map(value => objects?.find(obj => String(obj.value) === value || String(obj.id) === value)?.id) as string[];
    }

    /**
     * Zone form submit handler
     *
     * @param {IZone} values
     */
    handleSubmit(values: IZone) {

        const { onSave, objects } = this.props;

        if (!values.gateways?.length) {

            values.gateways = [];

            if (onSave) {

                onSave(values);
            }
        } else {

            const { gateways } = values;

            const { gateway } = editorConstants.objects;

            if (objects) {

                const usedItems = objects.filter(object => {

                    if (object.type === gateway) {

                        return this.checkGatewayRelations(gateways, object, values);
                    }
                }).length;

                this.setState({ dialog: Boolean(usedItems) });

                if (onSave && !usedItems) {

                    onSave(values);
                }
            }
        }
    }

    /**
     * Check gateway relations method
     * 
     * @param gateways 
     * @param object 
     * @param values 
     */
    checkGatewayRelations(gateways: (string | IGateway)[], object: IObject, values: IZone) {

        if (typeof object.zone === 'object') {

            const objZone = object.zone as IZone;

            return gateways.includes(object.id) && objZone && objZone.id !== values.model_id;
        } else {
            return gateways.includes(object.id) && object.zone && object.zone !== values.id;
        }
    }

    /**
     * Zone form cancel handler
     */
    handleCancel() {

        const { onCancel } = this.props;

        if (onCancel) {

            onCancel();
        }
    }

    /**
     * Confirming a dialog in the case of gateway items relate to the other object
     */
    onConfirmDialog(props: FormikProps<IZone>) {

        const { values } = props;

        const { onSave } = this.props;

        this.setState({ dialog: false });

        if (onSave) {

            onSave(values);

            props.handleReset();
        }
    }

    /**
     * Declining a dialog 
     */
    onDeclineDialog() {
        this.setState({ dialog: false });
    }

    /**
     * Render placeholder to multiselect
     *
     * @param selected
     */
    private renderValue(selected: any) {
        if (!selected || selected.length === 0) {
            return <span className="placeholder" style={{ color: '#adb5bd' }}>{this.props.t('SELECT_CONNECTED_GATEWAYS')}</span>;
        }

        return this.props.gateways.filter(gateway => selected.includes(gateway.value)).map(item => item.label).join(', ');
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t } = this.props,
            { initialValues, edit, dialog } = this.state;

        return (
            <React.Fragment>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <>
                            <form onSubmit={props.handleSubmit} noValidate>
                                <TextInput
                                    className={`form-field ${props.touched.name ? (props.errors.name) ? 'error-field' : 'success-field' : ''}`}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    placeholder={`${t('NAME')}*`}
                                    value={props.values.name}
                                    name="name"
                                    type="text"
                                    inputProps={{ maxLength: 50 }}
                                >
                                    {(props.touched.name && props.errors.name) &&
                                        <div className="validation-massage">{props.errors.name}</div>
                                    }
                                </TextInput>

                                <MultipleSelect
                                    name="gateways"
                                    placeholder={t('SELECT_CONNECTED_GATEWAYS')}
                                    onChange={props.handleChange}
                                    value={props.values.gateways?.length ? (props.values.gateways as string[]) : []}
                                    options={this.props.gateways}
                                    renderValue={(selected) => this.renderValue(selected)}
                                />
                                <div className="button-row">
                                    <Button type="button" color="primary" onClick={this.handleCancel.bind(this)} style={{ marginRight: 30 }}>
                                        {t('CANCEL')}
                                    </Button>
                                    <Button type="submit" color="primary">
                                        {t(edit ? 'EDIT' : 'ADD')}
                                    </Button>
                                </div>
                            </form>
                            <ConfirmDialog
                                heading={t('EDIT_ZONE_Q')}
                                onAccept={() => this.onConfirmDialog(props)}
                                onClose={this.onDeclineDialog.bind(this)}
                                body={t('THIS_GATEWAYS_HAS_ALREADY_BEEN_ASSIGNED')}
                                open={dialog}
                            />
                        </>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

export default withTranslation()(ZoneForm);
