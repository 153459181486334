import { IEditKpiAction, IEditKpiState } from '../../interfaces';
import { kpiConstants } from '../../constants';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function editKpi(state: IEditKpiState = {
    errors: {},
    kpi: null,
    unit: null,
}, action: IEditKpiAction): IEditKpiState {

    switch (action.type) {

        case kpiConstants.STORE_KPI_SUCCESS:

            return {
                kpi: action.kpi,
                unit: action.unit,
                errors: {},
            };

        case kpiConstants.STORE_KPI_FAILURE:

            return {
                ...state,
                errors: action.errors,
            };

        case kpiConstants.CLEAR_KPI_SUCCESS:

            return {
                kpi: null,
                unit: null,
                errors: {},
            };

        default:

            return state;
    }

}