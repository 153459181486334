import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Process from './MonitoringTree/Process';
import {
    IMonitoringTreeProps,
    IDashboardMonitoringTreeState,
    IUser,
    IFlatTreeFactory,
} from '../../../core/interfaces';
import { UnitActions } from '../../../core/actions';
import { RootState } from '../../../core/store';
import { Message } from '../../../core/ui/components';
import { AppRoles } from '../../../rbac/roles';
import { modules } from '../../../modules';

/**
 * Monitoring Tree Component
 *
 * @class MonitoringTree
 */
class MonitoringTree extends React.PureComponent<IMonitoringTreeProps & WithTranslation, IDashboardMonitoringTreeState> {

    /**
     * Constructor
     *
     * @param {IMonitoringTreeProps & WithTranslation} props
     */
    constructor(props: IMonitoringTreeProps & WithTranslation) {

        super(props);

        this.getActiveProductData = this.getActiveProductData.bind(this);
    }

    /**
     * Component state
     */
    readonly state: IDashboardMonitoringTreeState = {
        collapse: {
            process: [],
            factory: [],
            unit: [],
        },
    };

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.props.loadProducts('', {
            column: 'id',
            dir: 'asc',
        }, { table: ['products||id,name,category'] });
    }

    /**
     * Get active product data
     *
     * @param {number} unitId
     * @return {any[]}
     */
    getActiveProductData(unitId: number) {

        return this.props.activeProductData ?
            this.props.activeProductData.get(unitId) : [];
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const {
            maxWidthSideBar = 320,
            visibleSideBar,
            treeData,
            searchField,
            user,
            appSettings,
            graphStructuralTreeVisibility,
            dashboards,
            rbac,
        } = this.props;

        const structureTreeStyle = {
            display: this.props.visibility ? 'block' : 'none',
            height: this.props.scrollHeight,
        };

        return (
            <div className="structure-tree monitoring-mode"
                 ref={this.props.structureTreeRef}
                 style={structureTreeStyle}
            >
                {!appSettings.isConfigured ?
                    <Message
                        message={this.props.t('THE_SYSTEM_IS_NOT_CONFIGURED')}
                        hidden
                    />
                    : null
                }
                {appSettings.isConfigured && dashboards.length === 0 && !appSettings?.hasDashboards  && user?.role !== AppRoles.STANDARD ?
                    <Message
                        message={this.props.t('CREATE_DASHBOARD')}
                        hidden
                    />
                    : null
                }
                {treeData
                    .filter(treeItem => treeItem.type === 'factory')
                    .map((treeItem, treeItemIndex) => (
                        <React.Fragment key={treeItem.id}>
                            {(treeItem as IFlatTreeFactory).data.map((process, index: number) => (
                                <Process
                                    key={process}
                                    processID={process}
                                    factory={treeItem as IFlatTreeFactory}
                                    factoryIndex={treeItemIndex}
                                    index={index}
                                    visibleSideBar={visibleSideBar}
                                    maxWidthSideBar={maxWidthSideBar}
                                    searchField={searchField}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                {
                    modules.map((module, index) => {

                        if (rbac.can('hr:monitoring-tree:manage')) {

                            return module.renderMonitoringTree(
                                'monitoringMode',
                                index,
                                searchField,
                                graphStructuralTreeVisibility,
                            );
                        }
                    })
                }
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { unitActiveProducts, auth, appSetting, graphStructuralTreeVisibility, dashboardSelect, dashboard } = state;
    const { dashboards } = dashboard;

    return {
        activeProductData: unitActiveProducts.activeProductData,
        appSettings: appSetting.settings,
        user: auth.user as IUser,
        graphStructuralTreeVisibility,
        dashboardId: dashboardSelect.selectedDashboard?.id,
        dashboards,
        rbac: auth.rbac,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    loadProducts: UnitActions.listUnitProduct,

});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MonitoringTree));
