import { fabric } from 'fabric';

const Star = fabric.util.createClass(fabric.Object, {

    type: 'star',

    superType: 'drawing',

    initialize(options: any) {

        options.originX = 'center';
        options.originY = 'center';
        options.width = 18;
        options.height = 18;

        this.callSuper('initialize', options || {});
    },

    _render(ctx: CanvasRenderingContext2D) {

        const { strokeColor = '#74797d', fillColor = '#cdd0d4' } = this;

        ctx.fillStyle = fillColor;

        ctx.strokeStyle = strokeColor;

        ctx.beginPath();
        ctx.moveTo(2, -2.3);
        ctx.lineTo(2.1, -2);
        ctx.lineTo(2.4, -2);
        ctx.lineTo(7.7, -1.6);
        ctx.lineTo(3.7, 1.8);
        ctx.lineTo(3.4, 2);
        ctx.lineTo(3.5, 2.3);
        ctx.lineTo(4.8, 7.7);
        ctx.lineTo(0.3, 4.7);
        ctx.lineTo(0, 4.5);
        ctx.lineTo(-0.3, 4.7);
        ctx.lineTo(-4.8, 7.6);
        ctx.lineTo(-3.5, 2.3);
        ctx.lineTo(-3.4, 2);
        ctx.lineTo(-3.7, 1.8);
        ctx.lineTo(-7.7, -1.6);
        ctx.lineTo(-2.4, -2);
        ctx.lineTo(-2.1, -2);
        ctx.lineTo(-2, -2.3);
        ctx.lineTo(0, -7.3);
        ctx.lineTo(2, -2.3);
        ctx.closePath();

        ctx.fill();

        ctx.stroke();

        ctx.restore();
    },
});

Star.fromObject = (options: any, callback: Function) => {

    return callback(new Star(options));
};

// @ts-ignore
window.fabric.Star = Star;

export default Star;
