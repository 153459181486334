import { IDepartment } from '../../interfaces';
import { IErrors } from '../../../../core/interfaces';
import { DepartmentActions, DepartmentActionTypes } from '../actions/departmentActions';

export interface IDepartmentState {
    isLoading: boolean;
    departments: IDepartment[];
    error: IErrors;
}

export const initialState: IDepartmentState = {
    isLoading: false,
    departments: [],
    error: { },
};

export function departmentReducer(state = initialState, action: DepartmentActions): IDepartmentState {

    switch (action.type) {

        case DepartmentActionTypes.FETCH_DEPARTMENTS_REQUEST: {

            return {
                ...state,
                isLoading: true,
                error: {},
            };
        }

        case DepartmentActionTypes.FETCH_DEPARTMENTS_SUCCESS: {

            const { departments } = action.payload;

            return {
                ...state,
                isLoading: false,
                departments,
            };
        }

        case DepartmentActionTypes.FETCH_DEPARTMENTS_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case DepartmentActionTypes.SAVE_DEPARTMENT_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case DepartmentActionTypes.SAVE_DEPARTMENT_SUCCESS: {

            const { department } = action.payload;

            const departments = state.departments.concat([department]);

            return {
                ...state,
                isLoading: false,
                departments,
            };
        }

        case DepartmentActionTypes.UPDATE_DEPARTMENT_SUCCESS: {

            const { department } = action.payload;

            const departments = state.departments.map(value => {

                    if (value.id === department.id) {

                        department.employees = value.employees;

                        return { ...value, ...department };
                    }
                    return value;
                },
            );

            return {
                ...state,
                isLoading: false,
                departments,
            };
        }

        case DepartmentActionTypes.DELETE_EMPLOYEE_IN_DEPARTMENT_ACTION: {
            const { employee } = action.payload;

            const departments = state.departments.map(department => {

                if (department.employees) {

                    department.employees = department.employees.filter(({ id }) => employee.id !== id);

                    return department;
                }

                return department;
            });

            return {
                ...state,
                departments,
            };
        }

        case DepartmentActionTypes.UPDATE_DEPARTMENT_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case DepartmentActionTypes.DELETE_DEPARTMENT_ACTION: {

            const { department } = action.payload;

            const departments = state.departments.filter(({ id }) => department.id !== id);

            return {
                ...state,
                departments,
            };
        }

        case DepartmentActionTypes.CLEAR_DEPARTMENT_ERROR: {

            return {
                ...state,
                error: {},
            };
        }

        default: {
            return state;
        }
    }
}
