import { fabric } from 'fabric';
import { editorConstants } from '../../constants';
import { roundRect } from '../../../../../../core/helpers/roundRect';

/**
 * Marker object definition
 *
 * @type {any}
 */
const PitCodeLabel = fabric.util.createClass(fabric.IText, {

    type: editorConstants.objects.pitCode,

    superType: 'drawing',
    initialize(options: any) {

        options.fontFamily = 'IBM Plex Sans, arial';
        options.fontWeight = 500;
        options.fontSize =  options.fontSize || 12;
        options.fill = '#fff';
        options.fillDataLabel = '#fff';
        options.originX = 'center';
        options.originY = 'center';
        options.dataLabel = true;
        options.dataValue = options.dataValue || 'No data';
        options.objectCaching = false;
        options.ignoreZoom = false;
        options.hasControls = false;
        options.hasBorders = false;
        options.selectable = false;
        options.evented = true;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.hoverCursor = 'default';
        options.height = 20;
        options.sensorType = options.sensorType || '';


        this.callSuper('initialize', options.label, options);

    },
    _render(ctx: CanvasRenderingContext2D) {

        ctx.save();

        ctx.beginPath();
        ctx.textBaseline = 'alphabetic';
        ctx.font = `500 ${this.fontSize}px/${this.fontSize} IBM Plex Sans, arial`;

        const metrics = ctx.measureText(this.label);

        const fontHeight = this.fontSize + 6;
        const fontWidth = (metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight) + 2;

        this.measureWidth = fontWidth;

        this.width = fontWidth;
        // this.height = fontHeight;


        ctx.fillStyle = '#000000';

        roundRect(ctx, (-(fontWidth) / 2) - 3, -(fontHeight / 2), (fontWidth) + 6, fontHeight, 2, true, false);

        ctx.closePath();

        if (this.dataLabel && this.objectType !== 'indicator' && !this.modeEditor) {

            ctx.font = `600 ${this.fontSize}px/${this.fontSize} IBM Plex Sans, arial`;
            const dataText = ctx.measureText(this.dataValue);
            const fontDataWidth = (dataText.actualBoundingBoxLeft + dataText.actualBoundingBoxRight);

            ctx.shadowColor = 'rgba(0,0,0,0.2)';
            ctx.shadowBlur = 6;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;

            ctx.fillStyle = this.fillDataLabel;
            roundRect(ctx, (-(fontDataWidth) / 2) - 3, (fontHeight / 1.5), (fontDataWidth) + 6, fontHeight, 2, true, false);

            ctx.shadowBlur = 0;

            ctx.fillStyle = '#333333';
            ctx.fillText(this.dataValue, -(fontDataWidth) / 2, (fontHeight) + (this.fontSize/2) + 2);
        }

        ctx.restore();

        this.callSuper('_render', ctx);
    },
});

PitCodeLabel.fromObject = (options: any, callback: Function) => {

    return callback(new PitCodeLabel(options));
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
window.fabric.PitCodeLable = PitCodeLabel;

export default PitCodeLabel;
