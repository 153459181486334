import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape } from 'yup';

import Button from '../../../../ui/components/Button/Button';
import TextInput from '../../../../ui/components/Input/TextInput';
import { IBuilding, IObject } from '../../interfaces';


interface IProps {
    onSave?: (model: IBuilding) => void;
    onCancel?: () => void;
    object?: IBuilding | null;
    buildings: IObject[];
}

interface IState {
    initialValues: IBuilding;
    edit: boolean;
}

/**
 * Building form
 *
 * @class BuildingForm
 */
class BuildingForm extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, object, buildings } = this.props;

        this.state = {
            initialValues: {
                ...object,
                name: object?.name || '',
            },
            edit: !!(object && object.id),
        };

        this.validationSchema = yup.object().shape({
            name: yup
                .string()
                .trim()
                .test('unique_name', t('THE_BUILDING_NAME_HAS_ALREADY_BEEN_TAKEN'), function(val: string) {
                    return buildings && buildings.length > 0 ? 
                        !buildings.filter(b => {
                            return b.name.toLocaleLowerCase() === val?.toLowerCase().trim() &&
                                object?.name?.toLocaleLowerCase() !== val.toLocaleLowerCase().trim();
                        }).length : true;               
                })
                .required(t('NAME_IS_REQUIRED')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<Record<string, unknown>, IBuilding>>;

    /**
     * Building form submit handler
     *
     * @param {IBuilding} values
     */
    handleSubmit(values: IBuilding) {

        const { onSave } = this.props;

        if (onSave) {

            onSave(values);
        }
    }

    /**
     * Building form cancel handler
     */
    handleCancel() {

        const { onCancel } = this.props;

        if (onCancel) {

            onCancel();
        }
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t } = this.props,
            { initialValues, edit } = this.state;

        return (
            <React.Fragment>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <TextInput
                                className={
                                    'form-field ' +
                                    (props.touched.name ? (props.errors.name) ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                placeholder={t('NAME')}
                                value={props.values.name}
                                name="name"
                                type="text"
                                inputProps={{ maxLength: 50 }}
                            >
                                {(props.touched.name && props.errors.name) &&
                                    <div className="validation-massage">{props.errors.name}</div>
                                }
                            </TextInput>
                            <div className="button-row">
                                <Button type="button" color="primary" onClick={this.handleCancel} style={{ marginRight: 30 }}>
                                    {t('CANCEL')}
                                </Button>
                                <Button type="submit" color="primary">
                                    {t(edit ? 'EDIT' : 'ADD')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

export default withTranslation()(BuildingForm);