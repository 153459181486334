import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { getId } from '../../helpers/selectorsHelper';

const selectProductStore = (state: RootState) => state.product;

export const selectAllProducts = createSelector(
    selectProductStore,
    product => product.products,
);

export const selectProductByUnitId = createSelector(
    selectAllProducts,
    getId,
    (products, ID) => products.find(product => product.id === ID),
);

