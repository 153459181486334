import { createSelector } from 'reselect';
import { RootState } from '../../store';


const selectGraphSelection =  (state: RootState) => state.graphSelection;

export const selectGraphSelectionAlert = createSelector(
    selectGraphSelection,
    (graphSelection) => graphSelection.alert,
);

export const selectGraphSelectionAlertHover = createSelector(
    selectGraphSelection,
    (graphSelection) => graphSelection.alertHover,
);

export const selectGraphSelectionPosition = createSelector(
    selectGraphSelection,
    (graphSelection) => graphSelection.position,
);

export const selectGraphSelectionPositionHover = createSelector(
    selectGraphSelection,
    (graphSelection) => graphSelection.positionHover,
);
