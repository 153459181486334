import React, { useCallback } from 'react';
import { isIE } from 'react-device-detect';
import { ILayer, ILocation } from '../../../interfaces';
import { ReactComponent as LayerIcon } from '../../../ui/assets/images/icons/layer-mbri.svg';
import { MenuOptions } from '../MenuOptions/MenuOptions';
import { useMenuOption } from '../MenuOptions/useMenuOption';

import './Layer.scss';

/**
 * A HR layer in structural tree
 *
 * @param {IProps} props
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Layer: React.FC<IProps> = (props: IProps) => {
    const {
        location,
        layer,
        padding, 
        scrollLeft, 
        onOpen, 
        onDelete, 
    } = props;

    const onEdit = useCallback(() => {
        onOpen(location, layer);
    }, [onOpen, location, layer]);

    const onDeleteCallback = useCallback(() => {
        onDelete(layer);
    }, [onDelete, layer]);

    const menuNode = useMenuOption({
        onEdit,
        onDelete: onDeleteCallback, 
    });

    return (
        <div className="item-wrap">
            <div
                className={`left-section-hr ${padding}`}
                style={{
                    position: isIE ? 'relative' : 'sticky',
                    left: isIE ? scrollLeft : 0,
                }}
            >
                <LayerIcon style={{ height: 20, width: 20 }} />
                {layer.name}
                <MenuOptions
                    menuNode={menuNode}
                />
            </div>
            <div className="right-section-hr" />
        </div>
    );
};

export default Layer;

interface IProps {
    location: ILocation;
    layer: ILayer;
    padding: 'padding-1' | 'padding-2';
    scrollLeft: number;
    onOpen: (location: ILocation, layer: ILayer) => void;    
    onDelete: (layer: ILayer) => void;
}
