import React, { useCallback } from 'react';
import { ListItem } from '@material-ui/core';
import { IStateFormItemProps } from '../../../../core/interfaces';

const StateItem: React.FC<IStateFormItemProps> = ({ stateItem, values, stateCategory, onClickHandler }: IStateFormItemProps) => {

    /**
     * Callback on Click
     *
     * @type {() => void}
     */
    const onClickCallback = useCallback(() => {

        onClickHandler(
            {
                causeName: stateItem.name,
                causeId: Number(stateItem.id),
                stateCategoryId: Number(stateCategory.id),
                comment: values.comment,
            },
        );

    }, [onClickHandler, stateItem, stateCategory, values]);

    return (
        <ListItem 
            key={stateItem.id}
            className={values.cause && (values.cause === stateItem.name) ? 'selected' : ''}
            onClick={onClickCallback}
        >
            <span
                className="category-sub-name"
            >
                {stateItem.color && 
                    <div
                        className="color-circle-sub-name"
                        style={{
                            backgroundColor: stateItem.color,
                            border: `2px solid ${stateItem?.frameColor || 'transparent'}`,
                        }}
                    />
                }
                {stateItem.name}
            </span>
        </ListItem>
    );
};

export default StateItem;
