import React, { Component } from 'react';
import { Button, TextInput, ConfirmDialog, InfoModal } from '../../../core/ui/components';
import { Formik } from 'formik';
import './FormNode.scss';
import {
    IData,
    IError,
    IFlatTreeFactory,
    IFlatTreeProcess,
    IOrder,
    IProcess,
    IUnit,
    IHmiObjectTree,
} from '../../../core/interfaces';
import * as yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ReactComponent as ProcessIcon } from '../../../core/ui/assets/images/icons/folder-process.svg';
import { connect } from 'react-redux';
import {
    FactoryActions,
    FormActions,
    ProcessActions,
    HmiObjectTreeAction,
} from '../../../core/actions';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';
import { RootState } from '../../../core/store';
import FormSelect from './FormSelect/FormSelect';
import {
    selectConfigurationTreeFactory,
    selectConfigurationTreeProcess,
} from '../../../core/selectors/configurationTree/configurationTreeCollectionSelector';


/**
 * default props and state
 */

interface IOptions {
    label: string;
    value: string;
}

interface IFormValues {
    id?: number;
    factories: number | string;
    process: number | string;
    name: string;
    comment: string;
}

interface IProps {
    label?: string;
    loadFactory?: (search?: string, order?: IOrder) => void;
    loadProcess?: (search?: string, order?: IOrder) => void;
    factories: IData[];
    processes: IData[];
    processesTree: IFlatTreeProcess[];
    activeFactory?: IFlatTreeFactory[];
    processesData: IData | null | undefined;
    errors?: IError;
    errorsProcess?: IError;
    i18n?: WithTranslation;
    tReady?: WithTranslation;
    t?: WithTranslation;
    openSidebar: boolean;
    toggleForm: (opened: boolean, name?: string) => void;
    updateProcess: (data: IProcess[], updateConfig?: boolean, refreshTree?: boolean) => void;
    elementInTree?: {
        process: IProcess[];
        unit: IUnit[];
    };
    clearErrorChange: () => void;
    updateHmiObject: (data: IHmiObjectTree, type: string) => void;
}

interface IState {
    initialValues: IFormValues;
    enableReinitialize: boolean;
    selectedValue: string;
    optionsFactory: IOptions[];
    optionsProcess: IOptions[];
    currentProcess: number[];
    confirm: boolean;
    alert: boolean;
    closeFormAwaitLogic: boolean;
}

/**
 * FormProcess
 *
 * @class FormProcess
 */
class FormProcess extends Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t } = this.props;

        this.validationSchema = yup.object().shape({
            factories: yup.string().required(t('FACTORY_IS_REQUIRED')),
            process: yup.string().required(t('PROCESS_IS_REQUIRED')),
            name: yup.string()
                .trim()
                .max(30, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '30' }))
                .required(t('PROCESS_NAME_IS_REQUIRED')),
            comment: yup.string()
                .max(300, t('MAX_COMMENT_COMMENT')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.chooseFactory = this.chooseFactory.bind(this);

        this.handleChangeSelect = this.handleChangeSelect.bind(this);

        this.closeSidebar = this.closeSidebar.bind(this);

        this.closeFormAwait = this.closeFormAwait.bind(this);
    }

    /**
     * Current state
     */
    readonly state: IState = {
        initialValues: {
            factories: this.props.processesData?.parent.id || '',
            process: this.props.processesData?.current.id || '',
            comment: this.props.processesData?.current.comment || '',
            name: this.props.processesData?.current.name || '',
        },
        enableReinitialize: true,
        selectedValue: '',
        optionsFactory: [],
        optionsProcess: [],
        confirm: false,
        alert: false,
        currentProcess: [],
        closeFormAwaitLogic: false,
    };

    /**
     *
     * Callback after render the component to the DOM
     */
    componentDidMount() {
        const { loadFactory, loadProcess } = this.props;

        if (loadFactory && loadProcess) {

            loadProcess();

            loadFactory();
        }
    }

    /**
     * Component props update handler
     *
     * @param {Readonly<IProps & WithTranslation} prevProps Updated component properties
     */
    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>) {

        if (prevProps.factories !== this.props.factories || prevProps.processes !== this.props.processes) {

            this.updateOptions();

        }
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, { process: string; name: string; factories: string; comment: string }>>;


    /**
     *  Handler submit form
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        const { currentProcess } = this.state;

        if (values.factories &&
            values.name &&
            values.process &&
            (!currentProcess.find(value => value === values.process) || this.props.processesData?.current)) {

            this.setState({ confirm: true, initialValues: values });

        } else {

            this.setState({ alert: true, initialValues: values });

        }
    }

    /**
     * Handler for the cancel form dialog
     * Reset field form
     */
    handleCancel() {

        this.setState({ confirm: false, alert: false });
    }

    /**
     * Handler cancel dialog form
     */
    onConfirmDialog() {

        const { initialValues } = this.state,
            { updateProcess, processesData, toggleForm, processesTree, updateHmiObject } = this.props;

        const processTreeItem = processesTree.find(process => process.id === initialValues.process);

        if (processTreeItem && processTreeItem.data.length) {

            const data = {
                fabric: initialValues.factories as number,
                unit: null,
                units: processTreeItem.data,
            };

            updateHmiObject(data, 'process');
        }

        updateProcess([{
            id: parseInt(String(initialValues.process)),
            comment: initialValues.comment,
            name: initialValues.name,
            factory: initialValues.factories ? initialValues.factories as number : null,
        }],
            true,
            processesData?.parent.id !== initialValues.process,
        );

        this.closeFormAwait();
    }

    /**
     * Clear error in form
     */
    closeFormAwait() {
        const { toggleForm } = this.props;

        const setTimeoutCallback = setTimeout(() => {

            if ((this.props.errors && 'message' in this.props.errors) ||
                (this.props.errorsProcess && 'message' in this.props.errorsProcess)) {

                return;
            }

            toggleForm(true, '');

            this.setState({ closeFormAwaitLogic: false });

            clearTimeout(setTimeoutCallback);
        }, 150);

    }


    updateOptions() {
        const optionsFactoryPros: IOptions[] = [],
            optionsProcessProps: IOptions[] = [],
            { activeFactory = [] } = this.props,
            processInFactory: number[] = [];

        const factoryInTree = activeFactory.filter(value => value.type === 'factory');

        if (factoryInTree.length > 0) {

            factoryInTree.forEach((value) => {

                processInFactory.push(...value.data);

                optionsFactoryPros.push({
                    label: value.name.toString(),
                    value: String(value.id),
                });
            });
        }

        if (this.props.factories && factoryInTree.length === 0) {

            this.props.factories.forEach((value) => {

                processInFactory.push(...value.data);

                optionsFactoryPros.push({
                    label: value.name.toString(),
                    value: value.id,
                });

            });
        }

        if (this.props.processes) {
            this.props.processes.forEach((value) => {

                optionsProcessProps.push({
                    label: value.name.toString(),
                    value: value.id,
                });

            });
        }

        this.setState({
            optionsProcess: optionsProcessProps,
            optionsFactory: optionsFactoryPros,
            currentProcess: processInFactory,
        });
    }

    /**
     *  Handler Change select
     *  re-init  initialValues with choose param
     *
     * @param {React.ChangeEvent} event
     */
    handleChangeSelect(event: React.ChangeEvent<{ name?: string; value: unknown }>) {

        const { initialValues } = this.state;

        const fieldData = this.props.processes.filter(value => value.id === parseInt(String(event.target.value)));

        initialValues['process'] = event.target.value as number;
        initialValues['name'] = String(fieldData[0].name.toString());
        initialValues['comment'] = String((fieldData[0].comment || '').toString());
        initialValues['id'] = parseInt(String(event.target.value));

        this.setState({ initialValues: initialValues });

    }

    chooseFactory(event: React.ChangeEvent<{ name?: string; value: unknown }>) {

        const { initialValues } = this.state;

        initialValues.factories = event.target.value as number;

        this.setState({ initialValues: initialValues });
    }


    /**
     * Sidebar close handler
     */
    closeSidebar(): void {

        this.props.toggleForm(this.props.openSidebar);

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { errors = {}, t, clearErrorChange } = this.props;

        return (
            <React.Fragment>
                <div className="form-box form-box-space node-form">
                    <Formik
                        initialValues={this.state.initialValues}
                        enableReinitialize
                        validationSchema={this.validationSchema}
                        onSubmit={this.handleSubmit}
                    >{props => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <div className="section wrap-form-node">
                                <div className="table-header">
                                    <div
                                        className={'title'}
                                    >{this.props.processesData ? this.props.t('CHANGE_PROCESS') : this.props.t('ADD_PROCESS')}
                                    </div>
                                    <ProcessIcon className={'title-icon'} />
                                </div>
                                {errors && errors.field === 'name' ?
                                    <div className="common-error">{t('THE_PROCESS_HAS_ALREADY_BEEN_TAKEN')}</div>
                                    : null
                                }
                                <div className="table-body">
                                    <div className="form-group select-unit select">
                                        <FormSelect
                                            formProps={props}
                                            handleChangeSelect={this.handleChangeSelect}
                                            options={this.state.optionsProcess}
                                            disabledSelect={Boolean(this.props.processesData)}
                                            name={'process'}
                                            placeholder={this.props.t('SELECT_PROCESS')}
                                            label={this.props.t('SELECT_AN_AVAILABLE_PROCESS')}
                                            reset
                                        />
                                    </div>
                                    <div className="form-group name">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.name || errors.field === 'name' ? props.errors.name || errors.field === 'name' ? 'error-field' : 'success-field' : '')
                                            }
                                            label={this.props.t('CHANGE_NAME')}
                                            onChange={event => {
                                                props.handleChange(event);
                                                clearErrorChange();
                                            }}
                                            onBlur={props.handleBlur}
                                            value={props.values.name}
                                            name="name"
                                            type="text"
                                            placeholder={this.props.t('ENTER_NAME')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {(props.touched.name && props.errors.name) ?
                                                <div
                                                    className="validation-massage"
                                                >
                                                    {props.errors.name}
                                                </div>
                                                : null
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group comment">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.comment ? props.errors.comment ? 'error-field' : 'success-field' : '')
                                            }
                                            label={this.props.t('COMMENT')}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.comment}
                                            name="comment"
                                            type="text"
                                            placeholder={this.props.t('ENTER_COMMENT')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {((props.touched.comment && props.errors.comment)) &&
                                                <div
                                                    className="validation-massage"
                                                >{props.errors.comment}
                                                </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group select-unit select">
                                        <FormSelect
                                            formProps={props}
                                            handleChangeSelect={this.chooseFactory}
                                            options={this.state.optionsFactory}
                                            disabledSelect={false}
                                            name={'factories'}
                                            placeholder={this.props.t('SELECT_FACTORY')}
                                            label={this.props.t('SELECT_PARENT_FACTORY')}
                                            reset={false}
                                        />
                                    </div>
                                    <div className="form-group btn-group">

                                        <Button
                                            type="reset"
                                            color={'primary'}
                                            onClick={this.closeSidebar}
                                        >{this.props.t('CANCEL')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color={'secondary'}
                                        >{this.props.processesData ? this.props.t('SAVE_CHANGES') : this.props.t('ADD')}
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    )}
                    </Formik>
                </div>
                <ConfirmDialog
                    heading={this.props.processesData ? this.props.t('CHANGE_PROCESS_Q') : this.props.t('ADD_PROCESS_Q')}
                    onAccept={this.onConfirmDialog}
                    onClose={this.handleCancel}
                    open={this.state.confirm}
                />
                <InfoModal
                    onClose={this.handleCancel}
                    open={Boolean(this.state.alert)}
                >
                    {this.props.t('THE_CURRENT_PROCESS_IS_SET_ONE_OF_THE_FACTORY')}
                </InfoModal>
            </React.Fragment>
        );
    }
}


/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */

const mapStateToProps = (state: RootState) => {

    const { factories } = state.factory,
        { processes } = state.process,
        { formModel } = state.form;

    const activeFactory = selectConfigurationTreeFactory(state) as IFlatTreeFactory[];

    const processesTree = selectConfigurationTreeProcess(state) as IFlatTreeProcess[];

    return {
        factories,
        errorsFactories: state.factory.errors,
        processes,
        processesTree,
        errorsProcess: state.process.errors,
        errors: state.processChange.errors,
        // errors: { ...state.processChange.errors, ...state.process.errors },
        activeFactory,
        processesData: formModel || null,
    };
};


/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    loadFactory: FactoryActions.list,
    loadProcess: ProcessActions.list,
    updateProcess: ProcessActions.bulkUpdate,
    clearErrorChange: ProcessActions.clearErrorChange,
    toggleForm: FormActions.toggle,
    updateHmiObject: HmiObjectTreeAction.update,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormProcess));