import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, FileUpload, TextInput } from '../../../../../../../core/ui/components';
import { IGpsPin, IGpsPinIdent, ILocation, IMeasure, IPinCoordinates } from '../../../../../interfaces';
import { FormikProps } from 'formik';
import { readFile } from '../../../../../../../base/helpers/readFile';
import { SvgPreview } from '../SvgPreview/SvgPreview';
import styles from './LocationForm.module.scss';
import { AddGpsButton } from '../AddGpsButton/AddGpsButton';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
    selectLocationFormEditorMode, selectLocationFormPinByKey,
    selectLocationFormPins,
} from '../../../../../store/selectors/locationFormSelector';
import { locationThunks } from '../../../../../store/thunks/locationThunks';
import clsx from 'clsx';
import { isSvgFile } from '../../../../../utils/isSvgFile';
import { YellowMessage } from '../../../../../ui/components/YellowMessage';

const LocationForm: React.FC<IProps> = (props: IProps) => {
    const { locationData, onCancel, formikProps, onClickAddGpsButton, onOpenAddGpsModal } = props;
    const { t } = useTranslation();

    const pictureContent = locationData?.pictureContent;

    const [svgLocationContent, setSvgLocation] = useState<string>(pictureContent || '');

    const [angle, setAngle] = useState<number>(locationData?.angle || 0);

    const [measure, setMeasure] = useState<IMeasure>(locationData?.measure || { scaleX: 1, scaleY: 1 });

    useEffect(() => {
        if (pictureContent) {
            setSvgLocation(pictureContent);
        }
    }, [pictureContent]);

    const onSVGFileChange = useCallback(async(file: File) => {
        if (!isSvgFile(file)) {
            setSvgLocation('');
            formikProps.setFieldValue('picture', '');
            formikProps.setTouched({ picture: true });
            return;
        }

        const svgFileContent = await readFile(file);

        setSvgLocation(svgFileContent);

        formikProps.setFieldValue('picture', file);
    }, [formikProps]);

    const editorMode = useSelector(selectLocationFormEditorMode);
    const pins = useSelector(selectLocationFormPins);

    const dispatch = useDispatch();
    const store = useStore();
    const { hr } = store.getState();

    const onOpenPin = useCallback((key: IGpsPinIdent) => {
        const state = store.getState();
        const pin = selectLocationFormPinByKey(state, { key });

        onOpenAddGpsModal(pin);
    }, [onOpenAddGpsModal, store]);

    const onAddPin = useCallback((key: IGpsPinIdent, model: IPinCoordinates) => {
        dispatch(locationThunks.savePin(key, model));

        onOpenPin(key);
    }, [dispatch, onOpenPin]);

    const onScale = useCallback((scale: number) => {
        formikProps.setFieldValue('scale', scale);
    }, [formikProps]);

    const onRotating = useCallback((angle) => {
        formikProps.setFieldValue('angle', angle);
        setAngle(angle);
    }, [formikProps]);

    const onChangeMeasure = useCallback((measure: IMeasure) => {
        formikProps.setFieldValue('measure', measure);

        setMeasure(measure);
    }, [formikProps]);

    useEffect(() => {

        const { locationFormState } = hr;

        if (locationFormState?.error?.message.name && !formikProps.errors.name) {
            formikProps.setFieldError('name', t(locationFormState.error.message.name.key));
        }
    });

    useEffect(() => {

        const { angle, measure } = formikProps.values;

        const value = angle >= 0 && angle <= 360 ? angle : 0;

        setAngle(value);

        setMeasure(measure);

    }, [formikProps]);

    const onChangeName = useCallback((e: React.ChangeEvent) => {

        if (hr?.locationFormState?.error) {
            dispatch(locationThunks.clearError());
        }

        formikProps.handleChange(e);
    }, [formikProps, dispatch, hr]);

    return (
        <form onSubmit={formikProps.handleSubmit} noValidate className={styles.form}>
            <div className="section wrap-form-node">
                <div className="table-header">
                    <div className="title">
                        {locationData ? t('EDIT_LOCATION') : t('ADD_LOCATION')}
                    </div>
                </div>
                <div className={clsx(
                    'table-body',
                    styles.tableBody,
                )}
                >
                    <YellowMessage
                        message={t('UPLOAD_THE_PLANT_TERRITORY_WITH_BUILDINGS')}
                    />
                    <div className={clsx(
                        'form-group',
                        styles.nameRow,
                    )}
                    >
                        <TextInput
                            className={clsx(
                                'form-field',
                                styles.nameInput,
                                formikProps.touched.name ? formikProps.errors.name ? 'error-field' : 'success-field' : '',
                            )}
                            onChange={onChangeName}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.name}
                            name="name"
                            type="text"
                            placeholder={t('LOCATION_NAME')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            {((formikProps.touched.name && formikProps.errors.name)) &&
                                <div
                                    className="validation-massage"
                                >
                                    {formikProps.errors.name}
                                </div>
                            }
                        </TextInput>
                        {
                            svgLocationContent &&
                            <AddGpsButton
                                onClick={onClickAddGpsButton}
                                title={t('ADD_GPS_PIN')}
                                editorMode={editorMode}
                            />
                        }
                    </div>
                    {
                        svgLocationContent && (
                            <div className={styles.svgContent}>
                                <SvgPreview
                                    pins={pins}
                                    svg={svgLocationContent}
                                    scale={locationData?.scale}
                                    addPin={onAddPin}
                                    openPin={onOpenPin}
                                    editorMode={editorMode}
                                    onScale={onScale}
                                    onRotating={onRotating}
                                    onChangeMeasure={onChangeMeasure}
                                    meterWidth={locationData?.width || 10}
                                    meterHeight={locationData?.height || 10}
                                    planAngle={angle || 0}
                                    measure={measure}
                                />
                            </div>
                        )
                    }
                    <div className="form-group">
                        <TextInput
                            className={clsx(
                                'form-field',
                                styles.nameInput,
                                formikProps.touched.angle ? formikProps.errors.angle ? 'error-field' : 'success-field' : '',
                            )}
                            onChange={onChangeName}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.angle}
                            name="angle"
                            type="text"
                            label={t('PLAN_ANGLE')}
                            placeholder={t('PLAN_ANGLE')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            {((formikProps.touched.angle && formikProps.errors.angle)) &&
                                <div
                                    className="validation-massage"
                                >
                                    {formikProps.errors.angle}
                                </div>
                            }
                        </TextInput>
                    </div>

                    {
                        !svgLocationContent &&
                        <div className="form-field">
                            <FileUpload
                                id="select-location-image"
                                name={t('SELECT_SVG_IMAGE')}
                                onFileUpload={onSVGFileChange}
                            />
                            {((formikProps.touched.picture && formikProps.errors.picture)) &&
                                <div
                                    className="validation-massage"
                                >
                                    {formikProps.errors.picture}
                                </div>
                            }
                        </div>
                    }

                    <div className={styles.formButtonRow}>
                        {
                            svgLocationContent &&
                            <FileUpload
                                id="select-different-location-image"
                                name={t('CHOOSE_DIFFERENT_IMAGE')}
                                onFileUpload={onSVGFileChange}
                            />
                        }

                        <div className="form-group btn-group">
                            <Button
                                type="reset"
                                color="primary"
                                onClick={onCancel}
                            >
                                {t('CANCEL')}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                onClick={formikProps.submitForm}
                            >
                                {locationData ? t('SAVE_CHANGES') : t('NEXT')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

/**
 * default props and state
 */
interface IProps {
    locationData: ILocation | null;
    onCancel: () => void;
    formikProps: FormikProps<IFormValues>;
    onClickAddGpsButton: () => void;
    onOpenAddGpsModal: (pin: IGpsPin) => void;
}

export interface IFormValues {
    id?: number;
    name: string;
    picture: string;
    scale: number;
    angle: number;
    measure: IMeasure;
}

export { LocationForm };