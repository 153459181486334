import { exportDataConstants } from '../../constants';
import { IExportDataAction, IExportDataState } from '../../interfaces';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export const exportData = (state: IExportDataState = {
    loading: false,
    errors: {},
}, action: IExportDataAction): IExportDataState => {

    switch (action.type) {

        case exportDataConstants.EXPORT_ALL_DATA_STATE_SUCCESS:
        case exportDataConstants.EXPORT_DATA_STATE_SUCCESS:
        case exportDataConstants.EXPORT_ALL_DATA_STATE_FAILURE:
        case exportDataConstants.EXPORT_DATA_STATE_FAILURE:

            return {
                loading: true,
                errors: action.errors? action.errors : {},
            };

        case exportDataConstants.EXPORT_DATA_CLOSE_NOTIFICATION:

            return {
                loading: false,
                errors: {},
            };

        default:

            return state;
    }

};