import React from 'react';
import { Editor } from '@tinymce/tinymce-react/lib/cjs/main/ts';
import './EditorKpi.scss';
import { WithTranslation, withTranslation } from 'react-i18next';

/**
 * EditorKpi component
 *
 * @param {function<e: any>} onchange
 * @param {any} value
 * @param {Object<{id: number, name: string}>[]} availableVariables
 * @constructor
 *
 * @return {JSX.Element}
 */
class EditorKpi extends React.Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {
        super(props);

        this.state = {
            keyCode: ['32', '8', '97', '98', '99', '100', '101', '102', '103', '104', '105', '49', '50', '51', '52', '53', '54', '55', '56', '57', '48', '96', '110', '37', '38', '39', '40'],
            logic: {
                average: false,
                max: false,
                min: false,
            },
        };

        this.editor = null;

        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    private editor: any;

    /**
     *  Convert dragged item in Editor
     *
     *
     * @return {HTMLElementEventMap}
     * @param event
     */
    convertDraggedItem(event: any) {

        if (event.dataTransfer) {

            event.preventDefault();

            const currentId = new URL(event.dataTransfer.getData('text')).pathname.replace('/', '');

            const variables = this.props.availableVariables.filter(value1 => value1.id === parseInt(currentId));

            const content = '<span class=\'formula-item mceNonEditable\' id=' + variables[0].id + ' >' + variables[0].name.replace(' ', '_') + '</span>';

            this.editor.editor.insertContent(content + '&nbsp;');
        }
    }

    /**
     * handleEditorChange
     *
     * @param {any<Event>>} content
     */
    handleEditorChange(content: any) {

        this.props.onchange({ content: content.target.getContent(), logic: this.state.logic });

    }

    /**
     *  Check Change status element
     *
     * @param {string} element
     * @param {boolean} status
     */
    checker(element: string, status: boolean) {

        const newLogic: Record<string, unknown> = this.state.logic;

        newLogic[element] = status;

    }

    keyDown(event: KeyboardEvent) {

        if (this.state.keyCode.indexOf(String(event.keyCode)) < 0) {

            event.preventDefault();

        }
    }


    render() {
        const { t } = this.props;

        const configEditor = {
            height: 204,
            menubar: false,
            setup: (editor: any) => {

                editor.ui.registry.addButton('plus', {
                    text: '+',
                    classes: 'myClass',
                    onAction: () => {

                        editor.insertContent('<span class="logic-item square mceNonEditable"> +</span> &nbsp;');

                    },
                });
                editor.ui.registry.addButton('minus', {
                    text: '-',
                    onAction: () => {

                        editor.insertContent('<span class="logic-item square mceNonEditable"> -</span> &nbsp;');

                    },
                });
                editor.ui.registry.addButton('multiply', {
                    text: '\t&#215;',
                    onAction: () => {

                        editor.insertContent('<span class="logic-item square mceNonEditable"> \t&#215; </span> &nbsp;');

                    },
                });
                editor.ui.registry.addButton('split_up', {
                    text: '&#247;',
                    onAction: () => {

                        editor.insertContent('<span class="logic-item square mceNonEditable"> &#247; </span> &nbsp;');

                    },
                });
                editor.ui.registry.addButton('right_bracket', {
                    text: ')',
                    onAction: () => {

                        editor.insertContent('<span class="logic-item square mceNonEditable">) </span> &nbsp;');

                    },
                });
                editor.ui.registry.addButton('left_bracket', {
                    text: '(',
                    onAction: () => {

                        editor.insertContent('<span class="logic-item square mceNonEditable"> (</span> &nbsp;');

                    },
                });
                editor.ui.registry.addToggleButton('average', {
                    text: t('AVERAGE'),
                    onAction: (api: any) => {

                        this.checker('average', !api.isActive());

                        api.setActive(!api.isActive());
                    },
                });
                editor.ui.registry.addToggleButton('min', {
                    text: t('MIN'),
                    onAction: (api: any) => {

                        this.checker('min', !api.isActive());

                        api.setActive(!api.isActive());

                    },
                });
                editor.ui.registry.addToggleButton('max', {
                    text: t('MAX'),
                    onAction: (api: any) => {

                        this.checker('max', !api.isActive());

                        api.setActive(!api.isActive());

                    },
                });
                editor.on('drop', this.convertDraggedItem.bind(this));
                editor.on('keydown', this.keyDown.bind(this));
            },
            plugins: 'noneditable ',
            paste_as_text: true,
            content_style: '.formula-item {outline: none !important; overflow-wrap: normal; user-select: none; background-color: #4092f5; font-size: 14px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: 24px; letter-spacing: normal;  text-align: center;  color: #ffffff; padding: 0 11px; border-radius: 12px}' +
                '.mce-content-body {min-height: 130px;}' +
                '.mce-drag-container>span {float:left; left: 0; word-break: break-all;}.mce-drag-container {display:flex;text-align: start; word-break: normal; justify-content: flex-start;}',
            paste_data_images: false,
            toolbar:
                ' plus minus multiply split_up left_bracket right_bracket average min max ',
        };
        return (

            <div className={'editor-kpi'}>
                <Editor
                    apiKey={'daqfjjtiv7wz8lwyc0laqycuwyqm9ro3qfzoxgo2puj2j6ga'}
                    ref={event => this.editor = event}
                    value={this.props.value}
                    init={configEditor}
                    onChange={this.handleEditorChange}
                />
            </div>
        );
    }

}

export default withTranslation()(EditorKpi);


/**
 * init default interface
 */

interface IProps {
    value: any,
    onchange: (e: any) => void,
    availableVariables: {
        id: number,
        name: string
    }[]
}

interface IState {
    keyCode: string[];
    logic: {
        average: boolean,
        max: boolean,
        min: boolean
    },
}