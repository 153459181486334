/**
 * Get vh value 
 * 
 * @param {number} v
 * @returns
 */
export const getVh = (v: number): number => {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
};

/**
 * Get vw value
 * 
 * @param {number} v 
 * @returns
 */
export const getVw = (v: number): number => {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
};