import React, { FC, useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IHmiObject, IHmiSchema } from '../../../core/interfaces';
import { Canvas } from '../../ConfigurationTree/HMI/Editor/canvas/Canvas';
import CanvasObject from '../../ConfigurationTree/HMI/Editor/canvas/CanvasObject';
import { addObjectByType } from '../../ConfigurationTree/HMI/Editor/functions/addObjectByType';
import { appConfig } from '../../../config/appConfig';
import { selectAllSensorInTree } from '../../../core/selectors/monitoringTree/minimapGetActiveSensorInTreeSelector';
import {
    selectIsFullScreenDrawer, selectPositionDrawer,
} from '../../../core/selectors/layout/responsiveDrawerSelector';
import {
    selectOpacityScheme,
    selectVisibilityObjectOnScheme,
    selectVisibilitySchemeOnScheme,
} from '../../../core/selectors/hmi/playerSelector';
import { FabricObject } from '../../../base/components/Editor/interfaces';
import { HmiPlayerActions } from '../../../core/actions';
import { selectDashboardOnline } from '../../../core/selectors/dashboard/dashboardSelector';
import { selectBrushSelection } from '../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { useHmiSensorsData } from '../../../hooks/hmi/useHmiSensorsData';
import { LeftPanel } from '../../../base/components/Editor/panels/LeftPanel';
import { HmiObjectAction } from '../../../core/actions/hmiObjectAction';
import { usePrevious } from '../../../hooks/usePrevious';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { maxString } from '../../../core/helpers/fittingStringHelper';
import { selectHmiHoverSensor } from '../../../core/selectors/hmi/playerHoverItem';
let hoverFabricObject: FabricObject<import('fabric/fabric-impl').Object> | undefined = undefined;

interface IProps {
    schema: IHmiSchema | null;
    workareaWidth: number;
    workareaHeight: number;
}

/**
 * HMI Player Schema component
 *
 * @param {IHmiSchema} schema
 * @param {number} workareaWidth
 * @param {number} workareaHeight
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Schema: FC<IProps> = ({ schema, workareaWidth, workareaHeight }: IProps) => {

    const dispatch = useDispatch();

    const canvasRef = useRef<Canvas | null>(null);
    const allSensorInTree = useSelector(selectAllSensorInTree);
    const showObjects = useSelector(selectVisibilityObjectOnScheme);
    const showHmiMap = useSelector(selectVisibilitySchemeOnScheme);
    const selection = useSelector(selectBrushSelection);
    const isFullScreen = useSelector(selectIsFullScreenDrawer);
    const dashboardRealTime = useSelector(selectDashboardOnline);
    const schemeOpacity = useSelector(selectOpacityScheme);
    const anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom';
    const prewSchema = usePrevious(schema);

    const [src, setSrc] = useState<string | null>(null);
    const [width, setWidth] = useState<number>(970);
    const [height, setHeight] = useState<number>(774);
    const [zoomRatio, setZoomRatio] = useState<number>(1);
    const dedicatedSensor = useSelector(selectHmiHoverSensor);

   useHmiSensorsData(canvasRef.current);

    useEffect(() => {

        if (schema && schema !== prewSchema) {

            const schemaImage = new Image();

            schemaImage.onload = () => {
                setWidth(schemaImage.width);
                setHeight(schemaImage.height);
                setSrc(appConfig.hmiApiEndpoint + schema.picture);

            };

            schemaImage.src = appConfig.hmiApiEndpoint + schema.picture;

            dispatch(HmiPlayerActions.setOpacity(schema.opacity));
            dispatch(HmiPlayerActions.setRealTime(isMobile ? false : dashboardRealTime));
            dispatch(HmiPlayerActions.setValue(selection[1].getTime()));

            if (!isMobile) {

                dispatch(HmiPlayerActions.play());
            }
        }

        return () => {
            dispatch(HmiObjectAction.hoverItemOnMap(undefined));

            dispatch(HmiObjectAction.hoverSensorOnTree(undefined));
        };

    }, [schema, prewSchema,  dispatch]);

    useEffect(() => {
        hoverFabricObject = undefined;
    }, [dedicatedSensor]);



    useEffect(() => {

        const { current } = canvasRef;

        if (current && schema) {
            const { hmiObjects = [] } = schema;


            for (const object of hmiObjects
                .sort((a, b) => a.zIndex < b.zIndex ? -1 : 1)) {

                const sensor = object.sensorId !== null ? allSensorInTree.get(object.sensorId): null;

                const dataValue = sensor && sensor?.latestValue ? (Number(sensor.latestValue).toFixed(2)).toString().length >= 10 ?
                    maxString((Number(sensor.latestValue).toFixed(2)).toString(), 10)
                    :
                        sensor.latestValue.toFixed(2)
                    + ' ' + sensor.um : 'No data';

                addObjectByType(
                    current,
                    object,
                    sensor?.pIdCode || sensor?.name || 'No sensor', schema?.fontSize || 12,
                    Boolean(parseInt(schema.showObjects)),
                    dataValue,
                );

            }

            if (schema.scale !== null) {

                current.handler.zoomHandler.zoomToFit();
                current.handler.zoomHandler.zoomToValue(schema.scale);
            }

            current.canvas.forEachObject(obj=> {

                obj.set('dirty', true);

                obj.set('selectable', false);

            }).requestRenderAll();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef, schema, src]);


    useEffect(() => {

        if (canvasRef && canvasRef.current?.canvas && src && workareaHeight && workareaWidth) {

            canvasRef.current.canvas.setHeight(workareaHeight);
            canvasRef.current.canvas.setWidth(workareaWidth);

            // temporary disabled;
            // canvasRef.current.handler.zoomHandler.zoomAndCenter(canvasRef.current.canvas.getZoom());
            if (isMobileOnly) {

                canvasRef.current.handler.zoomHandler.zoomAndCenter(canvasRef.current.canvas.getZoom());
            } else {
                canvasRef.current.handler.zoomHandler.zoomToValue(canvasRef.current.canvas.getZoom());
            }

        }

    }, [workareaWidth, workareaHeight, canvasRef, src, isFullScreen]);

    useEffect(() => {
        const { current } = canvasRef;

        let zoomAndCenterTimeout: NodeJS.Timeout | undefined = undefined;

        if (current) {

            zoomAndCenterTimeout = setTimeout(() => {

                current.handler.zoomHandler.zoomAndCenter(current.canvas.getZoom());
                zoomAndCenterTimeout && clearTimeout(zoomAndCenterTimeout);

            }, 500);

        }

        return () => {
            zoomAndCenterTimeout && clearTimeout(zoomAndCenterTimeout);

            if (typeof src === 'string') {

                URL.revokeObjectURL(src);

            }

        };
    }, [canvasRef, anchor, isFullScreen]);



    useEffect(() => {

        if (canvasRef.current?.canvas && src && schema) {

            canvasRef.current.canvas.forEachObject((value: FabricObject) => {

                if (value.id === 'workarea') {

                    value.visible = showHmiMap;
                    value.opacity = schemeOpacity !== null ? schemeOpacity : schema.opacity;

                } else {

                    value.visible = showObjects;

                }
            });

            canvasRef.current.canvas.requestRenderAll();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showObjects, showHmiMap, schemeOpacity]);

    const sliderOptions = {
        value: zoomRatio * 100,
        min: 30,
        max: 300,
    };

    /**
     * Handler for slider zoom change event
     *
     * @param {React.ChangeEvent} event
     * @param {number | number[]} value
     */
    const sliderZoomChange = useCallback((event: React.ChangeEvent<Record<string, unknown>> | null, value: number | number[]) => {

        if (canvasRef.current && !Array.isArray(value)) {

            canvasRef.current.handler.zoomHandler.zoomToValue(value / 100);
        }
    }, [canvasRef]);

    /**
     * Zoom callback
     *
     * @param {number} zoom
     */
    const onZoom = useCallback((zoom: number) => {

        setZoomRatio(zoom);
    }, [setZoomRatio]);

    /**
     * Hover callback on HMI player
     *
     * @type {(obj: FabricObject) => void}
     */
    const onHoverTargetObject = useCallback((obj: FabricObject) => {

        let hoverObject: IHmiObject | undefined = undefined;

        if (obj.type !== 'image' && schema) {

            const { hmiObjects = [] } = schema;

            hoverObject = hmiObjects.find(object => object.id === obj.objectId);

        } else {

            hoverObject = undefined;
        }

        if (hoverFabricObject !== obj) {

            hoverFabricObject = obj;
            dispatch(HmiObjectAction.hoverItemOnMap(hoverObject));

            // dispatch(HmiObjectAction.hoverSensorOnTree(undefined));
        } else if (isMobile) {

            dispatch(HmiObjectAction.hoverItemOnMap(hoverObject));
        }
    }, [dispatch, schema]);
    /**
     * Callback Mouse Leave Schema
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseLeaveSchema=useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        event.preventDefault();

        hoverFabricObject = undefined;

        dispatch(HmiObjectAction.hoverItemOnMap(undefined));

    }, [dispatch]);

    if (!src) {

        return null;
    }

    return (
        <span onMouseLeave={onMouseLeaveSchema}>
            <LeftPanel
                slider={sliderOptions}
                grabActive
                onZoomChange={sliderZoomChange}
                disableGrabIcon
            />
            <Canvas
                ref={canvasRef}
                minZoom={30}
                maxZoom={300}
                workareaOptions={{
                    src: src,
                    width: width,
                    height: height,
                    opacity: schema?.opacity ? schema?.opacity : 1,
                    prevOpacity: schema?.opacity ? schema?.opacity : 1,
                    visible: Boolean(schema?.showMinimap),
                }}
                onZoom={onZoom}
                onHover={onHoverTargetObject}
                width={workareaWidth}
                height={workareaHeight}
                fabricObjects={CanvasObject}
            />
        </span>

    );
};

export default Schema;
