import { factoryConstants } from '../../constants';
import { IFactoriesListAction, IFactoryListState } from '../../interfaces';

/**
 * Factory reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function factory(state: IFactoryListState = {
    factories: [],
    errors: {},
}, action: IFactoriesListAction): IFactoryListState {

    switch (action.type) {

        case factoryConstants.LIST_SUCCESS:

            return {
                factories: action.factories,
                errors: {},
            };

        case factoryConstants.LIST_FAILURE:

            return {
                factories: [],
                errors: action.errors,
            };

        default:

            return state;
    }

}
