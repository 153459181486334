import React from 'react';
import { useTranslation } from 'react-i18next';

import '../styles/ScalePanel.scss';
import { isAndroid, isChrome, isIOS, isSafari } from 'react-device-detect';

/**
 * Panel with scale value
 *
 * @param {number} scale
 *
 * @returns {any}
 *
 * @constructor
 */
export const ScalePanel: React.FC<IProps> = ({
    scale,
}: IProps) => {

    const { t } = useTranslation();

    const ruleWidth = 70;

    return (
        <div className={`map-editor-scale-panel ${isIOS && isSafari ? 'ios-style-safari' : isIOS && isChrome ? 'ios-style-chrome' : ''} ${isAndroid ? 'android-style' : ''}`}>
            <div className="scale-ruler" />
            <div className="scale-value">{(scale * ruleWidth).toFixed(2)} {t('METER_ABBREVIATION')}</div>
        </div>
    );
};

interface IProps {
    scale: number;
}
