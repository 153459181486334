import { ThunkDispatch, ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ConfigurationService, ConfigurationFlatTreeService } from '../services';
import { configurationConstants } from '../constants';
import { IErrors } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Factory related actions
 *
 * @type {Object}
 */
export const ConfigurationActions = {

    /**
     * Get list of factories
     *
     * @params {String} search Search string
     * @param {boolean} trackerLogic
     *
     * @return {Promise<Object>}
     */
    list: (trackerLogic?: boolean): (dispatch: ThunkDispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (item: Record<string, unknown>[]) => {

            return {
                type: configurationConstants.LIST_CONFIGURATION_SUCCESS,
                item,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: configurationConstants.LIST_CONFIGURATION_FAILURE,
                errors,
            };

        }, service = new ConfigurationFlatTreeService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(trackerLogic)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    update: (type: string, item: number, data: {isMinimized: boolean, alertsEnabled?: boolean}): (dispatch: ThunkDispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (item: Record<string, unknown>[]) => {

            return {
                type: configurationConstants.UPDATE_CONFIGURATION_SUCCESS,
                item,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: configurationConstants.UPDATE_CONFIGURATION_FAILURE,
                errors,
            };

        }, service = new ConfigurationService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(type, item, data)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    updateAndRefresh: (type: string, item: number, data: {isMinimized: boolean, alertsEnabled?: boolean, position?: number}): (dispatch: ThunkDispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (item: Record<string, unknown>[]) => {

            return {
                type: configurationConstants.UPDATE_CONFIGURATION_SUCCESS,
                item,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: configurationConstants.UPDATE_CONFIGURATION_FAILURE,
                errors,
            };

        }, service = new ConfigurationService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(type, item, data)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                    if (data) {
                        dispatch(ConfigurationActions.list());
                    }
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    remove: (type:string, item: { id: number }): (dispatch: ThunkDispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (item: Record<string, unknown>[]) => {

            return {
                type: configurationConstants.REMOVE_CONFIGURATION_SUCCESS,
                item,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: configurationConstants.REMOVE_CONFIGURATION_FAILURE,
                errors,
            };

        }, service = new ConfigurationService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.remove(type, item)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

};
