import {
    SaveGatewayAction,
    FetchGatewaysRequest,
    FetchGatewaysSuccess,
    FetchGatewaysFailure,
    DeleteGatewayAction,
    ClearGatewaysAction,
    DeleteGatewayEditorAction,
    SaveGatewayEditorAction,
    ClearGatewayErrorAction,
} from '../actions/gatewayActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IGatewayApiModel, IGatewayIndentOnly } from '../../services/gatewayService';
import { GatewayService } from '../../services/gatewayService';
import { IFilter, IOrder } from '../../../../core/interfaces';
import { IJoin, ILocation } from '../../interfaces';
import { IGatewayState } from '../reducers/gatewayReducer';
import { OpeningForm } from '../actions/settingPageActions';

/**
 * Gateway thunks
 */
export const gatewayThunks = {

    /**
     * Fetch list of gateways
     *
     * @param {ILocation} location
     * @param {string} search
     * @param {IOrder} order
     *
     * @returns {Promise<void>}
     */
    fetchGateways: (location: ILocation, search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }, join?: IJoin, filter?: IFilter) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            dispatch(new FetchGatewaysRequest());

            const service = new GatewayService();

            const gateways = await service.list(search, order, join, filter);

            dispatch(new FetchGatewaysSuccess({ gateways }));

        } catch (error) {

            dispatch(new FetchGatewaysFailure({ error }));
        }
    },

    /**
     * Fetch list of gateways
     *
     * @param {string} search
     * @param {IOrder} order
     * @param {IJoin} join
     * @param {IFilter} filter
     * @returns {Promise<void>}
     */
    fetchAllGateways: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }, join?: IJoin, filter?: IFilter) => async(dispatch: Dispatch<IGatewayState, void, AnyAction>): Promise<void> => {

        const service = new GatewayService();

        try {

            dispatch(new FetchGatewaysRequest());

            const gateways = await service.list(search, order, join, filter);

            dispatch(new FetchGatewaysSuccess({ gateways }));

        } catch (errors) {

            const error = service.errorHandler(errors);

            dispatch(new FetchGatewaysFailure({ error }));
        }
    },

    /**
     * Delete gateway from editor map
     *
     * @param {IGatewayApiModel} gateway
     *
     * @returns {Promise<void>}
     */
    deleteGatewayFromEditor: (gateway: IGatewayIndentOnly) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new GatewayService();

            await service.deleteFromEditor(gateway);

            dispatch(new DeleteGatewayEditorAction());

        } catch (error) {

            //nothing to do
        }
    },

    /**
     * Delete gateway
     *
     * @param {IGatewayApiModel} gateway
     *
     * @returns {Promise<void>}
     */
    deleteGateway: (gateway: IGatewayIndentOnly) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new GatewayService();

            await service.delete(gateway);

            dispatch(new DeleteGatewayAction({ gateway }));

        } catch (error) {

            //nothing to do
        }
    },

    /**
     * Save gateway
     *
     * @param {IGatewayApiModel} model
     */
    saveGateway: (model: IGatewayApiModel) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        const service = new GatewayService();

        try {
            const gateway = await service.save(model);

            dispatch(new SaveGatewayAction({ gateway }));

            dispatch(new OpeningForm({ formOpening: false, formName: '' }));

        } catch (errors) {

            const error = service.errorHandler(errors);

            dispatch(new FetchGatewaysFailure({ error }));

            throw error;
        }
    },

    /**
     * Save gateway form editor
     *
     * @param {IGatewayApiModel} model
     */
    saveGatewayFormEditor: (model: IGatewayApiModel) => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        try {

            const service = new GatewayService();

            const gateway = await service.save(model);

            dispatch(new SaveGatewayEditorAction());

        } catch (error) {

            console.warn(error);

            throw error;
        }
    },


    clearGateways: () => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        dispatch(new ClearGatewaysAction());
    },
    
    clearError: () => async(dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        dispatch(new ClearGatewayErrorAction());
    },
};
