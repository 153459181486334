/**
 * Definition of application roles and permissions
 */

export enum AppRoles {
    SUPER = 'super',
    ADVANCED = 'advanced',
    STANDARD = 'standard',
}

const roles: {[key: string]: {actions: string[], routes: string[]}} = {};

roles[AppRoles.SUPER] = {
    actions: [
        'setting:manage',
        'factory:manage',
        'process:manage',
        'unit:manage',
        'product:manage',
        'state:manage',
        'user:manage',
        'password:update',
        'dashboard:create',
        'dashboard:update',
        'product:change',
        'monitoring-tree:update',
        'alert:add-comment',
        'histogram-setting:update',
        'state-chart:add-state',
        'state-chart:edit-state',
        'state-chart:delete-state',
        'state-chart:view-as-table',
        'hr:employee:manage',
        'hr:gateway:manage',
        'hr:beacon:manage',
        'hr:monitoring-tree:manage',
    ],
    routes: [
        'dashboard',
        'configuration',
        'setting',
        'setting:factory',
        'setting:process',
        'setting:unit',
        'setting:product',
        'setting:state',
        'setting:user',
        'setting:password',
        'setting:hr:employee',
        'setting:hr:gateway',
        'setting:hr:beacon',
    ],
};

roles[AppRoles.ADVANCED] = {
    actions: [
        'password:update',
        'dashboard:create',
        'dashboard:update',
        'product:change',
        'monitoring-tree:update',
        'alert:add-comment',
        'histogram-setting:update',
        'state-chart:add-state',
        'state-chart:edit-state',
        'state-chart:delete-state',
        'state-chart:view-as-table',
    ],
    routes: [
        'dashboard',
        'setting',
        'setting:password',
    ],
};

roles[AppRoles.STANDARD] = {
    actions: [
        'password:update',
        'state-chart:view-as-table',
    ],
    routes: [
        'dashboard',
        'setting',
        'setting:password',
    ],
};

export default roles;
