import React from 'react';
import { useSelector } from 'react-redux';

import './styles/Rule.scss';
import { ISensor, IChartData } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { selectScreenWidth } from '../../../selectors/dashboard/dashboardSelector';
import { selectGraphRule } from '../../../selectors/graphRule/graphRuleSelector';
import { selectHistogramHeight } from '../../../selectors/graphHistogramHeight/histogramHeightSelector';
import {
    calcRealTimeIndentation,
    selectBrushSelection,
} from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectHmiPlayerRealTimeStatus, selectHmiPlayerSchema } from '../../../selectors/hmi/playerSelector';
import { selectHmiPlayerVisibility } from '../../../selectors/hmi/visibilitySelector';
import { selectDashboardOnline } from '../../../selectors/dashboard/dashboardSelector';
import {
    selectDrawerWidthWithDrawRules,
    selectPositionDrawer,
} from '../../../selectors/layout/responsiveDrawerSelector';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectGraphDataLastValuesById } from '../../../selectors/graphDataLastValue/graphDataLastValueSelector';
import * as d3 from 'd3';
import { isMobileOnly } from 'react-device-detect';

interface IRuleTextProps {
    data:  IChartData[];
    sensor: ISensor;
    hrMode: boolean;
    barWidth: number;
}

const screenScale = d3.scaleLinear();

const valueScale = d3.scaleLinear();

/**
 * A rule text component to display graph values
 *
 * @param {React.PropsWithChildren<any>} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const RuleText: React.FC<IRuleTextProps> = (
    {
        data,
        sensor,
        hrMode,
        barWidth,
    }: IRuleTextProps,
) => {

    const { t } = useTranslation();
    const schema = useSelector(selectHmiPlayerSchema);
    const isVisible = useSelector(selectHmiPlayerVisibility) && schema !== null;
    const realTimeStatus = useSelector(selectHmiPlayerRealTimeStatus);
    const isDashboardOnline = useSelector(selectDashboardOnline);
    const [from, to] = useSelector(selectBrushSelection),
        realTimeIndentation = useSelector(calcRealTimeIndentation);

    const drawWidth = useSelector(selectDrawerWidthWithDrawRules);
    const anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
        screenWidthOrigin = useSelector(selectScreenWidth),
        screenWidth = screenWidthOrigin - (!isMobileOnly && isVisible && anchor === 'right' ? drawWidth : 0) - realTimeIndentation;


    // const drawWidth = useSelector(selectDrawerWidthWithDrawRules),
        // anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom',
    // realTimeIndentation = useSelector(calcRealTimeIndentation),
    const width = useSelector(selectScreenWidth),
        graphRule = useSelector(selectGraphRule),
        graphHeight = useSelector(selectHistogramHeight),
        lastValue = useParamSelector(selectGraphDataLastValuesById, sensor.id);

    let x = Math.round(graphRule.x);

    if (x < 0) {
        x = 0;
    }

    const styles = {
        right: (width - x) + 'px',
        top: ((graphHeight / 2) - 15) + 'px',
    };

    if (graphRule && graphRule.display && data && data.length) {

        screenScale
            .domain([from, to].map(d => d.getTime()))
            .range([0, screenWidth - 1]);
        valueScale
            .domain(
                [new Date(data[0].timestamp).getTime(),
                    new Date(data[data.length - 1].timestamp).getTime()],
            )
            .range([0, data.length - 1]);
        const realTimePositionTimestamp = screenScale.invert(screenWidth -1);
        const realTimePositionInValues = valueScale(realTimePositionTimestamp) as number;

        let minText: string | number = 'auto',
            maxText: string | number = 'auto';

        if (sensor.graphPreferences && sensor.graphPreferences[0]) {

            minText = sensor.graphPreferences[0].min || sensor.graphPreferences[0].min === 0 ? sensor.graphPreferences[0].min : 'auto';
            maxText = sensor.graphPreferences[0].max || sensor.graphPreferences[0].max === 0 ? sensor.graphPreferences[0].max : 'auto';
        }

        // convert x to index in valueScale
        const xTimestamp = screenScale.invert(x) as number;
        const valueIndex = valueScale(xTimestamp) as number;

        let index = Math.round(valueIndex);

        if (realTimeStatus && isVisible) {
            index = Math.round(realTimePositionInValues);
        } 

        if (index < 0) {
            index = 0;
        }

        index = Math.abs(index);

        const isRightEdge = index >= data.length - 1;
       const mayFallbackToLeft = isDashboardOnline && isRightEdge;
        const shouldUseLastValue = (realTimeStatus || mayFallbackToLeft) && lastValue !== null;
        
        let displayData;
        if (shouldUseLastValue) {
            displayData = parseFloat(String(lastValue)).toFixed(2);
        } else if (data[index] && data[index].value === null && mayFallbackToLeft) {
            displayData = data[index - 1] && data[index - 1].value !== null ? parseFloat(String(data[index - 1])) : 'No data';
        } else {
            displayData = data[index] && data[index].value !== null ? parseFloat(String(data[index].value)).toFixed(2) : 'No data';
        }

        if (index >= data.length && !realTimeStatus) {

            displayData = data[data.length -1] && data[data.length -1].value !== null ? parseFloat(String(data[data.length -1].value)).toFixed(2) : 'No data';

        }

        return (
            <div className="rule-text" style={styles}>
                <span>{displayData}</span>
                {displayData !== 'No data'?
                    <span className="description">
                        {sensor.um ? sensor.um : ''} ({sensor.graphPreferences && sensor.graphPreferences[0] ? `${minText}, ${maxText}` : hrMode ? t('CM') : sensor.sensorType === 'graph' ? 'auto' : ''})
                    </span> : null
                }
            </div>
        );
    } else if (graphRule && graphRule.display && x) {

        return (
            <div className="rule-text" style={styles}>
                <span>No data</span>
            </div>
        );
    }

    return null;
};

export default RuleText;
