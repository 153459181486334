import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IData, IFilter, IJoin, IOrder, IStateCategory } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with unit API resources
 *
 * @class StatesService
 */
export class StatesService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/state-categories(/:id)';
    }

    /**
     * Get list of units
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<AxiosResponse>  {

            return this.http
                .get(this.url(), {
                    params: this.prepareListParams(search, order, join, filter),
                    headers: { ...this.headers, 'Pragma': 'no-cache' },
                });
    }

    /**
     * Bulk State Category store
     *
     * @param {IData[]} stateCategory
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    bulkStore(stateCategory: IData[]): Promise<AxiosResponse>  {

        return trackPromise(
            this.http
                .post(this.url() + '/bulk', stateCategory, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Create new State Category
     *
     * @param {IStateCategory} stateCategory
     *
     * @return {Promise<Object>}
     */
    store(stateCategory: IStateCategory): Promise<AxiosResponse>  {

        return trackPromise(
            this.http
                .post(this.url(), stateCategory, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Update State Category
     *
     * @param {IStateCategory} stateCategory
     *
     * @return {Promise<Object>}
     */
    update(stateCategory: IStateCategory): Promise<AxiosResponse>  {

        return trackPromise(
            this.http
                .patch(this.url({ id: stateCategory.id }), stateCategory, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove State Category by ID
     *
     * @param {IStateCategory} stateCategory
     *
     * @return {Promise<Object>}
     */
    remove(stateCategory: IStateCategory): Promise<AxiosResponse>  {

        return trackPromise(
            this.http
                .delete(this.url({ id: stateCategory.id }), {
                    headers: this.headers,
                }),
        );
    }
}
