import { InteractionHandler as BaseInteractionHandler } from '../../../../../../base/components/Editor/canvas/handlers/InteractionHandler';
import { FabricObject, InteractionMode } from '../../../../../../base/components/Editor/interfaces';
import { editorConstants } from '../../constants';

export class InteractionHandler extends BaseInteractionHandler {

    /**
     * Switch handler to selection mode
     */
    selection(): void {

        if (this.handler.interactionMode === editorConstants.interaction.modeSelection) {

            return;
        }

        this.handler.interactionMode = editorConstants.interaction.modeSelection as InteractionMode;

        this.handler.canvas.selection = true;

        this.handler.canvas.defaultCursor = 'default';
        this.handler.workarea.hoverCursor = 'default';

        this.handler.getObjects().forEach((object: FabricObject) => {

            if (!object.zIndex) {

                object.zIndex = 0;

            }

            object.hoverCursor = 'move';
            object.moveCursor = 'grabbing';

            object.selectable = object.type === editorConstants.objects.marker ? true : object.editable;
            object.evented = true;

            this.handler.canvas.moveTo(object, object.zIndex);
        });

        this.handler.canvas.renderAll();
    }

    /**
     * Check if handler is switched to drawing mode
     *
     * @returns {boolean}
     */
    isDrawingMode(): boolean {

        return this.handler.interactionMode === editorConstants.interaction.modePolygon
            || this.handler.interactionMode === editorConstants.interaction.modeMarker
            || this.handler.interactionMode === editorConstants.interaction.modeGpsPin;
    }
}
