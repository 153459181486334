import { trackPromise } from 'react-promise-tracker';
import { AxiosResponse } from 'axios';
import HmiProvider from '../providers/hmiProvider';
import { IFilter, IHmiObject, IHmiSchema, IJoin, IOrder } from '../interfaces';
import { serialize as objectToFormData } from 'object-to-formdata';
import { selectAllHmiScheme } from '../selectors/hmiSchemas/hmiSchemeSelector';
import store from '../store';
import { selectHmiOpenScheme } from '../selectors/hmiSchemas/hmiOpenSchemeSelector';

/**
 * Service to work with auth API resources
 *
 * @class AppSetting
 */
export class hmiSchemasService extends HmiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/hmi-schemas(/:id)';
    }

    /**
     * Get list of hmi schema
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: { ...this.headers, 'Pragma': 'no-cache' },
            });
    }

    /**
     * Get list of hmi schema
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    getPicture(hmiSchema: IHmiSchema): Promise<AxiosResponse> {

        const url = this.url({ id: hmiSchema.id }) + '/picture';

        return trackPromise(this.http
            .get(url, {
                headers: this.headers,
            }),
        );
    }

    /**
     * Get list of hmi schema
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    async getHmiSchema(hmiSchema: IHmiSchema): Promise<AxiosResponse> {

        return await this.http
            .get(this.url({ id: hmiSchema.id }), {
                headers: this.headers,
            });
    }

    /**
     * Store hmi schema
     * @param {IHmiSchema} hmiSchema
     * @return {Promise<AxiosResponse>}
     */
    store(hmiSchema: IHmiSchema): Promise<AxiosResponse> {

        const formData = objectToFormData({ ...hmiSchema });

        return trackPromise(
            this.http
                .post(this.url(), formData, {
                    headers: {
                        ...this.headers,
                        ...this.formDataHeaders,
                    },
                }),
        );
    }

    /**
     * Update hmi schema
     *
     * @return {Promise<Object>}
     * @param hmiSchema
     */
    update(hmiSchema: IHmiSchema): Promise<AxiosResponse> {

        const formData = objectToFormData({ ...hmiSchema });

        return trackPromise(
            this.http
                .patch(this.url({ id: hmiSchema.id }), formData, {
                    headers: {
                        ...this.headers,
                        ...this.formDataHeaders,
                    },
                }),
        );
    }

    /**
     * Remove hmi schema by ID
     *
     *
     * @return {Promise<Object>}
     * @param hmiSchema
     */
    remove(hmiSchema: IHmiSchema): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .delete(this.url({ id: hmiSchema.id }), {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove hmi schema by ID
     *
     *
     * @return {Promise<Object>}
     */
    updateLocalScheme(hmiObject: IHmiObject, actionType: string): IHmiSchema[] {

        const allScheme = selectAllHmiScheme(store.getState());

        return allScheme.map(hmiSchema => {

            if (hmiSchema.id === (
                (hmiObject.hmiSchema as unknown as IHmiSchema)?.id ||
                hmiObject.hmiSchema)
                && hmiSchema?.hmiObjects) {

                if (actionType === 'updateObject') {

                    hmiSchema.hmiObjects = hmiSchema.hmiObjects.map(value => {

                        if (value.id === hmiObject.id) {

                            return { ...value, ...hmiObject };
                        }

                        return { ...value };
                    });
                }

                if (actionType === 'deleteObject') {

                    hmiSchema.hmiObjects = hmiSchema.hmiObjects?.filter(value=>value.id !== hmiObject.id);
                }

                if (actionType === 'storeObject') {

                        hmiSchema.hmiObjects = hmiSchema.hmiObjects?.concat([hmiObject]);
                }
            }

            return { ...hmiSchema };
        });
    }

    /**
     * Remove hmi schema by ID
     *
     *
     * @return {Promise<Object>}
     */
    updateLocalOpenScheme(hmiObject: IHmiObject, actionType: string): IHmiSchema {

        const openScheme = selectHmiOpenScheme(store.getState());

        if (openScheme && openScheme.hmiObjects) {

            if (actionType === 'updateObject') {

                openScheme.hmiObjects = openScheme.hmiObjects.map(object => {

                    if (object.id === hmiObject.id) {

                        return { ...object, ...hmiObject };
                    }

                    return { ...object };
                });
            }

            if (actionType === 'deleteObject') {

                openScheme.hmiObjects = openScheme.hmiObjects?.filter(value=>value.id !== hmiObject.id);
            }

            if (actionType === 'storeObject') {

                    openScheme.hmiObjects = openScheme.hmiObjects?.concat([hmiObject]);

            }
        }

        return openScheme!;
    }
}
