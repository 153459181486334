import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as Duration } from '../../assets/images/icons/duration.svg';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { INotification } from '../../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useTranslation } from 'react-i18next';
import { Button } from '../index';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import './Alert.scss';
import { NotificationAction } from '../../../actions';
import { selectRBAC } from '../../../selectors/auth/authSelector';
import { CellMeasurerCache } from 'react-virtualized';

interface IProps {
    dataAlert: INotification
    listRef: any;
    index: number;
    cache: CellMeasurerCache;
}

momentDurationFormatSetup.bind(moment());

const Alert: React.FC<IProps> = (
    {
        dataAlert,
        listRef,
        index,
        cache,
    }: IProps,
) => {

    const language = localStorage.getItem('language');

    if (language) {

        moment.locale(language);

    }

    const dispatch = useDispatch();

    const { t } = useTranslation(),
        rbac = useSelector(selectRBAC);

    const [showCommentInput, setShowCommentInput] = useState(false);
    const [initialValues, setInitialValues] = useState<INotification | undefined>(dataAlert);

    const validationSchema = yup.object().shape({
        comment: yup.string()
            .trim()
            .max(300, t('MAX_COMMENT_COMMENT')),
    });

    useEffect(() => {

        if (initialValues !== dataAlert) {

            setInitialValues(dataAlert);
        }

    }, [initialValues, setInitialValues, dataAlert]);

    /**
     * Recalculate Current Row
     *
     * @type {() => void}
     */
    const recalculateCurrentRow = useCallback(() => {

        setTimeout(() => {

            cache.clear(index, 0);

            listRef.recomputeRowHeights(index);

        }, 200);

    }, [listRef, cache, index]);


    useEffect(() => {

        recalculateCurrentRow();

    }, [recalculateCurrentRow]);

    /**
     * Handle Submit
     *
     * @type {(value: INotification) => void}
     */
    const handleSubmit = useCallback((value: INotification) => {

        if (value.comment) {

            value.comment = value.comment?.trim();
        }

        dispatch(NotificationAction.updateCommentAction(value));

        setInitialValues(value);

        setShowCommentInput(false);

        recalculateCurrentRow();

    }, [recalculateCurrentRow, dispatch, setShowCommentInput]);

    /**
     * Show Comment Input Callback
     *
     * @type {(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void}
     */
    const showCommentInputCallback = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {

        setShowCommentInput(true);

        recalculateCurrentRow();

    }, [recalculateCurrentRow, setShowCommentInput]);

    return (
        <div className={'alert-item--wrap'}>
            <div className="alert-item--body">
                <div className="alert-item--path">
                    <div className="text">
                        ...{`/${dataAlert.unit||''}/${dataAlert.sensor || ''}/`}
                        <span className={'param'}>
                            {`${dataAlert.um || ''} ${dataAlert.operator || ''}` }<span style={{ fontStyle: 'italic' }}>{` ${dataAlert.value}`}</span>
                            <span className={'for'}>
                                {' ' + t('FOR') + ' '}
                            </span>
                            <span style={{ fontStyle: 'italic' }}>
                                {Math.round(dataAlert.threshold / 60).toFixed(1)}
                                {' ' + t('MINUTES')}
                            </span>
                        </span>
                    </div>
                    {dataAlert?.isNew ? <span className={'read-icon'} /> : null }
                </div>
                {initialValues?.comment && !showCommentInput ?
                    <div className="alert-item--comment">{initialValues.comment}</div> : null}
                {showCommentInput && initialValues ? <div className="alert-item--add-comment">
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(props:FormikProps<INotification>) => (
                            <form onSubmit={e => {
                                e.preventDefault();
                                if (!props.isSubmitting) {
                                    props.handleSubmit(e);
                                }
                            }}
                                  noValidate
                            >
                                <TextareaAutosize
                                    className={
                                        'form-field field-comment '
                                        +
                                        (props.touched.comment ? (props.errors.comment) ? 'error-field' : 'success-field' : '')
                                    }
                                    autoFocus={false}
                                    onChange={event=> {

                                        props.handleChange(event);

                                        recalculateCurrentRow();
                                    }}
                                    onBlur={props.handleBlur}
                                    onMouseLeave={props.handleBlur}
                                    onDrop={event => event.preventDefault()}
                                    value={props.values.comment ? props.values.comment : ''}
                                    aria-label="minimum height"
                                    placeholder={t('COMMENT')}
                                    name="comment"
                                />
                                {((props.touched.comment && props.errors.comment)) &&
                                <div className="validation-massage">{props.errors.comment}</div>
                                }
                                <div className="submit-group">
                                    <Button
                                        type="reset"
                                        size={'small'}
                                        color={'primary'}
                                        onClick={() => {
                                            setShowCommentInput(false);
                                        }}
                                    >{t('CANCEL')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        size={'small'}
                                        color={'secondary'}
                                    >
                                        {t(initialValues.comment && initialValues.comment?.length > 0 ? 'SAVE_CHANGES' : 'SAVE')}
                                    </Button>
                                </div>
                            </form>)
                        }
                    </Formik>
                                                     </div> : null}
                <div className="alert-item--duration">
                    <div className="text">
                        <Duration />
                        {moment(dataAlert.startTime).format('D MMM')},&nbsp;
                        {moment(dataAlert.startTime).format('HH:mm')}
                        –
                        {moment(dataAlert.endTime || new Date()).isBefore(moment(dataAlert.startTime).add({ 'd': 1 })) ? ''
                            : `${moment(dataAlert.endTime || new Date()).format('D MMM')}, `}
                        {moment(dataAlert.endTime || new Date()).format('HH:mm')}
                    </div>
                    {rbac && rbac.can('alert:add-comment') && (!initialValues?.comment ?
                            <span
                                className={'add-comment-btn'}
                                hidden={showCommentInput}
                                onClick={showCommentInputCallback}
                            >
                                {t('ADD_COMMENT')}
                            </span>
                            :
                            <span
                                className={'add-comment-btn'}
                                hidden={showCommentInput}
                                onClick={showCommentInputCallback}
                            >
                                {t('EDIT_COMMENT')}
                            </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(Alert);