import { IEditAlertAction, IEditAlertState } from '../../interfaces';
import { alertConstants } from '../../constants';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function editAlert(state: IEditAlertState = {}, action: IEditAlertAction): IEditAlertState {

    switch (action.type) {

        case alertConstants.STORE_ALERT_SUCCESS:

            return {
                alert: action.alert,
                unit: action.unit,
                errors: {},
            };

        case alertConstants.STORE_ALERT_FAILURE:

            return {
                ...state,
                errors: action.errors,
            };

        case alertConstants.CLEAR_ALERT_SUCCESS:

            return {
                alert: null,
                unit: null,
                errors: {},
            };

        default:

            return state;
    }

}