import React from 'react';
import {
    Chip,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    Input,
    List,
    ListItem,
    Select,
    TextField,
} from '@material-ui/core';
import { ReactComponent as DropdownArrowExpandedIcon } from '../../../core/ui/assets/images/icons/arrow-expanded.svg';
import { ReactComponent as CheckedIcon } from '../../../core/ui/assets/images/icons/subcategory-checked.svg';
import './ListMultiple.scss';
import { connect } from 'react-redux';
import { RootState } from '../../../core/store';
import { ICause, IStateCategory } from '../../../core/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IProps {
    selected: {
        id: number,
        name: string,
        color?: string,
    }[];
    name?: string;
    onChange: (selected: ICause[]) => void;
    categories?: IStateCategory[];
}

interface IState {
    selected: ICauseSelection[];
    search: string;
}

interface ICauseSelection extends ICause {
    color: string
}

/**
 * List Multiple component
 */
class ListMultiple extends React.Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {
        super(props);

        this.state = {
            selected: [],
            search: '',
        };

        this.renderValue = this.renderValue.bind(this);
        this.setSelectedState = this.setSelectedState.bind(this);
        this.onSearchCategoryState = this.onSearchCategoryState.bind(this);
        this.filterCategoryStates = this.filterCategoryStates.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>) {

        if (this.props.selected.length > 0 && this.props.selected !== prevProps.selected) {

            this.setSelectedState();
        }
    }

    setSelectedState() {
        const { categories } = this.props,
            newSelected: ICauseSelection[] = [];

        categories && categories.forEach(value => {
            value.causes && value.causes.forEach(cause => {

                const selectedCurrent = this.props.selected.find(sl => sl && sl.id === cause.id);
                if (selectedCurrent) {
                    newSelected.push({
                        ...selectedCurrent, color: cause.color!,
                    });
                }
            });
        });

        this.setState({ selected: newSelected });
    }

    /**
     * Checked status active
     *
     * @param {Object} elem
     * @param color
     */
    checkedStatus(elem: ICauseSelection) {

        const { selected } = this.state;

        if (selected.some(some => some.id === elem.id)) {

            this.setState({ selected: selected.filter(value => value.id !== elem.id) });

            this.props.onChange(selected.filter(value => value.id !== elem.id));

        } else {

            selected.push(elem);

            this.setState({ selected: selected });

            this.props.onChange(selected);
        }

    }

    onSearchCategoryState(e: React.ChangeEvent<HTMLInputElement>) {

        const { value } = e.target;

        this.setState({ search: value });
    }

    /**
     * Render value
     *
     * @param selected
     * @return {React.ReactNode}
     */
    renderValue(selected: any): React.ReactNode {

        const { t } = this.props;

        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                {selected?.length > 0 ?
                    selected.map((value: Record<string, string>) => (
                        <Chip
                            key={value.id}
                            label={value.name}
                            className="chip-item"
                            style={{
                                border: `3px solid ${value.frameColor || '#adb5bd'}`,
                                background: value.color || 'white',
                                margin: 2,
                            }}
                        />
                    )) :
                    <Chip
                        className="chip-item-nullable"
                        label={t('SELECT_STATUS')}
                    />
                }
            </div>

        );
    }

    filterCategoryStates(): IStateCategory[] {

        const { search } = this.state;

        const { categories = [] } = this.props;

        const result: IStateCategory[] = [];

        categories.forEach((category) => {

            const causes = category.causes?.filter(cause => {
                return cause.name.toLowerCase().includes(search.toLowerCase());
            }) || [];

            if (category.name.toLowerCase().includes(search.toLowerCase()) || causes.length) {
                result.push({
                    ...category,
                    causes,
                });
            }
        });

        return result;
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { selected, search } = this.state;

        const { t } = this.props;

        const categories = this.filterCategoryStates();
        
        const MenuProps = {
            PaperProps: {
                style: {
                    // maxHeight: 426,
                    width: 250,
                },
            },
        };

        return (
            <React.Fragment>
                <FormControl
                    style={{
                        maxWidth: 556,
                        minWidth: '100%',
                    }}
                    className="list-multiple--wrap'"
                >
                    <Select
                        multiple
                        value={selected}
                        fullWidth
                        // displayEmpty
                        disableUnderline
                        variant="outlined"
                        className="multiple-select-list"
                        renderValue={this.renderValue}
                        MenuProps={{
                            ...MenuProps,
                            onExited: () => this.setState({ search: '' }),
                        }}
                        input={<Input />}
                    >
                        <div>
                            <TextField
                                variant="outlined"
                                fullWidth
                                autoFocus
                                className="select-search-input"
                                placeholder={t('SEARCH')}
                                value={search}
                                onChange={this.onSearchCategoryState}
                            />
                        </div>
                        <span className="list-multiple--body">
                            {categories && categories.map(value => (
                                <Accordion key={value.id} defaultExpanded>
                                    <AccordionSummary>
                                        <div className="category-name-box">
                                            <div className="category-name">
                                                <DropdownArrowExpandedIcon />
                                                <div className="color-circle"
                                                    style={{
                                                        background: value.color,
                                                    }}
                                                />
                                                {value.name}
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List disablePadding>
                                            {value.causes && value.causes.map(cause => (
                                                <ListItem
                                                    disableGutters key={cause.id}
                                                    button
                                                    className={selected.some(some => some.id === cause.id) ? 'selected-item active' : 'selected-item'}
                                                    onClick={() => this.checkedStatus(cause as ICauseSelection)}
                                                >
                                                    <CheckedIcon style={{ height: 24, width: 24 }} />
                                                    <span className="category-subname">
                                                        {cause.color &&
                                                            <div
                                                                className="color-circle-subname"
                                                                style={{
                                                                    backgroundColor: cause.color,
                                                                    border: `2px solid ${cause?.frameColor || 'transparent'}`,
                                                                }}
                                                            />
                                                        }
                                                        {cause.name}
                                                    </span>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </span>
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { statesList } = state;
    const { states } = statesList;

    states.forEach(state => {

        if (state.causes) {
            state.causes = state.causes.sort((a, b) => {

                if (a.position === undefined) {
    
                    a.position = 0;
                }
    
                if (b.position === undefined) {
    
                    b.position = 0;
                }
    
                return a.position < b.position ? -1 : 1;
            });
        }   
    });

    return {
        categories: states,
    };
};


export default connect(mapStateToProps, null)(withTranslation()(ListMultiple));
