import { 
    IStateItem,
} from '../core/interfaces';

/**
 * Get state width
 * 
 * Get diff between first state start time and last state end time
 * 
 * @param {IStateItem[]} states 
 * @returns {number}
 */
export const getStatesWidth = (
    states: IStateItem[],
): number => {

    const firstState = states[0];

    const lastState = states[states.length - 1];
    
    const diffWidth = Math.round(new Date(lastState.endTime).getTime() - new Date(firstState.startTime).getTime());
    
    return diffWidth / 1000;
};