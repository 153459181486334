import { BarrierActions, BarrierActionTypes } from '../actions/barrierActions';
import { IBarrierApiModel } from '../../services/barrierService';

export interface IBarrierState {
    isLoading: boolean;
    barriers: IBarrierApiModel[];
    error: any;
}

export const initialState: IBarrierState = {
    isLoading: false,
    barriers: [],
    error: null,
};

export function barrierReducer(state = initialState, action: BarrierActions): IBarrierState {

    switch (action.type) {

        case BarrierActionTypes.FETCH_BARRIERS_REQUEST: {

            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case BarrierActionTypes.FETCH_BARRIERS_SUCCESS: {

            const { barriers } = action.payload;

            return {
                ...state,
                isLoading: false,
                barriers,
            };
        }

        case BarrierActionTypes.FETCH_BARRIERS_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case BarrierActionTypes.DELETE_BARRIER_ACTION: {

            const modifyBarriers = state.barriers.filter(barrier => barrier.id !== action.payload.barrier.id);

            return {
                ...state,
                barriers: modifyBarriers,
            };
        }

        case BarrierActionTypes.SAVE_BARRIER_ACTION: {

            const modifyBarriers = state.barriers.map((barrier) => barrier.id === action.payload.barrier.id ? action.payload.barrier : barrier);

            if (modifyBarriers.findIndex(barrier => barrier.id === action.payload.barrier.id) === -1) modifyBarriers.push(action.payload.barrier);

            return {
                ...state,
                barriers: modifyBarriers,
            };
        }

        case BarrierActionTypes.CLEAR_BARRIERS_ACTION: {
            return {
                ...state,
                barriers: [],
            };
        }

        default: {

            return state;
        }
    }
}
