import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { formConstants } from '../constants';

/**
 * Form related actions
 * 
 * @type {Object}
 */
export const FormActions = {
    
    /**
     * Show/hide form in right sidebar
     *
     * @param {boolean} opened
     * @param {string} name
     * @param {any} model
     *
     * @return {Promise<Object>}
     */
    toggle: (opened: boolean, name?: string, model?: any) => {
        
        //Action creators
        const open = () => {

            return {
                type: formConstants.OPEN,
                name: name,
                model: model,
            };
            
        }, close = () => {
            
            return {
                type: formConstants.CLOSE,
                name: name,
            };
        };
        
        return  (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            
            dispatch(opened ? close() : open());
        };
    },

    /**
     * Show/hide form in dialog popup
     *
     * @param {boolean} opened
     *
     * @return {Promise<Object>}
     */
    toggleDialog: (opened: boolean) => {

        //Action creators
        const open = () => {

            return {
                type: formConstants.DIALOG_OPEN,
            };

        }, close = () => {

            return {
                type: formConstants.DIALOG_CLOSE,
            };
        };

        return  (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(opened ? close() : open());
        };
    },
};
