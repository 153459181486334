import { Handler } from '../canvas/handlers/Handler';
import { editorConstants } from '../constants';
import { IZone, IObject, IGateway } from '../interfaces';

/**
 * Assign gateway to zone method
 * 
 * @param objects 
 * @param target 
 */
export const assignGatewayToZone = (objects: IObject[], target: IObject) => {

    const { zone } = editorConstants.objects;

    objects
        .filter(object => object.type === zone)
        .forEach((zone, i) => {

            if (zone.id !== target.zone && zone.gateways?.includes(target.id)) {

                const gIndex = zone.gateways.findIndex(gateway => gateway === target.id);

                if (gIndex !== -1) {

                    zone.gateways.splice(gIndex, 1);

                    objects[i] = { ...objects[i] };
                }
            }
        });
};

/**
 * Assign zone to gateway method
 * 
 * @param objects 
 * @param object 
 */
export const assignZoneToGateway = (objects: IObject[], target: IObject) => {

    const { zone, gateway } = editorConstants.objects;

    // list of gateways [id] = model.value
    const gatewaysByModel: Record<string, any> = [];

    objects.forEach(obj => obj.type === gateway && obj.value ? gatewaysByModel[obj.id] = obj.value : null);

    objects.forEach((object, i) => {

        if (object.id !== target.id && object.type === zone && object.gateways?.length && target.gateways?.length) {

            target.gateways.forEach(gateway => {

                // check model value by passed object id
                const gatewayValue = gatewaysByModel[gateway.toString()];

                // search zone which has gateway and splice gateway from a list of another zone gateway
                if (object.gateways?.includes(gateway) || object.gateways?.includes(gatewayValue)) {

                    const zGateways = objects[i].gateways || [];

                    const gIndex = zGateways.findIndex(zGateway =>
                        zGateway === gateway || zGateway === gatewayValue,
                    );

                    if (zGateways && gIndex !== -1) {

                        zGateways.splice(gIndex, 1);

                        objects[i] = { ...objects[i], gateways: zGateways };
                    }
                }
            });
        }

        if (object.type === gateway) {

            // save zone with unassigned gateways
            if (object.zone === target.id) objects[i] = { ...objects[i], zone: '' };

            // reassign gateway from another zone
            if (target.gateways?.includes(object.id)) objects[i] = { ...objects[i], zone: target.id };
        }
    });
};

/**
 * Check gateway id 
 * 
 * @param {(string | IGateway)[]}
 * @param {IObject}
 * @returns {boolean}
 */
const checkGatewayId = (gateways: (string | IGateway)[], object: IObject): boolean => {
    return ((object?.value && gateways.includes(object?.value)) || gateways.includes(object.id));
};

/**
 * Delete zone relations
 * 
 * @param objects 
 * @param target 
 * @param handler 
 */
export const deleteZoneRelations = (objects: IObject[], target: IObject, handler?: Handler | null) => {

    const deleteList: string[] = [];

    const { barrier } = editorConstants.objects;

    objects.forEach((object, i) => {

        if (
            (target.gateways && checkGatewayId(target.gateways, object)) || // check to gateway objects
            (object.type === barrier && object?.zoneId === target.model_id) // check to barrier objects
        ) {

            if (handler) {

                const canvObject = handler.findById(object.id);

                if (canvObject) {

                    handler.remove(canvObject);

                    deleteList.unshift(object.id);
                }
            } else {
                objects[i] = { ...objects[i], zone: '' };
            }
        }
    });

    if (deleteList && handler) {

        for (const item of deleteList) {

            const index = objects.findIndex(object => object.id === item);

            index && objects.splice(index, 1);
        }
    }
};


/**
 * Check zone id
 */
const checkZoneId = (object: IObject, zoneId: string | undefined): boolean => {
    return object.model_id === zoneId || object.id === zoneId;
};

/**
 * Delete gateways relations
 * 
 * @param {IObject[]} objects 
 * @param {IObject} target 
 * @param {Handler | null} handler 
 */
export const deleteGatewayRelations = (objects: IObject[], target: IObject, handler?: Handler | null): void => {

    const deleteZones: string[] = [];

    const { zone } = editorConstants.objects;

    if (target.zone) {
        
        objects.forEach((object, i) => {

            // if new canvas object zone is obj if already stored object then string
            const zoneId = typeof target.zone === 'string' ? target.zone : (target.zone as IZone).id;

            // check if zone object with not empty gateways list
            if (object.type === zone && checkZoneId(object, zoneId) && object.gateways) {

                const relatedGateways = [...object.gateways];

                relatedGateways.forEach((gateway, i) => gateway === target.id && relatedGateways.splice(i, 1));

                objects[i] = { ...object, gateways: relatedGateways };

                if (handler) {

                    const canvObject = handler.findById(object.id);

                    if (canvObject) {

                        handler.remove(canvObject);

                        objects.splice(i, 1);

                        deleteZones.unshift(object.id);
                    }
                }
            }
        });

        clearObjRelateToZone(objects, deleteZones);
    }
};

/**
 * Delete barrier relations
 * 
 * @param objects 
 * @param target 
 * @param handler 
 */
export const deleteBarrierRelations = (objects: IObject[], target: IObject, handler: Handler) => {

    const deleteZones: string[] = [];

    objects.forEach((object, i) => {

        if (object.type === editorConstants.objects.zone && object.model_id === target.zoneId) {

            const canvObject = handler.findById(object.id);

            if (canvObject) {

                handler.remove(canvObject);

                objects.splice(i, 1);

                deleteZones.unshift(object.id);
            }
        }
    });

    clearObjRelateToZone(objects, deleteZones);
};

/**
 * Clear zone value in objects that related to zone
 * 
 * @param objects 
 * @param zones 
 */
const clearObjRelateToZone = (objects: IObject[], zones: string[]) => {

    const { gateway, barrier } = editorConstants.objects;

    objects.forEach((object, i) => {
        if (object.type && object.zone && [gateway, barrier].includes(object.type) && zones.includes(String(object.zone))) {

            objects[i] = { ...objects[i], zone: '' };
        }
    });
};

