import { createSelector, Selector } from 'reselect';

export function createFeatureSelector<T>(featureName: string): Selector<object, T>;
export function createFeatureSelector<T, V>(featureName: keyof T): Selector<T, V>;
export function createFeatureSelector(featureName: any): Selector<any, any> {
    return createSelector(
        (state: any) => {
            const featureState = state[featureName];
            if (!(featureName in state)) {
                console.warn(
                    `redux-store: The feature name "${featureName}" does ` +
                        'not exist in the state, therefore createFeatureSelector ' +
                        'cannot access it. Be sure it is declared in root store reducers.' +
                        'If the default state is intended to be undefined, as is the case with router ' +
                        'state, this development-only warning message can be ignored.',
                );
            }
            return featureState;
        },
        (featureState: any) => featureState,
    );
}
