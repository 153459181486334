import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

import Button from '../../../../ui/components/Button/Button';
import TextInput from '../../../../ui/components/Input/TextInput';
import Select from '../../../../ui/components/Select/Select';
import { IBarrier, IZone, IObject } from '../../interfaces';
import { ModalSelectOption } from '../../../../../../base/components/Editor/interfaces';



interface IProps {
    onSave?: (model: IBarrier) => void;
    onCancel?: () => void;
    object?: IBarrier | null;
    zones: ModalSelectOption[];
    barriers: IObject[];
    zoneObjects: IZone[];
}

interface IState {
    initialValues: IBarrier;
    edit: boolean;
}


/**
 * Barrier form
 *
 * @class BarrierForm
 */
class BarrierForm extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, object, barriers, zoneObjects } = this.props;

        const zone = zoneObjects.find(zoneObject => zoneObject.model_id === object?.zoneId);

        this.state = {
            initialValues: {
                ...object,
                name: object?.name || '',
                zone: zone?.id || '',
            },
            edit: !!(object && object.id),
        };

        this.validationSchema = yup.object().shape({
            name: yup
                .string()
                .trim()
                .test('unique_name', t('THE_BARRIER_NAME_HAS_ALREADY_BEEN_TAKEN'), function(val: string) {
                    return barriers && barriers.length > 0 ?
                        !barriers.filter(b => {
                            return b.name.toLocaleLowerCase() === val?.toLowerCase().trim() &&
                                object?.name?.toLocaleLowerCase() !== val?.toLocaleLowerCase().trim();
                        }).length : true;
                })
                .required(t('NAME_IS_REQUIRED')),
            zone: yup.string(),
        });

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IBarrier>>;

    /**
     * Barrier form submit handler
     *
     * @param {IBarrier} values
     */
    handleSubmit(values: IBarrier) {

        const { onSave } = this.props;

        if (onSave) {

            onSave(values);
        }
    }

    /**
     * Barrier form cancel handler
     */
    handleCancel() {

        const { onCancel } = this.props;

        if (onCancel) {

            onCancel();
        }
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t } = this.props,
            { initialValues, edit } = this.state;

        return (
            <React.Fragment>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <TextInput
                                className={
                                    'form-field ' +
                                    (props.touched.name ? (props.errors.name) ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                placeholder={`${t('NAME')}*`}
                                value={props.values.name}
                                name="name"
                                type="text"
                                inputProps={{ maxLength: 50 }}
                            >
                                {(props.touched.name && props.errors.name) &&
                                    <div className="validation-massage">{props.errors.name}</div>
                                }
                            </TextInput>
                            <Select
                                className="form-field"
                                value={props.values.zone}
                                name="zone"
                                placeholder={t('SELECT_OPTIONAL_ZONE')}
                                onChange={props.handleChange}
                                options={this.props.zones}
                                displayEmpty
                                MenuProps={{
                                    disableScrollLock: true,
                                    style: {
                                        maxHeight: 300,
                                    },
                                }}
                            />
                            <div className="button-row">
                                <Button type="button" color="primary" onClick={this.handleCancel} style={{ marginRight: 30 }}>
                                    {t('CANCEL')}
                                </Button>
                                <Button type="submit" color="primary">
                                    {t(edit ? 'EDIT' : 'ADD')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

export default withTranslation()(BarrierForm);
