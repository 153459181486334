import React, { useCallback } from 'react';
import { IData } from '../../../../../interfaces';
import Button from '../../../../ui/components/Button/Button';

interface IProps {
    action: (data: IData) => void,
    label: string,
    data: IData,
}

export const EditBeaconButton: React.FC<IProps> = (props: IProps) => {
    const { action, data, label } = props;

    const onClick = useCallback(() => {
        action(data);
    }, [action, data]);

    return <Button size="small" color="primary" onClick={onClick}>{label}</Button>;
};
