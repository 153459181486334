export const unitConstants = {
    LIST_SUCCESS: 'UNIT_LIST_SUCCESS',
    LIST_FAILURE: 'UNIT_LIST_FAILURE',

    LIST_PRODUCT_SUCCESS: 'UNIT_PRODUCT_LIST_SUCCESS',
    LIST_PRODUCT_FAILURE: 'UNIT_PRODUCT_LIST_FAILURE',

    BULK_UPDATE_SUCCESS: 'UNIT_BULK_UPDATE_SUCCESS',
    BULK_UPDATE_FAILURE: 'UNIT_BULK_UPDATE_FAILURE',

    UPDATE_SUCCESS: 'UNIT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'UNIT_UPDATE_FAILURE',
};
