import { graphConstants } from '../../constants';
import { IGraphHistogramHeightAction, IGraphHistogramHeightState } from '../../interfaces';


/**
 * Graph histogram height reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphHistogramHeight(state: IGraphHistogramHeightState = {
    height: graphConstants.histogramSmallHeight,
}, action: IGraphHistogramHeightAction): IGraphHistogramHeightState {

    switch (action.type) {

        case graphConstants.histogramSwitchNormal:

            return {
                ...state,
                height: graphConstants.histogramNormalHeight,
            };

        case graphConstants.histogramSwitchSmall:

            return {
                ...state,
                height: graphConstants.histogramSmallHeight,
            };

        case graphConstants.histogramSwitchMode:

            return {
                ...state,
                mode: action.mode,
            };

        default:

            return state;
    }
}
