import { Action } from 'redux';
import { IGatewayApiModel, IGatewayIndentOnly } from '../../services/gatewayService';

export enum GatewayActionTypes {
    SAVE_GATEWAY_ACTION = '[Gateway] Save Gateway',
    SAVE_GATEWAY_EDITOR_ACTION = '[Gateway] Save Gateway Editor',
    FETCH_GATEWAYS_REQUEST = '[Gateway] Fetch Gateways Request',
    FETCH_GATEWAYS_SUCCESS = '[Gateway] Fetch Gateways Success',
    FETCH_GATEWAYS_FAILURE = '[Gateway] Fetch Gateways Failure',
    DELETE_GATEWAY_ACTION = '[Gateway] Delete Gateway',
    DELETE_GATEWAY_EDITOR_ACTION = '[Gateway] Delete Gateway Editor',
    CLEAR_GATEWAYS_ACTION = '[Gateway] Clear Gateways',
    CLEAR_GATEWAY_ERROR = '[Gateway] Clear Gateway error',
}

/**
 * Fetch gateway request action
 *
 * @class FetchGatewaysRequest
 */
export class FetchGatewaysRequest implements Action {
    readonly type = GatewayActionTypes.FETCH_GATEWAYS_REQUEST;
}

/**
 * Fetch gateway success action
 *
 * @class FetchGatewaysSuccess
 */
export class FetchGatewaysSuccess implements Action {
    readonly type = GatewayActionTypes.FETCH_GATEWAYS_SUCCESS;

    constructor(
        public payload: {
            gateways: IGatewayApiModel[]
        },
    ) {}
}

/**
 * Fetch gateway failure action
 *
 * @class FetchGatewaysFailure
 */
export class FetchGatewaysFailure implements Action {
    readonly type = GatewayActionTypes.FETCH_GATEWAYS_FAILURE;

    constructor(
        public payload: {
            error: any
        },
    ) {}
}

/**
 * Save gateway action
 *
 * @class SaveGatewayAction
 */
export class SaveGatewayAction implements Action {
    readonly type = GatewayActionTypes.SAVE_GATEWAY_ACTION;

    constructor(
        public payload: {
            gateway: IGatewayApiModel,
        },
    ) {}
}

/**
 * Save gateway from editor action
 *
 * @class SaveGatewayAction
 */
export class SaveGatewayEditorAction implements Action {
    readonly type = GatewayActionTypes.SAVE_GATEWAY_EDITOR_ACTION;
}

/**
 * Delete gateway action
 *
 * @class DeleteGatewayAction
 */
export class DeleteGatewayAction implements Action {
    readonly type = GatewayActionTypes.DELETE_GATEWAY_ACTION;

    constructor(
        public payload: {
            gateway: IGatewayIndentOnly
        },
    ) {}
}

/**
 * Delete gateway editor action
 *
 * @class DeleteGatewayAction
 */
export class DeleteGatewayEditorAction implements Action {
    readonly type = GatewayActionTypes.DELETE_GATEWAY_EDITOR_ACTION;
}

/**
 * Clear gateways action
 * 
 * @class ClearGatewaysAction
 */
export class ClearGatewaysAction implements Action {
    readonly type = GatewayActionTypes.CLEAR_GATEWAYS_ACTION;
}

/**
 * Clear gateway error
 * 
 * @class ClearGatewayError
 */
export class ClearGatewayErrorAction implements Action {
    readonly type = GatewayActionTypes.CLEAR_GATEWAY_ERROR;
}

export type GatewayActions =
    FetchGatewaysRequest |
    FetchGatewaysSuccess |
    FetchGatewaysFailure |
    SaveGatewayAction |
    SaveGatewayEditorAction |
    DeleteGatewayAction |
    DeleteGatewayEditorAction |
    ClearGatewaysAction |
    ClearGatewayErrorAction; 
