import {
    OpeningForm,
} from '../actions/settingPageActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ISettingPageState } from '../reducers/settingPageReducer';
import { IBeacon, IDepartment, IEmployee } from '../../interfaces';
import { IGatewayApiModel } from '../../services/gatewayService';

export const SettingPageThunks = {

    toggleForm: (
        formOpening: boolean,
        formName?: string,
        model?: IEmployee | IDepartment | IGatewayApiModel | IBeacon,
    ) => async(dispatch: Dispatch<ISettingPageState, void, AnyAction>):Promise<void> => {

        dispatch(new OpeningForm({ formOpening, formName, model }));

    },
};
