import { IHmiSchema, IHmiState, IHMIStateAction } from '../../interfaces';
import { hmiObjectConstants, hmiSchemaConstants } from '../../constants';


const removeItemFromArray = (items: IHmiSchema[], item:IHmiSchema): IHmiSchema[]=> {

    return items.filter(value => value.id !== item.id);
};

const updateItemFromArray = (items: IHmiSchema[], item: IHmiSchema): IHmiSchema[] => {

    return items.map(value => {

        if (value.id === item.id) {

            return item;
        }

        return value;
    });
};


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function hmiState(state: IHmiState = {
    errors: {},
    objects: [],
    schemas: [],
}, action: IHMIStateAction): IHmiState {

    switch (action.type) {

        case hmiObjectConstants.LIST_HMI_OBJECT_SUCCESS:

            return {
                ...state,
                objects: action.objects,
            };

        case hmiSchemaConstants.LIST_HMI_SCHEMA_SUCCESS:
        case hmiSchemaConstants.UPDATE_LOCAL_HMI_SCHEMA_SUCCESS:

            return {
                ...state,
                schemas: action.schemas,
            };

        case hmiSchemaConstants.STORE_HMI_SCHEMA_SUCCESS:

            return {
                ...state,
                schemas: [...state.schemas, action.hmiSchema],
            };

        case hmiSchemaConstants.DELETE_HMI_SCHEMA_SUCCESS:

            return {
                ...state,
                schemas: [...removeItemFromArray(state.schemas, action.hmiSchema)],
            };

        case hmiSchemaConstants.UPDATE_HMI_SCHEMA_SUCCESS:

            return {
                ...state,
                schemas: updateItemFromArray(state.schemas, action.hmiSchema),
            };

        default:

            return state;
    }

}