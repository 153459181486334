import { graphConstants } from '../../constants';
import { IGraphBarDescriptionVisibilityState } from '../../interfaces';


/**
 * Bar chart description visibility reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphBarDescriptionVisibility(state: IGraphBarDescriptionVisibilityState = {
    visible: true,
}, action: { type: string }): IGraphBarDescriptionVisibilityState {

    switch (action.type) {

        case graphConstants.barDescriptionShow:

            return {
                visible: true,
            };

        case graphConstants.barDescriptionHide:

            return {
                visible: false,
            };

        default:

            return state;
    }
}
