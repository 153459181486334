import React from 'react';
import Button from '../../../../ui/components/Button/Button';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IObject } from '../../interfaces';
import { Formik } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './../../styles/DeleteForm.scss';
import { editorConstants } from '../../constants';


interface IProps {
    onDelete: Function;
    object: IObject;
}

interface IState {
    initialValues: {
        single: boolean;
        multiple: boolean;
    }
}

class DeleteForm extends React.Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {
        super(props);

        const { object } = this.props;

        const { layer, building } = editorConstants.objects;

        this.state = {
            initialValues: {
                single: object.type === building || object.type === layer,
                multiple: false,
            },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values: { single: boolean, multiple: boolean }) {

        this.props.onDelete(values.single, values.multiple);

    }

    render() {

        const { t, object } = this.props;
        const { initialValues } = this.state;

        const { layer, building } = editorConstants.objects;

        const deleteObjType = 'DELETE_' + object.type?.toLocaleUpperCase() + '_TYPE' || 'DELETE_OBJECT_TYPE';

        return (
            <React.Fragment>
                <div id="delete-object-dialog">
                    <h2 className="form-dialog-title">
                        {t('DELETE_OBJECT_TITLE', {
                            type: t(deleteObjType).toLowerCase(),
                            name: t(object.name),
                        })}
                    </h2>

                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                    >
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                {(object.type !== building && object.type !== layer) &&
                                    <>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={props.values.single}
                                                        onChange={props.handleChange}
                                                        name="single"
                                                        color="primary"
                                                    />
                                                }
                                                className="label-firts-letter-capitalize"
                                                label={t('DELETE_SINGLE_OBJECT', {
                                                    object: t(deleteObjType),
                                                })}
                                            />
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={props.values.multiple}
                                                        onChange={props.handleChange}
                                                        name="multiple"
                                                        color="primary"
                                                    />
                                                }
                                                className="label-firts-letter-capitalize"
                                                label={t('DELETE_OBJECT_AND_ALL_CONNECTED_ITEMS', {
                                                    object: t(deleteObjType),
                                                })}
                                            />
                                        </div>
                                    </>
                                }
                                <div className="button-row">
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => this.props.onDelete()}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        disabled={!props.values.single && !props.values.multiple}
                                        type="submit"
                                        color="primary"
                                        className="color-danger"
                                    >
                                        {t('DELETE')}
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(DeleteForm);