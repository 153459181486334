import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IDashboard, IDashboardDuplicate, IFilter, IHistogramData, IJoin, IOrder } from '../interfaces';
import { AxiosResponse } from 'axios';
import { getStatesWidth } from '../../helpers/localSensorData';

/**
 * Service to work with unit API resources
 *
 * @class DashboardService
 */
export class DashboardService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/dashboards(/:id)';
    }

    /**
     * Get list of dashboard
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            });
    }

    /**
     * Dashboard create
     *
     * @param {IDashboard} dashboard
     *
     * @return {Promise<AxiosResponse>}
     */
    create(dashboard: IDashboard): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url(), dashboard, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Duplicate dashboard
     *
     * @param {IDashboard} dashboard
     *
     * @return {Promise<AxiosResponse>}
     */
    duplicate(dashboard: IDashboardDuplicate): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url() + '/duplicate', dashboard, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Update dashboard
     *
     * @param {IDashboard} dashboard
     *
     * @return {Promise<Object>}
     */
    update(dashboard: IDashboard): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ id: dashboard.id }), dashboard, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove dashboard by ID
     *
     * @param {IDashboard} dashboard
     *
     * @return {Promise<Object>}
     */
    remove(dashboard: IDashboard): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .delete(this.url({ id: dashboard.id }), {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Update local store with sensor data.
     *
     * @param {IHistogramData[]} data
     * @param {IHistogramData[]} currentData
     * @param {number[]} ignore
     * @return {Promise<IHistogramData[]>}
     */
    async updateLocalSensorData(
        data: IHistogramData[],
        currentData: IHistogramData[],
        ignore: number[] = [],
    ): Promise<IHistogramData[]> {

        if (currentData.length > 0) {

            for (const value of data) {

                if (ignore.includes(value.id)) {

                    const currentDaTaBySensor = currentData.find(item => item.id === value.id);

                    // check diff state width between current and update value states
                    if (currentDaTaBySensor && currentDaTaBySensor.states?.length && value.states?.length) {

                        const valueWidth = getStatesWidth(value.states);

                        const currentValueWidth = getStatesWidth(currentDaTaBySensor.states);

                        const diffWidth = Math.abs(valueWidth - currentValueWidth);

                        // in case if diff width less then one minutes then ignore update
                        if (diffWidth < 60) {
                            continue;
                        }
                    }
                }

                const indexHistogramData = currentData.findIndex(cData => cData.id === value.id);

                if (indexHistogramData > -1) {

                    currentData.splice(indexHistogramData, 1, value);

                } else {

                    currentData.push(value);

                }

            }

            return [...currentData];
        }

        return data;

    }
}
