import { fabric } from 'fabric';

/**
 * PolygonCircle object definition
 *
 * @type {any}
 */
const PolygonCircle = fabric.util.createClass(fabric.Circle, {
    transform: function(ctx: CanvasRenderingContext2D) {
        this.callSuper('transform', ctx);

        if (!this.group && this.canvas) {
            const canvasZoom = this.canvas.getZoom();
            const rateToNotDecrease = 1.6;
            const zoom =  1 / canvasZoom <= rateToNotDecrease ? 1 / canvasZoom : rateToNotDecrease;

            ctx.scale(zoom, zoom);
        }
    },
});

export default PolygonCircle;
