import { userConstants } from '../../constants';
import { IUserListAction, IUserListState } from '../../interfaces';

/**
 * User list reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function userList(state: IUserListState = {
    users: [],
    errors: {},
}, action: IUserListAction): IUserListState {

    switch (action.type) {

        case userConstants.LIST_SUCCESS:

            return {
                users: action.users,
                errors: {},
            };

        case userConstants.LIST_FAILURE:

            return {
                users: [],
                errors: action.errors,
            };

        default:

            return state;
    }
}
