import { LayerFormActions, LayerFormActionTypes } from '../actions/layerFormActions';
import { EditorMode, IGpsPin, IGpsPinIdent, ILayer } from '../../interfaces';

export interface ILayerFormState {
    isLoading: boolean;
    locationId: number | null;
    layer: ILayer | null;
    editorMode: EditorMode;
    error: any;
    pins: Map<IGpsPinIdent, IGpsPin>;
}

export const initialState: ILayerFormState = {
    isLoading: false,
    locationId: null,
    layer: null,
    editorMode: EditorMode.DEFAULT,
    error: null,
    pins: new Map<IGpsPinIdent, IGpsPin>(),
};

export function layerFormReducer(state = initialState, action: LayerFormActions): ILayerFormState {
    switch (action.type) {
        case LayerFormActionTypes.SAVE_LAYER_FORM_REQUEST: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case LayerFormActionTypes.SAVE_LAYER_FORM_SUCCESS: {
            const { layer } = action.payload;

            return {
                ...state,
                isLoading: false,
                layer,
            };
        }

        case LayerFormActionTypes.SAVE_LAYER_FORM_FAILURE: {
            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case LayerFormActionTypes.RESET_WITH_LAYER_MODEL: {
            const { location, layer } = action.payload;

            const locationPins = location?.pins || [];
            const pins = new Map(locationPins.map(pin => [pin.localId, pin]));

            return {
                ...state,
                layer,
                locationId: location?.id || null,
                pins,
            };
        }

        case LayerFormActionTypes.SET_EDITOR_MODE_ACTION: {
            const { mode } = action.payload;

            return {
                ...state,
                editorMode: mode,
            };
        }

        default: {
            return state;
        }
    }
}
