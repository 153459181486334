import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';
import { selectLocations } from './locationSelector';

export const selectLayerFormState = createSelector(
    selectHrModuleState,
    state => state.layerFormState,
);

export const selectLayerFormModel = createSelector(
    selectLayerFormState,
    state => state.layer,
);

export const selectLayerFormError = createSelector(
    selectLayerFormState,
    state => state.error,
);

export const selectLayerFormEditorMode = createSelector(
    selectLayerFormState,
    state => state.editorMode,
);

export const selectLayerFormLocationId = createSelector(
    selectLayerFormState,
    state => state.locationId,
);

export const selectLayerFormPinsMap = createSelector(
    selectLayerFormState,
    state => state.pins,
);

export const selectLayerFormPins = createSelector(
    selectLayerFormPinsMap,
    pins => Array.from(pins.values()),
);

export const selectLayerFormLocation = createSelector(
    selectLayerFormLocationId,
    selectLocations,
    (locationId, locations) => locations.find(({ id }) => id === locationId),
);
