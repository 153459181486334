import React, { ReactNode, useState } from 'react';
import { InputLabel, Select as MuiSelect, MenuItem, TextField } from '@material-ui/core';
import { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import styled from 'reshadow';
import { useTranslation } from 'react-i18next';

const styles = require('./Select.scss');

interface IOptions {
	label: string;
	value: string | number;
	icon?: ReactNode;
}

interface IProps {
	options: IOptions[];
	label?: string;
	size?: 'small' | 'medium' | 'large';
	disabledselectoption?: IOptions;
	searchable?: boolean;
}

export type SelectProps = IProps & MuiSelectProps;
/**
 * An Input component for a select box, using an array of objects for the options
 *
 * @param {React.ReactNode} children
 * @param {object} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Select: React.FC<SelectProps> = ({
    children,
    ...props
}) => {

	const [open, setOpen] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');

	const { t } = useTranslation();

	const { className, options, placeholder, size, disabledselectoption, searchable } = props,
        classList = 'select-box' + (size === 'small' ? ' select-small' : ''),
        placeholderValue = placeholder ?
		(<MenuItem value="" disabled>
			<span className="placeholder">{placeholder}</span>
   </MenuItem>) : null;

	const filteredOptions = search 
		? options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()))
		: options;

	const searchMenuProps = searchable 
		? { 
			autoFocus: false,
			onEnter: () => setOpen(true), 
			onExited: () => {
				setOpen(false);
				setSearch('');
			},
		} 
		: {};
	
	const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	return styled(styles)(
		<div className={className}>
			<InputLabel>{props.label}</InputLabel>
			<MuiSelect
				fullWidth
				{...props}
				variant="outlined"
				className={classList}
                displayEmpty
				MenuProps={{
					...searchMenuProps,
					onKeyDownCapture: e => e.stopPropagation(),
				}}
			>
				{!open ? placeholderValue : null}
				{searchable &&
					<TextField
						className="select-search-input"
						variant="outlined"
						fullWidth
						autoFocus
						inputProps={{
							onChange: onChangeSearch,
							onClickCapture: e => e.stopPropagation(),
						}}
						placeholder={t('SEARCH')}
					/>
				}
				{filteredOptions.map(option => (
					<MenuItem
						key={option.value}
						value={option.value}
						disabled={disabledselectoption && disabledselectoption.value === option.value}
					>
						{option.icon &&(option.icon)}{option.label}
					</MenuItem>
				))}
			</MuiSelect>
			{children}
		</div>,
	);
};

export default React.memo(Select);
